export const GET_DEPARTMENT_LIST_REQUEST="GET_DEPARTMENT_LIST_REQUEST";
export const GET_DEPARTMENT_LIST_SUCCESS="GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_FAILURE="GET_DEPARTMENT_LIST_FAILURE";

export const DELETE_DEPARTMENT_LIST_REQUEST="DELETE_DEPARTMENT_LIST_REQUEST";
export const DELETE_DEPARTMENT_LIST_SUCCESS="DELETE_DEPARTMENT_LIST_SUCCESS";
export const DELETE_DEPARTMENT_LIST_FAILURE="DELETE_DEPARTMENT_LIST_FAILURE";
export const CLEAR_DEPARTMENT_DATA="CLEAR_DEPARTMENT_DATA";

export const UPDATE_DEPARTMENT_DATA="UPDATE_DEPARTMENT_DATA";

export const CREATE_DEPARTMENT_REQUEST="CREATE_DEPARTMENT_REQUEST";
export const CREATE_DEPARTMENT_SUCCESS="CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILURE="CREATE_DEPARTMENT_FAILURE";
export const CLEAR_DEPARTMENT_ERROR_DATA="CLEAR_DEPARTMENT_ERROR_DATA";

export const EDIT_DEPARTMENT_DIALOG_STATE="EDIT_DEPARTMENT_DIALOG_STATE";
export const SET_DEPARTMENT_INFO="SET_DEPARTMENT_INFO";

export const UPDATE_DEPARTMENT_REQUEST="UPDATE_DEPARTMENT_REQUEST";
export const UPDATE_DEPARTMENT_SUCCESS="UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE="UPDATE_DEPARTMENT_FAILURE";