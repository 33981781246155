import {
    UPDATE_LOGIN_FORM_DATA, UPDATE_FORGOT_PASSWORD_DATA, UPDATE_RESET_PASSWORD_DATA,
    HANDLE_LOGIN_REQUEST, HANDLE_LOGIN_SUCCESS, HANDLE_LOGIN_FAILURE, USER_LOGOUT_REQUEST, USER_LOGOUT_SUCCESS, USER_LOGOUT_FAILURE,
    RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, RESET_PASSWORD_INVITE_REQUEST, RESET_PASSWORD_INVITE_SUCCESS, RESET_PASSWORD_INVITE_FAILURE,
    UPDATE_CURRENT_STEP, ADD_EDUCATION, REMOVE_EDUCATION,ADD_MORE_CETIFICATE, REMOVE_CETIFICATE,
    HANDLE_UPDATE_MEMBER_DATA, MEMBER_REGISTRATION_REQUEST, MEMBER_REGISTRATION_SUCCESS, MEMBER_REGISTRATION_FAILURE, HANDLE_UPDATE_INTERN_INFO,
    HANDLE_UPDATE_PERSONAL_INFO,HANDLE_UPDATE_ADDRESS_INFO, HANDLE_UPDATE_EDUCATION_INFO, HANDLE_UPDATE_EMERGENCY_INFO,INTERN_REGISTRATION_REQUEST, INTERN_REGISTRATION_SUCCESS, INTERN_REGISTRATION_FAILURE,
    UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE, HANDLE_UPDATE_OTHER_INFO, SAVE_REDIRECT_LINK, CHANGE_ADDRESS_TYPE,
    UPLOAD_PROFILE_IMAGE_REQUEST, UPLOAD_PROFILE_IMAGE_SUCCESS, UPLOAD_PROFILE_IMAGE_FAILURE, DEFAULT_LOGIN_STATE, UPLOAD_CERTIFICATE_REQUEST, UPLOAD_CERTIFICATE_SUCCESS, UPLOAD_CERTIFICATE_FAILURE,
    DELETE_FILE_REQUEST, DELETE_FILE_SUCCESS, DELETE_FILE_FAILURE, DELETE_DEGREE_REQUEST, DELETE_DEGREE_SUCCESS, DELETE_DEGREE_FAILURE, DELETE_CERTIFICATE_REQUEST, DELETE_CERTIFICATE_SUCCESS, DELETE_CERTIFICATE_FAILURE,
    DELETE_MARKSHEET_REQUEST, DELETE_MARKSHEET_SUCCESS, DELETE_MARKSHEET_FAILURE,  MEMBER_ADMIN_REGISTRATION_REQUEST, MEMBER_ADMIN_REGISTRATION_SUCCESS, MEMBER_ADMIN_REGISTRATION_FAILURE,HANDLE_UPDATE_MEMBER_ADMIN_DATA
} from '../../constants/auth/AuthConstants';

const initialState = {
   currentStep:1,
   loginFormData : {
       "email":'',
       "password": ''
   },
   forgotPasswordData:{
       "email":''
   },
   resetPasswordData:{
       token:"",
       password:""
   },
   forgotPasswordSuccessMsg: '', 
   forgotPasswordErrorMsg: '',
   loginErrorMessage: '',
   loginSuccessMessage: '',
   isPageLoading: '',
   isAuthenticated: localStorage.getItem('token') ? true : false, 
   roleId:localStorage.getItem('role'),
   userName:localStorage.getItem('name'),
   profilePicture:localStorage.getItem('profile'),
   redirectLink: '',
   errorMessage: '',
    successMessage: '',
    memberRegistrationMessage: '',
    internRegistrationMessage:'',
    
    memberSignupData : {
        "token": '',
        "name": '',
        "contact": '',
        "password": '',
        'confirmPassword':''
    },
      personalInfo:{
        "fathers_name": "",
        "mothers_name": "",
        "gender": "",
        "dateofbirth": "",
        "blood_group": "",
        "marital_status": "",
        "spouse_name": "",
        "spouse_contact": "",
        "profile_img_link": ""
      },
      addressInfo:[{
        "address_type": "Current address",
        "address": "",
        "city": "",
        "district": "",
        "state": "",
        "pincode": 0,
        "country": ""
      },
      {
        "address_type": "Permanent address",
        "address": "",
        "city": "",
        "district": "",
        "state": "",
        "pincode": 0,
        "country": ""
      }],
      emergencyInfo: [
        {
          "contact_name": "",
          "contact_number": ""
        },
        {
        "contact_name": "",
        "contact_number": ""
          }]
      ,
      educationInfo: [
        {
          "name": "",
          "institution": "",
          "location": "",
          "year": "",
          "percentage": 0,
          "marksheet_link": [],
          "degree_link": ""
        }]
      ,
      otherDocumentsList: [
        {
          "name": "",
          "certificate_link": ""
        }]
    ,
      internInfo:{
        "personal_info": {
            "fathers_name": "",
            "mothers_name": "",
            "gender": "",
            "dateofbirth": "",
            "current_address": "",
            "permanent_address": "",
            "blood_group": "",
            "marital_status": "",
            "spouse_name": "",
            "spouse_contact": "",
            "profile_img_link": ""
          },
          "emergency_contact": 
          [
            {
              "contact_name": "",
              "contact_number": ""
            }
          ],
          "education": 
          [
            {
              "name": "",
              "institution": "",
              "location": "",
              "year": "",
              "percentage": 0,
              "marksheet_link": "",
              "degree_link": ""
            }
          ],
          "others": [
            {
              "name": "",
              "certificate_link": ""
            }
          ]
        },
    memberRegistrationSuccess: false,
    internRegistrationSuccess:false,

    uploadSuccessMsg:'',
    uploadErrorMsg:'',
    uploadFileSuccess:false, 
    previewLinks:'',
    uploadLink:'',
    isFileLoading:false,

    uploadProfileSuccess:false,
    uploadProfileSuccessMsg:'',
    uploadProfileErrorMsg:'', 
    isProfileLoading:false,
    uploadedImageURL: '',
    profileUploadLink:'',
    deleteFileSuccess: false,
    deleteFileSuccessMsg:'',
    deleteFileError:'',
    deleteDegreeSuccess: false,
    deleteDegreeSuccessMsg:'',
    deleteDegreeError:'',
    deleteCertificateSuccess: false,
    deleteCertificateSuccessMsg:'', 
    deleteCertificateError:'',
    deleteMarksheetSuccess: false, 
    deleteMarksheetSuccessMsg:'', 
    deleteMarksheetError:'',

    memberAdminSignupData : {
        "workspace":'',
        "token": '',
        "name": '',
        "contact": '',
        "password": '',
        'confirmPassword':''
      },
      memberAdminRegistrationMessage: '',
      memberAdminRegistrationSuccess: false
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {

        case SAVE_REDIRECT_LINK:
        return Object.assign({}, state, {
            redirectLink: action.link
        });
        case HANDLE_UPDATE_MEMBER_ADMIN_DATA:
            return Object.assign({}, state,{
                memberAdminSignupData: action.memberData
            });
        case MEMBER_ADMIN_REGISTRATION_REQUEST:
            return Object.assign({}, state, {
                isPageLoading: true,
                memberAdminRegistrationMessage:'',
                memberAdminRegistrationSuccess : false
            });

        case MEMBER_ADMIN_REGISTRATION_SUCCESS:   
                localStorage.setItem('token', action.response.data.token);
                localStorage.setItem('expire', action.response.data.expire);
                localStorage.setItem('created', Date.now());
                localStorage.setItem('account_type', action.response.data.role_id);
                localStorage.setItem('role', action.response.data.role_id);
                localStorage.setItem('loginTime', new Date().toISOString());
                localStorage.setItem('email', action.response.data.email);
                localStorage.setItem('name', action.response.data.name);
            return Object.assign({}, state, {
                isPageLoading: false,
                memberAdminRegistrationMessage:'',
                memberAdminRegistrationSuccess : true
            });
        case MEMBER_ADMIN_REGISTRATION_FAILURE:
            return Object.assign({}, state, {
                isPageLoading: false,
                memberAdminRegistrationMessage:action.response.data.message,
                memberAdminRegistrationSuccess: false
            });
        case UPDATE_CURRENT_STEP:
            return Object.assign({}, state, {
                currentStep:action.newStep
            })
        
        case UPDATE_LOGIN_FORM_DATA:
            return Object.assign({}, state, {
                loginFormData: action.newState
            });

        // login
        case HANDLE_LOGIN_REQUEST:
            return Object.assign({}, state, {
                isPageLoading: true,
                loginErrorMessage:'',
                loginSuccessMessage: ''
            });
        case HANDLE_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isPageLoading: false,
                isAuthenticated: true,
                loginSuccessMessage: action.user.message,
                loginErrorMessage: '',
                roleId: action.user.role_id,
                userName:action.user.name,
                profilePicture:action.user.link
            });
        case HANDLE_LOGIN_FAILURE:
            return Object.assign({}, state, {
                isPageLoading: false,
                loginSuccessMessage: '', 
                loginErrorMessage: action.data.message,
                isAuthenticated: false
            });

            case DEFAULT_LOGIN_STATE:
                    return Object.assign({}, state, {
                        isAuthenticated: localStorage.getItem('token') ? true : false,
                        loginForm: {
                            email: '',
                            password: ''
                        },
                        successMessage: '',
                        errorMessage: ''
                    });
        case USER_LOGOUT_REQUEST:           
            return Object.assign({}, state, {
                isPageLoading: true,    
                successMessage: ''
            });
        case USER_LOGOUT_SUCCESS:   
            localStorage.clear();
            sessionStorage.clear();
            return Object.assign({}, state, {
                isPageLoading: false,
                successMessage : '',
                logout:true,
                isAuthenticated:false,
            });
        case USER_LOGOUT_FAILURE:      
            return Object.assign({}, state, {
                isPageLoading: false,
                successMessage: '',             
                isAuthenticated:false
            });
        case UPDATE_FORGOT_PASSWORD_DATA:
            return Object.assign({}, state, {
                forgotPasswordData: action.newState,
            });
        case UPDATE_RESET_PASSWORD_DATA:
            return Object.assign({}, state, {
                resetPasswordData: action.resetData,
            });
        case RESET_PASSWORD_REQUEST:
            return Object.assign({}, state,{
                isPageLoading: true, forgotPasswordSuccessMsg: '', forgotPasswordErrorMsg: ''
            });
        case RESET_PASSWORD_SUCCESS:
            return Object.assign({}, state,{
                isPageLoading: false,
                forgotPasswordSuccessMsg: action.response.data.message,
                forgotPasswordErrorMsg: action.response.data.error === true ? action.response.data.message : ''
            });
        case RESET_PASSWORD_FAILURE:
            return Object.assign({}, state,{
                isPageLoading: false, forgotPasswordSuccessMsg: '', forgotPasswordErrorMsg: action.response.data.message
            });
       
        case RESET_PASSWORD_INVITE_REQUEST:
            return Object.assign({}, state,{
                isPageLoading: true, forgotInviteSuccessMsg: '', forgotInviteErrorMsg: ''
            });
        case RESET_PASSWORD_INVITE_SUCCESS:
            
            return Object.assign({}, state,{
                isPageLoading: false, 
                forgotInviteSuccessMsg: action.response.data.error === false ? action.response.data.message : '',
                forgotInviteErrorMsg:action.response.data.error === true ? action.response.data.message : ''
            });
        case RESET_PASSWORD_INVITE_FAILURE:
            return Object.assign({}, state,{
                isPageLoading: false, forgotInviteSuccessMsg: '', forgotInviteErrorMsg: action.response.data.message
            });

            case HANDLE_UPDATE_MEMBER_DATA:
              return Object.assign({}, state,{
                  memberSignupData: action.memberData
              });

              // member registration
          case MEMBER_REGISTRATION_REQUEST:
              return Object.assign({}, state, {
                  isPageLoading: true,
                  memberRegistrationMessage:'',
                  memberRegistrationSuccess : false
              });

          case MEMBER_REGISTRATION_SUCCESS:     
                localStorage.setItem('token', action.response.data.token);
                localStorage.setItem('expire', action.response.data.expire);
                localStorage.setItem('level', action.response.data.level);
                localStorage.setItem('role', action.response.data.role_id);
                localStorage.setItem('loginTime', new Date().toISOString());
                localStorage.setItem('name', action.response.data.name);
              return Object.assign({}, state, {
                  isPageLoading: false,
                  memberRegistrationMessage:'',
                  memberRegistrationSuccess : true,
                  roleId:action.response.data.role_id,
                  userName:action.response.data.name
              });
          case MEMBER_REGISTRATION_FAILURE:
              return Object.assign({}, state, {
                  isPageLoading: false,
                  memberRegistrationMessage:action.response.data.message,
                  memberRegistrationSuccess: false
              });
            case HANDLE_UPDATE_INTERN_INFO:
                return Object.assign({}, state,{
                    internInfo: action.internData
                });
            case HANDLE_UPDATE_PERSONAL_INFO:
                return Object.assign({}, state,{
                    personalInfo: action.internData
                });
            case HANDLE_UPDATE_ADDRESS_INFO:
                return Object.assign({}, state,{
                    addressInfo: action.internData
                });
            case CHANGE_ADDRESS_TYPE:
                return Object.assign({}, state,{
                    addressInfo:action.addressData
                })
            case HANDLE_UPDATE_EDUCATION_INFO:
                return Object.assign({}, state,{
                    educationInfo: action.internData
                });
            case HANDLE_UPDATE_EMERGENCY_INFO:
                return Object.assign({}, state,{
                    emergencyInfo: action.internData
                });
            case HANDLE_UPDATE_OTHER_INFO:
                return Object.assign({}, state,{
                    otherDocumentsList: action.internData
                });
                case INTERN_REGISTRATION_REQUEST:
                    return Object.assign({}, state, {
                        isPageLoading: true,
                        internRegistrationMessage:'',
                        internRegistrationSuccess : false
                    });
      
                case INTERN_REGISTRATION_SUCCESS:     
                      localStorage.setItem('profile', action.response.data.link)
                      localStorage.setItem('loginTime', new Date().toISOString());
                     
                    return Object.assign({}, state, {
                        isPageLoading: false,
                        internRegistrationMessage:action.response.data.message,
                        internRegistrationSuccess : true,
                        profilePicture:action.response.data.link
                    });
                case INTERN_REGISTRATION_FAILURE:
                    return Object.assign({}, state, {
                        isPageLoading: false,
                        internRegistrationMessage:action.response.data.message,
                        internRegistrationSuccess: false
                    });
                case ADD_EDUCATION:
                    return Object.assign({}, state, {
                        educationInfo:state.educationInfo.concat(action.education)
                    })
                case REMOVE_EDUCATION:
                    return Object.assign({}, state, {
                        educationInfo:action.education
                    })
                case ADD_MORE_CETIFICATE:
                    return Object.assign({}, state, {
                        otherDocumentsList:state.otherDocumentsList.concat(action.docsList)
                    })
                case REMOVE_CETIFICATE:
                    return Object.assign({}, state, {
                        otherDocumentsList:action.docsList
                    })
                case UPLOAD_FILE_REQUEST:
                    return Object.assign({}, state,{
                        uploadFileSuccess:false, uploadSuccessMsg:'', uploadFailureMsg:'', isFileLoading:true
                    })

                case UPLOAD_FILE_SUCCESS:
                    const name=action.response.data.name
                    if(name=="marksheet_link"){
                        return {...state, 
                            educationInfo:state.educationInfo.map((item, i) => (
                                i===action.response.data.index? {...item, [action.response.data.name]: action.response.data.uploadLink}: item
                            )),
                            uploadFileSuccess:true, uploadSuccessMsg:action.response.data.message, uploadFailureMsg:'',
                            previewLinks:action.response.data.previewLinks, uploadLink:action.response.data.uploadLink,
                            isFileLoading:false
                        }
                    }else{
                        return {...state, 
                            educationInfo:state.educationInfo.map((item, i) => (
                                i===action.response.data.index? {...item, [action.response.data.name]: action.response.data.uploadLink[0]}: item
                            )),
                            uploadFileSuccess:true, uploadSuccessMsg:action.response.data.message, uploadFailureMsg:'',
                            previewLinks:action.response.data.previewLinks, uploadLink:action.response.data.uploadLink,
                            isFileLoading:false
                        }
                    }
                    

                    
                case UPLOAD_FILE_FAILURE:
                    return Object.assign({}, state,{
                        uploadFileSuccess:false, uploadSuccessMsg:'', uploadFailureMsg:action.response.data.message,
                        isFileLoading:false
                    })

                    case UPLOAD_CERTIFICATE_REQUEST:
                        return Object.assign({}, state,{
                            uploadCertificateSuccess:false, uploadSuccessMsg:'', uploadFailureMsg:'', isFileLoading:true
                        })

                    case UPLOAD_CERTIFICATE_SUCCESS:
                    return {...state, 
                        otherDocumentsList:state.otherDocumentsList.map((item, i) => (
                        i===action.response.data.index? {...item, [action.response.data.name]: action.response.data.uploadLink[0]}: item
                    )),
                    uploadCertificateSuccess:true, uploadSuccessMsg:action.response.data.message, uploadFailureMsg:'',
                    previewLinks:action.response.data.previewLinks, uploadLink:action.response.data.uploadLink,
                    isFileLoading:false
                    }
    
                        
                    case UPLOAD_CERTIFICATE_FAILURE:
                        return Object.assign({}, state,{
                            uploadCertificateSuccess:false, uploadSuccessMsg:'', uploadFailureMsg:action.response.data.message,
                            isFileLoading:false
                        })

                case UPLOAD_PROFILE_IMAGE_REQUEST:
                        return Object.assign({}, state,{
                            uploadProfileSuccess:false, isProfileLoading:true
                        })
                case UPLOAD_PROFILE_IMAGE_SUCCESS:
                        return Object.assign({}, state,{
                            uploadProfileSuccess:true, uploadProfileSuccessMsg:action.response.data.message, uploadProfileErrorMsg:'', isProfileLoading:false,
                            uploadedImageURL:Object.values(action.response.data.previewLinks)[0], profileUploadLink:action.response.data.uploadLink
                        })
                case UPLOAD_PROFILE_IMAGE_FAILURE:
                        return Object.assign({}, state,{
                            uploadProfileSuccess:false, uploadProfileErrorMsg:action.response.data.message, uploadProfileSuccessMsg:'', isProfileLoading:false
                        })
                case DELETE_FILE_REQUEST:
                    return Object.assign({}, state, {
                        deleteFileSuccess: false
                    })
                case DELETE_FILE_SUCCESS:
                    return Object.assign({}, state, {
                        deleteFileSuccess: true, deleteFileSuccessMsg:action.response.data.message, deleteFileError:''
                    })
                case DELETE_FILE_FAILURE:
                    return Object.assign({}, state, {
                        deleteFileSuccess: false, deleteFileSuccessMsg:'', deleteFileError:action.response.data.message
                    })
                case DELETE_DEGREE_REQUEST:
                    return Object.assign({}, state, {
                        deleteDegreeSuccess: false,
                    })
                case DELETE_DEGREE_SUCCESS:
                    return Object.assign({}, state, {
                    deleteDegreeSuccess: true, deleteDegreeSuccessMsg:action.response.data.message, deleteDegreeError:''
                    })
                case DELETE_DEGREE_FAILURE:
                    return Object.assign({}, state, {
                    deleteDegreeSuccess: false, deleteDegreeSuccessMsg:'', deleteDegreeError:action.response.data.message
                    })
                case DELETE_CERTIFICATE_REQUEST:
                    return Object.assign({}, state, {
                        deleteCertificateSuccess: false
                        })
                case DELETE_CERTIFICATE_SUCCESS:
                    return Object.assign({}, state, {
                        deleteCertificateSuccess: true, deleteCertificateSuccessMsg:action.response.data.message, deleteCertificateError:''
                        })
                case DELETE_CERTIFICATE_FAILURE:
                    return Object.assign({}, state, {
                        deleteCertificateSuccess: true, deleteCertificateSuccessMsg:'', deleteCertificateError:action.response.data.message
                        })
                case DELETE_MARKSHEET_REQUEST:
                    return Object.assign({}, state, {
                        deleteMarksheetSuccess: false, deleteMarksheetSuccessMsg:'', deleteMarksheetError:''
                        })
                case DELETE_MARKSHEET_SUCCESS:
                    return Object.assign({}, state, {
                    deleteMarksheetSuccess: true, deleteMarksheetSuccessMsg:action.response.data.message, deleteMarksheetError:''
                    })
                case DELETE_MARKSHEET_FAILURE:
                    return Object.assign({}, state, {
                        deleteMarksheetSuccess: false, deleteMarksheetSuccessMsg:'', deleteMarksheetError:action.response.data.message
                        })
        default:
            return state
    }
}
