import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import {
    updateDepartmentFields,
    handleCreateDepartment,
    clearDepartmentErrorData,
    clearDepartmentData,
} from '../../../actions/admin/departmentActions';
import createNotification from '../../../components/app/notification/createNotification';

class CreateDepartment extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.dispatch(clearDepartmentData());
    }
    handleChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
        }
        const newState = Object.assign(this.props.department, {
            [name]: value,
        });

        this.props.dispatch(updateDepartmentFields(newState));
    }
    handleSubmit(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let body = {
                name: this.props.department.name,
                short_name: this.props.department.short_name,
            };

            this.props.dispatch(handleCreateDepartment(body));
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                }
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.createDepartmentErrorMsg.length !== 0) {
            createNotification('error', nextProps.createDepartmentErrorMsg);
            nextProps.dispatch(clearDepartmentErrorData());
        }
        if (nextProps.createDepartmentSuccessMsg.length !== 0) {
            createNotification('success', nextProps.createDepartmentSuccessMsg);
            nextProps.history.push('/admin/department/list');
            nextProps.dispatch(clearDepartmentData());
        }
    }

    render() {
        return (
            <React.Fragment>
                <main className="main-content-wrapper">
                    <section className="dashboard-content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-left">
                                        <h2 className="content-header-title">
                                            Create Department
                                        </h2>
                                        <p className="content-header-description">
                                            Create departments to be assigned to
                                            interns.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-right dashboard-header-action-group"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="dashboard-body-wrapper">
                        <div className="container">
                            <div className="main-content-body">
                                <section className="content-section-wrapper">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-9 col-lg-9">
                                            <div className="invite-employee-form-outer content-section-card">
                                                <form
                                                    className="invite-employee-form"
                                                    noValidate={true}
                                                    id="invute-employee-form"
                                                    onSubmit={this.handleSubmit.bind(
                                                        this
                                                    )}
                                                >
                                                    <div class="invite-employee-form-item">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                <div class="form-group">
                                                                    <Input
                                                                        type="text"
                                                                        name="name"
                                                                        id="name"
                                                                        value={
                                                                            this
                                                                                .props
                                                                                .department
                                                                                .name
                                                                        }
                                                                        outerClass="input-ctrl"
                                                                        placeholder="Department Name"
                                                                        required={
                                                                            true
                                                                        }
                                                                        onChange={this.handleChange.bind(
                                                                            this
                                                                        )}
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        autoComplete={
                                                                            'off'
                                                                        }
                                                                        label="Department Name"
                                                                        className="form-control"
                                                                        outerClassName="signup-input-box"
                                                                        errorMessage="Please enter department name(Min 3 char.)."
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                <div class="form-group">
                                                                    <Input
                                                                        type="text"
                                                                        name="short_name"
                                                                        id="short_name"
                                                                        value={
                                                                            this
                                                                                .props
                                                                                .department
                                                                                .short_name
                                                                        }
                                                                        outerClass="input-ctrl"
                                                                        placeholder="Department Short Name"
                                                                        required={
                                                                            true
                                                                        }
                                                                        onChange={this.handleChange.bind(
                                                                            this
                                                                        )}
                                                                        minLength={
                                                                            2
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        autoComplete={
                                                                            'off'
                                                                        }
                                                                        label="Department Short Name"
                                                                        className="form-control"
                                                                        outerClassName="signup-input-box"
                                                                        errorMessage="Please enter department short name(Min 2 char.)."
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="xd-btn-group">
                                                            <Button
                                                                onClick={() =>
                                                                    this.props.history.push(
                                                                        '/admin/department/list'
                                                                    )
                                                                }
                                                                className="btn "
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button type="submit">
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { department, createDepartmentErrorMsg, createDepartmentSuccessMsg } =
        state.departmentReducer;
    return { department, createDepartmentErrorMsg, createDepartmentSuccessMsg };
}

export default withRouter(connect(mapStateToProps)(CreateDepartment));
