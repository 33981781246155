import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import createNotification from "../../../components/app/notification/createNotification";
import {getFeedbackFormList, setSelectedFeedbackFormData, deleteFeedbackForm, clearFeedbackData, clearAssignFeedbackData, clearCreateFeedbackData} from '../../../actions/employee/feedbackActions';
import NoDataFound from '../../../components/app/no-data-found/NoDataFound';
import moment from "moment";
import { Dialogbox } from 'xd-react-dialogbox';
import {BlockLoader} from 'xd-react-loader';
import userRoles from '../../../components/roles/roles';

class FeedbackFormList extends Component{
    constructor(props){
        super(props)
        this.state= { }
    }
    
    componentDidMount(){
        this.props.dispatch(getFeedbackFormList())
        this.props.dispatch(clearAssignFeedbackData());
        this.props.dispatch(clearCreateFeedbackData())
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.deleteFeedbackErrorMsg.length!==0){
            createNotification('error', nextProps.deleteFeedbackErrorMsg);
            nextProps.dispatch(clearFeedbackData())
        }
        if(nextProps.deleteFeedbackSuccessMsg.length!==0){
            createNotification('success', nextProps.deleteFeedbackSuccessMsg);
            nextProps.dispatch(clearFeedbackData())
        }
        if(nextProps.getFeedbackList){
                nextProps.dispatch(getFeedbackFormList())
        }
    }

    setSelectedFeedbackInfo(e, form){
        this.props.dispatch(setSelectedFeedbackFormData(form))
    }
    openDeleteDialog(e, id) {
		this.setState(state => {
			state.feedbackId = id;
			return state;
		})
		document.querySelector('#deleteDialog').classList.add('opened');
		document.querySelector('body').classList.add('has-dialog');
    }
    submitDeleteDialog(e) {
		const data = {
			formid:this.state.feedbackId
		}
		this.props.dispatch(deleteFeedbackForm(data, this.state.feedbackId))
		document.querySelector('#deleteDialog').classList.remove('opened');
		document.querySelector('body').classList.remove('has-dialog');
	}
	// closes the dialog
	closeDialogbox() {
		document.querySelector('#deleteDialog').classList.remove('opened');
		document.querySelector('body').classList.remove('has-dialog');
	}

render(){
    const {feedbackListErrorMsg, isFeedbackListLoading, feedbackSuccessMsg}=this.props
    return(
    <main className="main-content-wrapper">
        <section className="dashboard-content-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                            <h2 className="content-header-title">Feedback Forms</h2>
                            <p className="content-header-description">List of feedback forms.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-right"> 
                        <div>
                      {this.props.roleId!== userRoles.Reviewer &&
                      <Button onClick={() => this.props.history.push("/admin/feedback/create")} className="btn btn-icon icon-left">
                        <i className="fa fa-plus btn-icon"></i>
                        <span className="btn-text">Create Feedback Form</span>
                      </Button>
                      }
                    </div>                       
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="dashboard-body-wrapper">
            <div className="container">                   
                <div className="main-content-body">                        
                    <section className="content-section-wrapper">
                    {isFeedbackListLoading?(
                    <div className="page-section-loader">
                        <BlockLoader
                            loadingText="Loading Feedback Forms .."
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                    </div>
                    ):feedbackListErrorMsg?(
                       <NoDataFound title={feedbackListErrorMsg} />
                    ):feedbackSuccessMsg?(
                        <div className="feedback-form-wrapper"  >
                        {this.props.feedbackFormList.length!==0 ?
                        (
                        <ul className="custom-list-item-wrapper">
                            {this.props.feedbackFormList.map((form, index) =>(
                            <li className="custom-list-item">
                                <div className="custom-list-item-content">
                                    <div className="row">
                                        <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                                            <div className="custom-list-sub-item">
                                            <NavLink to={'/admin/feedback/details/'+form.slug} className="feedback-link" >
                                                <div className="custom-list-sub-item-content">
                                                <span className="custom-list-item-label">Name</span>
                                                    <p className="custom-list-item-value">
                                                    <b> {form.title}</b>
                                                    </p>
                                                </div>
                                                </NavLink>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                <span className="custom-list-item-label">Description</span>
                                                    <p className="custom-list-item-value">
                                                        {form.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                    <span className="custom-list-item-label">Created On</span>
                                                    <p className="custom-list-item-value">{form.created_at!==0?(moment(new Date(form.created_at * 1000)).format('L')):'N/A'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                    <span className="custom-list-item-label">Created By</span>
                                                    <p className="custom-list-item-value">{form.created_by?form.created_by:'N/A'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                {this.props.roleId!==userRoles.Reviewer ?
                                                    <p className="custom-list-item-value">
                                                    <NavLink to={`/admin/feedback/${form.slug}/assign`} style={{color:"black"}}>
                                                        <button type="button" className="custom-icon-button add-icon-btn feedback-form-action" 
                                                        onClick={(e)=>this.setSelectedFeedbackInfo(e, form)}
                                                        >
                                                            <i class="fa fa-user-plus" aria-hidden="true"></i>
                                                        </button></NavLink>
                                                        <button type="button" className="custom-icon-button close-icon-btn feedback-form-action" 
                                                        onClick={e => this.openDeleteDialog(e, form.slug)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                        <NavLink to={"/admin/feedback/update/"+form.slug} style={{color:"black"}}>
                                                        <button type="button"  className="custom-icon-button close-icon-btn feedback-form-action" 
                                                        >
                                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        </button>
                                                        </NavLink>
                                                    </p>:
                                                    <p className="custom-list-item-value">
                                                        <button type="button" disabled className="custom-icon-button add-icon-btn feedback-form-action" 
                                                        >
                                                            <i class="fa fa-user-plus" aria-hidden="true"></i>
                                                        </button>
                                                        <button type="button" disabled className="custom-icon-button close-icon-btn feedback-form-action" 
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                        <button type="button" disabled className="custom-icon-button close-icon-btn feedback-form-action" 
                                                        >
                                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        </button>
                                                    </p>}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <Dialogbox
                                        title="Delete Feedback Form"
                                        successButton="Delete"
                                        cancelButton="Cancel"
                                        submitDialog={e => this.submitDeleteDialog(e)}
                                        closeDialog={this.closeDialogbox}
                                        dialogBoxID="deleteDialog" >
                                            <p>Are you sure you want to delete this feedback form?</p>
										</Dialogbox>
                            </div>
                        </div>
                    </li>
                    ))}
            </ul>
            )
            :(
                <NoDataFound title="No feedback forms available" description="No feedback forms has been created yet"/>
            )}
                        </div>):(
                            <NoDataFound/>
                        )}
                    </section>
                </div>
            </div>
        </div>
    </main>
        )
    }
}

function mapStateToProps(state) {
    const {feedbackFormFieldsList, feedbackListSuccess,feedbackListErrorMsg, feedbackFormList, feedbackSuccessMsg, isFeedbackListLoading,
    getFeedbackList,deleteFeedbackErrorMsg, deleteFeedbackSuccessMsg}=state.feedbackReducer;
    const {roleId} = state.authReducer;
    return {roleId, feedbackFormFieldsList, feedbackListSuccess,feedbackListErrorMsg, feedbackFormList, feedbackSuccessMsg, isFeedbackListLoading, deleteFeedbackErrorMsg, deleteFeedbackSuccessMsg, getFeedbackList}
}

export default withRouter(connect(mapStateToProps)(FeedbackFormList))
