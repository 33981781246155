export const ADD_NEW_FROM_FIELDS="ADD_NEW_FROM_FIELDS";
export const REMOVE_FORM_FIELDS="REMOVE_FORM_FIELDS";
export const ADD_NEW_DROPDOWN_FIELD="ADD_NEW_DROPDOWN_FIELD";

export const ADD_NEW_DROPDOWN_OPTION="ADD_NEW_DROPDOWN_OPTION";
export const REMOVE_DROPDOWN_OPTION="REMOVE_DROPDOWN_OPTION";
export const UPDATE_FORM_DETAIL_DATA="UPDATE_FORM_DETAIL_DATA";

export const GET_FEEDBACK_FORM_LIST_REQUEST="GET_FEEDBACK_FORM_LIST_REQUEST";
export const GET_FEEDBACK_FORM_LIST_SUCCESS="GET_FEEDBACK_FORM_LIST_SUCCESS";
export const GET_FEEDBACK_FORM_LIST_FAILURE="GET_FEEDBACK_FORM_LIST_FAILURE";

export const SET_SELECTED_FEEDBACK_FORM="SET_SELECTED_FEEDBACK_FORM";

export const DELETE_FEEDBACK_FORM_REQUEST="DELETE_FEEDBACK_FORM_REQUEST";
export const DELETE_FEEDBACK_FORM_SUCCESS="DELETE_FEEDBACK_FORM_SUCCESS";
export const DELETE_FEEDBACK_FORM_FAILURE="DELETE_FEEDBACK_FORM_FAILURE";

export const CLEAR_FEEDBACK_FORM_DATA="CLEAR_FEEDBACK_FORM_DATA";

export const GET_FEEDBACK_FORM_DETAIL_REQUEST="GET_FEEDBACK_FORM_DETAIL_REQUEST";
export const GET_FEEDBACK_FORM_DETAIL_SUCCESS="GET_FEEDBACK_FORM_DETAIL_SUCCESS";
export const GET_FEEDBACK_FORM_DETAIL_FAILURE="GET_FEEDBACK_FORM_DETAIL_FAILURE";

export const UPDATE_FEEDBACK_FORM_DETAILS="UPDATE_FEEDBACK_FORM_DETAILS";

export const UPDATE_FEEDBACK_FORM_REQUEST="UPDATE_FEEDBACK_FORM_REQUEST";
export const UPDATE_FEEDBACK_FORM_SUCCESS="UPDATE_FEEDBACK_FORM_SUCCESS";
export const UPDATE_FEEDBACK_FORM_FAILURE="UPDATE_FEEDBACK_FORM_FAILURE";

export const ASSIGN_FEEDBACK_FORM_REQUEST="ASSIGN_FEEDBACK_FORM_REQUEST";
export const ASSIGN_FEEDBACK_FORM_SUCCESS="ASSIGN_FEEDBACK_FORM_SUCCESS";
export const ASSIGN_FEEDBACK_FORM_FAILURE="ASSIGN_FEEDBACK_FORM_FAILURE";

export const CLEAR_ASSIGN_FEEDBACK_FORM_DATA="CLEAR_ASSIGN_FEEDBACK_FORM_DATA";

export const EDIT_FORM_DIALOG_STATE="EDIT_FORM_DIALOG_STATE";
export const SET_FORM_FIELD_DATA="SET_FORM_FIELD_DATA";
export const UPDATE_FORM_FIELDS="UPDATE_FORM_FIELDS";

export const REASSIGN_FEEDBACK_FORM_REQUEST="REASSIGN_FEEDBACK_FORM_REQUEST";
export const REASSIGN_FEEDBACK_FORM_SUCCESS="REASSIGN_FEEDBACK_FORM_SUCCESS";
export const REASSIGN_FEEDBACK_FORM_FAILURE="REASSIGN_FEEDBACK_FORM_FAILURE";

export const CLEAR_REASSIGN_FORM_DATA="CLEAR_REASSIGN_FORM_DATA";

export const CREATE_FEEDBACK_FORM_REQUEST="CREATE_FEEDBACK_FORM_REQUEST";
export const CREATE_FEEDBACK_FORM_SUCCESS="CREATE_FEEDBACK_FORM_SUCCESS";
export const CREATE_FEEDBACK_FORM_FAILURE="CREATE_FEEDBACK_FORM_FAILURE";
export const CLEAR_CREATE_FEEDBACK_FORM_DATA="CLEAR_CREATE_FEEDBACK_FORM_DATA";

export const UPDATE_REMOVE_FIELDS="UPDATE_REMOVE_FIELDS";
export const CLEAR_UPDATE_FORM_DATA="CLEAR_UPDATE_FORM_DATA";
export const ADD_NEW_UPDATE_FIELD="ADD_NEW_UPDATE_FIELD";

export const UPDATE_FORM_DIALOG_CHANGE="UPDATE_FORM_DIALOG_CHANGE";
export const EDIT_UPDATE_FORM_FIELD="EDIT_UPDATE_FORM_FIELD";

export const ADD_FORM_FEEDBACK_DATA="ADD_FORM_FEEDBACK_DATA";
export const GET_MODULE_FEEDBACK_FORM_REQUEST="GET_MODULE_FEEDBACK_FORM_REQUEST";
export const GET_MODULE_FEEDBACK_FORM_SUCCESS="GET_MODULE_FEEDBACK_FORM_SUCCESS";
export const GET_MODULE_FEEDBACK_FORM_FAILURE="GET_MODULE_FEEDBACK_FORM_FAILURE";

export const SUBMIT_FEEDBACK_FORM_REQUEST="SUBMIT_FEEDBACK_FORM_REQUEST";
export const SUBMIT_FEEDBACK_FORM_SUCCESS="SUBMIT_FEEDBACK_FORM_SUCCESS";
export const SUBMIT_FEEDBACK_FORM_FAILURE="SUBMIT_FEEDBACK_FORM_FAILURE";
export const CLEAR_SUBMIT_FEEDBACK_DATA="CLEAR_SUBMIT_FEEDBACK_DATA";

export const GET_INTERN_FEEDBACK_LIST_REQUEST="GET_INTERN_FEEDBACK_LIST_REQUEST";
export const GET_INTERN_FEEDBACK_LIST_SUCCESS="GET_INTERN_FEEDBACK_LIST_SUCCESS";
export const GET_INTERN_FEEDBACK_LIST_FAILURE="GET_INTERN_FEEDBACK_LIST_FAILURE";

export const GET_INTERN_FEEDBACK_REQUEST="GET_INTERN_FEEDBACK_REQUEST";
export const GET_INTERN_FEEDBACK_SUCCESS="GET_INTERN_FEEDBACK_SUCCESS";
export const GET_INTERN_FEEDBACK_FAILURE="GET_INTERN_FEEDBACK_FAILURE";