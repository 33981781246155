import {GET_INTERN_REPORTS_LIST_REQUEST, GET_INTERN_REPORTS_LIST_SUCCESS, GET_INTERN_REPORTS_LIST_FAILURE,
    GET_BATCH_LIST_REQUEST, GET_BATCH_LIST_SUCCESS, GET_BATCH_LIST_FAILURE, ADD_MORE_REPORTS_FILTERS} from '../../constants/admin/ReportsConstants';

const initialState = {
    isReportsListLoading: false,
    reportsListSuccessMessage: '',
    reportsListErrorMessage:'',
    reportsList:[],
    reportsListCount:0,

    isBatchListLoading: false, 
    batchListSuccessMessage: '', 
    batchListErrorMessage: '', 
    batchList:[],

    filtersData:{
        batch:'',
        module:'',
        sortby:''
    }
}

export default function member(state = initialState, action) {
    switch (action.type) {
        case GET_INTERN_REPORTS_LIST_REQUEST:
            return Object.assign({}, state, {
                isReportsListLoading: true, reportsListCount:0, reportsListSuccessMessage: '', reportsListErrorMessage: '', reportsList:[], getReportsAfterLevelChange:false
            });
        case GET_INTERN_REPORTS_LIST_SUCCESS:
            return Object.assign({}, state, {
                isReportsListLoading: false, reportsListCount:action.response.data.count, reportsListSuccessMessage:action.response.data.message, reportsListErrorMessage: '', reportsList:action.response.data.list
            });
        case GET_INTERN_REPORTS_LIST_FAILURE:
            return Object.assign({}, state, {
                isReportsListLoading: false, reportsListCount:0, reportsListSuccessMessage: '', reportsListErrorMessage:action.response.data.message, reportsList:[]
            });
        case GET_BATCH_LIST_REQUEST:
            return Object.assign({}, state, {
                isBatchListLoading: true, batchListSuccessMessage: '', batchListErrorMessage: '', batchList:[]
            });
        case GET_BATCH_LIST_SUCCESS:
            return Object.assign({}, state, {
                isBatchListLoading: false, batchListSuccessMessage:action.response.data.message, batchListErrorMessage: '', batchList:action.response.data.list
            });
        case GET_BATCH_LIST_FAILURE:
            return Object.assign({}, state, {
                isBatchListLoading: false, batchListSuccessMessage: '', batchListErrorMessage: action.response.data.message, batchList:[]
            });
        case ADD_MORE_REPORTS_FILTERS:
            return Object.assign({}, state, {
                filtersData:action.data
            });
        default:
            return state
    }
}
        