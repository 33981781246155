import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DashboardSubHeader from './DashboardSubHeader';

class EmployeeDashboard extends Component {
    render() {
        return (
            <main className="main-content-wrapper employee-dashboard_wrapper">
                <DashboardSubHeader />
                <div className="dashboard-body-wrapper gry-fill-background">
                    <div className="dashboard-body-content-section">
                        <div className="container">
                            <div className="dashboard-over-view-wrapper">
                                <section className="dashboard-overview__grid row">
                                    <div className="dashboard-overview__grid__item col-12 col-sm-6">
                                        <div className="dashborad-overview__card">
                                            <div className="dashboard-overview__card__header">
                                                <h2>News Feeds</h2>
                                            </div>
                                            <div className="dashboard-overview__card__content">
                                                <div className="news-feeds__content">
                                                    <ul className="news-feeds__list">
                                                        <li>
                                                            <div className="news-feeds_item">
                                                                <div className="news-feeds_item__content">
                                                                    <div className="new-feed__date-time">
                                                                        <span>
                                                                            Saturday,
                                                                            01
                                                                            Aug
                                                                            2020
                                                                        </span>
                                                                    </div>
                                                                    <h3>
                                                                        XenonStack
                                                                        9th
                                                                        Foundation
                                                                        Day
                                                                    </h3>
                                                                    <p>
                                                                        Celebrating
                                                                        9th
                                                                        Foundation
                                                                        Day, A
                                                                        Significant
                                                                        Milestone
                                                                        in the
                                                                        Journey
                                                                        of
                                                                        building
                                                                        and
                                                                        Growth
                                                                        of
                                                                        organization
                                                                        and
                                                                        Serving
                                                                        Clients
                                                                        Across
                                                                        the
                                                                        Globe.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="news-feeds_item">
                                                                <div className="news-feeds_item__content">
                                                                    <div className="new-feed__date-time">
                                                                        <span>
                                                                            Saturday,
                                                                            01
                                                                            Aug
                                                                            2019
                                                                        </span>
                                                                    </div>
                                                                    <h3>
                                                                        XenonStack
                                                                        8th
                                                                        Foundation
                                                                        Day
                                                                    </h3>
                                                                    <p>
                                                                        Celebrating
                                                                        8th
                                                                        Foundation
                                                                        Day, A
                                                                        Significant
                                                                        Milestone
                                                                        in the
                                                                        Journey
                                                                        of
                                                                        building
                                                                        and
                                                                        Growth
                                                                        of
                                                                        organization
                                                                        and
                                                                        Serving
                                                                        Clients
                                                                        Across
                                                                        the
                                                                        Globe.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dashboard-overview__grid__item col-12 col-sm-6">
                                        <div className="dashborad-overview__card">
                                            <div className="dashboard-overview__card__header">
                                                <h2>Useful Resources</h2>
                                            </div>
                                            <div className="dashboard-overview__card__content">
                                                <div className="news-feeds__content">
                                                    <ul className="news-feeds__list">
                                                        <li>
                                                            <div className="news-feeds_item">
                                                                <div className="news-feeds_item__content">
                                                                    <div className="new-feed__date-time">
                                                                        <span>
                                                                            Saturday,
                                                                            01
                                                                            Aug
                                                                            2020
                                                                        </span>
                                                                    </div>
                                                                    <h3>
                                                                        XenonStack
                                                                        9th
                                                                        Foundation
                                                                        Day
                                                                    </h3>
                                                                    <p>
                                                                        Celebrating
                                                                        9th
                                                                        Foundation
                                                                        Day, A
                                                                        Significant
                                                                        Milestone
                                                                        in the
                                                                        Journey
                                                                        of
                                                                        building
                                                                        and
                                                                        Growth
                                                                        of
                                                                        organization
                                                                        and
                                                                        Serving
                                                                        Clients
                                                                        Across
                                                                        the
                                                                        Globe.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="news-feeds_item">
                                                                <div className="news-feeds_item__content">
                                                                    <div className="new-feed__date-time">
                                                                        <span>
                                                                            Saturday,
                                                                            01
                                                                            Aug
                                                                            2019
                                                                        </span>
                                                                    </div>
                                                                    <h3>
                                                                        XenonStack
                                                                        8th
                                                                        Foundation
                                                                        Day
                                                                    </h3>
                                                                    <p>
                                                                        Celebrating
                                                                        8th
                                                                        Foundation
                                                                        Day, A
                                                                        Significant
                                                                        Milestone
                                                                        in the
                                                                        Journey
                                                                        of
                                                                        building
                                                                        and
                                                                        Growth
                                                                        of
                                                                        organization
                                                                        and
                                                                        Serving
                                                                        Clients
                                                                        Across
                                                                        the
                                                                        Globe.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    return {
        state,
    };
}

export default withRouter(connect(mapStateToProps)(EmployeeDashboard));
