export const GET_PROFILE_REQUEST="GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS="GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE="GET_PROFILE_FAILURE";

export const UPDATE_PROFILE_SUCCESS_STATUS = "UPDATE_PROFILE_SUCCESS_STATUS"

export const GET_PROFILE_LINK_REQUEST="GET_PROFILE_LINK_REQUEST";
export const GET_PROFILE_LINK_SUCCESS="GET_PROFILE_LINK_SUCCESS";
export const GET_PROFILE_LINK_FAILURE="GET_PROFILE_LINK_FAILURE";

export const USER_PROFILE_PIC_REQUEST="USER_PROFILE_PIC_REQUEST";
export const USER_PROFILE_PIC_SUCCESS="USER_PROFILE_PIC_SUCCESS";
export const USER_PROFILE_PIC_FAILURE="USER_PROFILE_PIC_FAILURE";

export const UPDATE_PROFILE_FIELDS="UPDATE_PROFILE_FIELDS";
export const UPDATE_ADDRESS_FIELDS="UPDATE_ADDRESS_FIELDS"
export const UPDATE_EDUCATION_FIELDS="UPDATE_EDUCATION_FIELDS";
export const UPDATE_EMERGENCY_FIELDS="UPDATE_EMERGENCY_FIELDS";
export const UPDATE_CERTIFICATE_FIELDS="UPDATE_CERTIFICATE_FIELDS";
export const UPDATE_ACCOUNT_FIELDS="UPDATE_ACCOUNT_FIELDS";

export const UPDATE_PROFILE_REQUEST="UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS="UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE="UPDATE_PROFILE_FAILURE";

export const HANDLE_EDIT_EDUCATION_DIALOG_STATE = "HANDLE_EDIT_EDUCATION_DIALOG_STATE";
export const HANDLE_EDIT_PERSONAL_DIALOG_STATE="HANDLE_EDIT_PERSONAL_DIALOG_STATE";
export const HANDLE_EDIT_OTHER_DIALOG_STATE="HANDLE_EDIT_OTHER_DIALOG_STATE";
export const HANDLE_EDIT_EMERGENCY_DIALOG_STATE="HANDLE_EDIT_EMERGENCY_DIALOG_STATE";
export const HANDLE_EDIT_ADDRESS_DIALOG_STATE="HANDLE_EDIT_ADDRESS_DIALOG_STATE";
export const HANDLE_CHNAGE_PASSWORD_DIALOG_STATE = "HANDLE_CHNAGE_PASSWORD_DIALOG_STATE";
export const HANDLE_EDIT_ACCOUNT_DIALOG_STATE="HANDLE_EDIT_ACCOUNT_DIALOG_STATE";
export const HANDLE_EDIT_PROFILE_PICTURE_DIALOG_STATE="HANDLE_EDIT_PROFILE_PICTURE_DIALOG_STATE"

export const GET_PROFILE_STATUS_REQUEST="GET_PROFILE_STATUS_REQUEST";
export const GET_PROFILE_STATUS_SUCCESS="GET_PROFILE_STATUS_SUCCESS";
export const GET_PROFILE_STATUS_FAILURE="GET_PROFILE_STATUS_FAILURE";

export const CHANGE_PROFILE_IMAGE_REQUEST="CHANGE_PROFILE_IMAGE_REQUEST";
export const CHANGE_PROFILE_IMAGE_SUCCESS="CHANGE_PROFILE_IMAGE_SUCCESS";
export const CHANGE_PROFILE_IMAGE_FAILURE="CHANGE_PROFILE_IMAGE_FAILURE";

export const CHANGE_PASSWORD_REQUEST="CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS="CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE="CHANGE_PASSWORD_FAILURE";
export const UPDATE_PASSWORD_DATA="UPDATE_PASSWORD_DATA";
export const CLEAR_PASSWORD_CHANGE_DATA="CLEAR_PASSWORD_CHANGE_DATA";

export const UPLOAD_USER_FILE_FAILURE="UPLOAD_USER_FILE_FAILURE";
export const UPLOAD_USER_FILE_REQUEST="UPLOAD_USER_FILE_REQUEST";
export const UPLOAD_USER_FILE_SUCCESS="UPLOAD_USER_FILE_SUCCESS";
export const UPLOAD_USER_CERTIFICATE_REQUEST="UPLOAD_USER_CERTIFICATE_REQUEST";
export const UPLOAD_USER_CERTIFICATE_SUCCESS="UPLOAD_USER_CERTIFICATE_SUCCESS";
export const UPLOAD_USER_CERTIFICATE_FAILURE="UPLOAD_USER_CERTIFICATE_FAILURE"

export const ADD_USER_EDUCATION="ADD_USER_EDUCATION";
export const REMOVE_USER_EDUCATION_DETAIL="REMOVE_USER_EDUCATION_DETAIL";

export const SET_PERSONAL_INFO="SET_PERSONAL_INFO";
export const SET_ADDRESS_INFO="SET_ADDRESS_INFO";
export const SET_OTHER_INFO="SET_OTHER_INFO";
export const SET_EMERGENCY_INFO="SET_EMERGENCY_INFO";
export const SET_ACCOUNT_INFO="SET_ACCOUNT_INFO";
export const SET_EDUCATION_INFO="SET_EDUCATION_INFO";

export const DELETE_PROFILE_PICTURE_REQUEST="DELETE_PROFILE_PICTURE_REQUEST";
export const DELETE_PROFILE_PICTURE_SUCCESS="DELETE_PROFILE_PICTURE_SUCCESS";
export const DELETE_PROFILE_PICTURE_FAILURE="DELETE_PROFILE_PICTURE_FAILURE";

export const DELETE_USER_FILE_REQUEST="DELETE_USER_FILE_REQUEST";
export const DELETE_USER_FILE_SUCCESS="DELETE_USER_FILE_SUCCESS";
export const DELETE_USER_FILE_FAILURE="DELETE_USER_FILE_FAILURE";
export const DELETE_DOCUMENT_REQUEST="DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS="DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE="DELETE_DOCUMENT_FAILURE";

export const GET_USER_PROFILE_PICTURE_REQUEST="GET_USER_PROFILE_PICTURE_REQUEST";
export const GET_USER_PROFILE_PICTURE_SUCCESS="GET_USER_PROFILE_PICTURE_SUCCESS";
export const GET_USER_PROFILE_PICTURE_FAILURE="GET_USER_PROFILE_PICTURE_FAILURE";

export const ADD_NEW_USER_CERTIFICATE="ADD_NEW_USER_CERTIFICATE";
