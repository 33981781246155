import React, {Component} from 'react';
import {withRouter,Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from '../../../components/admin/header/Header';
import Drawer from '../../../components/admin/drawer';
import InviteEmployee from "./InviteEmployee";
import EmployeeListing from '../employee-listing/EmployeeListing';
import CreateModule from '../intern-modules/CreateModule';
import "./dist/style.css"
import UserProfile from '../employee-listing/UserProfile';
import moduleDetails from '../intern-modules/ModuleDetails';
import editModule from '../intern-modules/EditModule';
import ListAsignee from '../assign-module/AssigneeListing';
import ListReviewer from '../assign-reviewer/ReviewerListing';
import AssignDate from '../assign-module/AssignDate';
import inviteMember from '../members/invite/InviteMember';
import viewMember from '../members/view/View-member';
import ReviewerStatus from '../assign-reviewer/ReviewerStatus';
import AssignModuleStatus from '../assign-module/AssignModuleStatus';
import FeedbackFormList from '../feedback/FeedbackFormList';
import CreateFeedbackForm from '../feedback/CreateFeedbackForm';
import AssignFeedbackForm from '../feedback/AssignFeedbackForm';
import FeedbackFormDetails from '../feedback/FeedbackFormDetails';
import UpdateFeedbackForm from '../feedback/UpdateFeedbackForm';
import AssignFeedbackStatus from '../feedback/AssignFeedbackStatus';
import assignModuleFeedback from '../intern-modules/ReassignFeedback';
import ReviewInvite from '../review-meeting/ReviewInvite';
import AddFeedback from '../employee-listing/employee-details/AddFeedback';
import InternFeedbackDetails from '../employee-listing/employee-details/InternFeedbackDetails';
import ReportsList from '../reports/ReportsList';
import DepartmentList from '../departments/DepartmentList';
import CreateDepartment from '../departments/CreateDepartment';
import ModuleTabs from '../intern-modules/ModuleTabs';
import dashboard from '../dashboard-main-page/dashboard';

class EmployeeIndex extends Component {
    componentDidMount(){
        const role=localStorage.getItem('role')
        if(role==='user'){
            this.props.history.push('/employee/modules')
        }
    }
    render() {
        return (   
            <div className="dashboard-wrappper">                        
                <Drawer />
                <div className={this.props.toggleStatus ? "dashboard-content-body" : "dashboard-content-body closed"}>   
                    <Header />
                    <Switch>
                        <Route exact path="/admin/dashboard" component={dashboard}/>    
                        <Route exact path="/admin/employee/l1" component={EmployeeListing}/>
                        <Route exact path="/admin/employee/l2" component={EmployeeListing}/>
                        <Route exact path="/admin/employee/l3" component={EmployeeListing}/>
                        <Route exact path="/admin/employee/l1/:email" component={UserProfile}/>
                        <Route exact path="/admin/employee/l2/:email" component={UserProfile}/>
                        <Route exact path="/admin/employee/l3/:email" component={UserProfile}/>
                        <Route exact path="/admin/employee/l1/:email/:formid" component={AddFeedback}/> 
                        <Route exact path="/admin/employee/l1/:email/feedback/:id" component={InternFeedbackDetails}/>                       
                        <Route exact path="/admin/feedback" component={CreateFeedbackForm}/>
                        <Route exact path="/admin/feedback/create" component={CreateFeedbackForm} />
                        <Route exact path="/admin/feedback/list" component={FeedbackFormList} />
                        <Route exact path="/admin/feedback/details/:id" component={FeedbackFormDetails} />
                        <Route exact path="/admin/feedback/:id/assign" component={AssignFeedbackForm} />
                        <Route exact path="/admin/feedback/assign-feedback/status" component={AssignFeedbackStatus} />
                        <Route exact path="/admin/employee/list-module/:id/reassign" component={assignModuleFeedback} />
                        <Route exact path="/admin/feedback/update/:id" component={UpdateFeedbackForm} />
                        <Route exact path="/admin/employee/review-invite/:email" component={ReviewInvite}/>
                        <Route exact path="/admin/reports/list" component={ReportsList}/>
                        <Route exact path="/admin/employee" component={InviteEmployee}/>
                        <Route exact path="/admin/employee/invite" component={InviteEmployee}/>
                        <Route exact path="/admin/employee/list-module/:pageNumber" component={ModuleTabs}/>
                        <Route exact path="/admin/employee/create-module" component={CreateModule}/>
                        <Route exact path="/admin/employee/module-details/:moduleId" component={moduleDetails}/>
                        <Route exact path="/admin/employee/edit-details/:moduleId" component={editModule}/>
                        <Route exact path="/admin/employee/view-assignee" component={ListAsignee}/>
                        <Route exact path="/admin/employee/assign-reviewer"component={ListReviewer}/>
                        <Route exact path="/admin/employee/assign-reviewer/status"component={ReviewerStatus}/>
                        <Route exact path="/admin/employee/assign-date" component={AssignDate}/>
                        <Route exact path="/admin/employee/module-status" component={AssignModuleStatus}/>
                        <Route exact path="/admin/invite-member" component={inviteMember}/>
                        <Route exact path="/admin/invite-member/list" component={viewMember}/>
                        <Route exact path="/admin/department/list" component={DepartmentList}/>
                        <Route exact path="/admin/department/create" component={CreateDepartment}/>

                    </Switch>
                </div>
            </div>             
        )
    }
}

function mapStateToProps(state) {
    const {toggleStatus} = state.appReducer;
    return {toggleStatus}
}

export default withRouter(connect(mapStateToProps)(EmployeeIndex))