import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/deadline-module.css';
import { internModuleDeadline } from '../../../actions/admin/adminDashboardActions';
class ModuleDeadline extends Component {
    componentWillMount() {
        this.props.dispatch(internModuleDeadline());
    }
    dropdown() {
        document
            .getElementsByClassName('panel-wrapper')[1]
            .classList.toggle('panel-wrapper-display');
        document
            .getElementsByClassName('down-arrow')[1]
            .classList.toggle('down-arrow-rotate');
    }
    render() {
        return (
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="panel panel-default card-view custom-panel pool-panel">
                    <div className="panel-heading">
                        <h5 className="panel-title">
                            Incomplete Module (Deadline Closed)
                        </h5>
                        <div>
                            <a
                                className="pull-left inline-block mr-15 txt-light collapsed"
                                data-toggle="collapse"
                                href="JavaScript:void(0"
                                aria-expanded="false"
                                onClick={this.dropdown.bind(this)}
                            >
                                <img
                                    src={require('../../../static/images/down-arrow.png')}
                                    className="zmdi zmdi-chevron-down down-arrow"
                                />{' '}
                            </a>
                        </div>
                    </div>
                    <div id="candidates" className="panel-wrapper collapse in">
                        <div className="panel-body">
                            <div className="table-wrap fix-height">
                                <div className="table-responsive">
                                    <table
                                        id="myTable1"
                                        className="table display table-hover pb-30"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="column2">
                                                    Email
                                                </th>
                                                <th className="column2">
                                                    Level
                                                </th>
                                                <th className="column2">
                                                    Module Name
                                                </th>
                                                <th className="column2">
                                                    Added By
                                                </th>
                                                <th className="column2">
                                                    Duration
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!this.props
                                                .internsModuleDeadlineError ? (
                                                this.props
                                                    .internsModuleDeadlineCount !==
                                                0 ? (
                                                    this.props.internsModuleDeadlineData.map(
                                                        (internsData, i) => (
                                                            <tr>
                                                                <td>
                                                                    <a
                                                                        href={`/onboarding/admin/employee/${internsData.level}/${internsData.userid} `}
                                                                        className="collapsed"
                                                                        aria-expanded="false"
                                                                    >
                                                                        {internsData.userid !==
                                                                            undefined &&
                                                                        internsData.userid !==
                                                                            ' '
                                                                            ? internsData.userid
                                                                            : 'NA'}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    {internsData.level !==
                                                                        undefined &&
                                                                    internsData.level !==
                                                                        ''
                                                                        ? internsData.level
                                                                        : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {internsData.name !==
                                                                        undefined &&
                                                                    internsData.name !==
                                                                        ''
                                                                        ? internsData.name
                                                                        : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {internsData.added_by !==
                                                                        undefined &&
                                                                    internsData.added_by !==
                                                                        ''
                                                                        ? internsData.added_by
                                                                        : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {internsData.duration !==
                                                                        undefined &&
                                                                    internsData.duration !==
                                                                        ''
                                                                        ? internsData.duration
                                                                        : 'NA'}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <div className="internMessage">
                                                        <p>No User Available</p>
                                                    </div>
                                                )
                                            ) : (
                                                this.props
                                                    .internsModuleDeadlineError && (
                                                    <div className="internMessage">
                                                        <p>No Modules Assigned
                                                            Yet</p>
                                                    </div>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const {
        internsModuleDeadlineError,
        internsModuleDeadlineData,
        internsModuleDeadlineCount,
        internsModuleDeadlineMessage
    } = state.adminDashboardReducer;

    return {
        internsModuleDeadlineError,
        internsModuleDeadlineData,
        internsModuleDeadlineCount,
        internsModuleDeadlineMessage
    };
};
export default withRouter(connect(mapStateToProps)(ModuleDeadline));
