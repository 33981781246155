import {
    GET_RECENTLY_JOINED_INTERNS_REQUEST,
    GET_RECENTLY_JOINED_INTERNS_SUCCESS,
    GET_RECENTLY_JOINED_INTERNS_FAILURE,
    GET_NOT_JOINED_INTERNS_REQUEST,
    GET_NOT_JOINED_INTERNS_SUCCESS,
    GET_NOT_JOINED_INTERNS_FAILURE,
    GET_INTERN_MODULE_PROGRESS_REQUEST,
    GET_INTERN_MODULE_PROGRESS_SUCCESS,
    GET_INTERN_MODULE_PROGRESS_FAILURE,
    GET_INTERN_MODULE_DEADLINE_REQUEST,
    GET_INTERN_MODULE_DEADLINE_SUCCESS,
    GET_INTERN_MODULE_DEADLINE_FAILURE,
} from '../../constants/admin/adminDashboardConstants';

const initialState = {
    recentlyJoinedInternsPageLoading: false,
    recentlyJoinedInternsMessage: '',
    recentlyJoinedInternsError: '',
    internsList: [],
    recentlyJoinedInternsCount: 0,

    notJoinedInternsPageLoading: false,
    notJoinedInternsMessage: '',
    notJoinedInternsError: '',
    internsData: [],
    notJoinedInternsCount: 0,

    internsModuleProgressPageLoading: false,
    internsModuleProgressMessage: '',
    internsModuleProgressError: '',
    internsModuleProgressData: [],
    internsModuleProgressCount: 0 ,

    internsModuleDeadlinePageLoading: false,
    internsModuleDeadlineMessage: '',
    internsModuleDeadlineError: '',
    internsModuleDeadlineData: [],
    internsModuleDeadlineCount:0,

};

export default function adminDashboardReducer(state = initialState, action) {
    switch (action.type) {
        case GET_RECENTLY_JOINED_INTERNS_REQUEST:
            return Object.assign({}, state, {
                recentlyJoinedInternsPageLoading: true,
                recentlyJoinedInternsError: '',
                recentlyJoinedInternsMessage: '',
            });
        case GET_RECENTLY_JOINED_INTERNS_SUCCESS:
            return Object.assign({}, state, {
                recentlyJoinedInternsPageLoading: false,
                recentlyJoinedInternsError: '',
                recentlyJoinedInternsMessage: action.response.data.message,
                internsList: action.response.data.user_list,
                recentlyJoinedInternsCount: action.response.data.total_users,
            });
        case GET_RECENTLY_JOINED_INTERNS_FAILURE:
            return Object.assign({}, state, {
                recentlyJoinedInternsPageLoading: false,
                recentlyJoinedInternsError: true,
                recentlyJoinedInternsMessage: action.response.data.message,
            });

        case GET_NOT_JOINED_INTERNS_REQUEST:
            return Object.assign({}, state, {
                notJoinedInternsPageLoading: true,
                notJoinedInternsError: '',
                notJoinedInternsMessage: '',
            });
        case GET_NOT_JOINED_INTERNS_SUCCESS:
            return Object.assign({}, state, {
                notJoinedInternsPageLoading: false,
                notJoinedInternsError: '',
                notJoinedInternsMessage: action.response.data.message,
                internsData: action.response.data.user_list,
                notJoinedInternsCount: action.response.data.total_users,
            });
        case GET_NOT_JOINED_INTERNS_FAILURE:
            return Object.assign({}, state, {
                notJoinedInternsPageLoading: false,
                notJoinedInternsError: true,
                notJoinedInternsMessage: action.response.data.message,
            });

        case GET_INTERN_MODULE_PROGRESS_REQUEST:
            return Object.assign({}, state, {
                internsModuleProgressPageLoading: true,
                internsModuleProgressError: '',
                internsModuleProgressMessage: '',
            });
        case GET_INTERN_MODULE_PROGRESS_SUCCESS:
            return Object.assign({}, state, {
                internsModuleProgressPageLoading: false,
                internsModuleProgressError: '',
                internsModuleProgressMessage: action.response.data.message,
                internsModuleProgressData: action.response.data.list,
                internsModuleProgressCount:action.response.data.count
            });
        case GET_INTERN_MODULE_PROGRESS_FAILURE:
            return Object.assign({}, state, {
                internsModuleProgressPageLoading: false,
                internsModuleProgressError: true,
                internsModuleProgressMessage: action.response.data.message,
            });
        case GET_INTERN_MODULE_DEADLINE_REQUEST:
            return Object.assign({}, state, {
                internsModuleDeadlinePageLoading: true,
                internsModuleDeadlineMessage: '',
                internsModuleDeadlineError: '',
            });
        case GET_INTERN_MODULE_DEADLINE_SUCCESS:
            return Object.assign({}, state, {
                internsModuleDeadlinePageLoading: true,
                internsModuleDeadlineMessage: action.response.data.message,
                internsModuleDeadlineError:action.response.data.error,
                internsModuleDeadlineData: action.response.data.list,
                internsModuleDeadlineCount: action.response.data.count,
            });
        case GET_INTERN_MODULE_DEADLINE_FAILURE:
            return Object.assign({}, state, {
                internsModuleDeadlinePageLoading: true,
                internsModuleDeadlineMessage: action.response.data.message,
                internsModuleDeadlineError: true,
                internsModuleDeadlineData: [],
            });
        default:
            return state;
    }
}
