import {
    UPDATE_LOGIN_FORM_DATA,
    UPDATE_FORGOT_PASSWORD_DATA,
    UPDATE_RESET_PASSWORD_DATA,
    HANDLE_LOGIN_REQUEST,
    HANDLE_LOGIN_SUCCESS,
    HANDLE_LOGIN_FAILURE,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    USER_LOGOUT_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_INVITE_REQUEST,
    RESET_PASSWORD_INVITE_SUCCESS,
    RESET_PASSWORD_INVITE_FAILURE,
    UPDATE_CURRENT_STEP,
    ADD_EDUCATION,
    REMOVE_EDUCATION,
    ADD_MORE_CETIFICATE,
    REMOVE_CETIFICATE,
    HANDLE_UPDATE_OTHER_INFO,
    HANDLE_UPDATE_MEMBER_DATA,
    MEMBER_REGISTRATION_REQUEST,
    MEMBER_REGISTRATION_SUCCESS,
    MEMBER_REGISTRATION_FAILURE,
    HANDLE_UPDATE_INTERN_INFO,
    HANDLE_UPDATE_PERSONAL_INFO,
    HANDLE_UPDATE_EDUCATION_INFO,
    HANDLE_UPDATE_ADDRESS_INFO,
    HANDLE_UPDATE_EMERGENCY_INFO,
    INTERN_REGISTRATION_REQUEST,
    INTERN_REGISTRATION_SUCCESS,
    INTERN_REGISTRATION_FAILURE,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    GET_UPLOADED_FILE_REQUEST,
    GET_UPLOADED_FILE_SUCCESS,
    GET_UPLOADED_FILE_FAILURE,
    SAVE_REDIRECT_LINK,
    DEFAULT_LOGIN_STATE,
    UPLOAD_PROFILE_IMAGE_REQUEST,
    UPLOAD_PROFILE_IMAGE_SUCCESS,
    UPLOAD_PROFILE_IMAGE_FAILURE,
    UPLOAD_CERTIFICATE_REQUEST,
    UPLOAD_CERTIFICATE_SUCCESS,
    UPLOAD_CERTIFICATE_FAILURE,
    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_FAILURE,
    DELETE_DEGREE_REQUEST,
    DELETE_DEGREE_SUCCESS,
    DELETE_DEGREE_FAILURE,
    DELETE_CERTIFICATE_REQUEST,
    DELETE_CERTIFICATE_SUCCESS,
    DELETE_CERTIFICATE_FAILURE,
    DELETE_MARKSHEET_REQUEST,
    DELETE_MARKSHEET_SUCCESS,
    DELETE_MARKSHEET_FAILURE,
    MEMBER_ADMIN_REGISTRATION_REQUEST,
    MEMBER_ADMIN_REGISTRATION_SUCCESS,
    MEMBER_ADMIN_REGISTRATION_FAILURE,
    HANDLE_UPDATE_MEMBER_ADMIN_DATA,
} from '../../constants/auth/AuthConstants';

import { POST_WITHOUT_TOKEN } from '../../middleware/postAPIWithoutToken';
import {
    GET_API,
    POST_API,
    PUT_API,
    DELETE_API,
} from '../../middleware/symbols';

import { getDocumentApi, accountApi } from '../ApiActions';

const AUTH_API = accountApi();
const DOCUMENT_API = getDocumentApi();

export function redirectLink(link) {
    return { type: SAVE_REDIRECT_LINK, link };
}

export function defaultLoginState() {
    return { type: DEFAULT_LOGIN_STATE };
}

export function updateCurrentStep(newStep) {
    return { type: UPDATE_CURRENT_STEP, newStep };
}

export function updateLoginForm(newState) {
    return { type: UPDATE_LOGIN_FORM_DATA, newState };
}

export function inviteAdminMemberRegistration(body) {
    return {
        [POST_WITHOUT_TOKEN]: {
            endpoint: AUTH_API + '/v1/admin-signup',
            types: [
                MEMBER_ADMIN_REGISTRATION_REQUEST,
                MEMBER_ADMIN_REGISTRATION_SUCCESS,
                MEMBER_ADMIN_REGISTRATION_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}

export function updateMemberAdminRegistrationData(memberData) {
    return { type: HANDLE_UPDATE_MEMBER_ADMIN_DATA, memberData };
}

export function handleUserLogin(creds) {
    let config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(creds),
    };
    return (dispatch) => {
        // We dispatch requestLogin to
        dispatch(requestLogin(creds));
        fetch(AUTH_API + '/v1/login', config)
            .then(function (response) {
                return response.json();
            })
            .then(
                function (user) {
                    if (user.error === true) {
                        dispatch(loginError(user));
                    } else {
                        localStorage.clear();
                        sessionStorage.clear();
                        localStorage.setItem('token', user.token);
                        localStorage.setItem('role', user.role_id);
                        localStorage.setItem('profile', user.link);
                        localStorage.setItem('name', user.name);
                        localStorage.setItem('created', Date.now());
                        localStorage.setItem('expire', user.expire);
                        localStorage.setItem(
                            'loginTime',
                            new Date().toISOString()
                        );
                        localStorage.setItem('email', creds.email);
                        dispatch(receiveLogin(user));
                    }
                },
                function (error) {
                    dispatch(
                        loginError({
                            error: true,
                            message: 'Internal Server Error',
                        })
                    );
                }
            );
    };
}

function requestLogin(creds) {
    return {
        type: HANDLE_LOGIN_REQUEST,
        creds,
    };
}

export function receiveLogin(user) {
    return {
        type: HANDLE_LOGIN_SUCCESS,
        user,
    };
}

function loginError(data) {
    return {
        type: HANDLE_LOGIN_FAILURE,
        data,
    };
}
export function logout() {
    const token = localStorage.getItem('token');
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return (dispatch) => {
        dispatch(logOutRequest());
        fetch(AUTH_API + '/v1/logout', config)
            .then(function (response) {
                return response.json();
            })
            .then(
                function (res) {
                    dispatch(logOutSuccess(res));
                },
                function (error) {
                    dispatch(logOutFailed(error));
                }
            );
    };
}

function logOutRequest() {
    return {
        type: USER_LOGOUT_REQUEST,
    };
}

function logOutSuccess(data) {
    return {
        type: USER_LOGOUT_SUCCESS,
        data,
    };
}
function logOutFailed(data) {
    return {
        type: USER_LOGOUT_FAILURE,
        data,
    };
}

export function updateForgotPasswordForm(newState) {
    return { type: UPDATE_FORGOT_PASSWORD_DATA, newState };
}

export function resetPasswordInvite(body) {
    return {
        [POST_WITHOUT_TOKEN]: {
            endpoint: AUTH_API + '/v1/resetpassword',
            authenticated: false,
            types: [
                RESET_PASSWORD_INVITE_REQUEST,
                RESET_PASSWORD_INVITE_SUCCESS,
                RESET_PASSWORD_INVITE_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}

export function updateResetPasswordForm(resetData) {
    return { type: UPDATE_RESET_PASSWORD_DATA, resetData };
}

export function resetPassword(body) {
    return {
        [PUT_API]: {
            endpoint: AUTH_API + '/v1/setpassword',
            authenticated: false,
            types: [
                RESET_PASSWORD_REQUEST,
                RESET_PASSWORD_SUCCESS,
                RESET_PASSWORD_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}

export function updateMemberRegistrationData(memberData) {
    return { type: HANDLE_UPDATE_MEMBER_DATA, memberData };
}

export function inviteMemberRegistration(body) {
    return {
        [POST_API]: {
            endpoint: AUTH_API + '/v1/signup',
            authenticated: false,
            types: [
                MEMBER_REGISTRATION_REQUEST,
                MEMBER_REGISTRATION_SUCCESS,
                MEMBER_REGISTRATION_FAILURE,
            ],
            body: body,
        },
    };
}

export function updateInternInfo(internData) {
    return { type: HANDLE_UPDATE_INTERN_INFO, internData };
}

export function updatePersonalInfo(internData) {
    return { type: HANDLE_UPDATE_PERSONAL_INFO, internData };
}
export function updateAddressInfo(internData) {
    return { type: HANDLE_UPDATE_ADDRESS_INFO, internData };
}

export function updateEducationInfo(internData) {
    return { type: HANDLE_UPDATE_EDUCATION_INFO, internData };
}
export function updateEmergencyInfo(internData) {
    return { type: HANDLE_UPDATE_EMERGENCY_INFO, internData };
}
export function updateOtherInfo(internData) {
    return { type: HANDLE_UPDATE_OTHER_INFO, internData };
}
export function internRegistration(body) {
    return {
        [POST_API]: {
            endpoint: AUTH_API + '/v1/register',
            authenticated: true,
            types: [
                INTERN_REGISTRATION_REQUEST,
                INTERN_REGISTRATION_SUCCESS,
                INTERN_REGISTRATION_FAILURE,
            ],
            body: body,
        },
    };
}

export function addEducation(education) {
    return { type: ADD_EDUCATION, education };
}

export function removeEducation(education) {
    return { type: REMOVE_EDUCATION, education };
}
export function addNewCertificate(docsList) {
    return { type: ADD_MORE_CETIFICATE, docsList };
}

export function removeCertificate(docsList) {
    return { type: REMOVE_CETIFICATE, docsList };
}

export function uploadFile(files, name, index) {
    let formData = new FormData();

    for (var file of files) {
        formData.append('files', file);
    }
    if (name == 'marksheet_link') {
        formData.set('type', 'marksheet');
    } else {
        formData.set('type', 'degree');
    }
    let status = '';
    const config = {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        body: formData,
    };
    return (dispatch) => {
        dispatch(uploadFileRequest());
        fetch(DOCUMENT_API + '/user/upload', config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            uploadFileSuccess({
                                data: {
                                    error: false,
                                    message: res.message,
                                    previewLinks: res.preview_links,
                                    uploadLink: res.uploadlink,
                                    name: name,
                                    index: index,
                                },
                                status: 200,
                            })
                        );
                    } else {
                        dispatch(
                            uploadFileFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        uploadFileFailure({
                            data: {
                                message: 'Error while updating file',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
function uploadFileRequest() {
    return {
        type: UPLOAD_FILE_REQUEST,
    };
}

function uploadFileSuccess(response) {
    return {
        type: UPLOAD_FILE_SUCCESS,
        response,
    };
}
function uploadFileFailure(response) {
    return {
        type: UPLOAD_FILE_FAILURE,
        response,
    };
}

export function uploadCertificate(file, name, index) {
    let formData = new FormData();
    formData.set('files', file);
    formData.set('type', 'cetificate');
    let status = '';
    const config = {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        body: formData,
    };
    return (dispatch) => {
        dispatch(uploadCertificateRequest());
        fetch(DOCUMENT_API + '/user/upload', config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            uploadCertificateSuccess({
                                data: {
                                    error: false,
                                    message: res.message,
                                    previewLinks: res.preview_links,
                                    uploadLink: res.uploadlink,
                                    name: name,
                                    index: index,
                                },
                                status: 200,
                            })
                        );
                    } else {
                        dispatch(
                            uploadCertificateFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        uploadCertificateFailure({
                            data: {
                                message: 'Error while updating certificate',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
function uploadCertificateRequest() {
    return {
        type: UPLOAD_CERTIFICATE_REQUEST,
    };
}

function uploadCertificateSuccess(response) {
    return {
        type: UPLOAD_CERTIFICATE_SUCCESS,
        response,
    };
}
function uploadCertificateFailure(response) {
    return {
        type: UPLOAD_CERTIFICATE_FAILURE,
        response,
    };
}

export function uploadProfileImage(file) {
    let formData = new FormData();
    formData.set('files', file);
    formData.set('type', 'profile');
    let status = '';
    const config = {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        body: formData,
    };
    return (dispatch) => {
        dispatch(uploadProfileRequest());
        fetch(DOCUMENT_API + '/user/upload', config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            uploadProfileSuccess({
                                data: {
                                    error: false,
                                    message: res.message,
                                    previewLinks: res.preview_links,
                                    uploadLink: res.uploadlink,
                                },
                                status: 200,
                            })
                        );
                    } else {
                        dispatch(
                            uploadProfileFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        uploadProfileFailure({
                            data: {
                                message: 'Error while updating profile image',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
function uploadProfileRequest() {
    return {
        type: UPLOAD_PROFILE_IMAGE_REQUEST,
    };
}

function uploadProfileSuccess(response) {
    return {
        type: UPLOAD_PROFILE_IMAGE_SUCCESS,
        response,
    };
}
function uploadProfileFailure(response) {
    return {
        type: UPLOAD_PROFILE_IMAGE_FAILURE,
        response,
    };
}

export function getUploadedFile(filename) {
    return {
        [GET_API]: {
            endpoint: DOCUMENT_API + '/getfile/' + filename,
            authenticated: true,
            types: [
                GET_UPLOADED_FILE_REQUEST,
                GET_UPLOADED_FILE_SUCCESS,
                GET_UPLOADED_FILE_FAILURE,
            ],
        },
    };
}

export function deleteFile(file, profileFile) {
    let status = '';
    const config = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    return (dispatch) => {
        dispatch(deleteFileRequest());
        fetch(DOCUMENT_API + '/delete/' + file, config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            deleteFileSuccess({
                                data: { error: false, message: res.message },
                                status: 200,
                            })
                        );
                        dispatch(uploadProfileImage(profileFile));
                    } else {
                        dispatch(
                            deleteFileFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        deleteFileFailure({
                            data: {
                                message: 'Error while updating file',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
export function deleteFileRequest() {
    return {
        type: DELETE_FILE_REQUEST,
    };
}
export function deleteFileSuccess(response) {
    return {
        type: DELETE_FILE_SUCCESS,
        response,
    };
}
export function deleteFileFailure(response) {
    return {
        type: DELETE_FILE_FAILURE,
        response,
    };
}

export function deleteDegree(files, name, index, file_name) {
    let status = '';
    const config = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    return (dispatch) => {
        dispatch(deleteDegreeRequest());
        fetch(DOCUMENT_API + '/delete/' + file_name, config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            deleteDegreeSuccess({
                                data: { error: false, message: res.message },
                                status: 200,
                            })
                        );
                        dispatch(uploadFile(files, name, index));
                    } else {
                        dispatch(
                            deleteDegreeFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        deleteDegreeFailure({
                            data: {
                                message: 'Error while updating file',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
export function deleteDegreeRequest() {
    return {
        type: DELETE_DEGREE_REQUEST,
    };
}
export function deleteDegreeSuccess(response) {
    return {
        type: DELETE_DEGREE_SUCCESS,
        response,
    };
}
export function deleteDegreeFailure(response) {
    return {
        type: DELETE_DEGREE_FAILURE,
        response,
    };
}

export function deleteMarksheet(marksheet) {
    return {
        [DELETE_API]: {
            endpoint: DOCUMENT_API + '/delete/' + marksheet,
            authenticated: true,
            types: [
                DELETE_MARKSHEET_REQUEST,
                DELETE_MARKSHEET_SUCCESS,
                DELETE_MARKSHEET_FAILURE,
            ],
        },
    };
}

export function deleteCertificate(file, name, index, file_name) {
    let status = '';
    const config = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    return (dispatch) => {
        dispatch(deleteCertificateRequest());
        fetch(DOCUMENT_API + '/delete/' + file_name, config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            deleteCertificateSuccess({
                                data: { error: false, message: res.message },
                                status: 200,
                            })
                        );
                        dispatch(uploadCertificate(file, name, index));
                    } else {
                        dispatch(
                            deleteCertificateFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        deleteCertificateFailure({
                            data: {
                                message: 'Error while updating file',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}

export function deleteCertificateRequest() {
    return {
        type: DELETE_CERTIFICATE_REQUEST,
    };
}
export function deleteCertificateSuccess(response) {
    return {
        type: DELETE_CERTIFICATE_SUCCESS,
        response,
    };
}
export function deleteCertificateFailure(response) {
    return {
        type: DELETE_CERTIFICATE_FAILURE,
        response,
    };
}
