const local = {
  app: {
    REACT_APP_BASE_URL:"https://xs-onboarding.neuralcompany.work/api",
    REACT_APP_WEBSITE_URL: "https://xs-onboarding.neuralcompany.work",
    
  }
};

const dev = {
  app: {
    REACT_APP_BASE_URL:"https://xs-onboarding.neuralcompany.work/api",
    REACT_APP_WEBSITE_URL: "https://careers.neuralcompany.work/",
    
  },
};

const uat = {
  app: {
    REACT_APP_BASE_URL:"https://xs-onboarding.neuralcompany.team/api",
    REACT_APP_WEBSITE_URL: "https://xenonstack.jobs/",
    
  },
};

const prod = {
  app: {
    REACT_APP_BASE_URL:"https://careers.xenonstack.com/onboarding/api",
    REACT_APP_WEBSITE_URL: "https://careers.xenonstack.com/",
    
  },
};

const config = process.env.REACT_APP_STAGE === 'production'  ? prod
             : process.env.REACT_APP_STAGE === 'development' ? dev
             : process.env.REACT_APP_STAGE === 'uat' ? uat
             : local;

export default {
  // Add common config values here
  ...config
};
