import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import _ from "lodash";
import createNotification from "../../../components/app/notification/createNotification";
import EditFormPopup from './EditFormPopup';
import FeedbackFormFields from './FeedbackFormFields';
import { addNewFormFields, handleRemoveFormFields, handleEdiFormDialogChange, setFormFieldData, updateFormDetailData, createNewFeedbackForm, clearCreateFeedbackData} from '../../../actions/employee/feedbackActions';
import { DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import userRoles from '../../../components/roles/roles'

class CreateFeedbackForm extends Component{
    constructor(props){
        super(props)
        this.state= { }
        this.handleEditFormFieldsInput=this.handleEditFormFieldsInput.bind(this)
        this.handleRemoveFormFieldsInput=this.handleRemoveFormFieldsInput.bind(this)
    }

    componentDidMount(){
        if(this.props.roleId===userRoles.Reviewer){
            this.props.history.push('/admin/feedback/list')
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.createFeedbackFormSuccessMsg.length!==0){
            createNotification('success', nextProps.createFeedbackFormSuccessMsg);
            nextProps.history.push('/admin/feedback/list')
            nextProps.dispatch(clearCreateFeedbackData())
          }
        if(nextProps.createFeedbackFormErrorMsg.length!==0){
            createNotification('error', nextProps.createFeedbackFormErrorMsg);
            nextProps.dispatch(clearCreateFeedbackData())
        }
    }
    handleAddNewTextInput(){
        let textInoutField = {
            "id":"short-answer-text",
            "name":"short-answer-text",
            "type":"text",
            "isRequired": false,
            "placeholder":"Short Answer text",
            'label':'Question?'
        }
        
        this.props.dispatch(addNewFormFields(textInoutField));
    }
    handleAddDropdownInput(){
        let dropdownInputField={
            "id":"select-option",
            "name":'select-option',
            "type":"select",
            "isRequired": false,
            "placeholder": "select option",
            "label": "Select",
            "options":[{"label":"option1", "value":0}, {"label":"option2", "value":1}, {"label":"option3", "value":2}]     
        }
        this.props.dispatch(addNewFormFields(dropdownInputField))
    }
    handleAddRadioInput(){
        let radioInputField={
            "id":"radio-input",
            "name":'radio-input',
            "type":"radio",
            "isRequired": false,
            "placeholder": "select option",
            "label": "Radio Input",
            "options":[{"label":"option1", "value":0}, {"label":"option2", "value":1}, {"label":"option3", "value":2}]     
        }
        this.props.dispatch(addNewFormFields(radioInputField))
    }
    handleAddTextarea(){
        let textareaField = {
            "id":"long-answer-text",
            "name":"long-answer-text",
            "type":"textarea",
            "isRequired": false,
            "placeholder":"Long Answer text",
            'label':'Question?'
        }
        
        this.props.dispatch(addNewFormFields(textareaField));
    }
    handleAddCheckbox(){
        let textareaField = {
            "id":"checkbox-input",
            "name":"check-box-input",
            "type":"checkbox",
            "isRequired": false,
            "placeholder":"Select option",
            'label':'Select any option.',
            "options":[{"label":"option1", "value":"option1"}, {"label":"option2", "value":"option2"}, {"label":"option3", "value":"option3"}]
        }
        
        this.props.dispatch(addNewFormFields(textareaField));
    }

    handleRemoveFormFieldsInput(index) {
        const fieldsList = this.props.feedbackFormFieldsList;
        fieldsList.splice(index, 1);
        this.props.dispatch(handleRemoveFormFields(fieldsList));
    }
    handleEditFormFieldsInput(formFields, index){
        document.querySelector('#body-root').style="overflow:hidden;"
        this.props.dispatch(handleEdiFormDialogChange(true))
        if(formFields !== ''){
            let data=_.cloneDeep(this.props.feedbackFormFieldsList)
            this.props.dispatch(setFormFieldData(data, index))
        }
    }
    handleCreateFeedbackForm(e){
        e.preventDefault();
        var name=document.getElementById("form_title");
        var description=document.getElementById("form-description")
    if (name.checkValidity() && description.checkValidity()) {
        const details={
            "title":this.props.formDetailData.title,
            "description":this.props.formDetailData.description,
            "outline": JSON.stringify(this.props.feedbackFormFieldsList)
        }
        this.props.dispatch(createNewFeedbackForm(details))
    }else{
        let allInvalidInput = document.querySelectorAll(
            ".form-group .form-control:invalid"
          );
          if (allInvalidInput.length > 0) {
            allInvalidInput[0].focus();
            for (let invalidInput of  allInvalidInput) {
              invalidInput.parentElement.classList.add("has-error");
              invalidInput.parentElement.parentElement.classList.add(
                "has-error"
              );
            }
          }
    }
    }
    handleOnChange(e){
        const newState = Object.assign(this.props.formDetailData, {
            [e.target.name]: e.target.value
            });
            this.props.dispatch(updateFormDetailData(newState))
          if (e.target.checkValidity()) {
            if(e.target.id=='form-description'){
                e.target.addEventListener("keypress", function(e) {
                    var k = e ? e.which : window.event.keyCode;
                    if (k == 32){
                        e.target.parentElement.classList.add("has-error");
                        e.target.parentElement.parentElement.classList.add("has-error");
                        return false;}else{e.target.parentElement.classList.remove("has-error");
                        e.target.parentElement.parentElement.classList.remove("has-error");}
                  });}else{
                e.target.parentElement.classList.remove("has-error");
                e.target.parentElement.parentElement.classList.remove("has-error");
            }
          } else {
            e.target.parentElement.classList.add("has-error");
            e.target.parentElement.parentElement.classList.add("has-error");
          }
    }

render(){
        const {feedbackFormFieldsList} = this.props
    
    return(
    <main className="main-content-wrapper">
        <section className="dashboard-content-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                            <h2 className="content-header-title">Feedback Form</h2>
                            <p className="content-header-description">Create a dynamic feedback form and reorder the fields.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-right">                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="dashboard-body-wrapper">
            <div className="container">                   
                <div className="main-content-body">                        
                    <section className="content-section-wrapper">
                        <div className="feedback-form-wrapper">
                            <div className="row">
                                <div class="col-12 col-sm-9">
                                    <div className="feedback-form-main-content">
                                        <div className="feedack-form-card card-box-shadow " >
                                                <fieldset className="feedack-form-card-content">
                                                <legend>Title & Description</legend>
                                                    <Input
                                                    type="text"
                                                    name="title"
                                                    id="form_title"
                                                    placeholder="Form Title"
                                                    value={this.props.formDetailData.title}
                                                    required={true}
                                                    minLength={3}
                                                    pattern={"([a-zA-Z]\.+\\s)*[a-zA-Z]+$"}
                                                    maxLength={50}
                                                    autoComplete={"off"}
                                                    className="form-control"
                                                    onChange={this.handleOnChange.bind(this)}
                                                    errorMessage="Please enter your form title(Min 3 char.)" 
                                                    />
                                                    <div className="form-group">
                                                        <div className="input-wrapper"> 
                                                            <textarea placeholder="Form Description"
                                                                    name="description"
                                                                    cols="20"
                                                                    rows="3"   
                                                                    required={true}  
                                                                    id="form-description"
                                                                    pattern={"([a-zA-Z]\.+\\s)*[a-zA-Z]+$"}
                                                                    value={this.props.formDetailData.description}
                                                                    minLength={3}
                                                                    maxLength={200}  
                                                                    onChange={this.handleOnChange.bind(this)}                                      
                                                                    className="form-control textarea-sm" >                                                 
                                                            </textarea>
                                                            <div className="help-block with-errors">
															Please enter form description(Min 3 char.)
															</div>
                                                        </div>                                                
                                                    </div>
                                                </fieldset>                                       
                                        </div>
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable  droppableId={'sdsdsds'}>
                                            {(provided) => (
                                        <div className="feedack-form-card card-box-shadow" 
                                        innerRef={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <Draggable draggableId="fields2" index="2">
                                                {(provided) => (
                                                <fieldset className="feedack-form-card-content"
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                innerRef={provided.innerRef}>
                                                <legend>Add Question</legend>
                                                {feedbackFormFieldsList.map((formField, index) => (
                                                    <>
                                                    <FeedbackFormFields 
                                                    formField={formField} 
                                                    index={index} 
                                                    removeHandler={this.handleRemoveFormFieldsInput}
                                                    editHandler={this.handleEditFormFieldsInput}/>
                                                </>
                                                ))}
                                                
                                                </fieldset>
                                                )}
                                                </Draggable>
                                        </div>
                                            )}
                                        </Droppable>
                                        </DragDropContext>
                                    </div>
                                </div>
                            <div class="col-12 col-sm-3">
                                <div className="feedback-form-toolbox-wrapper">
                                    <div className="feedback-form-toolbox">
                                        <h3 className="feedback-form-toolbox-title">Form Toolbox</h3>
                                        <ul className="feedback-form-toolbox-list">
                                            <li>
                                                <div className="form-toolbox-item" onClick={() => this.handleAddNewTextInput()}>
                                                    <i className="fa fa-font"></i>
                                                    <span>Text Input </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-toolbox-item" onClick={() => this.handleAddDropdownInput()}>
                                                    <i class="fa fa-caret-square-o-down"></i>
                                                    <span>Dropdown </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-toolbox-item" onClick={() => this.handleAddRadioInput()}>
                                                <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
                                                    <span>Radio Input </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-toolbox-item" onClick={() => this.handleAddTextarea()}>
                                                <i className="fa fa-font"></i>
                                                    <span>Textarea </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-toolbox-item" onClick={() => this.handleAddCheckbox()}>
                                                <i class="fa fa-check-square" aria-hidden="true"></i>
                                                    <span>Checkbox </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            </div>                           
                        </div>
                        <EditFormPopup />
                    </section>
                </div>
                
                <div className="xd-btn-group" style={{marginTop:"20px"}}>
                  <Button onClick={() => this.props.history.push("/admin/feedback/list")} >Back</Button>
                  <Button type="submit" onClick={(e) => this.handleCreateFeedbackForm(e)}>Create {this.props.createFeedbackFormLoading && <i className="fa fa-spin fa-spinner btn-icon"></i>}</Button>
                </div>
            </div>
        </div>
    </main>
        )
    }
}

function mapStateToProps(state) {
    const {isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink} = state.employeeReducer;
    const {feedbackFormFieldsList,formDetailData, createFeedbackFormLoading, createFeedbackFormSuccess, createFeedbackFormErrorMsg, createFeedbackFormSuccessMsg}=state.feedbackReducer;
    const {roleId} = state.authReducer;
    return {roleId, feedbackFormFieldsList, formDetailData, isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink, createFeedbackFormSuccess, createFeedbackFormLoading, createFeedbackFormErrorMsg, createFeedbackFormSuccessMsg}
}

export default withRouter(connect(mapStateToProps)(CreateFeedbackForm))
