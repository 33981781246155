import {
    GET_MEMBER_LIST_REQUEST,
    GET_MEMBER_LIST_SUCCESS,
    GET_MEMBER_LIST_FAILURE,
    CHANGE_DELETED_MEMBER,
    DELETE_MEMBER_REQUEST,
    DELETE_MEMBER_SUCCESS,
    DELETE_MEMBER_FAILURE,
    INVITE_MEMBER_REQUEST,
    INVITE_MEMBER_SUCCESS,
    INVITE_MEMBER_FAILURE,
    DEFAULT_MEMBER_STATE,
    CLEAR_MEMBER_DATA,
    HANDLE_UPDATE_MEMBER_LIST,
    ADD_MEMBER_INVITE
} from '../../constants/admin/memberConstant';

const initialState = {
    isPageLoading: false,
    errorMessage: '',
    successMessage: '',
    membersList: [],
    deletedMember: {},
    deleted: false,
    file: {},
    showInvite: false,
    searchQuery: '',
    sortedBy: '',
    isAsc: false,
    membersPerPage: 15,
    activePage: 1,
    membersDetail: {
        loginActivities: [],
        profile: {},
        deployments: {}
    },
    members: [
        { email: '', role: '' },
        { email: '', role: '' },
        { email: '', role: '' }
    ],
    memberAddedStatusList: {},
    resourcesDeleted: false,

    deleteMemberError:false,
    deleteMemberMessage:''
};
export default function member(state = initialState, action) {
    switch (action.type) {
        case GET_MEMBER_LIST_REQUEST:
            return Object.assign({}, state, {
                isPageLoading: true, successMessage: '', errorMessage: '',deleteMemberMessage:''
            });
        case GET_MEMBER_LIST_SUCCESS:
            const list1 = [], list2 = []; let list = [];
            for (let i = 0; i < action.response.data.list.length; i++) {
                if (action.response.data.list[i].email === localStorage.getItem('email')) {
                    list1.push(action.response.data.list[i]);
                } else {
                    list2.push(action.response.data.list[i]);
                }
            }
            list = list1.concat(list2);
            return Object.assign({}, state, {
                isPageLoading: false, membersList: list, deleted: false,
            });
        case GET_MEMBER_LIST_FAILURE:
            return Object.assign({}, state, {
                isPageLoading: false, errorMessage: action.response.data.message, membersList: []
            });
        case CHANGE_DELETED_MEMBER:
            return Object.assign({}, state, {
                deletedMember: action.member
            });

        case HANDLE_UPDATE_MEMBER_LIST:
            return Object.assign({}, state, {
                members: action.membersList
            });
        case DELETE_MEMBER_REQUEST:
            return Object.assign({}, state, {
                isPageLoading: true, deleteMemberMessage:'', deleteMemberError: false,
            });
        case DELETE_MEMBER_SUCCESS:
            return Object.assign({}, state, {
                isPageLoading: false, deleteMemberMessage: 'Member Deleted Successfully.', deleteMemberError: false,
            });
        case DELETE_MEMBER_FAILURE:
            return Object.assign({}, state, {
                isPageLoading: false, deleteMemberMessage: action.response.data.message, deleteMemberError: false,
            });



        case CLEAR_MEMBER_DATA:
            return Object.assign({}, state, {
                memberAddedStatusList: {},
                isPageLoading: false, successMessage: '', errorMessage: '',
                members: [
                    { email: '', role: '' },
                    { email: '', role: '' },
                    { email: '', role: '' }
                ]
            });
        case ADD_MEMBER_INVITE:
            return Object.assign({}, state, {
                members: state.members.concat(action.obj)
            });
        case INVITE_MEMBER_REQUEST:
            return Object.assign({}, state, {
                isPageLoading: true, successMessage: '', errorMessage: '',
                memberAddedStatusList: {}
            });
        case INVITE_MEMBER_SUCCESS:
            return Object.assign({}, state, {
                isPageLoading: false,
                successMessage: '',
                memberAddedStatusList: {
                    add: action.response.data.successful_sents,
                    not_add: action.response.data.unsuccessful_sents
                },
            });
        case INVITE_MEMBER_FAILURE:
            return Object.assign({}, state, {
                isPageLoading: false, errorMessage: action.response.data.message,
                memberAddedStatusList: {}
            });
        case DEFAULT_MEMBER_STATE:
            return Object.assign({}, state, {
                errorMessage: '', successMessage: '', deleted: false,
                members: [{ fname: '', lname: '', email: '', phone: '+91 ' }]
            });

        default:
            return state
    }
}
