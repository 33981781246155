import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import createNotification from "../../../../components/app/notification/createNotification";
import {BlockLoader} from 'xd-react-loader';
import { Avatar } from 'xd-react-avatar';
import NoDataFound from '../../../../components/app/no-data-found/NoDataFound';
import {getAssignedReviewerListing, getReviewerList, assignReviewer, deleteAssignedReviewer, clearAssignReviewerData, clearDeleteReviewerData} from '../../../../actions/admin/reviewerActions';
import userRoles from '../../../../components/roles/roles';

const url_string = window.location.href;
const url = new URL(url_string);



class ReviewerListing extends Component{
    constructor(props){
        super(props)
        this.state= {
        }
        this.handleAssignNewReviewer=this.handleAssignNewReviewer.bind(this)
      }

    componentDidMount(){
        this.props.dispatch(getAssignedReviewerListing(this.props.email))
        this.props.dispatch(getReviewerList())
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.assignReviewerErrorMsg.length!==0) {
            createNotification('error', nextProps.assignReviewerErrorMsg);
            nextProps.dispatch(clearAssignReviewerData());
        }
        
            if(nextProps.reviewerAssignedErrorList.length!==0){
                createNotification('error', nextProps.reviewerAssignedErrorList[0].status[0].message);
                nextProps.dispatch(clearAssignReviewerData());
            } 
        
        if(nextProps.deleteReviewerError.length!==0){
            createNotification('error', nextProps.deleteReviewerError);
            nextProps.dispatch(clearDeleteReviewerData())
        }
        if(nextProps.getAssignedList){
                nextProps.dispatch(getAssignedReviewerListing(nextProps.email))
        }
      }

    handleAssignNewReviewer(e, reviewerEmail){
        let email=this.props.email
        let data={
            interns: [email],
            reviewers:[reviewerEmail] 
        }
        this.props.dispatch(assignReviewer(data))
    }
    deleteAssignedReviewer(e, reviewerEmail){
        let userEmail= this.props.email
        this.props.dispatch(deleteAssignedReviewer( reviewerEmail, userEmail))
    }

render(){  
    const { assignedReviewerListLoading, assignedReviewerListError, assignedReviewerListSuccess} = this.props   
    return(
    <section className="content-section-wrapper">
        <div className="row">               
            <div className="col-12 col-sm-6 col-md-5">                                
                <div className="user-details">
                    {assignedReviewerListLoading?(
                    <div className="page-section-loader">
                        <BlockLoader
                            loadingText="Loading Reviewer Details .."
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                    </div>
                    ):assignedReviewerListError?(
                       <NoDataFound title={assignedReviewerListError} description={'No data found for assigned Reviewers.'}/>
                    ):assignedReviewerListSuccess?(
                     <div className="user-profile-container">   
                        <h2 class="profile-section_card_title">Assigned Reviewer</h2>    
                        <ul className="custom-list-item-wrapper">
                            {this.props.assignedReviewerList? (this.props.assignedReviewerList.map((reviewer, index)=>(

                            <li className="custom-list-item">
                                <div className="custom-list-item-content">
                                    <div className="row">  
                                        <div className="col-10">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content assigned-reviewer-meta-info">
                                                    <div className="assigned-reviewer-avatar">
                                                    <Avatar name={reviewer.name} size={36} radius={36}/>
                                                    </div>
                                                    <div className="assigned-reviewer-info">
                                                        <h3 className="assigned-reviewer-name">{reviewer.name}</h3>
                                                        <p className="assigned-reviewer-email">{reviewer.email}</p>
                                                    </div>                                       
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                {this.props.roleId != userRoles.Reviewer && 
                                                    <div className="assigned-reviewr-action">
                                                        <button type="button" className="custom-icon-button close-icon-btn" onClick={(e)=>this.deleteAssignedReviewer(e, reviewer.email)}>
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            ))):(
                                <NoDataFound />
                            )}
                    </ul>      
                </div>
             ):(
                <NoDataFound />
             )}
             </div>
            </div>
            {/* assigne new reviwer */}
            <div className="col-12 col-sm-6 col-md-5">
                 <div className="user-details">
                 {this.props.reviewerListLoading?(
                    <div className="page-section-loader">
                        <BlockLoader
                            loadingText="Loading Reviewer Details .."
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                    </div>
                    ):this.props.reviewerListErrorMsg?(
                       <NoDataFound title={this.props.reviewerListErrorMsg} />
                    ):this.props.reviewerListSuccessMsg?(
                 
                    <div className="user-profile-container">
                        {this.props.roleId != userRoles.Reviewer && 
                        <div className="add-new-assignee-list">
                        <h2 class="profile-section_card_title">Assign New Reviewer</h2> 
                        <ul className="custom-list-item-wrapper">
                            {this.props.reviewerList? (this.props.reviewerList.map((reviewer, i)=>(
                            <li className="custom-list-item">
                                <div className="custom-list-item-content">
                                    <div className="row">  
                                        <div className="col-10">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content assigned-reviewer-meta-info">
                                                    <div className="assigned-reviewer-avatar">
                                                    <Avatar name={reviewer.name} size={36} radius={36}/>
                                                    </div>
                                                    <div className="assigned-reviewer-info">
                                                        <h3 className="assigned-reviewer-name">{reviewer.name}</h3>
                                                        <p className="assigned-reviewer-email">{reviewer.email}</p>
                                                    </div>                                       
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                    <div className="assigned-reviewr-action">
                                                        <button type="button" className="custom-icon-button add-icon-btn" onClick={(e)=>this.handleAssignNewReviewer(e, reviewer.email)}>
                                                            <i className="fa fa-plus"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            ))):(
                                <NoDataFound />
                            )}
                        </ul>      
                        </div> }    
                    </div>
                    ):(
                        <NoDataFound />
                     )}
                </div>
            </div>
        </div>
    </section>                     
    )
    }
}

function mapStateToProps(state) {
    const {isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink} = state.employeeReducer;
    const {userModuleList}=state.moduleReducer;
    const {roleId} = state.authReducer;
    const {getAssignedList,assignedReviewerList,assignedReviewerListLoading, assignedReviewerListSuccess, assignedReviewerListError, reviewerListLoading, reviewerListErrorMsg,
         reviewerListSuccessMsg, reviewerList, assignReviewerErrorMsg, reviewerAssignedErrorList, reviewerAssignedSuccessList, deleteReviewerError, deleteReviewerSuccess}= state.reviewerReducer
    return {roleId, getAssignedList, isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink, userModuleList, reviewerAssignedErrorList,
        assignedReviewerList,assignedReviewerListLoading, assignedReviewerListSuccess, assignedReviewerListError, assignReviewerErrorMsg,
        reviewerListLoading, reviewerListErrorMsg, reviewerListSuccessMsg, reviewerList, reviewerAssignedSuccessList, deleteReviewerError, deleteReviewerSuccess}
}

export default withRouter(connect(mapStateToProps)(ReviewerListing))
