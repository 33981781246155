import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import './dist/style.css';
import '../employee-listing/dist/table.css';
import { Input } from 'xd-react-input';
import { updateSelectedModules, postAssignModule, clearSelectedModuleData, clearAssignModuleData } from '../../../actions/admin/employeeActions';
import { clearModuleData } from '../../../actions/admin/internModuleActions';
import createNotification from "../../../components/app/notification/createNotification";
class AssignDate extends Component {
  componentDidMount() {
    this.props.dispatch(clearAssignModuleData())
  }
  handleChange(e, module) {
    const newState = {
      'unlock_date': parseInt((new Date(e.target.value).getTime() / 1000).toFixed(0)),
      'moduleid': module
    }
    if (e.target.checkValidity()) {
      e.target.parentElement.classList.remove("has-error");
      e.target.parentElement.parentElement.classList.remove("has-error");
    } else {
      e.target.parentElement.classList.add("has-error");
      e.target.parentElement.parentElement.classList.add("has-error");
    }
    this.props.module.push(newState);
    this.props.dispatch(updateSelectedModules(this.props.module))
  }
  assignModule(e) {
    e.preventDefault();
    let email = [], list;
    if (e.target.checkValidity()) {
      if (this.props.employeeList.length === 0) {
        list = this.props.internsList
      } else {
        list = this.props.employeeList
      }
      
      list.map((m, i) => email.push(m.email))
      let body = {
        email: email,
        module: this.props.module,
      }
      this.props.dispatch(postAssignModule(body))
    } else {
      let allInvalidInput = document.querySelectorAll(
        ".form-group .form-control:invalid"
      );
      if (allInvalidInput.length > 0) {
        allInvalidInput[0].focus();
        for (let invalidInp of allInvalidInput) {
          invalidInp.parentElement.classList.add("has-error");
          invalidInp.parentElement.parentElement.classList.add(
            "has-error"
          );
        }
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.postAssignSuccessMessage !== "") {
      if (nextProps.postAssignError) {
        createNotification('error', nextProps.postAssignSuccessMessage);
        nextProps.dispatch(clearModuleData());
      } else {
        createNotification('success', nextProps.postAssignSuccessMessage);
        nextProps.history.push("/admin/employee/module-status")
        this.props.dispatch(clearSelectedModuleData())
      }
    }
  }
  render() {
    let list = []
    let selectedIntern = []
    if (this.props.selectedModules.length === 0) {
      list = this.props.moduleList
      selectedIntern = this.props.employeeList
    } else {
      list = this.props.selectedModules
      selectedIntern = this.props.employeeList
    }
    window.onbeforeunload = function () {
      return 'Are you sure you want to leave?';
    }
    const disablePastDate = () => {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
  };
    return (
      <React.Fragment>
        <main className="main-content-wrapper">
          <section className="dashboard-content-header">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6">
                  <div className="dashboard-content-header-left">
                    <h2 className="content-header-title">Arrange Selected Modules</h2>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6">
                  <div className="dashboard-content-header-right dashboard-header-action-group">

                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="dashboard-body-wrapper">
            <form onSubmit={this.assignModule.bind(this)} 	noValidate={true} >
            <div className="container">           
              <div className="">
                {list.map((m, i) =>
                  <section className="content-section-wrapper">
                    <div className="table-wrapper">
                      <div className="card-wrapper details personal_info">
                        <div className="details-header module education-info-header">
                          {i + 1}. Module
                        </div>
                        <div className="assign-table">
                          <table>
                            <tbody>
                              <React.Fragment>
                                <tr className="assign-date">
                                  <td className="detail-name">Name of Module</td>
                                  <td className="profile-value">{m.name}</td>

                                  <td className="detail-name">Duration</td>
                                  <td className="profile-value">{m.duration} min.</td>
                                </tr>
                                <tr className="assign-date">
                                  <td className="detail-name">Description</td>
                                  <td className="profile-value">{m.description}</td>
                                </tr>
                                <tr className="assign-date">
                                  <td className="detail-name">Select Assign Date</td>
                                  <td className="profile-value">
                                    <Input
                                      type="date"
                                      min={disablePastDate()}
                                      name="assign_date"
                                      id={"assign_date" + i}
                                      placeholder="DD/MM/YYYYY"
                                      autoComplete={"off"}
                                      required={true}
                                      className="form-control input-compact"
                                      errorMessage="Please enter module assign date."
                                      onChange={(e) => this.handleChange(e, m.id)}
                                    />
                                  </td>
                                </tr>

                              </React.Fragment>
                            </tbody></table>
                        </div>
                      </div>

                    </div>
                  </section>
                )}
              </div>

              <div className="footer">
                <div className="xd-btn-group">
                  <Button formNoValidate={true} onClick={() => this.props.history.push("/admin/employee/l1")} className="btn btn-outline">Back</Button>
                  <Button type="submit" >Assign</Button>
                </div>
              </div>
            </div>
            </form>
          </div>

        </main>
      </React.Fragment>
    )
  }
}


function mapStateToProps(state) {
  const { internsList, employeeList, selectedModules, module, postAssignError, postAssignSuccessMessage, assignModuleSuccessList, assignModuleErrorList } = state.employeeReducer;
  const { moduleList, getModulePageLoading } = state.moduleReducer;
  return { internsList, employeeList, selectedModules, module, postAssignSuccessMessage, postAssignError, getModulePageLoading, moduleList, assignModuleSuccessList, assignModuleErrorList}
}

export default withRouter(connect(mapStateToProps)(AssignDate))