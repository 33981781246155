import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import ReactTelInput from 'react-telephone-input';
import 'react-telephone-input/lib/withStyles';
import { updateMemberAdminRegistrationData, inviteAdminMemberRegistration } from '../../../actions/auth/authActions';
let phone = null;
const url_string = window.location.href;
const url = new URL(url_string);
class MemberAdminSignup extends Component {
    constructor(props) {
		super(props)
		this.state = {
			phoneNumberLength: 15
		}
	}
    componentDidMount() {
        document.getElementsByName('name')[0].focus();
        phone = document.querySelector('input[type=tel]');
        phone.setAttribute('tabIndex', 2)
        if (url.searchParams.get("token") === null) {
            this.props.history.push('/login')
        }
    }

    SetCaretAtEnd(elem) {
        const elemLen = elem.value.length;
        if (document.selection) {
            elem.focus();
            const oSel = document.selection.createRange();
            oSel.moveStart('character', -elemLen);
            oSel.moveStart('character', elemLen);
            oSel.moveEnd('character', 0);
            oSel.select();
        }
        else if (elem.selectionStart || elem.selectionStart === 0) {
            elem.selectionStart = elemLen;
            elem.selectionEnd = elemLen;
            elem.focus();
        }
    }

    handleChange(e) {
        const newState = Object.assign(this.props.memberAdminSignupData, {
            [e.target.name]: e.target.value,
            "token": url.searchParams.get('token')
        });
        this.props.dispatch(updateMemberAdminRegistrationData(newState))
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove("has-error");
            e.target.parentElement.parentElement.classList.remove("has-error");
        } else {
            e.target.parentElement.classList.add("has-error");
            e.target.parentElement.parentElement.classList.add("has-error");
        }

        // validate confirm password
        if (this.props.memberAdminSignupData.password !== this.props.memberAdminSignupData.confirmPassword) {
            if (this.props.memberAdminSignupData.confirmPassword !== '') {
                e.target.parentElement.parentElement.classList.add('has-error');
            } else {
                e.target.parentElement.parentElement.classList.remove('has-error');
            }
        }
        this.forceUpdate();
    }
    handleInputChange(telNumber, selectedCountry, e) {
        document.querySelector('input[type="tel"]').setAttribute('maxlength', selectedCountry.format.length);
        //validation for phone number
        if (telNumber.length !== selectedCountry.format.length) {
            phone.parentElement.parentElement.classList.add('has-error')
            phone.setCustomValidity('Enter valid phone number.');
        } else {
            phone.parentElement.parentElement.classList.remove('has-error')
            phone.setCustomValidity('');

            let memberData = {
                "token": this.props.memberAdminSignupData.token,
                "name": this.props.memberAdminSignupData.name,
                "contact": phone.value,
                "password": this.props.memberAdminSignupData.password,
                "confirmPassword": this.props.memberAdminSignupData.confirmPassword
            };
            this.props.dispatch(updateMemberAdminRegistrationData(memberData))
        }
    }
    handleInputBlur(telNumber, selectedCountry) {
        if (telNumber.length === selectedCountry.format.length) {
            phone.setCustomValidity('')
        }
        document.querySelector('label.phn').classList.remove('focused');
    }
    handleFocus(e) {
        this.SetCaretAtEnd(phone);
        document.querySelector('label.phn').classList.add('focused');
    }
    handleSubmit(e) {
        e.preventDefault();
        if (e.target.checkValidity() && (phone.value.length === this.state.phoneNumberLength) && (this.props.memberAdminSignupData.password === this.props.memberAdminSignupData.confirmPassword)) {
            //checks the validation over the signup form.
            this.props.dispatch(inviteAdminMemberRegistration(this.props.memberAdminSignupData))
        } else {
            if (phone.value.length !== this.state.phoneNumberLength) {
                phone.setCustomValidity('invalid');
                phone.parentElement.parentElement.classList.add('has-error')
            }

            const invalidInputs = document.querySelectorAll('input:invalid');
            invalidInputs[0].focus();
            for (let invalidInp of invalidInputs) {
                invalidInp.parentElement.classList.add('has-error')
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const self = this;
        if (nextProps.memberAdminRegistrationSuccess) {
            setTimeout(function () {
                self.props.history.push('/admin/dashboard')
            }, 2000)
        }
    }

    render() {
        const { isPageLoading, memberAdminRegistrationMessage, memberAdminRegistrationSuccess } = this.props
        const {password}=this.props.memberAdminSignupData
        return (
            <main className="main-content-wrapper full-height">
                <div className="dashboard-body-wrapper auth-fill-background full-height ">
                    <section className="full-width-content-section">
                        <div className="admin-form-outer">
                            <div className="admin-form-header">
                                <div className="admin-form-header-content">
                                    <figure>
                                        <img src={require('../dist/xenonstack-logo-xs.png')} alt="XenonStack Onboarding Portal Logo" />
                                    </figure>
                                    <h2>Member Registration</h2>
                                    <p>Fill your required information. You will be redirected to dashboard after registration.</p>
                                </div>
                            </div>
                            <form className="employee-signup-formon" onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true} id="employee-signup-form"
                            >
                                <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            outerClass="input-ctrl"
                                            placeholder="Full Name e.g: Steven Smith"
                                            required={true}
                                            minLength={3}
                                            maxLength={50}
                                            pattern={"([a-zA-Z]\.+\\s)*[a-zA-Z]+$"}
                                            autoComplete={"off"}
                                            label="Full Name"
                                            className="form-control"
                                            outerClassName="signup-input-box"
                                            errorMessage="Please enter your full name(min. 3 characters)."
                                            onChange={this.handleChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group telephone-outer">
                                            <label className="phn">Phone</label><span class="required">*</span>
                                            <div className="input-wrapper">
                                                <ReactTelInput
                                                    id="react-phone-control"
                                                    defaultCountry="in"
                                                    country={'in'}
                                                    required={true}
                                                    flagsImagePath={require('../../../static/images/flags.png')}
                                                    preferredCountries={['in', 'us', 'gb']}
                                                    name="phone"
                                                    tabIndex={2}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    onFocus={this.handleFocus.bind(this)}
                                                    onBlur={this.handleInputBlur.bind(this)}
                                                />
                                                <div className="with-error">
                                                    Please enter a valid phone number.
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="form-group"><label>Password <span className="req"> *</span></label>
                                            <input
                                                required
                                                type="password"
                                                id="password"
                                                placeholder="Password"
                                                tabIndex="4"
                                                name="password"
                                                onChange={this.handleChange.bind(this)}
                                                className="form-ctrl"
                                                label="Password"
                                                maxlength={50}
                                                minlength={8}
                                                pattern={"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"}
                                            />
                                            <div className="help-block with-errors" style={{ color: "unset" }}>Enter your
                                                            password (<span
                                                    style={{ color: `${password.length >= 8 ? "green" : "#f03030"}` }}>Min 8 char.</span>, <span
                                                        style={{ color: `${password.match("(?=.*?[A-Z])") ? "#279627" : "#f03030"}` }}>at least one uppercase letter</span>, <span
                                                            style={{ color: `${password.match("(?=.*?[a-z])") ? "#279627" : "#f03030"}` }}>one lowercase letter</span>, <span
                                                                style={{ color: `${password.match("(?=.*?[0-9])") ? "#279627" : "#f03030"}` }}>one number and </span>
                                                <span
                                                    style={{ color: `${password.match("(?=.*?[#?!@$%^&*-])") ? "#279627" : "#f03030"}` }}>one special character required</span>).
                                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12">
                                        <Input
                                            required
                                            type="password"
                                            visibility="true"
                                            passwordShowData='<i  class="fa fa-eye-slash eye-icon" aria-hidden="true"/>'
                                            passwordHideData='<i  class="fa fa-eye eye-icon" aria-hidden="true"/>'
                                            name="confirmPassword"
                                            autofill={"false"}
                                            label="Confirm Password"
                                            maxLength={30}
                                            minLength={3}
                                            className="form-control"
                                            outerClassName="signup-input-box"
                                            placeholder="Confirm Password"
                                            id="confirm-password"
                                            errorMessage="Password didn't match."
                                            onChange={this.handleChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group form-btn-group">
                                            {!isPageLoading ?
                                                (<Button type="submit"
                                                    id="send-invite" formNoValidate={true}
                                                    className="btn box-shadow">Create Account</Button>)
                                                :
                                                (<Button className="btn btn-icon icon-left">
                                                    <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                    <span className="btn-text">Please wait ..</span>
                                                </Button>)
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-link-group">
                                            <NavLink to="/login" className="auth-page-link">
                                                Back to Login
                                        </NavLink>
                                        </div>
                                        {!isPageLoading && !memberAdminRegistrationSuccess &&
                                            <div className="center-error form-error">
                                                <p className="error-msg">{memberAdminRegistrationMessage}</p>
                                            </div>
                                        }
                                        {!isPageLoading && memberAdminRegistrationSuccess &&
                                            <div className="center-error form-error">
                                                <p className="success-msg">{memberAdminRegistrationMessage}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    const { memberAdminRegistrationSuccess, isPageLoading, memberAdminRegistrationMessage, memberAdminSignupData } = state.authReducer;
    return { memberAdminRegistrationSuccess, isPageLoading, memberAdminRegistrationMessage, memberAdminSignupData }
}

export default withRouter(connect(mapStateToProps)(MemberAdminSignup))
