import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class EmployeeSubHeader extends Component {
    render() {
        return (
            <section className="emp-sub_header">
                <div className="container">
                    <div className="row">
                        <div class="col-12 col-sm-12 col-md-8">
                            <div className="emp-sub-header_content">
                                <h1>Training Module</h1>
                                <p>
                                    XenonStack Internship on boarding program.
                                    Here is your Internship Training Modules
                                    list, including the Tasks, Links and all the
                                    required information.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

export default withRouter(connect(mapStateToProps)(EmployeeSubHeader));
