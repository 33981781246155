import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import { SpinLoader } from 'xd-react-loader';
import './dist/style.css';
import {
    getModuleTaskList,
    getModuleTaskListDetails,
} from '../../../actions/admin/internModuleActions';
import { NoDataFound } from 'xd-react-nodatafound';
import userRoles from '../../../components/roles/roles';
class ModuleDetails extends Component {
    componentDidMount() {
        if (localStorage.getItem('isProfileModuleClicked') === 'true') {
            this.props.dispatch(
                getModuleTaskListDetails(this.props.match.params.moduleId)
            );
        } else {
            this.props.dispatch(
                getModuleTaskList(this.props.match.params.moduleId)
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <main className="main-content-wrapper">
                    <section className="dashboard-content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-left">
                                        <h2 className="content-header-title">
                                            Tasks List
                                        </h2>
                                        <p className="content-header-description">
                                            Tasks of selected module .{' '}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-right dashboard-header-action-group"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="dashboard-body-wrapper">
                        <div className="dashboard-content-wrapper">
                            <h4 style={{ fontSize: '18px' }}>
                                Module Name:{' '}
                                <>{this.props.moduleDetails.name}</>
                            </h4>
                            {this.props.getModuleTasksPageLoading && (
                                <div className="align-center">
                                    <SpinLoader
                                        loaderStyle={{
                                            size: '50px',
                                            speed: '1s',
                                        }}
                                        loading={true}
                                    />
                                </div>
                            )}
                            {!(
                                this.props.getModuleTasksPageLoading &&
                                this.props.moduleTasksList &&
                                this.props.moduleTasksList.length > 0
                            ) ? (
                                this.props.moduleTasksList.map((t, i) => (
                                    <>
                                        <div className="plain-card">
                                            <div className="card-outer">
                                                <div className="card-header">
                                                    <span>
                                                        <b>
                                                            {t.name
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                t.name.slice(1)}
                                                        </b>
                                                    </span>
                                                </div>
                                                <div className="card-body">
                                                    <div className="card-row">
                                                        <div className="break-line"></div>
                                                        <div className="row">
                                                            <div class="col-12 col-sm-10 col-md-4 col-lg-6">
                                                                <div className="form-group">
                                                                    <label>
                                                                        Links
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-10 col-md-3 col-lg-6">
                                                                {t.resources
                                                                    .length >
                                                                0 ? (
                                                                    t.resources.map(
                                                                        (
                                                                            links,
                                                                            j
                                                                        ) => (
                                                                            <div className="form-group links">
                                                                                <strong>
                                                                                    {
                                                                                        links
                                                                                    }
                                                                                </strong>
                                                                                <a
                                                                                    href={
                                                                                        links
                                                                                    }
                                                                                    target="_blank"
                                                                                >
                                                                                    <i
                                                                                        class="fa fa-external-link"
                                                                                        aria-hidden="true"
                                                                                    ></i>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <>N/A</>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="break-line"></div>
                                                    </div>
                                                    <div className="card-row">
                                                        <div className="row">
                                                            <div class="col-12 col-sm-10 col-md-4 col-lg-6">
                                                                <div className="form-group">
                                                                    <label>
                                                                        Description
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-10 col-md-3 col-lg-6">
                                                                <div className="form-group">
                                                                    <strong>
                                                                        {t.description ===
                                                                        ''
                                                                            ? 'N/A'
                                                                            : t.description}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-row">
                                                        <div className="row">
                                                            <div class="col-12 col-sm-10 col-md-4 col-lg-6">
                                                                <div className="form-group">
                                                                    <label>
                                                                        Attachments
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-10 col-md-5 col-lg-6">
                                                                <div className="form-group">
                                                                    {t
                                                                        ?.object_link
                                                                        ?.length >
                                                                    0
                                                                        ? t.object_link.map(
                                                                              (
                                                                                  i,
                                                                                  index
                                                                              ) => (
                                                                                  <div className="attachment-row">
                                                                                      {' '}
                                                                                      <strong>
                                                                                          {
                                                                                              i?.attachments?.split(
                                                                                                  '/'
                                                                                              )[1]
                                                                                          }{' '}
                                                                                      </strong>
                                                                                      <a
                                                                                          href={
                                                                                              i.links
                                                                                          }
                                                                                          target="_blank"
                                                                                          className="doc-download"
                                                                                      >
                                                                                          <i className="icon-cloud-download"></i>
                                                                                          View/download
                                                                                      </a>
                                                                                  </div>
                                                                              )
                                                                          )
                                                                        : 'N/A'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                            ) : (
                                <>
                                    {!this.props.getModulePageLoading &&
                                        this.props.moduleTasksList.length ===
                                            0 && (
                                            <NoDataFound
                                                NoDataImage={require('../../../static/images/no-resultfound.png')}
                                                alt="No Modules"
                                                NoDataTitle="Task not found."
                                                NoDataDesc={
                                                    <React.Fragment>
                                                        You have not created any
                                                        Task yet.
                                                    </React.Fragment>
                                                }
                                            />
                                        )}
                                </>
                            )}
                            {this.props.getModuleTasksMessage &&
                                this.props.getModuleTasksError && (
                                    <p
                                        style={{
                                            color: 'red',
                                            fontSize: '16px',
                                        }}
                                    >
                                        {this.props.getModuleTasksMessage}
                                    </p>
                                )}
                            {!this.props.getModuleTasksError && (
                                <div className="row">
                                    <div class="col-12 col-sm-10 col-md-6 col-lg-6">
                                        <div className="form-group">
                                        {this
                                             .props
                                             .roleId != userRoles.Reviewer &&
                                            <Button
                                                onClick={() =>
                                                    this.props.history.push(
                                                        '/admin/employee/edit-details/' +
                                                            this.props.match
                                                                .params.moduleId
                                                    )
                                                }
                                                className="btn btn-icon icon-left"
                                                href="/admin/employee/create-module"
                                            >
                                                <i className="fa fa-plus btn-icon"></i>
                                                <span className="btn-text">
                                                    Add New Task
                                                </span>
                                            </Button> }
                                            <Button
                                                className="btn box-shadow back-btn"
                                                onClick={() =>
                                                    this.props.history.push(
                                                        '/admin/employee/list-module/1'
                                                    )
                                                }
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {
        moduleDetails,
        moduleTasksList,
        getModuleTasksPageLoading,
        getModuleTasksError,
        getModuleTasksMessage,
    } = state.moduleReducer;
    const {roleId} = state.authReducer;
    return {
        moduleDetails,
        roleId,
        moduleTasksList,
        getModuleTasksPageLoading,
        getModuleTasksError,
        getModuleTasksMessage,
    };
}

export default withRouter(connect(mapStateToProps)(ModuleDetails));
