import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../dist/modal.css';
import { Button } from 'xd-react-button';
import { Input } from 'xd-react-input';
import QualificationData from '../../../../static/data/qualification.json';
import {
    handleEditEducationDialogChange,
    updateEducationFields,
    updateProfileDetails,
    uploaduserFile,
    deleteUserFile,
    deleteDocument,
    getEmployeeProfile,
} from '../../../../actions/employee/userActions';

class EditEducationPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marksheetError: '',
        };
    }

    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleEditEducationDialogChange(false));
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.updateSuccess !== this.props.updateSuccess) {
           nextProps.dispatch(getEmployeeProfile());
        }
    }

    handleOnChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        const index = this.props.eduIndex;
        let educationData = Object.assign([], this.props.usereducationInfo);
        if (name == 'percentage') {
            if (e.target.value) {
                let num = parseFloat(e.target.value);
                if (!isNaN(num)) {
                    educationData[index][name] = num;
                }
            } else {
                educationData[index][name] = e.target.value;
            }
        } else {
            educationData[index][name] = value;
        }
        this.props.dispatch(updateEducationFields(educationData));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
        }
    }

    handleDocumentUpload(e) {
        let input = e.target;
        let name = e.target.name;
        const index = this.props.eduIndex;

        if (input.files) {
            let files = input.files;
            this.props.dispatch(uploaduserFile(files, name, index));
        }
    }
    handledeleteMarksheet(marksheet, i) {
        const name = 'marksheet_link';
        const index = this.props.eduIndex;
        const list =
            this.props.usereducationInfo[this.props.eduIndex].marksheet_link;
        list.splice(i, 1);
        this.props.dispatch(deleteDocument(marksheet, name, index, list));
    }

    handleSubmit(e) {
        e.preventDefault();

        const details = {
            account_info: {
                name: this.props.profileInfo.profile.account.name,
                contact_no: this.props.profileInfo.profile.account.contact_no,
            },
            personal_info: this.props.profileInfo.profile.personal,
            user_address: this.props.profileInfo.profile.address,
            education: this.props.usereducationInfo,
            emergency_contact: this.props.profileInfo.profile.emergency,
            others: this.props.profileInfo.profile.others,
        };
        if (
            e.target.checkValidity() &&
            this.props.usereducationInfo[this.props.eduIndex].marksheet_link
                .length !== 0
        ) {
            if (this.props.profileInfo.profile.education[this.props.eduIndex]) {
                if (
                    this.props.usereducationInfo[this.props.eduIndex]
                        .degree_link !=
                        this.props.profileInfo.profile.education[
                            this.props.eduIndex
                        ].degree_link &&
                    this.props.profileInfo.profile.education[
                        this.props.eduIndex
                    ].degree_link !== ''
                ) {
                    this.props.dispatch(
                        deleteUserFile(
                            this.props.profileInfo.profile.education[
                                this.props.eduIndex
                            ].degree_link,
                            details
                        )
                    );
                } else {
                    this.props.dispatch(updateProfileDetails(details));
                }
            } else {
                this.props.dispatch(updateProfileDetails(details));
            }
            document.querySelector('#body-root').style = 'overflow:auto;';
        } else {
            if (
                this.props.usereducationInfo[this.props.eduIndex].marksheet_link
                    .length == 0
            ) {
                this.setState({
                    marksheetError: 'Please upload your marksheet',
                });
            } else {
                this.setState({ marksheetError: '' });
            }
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    render() {
        const { editEducationPopupState } = this.props;
        const educationInfo = this.props.usereducationInfo[this.props.eduIndex];
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': editEducationPopupState,
                        fadeOut: !editEducationPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown: editEducationPopupState,
                                fadeOutUp: !editEducationPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">Education</h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form
                                onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true}
                                id="edit-education-dialog"
                            >
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Select Education
                                                        <span className="required">
                                                            *
                                                        </span>{' '}
                                                    </label>
                                                    <div className="select-wrapper">
                                                        <select
                                                            className="form-control"
                                                            onChange={this.handleOnChange.bind(
                                                                this
                                                            )}
                                                            placeholder="Select Education "
                                                            required
                                                            name="name"
                                                            id="eductaion-name"
                                                        >
                                                            <option
                                                                disabled={true}
                                                                value=""
                                                                selected={
                                                                    educationInfo.name ===
                                                                        undefined ||
                                                                    educationInfo.name ===
                                                                        ''
                                                                }
                                                            >
                                                                Select Education
                                                            </option>
                                                            {QualificationData.map(
                                                                (
                                                                    qualification,
                                                                    i
                                                                ) => (
                                                                    <option
                                                                        value={
                                                                            qualification
                                                                        }
                                                                        selected={
                                                                            educationInfo.name ===
                                                                            qualification
                                                                        }
                                                                        key={i}
                                                                    >
                                                                        {
                                                                            qualification
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="with-error">
                                                        Select an education.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <Input
                                                        type="text"
                                                        name="institution"
                                                        className="form-control"
                                                        errorMessage="Please enter School/College/University name(min 3 char.)"
                                                        placeholder="School/College/University"
                                                        label="School/College/University"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={
                                                            educationInfo.institution
                                                        }
                                                        minLength={3}
                                                        maxLength={50}
                                                        pattern={
                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        name="location"
                                                        className="form-control"
                                                        errorMessage="Please enter School/College/University Location(min 3 char.)"
                                                        placeholder="Location"
                                                        label="Location"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={
                                                            educationInfo.location
                                                        }
                                                        minLength={3}
                                                        maxLength={50}
                                                        pattern={
                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        name="percentage"
                                                        className="form-control"
                                                        errorMessage="Please enter valid Percentage/CGPA"
                                                        placeholder="Percentage/CGPA"
                                                        label="Percentage/CGPA"
                                                        autoComplete={'off'}
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={
                                                            educationInfo.percentage
                                                        }
                                                        pattern={
                                                            '[0-9]+(.[0-9]{0,2})?%?'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <Input
                                                        type="number"
                                                        name="year"
                                                        className="form-control"
                                                        errorMessage="Please enter valid Passing Year"
                                                        placeholder="Passing Year"
                                                        label="Year of Passing"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={
                                                            educationInfo.year
                                                        }
                                                        max="9999"
                                                        min="1000"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Upload Marksheet{' '}
                                                        <span className="req">
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        multiple
                                                        name="marksheet_link"
                                                        placeholder="Upload Marksheets"
                                                        minLength={3}
                                                        autoComplete={'off'}
                                                        label="Upload Marksheets "
                                                        className="form-control"
                                                        outerClassName="signup-input-box"
                                                        errorMessage="Please upload your Marksheet"
                                                        onChange={(e) =>
                                                            this.handleDocumentUpload(
                                                                e
                                                            )
                                                        }
                                                    />
                                                    <div
                                                        style={{ color: 'red' }}
                                                    >
                                                        {
                                                            this.state
                                                                .marksheetError
                                                        }
                                                    </div>
                                                    <div className="with-error">
                                                        Upload marksheet.
                                                    </div>
                                                </div>
                                                <div>
                                                    {educationInfo.marksheet_link.map(
                                                        (marksheet, i) => (
                                                            <p className="document-delete">
                                                                Marksheet{' '}
                                                                {i + 1}{' '}
                                                                <i
                                                                    onClick={() =>
                                                                        this.handledeleteMarksheet(
                                                                            marksheet,
                                                                            i
                                                                        )
                                                                    }
                                                                    class="fa fa-close"
                                                                ></i>
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <Input
                                                        type="file"
                                                        name="degree_link"
                                                        placeholder="Upload Degree"
                                                        minLength={3}
                                                        autoComplete={'off'}
                                                        label="Upload Degree "
                                                        className="form-control"
                                                        outerClassName="signup-input-box"
                                                        errorMessage="Please upload your Degree"
                                                        onChange={(e) =>
                                                            this.handleDocumentUpload(
                                                                e
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{ color: '#f9a469' }}
                                                >
                                                    {
                                                        /[^/]*$/.exec(
                                                            educationInfo.degree_link
                                                        )[0]
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.updateErrorMsg && (
                                            <p style={{ color: 'red' }}>
                                                {this.props.updateErrorMsg}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {editEducationPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: editEducationPopupState,
                            fadeOut: !editEducationPopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        editEducationPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        usereducationInfo,
        eduIndex,
        uploaduserFileSuccess,
        uploadedUserFile,
    } = state.userReducer;
    return {
        editEducationPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        usereducationInfo,
        eduIndex,
        uploaduserFileSuccess,
        uploadedUserFile,
    };
}
export default withRouter(connect(mapStateToProps)(EditEducationPopup));
