import {
    UPDATE_FORM_STATE,
    UPDATE_TASKS,
    POST_MODULE_REQUEST,
    POST_MODULE_SUCCESS,
    POST_MODULE_FAILURE,
    CLEAR_MODULE_DATA,
    GET_MODULE_REQUEST,
    GET_MODULE_SUCCESS,
    GET_MODULE_FAILURE,
    NEW_PAGE_NUMBER_INTERN,
    GET_MODULE_TASKS_REQUEST,
    GET_MODULE_TASKS_SUCCESS,
    GET_MODULE_TASKS_FAILURE,
    GET_MODULE_DETAILS_REQUEST,
    GET_MODULE_DETAILS_SUCCESS,
    GET_MODULE_DETAILS_FAILURE,
    DELETE_MODULE_REQUEST,
    DELETE_MODULE_SUCCESS,
    DELETE_MODULE_FAILURE,
    CLEAR_MODULE_MESSAGE,
    UPDATE_MODULE_REQUEST,
    UPDATE_MODULE_SUCCESS,
    UPDATE_MODULE_FAILURE,
    GET_USER_MODULE_LIST_REQUEST,
    GET_USER_MODULE_LIST_SUCCESS,
    GET_USER_MODULE_LIST_FAILURE,
    HANDLE_MODULE_PDF_COMPLETE_DIALOG_STATE,
    HANDLE_UPLOAD_PDF_DIALOG_CHANGE,
    UPLOAD_PDF_REQUEST,
    UPLOAD_PDF_SUCCESS,
    UPLOAD_PDF_FAILURE,
    UPLOAD_PDF_LINK_REQUEST,
    UPLOAD_PDF_LINK_SUCCESS,
    UPLOAD_PDF_LINK_FAILURE,
    GET_PDF_LINK_REQUEST,
    GET_PDF_LINK_SUCCESS,
    GET_PDF_LINK_FAILURE,
    DELETE_PDF_REQUEST,
    DELETE_PDF_SUCCESS,
    DELETE_PDF_FAILURE,
    CLEAR_PDF_UPLOAD_DATA,
    HANDLE_UPLOAD_PDF_INDEX_CHANGE,
    UPDATE_TASKS_PDFS
} from '../../constants/admin/internModuleConstants';

import {
    DELETE_ASSIGNED_MODULE_REQUEST,
    DELETE_ASSIGNED_MODULE_SUCCESS,
    DELETE_ASSIGNED_MODULE_FAILURE,
    UPDATE_ASSIGN_MODULE_REQUEST,
    UPDATE_ASSIGN_MODULE_SUCCESS,
    UPDATE_ASSIGN_MODULE_FAILURE,
} from '../../constants/admin/EmployeeConstants';

const initialState = {
    module: {
        name: '',
        description: '',
        level: 'L1',
        duration: '',
    },
    tasks: [
        {
            name: '',
            description: '',
            resources: [{ link: '' }],
            attachments: [],
            object_link: [],
        },
    ],
    PDFUploadIndex: 0,
    modulePageLoading: false,
    moduleMessage: '',
    moduleError: '',

    getModulePageLoading: true,
    getModuleMessage: '',
    getModuleTasksError: '',
    moduleList: [],
    internTablePageNumber: 1,
    moduleCount: 0,

    moduleTasksList: [],
    moduleDetails: {},

    deleteModulePageLoading: true,
    deleteModuleMessage: '',
    deleteModuleTasksError: '',

    deleteAssignedModulePageLoading: true,
    deleteAssignedModuleMessage: '',
    deleteAssignedModuleError: '',

    getModuleDetailsPageLoading: true,
    getModuleDetailsMessage: '',
    getModuleDetailsTasksError: '',

    updateModulePageLoading: true,
    updateModuleMessage: '',
    updateModuleTasksError: '',

    updateAssignModulePageLoading: true,
    updateAssignModuleMessage: '',
    updateAssignModuleTasksError: '',

    userModuleListSuccess: false,
    userModuleList: [],

    selectedModule: {},
    moduleCompletePDFPopupState: false,
    uploadPDFPopupState: false,
    PDFLink: [],
    PDFUploadError: '',
    PDFUploadSuccess: '',
    uploadPDFAfterDelete: false,
    uploadPDFAfterDeleteSuccess: '',
    uploadPDFLoading: false,
    getDetailsAfterPDFUpload: '',
    PDFLinkUploadSuccess: '',
    PDFLinkUploadError: '',
    uploadPDFLinkSuccess: '',
    deletePDFSuccess: '',
    deletePDFSuccessMsg: '',
    deletePDFErrorMsg: '',
    deletePDFLoading: false,
    getPDFLinkSuccess: '',
    moduleData: {},
    searchModuleList: [],
    searchModuleCount: '',
    searchModuleError: '',
    searchModuleMessage: '',
};

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_FORM_STATE:
            return Object.assign({}, state, {
                module: action.newState,
            });
        case UPDATE_TASKS:
            return Object.assign({}, state, {
                tasks: action.newState,
            });
        case UPDATE_TASKS_PDFS:
            return {
                ...state,
                tasks: state.tasks.map((task, index) =>
                    index === state.PDFUploadIndex
                        ? {
                              ...task,
                              attachments: action.newState.attachments,
                              links: action.newState.links,
                              object_link: action.newState.attachments.map(
                                  (i, index) => ({
                                      attachments:
                                          action.newState.attachments[index],
                                      links: action.newState.links[index],
                                  })
                              ),
                          }
                        : task
                ),
            };
        case POST_MODULE_REQUEST:
            return Object.assign({}, state, {
                modulePageLoading: true,
                moduleMessage: '',
            });
        case POST_MODULE_SUCCESS:
            return Object.assign({}, state, {
                modulePageLoading: false,
                moduleError: false,
                moduleMessage: action.response.data.message,
            });
        case POST_MODULE_FAILURE:
            return Object.assign({}, state, {
                modulePageLoading: false,
                moduleError: true,
                moduleMessage: action.response.data.message,
            });
        case UPDATE_ASSIGN_MODULE_REQUEST:
            return Object.assign({}, state, {
                searchModuleList: [],
                searchModuleCount: '',
                searchModuleError: '',
                searchModuleMessage: '',
            });
        case UPDATE_ASSIGN_MODULE_SUCCESS:
            return Object.assign({}, state, {
                searchModuleList: [],
                searchModuleCount: '',
                searchModuleError: '',
                searchModuleMessage: '',
            });
        case UPDATE_ASSIGN_MODULE_FAILURE:
            return Object.assign({}, state, {
                searchModuleList: [],
                searchModuleCount: '',
                searchModuleError: '',
                searchModuleMessage: '',
            });
        case UPDATE_ASSIGN_MODULE_REQUEST:
            return Object.assign({}, state, {
                updateAssignModulePageLoading: true,
                updateAssignModuleMessage: '',
                updateAssignModuleTasksError: '',
            });
        case UPDATE_ASSIGN_MODULE_SUCCESS:
            return Object.assign({}, state, {
                updateAssignModulePageLoading: false,
                updateAssignModuleMessage: action.response.data.message,
                updateAssignModuleTasksError: false,
            });
        case UPDATE_ASSIGN_MODULE_FAILURE:
            return Object.assign({}, state, {
                updateAssignModulePageLoading: false,
                updateAssignModuleMessage: action.response.data.message,
                updateAssignModuleTasksError: true,
            });

        case GET_MODULE_REQUEST:
            return Object.assign({}, state, {
                getModulePageLoading: true,
                getModuleMessage: '',
                deleteModuleMessage: '',
                deleteModuleTasksError: false,
                updateModuleMessage: '',
            });
        case GET_MODULE_SUCCESS:
            return Object.assign({}, state, {
                getModulePageLoading: false,
                getModuleTasksError: false,
                moduleList: action.response.data.list,
                moduleCount: action.response.data.count,
            });
        case GET_MODULE_FAILURE:
            return Object.assign({}, state, {
                getModulePageLoading: false,
                getModuleTasksError: true,
                moduleList: [],
                getModuleMessage: action.response.data.message,
            });

        case GET_MODULE_TASKS_REQUEST:
            return Object.assign({}, state, {
                getModuleTasksPageLoading: true,
                getModuleTasksMessage: '',
            });
        case GET_MODULE_TASKS_SUCCESS:
            return Object.assign({}, state, {
                getModuleTasksPageLoading: false,
                getModuleTasksError: false,
                moduleTasksList: action.response.data.list,
                moduleDetails: action.response.data.module,
            });
        case GET_MODULE_TASKS_FAILURE:
            return Object.assign({}, state, {
                getModuleTasksPageLoading: false,
                getModuleTasksError: true,
                getModuleTasksMessage: action.response.data.message,
                moduleDetails: {},
            });
        case GET_MODULE_DETAILS_REQUEST:
            return Object.assign({}, state, {
                getModuleDetailsPageLoading: true,
                getModuleDetailsMessage: '',
                getModuleDetailsTasksError: false,
            });
        case GET_MODULE_DETAILS_SUCCESS:
            let generateTasks = {},
                task = [],
                resources = [],
                attachments = [],
                links = [],
                object_link = [];
            let result = action.response.data.list;
            for (let i = 0; i < result.length; i++) {
                for (let j = 0; j < result[i].resources.length; j++) {
                    resources.push({ link: result[i].resources[j] });
                }
                for (let j = 0; j < result[i]?.attachments?.length; j++) {
                    attachments.push(result[i].attachments[j]);
                }
                for (let j = 0; j < result[i]?.object_link?.length; j++) {
                    object_link.push(result[i].object_link[j]);
                }
                for (let j = 0; j < result[i]?.links?.length; j++) {
                    links.push({ link: result[i].links[j] });
                }
                generateTasks = {
                    name: result[i].name,
                    description: result[i].description,
                    resources: resources,
                    attachments: attachments,
                    links: links,
                    object_link: object_link,
                };
                task.push(generateTasks);
                resources = [];
                attachments = [];
                links = [];
                object_link = [];
            }
            return Object.assign({}, state, {
                getModuleDetailsPageLoading: false,
                getModuleDetailsMessage: action.response.data.message,
                getModuleDetailsTasksError: false,
                module: action.response.data.module,
                tasks: task,
            });
        case GET_MODULE_DETAILS_FAILURE:
            return Object.assign({}, state, {
                getModuleDetailsPageLoading: false,
                getModuleDetailsMessage: action.response.data.message,
                getModuleDetailsTasksError: true,
            });
        case DELETE_MODULE_REQUEST:
            return Object.assign({}, state, {
                deleteModulePageLoading: true,
                deleteModuleMessage: '',
                deleteModuleTasksError: '',
            });
        case DELETE_MODULE_SUCCESS:
            return Object.assign({}, state, {
                deleteModulePageLoading: true,
                deleteModuleMessage: action.response.data.message,
                deleteModuleTasksError: action.response.data.error,
            });
        case DELETE_MODULE_FAILURE:
            return Object.assign({}, state, {
                deleteModulePageLoading: false,
                deleteModuleMessage: action.response.data.message,
                deleteModuleTasksError: action.response.data.error,
            });
        case DELETE_ASSIGNED_MODULE_REQUEST:
            return Object.assign({}, state, {
                deleteAssignedModulePageLoading: true,
                deleteAssignedModuleMessage: '',
                deleteAssignedModuleError: '',
            });
        case DELETE_ASSIGNED_MODULE_SUCCESS:
            return Object.assign({}, state, {
                deleteAssignedModulePageLoading: false,
                deleteAssignedModuleMessage: action.response.data.message,
                deleteAssignedModuleError: action.response.data.error,
            });
        case DELETE_ASSIGNED_MODULE_FAILURE:
            return Object.assign({}, state, {
                deleteAssignedModulePageLoading: false,
                deleteAssignedModuleMessage: action.response.data.message,
                deleteAssignedModuleError: action.response.data.error,
            });

        case UPDATE_MODULE_REQUEST:
            return Object.assign({}, state, {
                updateModulePageLoading: true,
                updateModuleMessage: '',
                updateModuleTasksError: '',
            });
        case UPDATE_MODULE_SUCCESS:
            return Object.assign({}, state, {
                updateModulePageLoading: true,
                updateModuleMessage: action.response.data.message,
                updateModuleTasksError: action.response.data.error,
            });
        case UPDATE_MODULE_FAILURE:
            return Object.assign({}, state, {
                updateModulePageLoading: false,
                updateModuleMessage: action.response.data.message,
                updateModuleTasksError: action.response.data.error,
            });
        case CLEAR_MODULE_DATA:
            return Object.assign({}, state, {
                modulePageLoading: false,
                moduleError: false,
                moduleMessage: '',
                module: {
                    name: '',
                    description: '',
                    level: 'L1',
                    duration: '',
                },
                tasks: [
                    {
                        name: '',
                        description: '',
                        resources: [{ link: '' }],
                        attachments: [],
                        links: [],
                    },
                ],
            });
        case CLEAR_MODULE_MESSAGE:
            return Object.assign({}, state, {
                modulePageLoading: false,
                moduleError: false,
                moduleMessage: '',
            });

        case NEW_PAGE_NUMBER_INTERN:
            return Object.assign({}, state, {
                internTablePageNumber: action.newPageNumber,
            });
        case GET_USER_MODULE_LIST_REQUEST:
            return Object.assign({}, state, {
                userModuleListSuccess: false,
                deleteAssignedModuleMessage: '',
                deleteAssignedModuleError: false,
                updateAssignModuleMessage: '',
                updateAssignModuleTasksError: false,
            });
        case GET_USER_MODULE_LIST_SUCCESS:
            return Object.assign({}, state, {
                userModuleListSuccess: true,
                userModuleList: action.response.data.list,
            });
        case GET_USER_MODULE_LIST_FAILURE:
            return Object.assign({}, state, {
                userModuleListSuccess: false,
                userModuleList: '',
            });
        case HANDLE_MODULE_PDF_COMPLETE_DIALOG_STATE:
            return Object.assign({}, state, {
                moduleCompletePDFPopupState: action.status,
                taskId: action.id,
            });
        case HANDLE_UPLOAD_PDF_DIALOG_CHANGE:
            return Object.assign({}, state, {
                uploadPDFPopupState: action.status,
            });
        case HANDLE_UPLOAD_PDF_INDEX_CHANGE:
            return Object.assign({}, state, {
                uploadPDFPopupState: action.status,
                PDFUploadIndex: action.index,
            });
        case UPLOAD_PDF_REQUEST:
            return Object.assign({}, state, {
                PDFLink: '',
                PDFUploadError: '',
                PDFUploadSuccess: '',
                uploadPDFAfterDelete: false,
                uploadPDFLoading: true,
            });
        case UPLOAD_PDF_SUCCESS:
            return Object.assign({}, state, {
                PDFLink: action.response.data.uploadLink,
                PDFUploadError: '',
                PDFUploadSuccess: action.response.data.message,
                uploadPDFLoading: false,
            });
        case UPLOAD_PDF_FAILURE:
            return Object.assign({}, state, {
                PDFLink: '',
                PDFUploadError: action.response.data.message,
                PDFUploadSuccess: '',
                uploadPDFLoading: false,
            });
        case UPLOAD_PDF_LINK_REQUEST:
            return Object.assign({}, state, {
                uploadPDFLinkSuccess: false,
                uploadPDFLoading: true,
                PDFLinkUploadError: '',
                PDFLinkUploadSuccess: '',
                uploadPDFAfterDelete: false,
            });
        case UPLOAD_PDF_LINK_SUCCESS:
            return Object.assign({}, state, {
                uploadPDFLinkSuccess: true,
                uploadPDFLoading: false,
                PDFLinkUploadError: '',
                PDFLinkUploadSuccess: action.response.data.message,
                getDetailsAfterPDFUpload: true,
            });
        case UPLOAD_PDF_LINK_FAILURE:
            return Object.assign({}, state, {
                uploadPDFLinkSuccess: false,
                uploadPDFLoading: false,
                PDFLinkUploadError: action.response.data.message,
                PDFLinkUploadSuccess: '',
            });
        case GET_PDF_LINK_REQUEST:
            return Object.assign({}, state, {
                getPDFLinkSuccess: false,
                PDFLink: '',
            });
        case GET_PDF_LINK_SUCCESS:
            return Object.assign({}, state, {
                getPDFLinkSuccess: true,
                PDFLink: action.response.link,
            });
        case GET_PDF_LINK_FAILURE:
            return Object.assign({}, state, {
                getPDFLinkSuccess: false,
                PDFLink: '',
            });
        case DELETE_PDF_REQUEST:
            return Object.assign({}, state, {
                deletePDFSuccess: false,
                deletePDFSuccessMsg: '',
                deletePDFErrorMsg: '',
                deletePDFLoading: true,
            });
        case DELETE_PDF_SUCCESS:
            return Object.assign({}, state, {
                deletePDFSuccess: true,
                deletePDFSuccessMsg: action.response.data.message,
                deletePDFErrorMsg: '',
                uploadPDFAfterDelete: true,
                deletePDFLoading: false,
            });
        case DELETE_PDF_FAILURE:
            return Object.assign({}, state, {
                deletePDFSuccess: false,
                deletePDFSuccessMsg: '',
                deletePDFErrorMsg: action.response.data.message,
                deletePDFLoading: false,
            });
        case CLEAR_PDF_UPLOAD_DATA:
            return Object.assign({}, state, {
                uploadPDFLinkSuccess: false,
                PDFLinkUploadError: '',
                PDFLinkUploadSuccess: '',
                getDetailsAfterPDFUpload: false,
            });

        default:
            return state;
    }
}
