import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getInternFeedbackDetails} from '../../../../actions/employee/feedbackActions';
import NoDataFound from '../../../../components/app/no-data-found/NoDataFound';
import moment from "moment";
import {BlockLoader} from 'xd-react-loader';

class FeedbackFormDetails extends Component{
    constructor(props){
        super(props)
        this.state= { }
        this.IsJsonString=this.IsJsonString.bind(this)
    }
    
    componentDidMount(){
        const data={
            id:this.props.match.params.id
        }
        this.props.dispatch(getInternFeedbackDetails(data))
    }
    
    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

render(){
    const {internFeedbackDetailErrorMsg, internFeedbackDetail, internFeedbackDetailSuccessMsg, internFeedbackDetailLoading}=this.props
    return(
    <main className="main-content-wrapper">
        <section className="dashboard-content-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                            <h2 className="content-header-title">Module Feedback Details</h2>
                            <p className="content-header-description">Details of the submitted feedback form.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <div className="dashboard-body-wrapper">
            <div className="container">                   
                <div className="main-content-body">                        
                    <section className="content-section-wrapper">
                    {internFeedbackDetailLoading?(
                    <div className="page-section-loader">
                        <BlockLoader
                            loadingText="Loading Form Details .."
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                    </div>
                    ):internFeedbackDetailErrorMsg?(
                       <NoDataFound title={internFeedbackDetailErrorMsg} />
                    ):internFeedbackDetailSuccessMsg?(
                        <div className="feedback-form-wrapper"  >
                        {internFeedbackDetail.length!==0 ?
                        (<><div className="details-container">
                        <div className="user-profile-container">                                        
                            <div className="profile-section_card">
                            <h2 className="profile-section_card_title">Details</h2>
                                <div className="profile-section_grid">
                                    <div className="profile-section_card_item">
                                        <article className="profile-section_card_details">
                                            <div className="profile-card-meta_data">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Name</p>
                                                            <p className="fields-value">{internFeedbackDetail.title!== ''? internFeedbackDetail.title: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Description</p>
                                                            <p className="fields-value">{internFeedbackDetail.description!== ''? internFeedbackDetail.description: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Submitted On</p>
                                                            <p className="fields-value">{internFeedbackDetail.created_at!==0?(moment(new Date(internFeedbackDetail.created_at * 1000)).format('LL')):'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Submitted By</p>
                                                            <p className="fields-value">{internFeedbackDetail.submitted_by?internFeedbackDetail.submitted_by:'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Score</p>
                                                            <p className="fields-value">{internFeedbackDetail.scores?internFeedbackDetail.scores:'N/A'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>                                           
                                    </div>
                                </div>
                            </div>
                        </div>                                               
                        </div> 
                        <div className="details-container">
                            <div className="user-profile-container">
                                <div className="profile-section_card">
                                    <h2 className="profile-section_card_title">Submitted Feedback</h2>
                                    <div className="profile-section_grid">
                                        <div className="profile-section_card_item">
                                            <article className="profile-section_card_details">
                                                <div className="profile-card-meta_data">
                                                    <div className="row">
                                                        {this.IsJsonString(internFeedbackDetail.outline) ? JSON.parse(internFeedbackDetail.outline).map((formField, index) => (
                                                        <div className="col-12 col-sm-6">
                                                            <div className="profile-fields_data">
                                                                <p className="fields-name">Q. {formField.label}</p>
                                                                {formField.name==='check-box-input' ? 
                                                                (<p className="fields-value" style={{paddingLeft:'15px'}}>{formField.key?formField.key.map((key, i)=>
                                                                <li><span style={{color:'gray'}}> </span>{key}</li>):'N/A'}
                                                                </p>):
                                                                <>
                                                                {(formField.name==='radio-input' || formField.name==='select-option') ?
                                                                <p className="fields-value" ><span style={{color:'gray', fontWeight:'normal'}}>A. </span>{formField.key?formField.key:'N/A'}</p>:
                                                                <p className="fields-value" ><span style={{color:'gray', fontWeight:'normal'}}>A. </span>{formField.value?formField.value:'N/A'}</p>}
                                                                </>}
                                                            </div>
                                                        </div>
                                                        )):(<NoDataFound title="No feedback available"/>)}
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>                                               
                        </div>
                        </>):(<NoDataFound title="No data available"/>)}
                        </div>):(
                            <NoDataFound/>
                        )}
                    </section>
                </div>
            </div>
        </div>
    </main>
        )
    }
}

function mapStateToProps(state) {
    const { internFeedbackDetailErrorMsg, internFeedbackDetail, internFeedbackDetailSuccessMsg, internFeedbackDetailLoading}=state.feedbackReducer;
    return {internFeedbackDetailErrorMsg, internFeedbackDetail, internFeedbackDetailSuccessMsg, internFeedbackDetailLoading }
}

export default withRouter(connect(mapStateToProps)(FeedbackFormDetails))
