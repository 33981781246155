import {
    POST_INVITE_REQUEST, POST_INVITE_SUCCESS, POST_INVITE_FAILURE, CLEAR_INTERN_DATA, HANDLE_UPDATE_INTERN_LIST, REMOVE_INTERN_INVITE, ADD_INTERN_INVITE
} from '../constants/Index';

const initialState = {
    isPageloading : true,
    errorMessage:'', 
    successMessage:'',
    showInvite:false,
    internAddedStatusList:{},
    interns:{invite: [
        {email: '', level: ''}
      ]}
  };

  export default function apiReducer(state=initialState, action){
      switch(action.type){
          case CLEAR_INTERN_DATA:
            return Object.assign({}, state, {
                internAddedStatusList:{},
                isPageLoading: false, successMessage: '', errorMessage:'',
                interns:{invite: [
                  {email: '', level: ''}
                ]}
            });
            case POST_INVITE_REQUEST:
              return Object.assign({}, state,{
                  isloading:true, successMessage:'', errorMessage:'',
                  internAddedStatusList:{}
              });
            
            case POST_INVITE_SUCCESS:
                return Object.assign({}, state, {
                    isPageLoading: false,
                    successMessage: '',
                    internAddedStatusList:action.response.data.status,
                });
            case POST_INVITE_FAILURE:
                return Object.assign({}, state, {
                    isPageLoading: false, errorMessage:action.response.data.message,
                    internAddedStatusList:{}
                });
            case REMOVE_INTERN_INVITE:
                state.interns.invite=action.interns
                return Object.assign({}, state, {
                    interns:state.interns
                });
            case ADD_INTERN_INVITE:
                const addedList=state.interns.invite.concat(action.obj)
                state.interns.invite=addedList
                return Object.assign({}, state, {
                    interns:state.interns
                });
            case HANDLE_UPDATE_INTERN_LIST:
                return Object.assign({}, state, {
                    interns: action.internsList
             });
            
            default:
                return state
        
      }
  }