import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Checkbox } from 'xd-react-checkbox';
import {RadioGroup} from 'xd-react-radio';

class FeedbackFormFields extends Component{
    constructor(props){
        super(props)
    }

    removeHandler=(index)=>{
        this.props.removeHandler(index)
    }
    editHandler=(field, index)=>{
        this.props.editHandler(field, index)
    }

    render(){
        const {formField, index}=this.props
        return(
            <>
            {formField.type==="text" &&
                <div className="form-field-group-item">
                                                    <div class="form-group">
                                                    <label>{formField.label}</label>
                                                    {formField.isRequired && <span class="required">*</span>}
                                                        <div class="input-wrapper">
                                                            <input 
                                                                id='feedback-textbox'
                                                                className="form-control" 
                                                                type={formField.type}
                                                                name={formField.name}
                                                                style={{cursor:'not-allowed'}}
                                                                disabled={true}
                                                                autocomplete="off" 
                                                                minlength="3" 
                                                                maxlength="50" 
                                                                required={formField.isRequired} 
                                                                placeholder={formField.placeholder} 
                                                                value="" />
                                                          </div>
                                                    </div>
                                                    <div className="form-fields-action-group">
                                                      <ul>
                                                          <li className="form-fields-action">
                                                                <button type="button" className="custom-icon-button"
                                                                onClick={e => this.removeHandler(index)}>
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                          </li>
                                                          <li className="form-fields-action">
                                                            <button type="button" className="custom-icon-button"
                                                            onClick={e => this.editHandler(formField, index)}>
                                                                <i className="fa fa-pencil"></i>
                                                            </button>
                                                          </li>
                                                      </ul>
                                                    </div>
                                                </div>}
                                                {formField.type==="textarea" &&
                                                    <div className="form-field-group-item">
                                                    <div class="form-group">
                                                    <label>{formField.label}</label>
                                                    {formField.isRequired && <span class="required">*</span>}
                                                        <div class="input-wrapper">
                                                            <textarea 
                                                                id={formField.id}
                                                                className="form-control" 
                                                                type={formField.type}
                                                                name={formField.name}
                                                                autocomplete="off" 
                                                                disabled={true}
                                                                style={{cursor:'not-allowed'}}
                                                                minlength="3" 
                                                                maxlength="50" 
                                                                required={formField.isRequired} 
                                                                placeholder={formField.placeholder} 
                                                                value="" />
                                                          </div>
                                                    </div>
                                                    <div className="form-fields-action-group">
                                                      <ul>
                                                          <li className="form-fields-action">
                                                                <button type="button" className="custom-icon-button"
                                                                onClick={e => this.removeHandler(index)}>
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                          </li>
                                                          <li className="form-fields-action">
                                                            <button type="button" className="custom-icon-button"
                                                            onClick={e => this.editHandler(formField, index)}>
                                                                <i className="fa fa-pencil"></i>
                                                            </button>
                                                          </li>
                                                      </ul>
                                                    </div>
                                                </div>}
                                                {formField.type==="select" &&
                                                <div className="form-field-group-item">
                                                <div class="form-group">
                                                <label>{formField.label}</label>
                                                {formField.isRequired && <span class="required">*</span>}
                                                    <div class="input-wrapper">
                                                    <div className="select-wrapper">
                                                        <select 
                                                            id={formField.id}
                                                            className="form-control" 
                                                            type={formField.type}
                                                            name={formField.name}
                                                            disabled={true}
                                                            autocomplete="off" 
                                                            minlength="3" 
                                                            maxlength="50" 
                                                            required={formField.isRequired} 
                                                            placeholder={formField.placeholder} 
                                                            value="" >
                                                                {formField.options.map((option, i)=>(
                                                                    <option value={option.value}>{option.label}</option>
                                                                ))}
                                                        </select>
                                                      </div>
                                                      </div>
                                                </div>
                                                <div className="form-fields-action-group">
                                                  <ul>
                                                      <li className="form-fields-action">
                                                            <button type="button" className="custom-icon-button"
                                                            onClick={e => this.removeHandler(index)}>
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                      </li>
                                                      <li className="form-fields-action">
                                                        <button type="button" className="custom-icon-button"
                                                        onClick={e => this.editHandler(formField, index)}>
                                                            <i className="fa fa-pencil"></i>
                                                        </button>
                                                      </li>
                                                  </ul>
                                                </div>
                                            </div>}
                                            {formField.type==="radio" &&
                                                <div className="form-field-group-item">
                                                <div class="form-group">
                                                <label>{formField.label}</label>
                                                {formField.isRequired && <span class="required">*</span>}
                                                    <div class="input-wrapper">
                                                        <RadioGroup
                                                            id={formField.id}
                                                            position="vertical"
                                                            className="form-control" 
                                                            type={formField.type}
                                                            name={formField.name}
                                                            disabled={true}
                                                            autocomplete="off" 
                                                            minlength="3" 
                                                            maxlength="50" 
                                                            required={formField.isRequired} 
                                                            placeholder={formField.placeholder} 
                                                            value="" 
                                                            options={formField.options.map((option, i)=>(
                                                                { label: `${option.label}`, value: `${option.value}` }
                                                            ))}
                                                            
                                                        />
                                                      </div>
                                                </div>
                                                <div className="form-fields-action-group">
                                                  <ul>
                                                      <li className="form-fields-action">
                                                            <button type="button" className="custom-icon-button"
                                                            onClick={e => this.removeHandler(index)}>
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                      </li>
                                                      <li className="form-fields-action">
                                                        <button type="button" className="custom-icon-button"
                                                        onClick={e => this.editHandler(formField, index)}>
                                                            <i className="fa fa-pencil"></i>
                                                        </button>
                                                      </li>
                                                  </ul>
                                                </div>
                                            </div>}
                                            {formField.type==="checkbox" &&
                                                <div className="form-field-group-item">
                                                <div class="form-group">
                                                <label>{formField.label}</label>
                                                {formField.isRequired && <span class="required">*</span>}
                                                {formField.options.map((option, i)=>(
                                                    <div class="input-wrapper">
                                                    <div className="form-group" id={formField.id}>
                                            <Checkbox  
                                                type={formField.type}
                                                className="form-control"
                                                disabled={true}
                                                name={formField.name}
                                                labelValue={option.label}
                                                value={option.value}
                                                themeColor="#f99316" />
                                            </div>
                                                  </div>
                                                ))}
                                                    
                                                </div>
                                                <div className="form-fields-action-group">
                                                  <ul>
                                                      <li className="form-fields-action">
                                                            <button type="button" className="custom-icon-button"
                                                            onClick={e => this.removeHandler(index)}>
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                      </li>
                                                      <li className="form-fields-action">
                                                        <button type="button" className="custom-icon-button"
                                                        onClick={e => this.editHandler(formField, index)}>
                                                            <i className="fa fa-pencil"></i>
                                                        </button>
                                                      </li>
                                                  </ul>
                                                </div>
                                            </div>}
            </>
        )
    }
}

function mapStateToProps(state) {
    const {feedbackFormFieldsList,formDetailData, createFeedbackFormSuccess, createFeedbackFormErrorMsg, createFeedbackFormSuccessMsg}=state.feedbackReducer;
    return { feedbackFormFieldsList, formDetailData,  createFeedbackFormSuccess, createFeedbackFormErrorMsg, createFeedbackFormSuccessMsg}
}

export default withRouter(connect(mapStateToProps)(FeedbackFormFields))