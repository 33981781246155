export const UPDATE_LOGIN_FORM_DATA = "UPDATE_LOGIN_FORM_DATA"
export const SAVE_REDIRECT_LINK="SAVE_REDIRECT_LINK";

export const HANDLE_LOGIN_REQUEST = "HANDLE_LOGIN_REQUEST";
export const HANDLE_LOGIN_SUCCESS = "HANDLE_LOGIN_SUCCESS";
export const HANDLE_LOGIN_FAILURE = "HANDLE_LOGIN_FAILURE";
export const DEFAULT_LOGIN_STATE = "DEFAULT_LOGIN_STATE";

export const USER_LOGOUT_REQUEST="USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS="USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE="USER_LOGOUT_FAILURE";

export const MEMBER_ADMIN_REGISTRATION_REQUEST="MEMBER_ADMIN_REGISTRATION_REQUEST";
export const MEMBER_ADMIN_REGISTRATION_SUCCESS="MEMBER_ADMIN_REGISTRATION_SUCCESS";
export const MEMBER_ADMIN_REGISTRATION_FAILURE="MEMBER_ADMIN_REGISTRATION_FAILURE";

export const HANDLE_UPDATE_MEMBER_ADMIN_DATA="HANDLE_UPDATE_MEMBER_ADMIN_DATA";

export const UPDATE_FORGOT_PASSWORD_DATA="UPDATE_FORGOT_PASSWORD_DATA";
export const UPDATE_RESET_PASSWORD_DATA="UPDATE_RESET_PASSWORD_DATA";

export const RESET_PASSWORD_REQUEST="RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS="RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE="RESET_PASSWORD_FAILURE";

export const RESET_PASSWORD_INVITE_REQUEST="RESET_PASSWORD_INVITE_REQUEST";
export const RESET_PASSWORD_INVITE_SUCCESS="RESET_PASSWORD_INVITE_SUCCESS";
export const RESET_PASSWORD_INVITE_FAILURE="RESET_PASSWORD_INVITE_FAILURE";

export const UPDATE_CURRENT_STEP="UPDATE_CURRENT_STEP";

export const HANDLE_UPDATE_MEMBER_DATA = "HANDLE_UPDATE_MEMBER_DATA";
export const MEMBER_REGISTRATION_REQUEST="MEMBER_REGISTRATION_REQUEST";
export const MEMBER_REGISTRATION_SUCCESS="MEMBER_REGISTRATION_SUCCESS";
export const MEMBER_REGISTRATION_FAILURE="MEMBER_REGISTRATION_FAILURE";

export const HANDLE_UPDATE_INTERN_INFO= "HANDLE_UPDATE_INTERN_INFO";
export const HANDLE_UPDATE_PERSONAL_INFO=" HANDLE_UPDATE_PERSONAL_INFO";
export const HANDLE_UPDATE_ADDRESS_INFO=" HANDLE_UPDATE_ADDRESS_INFO";
export const HANDLE_UPDATE_EDUCATION_INFO=" HANDLE_UPDATE_EDUCATION_INFO";
export const HANDLE_UPDATE_EMERGENCY_INFO=" HANDLE_UPDATE_EMERGENCY_INFO";
export const HANDLE_UPDATE_OTHER_INFO="HANDLE_UPDATE_OTHER_INFO";
export const CHANGE_ADDRESS_TYPE="CHANGE_ADDRESS_TYPE"

export const INTERN_REGISTRATION_REQUEST="INTERN_REGISTRATION_REQUEST";
export const INTERN_REGISTRATION_SUCCESS="INTERN_REGISTRATION_SUCCESS";
export const INTERN_REGISTRATION_FAILURE="INTERN_REGISTRATION_FAILURE";

export const ADD_EDUCATION="ADD_EDUCATION";
export const REMOVE_EDUCATION="REMOVE_EDUCATION";

export const ADD_MORE_CETIFICATE="ADD_MORE_CETIFICATE";
export const REMOVE_CETIFICATE="REMOVE_CETIFICATE";

export const UPLOAD_FILE_REQUEST="UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS="UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE="UPLOAD_FILE_FAILURE";

export const UPLOAD_CERTIFICATE_REQUEST="UPLOAD_CERTIFICATE_REQUEST";
export const UPLOAD_CERTIFICATE_SUCCESS="UPLOAD_CERTIFICATE_SUCCESS";
export const UPLOAD_CERTIFICATE_FAILURE="UPLOAD_CERTIFICATE_FAILURE";

export const GET_UPLOADED_FILE_REQUEST="GET_UPLOADED_FILE_REQUEST";
export const GET_UPLOADED_FILE_SUCCESS="GET_UPLOADED_FILE_SUCCESS";
export const GET_UPLOADED_FILE_FAILURE="GET_UPLOADED_FILE_FAILURE";

export const UPLOAD_PROFILE_IMAGE_REQUEST="UPLOAD_PROFILE_IMAGE_REQUEST";
export const UPLOAD_PROFILE_IMAGE_SUCCESS="UPLOAD_PROFILE_IMAGE_SUCCESS";
export const UPLOAD_PROFILE_IMAGE_FAILURE="UPLOAD_PROFILE_IMAGE_FAILURE";

export const DELETE_FILE_REQUEST="DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS="DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE="DELETE_FILE_FAILURE";
export const DELETE_DEGREE_REQUEST="DELETE_DEGREE_REQUEST";
export const DELETE_DEGREE_SUCCESS="DELETE_DEGREE_SUCCESS";
export const DELETE_DEGREE_FAILURE="DELETE_DEGREE_FAILURE";
export const DELETE_CERTIFICATE_REQUEST="DELETE_CERTIFICATE_REQUEST";
export const DELETE_CERTIFICATE_SUCCESS="DELETE_CERTIFICATE_SUCCESS";
export const DELETE_CERTIFICATE_FAILURE="DELETE_CERTIFICATE_FAILURE";
export const DELETE_MARKSHEET_REQUEST="DELETE_MARKSHEET_REQUEST";
export const DELETE_MARKSHEET_SUCCESS="DELETE_MARKSHEET_SUCCESS";
export const DELETE_MARKSHEET_FAILURE="DELETE_MARKSHEET_FAILURE"
