import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../dist/modal.css';
import ReactTelInput from 'react-telephone-input';
import { Button } from 'xd-react-button';
import { Input } from 'xd-react-input';
import {
    getEmployeeProfile,
    handleEditAccountDialogChange,
    updateAccountFields,
    updateProfileDetails,
} from '../../../../actions/employee/userActions';

let phone = null;
class EditAccountPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumberLength: 15,
        };
    }

    componentDidMount() {
        document.getElementsByName('name')[0].focus();
        phone = document.querySelector('input[type="tel"]');
        phone.setAttribute('tabIndex', 2);
    }

    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleEditAccountDialogChange(false));
    }

    handleInputChange(telNumber, selectedCountry, e) {
        document
            .querySelector('input[type="tel"]')
            .setAttribute('maxlength', selectedCountry.format.length);
        //validation for phone number
        if (telNumber.length !== selectedCountry.format.length) {
            phone.parentElement.parentElement.parentElement.classList.add(
                'has-error'
            );
            phone.setCustomValidity('Enter valid phone number.');
        } else {
            phone.parentElement.parentElement.parentElement.classList.remove(
                'has-error'
            );
            phone.setCustomValidity('');
        }
        let newState = {
            name: this.props.useraccountInfo.name,
            contact_no: telNumber,
        };
        this.props.dispatch(updateAccountFields(newState));
    }
    handleInputBlur(telNumber, selectedCountry) {
        if (telNumber.length === selectedCountry.format.length) {
            phone.setCustomValidity('');
        }
        document.querySelector('label.phn').classList.remove('focused');
    }

    setCaretAtEnd(elem) {
        //this function relocate your pointer to the first character of the input field when we focus into the field.
        const elemLen = elem.value.length;
        if (document.selection) {
            elem.focus();
            const oSel = document.selection.createRange();
            oSel.moveStart('character', -elemLen);
            oSel.moveStart('character', elemLen);
            oSel.moveEnd('character', 0);
            oSel.select();
        } else if (elem.selectionStart || elem.selectionStart === 0) {
            elem.selectionStart = elemLen;
            elem.selectionEnd = elemLen;
            elem.focus();
        }
    }

    handleFocus(e) {
        this.setCaretAtEnd(phone);
        document.querySelector('label.phn').classList.add('focused');
    }

    handleOnChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        const newState = Object.assign(this.props.useraccountInfo, {
            [name]: value,
        });
        this.props.dispatch(updateAccountFields(newState));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.updateSuccess !== this.props.updateSuccess) {
           nextProps.dispatch(getEmployeeProfile());
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const details = {
            account_info: {
                name: this.props.useraccountInfo.name,
                contact_no: this.props.useraccountInfo.contact_no,
            },
            personal_info: this.props.profileInfo.profile.personal,
            user_address: this.props.profileInfo.profile.address,
            education: this.props.profileInfo.profile.education,
            emergency_contact: this.props.profileInfo.profile.emergency,
            others: this.props.profileInfo.profile.others,
        };
        if (e.target.checkValidity()) {
            this.props.dispatch(updateProfileDetails(details));
            document.querySelector('#body-root').style = 'overflow:auto;';
        } else {
            if (phone.value.length < 5) {
                phone.setCustomValidity('invalid');
                phone.parentElement.parentElement.classList.add('has-error');
            }
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    render() {
        const { editAccountPopupState } = this.props;
        console.log(editAccountPopupState)
        const accountInfo = this.props.useraccountInfo;
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': editAccountPopupState,
                        fadeOut: !editAccountPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown: editAccountPopupState,
                                fadeOutUp: !editAccountPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">
                                    Account Information
                                </h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form
                                onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true}
                                id="edit-education-dialog"
                            >
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        errorMessage="Enter your name(min 3 char.) "
                                                        placeholder="Name"
                                                        label="Name"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={accountInfo.name}
                                                        minLength={3}
                                                        maxLength={50}
                                                        pattern={
                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group telephone-outer">
                                                    <label className="phn">
                                                        Phone
                                                    </label>
                                                    <span class="required">
                                                        *
                                                    </span>
                                                    <div className="input-wrapper">
                                                        <ReactTelInput
                                                            id="react-phone-control"
                                                            defaultCountry="in"
                                                            country={'in'}
                                                            required={true}
                                                            flagsImagePath={require('../../../../static/images/flags.png')}
                                                            preferredCountries={[
                                                                'in',
                                                                'us',
                                                                'gb',
                                                            ]}
                                                            name="phone"
                                                            onChange={this.handleInputChange.bind(
                                                                this
                                                            )}
                                                            tabIndex={2}
                                                            onFocus={this.handleFocus.bind(
                                                                this
                                                            )}
                                                            onBlur={this.handleInputBlur.bind(
                                                                this
                                                            )}
                                                            value={
                                                                accountInfo.contact_no
                                                            }
                                                        />
                                                        <div className="with-error">
                                                            Please enter a valid
                                                            phone number.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.updateErrorMsg && (
                                            <p style={{ color: 'red' }}>
                                                {this.props.updateErrorMsg}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {editAccountPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: editAccountPopupState,
                            fadeOut: !editAccountPopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        editAccountPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        useraccountInfo,
    } = state.userReducer;
    return {
        editAccountPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        useraccountInfo,
    };
}
export default withRouter(connect(mapStateToProps)(EditAccountPopup));
