import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import {} from '../../../actions/admin/employeeActions';
import { RadioGroup } from 'xd-react-radio';
import './dist/style.css';
import {
    getModuleDetails,
    clearModuleData,
    handleUpdateModule,
    updateFormFields,
    updateTaskFields,
    handleUploadPDFDialogChange,
    getPDFLink,
    deleteUploadedPDF,
} from '../../../actions/admin/internModuleActions';
import createNotification from '../../../components/app/notification/createNotification';
import { BarLoader, BlockLoader } from 'xd-react-loader';
import UploadPdfPopup from './UploadPdfPopup';
class EditModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }
    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleUploadPDFDialogChange(false));
    }
    handleUploadPdf(i) {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleUploadPDFDialogChange(true, i));
    }
    handleGetPDFLink(pdf) {
        this.props.dispatch(getPDFLink(pdf.link));
    }
    handleSubmit(e) {
        let input = document.getElementById('presentation-upload-input');
        if (input.checkValidity()) {
            input.parentElement.classList.remove('has-error');
            input.parentElement.parentElement.classList.remove('has-error');
        } else {
            input.parentElement.classList.add('has-error');
            input.parentElement.parentElement.classList.add('has-error');
        }
    }
    componentWillMount() {
        this.props.dispatch(getModuleDetails(this.props.match.params.moduleId));
    }
    handleRemovePDF(file, index, i) {
        const deleteData = {
            filename: file,
        };
        this.props.tasks[i].attachments.splice(index, 1);
        this.props.dispatch(deleteUploadedPDF(deleteData));
    }
    handleChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
        }
        const newState = Object.assign(this.props.module, {
            [name]: value,
        });
        this.props.dispatch(updateFormFields(newState));
    }

    handleDurationChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        if (e.target.checkValidity() && e.target.value > 1) {
            e.target.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
        }
        const newState = Object.assign(this.props.module, {
            [name]: value,
        });
        this.props.dispatch(updateFormFields(newState));
    }

    addTasks() {
        const tasks = this.props.tasks;
        tasks.push({
            taskname: '',
            description: '',
            resources: [{ link: '' }],
            attachments: [],
        });
        this.props.dispatch(updateTaskFields(tasks));
    }

    removeTask(i) {
        const self = this.props;
        const tasks = self.tasks;
        tasks.splice(i, 1);
        this.props.dispatch(updateTaskFields(tasks));
    }
    removeLinks(e, i, j) {
        const self = this.props;
        const links = self.tasks;
        links[i].resources.splice(j, 1);
        this.forceUpdate();
    }

    addLinks(e, i) {
        let tasks = [];
        tasks = this.props.tasks;
        tasks[i].resources.push({
            link: '',
        });
        this.props.dispatch(updateTaskFields(tasks));
    }
    addPDFS(e, i) {
        let tasks = [];
        tasks = this.props.tasks;
        tasks[i].attachments.push(e);
        this.props.dispatch(updateTaskFields(tasks));
    }

    handleAddTasks(e, i, j) {
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }

        let name = e.target.name;
        let value = e.target.value;
        let tasks = this.props.tasks;

        if (name === 'resources') {
            let links = this.props.tasks[i].resources;
            links[j]['link'] = value;
            tasks[i][name] = links;
        } else {
            tasks[i][name] = value;
        }
        if (name === 'attachments') {
            let attachments = this.props.tasks[i].attachments;
            attachments[j] = value;
            tasks[i][name] = attachments;
        }

        this.props.dispatch(updateTaskFields(tasks));
    }
    moduleSubmit(e) {
        e.preventDefault();
        let finalTaskList = [];
        if (e.target.checkValidity()) {
            for (let task of this.props.tasks) {
                finalTaskList.push({
                    name: task.name,
                    description: task.description,
                    resources: task.resources.map((r, i) => r.link),
                    attachments: task.attachments,
                });
            }
            let body = {
                module: {
                    level: this.props.module.level,
                    name: this.props.module.name,
                    description: this.props.module.description,
                    duration: parseInt(this.props.module.duration),
                },
                task: finalTaskList,
            };
            this.props.dispatch(
                handleUpdateModule(this.props.match.params.moduleId, body)
            );
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                }
            }
            let invalidTextbox = document.querySelectorAll('textarea');
            if (invalidTextbox.length > 0) {
                invalidTextbox[0].focus();
                for (let invalidText of invalidTextbox) {
                    if (invalidText.length <= 3) {
                        invalidText.parentElement.classList.add('has-error');
                    }
                }
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.updateModuleMessage !== '') {
            if (nextProps.updateModuleTasksError) {
                createNotification('error', nextProps.updateModuleMessage);
                nextProps.dispatch(clearModuleData());
            } else {
                createNotification('success', nextProps.updateModuleMessage);
                nextProps.history.push('/admin/employee/list-module/1');
                nextProps.dispatch(clearModuleData());
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <main className="main-content-wrapper">
                    <UploadPdfPopup />
                    <section className="dashboard-content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-left">
                                        <h2 className="content-header-title">
                                            Edit Modules
                                        </h2>
                                        <p className="content-header-description">
                                            Edit the modules for interns.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-right dashboard-header-action-group"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="dashboard-body-wrapper">
                        <div className="container">
                            <div className="main-content-body">
                                <section className="content-section-wrapper">
                                    <div className="row">
                                        <BarLoader
                                            loading={
                                                this.props
                                                    .getModuleDetailsPageLoading
                                            }
                                            loaderStyle={{
                                                size: '6px',
                                                color: '#0562e8',
                                                speed: '1s',
                                            }}
                                        />
                                        {!this.props
                                            .getModuleDetailsPageLoading && (
                                            <div className="col-12 col-sm-12 col-md-9 col-lg-9">
                                                <div className="invite-employee-form-outer content-section-card">
                                                    <form
                                                        className="invite-employee-form"
                                                        noValidate={true}
                                                        id="invute-employee-form"
                                                        onSubmit={this.moduleSubmit.bind(
                                                            this
                                                        )}
                                                    >
                                                        <div class="invite-employee-form-item">
                                                            <div class="row">
                                                                <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                    <div class="form-group">
                                                                        <label>
                                                                            Intern
                                                                            Level{' '}
                                                                            <span class="req">
                                                                                {' '}
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <RadioGroup
                                                                            options={[
                                                                                {
                                                                                    label: 'Level 1',
                                                                                    value: 'L1',
                                                                                },
                                                                                {
                                                                                    label: 'Level 2',
                                                                                    value: 'L2',
                                                                                },
                                                                                {
                                                                                    label: 'Level 3',
                                                                                    value: 'L3',
                                                                                    isDisabled:
                                                                                        'true',
                                                                                },
                                                                            ]}
                                                                            checkedValue={this.props.module.level}
                                                                            name="level"
                                                                            onChange={this.handleChange.bind(
                                                                                this
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                    <div class="form-group">
                                                                        <Input
                                                                            type="text"
                                                                            name="name"
                                                                            id="name"
                                                                            value={
                                                                                this
                                                                                    .props
                                                                                    .module
                                                                                    .name
                                                                            }
                                                                            outerClass="input-ctrl"
                                                                            placeholder="Module Name"
                                                                            required={
                                                                                true
                                                                            }
                                                                            onChange={this.handleChange.bind(
                                                                                this
                                                                            )}
                                                                            minLength={
                                                                                3
                                                                            }
                                                                            maxLength={
                                                                                50
                                                                            }
                                                                            autoComplete={
                                                                                'off'
                                                                            }
                                                                            label="Module Name"
                                                                            className="form-control"
                                                                            outerClassName="signup-input-box"
                                                                            errorMessage="Please enter your module name(Min 3 char.)."
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                    <div class="form-group">
                                                                        <label class="control-label mb-5">
                                                                            Description
                                                                            <span class="req">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <textarea
                                                                            rows="4"
                                                                            column="4"
                                                                            type="text"
                                                                            onChange={this.handleChange.bind(
                                                                                this
                                                                            )}
                                                                            value={
                                                                                this
                                                                                    .props
                                                                                    .module
                                                                                    .description
                                                                            }
                                                                            class="form-control  desciption-textbox"
                                                                            name="description"
                                                                            minlength="30"
                                                                            utocomplete="off"
                                                                            required=""
                                                                            placeholder="Description"
                                                                        ></textarea>
                                                                        <p class="with-error">
                                                                            Please
                                                                            enter
                                                                            description
                                                                            (Min
                                                                            30
                                                                            char.).
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                    <div class="form-group">
                                                                        <Input
                                                                            type="text"
                                                                            name="duration"
                                                                            id="duration"
                                                                            onChange={this.handleDurationChange.bind(
                                                                                this
                                                                            )}
                                                                            value={
                                                                                this
                                                                                    .props
                                                                                    .module
                                                                                    .duration
                                                                            }
                                                                            outerClass="input-ctrl"
                                                                            placeholder="2"
                                                                            required={
                                                                                true
                                                                            }
                                                                            minLength={
                                                                                1
                                                                            }
                                                                            maxLength={
                                                                                50
                                                                            }
                                                                            pattern="^-?([0-9]*\.?[0-9]+|[0-9]+\.?[0-9]*)$"
                                                                            autoComplete={
                                                                                'off'
                                                                            }
                                                                            label="Duration (in Days)"
                                                                            className="form-control"
                                                                            outerClassName="signup-input-box"
                                                                            errorMessage="Please enter your duration & minimum 2 days"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-btn-heading">
                                                                <h4 class="content-heading">
                                                                    Add Tasks
                                                                </h4>
                                                            </div>
                                                            <div className="divider"></div>
                                                            {this.props.tasks
                                                                .length > 0 &&
                                                                this.props.tasks.map(
                                                                    (t, i) => (
                                                                        <div
                                                                            className={
                                                                                i ===
                                                                                0
                                                                                    ? ''
                                                                                    : 'row-wrapper'
                                                                            }
                                                                        >
                                                                            <div class="row">
                                                                                <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                                    <div class="form-group">
                                                                                        <Input
                                                                                            type="text"
                                                                                            name="name"
                                                                                            id="taskname"
                                                                                            value={
                                                                                                t.name
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                this.handleAddTasks(
                                                                                                    e,
                                                                                                    i
                                                                                                )
                                                                                            }
                                                                                            outerClass="input-ctrl"
                                                                                            placeholder="Task Name"
                                                                                            required={
                                                                                                true
                                                                                            }
                                                                                            minLength={
                                                                                                3
                                                                                            }
                                                                                            maxLength={
                                                                                                50
                                                                                            }
                                                                                            autoComplete={
                                                                                                'off'
                                                                                            }
                                                                                            label="Task Name 1"
                                                                                            className="form-control"
                                                                                            outerClassName="signup-input-box"
                                                                                            errorMessage="Please enter your task name(Min 3 char.)."
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row help-links-allignment">
                                                                                {t.resources.map(
                                                                                    (
                                                                                        link,
                                                                                        j
                                                                                    ) => (
                                                                                        <>
                                                                                            <div class="col-12 col-sm-10 col-md-7 col-lg-6 ">
                                                                                                <div class="form-group">
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        name="resources"
                                                                                                        id="help_links"
                                                                                                        value={
                                                                                                            t
                                                                                                                .resources[
                                                                                                                j
                                                                                                            ]
                                                                                                                .link
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            this.handleAddTasks(
                                                                                                                e,
                                                                                                                i,
                                                                                                                j
                                                                                                            )
                                                                                                        }
                                                                                                        outerClass="input-ctrl"
                                                                                                        placeholder="www.google.com"
                                                                                                        required={
                                                                                                            true
                                                                                                        }
                                                                                                        minLength={
                                                                                                            3
                                                                                                        }
                                                                                                        maxLength={
                                                                                                            700
                                                                                                        }
                                                                                                        autoComplete={
                                                                                                            'off'
                                                                                                        }
                                                                                                        label={
                                                                                                            j ===
                                                                                                                0 &&
                                                                                                            'Help Links'
                                                                                                        }
                                                                                                        className="form-control"
                                                                                                        outerClassName="signup-input-box"
                                                                                                        errorMessage="Please enter the help links."
                                                                                                    />
                                                                                                    {t
                                                                                                        .resources
                                                                                                        .length >
                                                                                                        1 &&
                                                                                                        j !==
                                                                                                            0 && (
                                                                                                            <div
                                                                                                                class="col-12 col-sm-3 col-md-1 col-lg-3 cross-button-allignment"
                                                                                                                place="top"
                                                                                                            >
                                                                                                                <Button
                                                                                                                    className={
                                                                                                                        'btn btn-icon-circle btn-compact icon-right  mr-0'
                                                                                                                    }
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        this.removeLinks(
                                                                                                                            e,
                                                                                                                            i,
                                                                                                                            j
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="fa fa-times btn-icon"></i>
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    {t
                                                                                                        .resources
                                                                                                        .length >=
                                                                                                        0 &&
                                                                                                        j ==
                                                                                                            0 && (
                                                                                                            <div class="col-12 col-sm-3 col-md-1 col-lg-3 button-allignment">
                                                                                                                <Button
                                                                                                                    className={
                                                                                                                        t
                                                                                                                            .resources
                                                                                                                            .length ===
                                                                                                                        1
                                                                                                                            ? 'btn btn-icon-circle btn-compact icon-right  mr-24'
                                                                                                                            : 'btn btn-icon-circle btn-compact icon-right  mr-0'
                                                                                                                    }
                                                                                                                    onClick={(
                                                                                                                        e
                                                                                                                    ) =>
                                                                                                                        this.addLinks(
                                                                                                                            e,
                                                                                                                            i
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <i className="fa fa-plus btn-icon"></i>
                                                                                                                </Button>
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                                    <div class="form-group">
                                                                                        <label>
                                                                                            Description
                                                                                            <span class="req">
                                                                                                {' '}
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <textarea
                                                                                            rows="4"
                                                                                            column="4"
                                                                                            type="text"
                                                                                            class="form-control desciption-textbox"
                                                                                            name="description"
                                                                                            value={
                                                                                                t.description
                                                                                            }
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                this.handleAddTasks(
                                                                                                    e,
                                                                                                    i
                                                                                                )
                                                                                            }
                                                                                            minlength="3"
                                                                                            autocomplete="off"
                                                                                            required
                                                                                            placeholder="Description"
                                                                                        ></textarea>
                                                                                        <p class="with-error">
                                                                                            Please
                                                                                            enter
                                                                                            description
                                                                                            (Min
                                                                                            3
                                                                                            char.).
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                                    <div class="form-group">
                                                                                        <label>
                                                                                            Upload
                                                                                            Attachments{' '}
                                                                                        </label>

                                                                                        <div className="presentation-container">
                                                                                            <div
                                                                                                className={
                                                                                                    ''
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    className={
                                                                                                        'presentation_wrapper'
                                                                                                    }
                                                                                                >
                                                                                                    <div className="module-list_item">
                                                                                                        <div className="profile-fields_data education-document-list">
                                                                                                            <p className="fields-name">
                                                                                                                Attachment
                                                                                                            </p>
                                                                                                            {t
                                                                                                                ?.attachments
                                                                                                                ?.length >
                                                                                                            0 ? (
                                                                                                                t?.attachments.map(
                                                                                                                    (
                                                                                                                        j,
                                                                                                                        index
                                                                                                                    ) => (
                                                                                                                        <p className="fields-value fields-value_link upload-line-pdf">
                                                                                                                            {
                                                                                                                                j?.split(
                                                                                                                                    '/'
                                                                                                                                )[1]
                                                                                                                            }
                                                                                                                            <strong
                                                                                                                                onClick={(
                                                                                                                                    e
                                                                                                                                ) =>
                                                                                                                                    this.handleRemovePDF(
                                                                                                                                        j,
                                                                                                                                        index,
                                                                                                                                        i
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                className={`${
                                                                                                                                    t
                                                                                                                                        .attachments
                                                                                                                                        .length >
                                                                                                                                    0
                                                                                                                                        ? 'link-button-remove-overlay'
                                                                                                                                        : 'link-button-remove'
                                                                                                                                } `}
                                                                                                                            >
                                                                                                                                {this
                                                                                                                                    .props
                                                                                                                                    .deletePresentationLoading ? (
                                                                                                                                    <BlockLoader
                                                                                                                                        loading={
                                                                                                                                            true
                                                                                                                                        }
                                                                                                                                        loaderStyle={{
                                                                                                                                            size: '14px',
                                                                                                                                            color: '0562e8',
                                                                                                                                            speed: '1s',
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                ) : (
                                                                                                                                    <i
                                                                                                                                        class="fa fa-times-circle"
                                                                                                                                        style={{
                                                                                                                                            fontSize:
                                                                                                                                                '24px',
                                                                                                                                        }}
                                                                                                                                    ></i>
                                                                                                                                )}
                                                                                                                            </strong>
                                                                                                                        </p>
                                                                                                                    )
                                                                                                                )
                                                                                                            ) : (
                                                                                                                <span className="no-data-text-small">
                                                                                                                    N/A
                                                                                                                </span>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            style={{
                                                                                                marginTop:
                                                                                                    '20px',
                                                                                                padding:
                                                                                                    '0px',
                                                                                            }}
                                                                                        >
                                                                                            <div className="module-list_item">
                                                                                                <Button
                                                                                                    type="button"
                                                                                                    className="btn btn-compact btn-outline"
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        this.handleUploadPdf(
                                                                                                            i
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {' '}
                                                                                                    <i
                                                                                                        class="fa fa-upload"
                                                                                                        aria-hidden="true"
                                                                                                    ></i>{' '}
                                                                                                    Upload
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class={
                                                                                    i ===
                                                                                    0
                                                                                        ? 'none'
                                                                                        : 'cross-btn'
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    <i
                                                                                        data-tip="Left Tooltip"
                                                                                        data-for="Left"
                                                                                        place="top"
                                                                                        class="material-icons"
                                                                                        onClick={() =>
                                                                                            this.removeTask(
                                                                                                i
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        clear
                                                                                    </i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}

                                                            <div class="row">
                                                                <div class="col-12 col-sm-10 col-md-7 col-lg-6">
                                                                    <div class="form-group">
                                                                        <Button
                                                                            type="button"
                                                                            className="btn btn-icon btn-compact icon-left "
                                                                            onClick={() =>
                                                                                this.addTasks()
                                                                            }
                                                                        >
                                                                            <i className="fa fa-plus btn-icon"></i>
                                                                            <span className="btn-text">
                                                                                {' '}
                                                                                Add
                                                                                More
                                                                                Task
                                                                            </span>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="xd-btn-group">
                                                                <Button
                                                                    onClick={() =>
                                                                        this.props.history.push(
                                                                            '/admin/employee/list-module/1'
                                                                        )
                                                                    }
                                                                    className="btn btn-outline"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button type="submit">
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { updateModuleMessage, updateModuleTasksError, module, tasks } =
        state.moduleReducer;
    const {
        moduleData,
        uploadPDFPopupState,
        PDFLink,
        PDFUploadError,
        PDFUploadSuccess,
        PDFLinkUploadError,
        PDFLinkUploadSuccess,
        deletePDFSuccessMsg,
        deletePDFErrorMsg,
        uploadPDFAfterDelete,
        uploadPDFLoading,
    } = state.moduleReducer;
    return {
        updateModuleMessage,
        updateModuleTasksError,
        module,
        tasks,
        moduleData,
        uploadPDFPopupState,
        PDFLink,
        PDFUploadError,
        PDFUploadSuccess,
        PDFLinkUploadError,
        PDFLinkUploadSuccess,
        deletePDFSuccessMsg,
        deletePDFErrorMsg,
        uploadPDFAfterDelete,
        uploadPDFLoading,
    };
}

export default withRouter(connect(mapStateToProps)(EditModule));
