import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './dist/style.css';
import ListModule from './ListModule';
import userRoles from '../../../components/roles/roles';


class ModuleTabs extends Component{
    constructor(props){
        super(props)
        this.state= {
            tabIndex: 0
        }
    }

    handleTabIndex(currentIndex){
        localStorage.setItem('modulesTabIndex', currentIndex )
        this.setState({
            tabIndex:currentIndex
        })
    }
      
render(){
    
    return(
    <main className="main-content-wrapper">
        <section className="dashboard-content-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                            <h2 className="content-header-title">List of Modules</h2>
                            <p className="content-header-description">List of modules that can be assigned to Interns</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-right">
                            <div>
                                {this.props.roleId!== userRoles.Reviewer &&
                                <Button onClick={() => this.props.history.push("/admin/employee/create-module")} className="btn btn-icon icon-left">
                                    <i className="fa fa-plus btn-icon"></i>
                                    <span className="btn-text">Create New Module</span>
                                </Button>
                                }
                            </div>      
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="dashboard-body-wrapper">
            <div className="container">                   
            <div className="main-content-body">                        
                <section className="content-section-wrapper">
                    <div className="react-tabs-section-wrapper">
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.handleTabIndex(tabIndex)}>
                        <TabList>
                            <Tab>
                                <div className="react-tabs-navigation-item-content">
                                    L1 Level
                                </div> 
                            </Tab>
                            <Tab>
                                <div className="react-tabs-navigation-item-content">
                                    L2 Level
                                </div> 
                            </Tab>
                        </TabList>
                        <TabPanel>
                        <div className="react-tabs-content-item">
                            <div className="admin-user-profile-section">
                                <div className="admin-user-profile-content">
                                    <ListModule level='L1'/>
                                </div>
                            </div>
                        </div>
                        </TabPanel>
                        <TabPanel>
                        <div className="react-tabs-content-item">
                            <div className="admin-user-profile-section">
                                <div className="admin-user-profile-content">
                                    <ListModule level='L2'/>
                                </div>
                            </div>
                        </div>
                        </TabPanel>
                    </Tabs>
                    </div>
                </section>
                </div>
            </div>
        </div>
    </main>
        )
    }
}

function mapStateToProps(state) {
    const {roleId } = state.authReducer;
    const {userModuleList}=state.moduleReducer;
    return {  userModuleList, roleId}
}

export default withRouter(connect(mapStateToProps)(ModuleTabs))
