import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import './dist/style.css';
import { Dropdown, DropItem } from 'xd-react-dropdown';
import { Dialogbox } from 'xd-react-dialogbox';
import {
    getReportsList,
    getInternBatchList,
    addMoreFilter,
} from '../../../actions/admin/reportsActions';
import userRoles from '../../../components/roles/roles'
import {
    storeSelectedInternData,
    storeAllInterns,
    removeSelectedInternData,
    clearSelectedEmailData,
    changeLevel,
    clearChangeLevelData,
} from '../../../actions/admin/employeeActions';
import { getModuleList } from '../../../actions/admin/internModuleActions';
import Pagination from 'react-js-pagination';
import createNotification from '../../../components/app/notification/createNotification';
import NoDataFound from '../../../components/app/no-data-found/NoDataFound';
import { BlockLoader } from 'xd-react-loader';
import { Avatar } from 'xd-react-avatar';

class ReportsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            level: '',
            internEmail: '',
            levelChangeEmail: '',
            isAllChecked: false
        };
    }

    componentDidMount() {
        this.props.dispatch(clearSelectedEmailData());
        const data = {
            level: 'L1',
            page: this.state.activePage,
            limit: 10,
            batch: this.props.filtersData.batch,
            module: this.props.filtersData.module,
            sortby: this.props.filtersData.sortby,
        };
        this.props.dispatch(getReportsList(data));
        this.props.dispatch(getInternBatchList());
        const moduleData = {
            page: 1,
        };
        this.props.dispatch(getModuleList(moduleData));
    }
    componentWillMount() {
        this.props.dispatch(clearSelectedEmailData());
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber }, this.getInternsList);
    }
    getInternsList() {
        const data = {
            level: 'L1',
            page: this.state.activePage,
            limit: 10,
            batch: this.props.filtersData.batch,
            module: this.props.filtersData.module,
            sortby: this.props.filtersData.sortby,
        };
        this.props.dispatch(getReportsList(data));
    }
    clearFilters() {
        const newState = {
            batch: '',
            module: '',
            sortby: '',
        };
        this.props.dispatch(addMoreFilter(newState));
        const data = {
            level: 'L1',
            page: this.state.activePage,
            limit: 10,
            batch: '',
            module: '',
            sortby: '',
        };
        this.props.dispatch(getReportsList(data));
        document.getElementById('reports-select-module').value = '';
        document.getElementById('select-batch').value = '';
        document.getElementById('reports-sort').value = '';
    }

    handleBatchFilter(e) {
        const value = e.target.value;
        const name = e.target.name;
        const newState = Object.assign(this.props.filtersData, {
            [name]: value,
        });
        this.props.dispatch(addMoreFilter(newState));
        const page = 1;
        this.handlePageChange(page);
    }
    handleModuleFilter(e) {
        const value = e.target.value;
        const name = e.target.name;
        const newState = Object.assign(this.props.filtersData, {
            [name]: value,
        });
        this.props.dispatch(addMoreFilter(newState));
        const page = 1;
        this.handlePageChange(page);
    }
    checkAllCheckbox(e) {
        var checkboxes = document.getElementsByName('reports');
        let list = [];
        const isChecked = !this.state.isAllChecked;
        this.setState({isAllChecked: isChecked})
        for (var checkbox of checkboxes) {
                checkbox.checked =  isChecked;
        }
        this.props.reportsList.map((m, i) => list.push(m));
        this.props.dispatch(storeAllInterns(list)); 
    }

    checkBoxSelectionEvent(e, intern) {
        if (e.target.checked) {
            this.props.dispatch(storeSelectedInternData(intern));
        } else {
            const removeIndex = this.props.employeeList.indexOf(intern);
            this.props.dispatch(removeSelectedInternData(removeIndex));
        }
    }

    handleSort(e) {
        const value = e.target.value;
        const name = e.target.name;
        const newState = Object.assign(this.props.filtersData, {
            [name]: value,
        });
        this.props.dispatch(addMoreFilter(newState));
        const data = {
            level: 'L1',
            sortby: this.props.filtersData.sortby,
            page: this.state.activePage,
            limit: 10,
            batch: this.props.filtersData.batch,
            module: this.props.filtersData.module,
        };
        this.props.dispatch(getReportsList(data));
    }
    handleLevelChange(e) {
        const level = e.target.value;
        this.setState((state) => {
            state.level = level;
            return state;
        });
    }
    openChangeLevelDialog(e, email) {
        this.setState((state) => {
            state.levelChangeEmail = email;
            return state;
        });
        document.querySelector('#changeLevelDialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
    }
    submitChangeLevelDialog(e) {
        const levelDetails = {
            email: this.state.levelChangeEmail,
            level: this.state.level,
        };
        this.props.dispatch(changeLevel(levelDetails));
        document.querySelector('#changeLevelDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    closeLevelDialogbox() {
        document.querySelector('#changeLevelDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.getReportsAfterLevelChange) {
            createNotification('success', nextProps.changeLevelSuccess);
            nextProps.dispatch(clearChangeLevelData());
            const data = {
                level: 'L1',
                page: this.state.activePage,
                limit: 10,
                batch: this.props.filtersData.batch,
                module: this.props.filtersData.module,
                sortby: this.props.filtersData.sortby,
            };
            this.props.dispatch(getReportsList(data));
        }
        if (nextProps.changeLevelFailure) {
            createNotification('error', nextProps.changeLevelFailure);
            nextProps.dispatch(clearChangeLevelData());
        }
    }

    render() {
        const {
            reportsList,
            isReportsListLoading,
            reportsListSuccessMessage,
            reportsListErrorMessage,
            batchList,
        } = this.props;
        return (
            <React.Fragment>
                <main className="main-content-wrapper">
                    <section className="dashboard-content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-left">
                                        <h2 className="content-header-title">
                                            People Score List
                                        </h2>
                                        <p className="content-header-description">
                                            List of scores
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-right dashboard-header-action-group">
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="dashboard-body-wrapper">
                        <div className="container">
                            <section className="content-section-wrapper">
                                <div class="reports-filters">
                                    <div className="filter-options-right">
                                        <Button
                                            className="btn btn-compact box-shadow"
                                            onClick={this.clearFilters.bind(
                                                this
                                            )}
                                        >
                                            Clear Filters
                                        </Button>
                                    </div>
                                    {this.props.roleId === userRoles.Admin
                                        && (
                                        <div className="filter-options-right">
                                            <Button
                                                className="btn btn-compact btn-outline"
                                                type="button"
                                                onClick={() =>
                                                    this.props.history.push(
                                                        '/admin/employee/view-assignee'
                                                    )
                                                }
                                            >
                                                Assign Module
                                            </Button>
                                        </div>
                                    )}
                                    <div className="filter-options-left">
                                        <div className="select-wrapper">
                                            <select
                                                id="reports-select-module"
                                                onChange={(e) =>
                                                    this.handleModuleFilter(e)
                                                }
                                                name="module"
                                                value={
                                                    this.props.filtersData
                                                        .module
                                                }
                                                className="form-control batch-select"
                                            >
                                                <option
                                                    selected
                                                    disabled={true}
                                                    value=""
                                                    defaultValue
                                                    style={{ color: 'gray' }}
                                                >
                                                    Select Module
                                                </option>
                                                {this.props.moduleList.length
                                                    ? this.props.moduleList.map(
                                                          (module, index) => (
                                                              <>
                                                                  <input type="checkbox" />
                                                                  <option
                                                                      value={
                                                                          module.id
                                                                      }
                                                                      key={
                                                                          index
                                                                      }
                                                                  >
                                                                      {
                                                                          module.name
                                                                      }
                                                                  </option>
                                                              </>
                                                          )
                                                      )
                                                    : ''}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="filter-options-left">
                                        <div className="select-wrapper">
                                            <select
                                                id={'select-batch'}
                                                onChange={(e) =>
                                                    this.handleBatchFilter(e)
                                                }
                                                name="batch"
                                                value={
                                                    this.props.filtersData.batch
                                                }
                                                className="form-control batch-select"
                                            >
                                                <option
                                                    selected
                                                    disabled={true}
                                                    value=""
                                                    defaultValue
                                                    style={{ color: 'gray' }}
                                                >
                                                    Select Batch
                                                </option>
                                                {batchList.length
                                                    ? batchList.map(
                                                          (batch, index) => (
                                                              <option
                                                                  value={
                                                                      batch.CreatedAt
                                                                  }
                                                                  key={index}
                                                              >
                                                                  {
                                                                      batch.CreatedAt
                                                                  }
                                                              </option>
                                                          )
                                                      )
                                                    : ''}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="filter-options-left">
                                        <div className="select-wrapper">
                                            <select
                                                id={'reports-sort'}
                                                onChange={(e) =>
                                                    this.handleSort(e)
                                                }
                                                name="sort"
                                                value={
                                                    this.props.filtersData
                                                        .sortby
                                                }
                                                className="form-control batch-select"
                                            >
                                                <option
                                                    selected
                                                    disabled={true}
                                                    value=""
                                                    defaultValue
                                                    style={{ color: 'gray' }}
                                                >
                                                    Sort By
                                                </option>
                                                <option value="scoring">
                                                    Score
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="user-profile-container"
                                    style={{ clear: 'both' }}
                                >
                                    {isReportsListLoading ? (
                                        <div className="page-section-loader">
                                            <BlockLoader
                                                loadingText="loading.."
                                                loading={true}
                                                loaderStyle={{
                                                    size: '24px',
                                                    color: '0562e8',
                                                    textSize: '14px',
                                                    textColor: '#757575',
                                                    speed: '0.5s',
                                                }}
                                            />
                                        </div>
                                    ) : reportsListErrorMessage ? (
                                        <NoDataFound
                                            title={reportsListErrorMessage}
                                        />
                                    ) : reportsListSuccessMessage ? (
                                        <div className="user-profile-container">
                                            {reportsList.length !== 0 ? (
                                                <div className="container">
                                                    <section className="content-section-wrapper">
                                                        <div className="table-container ">
                                                            <table className="listing-table">
                                                                <thead>
                                                                    <tr>
                                                                        <td>
                                                                            <input
                                                                                name= "parentcheckbox"
                                                                                type="checkbox"
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    this.checkAllCheckbox(
                                                                                        e
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <th class="avatar"></th>
                                                                        <th className="intern-score-name">
                                                                            Name
                                                                        </th>
                                                                        <th className="score-list-email">
                                                                            Email
                                                                        </th>
                                                                        <th className="intern-score">
                                                                            Score
                                                                        </th>
                                                                        {this
                                                                            .props
                                                                            .roleId !==
                                                                            userRoles.Reviewer && (
                                                                            <th className="score-list-actions">
                                                                                Actions
                                                                            </th>
                                                                        )}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {reportsList.map(
                                                                        (
                                                                            intern,
                                                                            i
                                                                        ) => {
                                                                            return (
                                                                                <tr name="list">
                                                                                    <td>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            name="reports"
                                                                                            onClick={(
                                                                                                e
                                                                                            ) =>
                                                                                                this.checkBoxSelectionEvent(
                                                                                                    e,
                                                                                                    intern
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td className='content avatar'>
                                                                        <Avatar
                                                                        src={
                                                                            intern.link !==
                                                                            ''
                                                                                ? intern.link
                                                                                : require('../../../static/images/profile-placeholder.png')
                                                                        }
                                                                        size={
                                                                            35
                                                                        }
                                                                        marginRight={
                                                                            2
                                                                        }
                                                                    />
                                                                        
                                                                        </td>         
                                                                                    <td className="content intern-name">
                                                                                        <NavLink
                                                                                            className="intern-link"
                                                                                            style={{
                                                                                                color: '#2a3bad',
                                                                                            }}
                                                                                            to={`/admin/employee/l1/${intern.email} `}
                                                                                        >
                                                                                            {
                                                                                                intern.name
                                                                                            }
                                                                                        </NavLink>
                                                                                    </td>
                                                                                    <td
                                                                                        className={`content ${
                                                                                            this
                                                                                                .props
                                                                                                .roleId !==
                                                                                            userRoles.Reviewer
                                                                                                ? 'score-list-email'
                                                                                                : 'score-email'
                                                                                        }`}
                                                                                    >
                                                                                        {' '}
                                                                                        {
                                                                                            intern.email
                                                                                        }
                                                                                    </td>
                                                                                    <td className="intern-scores">
                                                                                        {
                                                                                            intern.marks
                                                                                        }
                                                                                        {intern.marks !==
                                                                                            0 &&
                                                                                            '%'}
                                                                                    </td>
                                                                                    {this
                                                                                        .props
                                                                                        .roleId !==
                                                                                        userRoles.Reviewer && (
                                                                                        <td
                                                                                            className="content score-list-actions"
                                                                                            style={
                                                                                                this
                                                                                                    .props
                                                                                                    .roleId ===
                                                                                                userRoles.Reviewer
                                                                                                    ? {
                                                                                                          pointerEvents:
                                                                                                              'none',
                                                                                                      }
                                                                                                    : {
                                                                                                          pointerEvents:
                                                                                                              'auto',
                                                                                                      }
                                                                                            }
                                                                                        >
                                                                                            <Dropdown>
                                                                                                <DropItem
                                                                                                    name="Change Level"
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        this.openChangeLevelDialog(
                                                                                                            e,
                                                                                                            intern.email
                                                                                                        )
                                                                                                    }
                                                                                                    dropLink={
                                                                                                        '/admin/reports/list'
                                                                                                    }
                                                                                                />
                                                                                            </Dropdown>
                                                                                        </td>
                                                                                    )}
                                                                                    <Dialogbox
                                                                                        title="Change Level"
                                                                                        successButton="Change"
                                                                                        cancelButton="Cancel"
                                                                                        submitDialog={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.submitChangeLevelDialog(
                                                                                                e
                                                                                            )
                                                                                        }
                                                                                        closeDialog={
                                                                                            this
                                                                                                .closeLevelDialogbox
                                                                                        }
                                                                                        dialogBoxID="changeLevelDialog"
                                                                                    >
                                                                                        <p>
                                                                                            Select
                                                                                            the
                                                                                            new
                                                                                            level
                                                                                        </p>
                                                                                        <div className="select-wrapper">
                                                                                            <select
                                                                                                className="form-control"
                                                                                                onChange={this.handleLevelChange.bind(
                                                                                                    this
                                                                                                )}
                                                                                                name="change-level"
                                                                                                id="change-level"
                                                                                            >
                                                                                                <option
                                                                                                    disabled={
                                                                                                        true
                                                                                                    }
                                                                                                    value=""
                                                                                                    selected
                                                                                                >
                                                                                                    Select
                                                                                                    Level
                                                                                                </option>
                                                                                                <option value="L1">
                                                                                                    L1
                                                                                                </option>
                                                                                                <option value="L2">
                                                                                                    L2
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </Dialogbox>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </section>
                                                </div>
                                            ) : (
                                                <NoDataFound description="No reports list data was found for this component" />
                                            )}
                                        </div>
                                    ) : (
                                        <NoDataFound />
                                    )}
                                </div>
                            </section>
                        </div>
                        <div
                            className="pagination-container"
                            style={{ marginLeft: '60px' }}
                        >
                            <Pagination
                                activePage={this.state.activePage}
                                prevPageText={
                                    <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                    <i className="fa fa-angle-right" />
                                }
                                itemsCountPerPage={10}
                                totalItemsCount={this.props.reportsListCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {
        isReportsListLoading,
        reportsListCount,
        reportsListSuccessMessage,
        reportsListErrorMessage,
        reportsList,
        isBatchListLoading,
        batchListSuccessMessage,
        batchListErrorMessage,
        batchList,
        filtersData,
    } = state.reportsReducer;
    const { moduleList, moduleMessage, getModulePageLoading } =
        state.moduleReducer;
    const {
        employeeList,
        internsList,
        changeLevelSuccess,
        changeLevelFailure,
        getReportsAfterLevelChange,
    } = state.employeeReducer;
    const {roleId} = state.authReducer;
    return {
        roleId,
        internsList,
        employeeList,
        isReportsListLoading,
        reportsListCount,
        reportsListSuccessMessage,
        reportsListErrorMessage,
        reportsList,
        isBatchListLoading,
        batchListSuccessMessage,
        batchListErrorMessage,
        batchList,
        moduleList,
        moduleMessage,
        getModulePageLoading,
        filtersData,
        changeLevelSuccess,
        changeLevelFailure,
        getReportsAfterLevelChange,
    };
}

export default withRouter(connect(mapStateToProps)(ReportsList));
