import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { notJoinedInterns } from '../../../actions/admin/adminDashboardActions';

class NotJoinedIntern extends Component {
    componentWillMount() {
        const body = {
            limit: 10,
            page: 1,
            status: 'invited',
        };
            this.props.dispatch(notJoinedInterns(body));
    }
    dropdown() {
        document
            .getElementsByClassName('panel-wrapper')[3]
            .classList.toggle('panel-wrapper-display');
        document
            .getElementsByClassName('down-arrow')[3]
            .classList.toggle('down-arrow-rotate');
    }
    render() {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div className="panel panel-warning card-view custom-panel pool-panel">
                    <div className="panel-heading">
                        <h5 className="panel-title">Invitation Pending</h5>
                        <div>
                            <a
                                className="pull-left inline-block mr-15 txt-light"
                                data-toggle="collapse"
                                href="JavaScript:void(0"
                                aria-expanded="false"
                                onClick={this.dropdown.bind(this)}
                            >
                                <img
                                    src={require('../../../static/images/down-arrow.png')}
                                    className="zmdi zmdi-chevron-down down-arrow"
                                />{' '}
                            </a>
                        </div>
                    </div>
                    <div id="collapse_4" className="panel-wrapper collapse in">
                        <div className="panel-body">
                            <div className="table-wrap fix-height">
                                <div className="table-responsive">
                                    <table
                                        id="myTable1"
                                        className="table display table-hover pb-30"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="">Email</th>
                                                <th className="">Level</th>
                                                <th className="">Role</th>
                                                <th className="">Status</th>
                                            </tr>
                                        </thead>
                                        {!this.props.notJoinedInternsError ? (
                                            this.props.notJoinedInternsCount !==
                                            0 ? (
                                                this.props.internsData.map(
                                                    (pendingIntern, i) => (
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {pendingIntern.email !==
                                                                        undefined &&
                                                                    pendingIntern.email !==
                                                                        ''
                                                                        ? pendingIntern.email
                                                                        : 'NA'}
                                                                </td>

                                                                <td>
                                                                    {pendingIntern.level !==
                                                                        undefined &&
                                                                    pendingIntern.level !==
                                                                        ''
                                                                        ? pendingIntern.level
                                                                        : 'NA'}
                                                                </td>

                                                                <td>
                                                                    {
                                                                        pendingIntern.role
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        pendingIntern.account_status
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                )
                                            ) : (
                                                <div className="internMessage">
                                                    <p>No User Available</p>
                                                </div>
                                            )
                                        ) : (
                                            this.props
                                                .notJoinedInternsError && (
                                                <div className="internMessage">
                                                    <p>No User Available</p>
                                                </div>
                                            )
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const { internsData, notJoinedInternsError, notJoinedInternsCount } =
        state.adminDashboardReducer;
        const { roleId } = state.authReducer;
    return {
        roleId,
        internsData,
        notJoinedInternsError,
        notJoinedInternsCount,
    };
};
export default withRouter(connect(mapStateToProps)(NotJoinedIntern));
