import config from "../config";
import { logout } from "./auth/authActions";

export function baseApi() {
    return config.app.REACT_APP_BASE_URL;
}

export function getDocumentApi() {
    return config.app.REACT_APP_BASE_URL+"/docs-manage";
}

export function accountApi() {
    return config.app.REACT_APP_BASE_URL+"/auth";
}

export function moduleApi() {
    return config.app.REACT_APP_BASE_URL+"/module-tracker";
}

export function websiteURL() {
    return config.app.REACT_APP_WEBSITE_URL
}

export function isTokenValid() {
    const token = localStorage.getItem('token');
    let config = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
    return dispatch => {
        // We dispatch requestLogin to kickoff the call to the API
        fetch(accountApi()+'/v1/refreshtoken', config)
            .then(function (response) {
                if (response.status === 401) {
                    dispatch(logout())
                }
                return response.json();
            }).then(function (user) {
                if (user.error === true) {
                    dispatch(logout())
                }
                else {
                    localStorage.setItem('token', user.token);
                    localStorage.setItem('created', Date.now());
                    localStorage.setItem('expire', user.expire);
                    localStorage.setItem('loginTime', new Date().toISOString());
                    localStorage.setItem('showNotification', true);
                }
            }, function (error) {
                dispatch(logout())
            })
    }
}