/**
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 * The purpose of this constant is to-
 * check wheather user is logged in or logged out,
 * if logged in - user can visit any routes (eg. /tenants, /users, /hosta)
 */

import React from 'react';
import {  Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {checkAuth} from './Route'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        checkAuth()?
            <Component {...props}/>
            : <Redirect to='/login' />
    )}/>
);

function mapStateToProps(state){
    return state
}

export default connect(mapStateToProps)(PrivateRoute);
