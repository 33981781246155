import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../dist/modal.css';
import { Button } from 'xd-react-button';
import { Input } from 'xd-react-input';
import { Avatar } from 'xd-react-avatar';

import {
    handleEditProfilePictureDialogChange,
    changeProfileImage,
    updateProfileDetails,
    deleteProfilePicture,
} from '../../../../actions/employee/userActions';

class EditProfilePicturePopup extends Component {
   
    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleEditProfilePictureDialogChange(false));
    }

    handleOnChange(e) {
        let input = e.target;
        if (input.files[0]) {
            let file = input.files[0];
            this.props.dispatch(changeProfileImage(file));
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const details = {
            account_info: {
                name: this.props.profileInfo.profile.account.name,
                contact_no: this.props.profileInfo.profile.account.contact_no,
            },
            personal_info: this.props.userpersonalInfo,
            user_address: this.props.profileInfo.profile.address,
            education: this.props.profileInfo.profile.education,
            emergency_contact: this.props.profileInfo.profile.emergency,
            others: this.props.profileInfo.profile.others,
        };
        if (e.target.checkValidity()) {
            if (this.props.profileInfo.profile.personal.profile_img_link) {
                this.props.dispatch(
                    deleteProfilePicture(
                        this.props.profileInfo.profile.personal
                            .profile_img_link,
                        details
                    )
                );
            } else {
                this.props.dispatch(updateProfileDetails(details));
            }
            document.querySelector('#body-root').style = 'overflow:auto;';
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    render() {
        const { editProfilePicturePopupState, profileInfo } =
            this.props;
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': editProfilePicturePopupState,
                        fadeOut: !editProfilePicturePopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown: editProfilePicturePopupState,
                                fadeOutUp: !editProfilePicturePopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">
                                    Personal Information
                                </h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form
                                onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true}
                                id="edit-education-dialog"
                            >
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    {profileInfo.link === '' ? (
                                                        <Avatar
                                                            size={100}
                                                            name={
                                                                profileInfo
                                                                    .profile
                                                                    .account
                                                                    .name !== ''
                                                                    ? profileInfo
                                                                          .profile
                                                                          .account
                                                                          .name
                                                                    : 'XS'
                                                            }
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            color={'#F99316'}
                                                            size={120}
                                                            name={
                                                                profileInfo
                                                                    .profile
                                                                    .account
                                                                    .name !== ''
                                                                    ? profileInfo
                                                                          .profile
                                                                          .account
                                                                          .name
                                                                    : 'XS'
                                                            }
                                                            src={
                                                                this.props
                                                                    .profilePreviewLink
                                                                    ? this.props
                                                                          .profilePreviewLink
                                                                    : profileInfo.link
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <Input
                                                        type="file"
                                                        required
                                                        className="form-control"
                                                        placeholder="Mother's name"
                                                        label="Profile Picture"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.updateErrorMsg && (
                                            <p style={{ color: 'red' }}>
                                                {this.props.updateErrorMsg}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {editProfilePicturePopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: editProfilePicturePopupState,
                            fadeOut: !editProfilePicturePopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        editProfilePicturePopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        userpersonalInfo,
        profilePreviewLink,
    } = state.userReducer;
    return {
        editProfilePicturePopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        userpersonalInfo,
        profilePreviewLink,
    };
}
export default withRouter(connect(mapStateToProps)(EditProfilePicturePopup));
