import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/progress-module.css';
import { internModuleProgress } from '../../../actions/admin/adminDashboardActions';

class ModuleProgress extends Component {
    componentWillMount() {
        this.props.dispatch(internModuleProgress());
    }

    dropdown() {
        document
            .getElementsByClassName('panel-wrapper')[0]
            .classList.toggle('panel-wrapper-display');
        document
            .getElementsByClassName('down-arrow')[0]
            .classList.toggle('down-arrow-rotate');
    }
    render() {
        return (
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="panel panel-default card-view custom-panel pool-panel">
                    <div className="panel-heading">
                        <h5 className="panel-title">In Progress Module</h5>
                        <div>
                            <a
                                className="pull-left inline-block mr-15 txt-light collapsed"
                                data-toggle="collapse"
                                href="JavaScript:void(0"
                                aria-expanded="false"
                                onClick={this.dropdown.bind(this)}
                            >
                                <img
                                    src={require('../../../static/images/down-arrow.png')}
                                    className="zmdi zmdi-chevron-down down-arrow"
                                />{' '}
                            </a>
                        </div>
                    </div>
                    <div id="candidates" className="panel-wrapper collapse in">
                        <div className="panel-body">
                            <div className="table-wrap fix-height">
                                <div className="table-responsive">
                                    <table
                                        id="myTable1"
                                        className="table display table-hover pb-30"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="column2">
                                                    Name
                                                </th>
                                                <th className="column2">
                                                    Level
                                                </th>
                                                <th className="column2">
                                                    Module
                                                </th>
                                                <th className="column2">
                                                    Percentage
                                                </th>

                                                <th className="column2">
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!this.props
                                                .internsModuleProgressError ? (
                                                this.props
                                                    .internsModuleProgressCount !==
                                                0 ? (
                                                    this.props.internsModuleProgressData.map(
                                                        (internsData) => (
                                                            <tr>
                                                                <td>
                                                                    <a
                                                                        href={`/onboarding/admin/employee/${internsData.level}/${internsData.userid} `}
                                                                        className="collapsed"
                                                                        aria-expanded="false"
                                                                    >
                                                                        {internsData.userid !==
                                                                            undefined &&
                                                                        internsData.userid !==
                                                                            ''
                                                                            ? internsData.userid
                                                                            : 'NA'}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    {internsData.level !==
                                                                        undefined &&
                                                                    internsData.level !==
                                                                        ''
                                                                        ? internsData.level
                                                                        : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {internsData.name !==
                                                                        undefined &&
                                                                    internsData.name !==
                                                                        ''
                                                                        ? internsData.name
                                                                        : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {internsData.score !==
                                                                        undefined &&
                                                                    internsData.score !==
                                                                        ''
                                                                        ? internsData.score
                                                                        : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {internsData.status !==
                                                                        undefined &&
                                                                    internsData.status !==
                                                                        ''
                                                                        ? internsData.status
                                                                        : 'NA'}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <div className="internMessage">
                                                        <p>No User Available</p>
                                                    </div>
                                                )
                                            ) : (
                                                this.props
                                                    .internsModuleProgressError && (
                                                    <div className="internMessage">
                                                        <p>
                                                            No Modules Assigned
                                                            Yet
                                                        </p>
                                                    </div>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const {
        internsModuleProgressData,
        internsModuleProgressError,
        internsModuleProgressCount,
        internsModuleProgressMessage,
    } = state.adminDashboardReducer;

    return {
        internsModuleProgressData,
        internsModuleProgressError,
        internsModuleProgressCount,
        internsModuleProgressMessage,
    };
};
export default withRouter(connect(mapStateToProps)(ModuleProgress));
