import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../dist/modal.css';
import { Button } from 'xd-react-button';
import createNotification from '../../../../components/app/notification/createNotification';
import { Input } from 'xd-react-input';
import {
    handleChangePasswordDialogChange,
    updatePasswordData,
    changePassword,
    clearPasswordChangeData,
} from '../../../../actions/employee/userActions';

class ChangePasswordPopup extends Component {
    
    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleChangePasswordDialogChange(false));
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.passwordSuccessMsg) {
            createNotification('success', nextProps.passwordSuccessMsg);
            nextProps.dispatch(clearPasswordChangeData());
            document.querySelector('#body-root').style = 'overflow:auto;';
            this.props.dispatch(handleChangePasswordDialogChange(false));
        }
    }

    handleOnChange(e) {
        const newState = Object.assign(this.props.newPasswordData, {
            [e.target.name]: e.target.value,
        });
        this.props.dispatch(updatePasswordData(newState));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
        // validate confirm password
        if (
            this.props.newPasswordData.new_password !==
            this.props.newPasswordData.confirm_new_password
        ) {
            if (this.props.newPasswordData.confirm_new_password !== '') {
                e.target.parentElement.parentElement.classList.add('has-error');
            } else {
                e.target.parentElement.parentElement.classList.remove(
                    'has-error'
                );
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.dispatch(changePassword(this.props.newPasswordData));
            document.querySelector('#body-root').style = 'overflow:auto;';
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    render() {
        const { changePasswordPopupState } = this.props;
        const { new_password } = this.props.newPasswordData;
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': changePasswordPopupState,
                        fadeOut: !changePasswordPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'change-password-dialog xd-modal-dialog xd-modal-dialog-sm animated',
                            {
                                fadeInDown: changePasswordPopupState,
                                fadeOutUp: !changePasswordPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">
                                    Change Password
                                </h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form
                                onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true}
                                id="change-password-dialog"
                            >
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <div>
                                                    <Input
                                                        required
                                                        type="password"
                                                        name="old_password"
                                                        autofill={'false'}
                                                        label="Current Password"
                                                        className="form-control"
                                                        outerClassName="signup-input-box"
                                                        placeholder="Current Password"
                                                        id="password"
                                                        errorMessage="Please enter your current password."
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                    />
                                                    {this.props
                                                        .passwordErrorMsg ==
                                                        'Please enter correct current password' && (
                                                        <p
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {
                                                                this.props
                                                                    .passwordErrorMsg
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12">
                                                <div className="form-group">
                                                    <label>
                                                        New Password{' '}
                                                        <span className="req">
                                                            {' '}
                                                            *
                                                        </span>
                                                    </label>
                                                    <input
                                                        required
                                                        type="password"
                                                        name="new_password"
                                                        autofill={'false'}
                                                        label="New Password"
                                                        pattern={
                                                            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
                                                        }
                                                        className="form-control"
                                                        outerClassName="signup-input-box"
                                                        placeholder="New Password"
                                                        id="password"
                                                        errorMessage="Please enter your new password (Min 8 char., atleast one upper case letter, one number and one special character )."
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                    />
                                                    <div
                                                        className="help-block with-errors"
                                                        style={{
                                                            color: 'unset',
                                                        }}
                                                    >
                                                        Enter your password (
                                                        <span
                                                            style={{
                                                                color: `${
                                                                    new_password.length >=
                                                                    8
                                                                        ? 'green'
                                                                        : '#f03030'
                                                                }`,
                                                            }}
                                                        >
                                                            Min 8 char.
                                                        </span>
                                                        ,{' '}
                                                        <span
                                                            style={{
                                                                color: `${
                                                                    new_password.match(
                                                                        '(?=.*?[A-Z])'
                                                                    )
                                                                        ? '#279627'
                                                                        : '#f03030'
                                                                }`,
                                                            }}
                                                        >
                                                            at least one
                                                            uppercase letter
                                                        </span>
                                                        ,{' '}
                                                        <span
                                                            style={{
                                                                color: `${
                                                                    new_password.match(
                                                                        '(?=.*?[a-z])'
                                                                    )
                                                                        ? '#279627'
                                                                        : '#f03030'
                                                                }`,
                                                            }}
                                                        >
                                                            one lowercase letter
                                                        </span>
                                                        ,{' '}
                                                        <span
                                                            style={{
                                                                color: `${
                                                                    new_password.match(
                                                                        '(?=.*?[0-9])'
                                                                    )
                                                                        ? '#279627'
                                                                        : '#f03030'
                                                                }`,
                                                            }}
                                                        >
                                                            one number and{' '}
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: `${
                                                                    new_password.match(
                                                                        '(?=.*?[#?!@$%^&*-])'
                                                                    )
                                                                        ? '#279627'
                                                                        : '#f03030'
                                                                }`,
                                                            }}
                                                        >
                                                            one special
                                                            character required
                                                        </span>
                                                        ).
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-12">
                                                <div className="form-group">
                                                    <Input
                                                        required
                                                        type="password"
                                                        visibility="true"
                                                        passwordShowData='<i  class="fa fa-eye-slash eye-icon" aria-hidden="true"/>'
                                                        passwordHideData='<i  class="fa fa-eye eye-icon" aria-hidden="true"/>'
                                                        name="confirm_new_password"
                                                        autofill={'false'}
                                                        label="Confirm New Password"
                                                        maxLength={30}
                                                        minLength={3}
                                                        className="form-control"
                                                        outerClassName="signup-input-box"
                                                        placeholder="Confirm New Password"
                                                        id="confirm-password"
                                                        errorMessage="Password didn't match."
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {this.props.passwordErrorMsg &&
                                                this.props.passwordErrorMsg !==
                                                    'Please enter correct current password' && (
                                                    <p style={{ color: 'red' }}>
                                                        {
                                                            this.props
                                                                .passwordErrorMsg
                                                        }
                                                    </p>
                                                )}
                                            {this.props.passwordSuccessMsg && (
                                                <p style={{ color: 'green' }}>
                                                    {
                                                        this.props
                                                            .passwordSuccessMsg
                                                    }
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {changePasswordPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: changePasswordPopupState,
                            fadeOut: !changePasswordPopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        changePasswordPopupState,
        newPasswordData,
        changePasswordSuccess,
        passwordSuccessMsg,
        passwordErrorMsg,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
    } = state.userReducer;
    return {
        changePasswordPopupState,
        newPasswordData,
        changePasswordSuccess,
        passwordSuccessMsg,
        passwordErrorMsg,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
    };
}
export default withRouter(connect(mapStateToProps)(ChangePasswordPopup));
