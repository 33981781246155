import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../dist/modal.css';
import { Button } from 'xd-react-button';
import { RadioGroup } from 'xd-react-radio';
import { Input } from 'xd-react-input';
import {
    handleEditPersonalDialogChange,
    updateProfileFields,
    updateProfileDetails,
    getEmployeeProfile,
} from '../../../../actions/employee/userActions';

class EditPersonalPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            genderError: '',
            maritalError: '',
        };
    }

    componentDidMount() {
        if (this.props.updateSuccess) {
            this.props.history.push('/employee/profile/personal-information');
        }
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.updateSuccess !== this.props.updateSuccess) {
           nextProps.dispatch(getEmployeeProfile());
        }
    }
    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleEditPersonalDialogChange(false));
    }

    handleOnChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        const newState = Object.assign(this.props.userpersonalInfo, {
            [name]: value,
        });
        this.props.dispatch(updateProfileFields(newState));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const details = {
            account_info: {
                name: this.props.profileInfo.profile.account.name,
                contact_no: this.props.profileInfo.profile.account.contact_no,
            },
            personal_info: this.props.userpersonalInfo,
            user_address: this.props.profileInfo.profile.address,
            education: this.props.profileInfo.profile.education,
            emergency_contact: this.props.profileInfo.profile.emergency,
            others: this.props.profileInfo.profile.others,
        };

        if (
            e.target.checkValidity() &&
            this.props.userpersonalInfo.gender.length !== 0 &&
            this.props.userpersonalInfo.marital_Status.length !== 0
        ) {
            this.props.dispatch(updateProfileDetails(details));
            document.querySelector('#body-root').style = 'overflow:auto;';
            this.forceUpdate();
        } else {
            if (this.props.userpersonalInfo.gender.length == 0) {
                this.setState({ genderError: 'Please select gender' });
            } else {
                this.setState({ genderError: '' });
            }
            if (this.props.userpersonalInfo.marital_Status.length == 0) {
                this.setState({ maritalError: 'Please select marital status' });
            } else {
                this.setState({ maritalError: '' });
            }
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    render() {
        const { editPersonalPopupState } = this.props;
        const personalInfo = this.props.userpersonalInfo;
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': editPersonalPopupState,
                        fadeOut: !editPersonalPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown: editPersonalPopupState,
                                fadeOutUp: !editPersonalPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">
                                    Personal Information
                                </h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form
                                onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true}
                                id="edit-education-dialog"
                            >
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <Input
                                                        type="text"
                                                        name="fathers_name"
                                                        className="form-control"
                                                        errorMessage="Enter your father's name(min 3 char.)"
                                                        placeholder="Father's name"
                                                        minLength={3}
                                                        maxLength={50}
                                                        label="Father's Name"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={
                                                            personalInfo.fathers_name
                                                        }
                                                        pattern={
                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <Input
                                                        type="text"
                                                        name="mothers_name"
                                                        className="form-control"
                                                        errorMessage="Enter your mother's name(min 3 char.)"
                                                        placeholder="Mother's name"
                                                        label="Mother's name"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={
                                                            personalInfo.mothers_name
                                                        }
                                                        minLength={3}
                                                        maxLength={50}
                                                        pattern={
                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <Input
                                                        type="date"
                                                        name="dateofbirth"
                                                        className="form-control"
                                                        min="1980-01-01"
                                                        max={new Date().toISOString().split('T')[0]} 
                                                        errorMessage="Enter your date of birth."
                                                        placeholder="Date of birth"
                                                        label="Date of Birth"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={
                                                            personalInfo.dateofbirth
                                                        }
                                                        pattern={
                                                            '([a-zA-Z0-9-_])+$'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Select Blood group
                                                        <span className="required">
                                                            *
                                                        </span>{' '}
                                                    </label>
                                                    <div className="select-wrapper">
                                                        <select
                                                            name="blood_group"
                                                            className="form-control"
                                                            placeholder="Blood gruop"
                                                            onChange={this.handleOnChange.bind(
                                                                this
                                                            )}
                                                            required
                                                            value={
                                                                personalInfo.blood_group
                                                            }
                                                        >
                                                            <option
                                                                disabled={true}
                                                                selected
                                                                value=""
                                                                defaultValue
                                                            >
                                                                Select Blood
                                                                group
                                                            </option>
                                                            <option value="A+">
                                                                A+
                                                            </option>
                                                            <option value="A-">
                                                                A-
                                                            </option>
                                                            <option value="B+">
                                                                B+
                                                            </option>
                                                            <option value="B-">
                                                                B-
                                                            </option>
                                                            <option value="O+">
                                                                O+
                                                            </option>
                                                            <option value="O-">
                                                                O-
                                                            </option>
                                                            <option value="AB+">
                                                                AB+
                                                            </option>
                                                            <option value="AB-">
                                                                AB-
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="with-error">
                                                        Select blood group.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <RadioGroup
                                                        label="Gender"
                                                        className="form-control"
                                                        id="gender"
                                                        required={true}
                                                        value={
                                                            personalInfo.gender
                                                        }
                                                        checkedValue={
                                                            personalInfo.gender
                                                        }
                                                        name="gender"
                                                        errorMessage="Select gender"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        options={[
                                                            {
                                                                label: 'Male',
                                                                value: 'male',
                                                            },
                                                            {
                                                                label: 'Female',
                                                                value: 'female',
                                                            },
                                                        ]}
                                                    />
                                                    <p className="selecterror">
                                                        {this.state.genderError}
                                                    </p>
                                                    <div className="with-error">
                                                        Select gender.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <RadioGroup
                                                        label="Marital Status"
                                                        className="form-control"
                                                        id="marital-status"
                                                        required={true}
                                                        value={
                                                            personalInfo.marital_Status
                                                        }
                                                        checkedValue={
                                                            personalInfo.marital_Status
                                                        }
                                                        name="marital_Status"
                                                        errorMessage="Select marital status"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        options={[
                                                            {
                                                                label: 'Single',
                                                                value: 'single',
                                                            },
                                                            {
                                                                label: 'Married',
                                                                value: 'married',
                                                            },
                                                        ]}
                                                    />
                                                    <p className="selecterror">
                                                        {
                                                            this.state
                                                                .maritalError
                                                        }
                                                    </p>
                                                    <div className="with-error">
                                                        Select marita; status.
                                                    </div>
                                                </div>
                                            </div>
                                            {personalInfo.marital_Status ===
                                                'married' && (
                                                <>
                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="text"
                                                            name="spouse_name"
                                                            id="spouse_name"
                                                            placeholder="Spouse's Name"
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            value={
                                                                personalInfo.spouse_name
                                                            }
                                                            pattern={
                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                            }
                                                            autoComplete={'off'}
                                                            label="Spouse's Name"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter your spouse's name."
                                                            onChange={this.handleOnChange.bind(
                                                                this
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="text"
                                                            name="spouse_contact"
                                                            id="spouse_contact"
                                                            placeholder="Spouse's Phone Number"
                                                            required={true}
                                                            value={
                                                                personalInfo.spouse_contact
                                                            }
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '[7-9]{1}[0-9]{9}'
                                                            }
                                                            autoComplete={'off'}
                                                            label="Spouse's Phone"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter your spouse's Phone."
                                                            onChange={this.handleOnChange.bind(
                                                                this
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {this.props.updateErrorMsg && (
                                            <p style={{ color: 'red' }}>
                                                {this.props.updateErrorMsg}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {editPersonalPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: editPersonalPopupState,
                            fadeOut: !editPersonalPopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        editPersonalPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        userpersonalInfo,
        educationInfo,
        addressInfo,
    } = state.userReducer;
    return {
        editPersonalPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        userpersonalInfo,
        educationInfo,
        addressInfo,
    };
}
export default withRouter(connect(mapStateToProps)(EditPersonalPopup));
