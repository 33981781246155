import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import { BlockLoader } from 'xd-react-loader';
import ProfileSubHeader from './ProfileSubHeader';
import StateData from '../../../static/data/state.json';
import {
    getEmployeeProfile,
    updateProfileFields,
    updateProfileDetails,
    updateAddressFields,
} from '../../../actions/employee/userActions';

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            districts: [],
            districts_perm: [],
            newdate: '',
        };
        this.handleProfileChange = this.handleProfileChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getEmployeeProfile());
        let year = new Date(Date.now()).getFullYear();
        let month = new Date(Date.now()).getUTCMonth();
        let day = new Date(Date.now()).getDate();
        let date = new Date(year - 18, month, day);
        this.setState({
            newdate:
                date.getFullYear() +
                '-' +
                (date.getMonth() < 10
                    ? '0' + date.getMonth().toString()
                    : date.getMonth()) +
                '-' +
                date.getDate(),
        });
    }
    handleProfileChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        const newState = Object.assign(this.props.profileInfo.personal_info, {
            [name]: value,
        });
        this.props.dispatch(updateProfileFields(newState));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
    }
    handleAddressChange(e, i) {
        let value = e.target.value;
        const name = e.target.name;
        let addressData = this.props.profileInfo.address_info;

        if (name == 'state' && i == 0) {
            StateData.country.states.map((state, index) => {
                if (state.label == value) {
                    this.setState({
                        districts: state.districts,
                    });
                }
            });
        }
        if (name == 'state' && i == 1) {
            StateData.country.states.map((state, index) => {
                if (state.label == value) {
                    this.setState({
                        districts_perm: state.districts,
                    });
                }
            });
        }

        addressData[i][name] = value;
        this.props.dispatch(updateAddressFields(addressData));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const details = {
            personal_info: this.props.profileInfo.personal_info,
            user_address: this.props.profileInfo.address_info,
            education: this.props.profileInfo.education_details,
            emergency_contact: this.props.profileInfo.emergency_contacts,
            others: this.props.profileInfo.others_certificates,
        };
        if (e.target.checkValidity()) {
            this.props.dispatch(updateProfileDetails(details));
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.input-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }
    handleCancel() {
        this.props.history.push('/employee/UserProfile');
    }

    render() {
        const userProfile = this.props.profileInfo;
        return (
            <main className="main-content-wrapper employee-profile-wrapper">
                <ProfileSubHeader />
                <div className="container emp-profile">
                    <div className="profile-head">
                        <div className="row">
                            <ul
                                className=" profile-nav-tabs"
                                id="myTab"
                                role="tablist"
                            >
                                <li className="profile-nav-item">
                                    <NavLink
                                        to="/employee/UserProfile"
                                        className="profile-nav-link active "
                                        id="home-tab"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-controls="home"
                                        aria-selected="true"
                                    >
                                        Personal Information
                                    </NavLink>
                                </li>
                                <li className="profile-nav-item">
                                    <li>
                                        <NavLink
                                            to="/employee/EducationInfo"
                                            className="profile-nav-link"
                                            id="home-tab"
                                            data-toggle="tab"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true"
                                        >
                                            Educational Information
                                        </NavLink>
                                    </li>
                                </li>
                                <li className="profile-nav-item">
                                    <li>
                                        <NavLink
                                            to="/employee/AccountInfo"
                                            className="profile-nav-link"
                                            id="home-tab"
                                            data-toggle="tab"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true"
                                        >
                                            Account Information
                                        </NavLink>
                                    </li>
                                </li>
                                <li className="profile-nav-item">
                                    <li>
                                        <NavLink
                                            to="/employee/OtherInfo"
                                            className="profile-nav-link"
                                            id="home-tab"
                                            data-toggle="tab"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected="true"
                                        >
                                            Other
                                        </NavLink>
                                    </li>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <form method="post">
                        <div className="row">
                            <div className="col-md-4">
                                <div class="profile-img">
                                    <img
                                        src={
                                            userProfile
                                                ? userProfile.link
                                                : 'https://www.jamf.com/jamf-nation/img/default-avatars/generic-user-purple.png'
                                        }
                                        style={{
                                            width: '200px',
                                            height: '200px',
                                            borderRadius: '100px',
                                        }}
                                        alt=""
                                    />
                                    <div class="file btn btn-lg btn-primary">
                                        Change Photo
                                        <input type="file" name="file" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3 style={{ paddingTop: '40px' }}>
                                    Personal Information
                                </h3>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="profile-work"></div>
                            </div>
                            <div className="col-md-8">
                                <div
                                    className="tab-content profile-tab"
                                    id="myTabContent"
                                >
                                    <div
                                        className="tab-pane fade show active"
                                        id="home"
                                        role="tabpanel"
                                        aria-labelledby="home-tab"
                                    >
                                        {this.props.isUserProfileLoading ? (
                                            <div>
                                                <BlockLoader
                                                    loadingText="loading"
                                                    loading={true}
                                                    loaderStyle={{
                                                        size: '20px',
                                                        color: '0562e8',
                                                        textSize: '18px',
                                                        textColor: '#485e7d',
                                                        speed: '1s',
                                                    }}
                                                />
                                            </div>
                                        ) : this.props.profileErrorMsg ? (
                                            <h3>
                                                {this.props.profileErrorMsg}
                                            </h3>
                                        ) : this.props.getProfileSuccess ? (
                                            <React.Fragment>
                                                <div class="row input-group">
                                                    <div class="col-md-6">
                                                        <label>
                                                            Father's Name
                                                        </label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input
                                                            type="text"
                                                            required
                                                            className="form-control edit-field"
                                                            id="fathers_name"
                                                            value={
                                                                this.props
                                                                    .profileInfo
                                                                    .personal_info
                                                                    .fathers_name
                                                            }
                                                            name="fathers_name"
                                                            onChange={(e) =>
                                                                this.handleProfileChange(
                                                                    e
                                                                )
                                                            }
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                            }
                                                            autoComplete={'off'}
                                                            errorMessage="Please enter your father's name(Min 3 char.)."
                                                        />
                                                        <div className="help-block with-errors">
                                                            Please enter valid
                                                            name(min 3 char.
                                                            required).
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row input-group">
                                                    <div class="col-md-6">
                                                        <label>
                                                            Mother's Name
                                                        </label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input
                                                            className="form-control edit-field"
                                                            type="text"
                                                            required
                                                            minLength={3}
                                                            maxLength={50}
                                                            name="mothers_name"
                                                            value={
                                                                this.props
                                                                    .profileInfo
                                                                    .personal_info
                                                                    .mothers_name
                                                            }
                                                            label="Mother's Name"
                                                            pattern={
                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                            }
                                                            onChange={(e) =>
                                                                this.handleProfileChange(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <div className="help-block with-errors">
                                                            Please enter valid
                                                            name(min 3 char.
                                                            required).
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>
                                                            Date of Birth
                                                        </label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input
                                                            className="form-control edit-field"
                                                            type="date"
                                                            required
                                                            name="dateofbirth"
                                                            max={new Date().toISOString().split('T')[0]} 
                                                            value={
                                                                this.props
                                                                    .profileInfo
                                                                    .personal_info
                                                                    .dateofbirth
                                                            }
                                                            label="Date Of Birth"
                                                            onChange={this.handleProfileChange.bind(
                                                                this
                                                            )}
                                                        />
                                                        <div className="help-block with-errors">
                                                            Please enter date of
                                                            birth.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>
                                                            Blood Group
                                                        </label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select
                                                            className="form-control edit-field"
                                                            required
                                                            type="text"
                                                            name="blood_group"
                                                            value={
                                                                this.props
                                                                    .profileInfo
                                                                    .personal_info
                                                                    .blood_group
                                                            }
                                                            label="Blood Group"
                                                            onChange={this.handleProfileChange.bind(
                                                                this
                                                            )}
                                                        >
                                                            <option
                                                                disabled={true}
                                                                selected
                                                                value=""
                                                                defaultValue
                                                            >
                                                                Select Blood
                                                                group
                                                            </option>
                                                            <option value="A+">
                                                                A+
                                                            </option>
                                                            <option value="A-">
                                                                A-
                                                            </option>
                                                            <option value="B+">
                                                                B+
                                                            </option>
                                                            <option value="B-">
                                                                B-
                                                            </option>
                                                            <option value="O+">
                                                                O+
                                                            </option>
                                                            <option value="O-">
                                                                O-
                                                            </option>
                                                            <option value="AB+">
                                                                AB+
                                                            </option>
                                                            <option value="AB-">
                                                                AB-
                                                            </option>
                                                        </select>
                                                        <div className="help-block with-errors">
                                                            Please enter blood
                                                            group.
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.props.profileInfo.address_info.map(
                                                    (address, i) => (
                                                        <div>
                                                            <div>
                                                                <h3>
                                                                    {
                                                                        address.address_type
                                                                    }
                                                                </h3>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <label>
                                                                        Address
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input
                                                                        required
                                                                        className="form-control edit-field"
                                                                        style={{
                                                                            width: '350px',
                                                                        }}
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        type="text"
                                                                        name="address"
                                                                        value={
                                                                            address.address
                                                                        }
                                                                        label="Address"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleAddressChange(
                                                                                e,
                                                                                i
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="help-block with-errors">
                                                                        Please
                                                                        enter
                                                                        valid
                                                                        address(min
                                                                        3 char.
                                                                        required).
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <label>
                                                                        Country
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input
                                                                        className="form-control edit-field"
                                                                        required
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        name="country"
                                                                        value={
                                                                            address.country
                                                                        }
                                                                        label="Country"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleAddressChange(
                                                                                e,
                                                                                i
                                                                            )
                                                                        }
                                                                    />

                                                                    <div className="help-block with-errors">
                                                                        Please
                                                                        enter
                                                                        Country.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <label>
                                                                        State
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input
                                                                        className="form-control edit-field"
                                                                        type="text"
                                                                        required
                                                                        name="state"
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        pattern={
                                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                        }
                                                                        value={
                                                                            address.state
                                                                        }
                                                                        label="State"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleAddressChange(
                                                                                e,
                                                                                i
                                                                            )
                                                                        }
                                                                    />

                                                                    <div className="help-block with-errors">
                                                                        Please
                                                                        select
                                                                        state.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <label>
                                                                        City
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input
                                                                        className="form-control edit-field"
                                                                        type="text"
                                                                        required
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        pattern={
                                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                        }
                                                                        name="city"
                                                                        value={
                                                                            address.city
                                                                        }
                                                                        label="City"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleAddressChange(
                                                                                e,
                                                                                i
                                                                            )
                                                                        }
                                                                    />

                                                                    <div className="help-block with-errors">
                                                                        Please
                                                                        enter
                                                                        city.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <label>
                                                                        District
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input
                                                                        className="form-control edit-field"
                                                                        type="text"
                                                                        required
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        pattern={
                                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                        }
                                                                        name="district"
                                                                        value={
                                                                            address.district
                                                                        }
                                                                        label="District"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleAddressChange(
                                                                                e,
                                                                                i
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="help-block with-errors">
                                                                        Please
                                                                        enter
                                                                        district.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                <div
                                                    style={{
                                                        display: 'inline',
                                                        margin: '20px',
                                                    }}
                                                    className="submit-btn"
                                                >
                                                    <Button
                                                        className="update-btn"
                                                        type="submit"
                                                        onClick={this.handleSubmit.bind(
                                                            this
                                                        )}
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'inline',
                                                    }}
                                                    className="cancel-btn"
                                                >
                                                    <Button
                                                        className="cancel-btn"
                                                        onClick={this.handleCancel.bind(
                                                            this
                                                        )}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        userPersonalInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
    } = state.userReducer;
    return {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        userPersonalInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
    };
}

export default withRouter(connect(mapStateToProps)(EditProfile));
