import {ADD_NEW_FROM_FIELDS, REMOVE_FORM_FIELDS,  ADD_NEW_DROPDOWN_OPTION, REMOVE_DROPDOWN_OPTION,
    GET_FEEDBACK_FORM_LIST_REQUEST, GET_FEEDBACK_FORM_LIST_SUCCESS, GET_FEEDBACK_FORM_LIST_FAILURE, UPDATE_FORM_DETAIL_DATA,
    SET_SELECTED_FEEDBACK_FORM, DELETE_FEEDBACK_FORM_REQUEST, DELETE_FEEDBACK_FORM_SUCCESS, DELETE_FEEDBACK_FORM_FAILURE,
    CLEAR_FEEDBACK_FORM_DATA, GET_FEEDBACK_FORM_DETAIL_REQUEST, GET_FEEDBACK_FORM_DETAIL_SUCCESS, GET_FEEDBACK_FORM_DETAIL_FAILURE,
    UPDATE_FEEDBACK_FORM_DETAILS, UPDATE_FEEDBACK_FORM_REQUEST, UPDATE_FEEDBACK_FORM_SUCCESS, UPDATE_FEEDBACK_FORM_FAILURE,
    ASSIGN_FEEDBACK_FORM_REQUEST, ASSIGN_FEEDBACK_FORM_SUCCESS, ASSIGN_FEEDBACK_FORM_FAILURE, CLEAR_ASSIGN_FEEDBACK_FORM_DATA, 
    EDIT_FORM_DIALOG_STATE, SET_FORM_FIELD_DATA, UPDATE_FORM_FIELDS, CLEAR_REASSIGN_FORM_DATA,
    REASSIGN_FEEDBACK_FORM_REQUEST, REASSIGN_FEEDBACK_FORM_SUCCESS, REASSIGN_FEEDBACK_FORM_FAILURE,
    CREATE_FEEDBACK_FORM_REQUEST, CREATE_FEEDBACK_FORM_SUCCESS, CREATE_FEEDBACK_FORM_FAILURE, CLEAR_CREATE_FEEDBACK_FORM_DATA,
    UPDATE_REMOVE_FIELDS, CLEAR_UPDATE_FORM_DATA, ADD_NEW_UPDATE_FIELD, UPDATE_FORM_DIALOG_CHANGE, EDIT_UPDATE_FORM_FIELD,
    ADD_FORM_FEEDBACK_DATA, GET_MODULE_FEEDBACK_FORM_REQUEST, GET_MODULE_FEEDBACK_FORM_SUCCESS, GET_MODULE_FEEDBACK_FORM_FAILURE,
    SUBMIT_FEEDBACK_FORM_REQUEST, SUBMIT_FEEDBACK_FORM_SUCCESS, SUBMIT_FEEDBACK_FORM_FAILURE, CLEAR_SUBMIT_FEEDBACK_DATA,
    GET_INTERN_FEEDBACK_LIST_REQUEST, GET_INTERN_FEEDBACK_LIST_SUCCESS, GET_INTERN_FEEDBACK_LIST_FAILURE,
    GET_INTERN_FEEDBACK_REQUEST, GET_INTERN_FEEDBACK_SUCCESS, GET_INTERN_FEEDBACK_FAILURE} from '../../constants/employee/feedbackConstants';
const initialState = {
    feedbackFormFieldsList:[
         {
            "id":"form-field1",
            "name":"Input Box",
            "type":"text",
            "isRequired": false,
            "placeholder":"Input Box",
            "label": "Question?"
        },
    ],
    formDetailData:{
        "title":"",
        "description":""
    },
    feedbackFormDropdownList:[
        {
            "id":"select-option",
            "name":'select-option',
            "type":"dropdown",
            "isRequired": false,
            "placeholder": "select option",
            "label": "Select"        
        }
    ],
    feedbackListSuccess:false,
    feedbackListErrorMsg:'', 
    feedbackFormList: [], 
    feedbackSuccessMsg:'', 
    isFeedbackListLoading:false,

    selectedFeedbackForm:{},

    deleteFeedbackFormSuccess:false, 
    deleteFeedbackErrorMsg:'', 
    deleteFeedbackSuccessMsg:'',
    getFeedbackList:false,

    feedbackDetailSuccess:false,
    feedbackDetailErrorMsg:'', 
    feedbackFormDetails: {}, 
    feedbackDetailSuccessMsg:'', 
    feedbackDetailLoading:false,

    updateFeedbackSuccess:false, 
    updateFeedbackErrorMsg:'', 
    updateFeedbackSuccessMsg:'',

    assignFeedbackSuccess:false, 
    assignFeedbackErrorMsg:'', 
    assignFeedbackSuccessMsg:'',
    assignFeedbackSuccessList:[], 
    assignFeedbackErrorList:[],

    editFormPopupState:false,
    formFieldData: [],
    formFieldIndex:0,

    reassignFeedbackSuccess:false, 
    reassignFeedbackErrorMsg:'', 
    reassignFeedbackSuccessMsg:'', 

    createFeedbackFormSuccess:false, 
    createFeedbackFormLoading:false,
    createFeedbackFormErrorMsg:'', 
    createFeedbackFormSuccessMsg:'',
    updateFormPopupState:false,

    moduleFeedbackFormSuccess:false,
    moduleFeedbackFormErrorMsg:'', 
    moduleFeedbackForm: {}, 
    moduleFeedbackFormSuccessMsg:'', 
    moduleFeedbackFormLoading:false,

    submitFeedbackFormSuccess:false, 
    submitFeedbackFormLoading:false, 
    submitFeedbackFormErrorMsg:'', 
    submitFeedbackFormSuccessMsg:'',

    internFeedbackListSuccess:false,
    internFeedbackErrorMsg:'', 
    internFeedbackList: [], 
    internFeedbackSuccessMsg:'', 
    internFeedbackListLoading:false,

    internFeedbackDetailSuccess:false,
    internFeedbackDetailErrorMsg:'', 
    internFeedbackDetail: {}, 
    internFeedbackDetailSuccessMsg:'', 
    internFeedbackDetailLoading:false
}
export default function feedbackReducer(state=initialState, action){
    switch(action.type){
        case ADD_NEW_FROM_FIELDS:
            return Object.assign({}, state,{
                feedbackFormFieldsList:state.feedbackFormFieldsList.concat(action.fieldsProperty)
            })
        case REMOVE_FORM_FIELDS:
            return Object.assign({}, state, {
                feedbackFormFieldsList: action.fieldsProperty
            }); 
        case ADD_NEW_DROPDOWN_OPTION:
            let options=state.formFieldData[action.index].options.concat(action.data)
            return {...state,
                formFieldData:state.formFieldData.map((item, i)=>(
                    i===action.index? {...item, options: options}: item
                ))
            }
        case REMOVE_DROPDOWN_OPTION:
            return Object.assign({}, state, {
                formFieldData: action.data
            }); 
        case UPDATE_FORM_DETAIL_DATA:
            return Object.assign({}, state, {
                formDetailData:action.data
            })
        case GET_FEEDBACK_FORM_LIST_REQUEST:
            return Object.assign({}, state, {
                feedbackListSuccess:false,feedbackFormList: [], isFeedbackListLoading:true, getFeedbackList:false
            })
        case GET_FEEDBACK_FORM_LIST_SUCCESS:
            return Object.assign({}, state, {
                feedbackListSuccess:true,feedbackListErrorMsg:'', feedbackFormList: action.response.data.list, feedbackSuccessMsg:action.response.data.message, isFeedbackListLoading:false
            })
        case GET_FEEDBACK_FORM_LIST_FAILURE:
            return Object.assign({}, state, {
                feedbackListSuccess:false,feedbackListErrorMsg:action.response.data.message, feedbackFormList: [], feedbackSuccessMsg:'', isFeedbackListLoading:false
            })
        case SET_SELECTED_FEEDBACK_FORM:
            return Object.assign({}, state, {
                selectedFeedbackForm: action.data
            })
        case DELETE_FEEDBACK_FORM_REQUEST:
            return Object.assign({}, state, {
                deleteFeedbackFormSuccess:false, deleteFeedbackErrorMsg:'', deleteFeedbackSuccessMsg:''
            })
        case DELETE_FEEDBACK_FORM_SUCCESS:
            return Object.assign({}, state, {
                deleteFeedbackFormSuccess:true, deleteFeedbackErrorMsg:'', deleteFeedbackSuccessMsg:action.response.data.message, getFeedbackList:true
            })
        case DELETE_FEEDBACK_FORM_FAILURE:
            return Object.assign({}, state, {
                deleteFeedbackFormSuccess:false, deleteFeedbackErrorMsg:action.response.data.message, deleteFeedbackSuccessMsg:''
            })
        case CLEAR_FEEDBACK_FORM_DATA:
            return Object.assign({}, state, {
                deleteFeedbackErrorMsg:'',
                deleteFeedbackSuccessMsg:'',
                getFeedbackList:false
            })
        case GET_FEEDBACK_FORM_DETAIL_REQUEST:
            return Object.assign({}, state, {
                feedbackDetailSuccess:false,feedbackDetailErrorMsg:'', feedbackFormDetails: {}, feedbackDetailSuccessMsg:'', feedbackDetailLoading:true
            })
        case GET_FEEDBACK_FORM_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                feedbackDetailSuccess:true,feedbackDetailErrorMsg:'', feedbackFormDetails:action.response.data.form, feedbackDetailSuccessMsg:action.response.data.message, feedbackDetailLoading:false
            })
        case GET_FEEDBACK_FORM_DETAIL_FAILURE:
            return Object.assign({}, state, {
                feedbackDetailSuccess:false,feedbackDetailErrorMsg:action.response.data.message, feedbackFormDetails: {}, feedbackDetailSuccessMsg:'', feedbackDetailLoading:false
            })
        case GET_MODULE_FEEDBACK_FORM_REQUEST:
            return Object.assign({}, state, {
                moduleFeedbackFormSuccess:false,moduleFeedbackFormErrorMsg:'', moduleFeedbackForm: {}, moduleFeedbackFormSuccessMsg:'', moduleFeedbackFormLoading:true
            })
        case GET_MODULE_FEEDBACK_FORM_SUCCESS:
            return Object.assign({}, state, {
                moduleFeedbackFormSuccess:true, moduleFeedbackFormErrorMsg:'', moduleFeedbackForm: action.response.data.form, moduleFeedbackFormSuccessMsg:action.response.data.message, moduleFeedbackFormLoading:false
            })
        case GET_MODULE_FEEDBACK_FORM_FAILURE:
            return Object.assign({}, state, {
                moduleFeedbackFormSuccess:false,moduleFeedbackFormErrorMsg:action.response.data.message, moduleFeedbackForm: {}, moduleFeedbackFormSuccessMsg:'', moduleFeedbackFormLoading:false
            })
        case UPDATE_FEEDBACK_FORM_DETAILS:
            return Object.assign({}, state, {
                feedbackFormDetails:action.data
            })
        case UPDATE_FEEDBACK_FORM_REQUEST:
            return Object.assign({}, state, {
                updateFeedbackSuccess:false, updateFeedbackErrorMsg:'', updateFeedbackSuccessMsg:''
            })
        case UPDATE_FEEDBACK_FORM_SUCCESS:
            return Object.assign({}, state, {
                updateFeedbackSuccess:true, updateFeedbackErrorMsg:'', updateFeedbackSuccessMsg:action.response.data.message
            })
        case UPDATE_FEEDBACK_FORM_FAILURE:
            return Object.assign({}, state, {
                updateFeedbackSuccess:false, updateFeedbackErrorMsg:action.response.data.message, updateFeedbackSuccessMsg:''
            })
        case ASSIGN_FEEDBACK_FORM_REQUEST:
            return Object.assign({}, state, {
                assignFeedbackSuccess:false, assignFeedbackErrorMsg:'', assignFeedbackSuccessMsg:''
            })
        case ASSIGN_FEEDBACK_FORM_SUCCESS:
            return Object.assign({}, state, {
                assignFeedbackSuccess:true, assignFeedbackErrorMsg:'', assignFeedbackSuccessMsg:action.response.data.message, assignFeedbackSuccessList:action.response.data.successfull, assignFeedbackErrorList:action.response.data.unsuccessfull
            })
        case ASSIGN_FEEDBACK_FORM_FAILURE:
            return Object.assign({}, state, {
                assignFeedbackSuccess:false, assignFeedbackErrorMsg:action.response.data.message, assignFeedbackSuccessMsg:'', assignFeedbackSuccessList:[], assignFeedbackErrorList:[]
            })
        case CLEAR_ASSIGN_FEEDBACK_FORM_DATA:
            return Object.assign({}, state, {
                assignFeedbackErrorMsg:'', assignFeedbackSuccessMsg:'', assignFeedbackSuccessList:[], assignFeedbackErrorList:[]
            })
        case EDIT_FORM_DIALOG_STATE:
            return Object.assign({}, state, {
                editFormPopupState:action.status
            })
        case UPDATE_FORM_DIALOG_CHANGE:
            return Object.assign({}, state, {
                updateFormPopupState:action.status
            })
        case SET_FORM_FIELD_DATA:
            return Object.assign({}, state, {
                formFieldData: action.data,
                formFieldIndex: action.index
            })
        case UPDATE_FORM_FIELDS:
            return Object.assign({}, state, {
                feedbackFormFieldsList: action.data
            })
        case EDIT_UPDATE_FORM_FIELD:
            return {...state,
                feedbackFormDetails:{
                    ...state.feedbackFormDetails,
                        outline:JSON.stringify(action.data)
                }
            }
        case REASSIGN_FEEDBACK_FORM_REQUEST:
            return Object.assign({}, state, {
                reassignFeedbackSuccess:false, reassignFeedbackErrorMsg:'', reassignFeedbackSuccessMsg:''
            })
        case REASSIGN_FEEDBACK_FORM_SUCCESS:
            return Object.assign({}, state, {
                reassignFeedbackSuccess:true, reassignFeedbackErrorMsg:'', reassignFeedbackSuccessMsg:action.response.data.message
            })
        case REASSIGN_FEEDBACK_FORM_FAILURE:
            return Object.assign({}, state, {
                reassignFeedbackSuccess:false, reassignFeedbackErrorMsg:action.response.data.message, reassignFeedbackSuccessMsg:''
            })
        case CLEAR_REASSIGN_FORM_DATA:
            return Object.assign({}, state, {
                reassignFeedbackSuccess:false, reassignFeedbackErrorMsg:'', reassignFeedbackSuccessMsg:''
            })
        case CREATE_FEEDBACK_FORM_REQUEST:
            return Object.assign({}, state, {
                createFeedbackFormSuccess:false, createFeedbackFormLoading:true, createFeedbackFormErrorMsg:'', createFeedbackFormSuccessMsg:''
            })
        case CREATE_FEEDBACK_FORM_SUCCESS:
            return Object.assign({}, state, {
                createFeedbackFormSuccess:true, createFeedbackFormLoading:false, createFeedbackFormErrorMsg:'', createFeedbackFormSuccessMsg:action.response.data.message
            })
        case CREATE_FEEDBACK_FORM_FAILURE:
            return Object.assign({}, state, {
                createFeedbackFormSuccess:false, createFeedbackFormLoading:false, createFeedbackFormErrorMsg:action.response.data.message, createFeedbackFormSuccessMsg:''
            })
        case CLEAR_CREATE_FEEDBACK_FORM_DATA:
            return Object.assign({}, state, {
                createFeedbackFormSuccess:false, createFeedbackFormErrorMsg:'', createFeedbackFormSuccessMsg:'', 
                feedbackFormFieldsList:[
                    {
                       "id":"form-field1",
                       "name":"Input Box",
                       "type":"text",
                       "isRequired": false,
                       "placeholder":"Input Box",
                       "label": "Question?"
                   },
               ],
               formDetailData:{
                   "title":"",
                   "description":""
               }
            })
        case UPDATE_REMOVE_FIELDS:
            return {...state,
                feedbackFormDetails:{
                    ...state.feedbackFormDetails,
                        outline:JSON.stringify(action.data)
                }
            }
        case CLEAR_UPDATE_FORM_DATA:
            return Object.assign({}, state, {
                updateFeedbackSuccess:false, updateFeedbackErrorMsg:'', updateFeedbackSuccessMsg:''
            })
        case ADD_NEW_UPDATE_FIELD:
            return {...state,
                feedbackFormDetails:{
                    ...state.feedbackFormDetails,
                    outline:JSON.stringify(JSON.parse(state.feedbackFormDetails.outline).concat(action.data))
                }
                
            }
        case ADD_FORM_FEEDBACK_DATA:
            return {...state,
                moduleFeedbackForm:{
                    ...state.moduleFeedbackForm,
                    outline:JSON.stringify(action.data)
                }
            }
        case SUBMIT_FEEDBACK_FORM_REQUEST:
            return Object.assign({}, state, {
                submitFeedbackFormSuccess:false, submitFeedbackFormLoading:true, submitFeedbackFormErrorMsg:'', submitFeedbackFormSuccessMsg:''
            })
        case SUBMIT_FEEDBACK_FORM_SUCCESS:
            return Object.assign({}, state, {
                submitFeedbackFormSuccess:true, submitFeedbackFormLoading:false, submitFeedbackFormErrorMsg:'', submitFeedbackFormSuccessMsg:action.response.data.message
            })
        case SUBMIT_FEEDBACK_FORM_FAILURE:
            return Object.assign({}, state, {
                submitFeedbackFormSuccess:false, submitFeedbackFormLoading:false, submitFeedbackFormErrorMsg:action.response.data.message, submitFeedbackFormSuccessMsg:''
            })
        case CLEAR_SUBMIT_FEEDBACK_DATA:
            return Object.assign({}, state, {
                submitFeedbackFormLoading:false, submitFeedbackFormErrorMsg:'', submitFeedbackFormSuccessMsg:''
            })
        case GET_INTERN_FEEDBACK_LIST_REQUEST:
            return Object.assign({}, state, {
                internFeedbackListSuccess:false,internFeedbackErrorMsg:'', internFeedbackList: [], internFeedbackSuccessMsg:'', internFeedbackListLoading:true
            })
        case GET_INTERN_FEEDBACK_LIST_SUCCESS:
            return Object.assign({}, state, {
                internFeedbackListSuccess:true,internFeedbackErrorMsg:'', internFeedbackList: action.response.data.list, internFeedbackSuccessMsg:action.response.data.message, internFeedbackListLoading:false
            })
        case GET_INTERN_FEEDBACK_LIST_FAILURE:
            return Object.assign({}, state, {
                internFeedbackListSuccess:false,internFeedbackErrorMsg:action.response.data.message, internFeedbackList: [], internFeedbackSuccessMsg:'', internFeedbackListLoading:false
            })
        case GET_INTERN_FEEDBACK_REQUEST:
            return Object.assign({}, state, {
                internFeedbackDetailSuccess:false,internFeedbackDetailErrorMsg:'', internFeedbackDetail: {}, internFeedbackDetailSuccessMsg:'', internFeedbackDetailLoading:true
            })
        case GET_INTERN_FEEDBACK_SUCCESS:
            return Object.assign({}, state, {
                internFeedbackDetailSuccess:true, internFeedbackDetailErrorMsg:'', internFeedbackDetail: action.response.data.form, internFeedbackDetailSuccessMsg:action.response.data.message, internFeedbackDetailLoading:false
            })
        case GET_INTERN_FEEDBACK_FAILURE:
            return Object.assign({}, state, {
                internFeedbackDetailSuccess:false,internFeedbackDetailErrorMsg:action.response.data.message, internFeedbackDetail: {}, internFeedbackDetailSuccessMsg:'', internFeedbackDetailLoading:false
            })
            default:
                return state
    }
}