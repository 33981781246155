import React, {Component} from 'react';
import {withRouter,Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import "./dist/style.css"
import EmployeeDashboard from './Overview';

class DashboardIndex extends Component {
    render() {
        return (   
            <Switch>
                <Route exact path="/employee/dashboard" component={EmployeeDashboard}/>
            </Switch>    
        )
    }
}

function mapStateToProps(state) {
    return state
}

export default withRouter(connect(mapStateToProps)(DashboardIndex))