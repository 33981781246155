import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import NoDataFound from '../../../../components/app/no-data-found/NoDataFound';
import {
    deleteModule,
    getDocumentLinks,
    updateAssignModule,
} from '../../../../actions/admin/employeeActions';
import userRoles from '../../../../components/roles/roles'
import {
    getUserModuleList,
    getModuleList,
} from '../../../../actions/admin/internModuleActions';
import { Dialogbox } from 'xd-react-dialogbox';
import createNotification from '../../../../components/app/notification/createNotification';
import Popup from '../../../../components/employee/module/Popup';
import { EditFilled } from '@ant-design/icons';
import { Input } from 'xd-react-input';

const url_string = window.location.href;
const url = new URL(url_string);
class ModulesListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moduleId: '',
            userEmail: '',
            isOpen: false,
            startDate: '',
            unlockDate: '',
            selectedModule: '',
            selectedModuleId: '',
        };
    }

    componentDidMount() {
        const data = {
            page: '1',
            level: 'L1',
        };
        this.props.dispatch(getModuleList(data));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleteAssignedModuleMessage !== '') {
            if (nextProps.deleteAssignedModuleError) {
                createNotification(
                    'error',
                    nextProps.deleteAssignedModuleMessage
                );
            } else {
                createNotification(
                    'success',
                    nextProps.deleteAssignedModuleMessage
                );
                this.props.dispatch(
                    getUserModuleList(this.props.match.params.email)
                );
            }
        }
    }

    togglePopup = (id) => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
        this.state.selectedModuleId = id;
        this.filterModule(id);
    };
    filterModule(id) {
        let temp = [...this.props.userModuleList];
        let tempArrray = temp.filter((d) => d.id === id);
        this.state.selectedModule = tempArrray[0]?.slug;
        this.state.startDate = tempArrray[0]?.start_date;
        this.state.unlockDate = moment(
            new Date(tempArrray[0]?.unlock_date * 1000)
        ).format('YYYY-MM-DD');
    }
    onModuleChange(e) {
        this.state.selectedModule = e.target.value;
    }
    onDateChange(e) {
        let selectedDate = e.target.value;
        this.state.unlockDate = selectedDate;
        this.forceUpdate();
    }

    onSubmit(e) {
        let assignedModule = this.props.userModuleList.filter(
            (module) => module.id === this.state.selectedModuleId
        );
        let body = {
            added_by: assignedModule[0].added_by,
            department: assignedModule[0].department,
            description: assignedModule[0].description,
            duration: assignedModule[0].duration,
            form: assignedModule[0].form,
            id: assignedModule[0].id,
            level: assignedModule[0].level,
            module_id: assignedModule[0].module_id,
            name: assignedModule[0].slug,
            presentation: assignedModule[0].presentation,
            remaining_time: assignedModule[0].remaining_time,
            review: assignedModule[0].review,
            score: assignedModule[0].score,
            slug: this.state.selectedModule,
            start_date: assignedModule[0].start_date,
            status: assignedModule[0].status,
            unlock_date: moment(this.state.unlockDate, 'YYYY-MM-DD').unix(),
        };

        const moduleDetailsTabIndex = 4;
        localStorage.setItem('tabIndex', moduleDetailsTabIndex);
        this.props.dispatch(updateAssignModule(body, assignedModule[0].id));
    }

    handleGetDocumentLink(link) {
        this.props.dispatch(getDocumentLinks(link));
    }
    openLogoutDialog(id, email) {
        this.setState({ moduleId: id, userEmail: email });
        document.querySelector('#deleteModuleDialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
    }
    submitDeleteDialog() {
        this.props.dispatch(
            deleteModule(this.state.moduleId, this.state.userEmail)
        );
        document
            .querySelector('#deleteModuleDialog')
            .classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    closeDialogbox() {
        document
            .querySelector('#deleteModuleDialog')
            .classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }

    render() {
        let { userModuleList } = this.props;
        return (
            <>
                <section className="content-section-wrapper">
                    <div className="user-profile-container">
                        {userModuleList ? (
                            <ul className="custom-list-item-wrapper">
                                {userModuleList.map((module, index) => (
                                    <li className="custom-list-item">
                                        <div className="custom-list-item-content">
                                            <div className="row">
                                            {this.props.roleId != userRoles.Reviewer ? (
                                                 <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                                    <NavLink
                                                        to={
                                                            '/admin/employee/module-details/' +
                                                            module.id
                                                        }
                                                        onClick={() => {
                                                            localStorage.setItem(
                                                                'isProfileModuleClicked',
                                                                true
                                                            );
                                                        }}
                                                        className="feedback-link"
                                                    >
                                                        <div className="custom-list-sub-item">
                                                            <div className="custom-list-sub-item-content">
                                                                <h3 class="list-content-title">
                                                                    {
                                                                        module.name
                                                                    }
                                                                </h3>

                                                                <p className="list-content-meta-info">
                                                                    Level :{' '}
                                                                    {
                                                                        module.level
                                                                    }{' '}
                                                                    | Duration :{' '}
                                                                    {
                                                                        module.duration
                                                                    }{' '}
                                                                    Days
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                    </div>):(
                                                <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                                    <div className="custom-list-sub-item">
                                                            <div className="custom-list-sub-item-content">
                                                                <h3 class="list-content-title">
                                                                    {
                                                                        module.name
                                                                    }
                                                                </h3>

                                                                <p className="list-content-meta-info">
                                                                    Level :{' '}
                                                                    {
                                                                        module.level
                                                                    }{' '}
                                                                    | Duration :{' '}
                                                                    {
                                                                        module.duration
                                                                    }{' '}
                                                                    Days
                                                                </p>
                                                            </div>
                                                        </div>
                                                        </div>)
                                                }
                                                <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                                                    <div className="custom-list-sub-item">
                                                        <div className="custom-list-sub-item-content">
                                                            <span className="custom-list-item-label">
                                                                Start Date
                                                            </span>
                                                            <p className="custom-list-item-value">
                                                                {module.start_date
                                                                    ? moment(
                                                                          new Date(
                                                                              module.start_date *
                                                                                  1000
                                                                          )
                                                                      ).format(
                                                                          'LL'
                                                                      )
                                                                    : 'N/A'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-3 col-md-2 col-lg-2">
                                                    <div className="custom-list-sub-item">
                                                        <div className="custom-list-sub-item-content">
                                                            <span className="custom-list-item-label">
                                                                Unlock Date
                                                            </span>
                                                            <p className="custom-list-item-value">
                                                                {module.unlock_date
                                                                    ? moment(
                                                                          new Date(
                                                                              module.unlock_date *
                                                                                  1000
                                                                          )
                                                                      ).format(
                                                                          'LL'
                                                                      )
                                                                    : 'N/A'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-3 col-md-1 col-lg-1">
                                                    <div className="custom-list-sub-item">
                                                        <div className="custom-list-sub-item-content">
                                                            <span className="custom-list-item-label">
                                                                Status
                                                            </span>
                                                            <p className="custom-list-item-value">
                                                                {module.status ===
                                                                    'InComplete' && (
                                                                    <span className="label-status status-in-complete">
                                                                        InComplete
                                                                    </span>
                                                                )}
                                                                {module.status ===
                                                                    'InProgress' && (
                                                                    <span className="label-status status-in-progress">
                                                                        In
                                                                        Progress
                                                                    </span>
                                                                )}
                                                                {module.status ===
                                                                    'Done' && (
                                                                    <span className="label-status status-complete">
                                                                        Completed
                                                                    </span>
                                                                )}
                                                                {module.status ===
                                                                    'Locked' && (
                                                                    <span className="label-status status-locked">
                                                                        Locked
                                                                    </span>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-3 col-md-1 col-lg-1">
                                                    <div className="custom-list-sub-item">
                                                        <div className="custom-list-sub-item-content">
                                                            <span className="custom-list-item-label">
                                                                Progress
                                                            </span>
                                                            <p className="custom-list-item-value">
                                                                <strong>
                                                                    {
                                                                        module.score
                                                                    }
                                                                    %
                                                                </strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-3 col-md-1 col-lg-1">
                                                    <div className="custom-list-sub-item">
                                                        <div className="custom-list-sub-item-content">
                                                            <div className="profile-fields_data education-document-list">
                                                                <span className="custom-list-item-label">
                                                                    Presentation
                                                                </span>
                                                                <p className="custom-list-item-value">
                                                                    {module.presentation ? (
                                                                        <>
                                                                            {module.presentation
                                                                                ?.split(
                                                                                    ','
                                                                                )
                                                                                ?.map(
                                                                                    (
                                                                                        pptLinks,
                                                                                        i
                                                                                    ) => (
                                                                                        <a
                                                                                            href="javascript:void(0)"
                                                                                            onClick={() =>
                                                                                                this.handleGetDocumentLink(
                                                                                                   pptLinks
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            View
                                                                                        </a>
                                                                                    )
                                                                                )}
                                                                        </>
                                                                    ) : (
                                                                        'N/A'
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.props.roleId != userRoles.Reviewer && 
                                                <div className="col-12 col-sm-3 col-md-1 col-lg-1 profile-edit  ">
                                                    <div className="profile-fields_data education-document-list">
                                                        <button
                                                            type="button"
                                                            className="xd-table-btn delete"
                                                            onClick={() =>
                                                                this.openLogoutDialog(
                                                                    module.id,
                                                                    this.props
                                                                        .email
                                                                )
                                                            }
                                                        >
                                                            <i
                                                                class="fa fa-trash"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </button> 
                                                    </div>
                                                    <EditFilled
                                                        style={{
                                                            position:
                                                                'relative',
                                                            left: '15px',
                                                            top: '5px',
                                                        }}
                                                        onClick={() =>
                                                            this.togglePopup(
                                                                module.id
                                                            )
                                                        }
                                                    />
                                                </div>}
                                                <div
                                                        className={classNames('xd-modal animated ', {
                                                            'show fadeIn': this.state.isOpen,
                                                            fadeOut: !this.state.isOpen,
                                                        })}>
                                                <div
                                                            className={classNames(
                                                                'xd-modal-dialog box animated',
                                                                {
                                                                    fadeInDown: this.state.isOpen,
                                                                    fadeOutUp: !this.state.isOpen,
                                                                }
                                                            )}
                                                        >
                                                {this.state.isOpen && (
                                                    <div className="popup-wrapper">
                                                        <Popup
                                                            content={
                                                                <form
                                                                    noValidate={
                                                                        true
                                                                    }
                                                                    onSubmit={this.onSubmit.bind(
                                                                        this
                                                                    )}
                                                                >
                                                                    <div className="modulePopBox">
                                                                        <div className='module-popup-form-group-with-img module-title-with-border'>
                                                                       
                                                                        <h1>
                                                                            Edit
                                                                            Module
                                                                        </h1>
                                                                        </div>
                                                                        <div className="module-popup-form-group">
                                                                            <div className="module-popup-form-group-with-img">
                                                                                <img
                                                                                    src={require('../../../../static/images/assign-modules.svg')}
                                                                                />
                                                                                <label>
                                                                                    Assigned
                                                                                    Module
                                                                                </label>
                                                                            </div>
                                                                            <select
                                                                                className="popBoxSelect"
                                                                                defaultValue={
                                                                                    this
                                                                                        .state
                                                                                        .selectedModule
                                                                                }
                                                                                onChange={this.onModuleChange.bind(
                                                                                    this
                                                                                )}
                                                                            >
                                                                                {this.props.moduleList.map(
                                                                                    (
                                                                                        d,
                                                                                        i
                                                                                    ) => (
                                                                                        <option
                                                                                            value={
                                                                                                d.id
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                d.name
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                        <div className="module-popup-form-group">
                                                                            <div className="module-popup-form-group-with-img">
                                                                                <img
                                                                                    src={require('../../../../static/images/levels.svg')}
                                                                                />
                                                                                <label>
                                                                                    Level
                                                                                </label>
                                                                            </div>
                                                                            <input
                                                                                id="level"
                                                                                className="popBoxInput"
                                                                                type="text"
                                                                                value={
                                                                                    module.level
                                                                                }
                                                                                disabled
                                                                            />
                                                                        </div>

                                                                        <div className="module-popup-form-group">
                                                                            <div className="module-popup-form-group-with-img">
                                                                                <img
                                                                                    src={require('../../../../static/images/onboarding-start.svg')}
                                                                                />
                                                                                <label>
                                                                                    Start
                                                                                    Date
                                                                                </label>
                                                                            </div>
                                                                            <input
                                                                                id="startDate"
                                                                                className="popBoxInput"
                                                                                type="text"
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .startDate !=
                                                                                    0
                                                                                        ? moment(
                                                                                              new Date(
                                                                                                  this
                                                                                                      .state
                                                                                                      .startDate *
                                                                                                      1000
                                                                                              )
                                                                                          ).format(
                                                                                              'DD/MM/YYYY'
                                                                                          )
                                                                                        : 'Locked'
                                                                                }
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                        <div className="module-popup-form-group">
                                                                            <div className="module-popup-form-group-with-img">
                                                                                <img
                                                                                    src={require('../../../../static/images/lock-icon.svg')}
                                                                                />
                                                                                <label>
                                                                                    Unlock
                                                                                    Date
                                                                                </label>
                                                                            </div>
                                                                            <Input
                                                                                type="date"
                                                                                min="2020-08-01"
                                                                                name="assign_date"
                                                                                placeholder="DD/MM/YYYYY"
                                                                                autoComplete={
                                                                                    'on'
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .unlockDate
                                                                                }
                                                                                required={
                                                                                    true
                                                                                }
                                                                                className="form-control input-compact"
                                                                                errorMessage="Please enter module assign date."
                                                                                onChange={this.onDateChange.bind(
                                                                                    this
                                                                                )}
                                                                            />
                                                                        </div>
                                                                        <button className='moduleSubmit' type="submit">
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            }
                                                            handleClose={
                                                                this.togglePopup
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                </div>
                                                </div>
                                                {this.props.roleId ===
                                                    userRoles.Reviewer && (
                                                    <div className="col-1">
                                                        <div className="custom-list-sub-item">
                                                            <div className="custom-list-sub-item-content">
                                                                <div className="profile-fields_data education-document-list">
                                                                    <span className="custom-list-item-label">
                                                                        Feedback
                                                                    </span>
                                                                    <p className="custom-list-item-value">
                                                                        {this
                                                                            .props
                                                                            .roleId ===
                                                                        userRoles.Reviewer ? (
                                                                            module.form !==
                                                                            0 ? (
                                                                                module.review ? (
                                                                                    <p>
                                                                                        View
                                                                                    </p>
                                                                                ) : (
                                                                                    <NavLink
                                                                                        to={{
                                                                                            pathname: `/admin/employee/l1/${this.props.email}/${module.form}`,
                                                                                            aboutProps: `${module.id}`,
                                                                                        }}
                                                                                        style={
                                                                                            module.status !==
                                                                                            'Done'
                                                                                                ? {
                                                                                                      pointerEvents:
                                                                                                          'none',
                                                                                                      color: 'gray',
                                                                                                  }
                                                                                                : {
                                                                                                      pointerEvents:
                                                                                                          'auto',
                                                                                                  }
                                                                                        }
                                                                                    >
                                                                                        <span>
                                                                                            Submit
                                                                                        </span>
                                                                                    </NavLink>
                                                                                )
                                                                            ) : (
                                                                                'N/A'
                                                                            )
                                                                        ) : (
                                                                            'N/A'
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <NoDataFound />
                        )}
                    </div>
                </section>
                <Dialogbox
                    title="Delete Module"
                    successButton="Delete"
                    cancelButton="Cancel"
                    submitDialog={this.submitDeleteDialog.bind(this)}
                    closeDialog={this.closeDialogbox}
                    dialogBoxID="deleteModuleDialog"
                >
                    <p className="delete_message">
                        Are you sure you want to delete ?
                    </p>
                </Dialogbox>
            </>
        );
    }
}

function mapStateToProps(state) {
    const {
        userModuleList,
        moduleList,
        deleteAssignedModuleMessage,
        deleteAssignedModuleError,
        updateAssignModuleMessage,
        updateAssignModuleTasksError,
    } = state.moduleReducer;
    const {roleId} = state.authReducer;
    return {
        roleId,
        userModuleList,
        moduleList,
        deleteAssignedModuleMessage,
        deleteAssignedModuleError,
        updateAssignModuleMessage,
        updateAssignModuleTasksError,
    };
}

export default withRouter(connect(mapStateToProps)(ModulesListing));
