import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/recently-joined.css';
import { recentlyJoinedInterns } from '../../../actions/admin/adminDashboardActions';
import userRoles from '../../../components/roles/roles';

class RecentlyJoined extends Component {
    componentWillMount() {
        const body = {
            limit: 10,
            page: 1,
            status: 'active',
        };
        this.props.dispatch(recentlyJoinedInterns(body));
    }
    dropdown() {
        document
            .getElementsByClassName('panel-wrapper')[2]
            .classList.toggle('panel-wrapper-display');
        document
            .getElementsByClassName('down-arrow')[2]
            .classList.toggle('down-arrow-rotate');
    }
    render() {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div className="panel panel-warning card-view custom-panel pool-panel">
                    <div className="panel-heading">
                        <h5 className="panel-title">Recently Joined</h5>
                        <div>
                            <a
                                className="pull-left inline-block mr-15 txt-light"
                                data-toggle="collapse"
                                href="JavaScript:void(0"
                                aria-expanded="false"
                                onClick={this.dropdown.bind(this)}
                            >
                                <img
                                    src={require('../../../static/images/down-arrow.png')}
                                    className="zmdi zmdi-chevron-down down-arrow"
                                />
                            </a>
                        </div>
                    </div>
                    <div id="collapse_4" className="panel-wrapper collapse in">
                        <div className="panel-body">
                            <div className="table-wrap fix-height">
                                <div className="table-responsive">
                                    <table
                                        id="myTable1"
                                        className="table display table-hover pb-30"
                                    >
                                        <thead>
                                            <tr>
                                                <th className="">Name</th>
                                                <th className="">Level</th>
                                                <th className="">Email</th>
                                                <th className="">
                                                    Contact No.
                                                </th>
                                                <th className="">Role</th>
                                                <th className="">Status</th>
                                            </tr>
                                        </thead>
                                        {!this.props
                                            .recentlyJoinedInternsError ? (
                                            this.props
                                                .recentlyJoinedInternsCount !==
                                            0 ? (
                                                this.props.internsList.map(
                                                    (activeIntern, i) => (
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                            {activeIntern.name !==
                                                                                undefined &&
                                                                            activeIntern.name !==
                                                                                ''
                                                                                ? activeIntern.name
                                                                                : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {activeIntern.level !==
                                                                        undefined &&
                                                                    activeIntern.level !==
                                                                        ''
                                                                        ? activeIntern.level
                                                                        : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {activeIntern.email !==
                                                                        undefined &&
                                                                    activeIntern.email !==
                                                                        ''
                                                                        ? activeIntern.email
                                                                        : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {activeIntern.contact_no !==
                                                                        undefined &&
                                                                    activeIntern.contact_no !==
                                                                        ''
                                                                        ? activeIntern.contact_no
                                                                        : 'NA'}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        activeIntern.role
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        activeIntern.account_status
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                )
                                            ) : (
                                                <div className="internMessage">
                                                    <p>
                                                        No Acitve User Available
                                                    </p>
                                                </div>
                                            )
                                        ) : (
                                            this.props
                                                .recentlyJoinedInternsError && (
                                                <div className="internMessage">
                                                    <p>
                                                        No Acitve User Available
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const {
        internsList,
        recentlyJoinedInternsCount,
        recentlyJoinedInternsError,
    } = state.adminDashboardReducer;
    const { roleId } = state.authReducer;
    return {
        internsList,
        recentlyJoinedInternsCount,
        recentlyJoinedInternsError,
        roleId,
    };
};
export default withRouter(connect(mapStateToProps)(RecentlyJoined));
