import React, {Component} from 'react';
import {withRouter,Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import EducationInfo from './view-profile/EducationInfo'
import PersonalInfo from './view-profile/PersonalInfo'
import AccountInfo from './view-profile/AccountInfo'
import OtherInfo from './view-profile/OtherInfo';
import EditProfile from './EditPersonalProfile';
import EditEducationInfo from './EditEducationInfo';
import EditOtherInfo from './EditOtherInfo';
import './dist/style.css';

class UserProfileIndex extends Component {
    render() {
        return (   
        <Switch>
            <Route exact path="/employee/profile" component={AccountInfo} />
            <Route exact path="/employee/profile/account-information" component={AccountInfo} />
            <Route exact path="/employee/profile/personal-information" component={PersonalInfo} />
            <Route exact path="/employee/profile/education-information" component={EducationInfo}/>
            <Route exact path="/employee/profile/other-information" component={OtherInfo}/>
            <Route exact path="/employee/edit-profile" component={EditProfile}/>
            <Route exact path="/employee/edit-education" component={EditEducationInfo}/>
            <Route exact path= "/employee/edit-otherInfo" component={EditOtherInfo}/>        
        </Switch>             
        )
    }
}

function mapStateToProps(state) {
    return state
}

export default withRouter(connect(mapStateToProps)(UserProfileIndex))