import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NoDataFound from '../../../../components/app/no-data-found/NoDataFound';
import { BlockLoader} from 'xd-react-loader';

class PersonalDetails extends Component{
    constructor(props){
        super(props)
        this.state= { }
    }
    calculate_age(dob) { 
        var diff_ms = Date.now() - new Date(dob).getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

render(){
    const { userProfileData} = this.props  
    return(                       
        <section className="content-section-wrapper">
            <div className="row">               
                <div className="col-12 col-sm-12 col-md-10 col-lg-10">                                
                    <div className="user-details">
                        {this.props.isProfileLoading?(
                        <div className="page-section-loader">
                            <BlockLoader 
                            loadingText="Loading Personal Details.." 
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                        </div>
                        ):this.props.userProfileError?(
                            <NoDataFound title={this.props.userProfileError}/>
                        ):this.props.profileSuccess?(<div className="details-container">
                        <div className="user-profile-container">                                        
                            <div className="profile-section_card">
                                <div className="profile-section_grid">
                                    <div className="profile-section_card_item">
                                    <article className="profile-section_card_details">
                                            <div className="profile-card-meta_data">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Father's Name</p>
                                                            <p className="fields-value">{userProfileData.personal_info.fathers_name!== ''? userProfileData.personal_info.fathers_name: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Mother's Name</p>
                                                            <p className="fields-value">{userProfileData.personal_info.mothers_name!== ''? userProfileData.personal_info.mothers_name: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Gender</p>
                                                            <p className="fields-value">{userProfileData.personal_info.gender!== ''? userProfileData.personal_info.gender: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Blood Group</p>
                                                            <p className="fields-value">{userProfileData.personal_info.blood_group!== ''? userProfileData.personal_info.blood_group: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Marital Status</p>
                                                            <p className="fields-value">{userProfileData.personal_info.marital_Status!== ''? userProfileData.personal_info.marital_Status: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Date Of Birth/Age</p>
                                                            <p className="fields-value">{userProfileData.personal_info.dateofbirth!== ''? userProfileData.personal_info.dateofbirth: 'N/A'}{userProfileData.personal_info.dateofbirth!== ''? `/${this.calculate_age(userProfileData.personal_info.dateofbirth)}yrs`:""}</p>
                                                        </div>
                                                    </div>  
                                                    {userProfileData.personal_info.marital_Status === 'married' &&
                                                    (<><div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Spouse's Name</p>
                                                            <p className="fields-value">{userProfileData.personal_info.spouse_name !== '' ? userProfileData.personal_info.spouse_name : 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Spouse's Contact</p>
                                                            <p className="fields-value">{userProfileData.personal_info.spouse_contact !== '' ?  userProfileData.personal_info.spouse_contact : 'N/A'}</p>
                                                        </div>
                                                    </div> </>)
                                                     }
                                                </div>
                                            </div>
                                        </article>                                           
                                        </div>
                                    </div>
                                </div>

                                {/* address */}
                                <div className="profile-section_card">
                                    <h2 className="profile-section_card_title"> Address</h2>
                                    <div className="profile-section_grid"> 
                                    {userProfileData.address_info.map((address, addressIndex)=>
                                    <>  
                                    <div className="profile-section_card_item" key={addressIndex}>
                                    <article className="profile-section_card_details">
                                        <h3>{address.address_type}</h3>
                                        <div className="profile-card-meta_data">
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">Location</p>
                                                        <p className="fields-value">{address.address!==''?address.address:'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">Country</p>
                                                        <p className="fields-value">{address.country!== ''? address.country: 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">State</p>
                                                        <p className="fields-value">{address.state!== ''? address.state: 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">City</p>
                                                        <p className="fields-value">{address.city!== ''? address.city: 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">District</p>
                                                        <p className="fields-value">{address.district!== ''? address.district: 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">Pin Code</p>
                                                        <p className="fields-value">{address.pincode!== ''? address.pincode: 'N/A'}</p>
                                                    </div>
                                                </div>                                                    
                                            </div>
                                        </div>
                                    </article>                                           
                                    </div>
                                    </>)}
                                </div>
                            </div>    
                        </div>                                               
                        </div>   
                    ):(
                        <NoDataFound />
                    )}
                    </div>
                </div>
            </div>
        </section>
        )
    }
}

function mapStateToProps(state) {
    const {isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink} = state.employeeReducer;
    return { isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink}
}

export default withRouter(connect(mapStateToProps)(PersonalDetails))
