import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import { BlockLoader} from 'xd-react-loader';
import ProfileSubHeader from "./ProfileSubHeader";
import { getEmployeeProfile, updateEducationFields, updateProfileDetails } from "../../../actions/employee/userActions";

class EditEducationInfo extends Component{
    constructor(props){
        super(props)
        this.handleChange=this.handleProfileChange.bind(this)
    }

    componentDidMount(){
        this.props.dispatch(getEmployeeProfile())
    }
    handleProfileChange(e,i) {
        let value = e.target.value;
        const name = e.target.name;
        let educationData = this.props.profileInfo.education_details
        if(name=="percentage"){
            if(e.target.value){
                educationData[i][name] = parseFloat(e.target.value);
            }else{
                educationData[i][name] = (e.target.value);
            }
        }
        else{
            educationData[i][name] = value;
        }
        
        this.props.dispatch(updateEducationFields(educationData));
        if (e.target.checkValidity()) {
          e.target.parentElement.classList.remove('has-error')
        }
        else {
          e.target.parentElement.classList.add('has-error')
        }
       
      }

      handleSubmit(e){
        e.preventDefault();
        const details={
            "personal_info":this.props.profileInfo.personal_info,
            "user_address":this.props.profileInfo.address_info,
            "education":this.props.profileInfo.education_details,
            "emergency_contact":this.props.profileInfo.emergency_contacts,
            "others":this.props.profileInfo.others_certificates
        }
        if (e.target.checkValidity()) {
            this.props.dispatch(updateProfileDetails(details))
        }else{
            let allInvalidInput = document.querySelectorAll(
                ".form-group .form-control:invalid"
              );
              if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add("has-error");
                    invalidInput.parentElement.parentElement.classList.add(
                    "has-error"
                  );
                }
              }
        }
      }
      handleCancel(){
        this.props.history.push('/employee/EducationInfo')
    }
    

    render(){
        const userProfile=this.props.profileInfo
        return(
            <main className="main-content-wrapper employee-profile-wrapper">
                <ProfileSubHeader/>
                <div className="container emp-profile">
                <div className="profile-head">
                <div className="row">
                    
                                    <ul className=" profile-nav-tabs" id="myTab" role="tablist">
                                        <li className="profile-nav-item">
                                             <NavLink to='/employee/UserProfile' className="profile-nav-link  " id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">Personal Information</NavLink>
                                         </li>
                                         <li className="profile-nav-item">
                                         <li><NavLink to='/employee/EducationInfo'  className="profile-nav-link active" id="home-tab" data-toggle="tab"  role="tab" aria-controls="home" aria-selected="true">Educational Information</NavLink></li>
                                         </li>
                                         <li className="profile-nav-item">
                                         <li><NavLink to='/employee/AccountInfo'  className="profile-nav-link" id="home-tab" data-toggle="tab"  role="tab" aria-controls="home" aria-selected="true">Account Information</NavLink></li>
                                         </li>
                                         <li className="profile-nav-item">
                                         <li><NavLink to='/employee/OtherInfo'  className="profile-nav-link" id="home-tab" data-toggle="tab"  role="tab" aria-controls="home" aria-selected="true">Other</NavLink></li>
                                         </li>
                                    </ul>
                            </div>
                                </div>
            <form method="post">
                <div className="row">
                    <div className="col-md-4">
                        <div class="profile-img">
                            <img src={userProfile  ? userProfile.link : 
                                'https://www.jamf.com/jamf-nation/img/default-avatars/generic-user-purple.png'} style={{width: '200px', height: '200px', borderRadius:'100px'}} alt=""/>
                            <div class="file btn btn-lg btn-primary">
                                Change Photo
                                <input type="file" name="file"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 style={{paddingTop:"40px"}}>Education Information</h3>
                    </div>
                    <div className="col-md-6">
                        
                    </div>
                   
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="profile-work">
                            
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="tab-content profile-tab" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            
                {this.props.isUserProfileLoading?(
                                             <div>
                                                 <BlockLoader 
                                                 loadingText="loading" 
                                                 loading={true}
                                                 loaderStyle={{size:"20px",
                                                 color:"0562e8",
                                                 textSize:"18px",
                                                 textColor:"#485e7d",
                                                 speed:"1s"
                                                }}
                                                />
                                            </div>
                                        ):this.props.profileErrorMsg?(
                                            <h3>{this.props.profileErrorMsg}</h3>
                                        ):this.props.getProfileSuccess?(<React.Fragment>
                                            
                                            {userProfile.education_details.map((education,i)=>
                                            <React.Fragment>
                                                <div class="row">
                                            <div class="col-md-6">
                                               <label>Qualification</label>
                                            </div>
                                            <div class="col-md-6">
                                                <input
                                                type="text"
                                                required
                                                minLength={3}
                                                maxLength={50}
                                                pattern={"([a-zA-Z]\.+\\s)*[a-zA-Z]+$"}
                                                className="form-control edit-field"
                                                id="qualification_name"
                                                value={education.name}
                                                label="Qualification Name"
                                                name="name"
                                                disabled
                                                onChange={e=>this.handleProfileChange(e, i)}
                                                minLength={3}
                                                maxLength={50}
                                                pattern={"([a-zA-Z]\.+\\s)*[a-zA-Z]+$"}
                                                autoComplete={"off"}
                                                errorMessage="Please enter your father's name(Min 3 char.)."/>
                                            </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-md-6">
                                                <label>Institution</label>
                                            </div>
                                            <div class="col-md-6">
                                            <input
                                            className="form-control edit-field"
                                            type="text"
                                            required
                                            minLength={3}
                                            maxLength={50}
                                            pattern={"([a-zA-Z]\.+\\s)*[a-zA-Z]+$"}
                                            name="institution"
                                            value={education.institution}
                                            label="Mother's Name"
                                            onChange={e=>this.handleProfileChange(e, i)}/>
                                            <div className="help-block with-errors">
                                                    Please enter name of the institution.
                                                </div>
                                            </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-md-6">
                                                <label>Location</label>
                                            </div>
                                            <div class="col-md-6">
                                                <input
                                                className="form-control edit-field"
                                                type="text"
                                                required
                                                minLength={3}
                                                maxLength={50}
                                                pattern={"([a-zA-Z]\.+\\s)*[a-zA-Z]+$"}
                                                name="location"
                                                value={education.location}
                                                label="Location"
                                                onChange={e=>this.handleProfileChange(e, i)}/>
                                                <div className="help-block with-errors">
                                                    Please enter location(min 3 char.)
                                                </div>
                                            </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-md-6">
                                                <label>Year of Passing</label>
                                            </div>
                                            <div class="col-md-6">
                                                <input
                                                className="form-control edit-field"
                                                required
                                                minLength={4}
                                                maxLength={10}
                                                type="number"
                                                name="year"
                                                value={education.year}
                                                label="Year"
                                                onChange={e=>this.handleProfileChange(e, i)}/>
                                                <div className="help-block with-errors">
                                                    Please enter your year of passing.
                                                </div>
                                            </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-md-6">
                                                <label>Percentage/cgpa</label>
                                            </div>
                                            <div class="col-md-6">
                                                <input
                                                className="form-control edit-field"
                                                type="text"
                                                name="percentage"
                                                required
                                                pattern={"[0-9]+(\.[0-9]{0,2})?%?"}
                                                value={education.percentage}
                                                label="Percentage"
                                                onChange={e=>this.handleProfileChange(e, i)}/>
                                            </div>
                                            </div><br/>
                                            
                                            </React.Fragment>)}
                                            
                                            
                                            <div style={{display:"inline", margin:"20px"}} className="submit-btn">
                                                <Button
                                                className="update-btn"
                                                type="submit"
                                                onClick={this.handleSubmit.bind(this)}
                                                >Submit</Button>
                                            </div>
                                            <div style={{display:"inline"}} className="cancel-btn">
                                                <Button
                                                className="cancel-btn"
                                                onClick={this.handleCancel.bind(this)}
                                                >Cancel</Button>
                                            </div>
                                        </React.Fragment>
                                        ):("")}
                 </div>                            
                        </div>
                    </div>
                </div>
            </form>           
        </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    const {getProfileSuccess,isUserProfileLoading, profileErrorMsg, profileInfo, userPersonalInfo} = state.userReducer;
    return {getProfileSuccess,isUserProfileLoading, profileErrorMsg, profileInfo, userPersonalInfo}
}

export default withRouter(connect(mapStateToProps)(EditEducationInfo))