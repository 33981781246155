import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Progress from 'react-progressbar';
import { Dropdown, DropItem } from 'xd-react-dropdown';
import { Dialogbox } from 'xd-react-dialogbox';
import { BlockLoader } from 'xd-react-loader';
import {
    getInternList,
    setInternsLevel,
    deactivateAccount,
    storeSelectedInternData,
    removeSelectedInternData,
    clearSelectedEmailData,
    clearDeactivateData,
    storeAllInterns,
    changeLevel,
    clearChangeLevelData,
    clearEmpoyeeData,
    isInternSelected,
    isInternNotSelected,
} from '../../../actions/admin/employeeActions';
import './dist/table.css';
import createNotification from '../../../components/app/notification/createNotification';
import { Avatar } from 'xd-react-avatar';
import Pagination from 'react-js-pagination';
import {
    clearAssignReviewerData,
    clearReviewInviteData,
    checkUserCredentials,
    clearCheckData,
} from '../../../actions/admin/reviewerActions';
import { accountApi } from '../../../actions/ApiActions';
import userRoles from '../../../components/roles/roles';
import NoDataFound from '../../../components/app/no-data-found/NoDataFound';

const AUTH_API = accountApi();

class InternL2Listing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            level: '',
            internEmail: '',
            activePage: 1,
            levelChangeEmail: '',
            searchText: '',
        };

        this.submitDeactivateDialog = this.submitDeactivateDialog.bind(this);
        this.openDeactivateDialog = this.openDeactivateDialog.bind(this);
        this.getInternsList = this.getInternsList.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(setInternsLevel('L2'));
        const listingData = {
            level: 'L2',
            page: this.state.activePage,
            limit: 10,
        };
        this.props.dispatch(getInternList(listingData));
    }
    componentWillMount() {
        this.props.dispatch(clearSelectedEmailData());
        this.props.dispatch(clearAssignReviewerData());
        this.props.dispatch(clearReviewInviteData());
        this.props.dispatch(clearCheckData());
        this.props.dispatch(clearEmpoyeeData());
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.deactivateErrorMsg !== '' ||
            nextProps.deactivateSuccessMsg !== ''
        ) {
            if (nextProps.deactivateErrorMsg) {
                createNotification('error', nextProps.deactivateErrorMsg);
                nextProps.dispatch(clearDeactivateData());
            } else {
                window.location.reload(false);
                setTimeout(function () {
                    createNotification(
                        'success',
                        nextProps.deactivateSuccessMsg
                    );
                    nextProps.dispatch(clearDeactivateData());
                }, 2000);
            }
        }
        if (nextProps.checkCredentialsSuccessMsg.length !== 0) {
            nextProps.history.push(
                `/admin/employee/review-invite/${this.state.internEmail}`
            );
            nextProps.dispatch(clearCheckData());
        }
        if (nextProps.checkCredentialsErrorMsg.length !== 0) {
            this.openGoogleLoginDialog();
            nextProps.dispatch(clearCheckData());
        }
        if (nextProps.getReportsAfterLevelChange) {
            createNotification('success', nextProps.changeLevelSuccess);
            nextProps.dispatch(clearChangeLevelData());
            const listingData = {
                level: 'L2',
                page: this.state.activePage,
                limit: 10,
            };
            this.props.dispatch(getInternList(listingData));
        }
        if (nextProps.changeLevelFailure) {
            createNotification('error', nextProps.changeLevelFailure);
            nextProps.dispatch(clearChangeLevelData());
        }
    }

    handleCheckCredentials(e, email) {
        e.preventDefault();
        this.props.dispatch(checkUserCredentials());
        this.setState({
            internEmail: email,
        });
    }
    handleSearchBar(e) {
        this.state.searchText = e.target.value;
    }
    handleKeyPress(e) {
        const moduleData = {
            level: 'L2',
            page: this.state.activePage,
            limit: 10,
        };
        if (e.key === 'Enter') {
            this.props.dispatch(
                getInternList(moduleData, this.state.searchText)
            );
        }
    }
    handleSearchBarOnClick() {
        const moduleData = {
            level: 'L2',
            page: this.state.activePage,
            limit: 10,
        };
        this.props.dispatch(getInternList(moduleData, this.state.searchText));
    }

    getInternsList() {
        const listingData = {
            level: 'L2',
            page: this.state.activePage,
            limit: 10,
        };
        this.props.dispatch(getInternList(listingData));
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber }, this.getInternsList);
    }

    openDeactivateDialog(e, email) {
        this.setState((state) => {
            state.internEmail = email;
            return state;
        });
        document.querySelector('#deactivateDialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
    }
    // submit response of dialog
    submitDeactivateDialog(e) {
        const deactivateInfo = {
            status: 'blocked',
            email: this.state.internEmail,
        };
        this.props.dispatch(deactivateAccount(deactivateInfo));
        document.querySelector('#deactivateDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    // closes the dialog
    closeDialogbox() {
        document.querySelector('#deactivateDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }

    openGoogleLoginDialog() {
        document.querySelector('#googleLoginDialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
    }
    submitGoogleLoginDialog(e) {
        const url = `${AUTH_API}/v1/google/login`;
        window.open(url, '_blank');
        document.querySelector('#googleLoginDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    closeLoginDialogbox() {
        document.querySelector('#googleLoginDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }

    handleLevelChange(e) {
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
        const level = e.target.value;
        this.setState((state) => {
            state.level = level;
            return state;
        });
    }
    openChangeLevelDialog(e, email) {
        this.setState((state) => {
            state.levelChangeEmail = email;
            return state;
        });
        document.querySelector('#changeLevelDialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
    }
    submitChangeLevelDialog(e) {
        const levelInput = document.getElementById('change-level');
        if (levelInput.checkValidity()) {
            const levelDetails = {
                email: this.state.levelChangeEmail,
                level: this.state.level,
            };
            this.props.dispatch(changeLevel(levelDetails));
            document
                .querySelector('#changeLevelDialog')
                .classList.remove('opened');
            document.querySelector('body').classList.remove('has-dialog');
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add(
                        'has-error'
                    );
                }
            }
        }
    }
    closeLevelDialogbox() {
        document.querySelector('#changeLevelDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }

    checkAllCheckbox(e) {
        if (document.getElementsByName("allboxes")) {
            let allSelected = [];
            (document.getElementsByName("allboxes")).forEach(
                checkbox => { if (checkbox.checked) allSelected.push(checkbox.checked) })

            let isAnyCheckboxChecked = allSelected.length > 0

            if (isAnyCheckboxChecked) {
                this.props.dispatch(isInternSelected())
            }
            else {
                this.props.dispatch(isInternNotSelected())
            }
        }

        var checkboxes = document.getElementsByName('link');
        let list = [];
        for (var checkbox of checkboxes) {
            checkbox.checked = document.getElementsByName("allboxes")[0].checked;
        }
        this.props.internsList.map((m, i) => list.push(m));

        this.props.dispatch(storeAllInterns(list));

    }

    checkBoxSelectionEvent(e, intern) {
        if (document.getElementsByName("link")) {
            let allSelected = [];
            (document.getElementsByName("link")).forEach(
                checkbox => { if (checkbox.checked) allSelected.push(checkbox.checked) })

            let isAnyCheckboxChecked = allSelected.length > 0

            if (isAnyCheckboxChecked) {
                this.props.dispatch(isInternSelected())
            }
            else {
                this.props.dispatch(isInternNotSelected())
            }
        }
        if (e.target.checked) {
            this.props.dispatch(storeSelectedInternData(intern));
        } else {
            const removeIndex = this.props.employeeList.indexOf(intern);
            this.props.dispatch(removeSelectedInternData(removeIndex));
        }
    }
    render() {
        const internsList = this.props.internsList;
        return (
            <React.Fragment>
                <div>
                    {this.props.isListLoading ? (
                        <div style={{ paddingLeft: '30px' }}>
                            <BlockLoader
                                loadingText="loading"
                                loading={true}
                                loaderStyle={{
                                    size: '20px',
                                    color: '0562e8',
                                    textSize: '18px',
                                    textColor: '#485e7d',
                                    speed: '1s',
                                }}
                            />
                        </div>
                    ) : (
                        <div className="dashboard-body-wrapper">
                            <div className="container">
                                <div className="col-12">
                                    <div
                                        class="form-group candidate-search"
                                        style={{
                                            position: 'relative',
                                            right: '15px',
                                            bottom: '5px',
                                        }}
                                    >
                                        <input
                                            type="text"
                                            name="searchText"
                                            class="form-control"
                                            placeholder="Search name and press enter.."
                                            autocomplete="off"
                                            defaultValue={this.state.searchText}
                                            onChange={this.handleSearchBar.bind(
                                                this
                                            )}
                                            onKeyDown={this.handleKeyPress.bind(
                                                this
                                            )}
                                        ></input>
                                        <button
                                            onClick={this.handleSearchBarOnClick.bind(
                                                this
                                            )}
                                            className="candidate-search-functionality"
                                        >
                                            <img
                                                src={require('../../../static/images/onboarding-search.svg')}
                                            />
                                            <img
                                                src={require('../../../static/images/onboarding-search-mobile.svg')}
                                                className="onboarding-search-mobile"
                                            />
                                        </button>
                                    </div>
                                </div>
                                {
                                    internsList.length === 0 ? (
                                        <NoDataFound title="No data available" description="No Data found for L2 interns"/>
                                    ) : (
                                        <section className="content-section-wrapper">
                                        <div className="table-container ">
                                            <table className="listing-table">
                                                <thead>
                                                    <tr>
                                                        <td className="tableHeader">
                                                            <input
                                                                name="allboxes"
                                                                type="checkbox"
                                                                onClick={(e) =>
                                                                    this.checkAllCheckbox(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <th className="avatar"></th>
                                                        <th className="intern-name">
                                                            Name
                                                        </th>
                                                        <th className="email">
                                                            Email
                                                        </th>
                                                        <th className="task">
                                                            Task
                                                        </th>
                                                        <th className="scoring">
                                                            Progress
                                                        </th>
                                                        <th className="task">
                                                            Score
                                                        </th>
                                                        <th className="actions">
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {internsList.map(
                                                        (intern, i) => {
                                                            return (
                                                                <tr name="list">
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            name="link"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                this.checkBoxSelectionEvent(
                                                                                    e,
                                                                                    intern
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td className="content avatar">
                                                                        <Avatar
                                                                            src={
                                                                                intern.link !==
                                                                                    ''
                                                                                    ? intern.link
                                                                                    : require('../../../static/images/profile-placeholder.png')
                                                                            }
                                                                            size={
                                                                                35
                                                                            }
                                                                            marginRight={
                                                                                2
                                                                            }
                                                                        />{' '}
                                                                    </td>
                                                                    <td className="content intern-name">
                                                                        <NavLink
                                                                            className="intern-link"
                                                                            style={{
                                                                                color: '#2a3bad',
                                                                            }}
                                                                            to={`/admin/employee/l1/${intern.email} `}
                                                                        >
                                                                            <div>
                                                                                <b>
                                                                                    {
                                                                                        intern.name
                                                                                    }
                                                                                </b>
                                                                                <br />
                                                                                <span
                                                                                    style={{
                                                                                        fontSize:
                                                                                            '12px',
                                                                                    }}
                                                                                >
                                                                                    {intern.department
                                                                                        ? intern.department
                                                                                        : ''}
                                                                                </span>
                                                                            </div>
                                                                        </NavLink>
                                                                    </td>
                                                                    <td className="content email">
                                                                        {' '}
                                                                        {
                                                                            intern.email
                                                                        }
                                                                    </td>
                                                                    <td className="content task">
                                                                        {intern.task
                                                                            ? intern.task
                                                                            : 'N/A'}
                                                                    </td>
                                                                    <td className="content scoring">
                                                                        <Progress
                                                                            style={{
                                                                                border: '1px solid gray',
                                                                            }}
                                                                            completed={
                                                                                intern.progress
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td className="content task">
                                                                        {
                                                                            intern.scores
                                                                        }
                                                                        {intern.scores !==
                                                                            0 &&
                                                                            '%'}
                                                                    </td>
                                                                    <td className="content actions">
                                                                        <Dropdown>
                                                                            <DropItem
                                                                                name="Deactivate"
                                                                                style={{
                                                                                    fontSize:
                                                                                        '8px',
                                                                                }}
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    this.openDeactivateDialog(
                                                                                        e,
                                                                                        intern.email
                                                                                    )
                                                                                }
                                                                                dropLink={
                                                                                    '/admin/employee/l2'
                                                                                }
                                                                            />
    
                                                                            {this
                                                                                .props
                                                                                .roleId !=
                                                                                userRoles.Reviewer && (
                                                                                    <DropItem
                                                                                        name={
                                                                                            this
                                                                                                .props
                                                                                                .checkCredentialsLoading ? (
                                                                                                <span>
                                                                                                    Plan
                                                                                                    Review{' '}
                                                                                                    <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                                                                </span>
                                                                                            ) : (
                                                                                                'Plan Review'
                                                                                            )
                                                                                        }
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.handleCheckCredentials(
                                                                                                e,
                                                                                                intern.email
                                                                                            )
                                                                                        }
                                                                                        dropLink={
                                                                                            ''
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            {this
                                                                                .props
                                                                                .roleId !=
                                                                                userRoles.Reviewer && (
                                                                                    <DropItem
                                                                                        name="Change Level"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.openChangeLevelDialog(
                                                                                                e,
                                                                                                intern.email
                                                                                            )
                                                                                        }
                                                                                        dropLink={
                                                                                            '/admin/employee/l2'
                                                                                        }
                                                                                    />
                                                                                )}
                                                                        </Dropdown>
                                                                    </td>
                                                                    <Dialogbox
                                                                        title="Deactivate"
                                                                        successButton="Deactivate"
                                                                        cancelButton="Cancel"
                                                                        submitDialog={(
                                                                            e
                                                                        ) =>
                                                                            this.submitDeactivateDialog(
                                                                                e
                                                                            )
                                                                        }
                                                                        closeDialog={
                                                                            this
                                                                                .closeDialogbox
                                                                        }
                                                                        dialogBoxID="deactivateDialog"
                                                                    >
                                                                        <p>
                                                                            Are you
                                                                            sure you
                                                                            want to
                                                                            Deactivate
                                                                            this
                                                                            account?
                                                                        </p>
                                                                    </Dialogbox>
                                                                    <Dialogbox
                                                                        title="Continue with Google"
                                                                        successButton="OK"
                                                                        cancelButton="Cancel"
                                                                        submitDialog={(
                                                                            e
                                                                        ) =>
                                                                            this.submitGoogleLoginDialog(
                                                                                e
                                                                            )
                                                                        }
                                                                        closeDialog={
                                                                            this
                                                                                .closeLoginDialogbox
                                                                        }
                                                                        dialogBoxID="googleLoginDialog"
                                                                    >
                                                                        <p>
                                                                            Do you
                                                                            want to
                                                                            login
                                                                            with
                                                                            your
                                                                            Google
                                                                            account?
                                                                        </p>
                                                                    </Dialogbox>
                                                                    <Dialogbox
                                                                        title="Change Level"
                                                                        successButton="Change"
                                                                        cancelButton="Cancel"
                                                                        submitDialog={(
                                                                            e
                                                                        ) =>
                                                                            this.submitChangeLevelDialog(
                                                                                e
                                                                            )
                                                                        }
                                                                        closeDialog={
                                                                            this
                                                                                .closeLevelDialogbox
                                                                        }
                                                                        dialogBoxID="changeLevelDialog"
                                                                    >
                                                                        <div class="form-group">
                                                                            <p>
                                                                                Select
                                                                                the
                                                                                new
                                                                                level
                                                                            </p>
                                                                            <div className="select-wrapper">
                                                                                <select
                                                                                    className="form-control"
                                                                                    onChange={this.handleLevelChange.bind(
                                                                                        this
                                                                                    )}
                                                                                    required
                                                                                    name="change-level"
                                                                                    id="change-level"
                                                                                >
                                                                                    <option
                                                                                        disabled={
                                                                                            true
                                                                                        }
                                                                                        value=""
                                                                                        selected
                                                                                    >
                                                                                        Select
                                                                                        Level
                                                                                    </option>
                                                                                    <option value="L1">
                                                                                        L1
                                                                                    </option>
                                                                                    <option value="L3">
                                                                                        L3
                                                                                    </option>
                                                                                </select>
                                                                                <div className="help-block with-errors">
                                                                                    Please
                                                                                    select
                                                                                    level.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Dialogbox>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    )
                                }
                            </div>
                        </div>
                    )}
                    <div className="pagination-container">
                        <Pagination
                            activePage={this.state.activePage}
                            prevPageText={<i className="fa fa-angle-left" />}
                            nextPageText={<i className="fa fa-angle-right" />}
                            itemsCountPerPage={10}
                            totalItemsCount={this.props.internCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {
        internsList,
        internCount,
        isPageLoading,
        isListLoading,
        internListError,
        internListSuccess,
        deactivateSuccessMsg,
        deactivateErrorMsg,
        employeeList,
        changeLevelSuccess,
        changeLevelFailure,
        getReportsAfterLevelChange,
    } = state.employeeReducer;
    const {roleId} = state.authReducer;
    const {
        checkCredentialsLoading,
        checkCredentialsErrorMsg,
        checkCredentialsSuccessMsg,
    } = state.reviewerReducer;
    return {
        roleId,
        internsList,
        internCount,
        isPageLoading,
        isListLoading,
        internListError,
        internListSuccess,
        deactivateSuccessMsg,
        deactivateErrorMsg,
        employeeList,
        checkCredentialsLoading,
        checkCredentialsErrorMsg,
        checkCredentialsSuccessMsg,
        changeLevelSuccess,
        changeLevelFailure,
        getReportsAfterLevelChange,
    };
}

export default withRouter(connect(mapStateToProps)(InternL2Listing));
