import {
    GET_REVIEWER_LIST_REQUEST,
    GET_REVIEWER_LIST_SUCCESS,
    GET_REVIEWER_LIST_FAILURE,
    ASSIGN_REVIEWER_REQUEST,
    ASSIGN_REVIEWER_SUCCESS,
    ASSIGN_REVIEWER_FAILURE,
    STORE_SELECTED_REVIEWER_DATA,
    CLEAR_ASSIGN_REVIEWER_DATA,
    ASSIGNED_REVIEWER_LIST_REQUEST,
    ASSIGNED_REVIEWER_LIST_SUCCESS,
    ASSIGNED_REVIEWER_LIST_FAILURE,
    DELETE_ASSIGNED_REVIEWER_REQUEST,
    DELETE_ASSIGNED_REVIEWER_SUCCESS,
    DELETE_ASSIGNED_REVIEWER_FAILURE,
    CLEAR_DELETE_REVIEWER_DATA,
    REMOVE_SELECTED_REVIEWER_DATA,
    STORE_ALL_REVIEWERS_AS_SELECTED,
    ADD_MEMBER_TO_MEETING_LIST,
    REMOVE_MEMBER_FROM_MEETING_LIST,
    UPDATE_REVIEW_MEETING_DETAILS,
    CLEAR_REVIEW_INVITE_ERROR_DATA,
    SEND_REVIEW_MEETING_INVITE_REQUEST,
    SEND_REVIEW_MEETING_INVITE_SUCCESS,
    SEND_REVIEW_MEETING_INVITE_FAILURE,
    CLEAR_REVIEW_MEETING_DATA,
    CHECK_CREDENTIALS_REQUEST,
    CHECK_CREDENTIALS_SUCCESS,
    CHECK_CREDENTIALS_FAILURE,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_FAILURE,
    CLEAR_CHECK_CREDENTIALS_DATA,
} from '../../constants/admin/ReviewerConstants';

const initialState = {
    reviewerListLoading: false,
    reviewerListErrorMsg: '',
    reviewerListSuccessMsg: '',
    reviewerList: '',

    getAssignedList: false,

    assignReviewerSuccess: false,
    assignReviewerSuccessMsg: '',
    assignReviewerErrorMsg: '',
    selectedReviewers: [],
    reviewerAssignedSuccessList: [],
    reviewerAssignedErrorList: [],

    assignedReviewerList: [],
    assignedReviewerListLoading: false,
    assignedReviewerListSuccess: '',
    assignedReviewerListError: '',

    reviewerDeleted: false,
    deleteReviewerSuccess: '',
    deleteReviewerError: '',

    meetingMemberList: [],
    reviewMeetingData: {
        title: '',
        description: '',
        start: 0,
        end: 0,
        date: '',
    },
    reviewInviteSent: false,
    reviewMeetingLoading: false,
    reviewInviteSuccessMsg: '',
    reviewInviteErrorMsg: '',

    checkCredentialsCode: '',
    checkCredentialsLoading: false,
    checkCredentialsErrorMsg: '',
    checkCredentialsSuccessMsg: '',

    googleLoginSuccess: false,
    googleLoginSuccessMsg: '',
    googleLoginErrorMsg: '',
};

export default function reviewerReducer(state = initialState, action) {
    switch (action.type) {
        case GET_REVIEWER_LIST_REQUEST:
            return Object.assign({}, state, {
                reviewerListLoading: true,
                postAssignError: '',
                reviewerList: '',
            });
        case GET_REVIEWER_LIST_SUCCESS:
            return Object.assign({}, state, {
                reviewerListLoading: false,
                reviewerListErrorMsg: '',
                reviewerListSuccessMsg: action.response.data.message,
                reviewerList: action.response.data.list,
            });
        case GET_REVIEWER_LIST_FAILURE:
            return Object.assign({}, state, {
                reviewerListLoading: false,
                reviewerListErrorMsg: action.response.data.message,
                reviewerListSuccessMsg: '',
                reviewerList: '',
            });
        case ASSIGN_REVIEWER_REQUEST:
            return Object.assign({}, state, {
                assignReviewerSuccess: false,
                assignReviewerSuccessMsg: '',
                assignReviewerErrorMsg: '',
            });
        case ASSIGN_REVIEWER_SUCCESS:
            return Object.assign({}, state, {
                assignReviewerSuccess: true,
                getAssignedList: true,
                assignReviewerSuccessMsg: action.response.data.message,
                reviewerAssignedSuccessList: action.response.data.successful,
                reviewerAssignedErrorList: action.response.data.unsuccessful,
                assignReviewerErrorMsg: '',
            });
        case ASSIGN_REVIEWER_FAILURE:
            return Object.assign({}, state, {
                assignReviewerSuccess: false,
                assignReviewerSuccessMsg: '',
                assignReviewerErrorMsg: action.response.data.message,
            });
        case STORE_SELECTED_REVIEWER_DATA:
            return Object.assign({}, state, {
                selectedReviewers: state.selectedReviewers.concat(action.data),
            });
        case STORE_ALL_REVIEWERS_AS_SELECTED:
            return Object.assign({}, state, {
                selectedReviewers: action.list,
            });
        case REMOVE_SELECTED_REVIEWER_DATA:
            if (action.data > -1) {
                state.selectedReviewers.splice(action.data, 1);
                return Object.assign({}, state, {
                    selectedReviewers: state.selectedReviewers,
                });
            }
            break
        case CLEAR_ASSIGN_REVIEWER_DATA:
            return Object.assign({}, state, {
                selectedReviewers: [],
                reviewerAssignedSuccessList: [],
                reviewerAssignedErrorList: [],
                assignReviewerErrorMsg: '',
                assignReviewerSuccessMsg: '',
                getAssignedList: false,
            });
        case ASSIGNED_REVIEWER_LIST_REQUEST:
            return Object.assign({}, state, {
                assignedReviewerList: [],
                getAssignedList: false,
                assignedReviewerListLoading: true,
                assignedReviewerListSuccess: '',
                assignedReviewerListError: '',
            });
        case ASSIGNED_REVIEWER_LIST_SUCCESS:
            return Object.assign({}, state, {
                assignedReviewerList: action.response.data.list,
                assignedReviewerListLoading: false,
                assignedReviewerListSuccess: action.response.data.message,
                assignedReviewerListError: '',
            });
        case ASSIGNED_REVIEWER_LIST_FAILURE:
            return Object.assign({}, state, {
                assignedReviewerList: action.response.data.list,
                assignedReviewerListLoading: false,
                assignedReviewerListSuccess: '',
                assignedReviewerListError: action.response.data.message,
            });
        case DELETE_ASSIGNED_REVIEWER_REQUEST:
            return Object.assign({}, state, {
                reviewerDeleted: false,
                deleteReviewerSuccess: '',
                deleteReviewerError: '',
            });
        case DELETE_ASSIGNED_REVIEWER_SUCCESS:
            return Object.assign({}, state, {
                reviewerDeleted: true,
                getAssignedList: true,
                deleteReviewerSuccess: action.response.data.message,
                deleteReviewerError: '',
            });
        case DELETE_ASSIGNED_REVIEWER_FAILURE:
            return Object.assign({}, state, {
                reviewerDeleted: false,
                deleteReviewerSuccess: '',
                deleteReviewerError: action.response.data.message,
            });
        case CLEAR_DELETE_REVIEWER_DATA:
            return Object.assign({}, state, {
                deleteReviewerSuccess: '',
                deleteReviewerError: '',
            });
        case ADD_MEMBER_TO_MEETING_LIST:
            return Object.assign({}, state, {
                meetingMemberList: state.meetingMemberList.concat(
                    action.member
                ),
            });
        case REMOVE_MEMBER_FROM_MEETING_LIST:
            return Object.assign({}, state, {
                meetingMemberList: action.list,
            });
        case UPDATE_REVIEW_MEETING_DETAILS:
            return Object.assign({}, state, {
                reviewMeetingData: action.data,
            });
        case SEND_REVIEW_MEETING_INVITE_REQUEST:
            return Object.assign({}, state, {
                reviewInviteSent: false,
                reviewMeetingLoading: true,
                reviewInviteSuccessMsg: '',
                reviewInviteErrorMsg: '',
            });
        case SEND_REVIEW_MEETING_INVITE_SUCCESS:
            return Object.assign({}, state, {
                reviewInviteSent: true,
                reviewMeetingLoading: false,
                reviewInviteSuccessMsg: action.response.data.message,
                reviewInviteErrorMsg: '',
            });
        case SEND_REVIEW_MEETING_INVITE_FAILURE:
            return Object.assign({}, state, {
                reviewInviteSent: false,
                reviewMeetingLoading: false,
                reviewInviteSuccessMsg: '',
                reviewInviteErrorMsg: action.response.data.message,
            });
        case CLEAR_REVIEW_MEETING_DATA:
            return Object.assign({}, state, {
                reviewInviteSent: false,
                reviewInviteSuccessMsg: '',
                reviewInviteErrorMsg: '',
                meetingMemberList: [],
                reviewMeetingData: {
                    title: '',
                    description: '',
                    start: 0,
                    end: 0,
                    date: '',
                },
            });
        case CLEAR_REVIEW_INVITE_ERROR_DATA:
            return Object.assign({}, state, {
                reviewInviteSent: false,
                reviewInviteSuccessMsg: '',
                reviewInviteErrorMsg: '',
            });
        case CHECK_CREDENTIALS_REQUEST:
            return Object.assign({}, state, {
                checkCredentialsCode: '',
                checkCredentialsLoading: true,
                checkCredentialsErrorMsg: '',
                checkCredentialsSuccessMsg: '',
            });
        case CHECK_CREDENTIALS_SUCCESS:
            return Object.assign({}, state, {
                checkCredentialsCode: action.response.data.code,
                checkCredentialsErrorMsg: '',
                checkCredentialsLoading: false,
                checkCredentialsSuccessMsg: action.response.data.message,
            });
        case CHECK_CREDENTIALS_FAILURE:
            return Object.assign({}, state, {
                checkCredentialsCode: action.response.data.code,
                checkCredentialsLoading: false,
                checkCredentialsErrorMsg: action.response.data.message,
                checkCredentialsSuccessMsg: '',
            });
        case GOOGLE_LOGIN_REQUEST:
            return Object.assign({}, state, {
                googleLoginSuccess: false,
                googleLoginSuccessMsg: '',
                googleLoginErrorMsg: '',
            });
        case GOOGLE_LOGIN_SUCCESS:
            return Object.assign({}, state, {
                googleLoginSuccess: true,
                googleLoginSuccessMsg: action.response.data.message,
                googleLoginErrorMsg: '',
            });
        case GOOGLE_LOGIN_FAILURE:
            return Object.assign({}, state, {
                googleLoginSuccess: false,
                googleLoginSuccessMsg: '',
                googleLoginErrorMsg: action.response.data.message,
            });
        case CLEAR_CHECK_CREDENTIALS_DATA:
            return Object.assign({}, state, {
                checkCredentialsCode: '',
                checkCredentialsErrorMsg: '',
                checkCredentialsSuccessMsg: '',
            });

        default:
            return state;
    }
}
