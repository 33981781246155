import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/header.css';
import ReactTooltip from 'react-tooltip';
import { Avatar } from 'xd-react-avatar';
import { logout } from '../../../actions/auth/authActions';
import { getUserProfilePic } from '../../../actions/employee/userActions';

import { Dialogbox } from 'xd-react-dialogbox';

class Header extends Component {
    componentDidMount() {
        this.props.dispatch(getUserProfilePic(this.props.profilePicture));
        document.addEventListener('click', function (event) {
            const openProject = document.querySelector(
                '.project-dropdown-menu.show'
            );
            const openpop = document.querySelector(
                '.profile-dropdown-menu.show'
            );
            if (openProject !== null) {
                if (
                    event.target.closest(
                        '.info-navbar-menu-item.header-project'
                    )
                ) {
                    // do nothing
                } else {
                    openProject.classList.remove('show');
                }
            }
            if (openpop !== null) {
                if (
                    event.target.closest('.profile-dropdown-menu') ||
                    event.target.closest('.user-image-outer')
                ) {
                    // do nothing
                } else {
                    openpop.classList.remove('show');
                }
            }
        });
    }

    handleProfilePopup(e) {
        document
            .querySelector('.profile-dropdown-menu')
            .classList.toggle('show');
    }
    handleproject(e) {
        document
            .querySelector('.project-dropdown-menu')
            .classList.toggle('show');
    }
    hideHeaderMenuOnMobileView(e) {
        document.querySelector('.navigation-bar').classList.remove("navigation-bar-height");
         document.querySelector('.info-navbar-menu').classList.remove('info-navbar-menu-block')
    }
    openLogoutDialog() {
        document.querySelector('#logoutDialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
    }
    // submit response of dialog
    submitLogoutDialog() {
        this.props.dispatch(logout());
        document.querySelector('.dialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    // closes the dialog
    closeDialogbox() {
        document.querySelector('.dialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }

    //Hamburger Mobile view

    hamburgerToggle() {
        document
            .getElementsByClassName('info-navbar-menu')[0]
            .classList.toggle('info-navbar-menu-block');
        document
            .getElementsByClassName('navigation-bar')[0]
            .classList.toggle('navigation-bar-height');
    }

    linkRemoval() {
        if (
            document
                .getElementsByClassName('info-navbar-menu')[0]
                .classList.contains('info-navbar-menu-block') &&
            document
                .getElementsByClassName('navigation-bar')[0]
                .classList.contains('navigation-bar-height')
        ) {
            document
                .getElementsByClassName('info-navbar-menu')[0]
                .classList.remove('info-navbar-menu-block');
            document
                .getElementsByClassName('navigation-bar')[0]
                .classList.remove('navigation-bar-height');
        }
    }
    render() {
        return (
            <>
                <header
                    className={
                        this.props.toggleStatus
                            ? 'header-section header-fixed '
                            : 'header-section header-fixed closed'
                    }
                    id="header"
                >
                    <div className="container">
                        <nav id="navigation-bar" className="navigation-bar">
                            <div className="brand-logo-wrapper">
                                <figure className="brand-logo-md">
                                    <img
                                        src={require('../../../static/images/normal-logo.svg')}
                                        className="brand-logo"
                                        alt="XenonStack OnBording Portal"
                                    />
                                </figure>
                                <figure className="brand-logo-xs">
                                    <img
                                        src={require('./dist/xenonstack-logo-xs.png')}
                                        className="brand-logo"
                                        alt="XenonStack OnBording Portal"
                                    />
                                </figure>
                                <figure className="hamburger-logo">
                                    <img
                                        src={require('../../../static/images/hamburger.svg')}
                                        onClick={this.hamburgerToggle.bind(
                                            this
                                        )}
                                    />
                                </figure>
                            </div>

                            <div className="navigation-menu-bar">
                                <div className="info-navigation-menu nav-left"></div>
                                <div className="info-navigation-menu nav-right">
                                    <ul className="info-navbar-menu">
                                        <li
                                            onClick={this.linkRemoval.bind(
                                                this
                                            )}
                                        > 
                                        </li>

                                        <li>
                                            <div
                                                className="info-navbar-menu-item user-image-outer"
                                                data-tip
                                                data-for="profile"
                                                onClick={this.handleProfilePopup.bind(
                                                    this
                                                )}
                                            >
                                                {this.props.profilePic ===
                                                '' ? (
                                                    <Avatar
                                                        size={30}
                                                        marginRight={6}
                                                        name={
                                                            this.props
                                                                .userProfileName !==
                                                            ''
                                                                ? this.props
                                                                      .userProfileName
                                                                : 'XS'
                                                        }
                                                    />
                                                ) : (
                                                    <Avatar
                                                        color={'#F99316'}
                                                        size={30}
                                                        marginRight={6}
                                                        name={
                                                            this.props
                                                                .userProfileName !==
                                                            ''
                                                                ? this.props
                                                                      .userProfileName
                                                                : 'XS'
                                                        }
                                                        src={
                                                            this.props
                                                                .profilePic !==
                                                            ''
                                                                ? this.props
                                                                      .profilePic
                                                                : ''
                                                        }
                                                    />
                                                )}

                                                <span className="user-auth-name">
                                                    {this.props.userProfileName}
                                                </span>
                                                <i className="fa fa-angle-down arrow-down"></i>
                                            </div>
                                            <div className="profile-dropdown-menu">
                                                <div className="profile-dropdown-item">
                                                    <div className="dropdown-content-box">
                                                        <ul>
                                                            <li>
                                                                <NavLink to="/employee/profile/account-information"
                                                                onClick={(e)=> {this.hideHeaderMenuOnMobileView()}}
                                                                >
                                                                    Profile
                                                                </NavLink>
                                                            </li>
                                                            <li>
                                                            <NavLink
                                                            to="/employee/modules"
                                                            onClick={(e)=> {this.hideHeaderMenuOnMobileView()}}
                                                            >
                                                            Training
                                                            </NavLink>
                                                         </li>
                                                        </ul>
                                                    </div>
                                                    <div className="dropdown-footer-box">
                                                        <button
                                                            type="button"
                                                            className="logout-link"
                                                            onClick={this.openLogoutDialog.bind(
                                                                this
                                                            )}
                                                        >
                                                            <i className="fa fa-sign-out"></i>
                                                            Logout
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                        <Dialogbox
                            title="Logout"
                            successButton="Logout"
                            cancelButton="Cancel"
                            submitDialog={this.submitLogoutDialog.bind(this)}
                            closeDialog={this.closeDialogbox}
                            dialogBoxID="logoutDialog"
                        >
                            <p>Are you sure you want to Logout?</p>
                        </Dialogbox>
                    </div>
                </header>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { toggleStatus } = state.appReducer;
    const { userName, profilePicture } = state.authReducer;
    const {
        userProfilePictureLink,
        profilePic,
        userProfileName,
        profilePicErrorMsg,
    } = state.userReducer;
    return {
        toggleStatus,
        userName,
        profilePicture,
        userProfilePictureLink,
        profilePic,
        userProfileName,
        profilePicErrorMsg,
    };
}

export default withRouter(connect(mapStateToProps)(Header));
