import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { BlockLoader} from 'xd-react-loader';
import NoDataFound from '../../../../components/app/no-data-found/NoDataFound';
import { getDocumentLinks } from '../../../../actions/admin/employeeActions';

class EducationDetails extends Component{
    constructor(props){
        super(props)
        this.state= { }
    }
    handleGetDocumentLink(link){
        this.props.dispatch(getDocumentLinks(link))
    }
render(){
    const { userProfileData} = this.props  
    return(                       
        <section className="content-section-wrapper">
            <div className="row">               
                <div className="col-12 col-sm-12 col-md-10 col-lg-10">                                
                    <div className="user-details">
                        {this.props.isProfileLoading?(
                        <div className="page-section-loader">
                            <BlockLoader 
                            loadingText="Loading Educational Details.." 
                            loading={true}
                            loaderStyle={{size:"24px",
                            color:"0562e8",
                            textSize:"14px",
                            textColor:"#757575",
                            speed:"0.5s"
                        }}
                        />
                        </div>
                        ):this.props.userProfileError?(
                            <NoDataFound title={this.props.userProfileError} />
                        ):this.props.profileSuccess?(userProfileData.education_details.length!==0?(<div className="details-container">
                        <div className="user-profile-container">  
                        {userProfileData.education_details.map((education,eduIndex)=>
                        <>
                        <div className="profile-section_card education-section_card">
                            <div className="profile-section_card_item" key={eduIndex}>
                                <article className="profile-section_card_details">
                                    <h3><strong>{education.name}</strong><span>(Year - {education.year})</span></h3>
                                    <div className="profile-card-meta_data">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div className="profile-fields_data">
                                                    <p className="fields-name">School/College/University</p>
                                                    <p className="fields-value">{education.institution!== ''? education.institution: 'N/A'}</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="profile-fields_data">
                                                    <p className="fields-name">Location</p>
                                                    <p className="fields-value">{education.location!== ''? education.location: 'N/A'}</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="profile-fields_data">
                                                    <p className="fields-name">Percentage/CGPA</p>
                                                    <p className="fields-value">{education.percentage!== ''? education.percentage: 'N/A'}</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="profile-fields_data">
                                                    <p className="fields-name">Passing Year</p>
                                                    <p className="fields-value">{education.year!== ''? education.year: 'N/A'}</p>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="profile-fields_data education-document-list">
                                                    <p className="fields-name">Marksheet</p>
                                                    {(education.marksheet_link.length!==0 && education.marksheet_link) ? (education.marksheet_link.map((marksheet, marIndex)=>
                                                    <p className="fields-value fields-value_link" key={marIndex}>
                                                        Marksheet - {marIndex+1}
                                                        <a href="javascript:void(0)"  onClick={()=>this.handleGetDocumentLink(marksheet)} className="doc-download">
                                                            <i className="icon-cloud-download"></i>Download 
                                                        </a>
                                                    </p>                                                                            
                                                    )): (<span className="no-data-text-small">N/A</span>)}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="profile-fields_data">
                                                    <p className="fields-name">Degree</p>
                                                    <p className="fields-value fields-value_link">
                                                        {education.degree_link !== '' ?
                                                        (<>
                                                        Degree
                                                        <a href="javascript:void(0)"  onClick={()=>this.handleGetDocumentLink(education.degree_link)} className="doc-download"> <i className="icon-cloud-download"></i>Download 
                                                        </a>
                                                        </>)
                                                        : (<span className="no-data-text-small">N/A</span>)
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                
                            </div> 
                            
                        </div>  
                        </>
                        )}
                               
                        </div>                                               
                        </div>   
                    ):(<NoDataFound title="No Educational Information Available" description="User has not added their educational information yet"/>
                    )):(
                        <NoDataFound />
                    )}
                    </div>
                </div>
            </div>
        </section>
        )
    }
}

function mapStateToProps(state) {
    const {isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink} = state.employeeReducer;
    return { isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink}
}

export default withRouter(connect(mapStateToProps)(EducationDetails))
