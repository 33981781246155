import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NoDataFound } from 'xd-react-nodatafound';
import { withRouter, NavLink } from 'react-router-dom';
import {
    getMembersList,
    deleteMember,
} from '../../../../actions/admin/memberAction';
import {
    Table,
    TableRow,
    TableBody,
    TableHeaderRow,
    TableHeader,
    TableHeadComponent,
    ThComponent,
    TablePagination,
} from 'xd-react-table';
import userRoles from '../../../../components/roles/roles'
import { PulseLoader } from 'xd-react-loader';
import { Dialogbox } from 'xd-react-dialogbox';
import { Button } from 'xd-react-button';
import createNotification from '../../../../components/app/notification/createNotification';
class ViewMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultPageSize: 10,
            activePageNumber: 1,
            memberId: '',
        };
    }

    componentDidMount() {
        this.props.dispatch(getMembersList());
        window.scrollTo(0, 0);

        window.addEventListener('click', this.handleOutsideClick, true);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleOutsideClick, true);
    }

    handlePaginationChange = (e) => {
        this.setState({
            defaultPageSize: this.state.defaultPageSize,
            activePageNumber: e,
        });
    };

    handlePageSizeChange = (e) => {
        this.setState({
            defaultPageSize: Number(e.target.value),
            activePageNumber: 1,
        });
    };

    // opens the dialog
    openDeleteDialog(id) {
        this.setState({ memberId: id });
        document.querySelector('#agentDialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
    }

    submitDeleteDialog() {
        this.props.dispatch(deleteMember(this.state.memberId));
        document.querySelector('#agentDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    // closes the dialog
    closeDialogbox() {
        document.querySelector('#agentDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.deleteMemberMessage !== '') {
            if (nextProps.deleteMemberError) {
                createNotification('error', nextProps.deleteMemberMessage);
            } else {
                createNotification('success', nextProps.deleteMemberMessage);
                nextProps.history.push('/admin/invite-member/list');
                this.props.dispatch(getMembersList());
            }
        }
    }

    render() {
        const { defaultPageSize, activePageNumber } = this.state;
        return (
            <>
                <main className="main-content-wrapper">
                    <section className="dashboard-content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-left">
                                        <h2 className="content-header-title">
                                            List Members
                                        </h2>
                                        <p className="content-header-description">
                                            List of Reviewers, Admin etc.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-right dashboard-header-action-group">
                                        <div>
                                            {this.props.roleId === userRoles.Admin && (
                                                <Button
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            '/admin/invite-member'
                                                        )
                                                    }
                                                    className="btn btn-icon icon-left"
                                                >
                                                    <i className="fa fa-plus btn-icon"></i>
                                                    <span className="btn-text">
                                                        Invite Member
                                                    </span>
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="dashboard-body-wrapper">
                        <div className="container">
                            {this.props.isPageLoading && (
                                <PulseLoader
                                    margin="30px"
                                    sizeUnit={'px'}
                                    size={15}
                                    color={'#0562e8'}
                                    loading={this.props.isPageLoading}
                                />
                            )}
                            {!this.props.isPageLoading &&
                                this.props.membersList.length > 0 && (
                                    <div className="react-xd-table-wrapper listing-table">
                                        <Table
                                            data={this.props.membersList}
                                            pageSize={defaultPageSize}
                                            currentPage={activePageNumber}
                                            pageSizeOptions={[5, 10, 15, 20]}
                                            render={({
                                                rows,
                                                pageSize,
                                                pageSizeOptions,
                                                currentPage,
                                                total,
                                            }) => {
                                                return (
                                                    <React.Fragment>
                                                        <div className="react-xd-table-body">
                                                            <table className="react-xd-table striped-xd-table sortable-table ver-responsive-table footer-pagination-table">
                                                                <TableHeaderRow
                                                                    component={
                                                                        TableHeadComponent
                                                                    }
                                                                >
                                                                    <TableHeader
                                                                        accessor="sr_no"
                                                                        accessor_name="Sr. No."
                                                                        component={
                                                                            ThComponent
                                                                        }
                                                                    >
                                                                        Sr. No.
                                                                    </TableHeader>

                                                                    <TableHeader
                                                                        accessor="name"
                                                                        accessor_name="Name"
                                                                        component={
                                                                            ThComponent
                                                                        }
                                                                        sortable
                                                                    >
                                                                        Name
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor="email"
                                                                        accessor_name="Email"
                                                                        component={
                                                                            ThComponent
                                                                        }
                                                                        sortable
                                                                    >
                                                                        Email
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor="role"
                                                                        accessor_name="Role"
                                                                        component={
                                                                            ThComponent
                                                                        }
                                                                        sortable
                                                                    >
                                                                        Role
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor="account_status"
                                                                        accessor_name="Account Status"
                                                                        component={
                                                                            ThComponent
                                                                        }
                                                                        sortable
                                                                    >
                                                                        Account
                                                                        Status
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor="contact_no"
                                                                        accessor_name="Contact No"
                                                                        component={
                                                                            ThComponent
                                                                        }
                                                                        sortable
                                                                    >
                                                                        Contact
                                                                        No.
                                                                    </TableHeader>
                                                                    <TableHeader
                                                                        accessor_name="Action"
                                                                        component={
                                                                            ThComponent
                                                                        }
                                                                    >
                                                                        Action
                                                                    </TableHeader>
                                                                </TableHeaderRow>
                                                                <TableBody component="tbody">
                                                                    {rows.map(
                                                                        (
                                                                            {
                                                                                rowKey,
                                                                                rowData,
                                                                                selected,
                                                                            },
                                                                            rowIndex
                                                                        ) => (
                                                                            <TableRow
                                                                                component="tr"
                                                                                className="xd-table-row xd-table-td"
                                                                                key={
                                                                                    rowKey
                                                                                }
                                                                            >
                                                                                {rowData.map(
                                                                                    ({
                                                                                        accessor,
                                                                                        accessor_name,
                                                                                        data,
                                                                                        key,
                                                                                        rowIndex,
                                                                                        rowsData,
                                                                                    }) => (
                                                                                        <td
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                            data-value={
                                                                                                accessor_name
                                                                                            }
                                                                                        >
                                                                                            {accessor ? (
                                                                                                accessor ===
                                                                                                'sr_no' ? (
                                                                                                    <span>
                                                                                                        {rowIndex +
                                                                                                            defaultPageSize *
                                                                                                                (activePageNumber -
                                                                                                                    1) +
                                                                                                            1}{' '}
                                                                                                        .
                                                                                                    </span>
                                                                                                ) : accessor ===
                                                                                                  'role' ? (
                                                                                                    <span>
                                                                                                        {rowsData.role ===
                                                                                                        'p&c'
                                                                                                            ? 'People & Culture'
                                                                                                            : rowsData.role
                                                                                                                  .charAt(
                                                                                                                      0
                                                                                                                  )
                                                                                                                  .toUpperCase() +
                                                                                                              rowsData.role.slice(
                                                                                                                  1
                                                                                                              )}{' '}
                                                                                                    </span>
                                                                                                ) : accessor ===
                                                                                                  'account_status' ? (
                                                                                                    <span>
                                                                                                        {rowsData.account_status
                                                                                                            ? rowsData.account_status
                                                                                                                  .charAt(
                                                                                                                      0
                                                                                                                  )
                                                                                                                  .toUpperCase() +
                                                                                                              rowsData.account_status.slice(
                                                                                                                  1
                                                                                                              )
                                                                                                            : ''}{' '}
                                                                                                    </span>
                                                                                                ) : accessor ===
                                                                                                  'contact_no' ? (
                                                                                                    <span>
                                                                                                        {rowsData.contact_no ===
                                                                                                        ''
                                                                                                            ? 'n/a'
                                                                                                            : rowsData.contact_no}{' '}
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    data
                                                                                                )
                                                                                            ) : (
                                                                                                <div
                                                                                                    className="table-action-group"
                                                                                                    style={{
                                                                                                        minWidth:
                                                                                                            '50px',
                                                                                                    }}
                                                                                                >
                                                                                                    {this
                                                                                                        .props
                                                                                                        .roleId ===
                                                                                                    userRoles.Admin ? (
                                                                                                        <span
                                                                                                            style={{
                                                                                                                cursor: 'pointer',
                                                                                                            }}
                                                                                                            className="xd-table-btn delete"
                                                                                                            onClick={() =>
                                                                                                                this.openDeleteDialog(
                                                                                                                    rowsData.email
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <i
                                                                                                                class="fa fa-trash"
                                                                                                                aria-hidden="true"
                                                                                                            ></i>{' '}
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span
                                                                                                            style={{
                                                                                                                cursor: 'not-allowed',
                                                                                                            }}
                                                                                                            className="xd-table-btn delete disabled"
                                                                                                        >
                                                                                                            <i
                                                                                                                class="fa fa-trash"
                                                                                                                aria-hidden="true"
                                                                                                            ></i>{' '}
                                                                                                        </span>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                    )
                                                                                )}
                                                                            </TableRow>
                                                                        )
                                                                    )}
                                                                </TableBody>
                                                            </table>
                                                        </div>
                                                        <div className="react-xd-table-footer">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-7 col-md-8">
                                                                    <div className="react-xd-pagination-container">
                                                                        <div className="pagination-outer">
                                                                            <div className="pagination-group">
                                                                                <TablePagination
                                                                                    firstPageText={
                                                                                        'First'
                                                                                    }
                                                                                    lastPageText="Last"
                                                                                    prevPageText={
                                                                                        <i className="fa fa-angle-left" />
                                                                                    }
                                                                                    nextPageText={
                                                                                        <i className="fa fa-angle-right" />
                                                                                    }
                                                                                    activePage={
                                                                                        activePageNumber
                                                                                    }
                                                                                    itemsCountPerPage={
                                                                                        defaultPageSize
                                                                                    }
                                                                                    totalItemsCount={
                                                                                        total
                                                                                    }
                                                                                    pageRangeDisplayed={
                                                                                        3
                                                                                    }
                                                                                    handlePaginationChange={
                                                                                        this
                                                                                            .handlePaginationChange
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                            {!this.props.isPageLoading &&
                                this.props.membersList.length === 0 && (
                                    <NoDataFound
                                        alt="No Tickets"
                                        NoDataTitle="No Member Added."
                                        NoDataDesc={
                                            <>
                                                You have not Added Other
                                                Members.{' '}
                                                <NavLink to="/invite-member">
                                                    Click here
                                                </NavLink>{' '}
                                                to Invite more Members
                                            </>
                                        }
                                    />
                                )}
                        </div>
                        <Dialogbox
                            dialogBoxID="agentDialog"
                            title="Delete Member"
                            successButton="Delete"
                            cancelButton="Cancel"
                            submitDialog={this.submitDeleteDialog.bind(this)}
                            closeDialog={this.closeDialogbox}
                        >
                            <p>Are you sure you want to Delete this Member?</p>
                        </Dialogbox>
                    </div>
                </main>
            </>
        );
    }
}

function mapStateToProps(state) {
    const {
        successMessage,
        errorMessage,
        membersList,
        isPageLoading,
        deletedMember,
        deleted,
        searchQuery,
        sortedBy,
        isAsc,
        membersPerPage,
        activePage,
        resourcesDeleted,
        deleteMemberMessage,
        deleteMemberError,
    } = state.member;
    const {roleId} = state.authReducer;
    const { toggleStatus } = state.appReducer;
    return {
        successMessage,
        roleId,
        errorMessage,
        isPageLoading,
        deletedMember,
        deleted,
        membersList,
        searchQuery,
        sortedBy,
        isAsc,
        membersPerPage,
        activePage,
        resourcesDeleted,
        deleteMemberMessage,
        deleteMemberError,
        toggleStatus,
    };
}

export default withRouter(connect(mapStateToProps)(ViewMembers));
