import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {} from '../../../actions/admin/employeeActions';
import './dist/style.css';
import {
    getModuleList,
    changePageNumber,
    deleteModule,
} from '../../../actions/admin/internModuleActions';
import { SpinLoader } from 'xd-react-loader';
import Pagination from 'react-js-pagination';
import {
    Table,
    TableRow,
    TableBody,
    TableHeaderRow,
    TableHeader,
    TableHeadComponent,
    ThComponent,
} from 'xd-react-table';
import createNotification from '../../../components/app/notification/createNotification';
import { Dialogbox } from 'xd-react-dialogbox';
import { NoDataFound } from 'xd-react-nodatafound';
import ReactTooltip from 'react-tooltip';
import userRoles from '../../../components/roles/roles';
class ListModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moduleId: '',
            selectedModuleName: '',
        };
    }
    componentDidMount() {
        const data = {
            page: 1,
            level: this.props.level,
        };
        this.props.dispatch(getModuleList(data));
        this.props.dispatch(changePageNumber(1));
    }

    handlePageChange(pageNumber) {
        this.props.dispatch(changePageNumber(pageNumber));
        this.props.history.push('/admin/employee/list-module/' + pageNumber);
        const data = {
            page: pageNumber,
            level: this.props.level,
        };
        this.props.dispatch(getModuleList(data));
        this.forceUpdate();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.deleteModuleMessage !== '') {
            if (nextProps.deleteModuleTasksError) {
                createNotification('error', nextProps.deleteModuleMessage);
            } else {
                createNotification('success', nextProps.deleteModuleMessage);
                nextProps.history.push('/admin/employee/list-module/1');
                const data = {
                    page: 1,
                    level: this.props.level,
                };
                this.props.dispatch(getModuleList(data));
            }
        }
    }

    openLogoutDialog(id) {
        this.setState({ moduleId: id });
        let moduleList = [...this.props.moduleList];
        let moduleListArray = moduleList.filter((data) => data.id === id);
        this.state.selectedModuleName = moduleListArray[0]?.name;
        document.querySelector('#deleteModuleDialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
    }

    submitDeleteDialog() {
        this.props.dispatch(deleteModule(this.state.moduleId));
        document
            .querySelector('#deleteModuleDialog')
            .classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    // closes the dialog
    closeDialogbox() {
        document
            .querySelector('#deleteModuleDialog')
            .classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    assignFeedbackForm(data) {
        this.props.history.push(
            `/admin/employee/list-module/${data.id}/reassign`
        );
    }
    render() {
        return (
            <React.Fragment>
                <section className="content-section-wrapper">
                    {this.props.getModulePageLoading && (
                        <div className="align-center">
                            <SpinLoader
                                loaderStyle={{ size: '50px', speed: '1s' }}
                                loading={true}
                            />
                        </div>
                    )}
                    {!this.props.getModulePageLoading &&
                        this.props.moduleList.length > 0 && (
                            <Table
                                data={this.props.moduleList}
                                pageSize={10}
                                pageSizeOptions={[10, 20, 30, 40, 50]}
                                startIndex={0}
                                endIndex={10}
                                render={({
                                    rows,
                                    pageSize,
                                    pageSizeOptions,
                                    handlePageSizeChange,
                                    handlePrevPage,
                                    handleNextPage,
                                    hasNextPage,
                                    hasPrevPage,
                                    currentPage,
                                    total,
                                    startIndex,
                                    endIndex,
                                }) => {
                                    return (
                                        <React.Fragment>
                                            <div className="react-xd-table-body">
                                                <table className="react-xd-table striped-xd-table ver-responsive-table sortable-table footer-pagination-table">
                                                    <TableHeaderRow
                                                        component={
                                                            TableHeadComponent
                                                        }
                                                    >
                                                        <TableHeader
                                                            accessor="name"
                                                            accessor_name="Name"
                                                            component={
                                                                ThComponent
                                                            }
                                                            sortable
                                                        >
                                                            Name
                                                        </TableHeader>
                                                        <TableHeader
                                                            accessor="level"
                                                            accessor_name="Level"
                                                            component={
                                                                ThComponent
                                                            }
                                                            sortable
                                                        >
                                                            Level
                                                        </TableHeader>
                                                        <TableHeader
                                                            accessor="added_by"
                                                            accessor_name="Added By"
                                                            component={
                                                                ThComponent
                                                            }
                                                            sortable
                                                        >
                                                            Added By
                                                        </TableHeader>
                                                        <TableHeader
                                                            accessor="duration"
                                                            accessor_name="Duration"
                                                            component={
                                                                ThComponent
                                                            }
                                                            sortable
                                                        >
                                                            Duration(in Days)
                                                        </TableHeader>
                                                        <TableHeader
                                                            accessor="form"
                                                            accessor_name="Feedback"
                                                            component={
                                                                ThComponent
                                                            }
                                                            sortable
                                                        >
                                                            Feedback Form
                                                        </TableHeader>
                                                        <TableHeader
                                                            accessor_name="Action"
                                                            component={
                                                                ThComponent
                                                            }
                                                        >
                                                            {this.props.roleId != userRoles.Reviewer && 
                                                            <div  className="action-table-header">
                                                             Actions
                                                        </div> }
                                                        </TableHeader>
                                                    </TableHeaderRow>
                                                    <TableBody component="tbody">
                                                        {rows.map(
                                                            (
                                                                {
                                                                    rowKey,
                                                                    rowData,
                                                                    selected,
                                                                },
                                                                rowIndex
                                                            ) => (
                                                                <TableRow
                                                                    component="tr"
                                                                    className="xd-table-row xd-table-td"
                                                                    key={
                                                                        rowIndex
                                                                    }
                                                                >
                                                                    {rowData.map(
                                                                        (
                                                                            {
                                                                                accessor,
                                                                                accessor_name,
                                                                                data,
                                                                                key,
                                                                                rowsData,
                                                                            },
                                                                            index
                                                                        ) => {
                                                                            return accessor ? 
                                                                            (
                                                                                <td
                                                                                    data-value={
                                                                                        accessor_name
                                                                                    }
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    style={{
                                                                                        minWidth:
                                                                                            '100px',
                                                                                    }}
                                                                                >
                                                                                    {accessor ===
                                                                                    'name' ? (
                                                                                        <NavLink
                                                                                            to={
                                                                                                '/admin/employee/module-details/' +
                                                                                                rowsData.id
                                                                                            }
                                                                                            onClick={() => {
                                                                                                localStorage.setItem(
                                                                                                    'isProfileModuleClicked',
                                                                                                    false
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                data
                                                                                            }
                                                                                        </NavLink>
                                                                                    ) : accessor ===
                                                                                      'sr_no' ? (
                                                                                        rowIndex +
                                                                                        pageSize *
                                                                                            (this
                                                                                                .props
                                                                                                .currentPageNumber -
                                                                                                1) +
                                                                                        1
                                                                                    ) : accessor ===
                                                                                      'form' ? (
                                                                                        data.length !==
                                                                                        0 ? (
                                                                                            <NavLink
                                                                                                to={
                                                                                                    '/admin/feedback/details/' +
                                                                                                    rowsData.form
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    data
                                                                                                }
                                                                                            </NavLink>
                                                                                        ) : (
                                                                                            'N/A'
                                                                                        )
                                                                                    ) : accessor ===
                                                                                      'AppliedFor' ? (
                                                                                        rowsData.AppliedFor ===
                                                                                        '' ? (
                                                                                            'NA'
                                                                                        ) : (
                                                                                            rowsData.AppliedFor
                                                                                        )
                                                                                    ) : (
                                                                                        data
                                                                                    )}
                                                                                </td>
                                                                            ) 
                                                                            : ( 
                                                                                <td
                                                                                data-value={
                                                                                    accessor_name
                                                                                }
                                                                                key={
                                                                                    key
                                                                                }
                                                                                style={{
                                                                                    minWidth:
                                                                                        '100px',
                                                                                }}
                                                                                >
                                                                                    {this.props.roleId != userRoles.Reviewer && 
                                                                                    <div className="table-action-group">
                                                                                        {this
                                                                                            .props
                                                                                            .roleId !==
                                                                                            userRoles.Reviewer ? (
                                                                                            <>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={() =>
                                                                                                        this.props.history.push(
                                                                                                            '/admin/employee/edit-details/' +
                                                                                                                rowsData.id
                                                                                                        )
                                                                                                    }
                                                                                                    className="xd-table-btn delete"
                                                                                                    data-tip="Edit"
                                                                                                >
                                                                                                    <i
                                                                                                        class="fa fa-pencil-square-o"
                                                                                                        aria-hidden="true"
                                                                                                    ></i>
                                                                                                    <div className="tooltipHeight"></div>
                                                                                                    <ReactTooltip place="bottom" />
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    data-tip="Delete"
                                                                                                    onClick={() =>
                                                                                                        this.openLogoutDialog(
                                                                                                            rowsData.id
                                                                                                        )
                                                                                                    }
                                                                                                    className="xd-table-btn delete"
                                                                                                >
                                                                                                    <i
                                                                                                        class="fa fa-trash"
                                                                                                        aria-hidden="true"
                                                                                                    ></i>
                                                                                                       <div className="tooltipHeight"></div>
                                                                                                    <ReactTooltip place="bottom" />
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    disabled={
                                                                                                        rowsData
                                                                                                            .form
                                                                                                            .length !==
                                                                                                        0
                                                                                                            ? false
                                                                                                            : true
                                                                                                    }
                                                                                                    onClick={() =>
                                                                                                        this.assignFeedbackForm(
                                                                                                            rowsData
                                                                                                        )
                                                                                                    }
                                                                                                    className="xd-table-btn delete"
                                                                                                >
                                                                                                    <i
                                                                                                        class="fa fa-wpforms"
                                                                                                        aria-hidden="true"
                                                                                                    ></i>
                                                                                                </button>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    style={{
                                                                                                        cursor: 'not-allowed',
                                                                                                    }}
                                                                                                    className="xd-table-btn delete disabled"
                                                                                                    data-tip="You are not allowed to perform to this action"
                                                                                                >
                                                                                                    <i
                                                                                                        class="fa fa-pencil-square-o"
                                                                                                        aria-hidden="true"
                                                                                                    ></i>
                                                                                                    <div className="tooltipHeight"></div>
                                                                                                    <ReactTooltip place="bottom" />
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    style={{
                                                                                                        cursor: 'not-allowed',
                                                                                                    }}
                                                                                                    className="xd-table-btn delete disabled"
                                                                                                    data-tip="You are not allowed to perform to this action"
                                                                                                  >
                                                                                                    <i
                                                                                                        class="fa fa-trash"
                                                                                                        aria-hidden="true"
                                                                                                    ></i>
                                                                                                    <div className="tooltipHeight"></div>
                                                                                                    <ReactTooltip place="bottom" />
                                                                                                </button>
                                                                                            </>
                                                                                        )}
                                                                                    </div>}
                                                                                </td>
                                                                            );
                                                                        }
                                                                    )}
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </table>
                                            </div>
                                            <div className="react-xd-table-footer">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12">
                                                        <div className="react-xd-pagination-container">
                                                            <div className="pagination-outer">
                                                                <div className="pagination-group">
                                                                    <Pagination
                                                                        firstPageText={
                                                                            'First'
                                                                        }
                                                                        lastPageText="Last"
                                                                        prevPageText={
                                                                            <i className="fa fa-angle-left" />
                                                                        }
                                                                        nextPageText={
                                                                            <i className="fa fa-angle-right" />
                                                                        }
                                                                        activePage={
                                                                            this
                                                                                .props
                                                                                .internTablePageNumber
                                                                        }
                                                                        itemsCountPerPage={
                                                                            pageSize
                                                                        }
                                                                        totalItemsCount={
                                                                            this
                                                                                .props
                                                                                .moduleCount
                                                                        }
                                                                        pageRangeDisplayed={
                                                                            8
                                                                        }
                                                                        onChange={this.handlePageChange.bind(
                                                                            this
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                }}
                            />
                        )}
                    {!this.props.getModulePageLoading &&
                        this.props.moduleList.length === 0 && (
                            <NoDataFound
                                NoDataImage={require('../../../static/images/no-resultfound.png')}
                                alt="No Modules"
                                NoDataTitle="Module not found."
                                NoDataDesc={
                                    <React.Fragment>
                                        You have not created any Module yet.
                                    </React.Fragment>
                                }
                            />
                        )}
                </section>
                <Dialogbox
                    title="Delete Module"
                    successButton="Delete"
                    cancelButton="Cancel"
                    submitDialog={this.submitDeleteDialog.bind(this)}
                    closeDialog={this.closeDialogbox}
                    dialogBoxID="deleteModuleDialog"
                >
                    <p>
                        Are you sure you want to delete {this.state.selectedModuleName}?
                    </p>
                </Dialogbox>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {
        moduleList,
        moduleMessage,
        getModulePageLoading,
        moduleCount,
        internTablePageNumber,
        deleteModulePageLoading,
        deleteModuleMessage,
        deleteModuleTasksError,
    } = state.moduleReducer;
    const {roleId} = state.authReducer;
    return {
        roleId,
        moduleList,
        moduleMessage,
        getModulePageLoading,
        moduleCount,
        internTablePageNumber,
        deleteModulePageLoading,
        deleteModuleMessage,
        deleteModuleTasksError,
    };
}

export default withRouter(connect(mapStateToProps)(ListModule));
