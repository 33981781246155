/**
 *
 * @param Component
 * @param rest
 * @returns {*}
 * @constructor
 * To Do: edit the following details
 * The purpose of this constant is to-
 * check wheather user is logged in or logged out,
 * if logged in - user can visit any routes (eg. /tenants, /users, /hosta)
 */

import { checkAuth } from './Route';
import React from 'react';
import {store} from "../index";
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';


function checkUserType() {
    const { roleId } = store.getState().authReducer;
    return roleId
}
export const ProtectedRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        return checkAuth() ?(checkUserType()=="admin"?
            <Redirect to='/admin/dashboard'/>:<Redirect to='/employee/modules'/>)
            : <Component {...props}/>
    }}/>
);

function mapStateToProps(state){
    return state
}

export default connect(mapStateToProps)(ProtectedRoute);