import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    BlockLoader,
} from 'xd-react-loader';
import {
    getEmployeeProfile,
    handleChangePasswordDialogChange,
    handleEditAccountDialogChange,
    setAccountInfo,
} from '../../../../actions/employee/userActions';
import ProfileSubHeader from '../ProfileSubHeader';
import UserProfileSidebar from '../UserProfileSidebar';
import ProfileNavigation from '../ProfileNavigation';
import ChangePasswordPopup from '../edit-profile/ChangePasswordPopup';
import EditAccountPopup from '../edit-profile/EditAccountPopup';

class AccountInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editAccountInfo: {},
        };
    }

    handleChangePasswordDialog() {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleChangePasswordDialogChange(true));
    }
    handleEditAccountDialog(accountInfo) {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleEditAccountDialogChange(true));
        if (accountInfo !== '') {
            let account = Object.assign(
                {},
                this.props.profileInfo.profile.account
            );
            this.props.dispatch(setAccountInfo(account));
        } else {
            this.setState({
                editAccountInfo: {},
            });
        }
    }
    render() {
        const { profileInfo } = this.props;
        return (
            <main className="main-content-wrapper employee-profile-wrapper">
                <ProfileSubHeader />
                <div className="dashboard-body-wrapper gry-fill-background">
                    <div className="container">
                        <section className="employee-profile-content-wrapper">
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <div className="employee-profile-side-template">
                                        <UserProfileSidebar />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-9">
                                    <div className="employee-profile-content">
                                        <ProfileNavigation />
                                        {this.props.isUserProfileLoading ? (
                                            <div className="xs-loader-wrapper">
                                                <BlockLoader
                                                    loadingText="loading"
                                                    loading={true}
                                                    loaderStyle={{
                                                        size: '20px',
                                                        color: '0562e8',
                                                        textSize: '18px',
                                                        textColor: '#485e7d',
                                                        speed: '1s',
                                                    }}
                                                />
                                            </div>
                                        ) : this.props.profileErrorMsg ? (
                                            <h3>
                                                {this.props.profileErrorMsg}
                                            </h3>
                                        ) : this.props.getProfileSuccess ? (
                                            <div className="user-profile-container">
                                                <div className="profile-section_card">
                                                    <h2 className="profile-section_card_title">
                                                        Account Details
                                                    </h2>
                                                    <div className="profile-section_grid">
                                                        <div className="profile-section_card_item">
                                                            <article className="profile-section_card_details">
                                                                <div className="profile-action">
                                                                    <strong
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            this.handleEditAccountDialog(
                                                                                profileInfo
                                                                                    .profile
                                                                                    .account
                                                                            )
                                                                        }
                                                                        className="link-button link-button_icon"
                                                                    >
                                                                        <i className="icon-pencil"></i>{' '}
                                                                        Edit
                                                                    </strong>
                                                                </div>
                                                                <div className="profile-card-meta_data">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Name
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {
                                                                                        profileInfo
                                                                                            .profile
                                                                                            .account
                                                                                            .name
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Email
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {
                                                                                        profileInfo
                                                                                            .profile
                                                                                            .account
                                                                                            .email
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Phone
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {
                                                                                        profileInfo
                                                                                            .profile
                                                                                            .account
                                                                                            .contact_no
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Level
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {
                                                                                        profileInfo
                                                                                            .profile
                                                                                            .account
                                                                                            .level
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="profile-section_card">
                                                    <h2 className="profile-section_card_title">
                                                        Team Information
                                                    </h2>
                                                    <div className="profile-section_grid">
                                                        <div className="profile-section_card_item">
                                                            <article className="profile-section_card_details">
                                                                <div className="profile-card-meta_data">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Team
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .pandcinfo
                                                                                        .team
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .pandcinfo
                                                                                              .team !==
                                                                                          ''
                                                                                            ? profileInfo
                                                                                                  .profile
                                                                                                  .pandcinfo
                                                                                                  .team
                                                                                            : 'N/A'
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Head
                                                                                    Coach
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .pandcinfo
                                                                                        .headcoach !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .pandcinfo
                                                                                              .headcoach
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Coach
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .pandcinfo
                                                                                        .coach !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .pandcinfo
                                                                                              .coach
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    People
                                                                                    Manager
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .pandcinfo
                                                                                        .people_manager !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .pandcinfo
                                                                                              .people_manager
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Project
                                                                                    Manager
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .pandcinfo
                                                                                        .project_manager !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .pandcinfo
                                                                                              .project_manager
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Buddy
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .pandcinfo
                                                                                        .buddy !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .pandcinfo
                                                                                              .buddy
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="profile-section_card education-section_card">
                                                    <div className="profile-section_card_item">
                                                        <strong
                                                            className="link-button link-button_icon"
                                                            onClick={(e) =>
                                                                this.handleChangePasswordDialog()
                                                            }
                                                        >
                                                            <i className="fa fa-key"></i>{' '}
                                                            Change Password
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ChangePasswordPopup />
                        <EditAccountPopup />
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        useraccountInfo,
        updateSuccess,
        changePasswordSuccess,
    } = state.userReducer;
    return {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        useraccountInfo,
        updateSuccess,
        changePasswordSuccess,
    };
}

export default withRouter(connect(mapStateToProps)(AccountInfo));
