import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../dist/modal.css';
import { Button } from 'xd-react-button';
import { Input } from 'xd-react-input';
import {
    handleEditOtherDialogChange,
    updateCertificateFields,
    updateProfileDetails,
    uploaduserCertificate,
    addNewUserCertificate,
} from '../../../../actions/employee/userActions';

class EditOtherPopup extends Component {

    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleEditOtherDialogChange(false));
    }

    handleOnChange(e, index) {
        let value = e.target.value;
        const name = e.target.name;
        let otherData = Object.assign([], this.props.userotherInfo);
        otherData[index][name] = value;

        this.props.dispatch(updateCertificateFields(otherData));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
        }
    }
    handleCertificateUpload(e, cerIndex) {
        let input = e.target;
        let name = e.target.name;
        if (this.props.profileInfo.profile.others[cerIndex]) {
            const old_cerificate =
                this.props.profileInfo.profile.others[cerIndex]
                    .certificate_link;
            if (input.files[0]) {
                let file = input.files[0];
                this.props.dispatch(
                    uploaduserCertificate(file, name, cerIndex, old_cerificate)
                );
            }
        } else {
            const old_cerificate = '';
            if (input.files[0]) {
                let file = input.files[0];
                this.props.dispatch(
                    uploaduserCertificate(file, name, cerIndex, old_cerificate)
                );
            }
        }
    }

    handleAddUserCertificate() {
        this.props.dispatch(
            addNewUserCertificate({ name: '', certificate_link: '' })
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        const details = {
            account_info: {
                name: this.props.profileInfo.profile.account.name,
                contact_no: this.props.profileInfo.profile.account.contact_no,
            },
            personal_info: this.props.profileInfo.profile.personal,
            user_address: this.props.profileInfo.profile.address,
            education: this.props.profileInfo.profile.education,
            emergency_contact: this.props.profileInfo.profile.emergency,
            others: this.props.userotherInfo,
        };
        if (e.target.checkValidity()) {
            this.props.dispatch(updateProfileDetails(details));
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    render() {
        const { editOtherPopupState } = this.props;
        const otherInfo = this.props.userotherInfo;
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': editOtherPopupState,
                        fadeOut: !editOtherPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown: editOtherPopupState,
                                fadeOutUp: !editOtherPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">
                                    Other Information
                                </h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form
                                onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true}
                                id="edit-education-dialog"
                            >
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        {otherInfo
                                            ? otherInfo.map(
                                                  (certificate, cerIndex) => (
                                                      <div className="row">
                                                          <div className="col-12 col-sm-6">
                                                              <div>
                                                                  <Input
                                                                      type="text"
                                                                      name="name"
                                                                      className="form-control"
                                                                      errorMessage="Enter the name of certificate"
                                                                      placeholder="Certificate name"
                                                                      label="Certificate Name"
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          this.handleOnChange(
                                                                              e,
                                                                              cerIndex
                                                                          )
                                                                      }
                                                                      required
                                                                      value={
                                                                          certificate.name
                                                                      }
                                                                      minLength={
                                                                          3
                                                                      }
                                                                      maxLength={
                                                                          50
                                                                      }
                                                                      pattern={
                                                                          '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                      }
                                                                  />
                                                              </div>
                                                          </div>

                                                          <div className="col-12 col-sm-6">
                                                              <div>
                                                                  <Input
                                                                      type="file"
                                                                      name="certificate_link"
                                                                      placeholder="Upload Certificate"
                                                                      label="Upload Certificate "
                                                                      className="form-control"
                                                                      outerClassName="signup-input-box"
                                                                      errorMessage="Please upload your Certificate"
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          this.handleCertificateUpload(
                                                                              e,
                                                                              cerIndex
                                                                          )
                                                                      }
                                                                  />
                                                              </div>
                                                              <div
                                                                  style={{
                                                                      color: '#f9a469',
                                                                  }}
                                                              >
                                                                  {
                                                                      /[^/]*$/.exec(
                                                                          certificate.certificate_link
                                                                      )[0]
                                                                  }
                                                              </div>
                                                          </div>
                                                      </div>
                                                  )
                                              )
                                            : ''}
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <div
                                                        className="add-more-link "
                                                        style={{
                                                            color: '#bf1f67',
                                                            fontSize: '15px',
                                                        }}
                                                        onClick={this.handleAddUserCertificate.bind(
                                                            this
                                                        )}
                                                    >
                                                        <i className="fa fa-plus"></i>
                                                        <span className="add-more-text">
                                                            Add More Certificate
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.updateErrorMsg && (
                                            <p style={{ color: 'red' }}>
                                                {this.props.updateErrorMsg}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {editOtherPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: editOtherPopupState,
                            fadeOut: !editOtherPopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        editOtherPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        userotherInfo,
    } = state.userReducer;
    return {
        editOtherPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        userotherInfo,
    };
}
export default withRouter(connect(mapStateToProps)(EditOtherPopup));
