export const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';
export const UPDATE_TASKS = 'UPDATE_TASKS';

export const POST_MODULE_REQUEST='POST_MODULE_REQUEST';
export const POST_MODULE_SUCCESS='POST_MODULE_SUCCESS';
export const POST_MODULE_FAILURE='POST_MODULE_FAILURE';

export const CLEAR_MODULE_DATA='CLEAR_MODULE_DATA';
export const CLEAR_MODULE_MESSAGE="CLEAR_MODULE_MESSAGE";

export const GET_MODULE_REQUEST='GET_MODULE_REQUEST';
export const GET_MODULE_SUCCESS='GET_MODULE_SUCCESS';
export const GET_MODULE_FAILURE='GET_MODULE_FAILURE';

export const NEW_PAGE_NUMBER_INTERN='NEW_PAGE_NUMBER_INTERN';

export const GET_MODULE_TASKS_REQUEST='GET_MODULE_TASKS_REQUEST';
export const GET_MODULE_TASKS_SUCCESS='GET_MODULE_TASKS_SUCCESS';
export const GET_MODULE_TASKS_FAILURE='GET_MODULE_TASKS_FAILURE';

export const GET_MODULE_DETAILS_REQUEST='GET_MODULE_DETAILS_REQUEST';
export const GET_MODULE_DETAILS_SUCCESS='GET_MODULE_DETAILS_SUCCESS';
export const GET_MODULE_DETAILS_FAILURE='GET_MODULE_DETAILS_FAILURE';

export const DELETE_MODULE_REQUEST='DELETE_MODULE_REQUEST';
export const DELETE_MODULE_SUCCESS='DELETE_MODULE_SUCCESS';
export const DELETE_MODULE_FAILURE='DELETE_MODULE_FAILURE';

export const UPDATE_MODULE_REQUEST='UPDATE_MODULE_REQUEST';
export const UPDATE_MODULE_SUCCESS='UPDATE_MODULE_SUCCESS';
export const UPDATE_MODULE_FAILURE='UPDATE_MODULE_FAILURE';

export const GET_USER_MODULE_LIST_REQUEST="GET_USER_MODULE_LIST_REQUEST";
export const GET_USER_MODULE_LIST_SUCCESS="GET_USER_MODULE_LIST_SUCCESS";
export const GET_USER_MODULE_LIST_FAILURE="GET_USER_MODULE_LIST_FAILURE";

export const HANDLE_MODULE_PDF_COMPLETE_DIALOG_STATE="HANDLE_MODULE_PDF_COMPLETE_DIALOG_STATE";

export const HANDLE_UPLOAD_PDF_DIALOG_CHANGE="HANDLE_UPLOAD_PDF_DIALOG_CHANGE";

export const UPLOAD_PDF_REQUEST="UPLOAD_PDF_REQUEST";
export const UPLOAD_PDF_SUCCESS="UPLOAD_PDF_SUCCESS";
export const UPLOAD_PDF_FAILURE="UPLOAD_PDF_FAILURE";

export const UPLOAD_PDF_LINK_REQUEST="UPLOAD_PDF_LINK_REQUEST";
export const UPLOAD_PDF_LINK_SUCCESS="UPLOAD_PDF_LINK_SUCCESS";
export const UPLOAD_PDF_LINK_FAILURE="UPLOAD_PDF_LINK_FAILURE";

export const GET_PDF_LINK_REQUEST="GET_PDF_LINK_REQUEST";
export const GET_PDF_LINK_SUCCESS="GET_PDF_LINK_SUCCESS";
export const GET_PDF_LINK_FAILURE="GET_PDF_LINK_FAILURE";

export const DELETE_PDF_REQUEST="DELETE_PDF_REQUEST";
export const DELETE_PDF_SUCCESS="DELETE_PDF_SUCCESS";
export const DELETE_PDF_FAILURE="DELETE_PDF_FAILURE";

export const CLEAR_PDF_UPLOAD_DATA="CLEAR_PDF_UPLOAD_DATA";

export const HANDLE_UPLOAD_PDF_INDEX_CHANGE="HANDLE_UPLOAD_PDF_INDEX_CHANGE";

export const UPDATE_TASKS_PDFS = "UPDATE_TASKS_PDFS"