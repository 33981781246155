import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getUserProfile,
    getDocumentLinks,
} from '../../../actions/admin/employeeActions';
import { getUserModuleList } from '../../../actions/admin/internModuleActions';
import './dist/table.css';
import AccountDetails from './employee-details/AccountDetails';
import PersonalDetails from './employee-details/PersonalDetails';
import EducationDetails from './employee-details/EducationDetails';
import OthersDetails from './employee-details/OtherDetails';
import ModulesListing from './employee-details/ModuleListing';
import ReviewerListing from './employee-details/ReviewerListing';
import FeedbackListing from './employee-details/FeedbackListing';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './dist/style.css';

class UserProfile extends Component {
    constructor(props) {
        super(props);
        let pageIndex = parseInt(localStorage.getItem('tabIndex')) || 0;
        this.state = {
            tabIndex: pageIndex,
        };
        localStorage.removeItem('tabIndex');
    }

    componentDidMount() {
        let email = this.props.match.params.email;
        this.props.dispatch(getUserProfile(email));
        this.props.dispatch(getUserModuleList(email));
    }

    handleGetDocumentLink(link) {
        this.props.dispatch(getDocumentLinks(link));
    }

    handleTabIndex(currentIndex) {
        localStorage.setItem('profileTabIndex', currentIndex);
        this.setState({
            tabIndex: currentIndex,
        });
    }

    render() {
        const userEmail = this.props.match.params.email.replace(' ', '');

        return (
            <main className="main-content-wrapper">
                <section className="dashboard-content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6">
                                <div className="dashboard-content-header-left">
                                    <h2 className="content-header-title">
                                        User Details
                                    </h2>
                                    <p className="content-header-description"></p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6">
                                <div className="dashboard-content-header-right"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="dashboard-body-wrapper">
                    <div className="container">
                        <div className="main-content-body">
                            <section className="content-section-wrapper">
                                <div className="react-tabs-section-wrapper">
                                    <Tabs
                                        selectedIndex={this.state.tabIndex}
                                        onSelect={(tabIndex) =>
                                            this.handleTabIndex(tabIndex)
                                        }
                                    >
                                        <TabList>
                                            <Tab>
                                                <div className="react-tabs-navigation-item-content">
                                                    Account Details
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="react-tabs-navigation-item-content">
                                                    Personal Details
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="react-tabs-navigation-item-content">
                                                    Education Details
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="react-tabs-navigation-item-content">
                                                    Other Details
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="react-tabs-navigation-item-content">
                                                    Module Details
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="react-tabs-navigation-item-content">
                                                    Reviewer Details
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="react-tabs-navigation-item-content">
                                                    Feedback
                                                </div>
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            <div className="react-tabs-content-item">
                                                <div className="admin-user-profile-section">
                                                    <div className="admin-user-profile-header">
                                                        <h2>Account Details</h2>
                                                    </div>
                                                    <div className="admin-user-profile-content">
                                                        <AccountDetails />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="react-tabs-content-item">
                                                <div className="admin-user-profile-section">
                                                    <div className="admin-user-profile-header">
                                                        <h2>
                                                            Personal Details
                                                        </h2>
                                                    </div>
                                                    <div className="admin-user-profile-content">
                                                        <PersonalDetails />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="react-tabs-content-item">
                                                <div className="admin-user-profile-section">
                                                    <div className="admin-user-profile-header">
                                                        <h2>
                                                            Education Details
                                                        </h2>
                                                    </div>
                                                    <div className="admin-user-profile-content">
                                                        <EducationDetails />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="react-tabs-content-item">
                                                <div className="admin-user-profile-section">
                                                    <div className="admin-user-profile-header">
                                                        <h2>Other Details</h2>
                                                    </div>
                                                    <div className="admin-user-profile-content">
                                                        <OthersDetails />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="react-tabs-content-item">
                                                <div className="admin-user-profile-section">
                                                    <div className="admin-user-profile-header">
                                                        <h2>Module Details</h2>
                                                    </div>
                                                    <div className="admin-user-profile-content">
                                                        <ModulesListing
                                                            email={userEmail}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="react-tabs-content-item">
                                                <div className="admin-user-profile-section">
                                                    <div className="admin-user-profile-header">
                                                        <h2>
                                                            Reviewer Details
                                                        </h2>
                                                    </div>
                                                    <div className="admin-user-profile-content">
                                                        <ReviewerListing
                                                            email={userEmail}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="react-tabs-content-item">
                                                <div className="admin-user-profile-section">
                                                    <div className="admin-user-profile-header">
                                                        <h2>Feedback</h2>
                                                    </div>
                                                    <div className="admin-user-profile-content">
                                                        <FeedbackListing
                                                            email={userEmail}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        isProfileLoading,
        userProfileData,
        userProfileError,
        profileSuccess,
        userProfileSuccess,
        documentLink,
    } = state.employeeReducer;
    const { userModuleList } = state.moduleReducer;
    return {
        isProfileLoading,
        userProfileData,
        userProfileError,
        profileSuccess,
        userProfileSuccess,
        documentLink,
        userModuleList,
    };
}

export default withRouter(connect(mapStateToProps)(UserProfile));
