import {
    GET_RECENTLY_JOINED_INTERNS_REQUEST,
    GET_RECENTLY_JOINED_INTERNS_SUCCESS,
    GET_RECENTLY_JOINED_INTERNS_FAILURE,
    GET_NOT_JOINED_INTERNS_REQUEST,
    GET_NOT_JOINED_INTERNS_SUCCESS,
    GET_NOT_JOINED_INTERNS_FAILURE,
    GET_INTERN_MODULE_PROGRESS_REQUEST,
    GET_INTERN_MODULE_PROGRESS_SUCCESS,
    GET_INTERN_MODULE_PROGRESS_FAILURE,
    GET_INTERN_MODULE_DEADLINE_REQUEST,
    GET_INTERN_MODULE_DEADLINE_SUCCESS,
    GET_INTERN_MODULE_DEADLINE_FAILURE,
} from '../../constants/admin/adminDashboardConstants';
import { GET_API } from '../../middleware/symbols';

import { accountApi, moduleApi } from '../ApiActions';
const AUTH_API = accountApi();
const MODULE_API = moduleApi();

export function recentlyJoinedInterns(body) {
    return {
        [GET_API]: {
            endpoint:
                AUTH_API +
                `v1/intern-list?limit=${body.limit}&&page=${body.page}&&status=${body.status}`,
            authenticated: true,
            types: [
                GET_RECENTLY_JOINED_INTERNS_REQUEST,
                GET_RECENTLY_JOINED_INTERNS_SUCCESS,
                GET_RECENTLY_JOINED_INTERNS_FAILURE,
            ],
            body: body,
        },
    };
}

export function notJoinedInterns(body) {
    return {
        [GET_API]: {
            endpoint:
                AUTH_API +
                `v1/intern-list?limit=${body.limit}&&page=${body.page}&&status=${body.status}`,
            authenticated: true,
            types: [
                GET_NOT_JOINED_INTERNS_REQUEST,
                GET_NOT_JOINED_INTERNS_SUCCESS,
                GET_NOT_JOINED_INTERNS_FAILURE,
            ],
            body: body,
        },
    };
}

export function internModuleProgress() {
    return {
        [GET_API]: {
            endpoint: MODULE_API + 'v1/listusers?type=scorecheck',
            authenticated: true,
            types: [
                GET_INTERN_MODULE_PROGRESS_REQUEST,
                GET_INTERN_MODULE_PROGRESS_SUCCESS,
                GET_INTERN_MODULE_PROGRESS_FAILURE,
            ],
        },
    };
}

export function internModuleDeadline() {
    return {
        [GET_API]: {
            endpoint: MODULE_API + 'v1/listusers?type=deadlinecheck',
            authenticated: true,
            types: [
                GET_INTERN_MODULE_DEADLINE_REQUEST,
                GET_INTERN_MODULE_DEADLINE_SUCCESS,
                GET_INTERN_MODULE_DEADLINE_FAILURE,
            ],
        },
    };
}
