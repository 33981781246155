import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import classNames from 'classnames';
import '../../employee/profile/dist/modal.css';
import { Button } from 'xd-react-button';
import { Checkbox } from 'xd-react-checkbox';
import {Input} from 'xd-react-input';
import { handleEdiFormDialogChange, setFormFieldData, updateFormFields, addNewOption, removeFromOptions} from '../../../actions/employee/feedbackActions';

class EditAccountPopup extends Component { 
    constructor(props){
        super(props)
        }
    
    
    handleDialogOnClose(){
        document.querySelector('#body-root').style="overflow:auto;"
        this.props.dispatch(handleEdiFormDialogChange(false))
    }
    
    handleOnChange(e){ 
        const name = e.target.name;
        const index= this.props.formFieldIndex
        const value= e.target.value
        let Data = Object.assign([],this.props.formFieldData)
        if(e.target.name=='isRequired'){
            Data[index][name] = e.target.checked;
        }else{
            Data[index][name] = value;
        }
        this.props.dispatch(setFormFieldData(Data, index));
        if (e.target.checkValidity()) {
          e.target.parentElement.classList.remove('has-error')
          e.target.parentElement.parentElement.classList.remove("has-error");
        }
        else {
          e.target.parentElement.classList.add('has-error')
          e.target.parentElement.parentElement.classList.add("has-error");
        }
    }
    handleDropdownChange(e, i){
        const name = e.target.name;
        const index= this.props.formFieldIndex
        const value= e.target.value
        let Data = Object.assign([],this.props.formFieldData)
        let details=Data[index]['options']
        details[i][name] = value;
        this.props.dispatch(setFormFieldData(Data, index));
        if (e.target.checkValidity()) {
          e.target.parentElement.classList.remove('has-error')
          e.target.parentElement.parentElement.classList.remove("has-error");
        }
        else {
          e.target.parentElement.classList.add('has-error')
          e.target.parentElement.parentElement.classList.add("has-error");
        }
    }
    handleAddOption(e){
        let data= {"label":"option", "value":"option"}
        this.props.dispatch(addNewOption(data, this.props.formFieldIndex))
    }
    handleRemoveOption(e, index){
        const fieldsList = this.props.formFieldData;
        fieldsList[this.props.formFieldIndex].options.splice(index, 1);
        this.props.dispatch(removeFromOptions(fieldsList))
    }

    handleSubmit(e){
        e.preventDefault();     
        const details=this.props.formFieldData     
        if(e.target.checkValidity()){   
            this.props.dispatch(updateFormFields(details))
            document.querySelector('#body-root').style="overflow:auto;"
            this.props.dispatch(handleEdiFormDialogChange(false))         
        }else{
            let allInvalidInput = document.querySelectorAll(
                ".form-group .form-control:invalid"
              );
              if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add("has-error");
                    invalidInput.parentElement.parentElement.classList.add(
                    "has-error"
                  );
                }
              }
        }
    }

    
    
    render() {
        const { editFormPopupState } = this.props;
        const formData=this.props.formFieldData[this.props.formFieldIndex]
        
        return (        
            <>          
            <div className={classNames("xd-modal animated ", {'show fadeIn': editFormPopupState, 'fadeOut' : !editFormPopupState})} id="add_member_dialog">
                
                <div className={classNames('edit-education-dialog  xd-modal-dialog  animated', {'fadeInDown': editFormPopupState, 'fadeOutUp' : !editFormPopupState})} >
                    <div className="xd-modal-content">
                        <div className="xd-modal-header">       
                            <h2 className="xd-modal-title">Text Input</h2>
                            <button type="button" className="xd-modal-close" onClick={this.handleDialogOnClose.bind(this)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={this.handleSubmit.bind(this)} noValidate={true} id='edit-education-dialog'>
                        <div className="xd-modal-body">
                            <div className="add-new-dag-form">    
                                    <div className="row">
                                         <div className="col-12">
                                            <div>
                                                <Input type="text" 
                                                    name="label"
                                                    className="form-control"
                                                    errorMessage="Enter label." 
                                                    label="Label"
                                                    onChange={this.handleOnChange.bind(this)}
                                                    required
                                                    value={formData?formData.label:""}
                                                    minLength={3}
                                                    maxLength={50}
                                                />                                        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 ">
                                            <div className="form-group">
                                                <Input type="text" 
                                                    name="placeholder"
                                                    className="form-control"
                                                    errorMessage="Enter placeholder."
                                                    label="Placeholder"
                                                    onChange={this.handleOnChange.bind(this)}
                                                    required
                                                    value={formData?formData.placeholder:""}
                                                    minLength={3}
                                                    maxLength={50}
                                                />                                        
                                            </div>
                                        </div> 
                                </div>
                                {formData ? ((formData.type=="select" || formData.type=="radio" || formData.type=="checkbox") &&
                                <>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <b>Options</b>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <b>Value</b>
                                    </div>
                                </div>
                                {formData.options.map((option, i)=>(
                                    <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group">
                                            <Input type="text" 
                                                name="label"
                                                className="form-control"
                                                errorMessage="Enter option."
                                                onChange={(e)=>this.handleDropdownChange(e, i)}
                                                required
                                                value={option.label}
                                                minLength={1}
                                                maxLength={50}
                                            />                                        
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="form-group">
                                            <Input type={formData.type=="select" || formData.type=="radio" ? "number":"text"} 
                                                name="value"
                                                className="form-control"
                                                errorMessage={formData.type=="select" || formData.type=="radio" ? "Enter value as a number":"Enter value"}
                                                onChange={(e)=>this.handleDropdownChange(e, i)}
                                                required
                                                value={option.value}
                                                minLength={1}
                                                maxLength={50}
                                            />                                        
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-2">
                                        <div className="form-group">
                                        {formData.options.length!==1 &&
                                            <button type="button" className="custom-icon-button add-icon-btn"
                                            onClick={(e)=>this.handleRemoveOption(e, i)}
                                            ><i className="fa fa-trash"></i>
                                            </button> }                                       
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                <div className="row">
                                <div className="col-12 col-sm-4">
                                <div className="form-group">
                                    <Button type="button" className="btn btn-compact btn-outline"
                                            onClick={(e)=>this.handleAddOption(e)}
                                            ><i className="fa fa-plus"></i> Add Option
                                    </Button>
                                    </div>
                                </div>
                                </div>
                                </>):("")}
                                
                                <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <Checkbox  
                                                name="isRequired" 
                                                checked={formData?formData.isRequired:false} 
                                                onChange={this.handleOnChange.bind(this)} 
                                                labelValue="Required" 
                                                id="checked-input" 
                                                themeColor="#f99316" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="xd-modal-footer">                            
                           <div className="xd-btn-group form-group">
                                <Button className="btn btn-compact box-shadow" onClick={this.handleDialogOnClose.bind(this)} >Cancel</Button>
                                <Button type="submit" className="btn btn-compact btn-with-loader box-shadow" >Update</Button>
                           </div>                           
                        </div>
                    </form>
                    </div>
                </div>               
            </div>
            {editFormPopupState &&
             <div className={classNames("xd-modal-overlay animated ", {'fadeIn': editFormPopupState, 'fadeOut' : !editFormPopupState})}></div>
            }
             </>
        )
    }    
}
function mapStateToProps(state) {  
    const {editFormPopupState, formFieldData,formFieldIndex} = state.feedbackReducer;
    return {editFormPopupState,formFieldData,formFieldIndex}
}
export default withRouter(connect(mapStateToProps)(EditAccountPopup))
