import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import '../assign-module/dist/style.css'
class AssignModuleStatus extends Component {
    constructor(props){
        super(props)
    }

    componentDidMount(){
        if(this.props.assignModuleSuccessList.length==0 && this.props.assignModuleErrorList.length==0){
            this.props.history.push('/admin/employee/l1')
        }
    }

    handleBackToListing() {
        this.props.history.push('/admin/employee/l1')
      }

    render(){
        const {assignModuleSuccessList, assignModuleErrorList}= this.props
        return(
            <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                <div className="invite-employee-form-outer content-section-card invite-team-response" id="invite-team-response">
                    <section className="dashboard-content-box" id="dashboard-content-box">
                        <div className="invite-member-message-box" id="invite-member-message-box">
                            {assignModuleSuccessList !== undefined &&
                            assignModuleSuccessList.length > 0 ? (
                            <div className="invite-team-header" >
                                <h3 className="invite-success">Modules successfully assigned!</h3>
                                <p className="invite-success">You've Successfully assigned modules to {assignModuleSuccessList.length} Member(s).
                                </p>
                            </div>
                                ) : ("")}
                                {assignModuleSuccessList !== undefined &&
                                assignModuleSuccessList.length > 0 && (
                                    <table className="invite-member-message-table error-table assign-module-status-table" id="error-table">
                                    <tbody>
                                        <tr>
                                            <th rowSpan="2">Email </th>
                                            <th colspan="2">Modules </th>
                                        </tr>
                                        <tr>
                                            <td class="table-row-subheading" data-label="Message">Name</td>
                                            <td class="table-row-subheading" data-label="Message">Message</td>
                                        </tr>
                                        {assignModuleSuccessList.map(
                                            (intern, index) => (
                                                <>
                                            <tr key={index}>
                                                <td rowSpan={intern.module.length+1} data-label="Email">{intern.email}</td>
                                                
                                            </tr>
                                            {intern.module.map((module, i)=>(
                                                <tr>
                                                <td data-label="Message">{module.name}</td>
                                                <td data-label="Message">{module.message}</td>
                                                </tr>
                                            ))}
                                            </>
                                            )
                                            )}
                                    </tbody>
                                </table>
                                )}
                                <hr className="m-2-rem light-grey-hr"></hr>
                                {assignModuleErrorList !== undefined &&
                                assignModuleErrorList.length > 0 ? (
                                <div className="invite-team-header">
                                    <h3 className="invite-error">Modules not assigned!</h3>
                                    <p className="invite-error">Some modules are already assigned please verify.
                                    </p>
                                </div>
                                ) : ("")}
                                {assignModuleErrorList !== undefined &&
                                assignModuleErrorList.length > 0 && (
                                <table className="invite-member-message-table error-table assign-module-status-table" id="error-table">
                                    <tbody>
                                        <tr>
                                            <th rowSpan="2">Email </th>
                                            <th colspan="2">Modules </th>
                                        </tr>
                                        <tr>
                                            <td class="table-row-subheading" data-label="Message">Name</td>
                                            <td class="table-row-subheading" data-label="Message">Message</td>
                                        </tr>
                                        {assignModuleErrorList.map(
                                            (intern, index) => (
                                                <>
                                            <tr key={index}>
                                                <td rowSpan={intern.module.length+1} data-label="Email">{intern.email}</td>
                                                
                                            </tr>
                                            {intern.module.map((module, i)=>(
                                                <tr>
                                                <td data-label="Message">{module.name}</td>
                                                <td data-label="Message">{module.message}</td>
                                                </tr>
                                            ))}
                                            </>
                                            )
                                            )}
                                    </tbody>
                                </table>
                                )}
                        </div>
                        <div className="invite-success-action">
                            <div className="row">
                                <div className="col-12 col-sm-5 col-md-6">
                                    <div className="form-btn-group">
                                        <Button className="btn box-shadow send-invite-btn"
                                        onClick={this.handleBackToListing.bind(this)}>Assign more Modules</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { internsList, employeeList, assignModuleSuccessList, assignModuleErrorList} = state.employeeReducer;
    return {internsList, employeeList, assignModuleSuccessList, assignModuleErrorList}
  }
  
  export default withRouter(connect(mapStateToProps)(AssignModuleStatus))