import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import createNotification from "../../../components/app/notification/createNotification";
import NoDataFound from '../../../components/app/no-data-found/NoDataFound';
import moment from "moment";
import {getDepartmentList, handleEditDeptDialogChange, deleteDepartment, clearDepartmentData, setDepartmentInfo} from '../../../actions/admin/departmentActions';
import { Dialogbox } from 'xd-react-dialogbox';
import {BlockLoader} from 'xd-react-loader';
import EditDepartment from './EditDepartment';
import userRoles from '../../../components/roles/roles';

class DepartmentList extends Component{
    constructor(props){
        super(props)
        this.state= {
            departmentId:''
         }
    }
    
    componentDidMount(){
        this.props.dispatch(getDepartmentList())
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.deleteDepartmentErrorMsg.length!==0){
            createNotification('error', nextProps.deleteDepartmentErrorMsg);
            nextProps.dispatch(clearDepartmentData())
        }
        if(nextProps.getDeptAfterDelete===true){
            createNotification('success', nextProps.deleteDepartmentSuccessMsg);
            nextProps.dispatch(getDepartmentList())
            nextProps.dispatch(clearDepartmentData())
        }
    }

    openDeleteDialog(e, id) {
		this.setState(state => {
			state.departmentId = id;
			return state;
		})
		document.querySelector('#deleteDialog').classList.add('opened');
		document.querySelector('body').classList.add('has-dialog');
    }
    submitDeleteDialog(e) {
		const data = {
			id:this.state.departmentId
		}
		this.props.dispatch(deleteDepartment(data))
		document.querySelector('#deleteDialog').classList.remove('opened');
		document.querySelector('body').classList.remove('has-dialog');
	}
	// closes the dialog
	closeDialogbox() {
		document.querySelector('#deleteDialog').classList.remove('opened');
		document.querySelector('body').classList.remove('has-dialog');
    }
    handleEditDepartment(dept){
        document.querySelector('#body-root').style="overflow:hidden;"
        this.props.dispatch(handleEditDeptDialogChange(true))
        let departmentData=Object.assign({},dept)
        this.props.dispatch(setDepartmentInfo(departmentData))
    }

render(){
    const {departmentList, departmentListErrorMsg, isDepartmentListLoading, departmentSuccessMsg}=this.props
    return(
    <main className="main-content-wrapper">
        <section className="dashboard-content-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                            <h2 className="content-header-title">Manage Departments</h2>
                            <p className="content-header-description">List of departments and options to manage them.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-right"> 
                        <div>
                      {this.props.roleId!== userRoles.Reviewer &&
                      <Button onClick={() => this.props.history.push("/admin/department/create")} className="btn btn-icon icon-left">
                        <i className="fa fa-plus btn-icon"></i>
                        <span className="btn-text">Create Department</span>
                      </Button>
                      }
                    </div>                       
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="dashboard-body-wrapper">
            <div className="container">                   
                <div className="main-content-body">                        
                    <section className="content-section-wrapper">
                    {isDepartmentListLoading?(
                    <div className="page-section-loader">
                        <BlockLoader
                            loadingText="Loading .."
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                    </div>
                    ):departmentListErrorMsg?(
                       <NoDataFound title={departmentListErrorMsg} />
                    ):departmentSuccessMsg?(
                        <div className="feedback-form-wrapper"  >
                        {departmentList.length!==0 ?
                        (
                        <ul className="custom-list-item-wrapper">
                            {departmentList.map((dept, index) =>(
                            <li className="custom-list-item">
                                <div className="custom-list-item-content">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                <span className="custom-list-item-label">Name</span>
                                                    <p className="custom-list-item-value">
                                                    <b> {dept.name}</b>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                <span className="custom-list-item-label">Short Name</span>
                                                    <p className="custom-list-item-value">
                                                        {dept.short_name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                <span className="custom-list-item-label">Created On</span>
                                                    <p className="custom-list-item-value">
                                                        {dept.timestamp?(moment(new Date(dept.timestamp * 1000)).format('dddd, LL')):('N/A')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                <span className="custom-list-item-label">Created By</span>
                                                    <p className="custom-list-item-value">
                                                        {dept.added_by?dept.added_by:'N/A'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-2" >
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                {this.props.roleId!=userRoles.Reviewer ?
                                                    <p className="custom-list-item-value">
                                                        <button type="button" className="custom-icon-button close-icon-btn feedback-form-action" 
                                                        onClick={e => this.openDeleteDialog(e, dept.id)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                        <button type="button"  className="custom-icon-button close-icon-btn feedback-form-action" 
                                                        onClick={()=>this.handleEditDepartment(dept)}>
                                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        </button>
                                                    </p>:
                                                    <p className="custom-list-item-value">
                                                        <button type="button" disabled className="custom-icon-button close-icon-btn feedback-form-action" 
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                        <button type="button" disabled className="custom-icon-button close-icon-btn feedback-form-action" 
                                                        >
                                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        </button>
                                                    </p>}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <Dialogbox
                                        title="Delete Department"
                                        successButton="Delete"
                                        cancelButton="Cancel"
                                        submitDialog={e => this.submitDeleteDialog(e)}
                                        closeDialog={this.closeDialogbox}
                                        dialogBoxID="deleteDialog" >
                                            <p>Are you sure you want to delete this department?</p>
										</Dialogbox>
                            </div>
                        </div>
                    </li>
                    ))}
            </ul>
            )
            :(
                <NoDataFound title="No data available" description="No departments has been created yet"/>
            )}
                        </div>):(
                            <NoDataFound/>
                        )}
                        <EditDepartment/>
                    </section>
                </div>
            </div>
        </div>
    </main>
        )
    }
}

function mapStateToProps(state) {
    const { roleId} = state.authReducer;
    const {departmentListErrorMsg, departmentList, departmentSuccessMsg, isDepartmentListLoading, deleteDepartmentErrorMsg, deleteDepartmentSuccessMsg, getDeptAfterDelete}=state.departmentReducer;
    return {roleId, departmentListErrorMsg, departmentList, departmentSuccessMsg, isDepartmentListLoading, deleteDepartmentErrorMsg, deleteDepartmentSuccessMsg, getDeptAfterDelete}
}

export default withRouter(connect(mapStateToProps)(DepartmentList))
