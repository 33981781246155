import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import "./dist/style.css"

class SignupHeader extends Component {
    render() {
        return (    
        <header className='header-section header-fixed full-page-header' id="header">               
                <nav id="signup-navigation-bar" className='signup-navigation-bar'>
                <div className="container">
                 <div className="header-navigation-outer">
                    <div className="brand-logo-wrapper">
                        <figure className="brand-logo-md">
                            <img src={require('../../static/images/normal-logo.svg')} className="brand-logo" alt="XenonStack OnBording Portal" />
                        </figure>
                        <figure className="brand-logo-xs">
                            <img src={require('../../static/images/xenonstack-logo-xs.png')} className="brand-logo" alt="XenonStack OnBording Portal" />
                        </figure>
                    </div>
                
                <div className="navigation-menu-bar">
                    <div className="info-navigation-menu nav-left">
                    </div>
                    <div className="info-navigation-menu nav-right">
                        <ul className="info-navbar-menu">
                            <li>
                                <div className="info-navbar-menu-item user-image-outer" >
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            </nav>            
        </header>      
        )
    }
}

function mapStateToProps(state) {
    const {toggleStatus} = state.appReducer;
    return {toggleStatus}
}

export default withRouter(connect(mapStateToProps)(SignupHeader))