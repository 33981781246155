import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import './dist/style.css';
import { handleDrawerToggleChange } from '../../../actions/index';
import userRoles from '../../roles/roles';

class Drawer extends Component {
	componentDidMount() {
		const slidedrop = document.querySelectorAll(".drawer-navmenu");
		for (const slideitem of slidedrop) {
			const items = slideitem.querySelectorAll(".drawer-item");
			for (const item of items) {
				const head = item.querySelector(".drawer-link");
				head.addEventListener('click', () => {
					for (const otherPanel of items) {
						if (otherPanel !== item) {
							otherPanel.classList.remove('open');
						}
					}
					item.classList.toggle('open');
				});
			}
		}

		
	}

	drawerNavigationLinks()
	{
		
		let mediaQuery = window.matchMedia('(max-width: 767px)');
        if (mediaQuery.matches) {
			this.props.dispatch(handleDrawerToggleChange(!this.props.toggleStatus));
        }

	}
	render() {
		return (
			<aside className={this.props.toggleStatus ? "drawer-wrapper" : "drawer-wrapper closed"}>
				<div className="navigation-menu">
					<nav className="drawer-navigation main-nav">
						<span className="navgroup-title">Navigation </span>
						<ul className="drawer-navmenu">

							<li className="drawer-item" data-tip data-for='dashboard' onClick={this.drawerNavigationLinks.bind(this)}>
								<NavLink exact to={'/admin/dashboard'} className="drawer-link">
								<i class="material-icons">dashboard</i>
									<span className="navmenu-item-text">Dashboard</span>
								</NavLink>
								<ReactTooltip id='dashboard' type="dark" effect='solid' place="right">
									<span>Dashboard</span>
								</ReactTooltip>
							</li>
							<li className="drawer-item" data-tip data-for='people' onClick={this.drawerNavigationLinks.bind(this)}>
								<NavLink to={"/admin/employee/l1"} className="drawer-link" >
								<i class="material-icons">people</i>
									<span className="navmenu-item-text">People</span>
								</NavLink>
								<ReactTooltip id='people' type="dark" effect='solid' place="right">
									<span>People</span>
								</ReactTooltip>
							</li>
							<li className="drawer-item" data-tip data-for='module' onClick={this.drawerNavigationLinks.bind(this)}>
								<NavLink to="/admin/employee/list-module/1" className="drawer-link" >
								<i class="material-icons">book</i>
									<span className="navmenu-item-text">Modules</span>
								</NavLink>
								<ReactTooltip id='module' type="dark" effect='solid' place="right">
									<span>Modules</span>
								</ReactTooltip>
							</li>
							{this.props.roleId === userRoles.Reviewer ?
							(<li className="drawer-item" data-tip data-for='feedback-forms' onClick={this.drawerNavigationLinks.bind(this)}>
								<NavLink to="#" className="drawer-link" >
								<i class="material-icons">feedback</i>
									
									<span className="navmenu-item-text">Feedback</span>
								</NavLink>
								<ReactTooltip id='feedback-forms' type="dark" effect='solid' place="right">
									<span >Feedback Forms</span>
								</ReactTooltip>
							</li>) : (
								<li className="drawer-item" data-tip data-for='feedback-forms' onClick={this.drawerNavigationLinks.bind(this)}>
								<NavLink to="/admin/feedback/list" className="drawer-link" >
								<i class="material-icons">feedback</i>
									
									<span className="navmenu-item-text">Feedback</span>
								</NavLink>
								<ReactTooltip id='feedback-forms' type="dark" effect='solid' place="right">
									<span >Feedback Forms</span>
								</ReactTooltip>
							</li>
							)}
							<li className="drawer-item" data-tip data-for='documents' onClick={this.drawerNavigationLinks.bind(this)}>
								<NavLink to="#" className="drawer-link" >
								<i class="material-icons">description</i>
									<span className="navmenu-item-text">Documents</span>
								</NavLink>
								<ReactTooltip id='documents' type="dark" effect='solid' place="right">
									<span>Documents</span>
								</ReactTooltip>
							</li>
							{this.props.roleId === userRoles.Reviewer ?
							(<li className="drawer-item" data-tip data-for='reports' onClick={this.drawerNavigationLinks.bind(this)}>
								<NavLink to="#" className="drawer-link" >
								<i class="material-icons">report</i>
									<span className="navmenu-item-text">Reports</span>
								</NavLink>
								<ReactTooltip id='reports' type="dark" effect='solid' place="right">
									<span>Reports</span>
								</ReactTooltip>
							</li>) : 
							 (<li className="drawer-item" data-tip data-for='reports' onClick={this.drawerNavigationLinks.bind(this)}>
								<NavLink to="/admin/reports/list" className="drawer-link" >
								<i class="material-icons">report</i>
									<span className="navmenu-item-text">Reports</span>
								</NavLink>
								<ReactTooltip id='reports' type="dark" effect='solid' place="right">
									<span>Reports</span>
								</ReactTooltip>
							</li>) }
						</ul>
					</nav>
					<nav className="drawer-navigation drawer-nav-footer">
						<ul className="drawer-navmenu">
							{this.props.roleId!==userRoles.Reviewer &&
								<li className="drawer-item" data-tip data-for='manage-admin'>
									<NavLink to="/admin/invite-member/list" className="drawer-link" >
									<i class="material-icons">person</i>
										<span className="navmenu-item-text">Manage Admin</span>
									</NavLink>
									<ReactTooltip id='manage-admin' type="dark" effect='solid' place="right">
										
										<span>Manage Admin</span>
									</ReactTooltip>
								</li>
							}
						</ul>
					</nav>
				</div>
			</aside>
		)
	}
}

function mapStateToProps(state) {
	const { toggleStatus } = state.appReducer;
	const {roleId} = state.authReducer;
	return { toggleStatus, roleId };
}
export default withRouter(connect(mapStateToProps)(Drawer));
