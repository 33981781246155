import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import '../../../container/employee/profile/dist/modal.css';
import './dist/table.css';
import { Tab, Tabs, TabList } from 'react-tabs';
import InternL1Listing from './InternL1Listing';
import InternL2Listing from './InternL2Listing';
import InternL3Listing from './InternL3Listing';
import classNames from 'classnames';
import { accountApi } from '../../../actions/ApiActions';
import Popup from '../../../components/employee/module/Popup';
import {
    getInternList,
    deleteIntern,
    isInternNotSelected,
} from '../../../actions/admin/employeeActions';
import createNotification from '../../../components/app/notification/createNotification';
import ReactTooltip from 'react-tooltip';
import userRoles from '../../../components/roles/roles';

const AUTH_API = accountApi();
const listingData = {
    level: 'L1',
    page: 1,
    limit: 10,
};
class EmployeeListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            isOpen: false,
            removeInternModal: false,
            isvalid: false,
            email: '',
            error: '',
        };
    }

    componentDidMount() {
        this.props.dispatch(isInternNotSelected())
        const url_string = window.location.href;
        const url = new URL(url_string);
        const level = url.href.substring(url.href.lastIndexOf('/') + 1);
        if (level === 'l1') {
            
            this.setState({
                tabIndex: 0,
            });
        } else if (level === 'l2') {
            this.setState({
                tabIndex: 1,
            });
        } else if (level === 'l3'){
            this.setState({
                tabIndex: 2,
            });
        } else {
            this.setState({
                tabIndex: 0,
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.deleteInternMessage !== '') {
            if (nextProps.deleteInternError) {
                createNotification('error', nextProps.deleteInternMessage);
            } else {
                createNotification('success', nextProps.deleteInternMessage);
                this.props.dispatch(getInternList(listingData));
            }
        }
    }

    togglePopup = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };
    handleEmail(e) {
        this.setState({
            email: e.target.value,
        });
        const isEmailValid = this.emailValidation();
        this.setState({
            isvalid: isEmailValid,
            error: isEmailValid ? '' : 'Email Address not valid!',
        });
    }
    emailValidation() {
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return !(!this.state.email || regex.test(this.state.email) === false);
    }
    onDelete() {
        this.props.dispatch(deleteIntern(this.state.email));
        this.togglePopup();
    }
    handleRedirectToInvite() {
        this.props.history.push('/admin/employee/invite');
    }

    handleExportAsCsv() {
        this.props.history.push(
            `/api/auth/export?token=${localStorage.getItem('token')}`
        );
    }
    handleTabIndex(currentIndex) {
        localStorage.setItem('internlevelTabIndex', currentIndex);
        this.setState({
            tabIndex: currentIndex,
        });
    }
    tabChange(level) {
        if (level === 'L1') {
            this.props.history.push('/admin/employee/l1');
        }
        if (level === 'L2') {
            this.props.history.push('/admin/employee/l2');
        }
        if (level === 'L3') {
            this.props.history.push('/admin/employee/l3');
        }
    }

    render() {
        return (
            <React.Fragment>
                <main className="main-content-wrapper">
                    <section className="dashboard-content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-left">
                                        <h2 className="content-header-title">
                                            List Of Interns
                                        </h2>
                                        <p className="content-header-description"></p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-right dashboard-header-action-group"></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="dashboard-body-wrapper">
                        <div className="container">
                            <div>
                                <section className="content-section-wrapper">
                                    <div className="table-container ">
                                        {' '}
                                        {/*change css properties from here*/}
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-9 col-lg-9">
                                                <div
                                                    className="listing-page-btn-grp xd-btn-group"
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    {this.props.roleId !==
                                                        userRoles.Reviewer && (
                                                            <Button
                                                                className="btn btn-compact btn-outline"
                                                                onClick={this.handleRedirectToInvite.bind(
                                                                    this
                                                                )}
                                                            >
                                                                Invite
                                                            </Button>
                                                        )}
                                                    {(this.props.roleId ===
                                                        userRoles.Admin ||
                                                        this.props.roleId ===
                                                        userRoles.Reviewer) && (
                                                            <a
                                                                href={`${AUTH_API}/export?token=${localStorage.getItem(
                                                                    'token'
                                                                )}`}
                                                                className="btn btn-compact btn-outline"
                                                                style={{
                                                                    fontWeight:
                                                                        'normal',
                                                                }}
                                                            >
                                                                Export
                                                            </a>
                                                        )}

                                                    {(this.props.roleId ===
                                                        userRoles.Admin ||
                                                        this.props.roleId ===
                                                        userRoles.Reviewer)  && (
                                                            <>
                                                                <span className="assign-button" data-tip data-for="registerTip" data-tip-disable={false}>
                                                                    <Button disabled={
                                                                        this.props.internSelected !== true
                                                                    }

                                                                        className="btn btn-compact btn-outline"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            this.props.history.push(
                                                                                '/admin/employee/view-assignee'
                                                                            )
                                                                        }
                                                                    >
                                                                        Assign Module
                                                                    </Button>
                                                                </span>
                                                                <ReactTooltip id="registerTip" place="top" effect="solid">
                                                                    Make sure you selected intern
                                                                </ReactTooltip>
                                                            </>
                                                        )}
                                                    {this.props.roleId ===
                                                        userRoles.Admin && 
                                                            <Button
                                                                className="btn btn-compact btn-outline"
                                                                type="button"
                                                                onClick={() =>
                                                                    this.props.history.push(
                                                                        '/admin/employee/assign-reviewer'
                                                                    )
                                                                }
                                                            >
                                                                Assign Reviewer
                                                            </Button>
                                                        }
                                                  {(this.props.roleId !=
                                                        userRoles.Reviewer) && (
                                                            <Button
                                                        className="btn btn-compact btn-outline"
                                                        type="button"
                                                        onClick={
                                                            this.togglePopup
                                                        }
                                                    >
                                                        Remove Intern
                                                    </Button>
                                                        )}  
                                                    <div
                                                        className={classNames(
                                                            'xd-modal animated ',
                                                            {
                                                                'show fadeIn':
                                                                    this.state
                                                                        .isOpen,
                                                                fadeOut:
                                                                    !this.state
                                                                        .isOpen,
                                                            }
                                                        )}
                                                    >
                                                        <div
                                                            className={classNames(
                                                                'xd-modal-dialog animated',
                                                                {
                                                                    fadeInDown:
                                                                        this
                                                                            .state
                                                                            .isOpen,
                                                                    fadeOutUp:
                                                                        !this
                                                                            .state
                                                                            .isOpen,
                                                                },'remove-intern-modal-alignment'
                                                            )}
                                                        >
                                                            {this.state
                                                                .isOpen && (
                                                                    <div className="deleteIntern-wrapper">
                                                                        <Popup
                                                                            classname={'remove-intern-modal-alignment'}
                                                                            content={
                                                                                <div className="deleteInternPopBox">
                                                                                    <h2>
                                                                                        Remove
                                                                                        User
                                                                                    </h2>
                                                                                    <label>
                                                                                        <div className="span-name">
                                                                                            <span class="req">
                                                                                                *
                                                                                            </span>
                                                                                            Email:
                                                                                        </div>
                                                                                        <input
                                                                                            type="email"
                                                                                            defaultValue={
                                                                                                this
                                                                                                    .state
                                                                                                    .email
                                                                                            }
                                                                                            pattern={
                                                                                                '([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}'
                                                                                            }
                                                                                            required={
                                                                                                true
                                                                                            }
                                                                                            placeholder="Email Address"
                                                                                            onChange={this.handleEmail.bind(
                                                                                                this
                                                                                            )}
                                                                                        />
                                                                                        <p className="email-error">
                                                                                            {this.state.email.length > 0 &&
                                                                                                this
                                                                                                    .state
                                                                                                    .error
                                                                                            }
                                                                                        </p>
                                                                                    </label>
                                                                                    {!this
                                                                                        .state
                                                                                        .error && this.state.email.length > 0 ? (
                                                                                        <button
                                                                                            className="delete-button"
                                                                                            onClick={this.onDelete.bind(
                                                                                                this
                                                                                            )}
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    ) : (

                                                                                        <button
                                                                                            className="delete-button"
                                                                                            disabled
                                                                                            onClick={this.onDelete.bind(
                                                                                                this
                                                                                            )}
                                                                                        >
                                                                                            Delete
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            handleClose={
                                                                                this
                                                                                    .togglePopup
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="react-tabs-section-wrapper">
                                            <Tabs
                                                selectedIndex={
                                                    this.state.tabIndex
                                                }
                                                onSelect={(tabIndex) =>
                                                    this.handleTabIndex(
                                                        tabIndex
                                                    )
                                                }
                                            >
                                                <TabList>
                                                    <Tab
                                                        onClick={() =>
                                                            this.tabChange('L1')
                                                        }
                                                    >
                                                        <div className="react-tabs-navigation-item-content">
                                                            <div>
                                                                L1 Interns
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    <Tab
                                                        onClick={() =>
                                                            this.tabChange('L2')
                                                        }
                                                    >
                                                        <div className="react-tabs-navigation-item-content">
                                                            <div>
                                                                L2 Interns
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                    <Tab
                                                        onClick={() =>
                                                            this.tabChange('L3')
                                                        }
                                                    >
                                                        <div className="react-tabs-navigation-item-content">
                                                            <div>
                                                                L3 Interns
                                                            </div>
                                                        </div>
                                                    </Tab>
                                                </TabList>
                                            </Tabs>
                                        </div>
                                        <div className="table-header topnav"></div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    <Switch>
                        <Route
                            exact
                            path="/admin/employee/l1"
                            component={InternL1Listing}
                        />

                        <Route
                            exact
                            path="/admin/employee/l2"
                            component={InternL2Listing}
                        />
                        <Route
                            exact
                            path="/admin/employee/l3"
                            component={InternL3Listing}
                        />
                    </Switch>
                </main>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {
        internsList,
        isPageLoading,
        internListError,
        internListSuccess,
        csvList,
        deleteInternMessage,
        deleteInternError,
        internSelected
    } = state.employeeReducer;
    const {roleId} = state.authReducer;
    return {
        internsList,
        isPageLoading,
        internListError,
        internListSuccess,
        csvList,
        deleteInternMessage,
        deleteInternError,
        internSelected,
        roleId
    };
}

export default withRouter(connect(mapStateToProps)(EmployeeListing));
