import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import createNotification from "../../../../components/app/notification/createNotification";
import { addFormFeedback, submitFeedbackForm, getModuleFeedbackForm, clearSubmitFeedbackData} from '../../../../actions/employee/feedbackActions';
import { Checkbox } from 'xd-react-checkbox';
import {RadioGroup} from 'xd-react-radio';
import NoDataFound from '../../../../components/app/no-data-found/NoDataFound';
import {BlockLoader} from 'xd-react-loader';


class FeedbackFormDetails extends Component{
    constructor(props){
        super(props)
        this.state= { 
            radioError:''
        }
        this.IsJsonString=this.IsJsonString.bind(this)
    }
    
    componentDidMount(){
        const details={
            formid:this.props.match.params.formid
        }
        this.props.dispatch(getModuleFeedbackForm(details))
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.submitFeedbackFormSuccessMsg.length!==0){
            createNotification('success', nextProps.submitFeedbackFormSuccessMsg);
            nextProps.history.push('/admin/employee/l1')
            nextProps.dispatch(clearSubmitFeedbackData())
          }
        if(nextProps.submitFeedbackFormErrorMsg.length!==0){
            createNotification('error', nextProps.submitFeedbackFormErrorMsg);
            nextProps.dispatch(clearSubmitFeedbackData())
        }
    }
      
    handleChange(e, index, checkboxLabel){
        let value = e.target.value;
        const name = e.target.name;
        const type= e.target.type
        let data = Object.assign([],JSON.parse(this.props.moduleFeedbackForm.outline))
        if(name==="check-box-input"){
            if(!data[index]['value']){
                data[index]['value']=[]
            }
            if(!data[index]['key']){
                data[index]['key']=[]
            }
            if(e.target.checked){
                data[index]['value']=data[index]['value'].concat(e.target.value)
                data[index]['key']=data[index]['key'].concat(checkboxLabel)
            }else{
                var delIndex=data[index]['value'].indexOf(e.target.value)
                data[index]['value'].splice(delIndex, 1)
                data[index]['key'].splice(delIndex, 1)
            }
        }else{
            if(name==='select-option' ){
                data[index]['value'] = value
                data[index]['key'] = e.target.options[e.target.selectedIndex].text;
            }
            if(type==='radio' ){
                data[index]['value'] = value
                for(let i=0;i<data[index].options.length; i++){
                    if(data[index].options[i].value==(value)){
                        data[index]['key']= data[index].options[i].label
                        break
                    }
                }
            }
            else{data[index]['value'] = value;}
        }
        this.props.dispatch(addFormFeedback(data))
          if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove("has-error");
            e.target.parentElement.parentElement.classList.remove("has-error");
          } else {
            e.target.parentElement.classList.add("has-error");
            e.target.parentElement.parentElement.classList.add("has-error");
          }
    }
    
    handleFeedbackSubmit(e){
        e.preventDefault();
        const module_id= this.props.location.aboutProps
        const inputs=JSON.parse(this.props.moduleFeedbackForm.outline)
        var score=0;
        var totalMaxScore=0, maxScore=0
        for(let i=0;i<inputs.length;i++){
            if(inputs[i].name==='select-option' || inputs[i].name==='radio-input'){
                let value= parseInt(inputs[i].value)
                if(value){
                    score+=parseInt(value)
                }
            }
        }
        for(let i=0;i<inputs.length;i++){
            if(inputs[i].name==='select-option' || inputs[i].name==='radio-input'){
                if(inputs[i].value){
                let options= inputs[i].options
                maxScore= Math.max.apply(Math, options.map(function(o) { return( o.value) }))
                if(maxScore){
                    totalMaxScore+=maxScore
                }
            }
            }
        }
        const details={
            "moduleid":parseInt(module_id),
            "title":this.props.moduleFeedbackForm.title,
            "description":this.props.moduleFeedbackForm.description,
            "outline":this.props.moduleFeedbackForm.outline,
            "scores":score,
            "total":totalMaxScore
        }   
        let validity=true
            for(let i=0;i<inputs.length;i++){
                if(inputs[i].name==='radio-input' ){
                    if(inputs[i].isRequired===true){
                        if(!inputs[i].value){
                            validity=false
                            break
                        }
                    }
                }
            }
            for(let i=0;i<inputs.length;i++){
                if(inputs[i].name==='check-box-input'){
                    if(inputs[i].isRequired===true){
                        if(!inputs[i].value ){
                            validity=false
                            break
                        }else{
                            if(inputs[i].value ){
                                if(inputs[i].value.length==0 ){
                                    validity=false
                                    break
                                }}else{validity=true}
                            }
                    }
                }
            }       
        if(e.target.checkValidity() && validity===true){ 
                this.setState({radioError:''})
                this.props.dispatch(submitFeedbackForm(details))   
        }else{
            var setError=false
            for(let i=0;i<inputs.length;i++){
                if(inputs[i].name==='radio-input'){
                    if(inputs[i].isRequired===true){
                        if(!inputs[i].value){
                            setError=true
                            break
                        }else{setError=false}
                    }
                }
            }
            for(let i=0;i<inputs.length;i++){
                if(inputs[i].name==='check-box-input'){
                    if(inputs[i].isRequired===true){
                        if(!inputs[i].value){
                            setError=true
                            break
                        }
                        else{
                            if(inputs[i].value ){
                                if(inputs[i].value.length==0 ){
                                    setError=true
                                    break
                                }}else{setError=false}
                            }
                    }
                }
            }
            if(setError===true){
                this.setState({radioError:'This is a required field'})
            }else{
                this.setState({radioError:''})}
            let allInvalidInput = document.querySelectorAll(
                ".form-group .form-control:invalid"
              );
              if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let i = 0; i < allInvalidInput.length; i++) {
                  allInvalidInput[i].parentElement.classList.add("has-error");
                  allInvalidInput[i].parentElement.parentElement.classList.add(
                    "has-error"
                  );
                }
              }
        }
    }

    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

render(){
    const {moduleFeedbackForm, moduleFeedbackFormErrorMsg, moduleFeedbackFormSuccessMsg, moduleFeedbackFormLoading}=this.props
        
    return(
    <main className="main-content-wrapper">
        <section className="dashboard-content-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                            <h2 className="content-header-title">Submit Feedback Form</h2>
                            <p className="content-header-description">Fill and submit the feedback form.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <div className="dashboard-body-wrapper">
            <div className="container"> 
            <form onSubmit={this.handleFeedbackSubmit.bind(this)} noValidate={true} >              
                <div className="main-content-body">                        
                    <section className="content-section-wrapper">
                    {moduleFeedbackFormLoading?(
                    <div className="page-section-loader">
                        <BlockLoader
                            loadingText="Loading Form Details .."
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                    </div>
                    ):moduleFeedbackFormErrorMsg?(
                       <NoDataFound title={moduleFeedbackFormErrorMsg} />
                    ):moduleFeedbackFormSuccessMsg?(
                        <div className="feedback-form-wrapper"  >
                        {moduleFeedbackForm.length!==0 ?
                        (<>
                        <div className="details-container">
                        <div className="user-profile-container">                                        
                            <div className="profile-section_card">
                            <h2 className="profile-section_card_title"> Please fill the form</h2>
                            
                                                <div className="row">
                                                    <div className="col-12 col-sm-9">
                                                    
                                                        <div className="profile-fields_data">
                                                        <div className="feedack-form-card card-box-shadow">
                                                        <fieldset className="feedack-form-card-content">
                                                        <legend>{moduleFeedbackForm.title}</legend>
                                                        {this.IsJsonString(moduleFeedbackForm.outline) ? JSON.parse(moduleFeedbackForm.outline).map((formField, index) => (
                                                    <>
                                                    {formField.type=="text" &&
                                                    <div className="form-field-group-item">
                                                    <div class="form-group">
                                                    <label>{formField.label}</label>
                                                    {formField.isRequired && <span class="required">*</span>}
                                                        <div class="input-wrapper">
                                                            <input 
                                                                id={formField.id}
                                                                className="form-control" 
                                                                type={formField.type}
                                                                name={formField.name}
                                                                autocomplete="off" 
                                                                minlength="3" 
                                                                maxlength="50" 
                                                                required={formField.isRequired} 
                                                                placeholder={formField.placeholder} 
                                                                onChange={(e)=>this.handleChange(e, index)}
                                                                />
                                                                <div className="help-block with-errors">
                                                                This is a required field(min 3 char.)
                                                                </div>
                                                          </div>
                                                    </div>
                                                </div>}
                                                {formField.type=="textarea" &&
                                                    <div className="form-field-group-item">
                                                    <div class="form-group">
                                                    <label>{formField.label}</label>
                                                    {formField.isRequired && <span class="required">*</span>}
                                                        <div class="input-wrapper">
                                                            <textarea 
                                                                id={formField.id}
                                                                className="form-control" 
                                                                type={formField.type}
                                                                name={formField.name}
                                                                autocomplete="off" 
                                                                minlength="3" 
                                                                maxlength="300" 
                                                                required={formField.isRequired} 
                                                                placeholder={formField.placeholder} 
                                                                onChange={(e)=>this.handleChange(e, index)}
                                                                />
                                                                <div className="help-block with-errors">
                                                                This is a required field(min 3 char.)
															</div>
                                                        </div>
                                                    </div>
                                                </div>}
                                                {formField.type=="select" &&
                                                <div className="form-field-group-item">
                                                    <div class="form-group">
                                                        <label>{formField.label}</label>
                                                        {formField.isRequired && <span class="required">*</span>}
                                                        <div className="select-wrapper">
                                                            <select
                                                            id={formField.id}
                                                            required={formField.isRequired} 
                                                            className="form-control" 
                                                            name={formField.name}
                                                            placeholder={formField.placeholder} 
                                                            onChange={(e)=>this.handleChange(e, index)}
                                                            >
                                                                <option selected disabled={true} value="" defaultValue>
                                                                    Select any option
                                                                </option>
                                                                {formField.options.map((option, i)=>(
                                                                    <option value={option.value} key={i}>{option.label}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="help-block with-errors">
                                                            This is a required field
                                                        </div>
                                                    </div>
                                                </div>}
                                            {formField.type=="radio" &&
                                                <div className="form-field-group-item">
                                                    <div class="form-group">
                                                        <label>{formField.label}</label>
                                                        {formField.isRequired && <span class="required">*</span>}
                                                        <div class="input-wrapper">
                                                            <RadioGroup
                                                            position="vertical"
                                                            className="form-control" 
                                                            name={formField.name + index} 
                                                            required={formField.isRequired} 
                                                            errorMessage='This is a required field.'
                                                            onChange={(e)=>this.handleChange(e, index)}
                                                            value={JSON.parse(moduleFeedbackForm.outline)[index].value} 
                                                            options={formField.options.map((option, i)=>(
                                                                { label: `${option.label}`, value: `${option.value}`}
                                                            ))}
                                                            />
                                                        {formField.isRequired===true && (!formField.value) &&
                                                        <p className="selecterror">
                                                            {this.state.radioError}
                                                        </p>}
                                                      </div>
                                                </div>
                                            </div>}
                                            {formField.type=="checkbox" &&
                                                <div className="form-field-group-item">
                                                    <div className="form-group">
                                                        <label>{formField.label}</label>
                                                        {formField.isRequired && <span class="required">*</span>}
                                                        {formField.options.map((option, i)=>(
                                                                <div className="form-group" id={formField.id}>
                                                                    <Checkbox
                                                                    type={formField.type}
                                                                    name={formField.name}
                                                                    labelValue={option.label}
                                                                    value={option.value}
                                                                    onChange={(e)=>this.handleChange(e, index, option.label)}
                                                                    themeColor="#f99316" />
                                                            </div>
                                                        ))}
                                                        {formField.isRequired===true && 
                                                        !formField.value ?(
                                                        <p className="selecterror">
                                                            {this.state.radioError}
                                                        </p>):(formField.value? (formField.value.length==0?<p className="selecterror">
                                                            {this.state.radioError}
                                                        </p>:''):(''))
                                                        }
                                                    </div>
                                                </div>}
                                                </>
                                                )):(<NoDataFound title="No form available"/>)}</fieldset></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                </div>
                            </div>                                               
                        </div>
                    </>  
            )
            :(
                <NoDataFound title="No data available"/>
            )}
                        </div>):(
                            <NoDataFound/>
                        )}
                    </section>
                </div>
                <div className="xd-btn-group" style={{marginTop:"20px"}}>
                  <Button onClick={() => this.props.history.push("/admin/employee/l1")} >Back</Button>
                  <Button type="submit" 
                  >Submit {this.props.submitFeedbackFormLoading && <i className="fa fa-spin fa-spinner btn-icon"></i>}
                  </Button>
                </div>
                </form>
            </div>
        </div>
    </main>
        )
    }
}

function mapStateToProps(state) {
    const {moduleFeedbackFormErrorMsg, moduleFeedbackFormSuccessMsg, moduleFeedbackFormLoading, moduleFeedbackForm, submitFeedbackFormLoading, submitFeedbackFormErrorMsg, submitFeedbackFormSuccessMsg }=state.feedbackReducer;
    return {  moduleFeedbackFormErrorMsg, moduleFeedbackFormSuccessMsg, moduleFeedbackFormLoading, moduleFeedbackForm, submitFeedbackFormLoading, submitFeedbackFormErrorMsg, submitFeedbackFormSuccessMsg}
}

export default withRouter(connect(mapStateToProps)(FeedbackFormDetails))
