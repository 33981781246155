import {
    UPDATE_FORM_STATE,
    UPDATE_TASKS,
    POST_MODULE_REQUEST,
    POST_MODULE_SUCCESS,
    POST_MODULE_FAILURE,
    CLEAR_MODULE_DATA,
    GET_MODULE_REQUEST,
    GET_MODULE_SUCCESS,
    GET_MODULE_FAILURE,
    NEW_PAGE_NUMBER_INTERN,
    GET_MODULE_TASKS_REQUEST,
    GET_MODULE_TASKS_SUCCESS,
    GET_MODULE_TASKS_FAILURE,
    GET_MODULE_DETAILS_REQUEST,
    GET_MODULE_DETAILS_SUCCESS,
    GET_MODULE_DETAILS_FAILURE,
    DELETE_MODULE_REQUEST,
    DELETE_MODULE_SUCCESS,
    DELETE_MODULE_FAILURE,
    CLEAR_MODULE_MESSAGE,
    UPDATE_MODULE_REQUEST,
    UPDATE_MODULE_SUCCESS,
    UPDATE_MODULE_FAILURE,
    GET_USER_MODULE_LIST_REQUEST,
    GET_USER_MODULE_LIST_SUCCESS,
    GET_USER_MODULE_LIST_FAILURE,
    HANDLE_UPLOAD_PDF_DIALOG_CHANGE,
    CLEAR_PDF_UPLOAD_DATA,
    UPLOAD_PDF_LINK_REQUEST,
    UPLOAD_PDF_LINK_SUCCESS,
    UPLOAD_PDF_LINK_FAILURE,
    UPLOAD_PDF_REQUEST,
    UPLOAD_PDF_SUCCESS,
    UPLOAD_PDF_FAILURE,
    DELETE_PDF_REQUEST,
    DELETE_PDF_SUCCESS,
    DELETE_PDF_FAILURE,
    GET_PDF_LINK_REQUEST,
    GET_PDF_LINK_SUCCESS,
    GET_PDF_LINK_FAILURE,
    HANDLE_UPLOAD_PDF_INDEX_CHANGE,
    UPDATE_TASKS_PDFS,
} from '../../constants/admin/internModuleConstants';

import {
    GET_API,
    DELETE_API,
    POST_API,
    PUT_API,
} from '../../middleware/symbols';
import { moduleApi, getDocumentApi } from '../ApiActions';
const MODULE_API = moduleApi();
const DOCUMENT_API = getDocumentApi();
export function handleUploadPDFDialogChange(status, index) {
    if (typeof index !== 'undefined') {
        return { type: HANDLE_UPLOAD_PDF_INDEX_CHANGE, status, index };
    } else {
        return { type: HANDLE_UPLOAD_PDF_DIALOG_CHANGE, status };
    }
}
export function clearPDFUploadData() {
    return { type: CLEAR_PDF_UPLOAD_DATA };
}
export function uploadPDFLink(body) {
    return {
        [PUT_API]: {
            endpoint: MODULE_API + `/v1/presentation`,
            authenticated: true,
            types: [
                UPLOAD_PDF_LINK_REQUEST,
                UPLOAD_PDF_LINK_SUCCESS,
                UPLOAD_PDF_LINK_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}
export function deleteUploadedPDF(file) {
    return {
        [DELETE_API]: {
            endpoint: DOCUMENT_API + '/delete/' + file.filename,
            authenticated: true,
            types: [DELETE_PDF_REQUEST, DELETE_PDF_SUCCESS, DELETE_PDF_FAILURE],
            index: file.filename,
        },
    };
}
export function uploadPDFRequest() {
    return { type: UPLOAD_PDF_REQUEST };
}
export function uploadPDFSuccess(response) {
    return { type: UPLOAD_PDF_SUCCESS, response };
}
export function uploadPDFFailure(response) {
    return { type: UPLOAD_PDF_FAILURE, response };
}
export function uploadPDF(files) {
    let formData = new FormData();
    for (var file of files) {
        formData.append('files', file);
    }
    formData.set('type', 'modules');
    let status = '';
    const config = {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        body: formData,
    };
    return (dispatch) => {
        dispatch(uploadPDFRequest());
        fetch(DOCUMENT_API + '/user/upload', config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            uploadPDFSuccess({
                                data: {
                                    error: false,
                                    message: res.message,
                                    previewLinks: res.preview_links,
                                    uploadLink: res.uploadlink,
                                },
                                status: 200,
                            })
                        );
                        const pdfdata = {
                            link: res.uploadlink[0],
                        };

                        dispatch(uploadPDFLink(pdfdata));
                        dispatch(
                            updateTaskPDF({
                                attachments: res.uploadlink,
                                links: res.preview_links,
                            })
                        );
                    } else {
                        dispatch(
                            uploadPDFFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        uploadPDFFailure({
                            data: {
                                message: 'Error while uploading presentation',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
export function getPDFLink(link) {
    const token = localStorage.getItem('token');
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return (dispatch) => {
        dispatch(getPDFLinkRequest());
        fetch(DOCUMENT_API + `/getfile/${link}`, config)
            .then(function (response) {
                return response.json();
            })
            .then(
                function (res) {
                    dispatch(getPDFLinkSuccess(res));
                    window.open(res.link, '_blank');
                },
                function (error) {
                    dispatch(getPDFLinkFailed(error));
                }
            );
    };
}
export function getPDFLinkRequest() {
    return {
        type: GET_PDF_LINK_REQUEST,
    };
}

export function getPDFLinkSuccess(response) {
    return {
        type: GET_PDF_LINK_SUCCESS,
        response,
    };
}
export function getPDFLinkFailed(response) {
    return {
        type: GET_PDF_LINK_FAILURE,
        response,
    };
}
export function updateTaskFields(newState) {
    return { type: UPDATE_TASKS, newState };
}
export function updateTaskPDF(newState) {
    return { type: UPDATE_TASKS_PDFS, newState };
}
export function updateFormFields(newState) {
    return { type: UPDATE_FORM_STATE, newState };
}

export function handleAddModule(body) {
    return {
        [POST_API]: {
            endpoint: MODULE_API + 'v1/addmodule',
            authenticated: true,
            types: [
                POST_MODULE_REQUEST,
                POST_MODULE_SUCCESS,
                POST_MODULE_FAILURE,
            ],
            body,
        },
    };
}

export function getModuleList(data,search) {
    let endpoint = MODULE_API + '/v1/listmodules' + `?limit=10`;
    if (data.page !== undefined) {
        endpoint += '&page=' + data.page;
    }
    if (data.level !== undefined) {
        endpoint += '&level=' + data.level;
    }
    if (search !== undefined) {
        endpoint += '&search=' + search;
    }
    return {
        [GET_API]: {
            endpoint: endpoint,
            authenticated: true,
            types: [GET_MODULE_REQUEST, GET_MODULE_SUCCESS, GET_MODULE_FAILURE],
        },
    };
}

export function getModuleDetails(moduleID) {
    return {
        [GET_API]: {
            endpoint: MODULE_API + '/v1/module/' + moduleID,
            authenticated: true,
            types: [
                GET_MODULE_DETAILS_REQUEST,
                GET_MODULE_DETAILS_SUCCESS,
                GET_MODULE_DETAILS_FAILURE,
            ],
        },
    };
}

export function getModuleTaskList(moduleId) {
    let endpoint = MODULE_API + '/v1/tasks/' + moduleId;

    return {
        [GET_API]: {
            endpoint: endpoint,
            authenticated: true,
            types: [
                GET_MODULE_TASKS_REQUEST,
                GET_MODULE_TASKS_SUCCESS,
                GET_MODULE_TASKS_FAILURE,
            ],
        },
    };
}

export function getModuleTaskListDetails(moduleId) {
    let endpoint = MODULE_API + '/v1/listtasks/' + moduleId;

    return {
        [GET_API]: {
            endpoint: endpoint,
            authenticated: true,
            types: [
                GET_MODULE_TASKS_REQUEST,
                GET_MODULE_TASKS_SUCCESS,
                GET_MODULE_TASKS_FAILURE,
            ],
        },
    };
}

export function clearModuleData() {
    return { type: CLEAR_MODULE_DATA };
}
export function clearModuleMsg() {
    return { type: CLEAR_MODULE_MESSAGE };
}

//calls action to change page no.
export function changePageNumber(newPageNumber) {
    return {
        type: NEW_PAGE_NUMBER_INTERN,
        newPageNumber,
    };
}

export function deleteModule(moduleID) {
    return {
        [DELETE_API]: {
            endpoint: MODULE_API + 'v1/module/' + moduleID,
            authenticated: true,
            types: [
                DELETE_MODULE_REQUEST,
                DELETE_MODULE_SUCCESS,
                DELETE_MODULE_FAILURE,
            ],
        },
    };
}

export function handleUpdateModule(moduleID, body) {
    return {
        [PUT_API]: {
            endpoint: MODULE_API + 'v1/module/' + moduleID,
            authenticated: true,
            types: [
                UPDATE_MODULE_REQUEST,
                UPDATE_MODULE_SUCCESS,
                UPDATE_MODULE_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}

export function getUserModuleList(email) {
    return {
        [GET_API]: {
            endpoint: MODULE_API + '/v1/listmodules/user/' + email,
            authenticated: true,
            types: [
                GET_USER_MODULE_LIST_REQUEST,
                GET_USER_MODULE_LIST_SUCCESS,
                GET_USER_MODULE_LIST_FAILURE,
            ],
        },
    };
}
