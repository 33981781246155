import {
    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAILURE,
    UPDATE_PROFILE_SUCCESS_STATUS,
    GET_PROFILE_LINK_REQUEST,
    GET_PROFILE_LINK_SUCCESS,
    GET_PROFILE_LINK_FAILURE,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    UPDATE_PROFILE_FIELDS,
    UPDATE_EDUCATION_FIELDS,
    UPDATE_EMERGENCY_FIELDS,
    UPDATE_CERTIFICATE_FIELDS,
    UPDATE_ADDRESS_FIELDS,
    ADD_USER_EDUCATION,
    HANDLE_EDIT_EDUCATION_DIALOG_STATE,
    HANDLE_CHNAGE_PASSWORD_DIALOG_STATE,
    GET_PROFILE_STATUS_REQUEST,
    GET_PROFILE_STATUS_SUCCESS,
    GET_PROFILE_STATUS_FAILURE,
    CHANGE_PROFILE_IMAGE_REQUEST,
    CHANGE_PROFILE_IMAGE_SUCCESS,
    CHANGE_PROFILE_IMAGE_FAILURE,
    HANDLE_EDIT_PERSONAL_DIALOG_STATE,
    HANDLE_EDIT_OTHER_DIALOG_STATE,
    HANDLE_EDIT_EMERGENCY_DIALOG_STATE,
    HANDLE_EDIT_ADDRESS_DIALOG_STATE,
    HANDLE_EDIT_ACCOUNT_DIALOG_STATE,
    UPDATE_ACCOUNT_FIELDS,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    UPDATE_PASSWORD_DATA,
    UPLOAD_USER_FILE_FAILURE,
    UPLOAD_USER_FILE_REQUEST,
    UPLOAD_USER_FILE_SUCCESS,
    HANDLE_EDIT_PROFILE_PICTURE_DIALOG_STATE,
    UPLOAD_USER_CERTIFICATE_REQUEST,
    UPLOAD_USER_CERTIFICATE_SUCCESS,
    UPLOAD_USER_CERTIFICATE_FAILURE,
    SET_PERSONAL_INFO,
    SET_ADDRESS_INFO,
    SET_OTHER_INFO,
    SET_EMERGENCY_INFO,
    SET_ACCOUNT_INFO,
    SET_EDUCATION_INFO,
    REMOVE_USER_EDUCATION_DETAIL,
    DELETE_PROFILE_PICTURE_REQUEST,
    DELETE_PROFILE_PICTURE_SUCCESS,
    DELETE_PROFILE_PICTURE_FAILURE,
    DELETE_USER_FILE_REQUEST,
    DELETE_USER_FILE_SUCCESS,
    DELETE_USER_FILE_FAILURE,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAILURE,
    GET_USER_PROFILE_PICTURE_REQUEST,
    GET_USER_PROFILE_PICTURE_SUCCESS,
    GET_USER_PROFILE_PICTURE_FAILURE,
    USER_PROFILE_PIC_REQUEST,
    USER_PROFILE_PIC_SUCCESS,
    USER_PROFILE_PIC_FAILURE,
    ADD_NEW_USER_CERTIFICATE,
    CLEAR_PASSWORD_CHANGE_DATA,
} from '../../constants/employee/userConstants';

const initialState = {
    getProfileSuccess: false,
    profileErrorMsg: '',
    profileInfo: {},
    getProfileLinkSuccess: false,
    getUserProfileePictureSuccess: false,
    userProfilePictureLink: '',
    profileDocumentLink: '',
    profilePicSuccess: false,
    profilePic: '',
    userProfileName: '',
    profilePicErrorMsg: '',

    profileStatusData: [],
    getProfileStatusSuccess: false,
    updateSuccess: false,
    updateErrorMsg: '',
    updateSuccessMsg: '',
    editEducationPopupState: false,
    editPersonalPopupState: false,
    editOtherPopupState: false,
    editEmergencyPopupState: false,
    editAddressPopupState: false,
    editProfilePicturePopupState: false,
    changePasswordPopupState: false,
    editAccountPopupState: false,
    changeProfileSuccess: false,
    profileLink: '',
    newPasswordData: {
        old_password: '',
        new_password: '',
        confirm_new_password: '',
    },
    changePasswordSuccess: false,
    passwordSuccessMsg: '',
    passwordErrorMsg: '',

    deletePictureSuccess: false,
    deletePictureErrorMsg: '',
    deletePictureSuccessMsg: '',

    uploaduserFileSuccess: false,
    uploaduserFileSuccessMsg: '',
    uploaduserFileErrorMsg: '',
    uploadedUserFile: '',
    uploaduserCertificateSuccess: false,
    uploaduserCertificateSuccessMsg: '',
    uploaduserCertificateErrorMsg: '',
    deleteUserFileSuccess: false,
    deleteUserFileErrorMsg: '',
    deleteUserFileSuccessMsg: '',
    userpersonalInfo: {
        blood_group: '',
        dateofbirth: '',
        fathers_name: '',
        gender: '',
        marital_Status: '',
        mothers_name: '',
        profile_img_link: '',
        spouse_contact: '',
        spouse_name: '',
    },
    useraddressInfo: [
        {
            address: '',
            address_type: 'Current Address',
            city: '',
            country: '',
            district: '',
            pincode: 0,
            state: '',
        },
        {
            address: '',
            address_type: 'Permanent Address',
            city: '',
            country: '',
            district: '',
            pincode: 0,
            state: '',
        },
    ],
    addressIndex: 0,
    useraccountInfo: {
        name: '',
        contact_no: '',
    },
    useremergencyInfo: [
        { contact_name: '', contact_number: '' },
        { contact_name: '', contact_number: '' },
    ],
    usereducationInfo: [
        {
            name: '',
            institution: '',
            location: '',
            year: '',
            percentage: 0,
            marksheet_link: [],
            degree_link: '',
        },
    ],
    eduIndex: 0,
    userotherInfo: [
        {
            name: '',
            certificate_link: '',
        },
    ],
    deleteDocumentSuccess: false,
    deleteDocumentSuccessMsg: '',
    deleteDocumentErrorMsg: '',
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROFILE_REQUEST:
            return Object.assign({}, state, {
                getProfileSuccess: false,
            });
        case GET_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                getProfileSuccess: true,
                profileErrorMsg: '',
                profileInfo: action.response.data,
                profileUpdatePercentage: action.response.data.percentage.total
            });
        case GET_PROFILE_FAILURE:
            return Object.assign({}, state, {
                getProfileSuccess: false,
                profileInfo: {},
                profileErrorMsg: action.response.data.message,
            });
        case UPDATE_PROFILE_SUCCESS_STATUS:
            return Object.assign({}, state, {
                getProfileSuccess: false,
            });
        case GET_USER_PROFILE_PICTURE_REQUEST:
            return Object.assign({}, state, {
                getUserProfileePictureSuccess: false,
                userProfilePictureLink: '',
            });
        case GET_USER_PROFILE_PICTURE_SUCCESS:
            return Object.assign({}, state, {
                getUserProfileePictureSuccess: true,
                userProfilePictureLink: action.response.data.link,
            });
        case GET_USER_PROFILE_PICTURE_FAILURE:
            return Object.assign({}, state, {
                getUserProfileePictureSuccess: false,
                userProfilePictureLink: '',
            });

        case GET_PROFILE_STATUS_REQUEST:
            return Object.assign({}, state, {
                getProfileStatusSuccess: false,
            });
        case GET_PROFILE_STATUS_SUCCESS:
            return Object.assign({}, state, {
                getProfileStatusSuccess: true,
                profileStatusData: action.response.data,
            });
        case GET_PROFILE_STATUS_FAILURE:
            return Object.assign({}, state, {
                getProfileStatusSuccess: false,
                profileStatusData: [],
            });
        case USER_PROFILE_PIC_REQUEST:
            return Object.assign({}, state, {
                profilePicSuccess: false,
                profilePic: '',
            });
        case USER_PROFILE_PIC_SUCCESS:
            return Object.assign({}, state, {
                profilePicSuccess: true,
                profilePic: action.response.data.link,
                userProfileName: action.response.data.name,
                profilePicErrorMsg: '',
            });
        case USER_PROFILE_PIC_FAILURE:
            return Object.assign({}, state, {
                profilePicSuccess: false,
                profilePic: '',
                userProfileName: '',
                profilePicErrorMsg: action.response.data.error,
            });

        case GET_PROFILE_LINK_REQUEST:
            return Object.assign({}, state, {
                getProfileLinkSuccess: false,
                profileDocumentLink: '',
            });
        case GET_PROFILE_LINK_SUCCESS:
            return Object.assign({}, state, {
                getProfileLinkSuccess: true,
                profileDocumentLink: action.response.link,
            });
        case GET_PROFILE_LINK_FAILURE:
            return Object.assign({}, state, {
                getProfileLinkSuccess: false,
                profileDocumentLink: '',
            });
        case UPDATE_PROFILE_FIELDS:
            return Object.assign({}, state, {
                userpersonalInfo: action.newState,
            });

        case UPDATE_ACCOUNT_FIELDS:
            return Object.assign({}, state, {
                useraccountInfo: action.newState,
            });
        case UPDATE_ADDRESS_FIELDS:
            return Object.assign({}, state, {
                useraddressInfo: action.newState,
            });

        case UPDATE_EDUCATION_FIELDS:
            return Object.assign({}, state, {
                usereducationInfo: action.newState,
            });
        case UPDATE_CERTIFICATE_FIELDS:
            return Object.assign({}, state, {
                userotherInfo: action.newState,
            });
        case UPDATE_EMERGENCY_FIELDS:
            return Object.assign({}, state, {
                useremergencyInfo: action.newState,
            });
        case UPDATE_PROFILE_REQUEST:
            return Object.assign({}, state, {
                updateSuccess: false,
                updateErrorMsg: '',
            });
        case UPDATE_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                updateSuccess: true,
                updateErrorMsg: '',
                updateSuccessMsg: action.response.data.message,
                editEducationPopupState: false,
                editPersonalPopupState: false,
                editOtherPopupState: false,
                editEmergencyPopupState: false,
                editAddressPopupState: false,
                editProfilePicturePopupState: false,
                changePasswordPopupState: false,
                editAccountPopupState: false,
            });
        case UPDATE_PROFILE_FAILURE:
            return Object.assign({}, state, {
                updateSuccess: false,
                updateErrorMsg: action.response.data.message,
                updateSuccessMsg: '',
            });
        case HANDLE_EDIT_EDUCATION_DIALOG_STATE:
            return Object.assign({}, state, {
                editEducationPopupState: action.status,
            });
        case HANDLE_EDIT_PERSONAL_DIALOG_STATE:
            return Object.assign({}, state, {
                editPersonalPopupState: action.status,
            });
        case HANDLE_EDIT_OTHER_DIALOG_STATE:
            return Object.assign({}, state, {
                editOtherPopupState: action.status,
            });
        case HANDLE_EDIT_EMERGENCY_DIALOG_STATE:
            return Object.assign({}, state, {
                editEmergencyPopupState: action.status,
            });
        case HANDLE_EDIT_ADDRESS_DIALOG_STATE:
            return Object.assign({}, state, {
                editAddressPopupState: action.status,
            });
        case HANDLE_CHNAGE_PASSWORD_DIALOG_STATE:
            return Object.assign({}, state, {
                changePasswordPopupState: action.status,
            });
        case HANDLE_EDIT_ACCOUNT_DIALOG_STATE:
            return Object.assign({}, state, {
                editAccountPopupState: action.status,
            });
        case HANDLE_EDIT_PROFILE_PICTURE_DIALOG_STATE:
            return Object.assign({}, state, {
                editProfilePicturePopupState: action.status,
            });

        case CHANGE_PROFILE_IMAGE_REQUEST:
            return Object.assign({}, state, {
                changeProfileSuccess: false,
            });
        case CHANGE_PROFILE_IMAGE_SUCCESS:
            return {
                ...state,
                userpersonalInfo: {
                    ...state.userpersonalInfo,
                    profile_img_link: action.response.data.uploadLink[0],
                },
                changeProfileSuccess: true,
                profileLink: action.response.data.uploadLink[0],
                profilePreviewLink: action.response.data.previewLinks[0],
            };
        case CHANGE_PROFILE_IMAGE_FAILURE:
            return Object.assign({}, state, {
                changeProfileSuccess: false,
                profileLink: '',
            });
        case UPDATE_PASSWORD_DATA:
            return Object.assign({}, state, {
                newPasswordData: action.newState,
            });
        case CHANGE_PASSWORD_REQUEST:
            return Object.assign({}, state, {
                changePasswordSuccess: false,
            });
        case CHANGE_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                changePasswordSuccess: true,
                passwordSuccessMsg: action.response.data.message,
                passwordErrorMsg: '',
            });
        case CHANGE_PASSWORD_FAILURE:
            return Object.assign({}, state, {
                changePasswordSuccess: false,
                passwordSuccessMsg: '',
                passwordErrorMsg: action.response.data.message,
            });
        case CLEAR_PASSWORD_CHANGE_DATA:
            return Object.assign({}, state, {
                passwordSuccessMsg: '',
                passwordErrorMsg: '',
            });

        case UPLOAD_USER_FILE_REQUEST:
            return Object.assign({}, state, {
                uploaduserFileSuccess: false,
            });
        case UPLOAD_USER_FILE_SUCCESS:
            if (action.response.data.username == 'marksheet_link') {
                return {
                    ...state,
                    usereducationInfo: state.usereducationInfo.map((item, i) =>
                        i === action.response.data.userindex
                            ? {
                                  ...item,
                                  [action.response.data.username]:
                                      state.usereducationInfo[
                                          action.response.data.userindex
                                      ].marksheet_link.concat(
                                          action.response.data.useruploadLink
                                      ),
                              }
                            : item
                    ),
                    uploaduserFileSuccess: true,
                    uploadFileErrorMsg: '',
                    uploadFileSuccessMsg: action.response.data.usermessage,
                    uploadedUserFile: action.response.data.useruploadLink[0],
                };
            } else {
                return {
                    ...state,
                    usereducationInfo: state.usereducationInfo.map((item, i) =>
                        i === action.response.data.userindex
                            ? {
                                  ...item,
                                  [action.response.data.username]:
                                      action.response.data.useruploadLink[0],
                              }
                            : item
                    ),
                    uploaduserFileSuccess: true,
                    uploadFileErrorMsg: '',
                    uploadFileSuccessMsg: action.response.data.usermessage,
                    uploadedUserFile: action.response.data.useruploadLink[0],
                };
            }
        case UPLOAD_USER_FILE_FAILURE:
            return Object.assign({}, state, {
                uploaduserFileSuccess: false,
                uploadFileSuccessMsg: '',
                uploadFileErrorMsg: action.response.data.message,
            });
        case UPLOAD_USER_CERTIFICATE_REQUEST:
            return Object.assign({}, state, {
                uploaduserCertificateSuccess: false,
            });
        case UPLOAD_USER_CERTIFICATE_SUCCESS:
            return {
                ...state,
                userotherInfo: state.userotherInfo.map((item, i) =>
                    i === action.response.data.index
                        ? {
                              ...item,
                              [action.response.data.name]:
                                  action.response.data.uploadLink[0],
                          }
                        : item
                ),
                uploaduserCertificateSuccess: true,
                uploadCertificateErrorMsg: '',
                uploadCertificateSuccessMsg: action.response.data.message,
            };
        case UPLOAD_USER_CERTIFICATE_FAILURE:
            return Object.assign({}, state, {
                uploaduserCertificateSuccess: false,
                uploadCertificateSuccessMsg: '',
                uploadCertificateErrorMsg: action.response.data.message,
            });
        case ADD_USER_EDUCATION:
            return Object.assign({}, state, {
                usereducationInfo: state.profileInfo.profile.education.concat(
                    action.education
                ),
                eduIndex: action.eduIndex,
            });
        case REMOVE_USER_EDUCATION_DETAIL:
            return {
                ...state,
                profileInfo: {
                    ...state.profileInfo,
                    profile: {
                        ...state.profileInfo.profile,
                        education: action.education,
                    },
                },
            };
        case SET_PERSONAL_INFO:
            return Object.assign({}, state, {
                userpersonalInfo: action.personalInfo,
            });
        case SET_ADDRESS_INFO:
            return Object.assign({}, state, {
                useraddressInfo: action.addressInfo,
                addressIndex: action.addressIndex,
            });
        case SET_ACCOUNT_INFO:
            return Object.assign({}, state, {
                useraccountInfo: action.accountInfo,
            });
        case SET_EDUCATION_INFO:
            return Object.assign({}, state, {
                usereducationInfo: action.educationInfo,
                eduIndex: action.eduIndex,
            });
        case SET_OTHER_INFO:
            return Object.assign({}, state, {
                userotherInfo: action.otherInfo,
            });
        case SET_EMERGENCY_INFO:
            return Object.assign({}, state, {
                useremergencyInfo: action.emergencyInfo,
            });
        case DELETE_PROFILE_PICTURE_REQUEST:
            return Object.assign({}, state, {
                deletePictureSuccess: false,
            });
        case DELETE_PROFILE_PICTURE_SUCCESS:
            return Object.assign({}, state, {
                deletePictureSuccess: true,
                deletePictureSuccessMsg: action.response.data.message,
                deletePictureSuccessError: false,
            });
        case DELETE_PROFILE_PICTURE_FAILURE:
            return Object.assign({}, state, {
                deletePictureSuccess: false,
                deletePictureErrorMsg: action.response.data.message,
                deletePictureSuccessMsg: '',
            });
        case DELETE_USER_FILE_REQUEST:
            return Object.assign({}, state, {
                deleteUserFileSuccess: false,
            });
        case DELETE_USER_FILE_SUCCESS:
            return Object.assign({}, state, {
                deleteUserFileSuccess: true,
                deleteUserFileSuccessMsg: action.response.data.message,
                deleteUserFileErrorMsg: '',
            });
        case DELETE_USER_FILE_FAILURE:
            return Object.assign({}, state, {
                deleteUserFileSuccess: false,
                deleteUserFileErrorMsg: action.response.data.message,
                deleteUserFileSuccessMsg: '',
            });
        case DELETE_DOCUMENT_REQUEST:
            return Object.assign({}, state, {
                deleteDocumentSuccess: false,
            });
        case DELETE_DOCUMENT_SUCCESS:
            if (action.name == 'marksheet_link') {
                return {
                    ...state,
                    usereducationInfo: state.usereducationInfo.map((item, i) =>
                        i === action.index
                            ? { ...item, marksheet_link: action.list }
                            : item
                    ),
                    deleteDocumentSuccess: true,
                    deleteDocumentSuccessMsg: action.response.data.message,
                    deleteDocumentErrorMsg: '',
                };
            } else {
                return Object.assign({}, state, {
                    deleteDocumentSuccess: true,
                    deleteDocumentSuccessMsg: action.response.data.message,
                    deleteDocumentErrorMsg: '',
                });
            }
        case DELETE_DOCUMENT_FAILURE:
            return Object.assign({}, state, {
                deleteDocumentSuccess: false,
                deleteDocumentSuccessMsg: '',
                deleteDocumentErrorMsg: action.response.data.message,
            });
        case ADD_NEW_USER_CERTIFICATE:
            return Object.assign({}, state, {
                userotherInfo: state.userotherInfo.concat(
                    action.newCertificate
                ),
            });
        default:
            return state;
    }
}
