import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../dist/modal.css';
import { Button } from 'xd-react-button';
import { Input } from 'xd-react-input';
import {
    getEmployeeProfile,
    handleEditEmergencyDialogChange,
    updateEmergencyFields,
    updateProfileDetails,
} from '../../../../actions/employee/userActions';

class EditEmergencyPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactError: '',
        };
    }

    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleEditEmergencyDialogChange(false));
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.updateSuccess !== this.props.updateSuccess) {
           nextProps.dispatch(getEmployeeProfile());
        }
    }

    handleOnChange(e, index) {
        let value = e.target.value;
        const name = e.target.name;
        let emergencyData = Object.assign([], this.props.useremergencyInfo);
        emergencyData[index][name] = value;
        this.props.dispatch(updateEmergencyFields(emergencyData));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const details = {
            account_info: {
                name: this.props.profileInfo.profile.account.name,
                contact_no: this.props.profileInfo.profile.account.contact_no,
            },
            personal_info: this.props.profileInfo.profile.personal,
            user_address: this.props.profileInfo.profile.address,
            education: this.props.profileInfo.profile.education,
            emergency_contact: this.props.useremergencyInfo,
            others: this.props.profileInfo.profile.others,
        };
        if (
            e.target.checkValidity() &&
            this.props.useremergencyInfo[0].contact_number !=
                this.props.useremergencyInfo[1].contact_number
        ) {
            this.props.dispatch(updateProfileDetails(details));
            document.querySelector('#body-root').style = 'overflow:auto;';
        } else {
            if (
                this.props.useremergencyInfo[0].contact_number ==
                    this.props.useremergencyInfo[1].contact_number &&
                this.props.useremergencyInfo[0].contact_number.length == 10 &&
                this.props.useremergencyInfo[1].contact_number.length == 10
            ) {
                this.setState({
                    contactError: 'Both Contact numbers cannot be same',
                });
            } else {
                this.setState({ contactError: '' });
            }
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    render() {
        const { editEmergencyPopupState } = this.props;
        const emergencyInfo = this.props.useremergencyInfo;
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': editEmergencyPopupState,
                        fadeOut: !editEmergencyPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown: editEmergencyPopupState,
                                fadeOutUp: !editEmergencyPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">
                                    Emergency Information
                                </h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form
                                onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true}
                                id="edit-education-dialog"
                            >
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        {emergencyInfo
                                            ? emergencyInfo.map(
                                                  (emergency, emIndex) => (
                                                      <div className="row">
                                                          <div className="col-12 col-sm-6">
                                                              <div className="form-group">
                                                                  <Input
                                                                      type="text"
                                                                      name="contact_name"
                                                                      className="form-control"
                                                                      errorMessage="Enter emergency contact name(min 3 char.)"
                                                                      placeholder="Contact name"
                                                                      label="Contact Name"
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          this.handleOnChange(
                                                                              e,
                                                                              emIndex
                                                                          )
                                                                      }
                                                                      required
                                                                      value={
                                                                          emergency.contact_name
                                                                      }
                                                                      minLength={
                                                                          3
                                                                      }
                                                                      maxLength={
                                                                          50
                                                                      }
                                                                      pattern={
                                                                          '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                      }
                                                                  />
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-sm-6">
                                                              <div className="form-group">
                                                                  <Input
                                                                      type="text"
                                                                      name="contact_number"
                                                                      className="form-control"
                                                                      errorMessage="Enter the emergency contact number"
                                                                      placeholder="Contact number"
                                                                      label="Contact Number"
                                                                      onChange={(
                                                                          e
                                                                      ) =>
                                                                          this.handleOnChange(
                                                                              e,
                                                                              emIndex
                                                                          )
                                                                      }
                                                                      required
                                                                      value={
                                                                          emergency.contact_number
                                                                      }
                                                                      pattern={
                                                                          '[7-9]{1}[0-9]{9}'
                                                                      }
                                                                  />
                                                                  {emIndex ==
                                                                      '1' && (
                                                                      <div
                                                                          style={{
                                                                              color: 'red',
                                                                              padding:
                                                                                  '0',
                                                                              margin: '0',
                                                                          }}
                                                                      >
                                                                          {
                                                                              this
                                                                                  .state
                                                                                  .contactError
                                                                          }
                                                                      </div>
                                                                  )}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  )
                                              )
                                            : ''}
                                        {this.props.updateErrorMsg && (
                                            <p style={{ color: 'red' }}>
                                                {this.props.updateErrorMsg}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {editEmergencyPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: editEmergencyPopupState,
                            fadeOut: !editEmergencyPopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        editEmergencyPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        useremergencyInfo,
    } = state.userReducer;
    return {
        editEmergencyPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        useremergencyInfo,
    };
}
export default withRouter(connect(mapStateToProps)(EditEmergencyPopup));
