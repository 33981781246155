import {GET_INTERN_REPORTS_LIST_REQUEST, GET_INTERN_REPORTS_LIST_SUCCESS, GET_INTERN_REPORTS_LIST_FAILURE,
    GET_BATCH_LIST_REQUEST, GET_BATCH_LIST_SUCCESS, GET_BATCH_LIST_FAILURE, ADD_MORE_REPORTS_FILTERS} from '../../constants/admin/ReportsConstants';

import { GET_API } from "../../middleware/symbols";


import {getDocumentApi, accountApi, moduleApi } from "../ApiActions";

const AUTH_API = accountApi();
const DOCUMENT_API = getDocumentApi();
const MODULE_API = moduleApi();

export function getReportsList(body){
    return{
        [GET_API]:{
            endpoint: AUTH_API+`/v1/reports/${body.level}?page=${body.page}&limit=${body.limit}${body.batch!=='' ? '&batch='+body.batch : ''}${body.module!=='' ? '&module='+body.module : ''}${body.sortby!=='' ? '&sortby='+body.sortby : ''}`,
            authenticated: true,
            types:[GET_INTERN_REPORTS_LIST_REQUEST, GET_INTERN_REPORTS_LIST_SUCCESS, GET_INTERN_REPORTS_LIST_FAILURE],
            body: body
        }
    }
}
export function getInternBatchList(){
    return{
        [GET_API]:{
            endpoint: AUTH_API+`/v1/batch`,
            authenticated: true,
            types:[GET_BATCH_LIST_REQUEST, GET_BATCH_LIST_SUCCESS, GET_BATCH_LIST_FAILURE]
        }
    }
}
export function addMoreFilter(data){
    return{
        type: ADD_MORE_REPORTS_FILTERS, data
    }
}