export const HANDLE_DRAWER_TOGGLE_CHANGE = "HANDLE_DRAWER_TOGGLE_CHANGE";

export const GET_FORM_REQUEST = "GET_FORM_REQUEST";
export const GET_FORM_SUCCESS = "GET_FORM_SUCCESS";
export const GET_FORM_FAILURE = "GET_FORM_FAILURE";

export const POST_INVITE_REQUEST="POST_INVITE_REQUEST";
export const POST_INVITE_SUCCESS="POST_INVITE_REQUEST";
export const POST_INVITE_FAILURE="POST_INVITE_REQUEST";

export const HANDLE_UPDATE_INTERN_LIST="HANDLE_UPDATE_INTERN_LIST";
export const CLEAR_INTERN_DATA="CLEAR_INTERN_DATA";

export const REMOVE_INTERN_INVITE="REMOVE_INTERN_INVITE";
export const ADD_INTERN_INVITE="ADD_INTERN_INVITE";
