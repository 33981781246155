import {
    ADD_INVITE_EMPLOYEE,
    REMOVE_INVITE_EMPLOYEE,
    UPDTAE_INVITE_EMPLOYEE_FORM,
    INVITE_EMPLOYEE_REQUEST,
    INVITE_EMPLOYEE_SUCCESS,
    INVITE_EMPLOYEE_FAILURE,
    CLEAR_EMPLOYEE_DATA,
    GET_INTERN_LIST_REQUEST,
    GET_INTERN_LIST_SUCCESS,
    GET_INTERN_LIST_FAILURE,
    GET_INTERN_COUNT_L1_REQUEST,
    GET_INTERN_COUNT_L1_SUCCESS,
    GET_INTERN_COUNT_L1_FAILURE,
    GET_INTERN_COUNT_L2_REQUEST,
    GET_INTERN_COUNT_L2_SUCCESS,
    GET_INTERN_COUNT_L2_FAILURE,
    GET_INTERN_COUNT_L3_REQUEST,
    GET_INTERN_COUNT_L3_SUCCESS,
    GET_INTERN_COUNT_L3_FAILURE,
    DELETE_INTERN_REQUEST,
    DELETE_INTERN_SUCCESS,
    DELETE_INTERN_FAILURE,
    GET_USER_PROFILE_REQUEST,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
    DEACTIVATE_ACCOUNT_REQUEST,
    DEACTIVATE_ACCOUNT_SUCCESS,
    DEACTIVATE_ACCOUNT_FAILURE,
    CHANGE_LEVEL_REQUEST,
    CHANGE_LEVEL_SUCCESS,
    CHANGE_LEVEL_FAILURE,
    EXPORT_CSV_REQUEST,
    EXPORT_CSV_SUCCESS,
    EXPORT_CSV_FAILURE,
    UPDATE_SELECTED_MODULE,
    GET_LINK_REQUEST,
    GET_LINK_SUCCESS,
    GET_LINK_FAILURE,
    STORE_SELECTED_MODULE,
    STORE_SELECTED_EMPLOYEE,
    POST_ASSIGN_MODULE_REQUEST,
    POST_ASSIGN_MODULE_SUCCESS,
    POST_ASSIGN_MODULE_FAILURE,
    CLEAR_SELECTED_MODULE_LIST,
    REMOVE_SELECTED_MODULE_DATA,
    STORE_ALL_MODULES,
    CLEAR_SELECTED_EMAIL_LIST,
    CLEAR_ASSIGN_MODULE,
    CLEAR_DEACTIVATE_ACCOUNT_DATA,
    REMOVE_SELECTED_EMPLOYEE,
    STORE_ALL_INTERNS,
    CLEAR_CHANGE_LEVEL_DATA,
    SET_INTERNS_LEVEL,
    DELETE_ASSIGNED_MODULE_REQUEST,
    DELETE_ASSIGNED_MODULE_SUCCESS,
    DELETE_ASSIGNED_MODULE_FAILURE,
    UPDATE_ASSIGN_MODULE_REQUEST,
    UPDATE_ASSIGN_MODULE_SUCCESS,
    UPDATE_ASSIGN_MODULE_FAILURE,
    INTERN_CHECKED,
    INTERN_NOT_CHECKED
} from '../../constants/admin/EmployeeConstants';

import {
    GET_API,
    DELETE_API,
    POST_API,
    PUT_API,
} from '../../middleware/symbols';

import { saveAs } from 'file-saver';
import { getDocumentApi, accountApi, moduleApi } from '../ApiActions';

const AUTH_API = accountApi();
const DOCUMENT_API = getDocumentApi();
const MODULE_API = moduleApi();

export function addNewEmployee(employee) {
    return { type: ADD_INVITE_EMPLOYEE, employee };
}

export function storeSelectedInternData(employee) {
    return { type: STORE_SELECTED_EMPLOYEE, employee };
}
export function isInternSelected(){
    return {
        type: INTERN_CHECKED
    }
}
export function isInternNotSelected(){
    return {
        type: INTERN_NOT_CHECKED
    }
}

export function deleteModule(moduleId, email) {
    return {
        [DELETE_API]: {
            endpoint: MODULE_API + '/v1/module/' + moduleId + '/user/' + email,
            authenticated: true,
            types: [
                DELETE_ASSIGNED_MODULE_REQUEST,
                DELETE_ASSIGNED_MODULE_SUCCESS,
                DELETE_ASSIGNED_MODULE_FAILURE,
            ],
        },
    };
}
export function removeSelectedInternData(index) {
    return { type: REMOVE_SELECTED_EMPLOYEE, index };
}
export function storeAllInterns(interns) {
    return { type: STORE_ALL_INTERNS, interns };
}

export function storeSelectedModuleData(module) {
    return { type: STORE_SELECTED_MODULE, module };
}
export function removeSelectedModuleData(index) {
    return { type: REMOVE_SELECTED_MODULE_DATA, index };
}
export function storeAllModules(modules) {
    return { type: STORE_ALL_MODULES, modules };
}

export function updateSelectedModules(module) {
    return { type: UPDATE_SELECTED_MODULE, module };
}
export function removeEmployee(employee) {
    return { type: REMOVE_INVITE_EMPLOYEE, employee };
}

export function updateEmployeeList(employee) {
    return { type: UPDTAE_INVITE_EMPLOYEE_FORM, employee };
}

export function clearSelectedModuleData() {
    return { type: CLEAR_SELECTED_MODULE_LIST };
}

export function clearAssignModuleData() {
    return { type: CLEAR_ASSIGN_MODULE };
}
export function clearSelectedEmailData() {
    return { type: CLEAR_SELECTED_EMAIL_LIST };
}
export function updateAssignModule(body, moduleId) {
    return {
        [PUT_API]: {
            endpoint: MODULE_API + `/v1/module/${moduleId}/user`,
            authenticated: true,
            types: [
                UPDATE_ASSIGN_MODULE_REQUEST,
                UPDATE_ASSIGN_MODULE_SUCCESS,
                UPDATE_ASSIGN_MODULE_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}

export function postAssignModule(body) {
    return {
        [POST_API]: {
            endpoint: MODULE_API + '/' + 'v1/assignmodule',
            authenticated: true,
            types: [
                POST_ASSIGN_MODULE_REQUEST,
                POST_ASSIGN_MODULE_SUCCESS,
                POST_ASSIGN_MODULE_FAILURE,
            ],
            body,
        },
    };
}

export function clearEmpoyeeData() {
    return { type: CLEAR_EMPLOYEE_DATA };
}

export function handleInviteEmployee(body) {
    return {
        [POST_API]: {
            endpoint: AUTH_API + '/v1/invite',
            authenticated: true,
            types: [
                INVITE_EMPLOYEE_REQUEST,
                INVITE_EMPLOYEE_SUCCESS,
                INVITE_EMPLOYEE_FAILURE,
            ],
            body: body,
        },
    };
}

export function getInternList(body, search) {
    let endpoint =
        AUTH_API +
        `/v1/internslist/${body.level}?page=${body.page}&limit=${body.limit}`;
    if (search !== undefined) {
        endpoint += '&search=' + search;
    }
    return {
        [GET_API]: {
            endpoint,
            authenticated: true,
            types: [
                GET_INTERN_LIST_REQUEST,
                GET_INTERN_LIST_SUCCESS,
                GET_INTERN_LIST_FAILURE,
            ],
            body: body,
        },
    };
}


export function getInternCountL1(body, search) {
    let endpoint =
        AUTH_API +
        `/v1/internslist/${body.level}?page=${body.page}&limit=${body.limit}`;
    if (search !== undefined) {
        endpoint += '&search=' + search;
    }
    return {
        [GET_API]: {
            endpoint,
            authenticated: true,
            types: [
                GET_INTERN_COUNT_L1_REQUEST,
                GET_INTERN_COUNT_L1_SUCCESS,
                GET_INTERN_COUNT_L1_FAILURE,
            ],
            body: body,
        },
    };
}
export function getInternCountL2(body, search) {
    let endpoint =
        AUTH_API +
        `/v1/internslist/${body.level}?page=${body.page}&limit=${body.limit}`;
    if (search !== undefined) {
        endpoint += '&search=' + search;
    }
    return {
        [GET_API]: {
            endpoint,
            authenticated: true,
            types: [
                GET_INTERN_COUNT_L2_REQUEST,
                GET_INTERN_COUNT_L2_SUCCESS,
                GET_INTERN_COUNT_L2_FAILURE,
            ],
            body: body,
        },
    };
}
export function getInternCountL3(body, search) {
    let endpoint =
        AUTH_API +
        `/v1/internslist/${body.level}?page=${body.page}&limit=${body.limit}`;
    if (search !== undefined) {
        endpoint += '&search=' + search;
    }
    return {
        [GET_API]: {
            endpoint,
            authenticated: true,
            types: [
                GET_INTERN_COUNT_L3_REQUEST,
                GET_INTERN_COUNT_L3_SUCCESS,
                GET_INTERN_COUNT_L3_FAILURE,
            ],
            body: body,
        },
    };
}
export function deleteIntern(email) {
    return {
        [DELETE_API]: {
            endpoint: AUTH_API + `/v1/delete/${email}`,
            authenticated: true,
            types: [
                DELETE_INTERN_REQUEST,
                DELETE_INTERN_SUCCESS,
                DELETE_INTERN_FAILURE,
            ],
        },
    };
}

export function getUserProfile(email) {
    return {
        [GET_API]: {
            endpoint: AUTH_API + `/v1/userprofile/${email}`,
            authenticated: true,
            types: [
                GET_USER_PROFILE_REQUEST,
                GET_USER_PROFILE_SUCCESS,
                GET_USER_PROFILE_FAILURE,
            ],
        },
    };
}

export function deactivateAccount(body) {
    return {
        [PUT_API]: {
            endpoint:
                AUTH_API +
                `/v1/accountstatus/${body.status}/userid/${body.email}`,
            authenticated: true,
            types: [
                DEACTIVATE_ACCOUNT_REQUEST,
                DEACTIVATE_ACCOUNT_SUCCESS,
                DEACTIVATE_ACCOUNT_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}

export function clearDeactivateData() {
    return { type: CLEAR_DEACTIVATE_ACCOUNT_DATA };
}

export function changeLevel(body) {
    return {
        [POST_API]: {
            endpoint: AUTH_API + `/v1/changelevel`,
            authenticated: true,
            types: [
                CHANGE_LEVEL_REQUEST,
                CHANGE_LEVEL_SUCCESS,
                CHANGE_LEVEL_FAILURE,
            ],
            body: body,
        },
    };
}
export function clearChangeLevelData() {
    return { type: CLEAR_CHANGE_LEVEL_DATA };
}

export function exportAsCsv() {
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    };
    return (dispatch) => {
        // We dispatch requestLogin to
        dispatch(ExportAsCsvRequest());
        fetch(AUTH_API + '/v1/export', config)
            .then((response) => {
                // Extract filename from header
                window.exportCsvFilename = response.headers
                    .get('content-disposition')
                    .split(';')
                    .find((n) => n.includes('filename='))
                    .replace('filename=', '')
                    .trim();
                return response.text();
            })
            .then(function (res) {
                if (res) {
                    const filename = window.exportCsvFilename;
                    // Download the file
                    var blob = new Blob([res], {
                        type: 'text/csv;charset=utf-8',
                    });
                    saveAs(blob, filename);
                    dispatch(
                        ExportAsCsvSuccess({
                            data: {
                                error: false,
                                message: 'successful',
                                csvData: res,
                            },
                            status: 200,
                        })
                    );
                } else {
                    dispatch(
                        ExportAsCsvFailure({
                            data: {
                                error: true,
                                message: 'failed',
                                csvData: '',
                            },
                        })
                    );
                }
            });
    };
}
export function ExportAsCsvRequest() {
    return {
        type: EXPORT_CSV_REQUEST,
    };
}
export function ExportAsCsvSuccess(response) {
    return {
        type: EXPORT_CSV_SUCCESS,
        response,
    };
}
export function ExportAsCsvFailure(response) {
    return {
        type: EXPORT_CSV_FAILURE,
        response,
    };
}

export function getDocumentLink(link) {
    return {
        [GET_API]: {
            endpoint: DOCUMENT_API + `/getfile/${link}`,
            authenticated: true,
            types: [GET_LINK_REQUEST, GET_LINK_SUCCESS, GET_LINK_FAILURE],
        },
    };
}

export function getDocumentLinks(link) {
    const token = localStorage.getItem('token');
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return (dispatch) => {
        dispatch(getLinkRequest());
        fetch(DOCUMENT_API + `/getfile/${link}`, config)
            .then(function (response) {
                return response.json();
            })
            .then(
                function (res) {
                    dispatch(getLinkSuccess(res));
                    window.open(res.link, '_blank');
                },
                function (error) {
                    dispatch(getLinkFailed(error));
                }
            );
    };
}

export function getLinkRequest() {
    return {
        type: GET_LINK_REQUEST,
    };
}

export function getLinkSuccess(response) {
    return {
        type: GET_LINK_SUCCESS,
        response,
    };
}
export function getLinkFailed(response) {
    return {
        type: GET_LINK_FAILURE,
        response,
    };
}
export function setInternsLevel(level) {
    return { type: SET_INTERNS_LEVEL, level };
}
