import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'xd-react-button';
import createNotification from '../../../components/app/notification/createNotification';
import {
    handleUploadPDFDialogChange,
    uploadPDF,
    clearPDFUploadData,
} from '../../../actions/admin/internModuleActions';

class UploadPDFPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        let input = document.getElementById('pdf-upload-input');
        input.value = null;
        this.props.dispatch(handleUploadPDFDialogChange(false));
    }

    handleSubmit(e) {
        let input = document.getElementById('pdf-upload-input');
        if (input.checkValidity()) {
            input.parentElement.classList.remove('has-error');
            input.parentElement.parentElement.classList.remove('has-error');
            if (input.files) {
                let files = input.files;
                this.props.dispatch(uploadPDF(files));
                input.value = null;
            }
        } else {
            input.parentElement.classList.add('has-error');
            input.parentElement.parentElement.classList.add('has-error');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.PDFLinkUploadError) {
            createNotification('error', nextProps.PDFLinkUploadError);
            nextProps.dispatch(clearPDFUploadData());
        }
        if (nextProps.PDFLinkUploadSuccess) {
            document.querySelector('#body-root').style = 'overflow:auto;';
            this.props.dispatch(handleUploadPDFDialogChange(false));
            createNotification('success', 'Attachment saved successfully');
            nextProps.dispatch(clearPDFUploadData());
        }
    }

    render() {
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': this.props.uploadPDFPopupState,
                        fadeOut: !this.props.uploadPDFPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown: this.props.uploadPDFPopupState,
                                fadeOutUp: !this.props.uploadPDFPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">Upload PDF</h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div id="edit-education-dialog">
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <div>
                                                    <label>
                                                        Upload Attachment{' '}
                                                        <span className="req">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div class="input-wrapper">
                                                        <input
                                                            type="file"
                                                            name="pdf_link"
                                                            required
                                                            multiple
                                                            id="pdf-upload-input"
                                                            placeholder="Upload PDF"
                                                            label="Upload PDF"
                                                            accept="application/pdf"
                                                            className="form-control"
                                                            errorMessage="Please upload your pdf"
                                                        />
                                                        <div className="help-block with-errors">
                                                            Please Upload
                                                            Attachment
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                            onClick={this.handleSubmit.bind(
                                                this
                                            )}
                                        >
                                            {this.props.uploadPDFLoading ? (
                                                <span>
                                                    <i className="fa fa-spin fa-spinner btn-icon"></i>{' '}
                                                    Uploading..
                                                </span>
                                            ) : (
                                                <span>Upload</span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.uploadPDFPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: this.props.uploadPDFPopupState,
                            fadeOut: !this.props.uploadPDFPopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        moduleData,
        uploadPDFPopupState,
        PDFLink,
        PDFUploadError,
        PDFUploadSuccess,
        PDFLinkUploadError,
        PDFLinkUploadSuccess,
        deletePDFSuccessMsg,
        deletePDFErrorMsg,
        uploadPDFAfterDelete,
        uploadPDFLoading,
    } = state.moduleReducer;
    return {
        moduleData,
        uploadPDFPopupState,
        PDFLink,
        PDFUploadError,
        PDFUploadSuccess,
        PDFLinkUploadError,
        PDFLinkUploadSuccess,
        deletePDFSuccessMsg,
        deletePDFErrorMsg,
        uploadPDFAfterDelete,
        uploadPDFLoading,
    };
}
export default withRouter(connect(mapStateToProps)(UploadPDFPopup));
