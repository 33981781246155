import {GET_DEPARTMENT_LIST_REQUEST, GET_DEPARTMENT_LIST_SUCCESS, GET_DEPARTMENT_LIST_FAILURE,
    DELETE_DEPARTMENT_LIST_REQUEST, DELETE_DEPARTMENT_LIST_SUCCESS, DELETE_DEPARTMENT_LIST_FAILURE,
    CLEAR_DEPARTMENT_DATA, UPDATE_DEPARTMENT_DATA, EDIT_DEPARTMENT_DIALOG_STATE,
    CREATE_DEPARTMENT_REQUEST, CREATE_DEPARTMENT_SUCCESS, CREATE_DEPARTMENT_FAILURE, 
    CLEAR_DEPARTMENT_ERROR_DATA, SET_DEPARTMENT_INFO, 
    UPDATE_DEPARTMENT_REQUEST, UPDATE_DEPARTMENT_SUCCESS, UPDATE_DEPARTMENT_FAILURE} from '../../constants/admin/DepartmentConstants';

import { GET_API, DELETE_API, POST_API, PUT_API } from "../../middleware/symbols";

import {accountApi} from "../ApiActions";
const AUTH_API = accountApi();

export function getDepartmentList(body){
    return{
        [GET_API]:{
            endpoint:AUTH_API+`/v1/department`,
            authenticated:true,
            types:[GET_DEPARTMENT_LIST_REQUEST, GET_DEPARTMENT_LIST_SUCCESS, GET_DEPARTMENT_LIST_FAILURE],
        }
    }
}
export function deleteDepartment(body){
    return{
        [DELETE_API]:{
            endpoint:AUTH_API+`/v1/department/${body.id}`,
            authenticated:true,
            types:[DELETE_DEPARTMENT_LIST_REQUEST, DELETE_DEPARTMENT_LIST_SUCCESS, DELETE_DEPARTMENT_LIST_FAILURE],
            body:body
        }
    }
}
export function clearDepartmentData(){
    return{ type: CLEAR_DEPARTMENT_DATA}
}
export function updateDepartmentFields(data){
    return{type:UPDATE_DEPARTMENT_DATA, data}
}
export function handleCreateDepartment(body){
    return{
        [POST_API]:{
            endpoint:AUTH_API+`/v1/department`,
            authenticated:true,
            types:[CREATE_DEPARTMENT_REQUEST, CREATE_DEPARTMENT_SUCCESS, CREATE_DEPARTMENT_FAILURE],
            body:body
        }
    }
}
export function clearDepartmentErrorData(){
    return{type:CLEAR_DEPARTMENT_ERROR_DATA}
}
export function handleEditDeptDialogChange(status){
    return{
        type: EDIT_DEPARTMENT_DIALOG_STATE, status
    }
}
export function setDepartmentInfo(data){
    return{type: SET_DEPARTMENT_INFO, data}
}
export function handleEditDepartment(body){
    return{
        [PUT_API]:{
            endpoint:AUTH_API+`/v1/department/${body.id}`,
            authenticated:true,
            types:[UPDATE_DEPARTMENT_REQUEST, UPDATE_DEPARTMENT_SUCCESS, UPDATE_DEPARTMENT_FAILURE],
            body:JSON.stringify(body)
        }
    }
}