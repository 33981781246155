import {
    GET_DEPARTMENT_LIST_REQUEST,
    GET_DEPARTMENT_LIST_SUCCESS,
    GET_DEPARTMENT_LIST_FAILURE,
    DELETE_DEPARTMENT_LIST_REQUEST,
    DELETE_DEPARTMENT_LIST_SUCCESS,
    DELETE_DEPARTMENT_LIST_FAILURE,
    CLEAR_DEPARTMENT_DATA,
    UPDATE_DEPARTMENT_DATA,
    CLEAR_DEPARTMENT_ERROR_DATA,
    CREATE_DEPARTMENT_REQUEST,
    CREATE_DEPARTMENT_SUCCESS,
    CREATE_DEPARTMENT_FAILURE,
    EDIT_DEPARTMENT_DIALOG_STATE,
    SET_DEPARTMENT_INFO,
    UPDATE_DEPARTMENT_REQUEST,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAILURE,
} from '../../constants/admin/DepartmentConstants';

const initialState = {
    isDepartmentListLoading: false,
    departmentListErrorMsg: '',
    departmentList: [],
    departmentSuccessMsg: '',

    deleteDepartmentErrorMsg: '',
    deleteDepartmentSuccessMsg: '',
    getDeptAfterDelete: false,

    department: {
        name: '',
        short_name: '',
    },

    createDepartmentErrorMsg: '',
    createDepartmentSuccessMsg: '',
    editDepartmentPopupState: false,

    departmentData: {},
    updateDepartmentErrorMsg: '',
    updateDepartmentSuccessMsg: '',
    getListAfterUpdate: false,
};

export default function departmentReducer(state = initialState, action) {
    switch (action.type) {
        case GET_DEPARTMENT_LIST_REQUEST:
            return Object.assign({}, state, {
                isDepartmentListLoading: true,
                departmentListErrorMsg: '',
                departmentList: [],
                departmentSuccessMsg: '',
                getDeptAfterDelete: false,
                getListAfterUpdate: false,
            });
        case GET_DEPARTMENT_LIST_SUCCESS:
            return Object.assign({}, state, {
                isDepartmentListLoading: false,
                departmentListErrorMsg: '',
                departmentList: action.response.data.list,
                departmentSuccessMsg: action.response.data.message,
            });
        case GET_DEPARTMENT_LIST_FAILURE:
            return Object.assign({}, state, {
                isDepartmentListLoading: false,
                departmentListErrorMsg: action.response.data.message,
                departmentList: [],
                departmentSuccessMsg: '',
            });
        case DELETE_DEPARTMENT_LIST_REQUEST:
            return Object.assign({}, state, {
                deleteDepartmentErrorMsg: '',
                deleteDepartmentSuccessMsg: '',
            });
        case DELETE_DEPARTMENT_LIST_SUCCESS:
            return Object.assign({}, state, {
                deleteDepartmentErrorMsg: '',
                deleteDepartmentSuccessMsg: action.response.data.message,
                getDeptAfterDelete: true,
            });
        case DELETE_DEPARTMENT_LIST_FAILURE:
            return Object.assign({}, state, {
                deleteDepartmentErrorMsg: action.response.data.message,
                deleteDepartmentSuccessMsg: '',
            });
        case CLEAR_DEPARTMENT_DATA:
            return Object.assign({}, state, {
                deleteDepartmentErrorMsg: '',
                deleteDepartmentSuccessMsg: '',
                createDepartmentErrorMsg: '',
                createDepartmentSuccessMsg: '',
                department: {
                    name: '',
                    short_name: '',
                },
            });
        case UPDATE_DEPARTMENT_DATA:
            return Object.assign({}, state, {
                department: action.data,
            });
        case CREATE_DEPARTMENT_REQUEST:
            return Object.assign({}, state, {
                createDepartmentErrorMsg: '',
                createDepartmentSuccessMsg: '',
            });
        case CREATE_DEPARTMENT_SUCCESS:
            return Object.assign({}, state, {
                createDepartmentErrorMsg: '',
                createDepartmentSuccessMsg: action.response.data.message,
            });
        case CREATE_DEPARTMENT_FAILURE:
            return Object.assign({}, state, {
                createDepartmentErrorMsg: action.response.data.message,
                createDepartmentSuccessMsg: '',
            });
        case CLEAR_DEPARTMENT_ERROR_DATA:
            return Object.assign({}, state, {
                createDepartmentErrorMsg: '',
                createDepartmentSuccessMsg: '',
                updateDepartmentErrorMsg: '',
                updateDepartmentSuccessMsg: '',
            });
        case EDIT_DEPARTMENT_DIALOG_STATE:
            return Object.assign({}, state, {
                editDepartmentPopupState: action.status,
            });
        case SET_DEPARTMENT_INFO:
            return Object.assign({}, state, {
                departmentData: action.data,
            });
        case UPDATE_DEPARTMENT_REQUEST:
            return Object.assign({}, state, {
                updateDepartmentErrorMsg: '',
                updateDepartmentSuccessMsg: '',
            });
        case UPDATE_DEPARTMENT_SUCCESS:
            return Object.assign({}, state, {
                updateDepartmentErrorMsg: '',
                updateDepartmentSuccessMsg: action.response.data.message,
                getListAfterUpdate: true,
            });
        case UPDATE_DEPARTMENT_FAILURE:
            return Object.assign({}, state, {
                updateDepartmentErrorMsg: action.response.data.message,
                updateDepartmentSuccessMsg: '',
                getListAfterUpdate: false,
            });
        default:
            return state;
    }
}
