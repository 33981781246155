import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import {
    addNewEmployee,
    removeEmployee,
    updateEmployeeList,
    handleInviteEmployee,
    clearEmpoyeeData,
} from '../../../actions/admin/employeeActions';
import CSVReader from 'react-csv-reader';
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

let employeeRoleList = [
    {
        label: 'L1',
        value: 'L1',
    },
    {
        label: 'L2',
        value: 'L2',
    },
    {
        label: 'L3',
        value: 'L3',
    },
];
class InviteEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csvError: false,
            csvErrorMsg:
                'Invalid CSV file, Please Upload a file as per the Sample CSV File.',
        };
    }

    handleAddNewEmpoyee(e) {
        this.props.dispatch(addNewEmployee({ email: '', role: '' }));
    }
    handleRemoveEmployee(index) {
        const list = this.props.inviteEmployeeList;
        list.splice(index, 1);
        this.props.dispatch(removeEmployee(list));
        this.forceUpdate();
    }
    handleCSVFileUpload(employee) {
        const csvEmployeeList = [];
        let pattern =
            /^(([-\w\d]+)(\.[-\w\d]+)*@((?!gmail.)(?!yahoo.)(?!ymail.)(?!hotmail.)[-\w\d]+)(\.[-\w\d]+)*(\.([a-zA-Z]{2,}|[\d]{1,})){1,2})$/;

        for (let i = 0; i < employee.length - 1; i++) {
            const checkmail = pattern.test(
                String(employee[i]['0']).toLowerCase()
            );
            if (checkmail === false) {
                this.setState({
                    csvError: true,
                    csvErrorMsg:
                        'CSV file Contain invalid Business Email addresses. Please verify and try again.',
                });
                break;
            } else {
                csvEmployeeList.push({
                    email: employee[i]['0'],
                    level: employee[i]['1'],
                });
                this.setState({
                    csvError: false,
                    csvErrorMsg: '',
                });
            }
        }

        if (csvEmployeeList.length === employee.length - 1) {
            this.props.dispatch(updateEmployeeList(csvEmployeeList));
            this.setState({
                csvError: false,
                csvErrorMsg: '',
            });
        } else {
            this.setState({
                csvError: true,
                csvErrorMsg:
                    'Invalid CSV file, Please Upload a file as per the Sample CSV File.',
            });
        }
    }
    handleCSVUploadError(e) {
        this.setState({
            csvError: true,
            csvErrorMsg:
                'Invalid CSV file, Please Upload a file as per the Sample CSV File.',
        });
    }

    checkDuplicateInObject(propertyName, inputArray) {
        var duplicate = false,
            testObject = {};
        inputArray.map(function (item) {
            var itemPropertyName = item[propertyName].toLowerCase();
            if (item.email !== '') {
                if (itemPropertyName in testObject) {
                    testObject[itemPropertyName].duplicate = true;
                    item.duplicate = true;
                    duplicate = true;
                } else {
                    testObject[itemPropertyName] = item;
                    item.duplicate = false;
                    duplicate = false;
                }
            }
        });
        return duplicate;
    }

    handleChange(e, index) {
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }

        let name = e.target.name;
        let value = e.target.value;
        let employeeList = this.props.inviteEmployeeList;
        employeeList[index][name] = value;

        this.checkDuplicateInObject('email', employeeList);
        this.props.dispatch(updateEmployeeList(employeeList));
    }

    handleInviteSubmit(e) {
        this.props.dispatch(updateEmployeeList(this.props.inviteEmployeeList));
        //if invitation form is valid then submit the data, else show the validation message on the invalid field.
        e.preventDefault();
        if (
            e.target.checkValidity() &&
            !this.checkDuplicateInObject('email', this.props.inviteEmployeeList)
        ) {
            let data = {
                invite: this.props.inviteEmployeeList,
            };
            this.props.dispatch(handleInviteEmployee(data));
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add(
                        'has-error'
                    );
                }
            }
        }
    }

    handleBackToInvite() {
        this.props.dispatch(clearEmpoyeeData());
        const employeeList = [
            { email: '', level: '' },
            { email: '', lavel: '' },
        ];
        this.props.dispatch(updateEmployeeList(employeeList));
    }
    handleBackToListing() {
        this.props.history.push('/admin/employee/l1');
    }

    render() {
        const {
            inviteEmployeeList,
            employeeAddedStatusList,
            employeeErrorMessage,
        } = this.props;
        return (
            <main className="main-content-wrapper">
                <section className="dashboard-content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6">
                                <div className="dashboard-content-header-left">
                                    <h2 className="content-header-title">
                                        Invite New Members
                                    </h2>
                                    <p className="content-header-description">
                                        Invite member to join your team.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6">
                                <div className="dashboard-content-header-right dashboard-header-action-group"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="dashboard-body-wrapper">
                    <div className="container">
                        <div className="main-content-body">
                            <section className="content-section-wrapper">
                                <div className="row">
                                    {isEmpty(employeeAddedStatusList) ===
                                    true ? (
                                        <div className="col-12 col-sm-12 col-md-9 col-lg-9">
                                            <div className="invite-employee-form-outer content-section-card">
                                                <div className="invite-employee-form-item">
                                                    <div className="row">
                                                        <div
                                                            className="col-sm-6"
                                                            style={{
                                                                paddingBottom:
                                                                    '10px',
                                                            }}
                                                        >
                                                            <label>
                                                                Email Address
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label>Level</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form
                                                    className="invite-employee-form"
                                                    noValidate={true}
                                                    id="invute-employee-form"
                                                    onSubmit={this.handleInviteSubmit.bind(
                                                        this
                                                    )}
                                                >
                                                    <div className="invite-employee-form-list">
                                                        {inviteEmployeeList.map(
                                                            (
                                                                employee,
                                                                index
                                                            ) => (
                                                                <div className="invite-employee-form-item">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <input
                                                                                    type="email"
                                                                                    placeholder="name@example.com"
                                                                                    name={
                                                                                        'email'
                                                                                    }
                                                                                    value={
                                                                                        employee.email
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.handleChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    className="form-control"
                                                                                    required={
                                                                                        true
                                                                                    }
                                                                                    id={
                                                                                        'email-' +
                                                                                        index
                                                                                    }
                                                                                    maxLength={
                                                                                        50
                                                                                    }
                                                                                    minLength={
                                                                                        3
                                                                                    }
                                                                                    autoComplete={
                                                                                        'off'
                                                                                    }
                                                                                    pattern={
                                                                                        '([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}'
                                                                                    }
                                                                                />
                                                                                {employeeAddedStatusList[
                                                                                    index
                                                                                ] ===
                                                                                false ? (
                                                                                    <span className="member-status-check exist">
                                                                                        Already
                                                                                        Exist!
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {employeeAddedStatusList[
                                                                                    index
                                                                                ] ===
                                                                                true ? (
                                                                                    <span className="member-status-check added">
                                                                                        Added!
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {inviteEmployeeList[
                                                                                    index
                                                                                ]
                                                                                    .duplicate ===
                                                                                true ? (
                                                                                    <span className="member-status-check exist">
                                                                                        Duplicate
                                                                                        email
                                                                                        address.
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                <div className="help-block with-errors">
                                                                                    Please
                                                                                    enter
                                                                                    valid
                                                                                    email
                                                                                    address.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6 col-md-6">
                                                                            <div className="form-group">
                                                                                <div className="select-wrapper">
                                                                                    <select
                                                                                        id={
                                                                                            'employee-level' +
                                                                                            index
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.handleChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        name="level"
                                                                                        value={
                                                                                            employee.level
                                                                                        }
                                                                                        className="form-control"
                                                                                        required={
                                                                                            true
                                                                                        }
                                                                                    >
                                                                                        <option
                                                                                            selected
                                                                                            disabled={
                                                                                                true
                                                                                            }
                                                                                            value=""
                                                                                            defaultValue
                                                                                        >
                                                                                            Select
                                                                                            Level
                                                                                        </option>
                                                                                        {employeeRoleList.map(
                                                                                            (
                                                                                                role,
                                                                                                index
                                                                                            ) => (
                                                                                                <option
                                                                                                    value={
                                                                                                        role.value
                                                                                                    }
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        role.label
                                                                                                    }
                                                                                                </option>
                                                                                            )
                                                                                        )}
                                                                                    </select>
                                                                                    <div className="help-block with-errors">
                                                                                        Please
                                                                                        select
                                                                                        level.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {index >
                                                                        0 && (
                                                                        <span
                                                                            className="cross-icon"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                this.handleRemoveEmployee(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            )
                                                        )}
                                                        <div
                                                            className="invite-employee-form-item add-more-employee"
                                                            id="add-more-employe"
                                                        >
                                                            <div className="row">
                                                                <div className="col-sm-12 text-right">
                                                                    <div
                                                                        className="add-more-link"
                                                                        onClick={this.handleAddNewEmpoyee.bind(
                                                                            this
                                                                        )}
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                        <span className="add-more-text">
                                                                            Add
                                                                            More
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="m-2-rem light-grey-hr"></hr>
                                                    <div className="invite-employee-form-item invite-form-action">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-5 col-md-6">
                                                                <div className="form-btn-group">
                                                                    <Button
                                                                        type="submit"
                                                                        id="send-invite"
                                                                        formNoValidate={
                                                                            true
                                                                        }
                                                                        className="btn box-shadow send-invite-btn"
                                                                    >
                                                                        Send
                                                                        Invitation
                                                                    </Button>
                                                                    <Button
                                                                        className="btn box-shadow back-btn"
                                                                        onClick={() =>
                                                                            this.props.history.push(
                                                                                '/admin/employee/l1'
                                                                            )
                                                                        }
                                                                    >
                                                                        Back
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-7 col-md-6">
                                                                <div className="invite-via-csv-outer">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/members-samples/members-sample.csv"
                                                                        rel="noopener noreferrer"
                                                                        className=" add-more-link download-csv"
                                                                        target="_blank"
                                                                    >
                                                                        Download
                                                                        Sample
                                                                    </a>
                                                                    <div
                                                                        className="csv-btn-group"
                                                                        id="csv-btn-group"
                                                                    >
                                                                        <CSVReader
                                                                            cssClass="file-upload-btn btn box-shadow"
                                                                            label="Invite Via CSV"
                                                                            onFileLoaded={this.handleCSVFileUpload.bind(
                                                                                this
                                                                            )}
                                                                            onError={this.handleCSVUploadError.bind(
                                                                                this
                                                                            )}
                                                                            inputId="csv-fileUplaod"
                                                                            cssInputClass="file-input-ctrl"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.csvError && (
                                                        <div className="form-error">
                                                            <p className="error-msg">
                                                                {
                                                                    this.state
                                                                        .csvErrorMsg
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                                    {employeeErrorMessage && (
                                                        <div className="form-error">
                                                            <p className="error-msg">
                                                                {
                                                                    employeeErrorMessage
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                                </form>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                                            <div
                                                className="invite-employee-form-outer content-section-card invite-team-response"
                                                id="invite-team-response"
                                            >
                                                <section
                                                    className="dashboard-content-box"
                                                    id="dashboard-content-box"
                                                >
                                                    <div
                                                        className="invite-member-message-box"
                                                        id="invite-member-message-box"
                                                    >
                                                        {employeeAddedStatusList.successful_sents !==
                                                            undefined &&
                                                        employeeAddedStatusList
                                                            .successful_sents
                                                            .length > 0 ? (
                                                            <div className="invite-team-header">
                                                                <h3 className="invite-success">
                                                                    Invitation
                                                                    Sent
                                                                    Successfully!
                                                                </h3>
                                                                <p className="invite-success">
                                                                    You've
                                                                    successfully
                                                                    invited{' '}
                                                                    {
                                                                        employeeAddedStatusList
                                                                            .successful_sents
                                                                            .length
                                                                    }{' '}
                                                                    member(s).
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {employeeAddedStatusList.successful_sents !==
                                                            undefined &&
                                                            employeeAddedStatusList
                                                                .successful_sents
                                                                .length > 0 && (
                                                                <table
                                                                    className="invite-member-message-table sucess-table"
                                                                    id="sucess-table"
                                                                >
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>
                                                                                Email{' '}
                                                                            </th>
                                                                            <th>
                                                                                Level{' '}
                                                                            </th>
                                                                            <th>
                                                                                Message{' '}
                                                                            </th>
                                                                        </tr>
                                                                        {employeeAddedStatusList.successful_sents.map(
                                                                            (
                                                                                employee,
                                                                                index
                                                                            ) => (
                                                                                <tr
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <td data-label="Email">
                                                                                        {
                                                                                            employee.email
                                                                                        }
                                                                                    </td>
                                                                                    <td data-label="Level">
                                                                                        {
                                                                                            employee.level
                                                                                        }{' '}
                                                                                    </td>
                                                                                    <td data-label="Message">
                                                                                        {
                                                                                            employee.message
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            )}
                                                        <hr className="m-2-rem light-grey-hr"></hr>
                                                        {employeeAddedStatusList.unsuccessful_sents !==
                                                            undefined &&
                                                        employeeAddedStatusList
                                                            .unsuccessful_sents
                                                            .length > 0 ? (
                                                            <div className="invite-team-header">
                                                                <h3 className="invite-error">
                                                                    Invitation
                                                                    not Sent!
                                                                </h3>
                                                                <p className="invite-error">
                                                                    {
                                                                        employeeAddedStatusList
                                                                            .unsuccessful_sents
                                                                            .length
                                                                    }
                                                                    invitation(s)
                                                                    didn't send.
                                                                    Please
                                                                    review the
                                                                    error
                                                                    message.
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {employeeAddedStatusList.unsuccessful_sents !==
                                                            undefined &&
                                                            employeeAddedStatusList
                                                                .unsuccessful_sents
                                                                .length > 0 && (
                                                                <table
                                                                    className="invite-member-message-table error-table"
                                                                    id="error-table"
                                                                >
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>
                                                                                Email{' '}
                                                                            </th>
                                                                            <th>
                                                                                Level{' '}
                                                                            </th>
                                                                            <th>
                                                                                Message{' '}
                                                                            </th>
                                                                        </tr>
                                                                        {employeeAddedStatusList.unsuccessful_sents.map(
                                                                            (
                                                                                employee,
                                                                                index
                                                                            ) => (
                                                                                <tr
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    <td data-label="Email">
                                                                                        {
                                                                                            employee.email
                                                                                        }
                                                                                    </td>
                                                                                    <td data-label="Level">
                                                                                        {
                                                                                            employee.level
                                                                                        }{' '}
                                                                                    </td>
                                                                                    <td data-label="Message">
                                                                                        {
                                                                                            employee.message
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            )}
                                                    </div>
                                                    <div className="invite-success-action">
                                                        <div className="form-btn-group xd-btn-group">
                                                            <Button
                                                                className="btn box-shadow send-invite-btn"
                                                                onClick={this.handleBackToInvite.bind(
                                                                    this
                                                                )}
                                                            >
                                                                Invite More
                                                                Employee
                                                            </Button>

                                                            <Button
                                                                className="btn box-shadow send-invite-btn"
                                                                onClick={this.handleBackToListing.bind(
                                                                    this
                                                                )}
                                                            >
                                                                View All
                                                                Employee
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        inviteEmployeeList,
        employeeAddedStatusList,
        employeeErrorMessage,
    } = state.employeeReducer;
    return {
        inviteEmployeeList,
        employeeAddedStatusList,
        employeeErrorMessage,
    };
}

export default withRouter(connect(mapStateToProps)(InviteEmployee));
