import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import './dist/style.css';
import Pagination from 'react-js-pagination';
import { Dialogbox } from 'xd-react-dialogbox';
import Modal from '../../../components/app/modal/Modal';
import {
    getModuleList,
    changePageNumber,
} from '../../../actions/admin/internModuleActions';
import {
    storeSelectedModuleData,
    clearSelectedModuleData,
    storeAllModules,
    removeSelectedModuleData,
    getModuleSearchList,
} from '../../../actions/admin/employeeActions';
import createNotification from '../../../components/app/notification/createNotification';
class ListAsignee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            showFeedbackModal: false,
            isAllModuleSelected: false,
            moduleListData: [],
            isSingleModuleSelected: '',
            searchText: '',
            activePage: 1,
            srNo:1
        };
        this.submitWarningDialog = this.submitWarningDialog.bind(this);
    }
    componentWillMount() {
        
        const data = {
            page: 1,
            level: this.props.internsLevel,
        };
        this.props.dispatch(getModuleList(data));
        this.props.dispatch(changePageNumber(1));
        this.props.dispatch(clearSelectedModuleData());
    }
    
    componentDidMount() {
        
        let list = [];
        if (this.props.employeeList.length === 0) {
            list = this.props.internsList;
        } else {
            list = this.props.employeeList;
        }
        if (this.props.internsLevel === 'L2') {
            const isDepartmentEqual = list.every(
                (val, i, arr) => val.department === arr[0].department
            );
            if (isDepartmentEqual === false) {
                this.openWarningDialog();
            }
        }
    }

    handleSearchBar(e) {
        this.state.searchText = e.target.value;
    }
    handleKeyPress(e) {
        
        const moduleData = {
            level: this.props.internsLevel,
            page: this.state.activePage,
        };
        if (e.key === 'Enter') {
            this.props.dispatch(
                getModuleList(moduleData, this.state.searchText)
            );
        }
    }
    handleSearchBarOnClick() {
        const moduleData = {
            level: this.props.internsLevel,
            page: this.state.activePage,
        };
         this.props.dispatch(getModuleList(moduleData, this.state.searchText));
    }
    showModal(e, id) {
        this.setState({ showFeedbackModal: true, value: '' });
    }
    hideModal = () => {
        this.setState({ showFeedbackModal: false });
    };

    checkAllCheckbox(e) {
        var checkboxes = document.getElementsByName('module');
        this.setState({ moduleListData: [] });
        const isChecked = !this.state.isAllModuleSelected;
        this.setState({ isAllModuleSelected: isChecked });
        for (var checkbox of checkboxes) {
            checkbox.checked = isChecked;
        }
        let list = [];
        if (isChecked) {
            this.props.moduleList.map((m, i) => {
                list.push(m);
            });
            this.setState({ moduleListData: list });
        }
        this.props.dispatch(storeAllModules(list));
    }
    checkBoxSelectionEvent(e, module) {
        let newSelectedModuleList = this.state.moduleListData;
        if ((newSelectedModuleList.length == 0)) {
            newSelectedModuleList.push(module);
        } else {
            const isModuleAvailable = newSelectedModuleList.filter((selectedModule)=> 
            selectedModule.name == module.name
            ).length > 0 

            this.state.moduleListData.forEach((currentModule, index) => {
                if(isModuleAvailable && currentModule.name == module.name){
                    newSelectedModuleList.splice(index, 1);
                    const deleteIndex = this.props.selectedModules.indexOf(module);
                    if (deleteIndex > -1){
                        this.props.dispatch(removeSelectedModuleData(deleteIndex));        
                    }
                }
            });
                if(!isModuleAvailable)newSelectedModuleList.push(module);
         }
         this.setState({ moduleListData: newSelectedModuleList });
        if (e.target.checked) {
            this.props.dispatch(storeSelectedModuleData(module));
        } 
    }
    openWarningDialog() {
        document.querySelector('#warningDialog').classList.add('opened');
        document.querySelector('body').classList.add('has-dialog');
    }
    // submit response of dialog
    submitWarningDialog(e) {
        this.props.history.push('/admin/employee/l2');
        document.querySelector('#warningDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    // closes the dialog
    closeDialogbox() {
        document.querySelector('#warningDialog').classList.remove('opened');
        document.querySelector('body').classList.remove('has-dialog');
    }
    handlePageChange(pageNumber) {
        this.setState({srNo:pageNumber})
        this.props.dispatch(changePageNumber(pageNumber));
        const data = {
            page: pageNumber,
            level: this.props.internsLevel,
        };
        this.props.dispatch(getModuleList(data,this.state.searchText));
        this.forceUpdate();
    }
    render() {
        window.onbeforeunload = function () {
            return 'Are you sure you want to leave?';
        };
        let list = [];
        list = this.props.employeeList;

        window.onbeforeunload = function () {
            this.alert('do you want to reload');
        };
        return (
            <React.Fragment>
                <main className="main-content-wrapper">
                    <section className="dashboard-content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-left">
                                        <h2 className="content-header-title">
                                            Assign Modules
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-right dashboard-header-action-group"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="dashboard-body-wrapper">
                        <div className="container">
                            <div className="main-content-body">
                                <section className="content-section-wrapper">
                                    <div className="table-wrapper">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-6">
                                                <div className="dashboard-content-header-left">
                                                    <div className="heading">
                                                        Selected Interns
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="table-container ">
                                                <table className="listing-table ">
                                                    <thead>
                                                        <tr>
                                                            <th className="intern-name">
                                                                Sr. No
                                                            </th>
                                                            <th className="intern-name">
                                                                Name
                                                            </th>
                                                            <th className="email">
                                                                Email
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {list.length > 0 &&
                                                            list.map((l, i) => (
                                                                <tr>
                                                                    <td className="content intern-name">
                                                                        {i + 1}.
                                                                    </td>
                                                                    <td className="content email">
                                                                        {' '}
                                                                        <b>
                                                                            {
                                                                                l.name
                                                                            }
                                                                        </b>{' '}
                                                                        <br />
                                                                        {this
                                                                            .props
                                                                            .internsLevel ===
                                                                            'L2' && (
                                                                            <span
                                                                                style={{
                                                                                    fontSize:
                                                                                        '11px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    l.department
                                                                                }
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td className="content task">
                                                                        {
                                                                            l.email
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                    {list.length === 0 && (
                                                         this.props.history.push(
                                                            '/admin/employee/L1'
                                                        )
                                                    )}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="content-section-wrapper">
                                <div className='table-container '>

                                
                                    <div className="table-wrapper">
                                        
                                        <div className="heading">
                                            Select Modules
                                        </div>
                                        <div className="module-search-bar">
                                    <div
                                        class="form-group module-search"
                                        style={{
                                            position: 'relative',
                                            right: '15px',
                                            bottom: '5px',
                                        }}
                                    >
                                        <input
                                            type="text"
                                            name="searchText"
                                            class="form-control"
                                            placeholder="Search name and press enter.."
                                            autocomplete="off"
                                            defaultValue={this.state.searchText}
                                            onChange={this.handleSearchBar.bind(
                                                this
                                            )}
                                            onKeyDown={this.handleKeyPress.bind(
                                                this
                                            )}
                                        ></input>
                                        <button
                                            onClick={this.handleSearchBarOnClick.bind(
                                                this
                                            )}
                                            className="candidate-search-functionality"
                                        >
                                            <img
                                                src={require('../../../static/images/onboarding-search.svg')}
                                            />
                                            <img
                                                src={require('../../../static/images/onboarding-search-mobile.svg')}
                                                className="onboarding-search-mobile"
                                            />
                                        </button>
                                    </div>
                                </div>
                                        <div>
                                            <div className="table-container ">
                                                <table className="listing-table ">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <input
                                                                name="allValueCheckbox"
                                                                    type="checkbox"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.checkAllCheckbox(
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </th>
                                                            <th className="task">
                                                                Sr. No
                                                            </th>
                                                            <th className="email">
                                                                Name
                                                            </th>
                                                            {this.props
                                                                .internsLevel ===
                                                                'L2' && (
                                                                <th
                                                                    className="module-department"
                                                                    style={{
                                                                        lineBreak:
                                                                            'break-all',
                                                                    }}
                                                                >
                                                                    Department
                                                                </th>
                                                            )}
                                                             <th className="module-level">
                                                                Level
                                                            </th>
                                                            <th className="email">
                                                                Description
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!this.props
                                                            .getModulePageLoading &&
                                                        this.props.moduleList
                                                            .length > 0 ? (
                                                            this.props.moduleList.map(
                                                                (m, i) => (
                                                                    <tr>
                                                                        <td>
                                                                            <input
                                                                                type="checkbox"
                                                                                onClick={(
                                                                                    e
                                                                                ) =>
                                                                                    this.checkBoxSelectionEvent(
                                                                                        e,
                                                                                        m
                                                                                    )
                                                                                }
                                                                                name="module"
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            className={`content ${
                                                                                this
                                                                                    .props
                                                                                    .internsLevel ===
                                                                                'L2'
                                                                                    ? 'task'
                                                                                    : 'email'
                                                                            }`}
                                                                        >
                                                                            {this.state.srNo === 0 ? (i) : ((this.state.srNo -1)*10 + i)+1}
                                                                            .
                                                                        </td>
                                                                        <td className="content email">
                                                                            {' '}
                                                                            {
                                                                                m.name
                                                                            }
                                                                        </td>
                                                                        {this
                                                                            .props
                                                                            .internsLevel ===
                                                                            'L2' && (
                                                                            <td className="content module-department">
                                                                                {' '}
                                                                                {m.department_name
                                                                                    ? m.department_name
                                                                                    : 'N/A'}
                                                                            </td>
                                                                        )}
                                                                        <td className="content level">
                                                                            {
                                                                                m.level
                                                                            }
                                                                        </td>
                                                                        <td className="content email">
                                                                            {
                                                                                m.description
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )
                                                        ) : (
                                                            <p className="module-not-present-message">
                                                                No Module is
                                                                present
                                                            </p>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </section>
                                <div
                                    className="row"
                                    style={{ margin: '0px 0px 10px 0px' }}
                                >
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="react-xd-pagination-container">
                                            <div className="pagination-outer">
                                                <div className="pagination-group">
                                                    <Pagination
                                                        firstPageText={'First'}
                                                        lastPageText="Last"
                                                        prevPageText={
                                                            <i className="fa fa-angle-left" />
                                                        }
                                                        nextPageText={
                                                            <i className="fa fa-angle-right" />
                                                        }
                                                        activePage={
                                                            this.props
                                                                .internTablePageNumber
                                                        }
                                                        itemsCountPerPage={10
                                                        }
                                                        totalItemsCount={
                                                            this.props
                                                                .moduleCount
                                                        }
                                                        pageRangeDisplayed={8}
                                                        onChange={this.handlePageChange.bind(
                                                            this
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="xd-btn-group"
                                style={{ marginLeft: '7px' }}
                            >
                                <Button
                                    onClick={() =>
                                        this.props.history.push(
                                            '/admin/employee/L1'
                                        )
                                    }
                                    className="btn "
                                >
                                    Back
                                </Button>
                                <Button
                                    disabled={
                                        list.length <= 0 ||
                                        this.state.moduleListData.length <= 0
                                    }
                                    type="submit"
                                    onClick={() =>
                                        this.props.history.push(
                                            '/admin/employee/assign-date'
                                        )
                                    }
                                >
                                    Next
                                </Button>
                            </div>
                            <Dialogbox
                                title="Warning"
                                successButton="Back"
                                cancelButton="Continue"
                                submitDialog={(e) =>
                                    this.submitWarningDialog(e)
                                }
                                closeDialog={this.closeDialogbox}
                                dialogBoxID="warningDialog"
                            >
                                <p>
                                    You have selected interns of different
                                    departments. The selected modules would be
                                    assigned to all of these interns.
                                </p>
                            </Dialogbox>
                        </div>
                    </div>
                    <Modal
                        size="500px"
                        showModal={this.state.showFeedbackModal}
                        outsideClose={false}
                        closeModal={this.hideModal}
                        title="Add Intern"
                        hasFooter={false}
                    >
                        <div>
                            <form
                                className="submit-close"
                                id="submitClose"
                                name="submitClose"
                                noValidate={true}
                            >
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Select Intern{' '}
                                                        <span className="req">
                                                            *
                                                        </span>{' '}
                                                    </label>
                                                    <div className="select-wrapper">
                                                        <select
                                                            id="years"
                                                            name="year"
                                                            className="form-control"
                                                            required={true}
                                                        >
                                                            <option
                                                                disabled={true}
                                                                selected
                                                                value=""
                                                                defaultValue
                                                            >
                                                                Select any
                                                                Intern
                                                            </option>
                                                            <option>
                                                                Neha Sharma
                                                            </option>
                                                        </select>
                                                        <div className="help-block with-errors">
                                                            Please select any
                                                            Intern
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="xd-btn-group"
                                            style={{ marginLeft: '12px' }}
                                        >
                                            <button
                                                type="button"
                                                onClick={this.hideModal.bind(
                                                    this
                                                )}
                                                className="btn btn-outline btn-compact"
                                            >
                                                {' '}
                                                Cancel{' '}
                                            </button>
                                            <button
                                                type="submit"
                                                disabled={true}
                                                id="submitCloseTicket"
                                                className="btn btn-compact"
                                            >
                                                Add Intern
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </main>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { internsList, employeeList, selectedModules, internsLevel } =
        state.employeeReducer;
    const {
        moduleList,
        getModulePageLoading,
        moduleCount,
        internTablePageNumber,
    } = state.moduleReducer;
    return {
        internsList,
        employeeList,
        selectedModules,
        getModulePageLoading,
        moduleList,
        internsLevel,
        moduleCount,
        internTablePageNumber,
    };
}

export default withRouter(connect(mapStateToProps)(ListAsignee));
