import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/dashboard-list.css';
import {
    getInternCountL1,
    getInternCountL2,
    getInternCountL3,
} from '../../../actions/admin/employeeActions';

const l1InternCount = {
    level: 'L1',
    page: 1,
    limit: 10,
};

const l2InternCount = {
    level: 'L2',
    page: 1,
    limit: 10,
};

const l3InternCount = {
    level: 'L3',
    page: 1,
    limit: 10,
};
class Dashboardlist extends Component {
    componentWillMount() {
        this.props.dispatch(getInternCountL1(l1InternCount));
        this.props.dispatch(getInternCountL2(l2InternCount));
        this.props.dispatch(getInternCountL3(l3InternCount));
    }
    render() {
        return (
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="dashboard-card">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                    <img
                                        src={require('../../../static/images/l1_intern.svg')}
                                    />
                                    <div className="info-box-content">
                                        <span className="info-box-text">
                                            L1 Interns
                                        </span>
                                        <span className="info-box-number">
                                            {this.props.internCountL1}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                    <img
                                        src={require('../../../static/images/l2_intern.svg')}
                                    />
                                    <div className="info-box-content">
                                        <span className="info-box-text">
                                            L2 Interns
                                        </span>
                                        <span className="info-box-number">
                                        {this.props.internCountL2}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                    <img
                                        src={require('../../../static/images/l3_intern.svg')}
                                    />
                                    <div className="info-box-content">
                                        <span className="info-box-text">
                                            L3 Interns
                                        </span>
                                        <span className="info-box-number">
                                        {this.props.internCountL3}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                    <img
                                        src={require('../../../static/images/planned-interviews.svg')}
                                    />
                                    <div className="info-box-content">
                                        <span className="info-box-text">
                                            Total Count
                                        </span>
                                        <span className="info-box-number">
                                        {this.props.internCountL1 + this.props.internCountL2 + this.props.internCountL3}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const { internCountL1, internCountL2, internCountL3 } =
        state.employeeReducer;

    return { internCountL1, internCountL2, internCountL3 };
};
export default withRouter(connect(mapStateToProps)(Dashboardlist));
