import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';
import './style.css';
import {changeTaskStatus, handleModuleCompleteDialogChange} from '../../../actions/employee/userModuleActions';
import ModuleCompletePopup from './ModuleCompletePopup';


class TaskItem extends Component {
    constructor(props){
        super(props)
        this.state={
            show:false,
            taskID:0
        }
        this.showModal=this.showModal.bind(this)
    }
    showModal(){
        this.setState({ show: true });
    }
    hideModal(){
        this.setState({ show: false });
    }
    taskList(e)
    {   
        let moduleList=document.getElementsByClassName('task-list_item_wrapper')

        for( let i =0 ; i<moduleList.length;i++)
        {   
            moduleList[i].classList.remove('task-list_item_wrapper-active')
        }
        let parent = e.target.closest('.task-list_item_wrapper');
        parent.classList.add('task-list_item_wrapper-active')
    }
    
    taskStatusChange(e){
        const statusDetails= {
            'taskid':this.props.TaskList.id,
            'status':e.target.value
        }
        if(e.target.value=="Done"){
            let count=0
            for(let list of this.props.taskList){
                if(list.status=="Done"){
                    count++
                }
            }
            if(count==this.props.taskList.length-1){
                document.querySelector('#body-root').style="overflow:hidden;"
                
                this.props.dispatch(handleModuleCompleteDialogChange(true, statusDetails.taskid))
                
                
            }else{this.props.dispatch(changeTaskStatus(statusDetails, this.props.ModuleId ))
            }
        }else{this.props.dispatch(changeTaskStatus(statusDetails, this.props.ModuleId ))
        }
    }

    render() {
        const {name,status} = this.props.TaskList
        const ModuleId=this.props.ModuleId
        return (       
        <article className="task-list_item_wrapper" onClick={this.taskList.bind(this)} >
            
            <div className="task-list_item_content">
                <div className="row">
                    <div className="col-12 col-sm-7">
                        <div className="task-list-content">
                          <div className="task-status-icon">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="task-title">
                               <h3>{name}</h3>     
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-5">
                        <div className="task-list-action">
                        <div className="task-status_action">
                            <select className={`task-status ${(status=="InComplete")?"to-do":(status=="InProgress"?"in-progress":"module-completed")}`} onChange={this.taskStatusChange.bind(this)}>
                                <option selected={status=="Incomplete"}
                                 value="InComplete">
                                To Do 
                                </option>
                                <option selected={status=="InProgress"}
                                 value="InProgress">
                                In Progress
                                </option>
                                <option selected={status=="Done"}
                                 value="Done">
                                Completed
                                </option>
                            </select>
                            
                        </div>
                        <div className="task-details_action">
                            <i className="fa fa-angle-right"></i>
                        </div>     
                        </div>
                    </div>
                </div>       
            </div>
            <ModuleCompletePopup moduleId={ModuleId}/>
        </article>
        )
    }
}

function mapStateToProps(state) {
    const {changeStatusSuccess, statusErrorMsg, taskList} = state.userModuleReducer;
    return {changeStatusSuccess, statusErrorMsg, taskList}
}

export default withRouter(connect(mapStateToProps)(TaskItem))
