export const GET_RECENTLY_JOINED_INTERNS_REQUEST = 'GET_RECENTLY_JOINED_INTERNS_REQUEST';
export const GET_RECENTLY_JOINED_INTERNS_SUCCESS = 'GET_RECENTLY_JOINED_INTERNS_SUCCESS';
export const GET_RECENTLY_JOINED_INTERNS_FAILURE = 'GET_RECENTLY_JOINED_INTERNS_FAILURE';


export const GET_NOT_JOINED_INTERNS_REQUEST = 'GET_NOT_JOINED_INTERNS_REQUEST';
export const GET_NOT_JOINED_INTERNS_SUCCESS = 'GET_NOT_JOINED_INTERNS_SUCCESS';
export const GET_NOT_JOINED_INTERNS_FAILURE = 'GET_NOT_JOINED_INTERNS_FAILURE';


export const GET_INTERN_MODULE_PROGRESS_REQUEST = 'GET_INTERN_MODULE_PROGRESS_REQUEST';
export const GET_INTERN_MODULE_PROGRESS_SUCCESS = 'GET_INTERN_MODULE_PROGRESS_SUCCESS';
export const GET_INTERN_MODULE_PROGRESS_FAILURE = 'GET_INTERN_MODULE_PROGRESS_FAILURE';


export const GET_INTERN_MODULE_DEADLINE_REQUEST = 'GET_INTERN_MODULE_DEADLINE_REQUEST';
export const GET_INTERN_MODULE_DEADLINE_SUCCESS = 'GET_INTERN_MODULE_DEADLINE_SUCCESS';
export const GET_INTERN_MODULE_DEADLINE_FAILURE = 'GET_INTERN_MODULE_DEADLINE_FAILURE';