import {
    MODULE_LIST_REQUEST,
    MODULE_LIST_SUCCESS,
    MODULE_LIST_FAILURE,
    TASK_LIST_REQUEST,
    TASK_LIST_SUCCESS,
    TASK_LIST_FAILURE,
    CHANGE_TASK_STATUS_REQUEST,
    CHANGE_TASK_STATUS_SUCCESS,
    CHANGE_TASK_STATUS_FAILURE,
    HANDLE_MODULE_COMPLETE_DIALOG_STATE,
    CLEAR_TASK_STATUS_DATA,
    HANDLE_UPLOAD_PRESENTATION_DIALOG_CHANGE,
    UPLOAD_PRESENTATION_REQUEST,
    UPLOAD_PRESENTATION_SUCCESS,
    UPLOAD_PRESENTATION_FAILURE,
    UPLOAD_PRESENTATION_LINK_REQUEST,
    UPLOAD_PRESENTATION_LINK_SUCCESS,
    UPLOAD_PRESENTATION_LINK_FAILURE,
    GET_PRESENTATION_LINK_REQUEST,
    GET_PRESENTATION_LINK_SUCCESS,
    GET_PRESENTATION_LINK_FAILURE,
    DELETE_PRESENTATION_REQUEST,
    DELETE_PRESENTATION_SUCCESS,
    DELETE_PRESENTATION_FAILURE,
    CLEAR_PRESENTATION_UPLOAD_DATA,
    CLEAR_MODULE_LIST,
    CLEAR_NOTIFICATION_DATA
} from '../../constants/employee/userModuleConstants';

const initialState = {
    isModuleListLoading: false,
    moduleListSuccess: false,
    deleteSuccessMessageRequest: false,
    moduleList: [],
    moduleSuccessMsg: '',
    moduleErrorMsg: '',
    taskId: 0,

    taskListSuccess: false,
    taskList: [],
    moduleData: {},
    taskSuccessMsg: '',
    taskErrorMsg: '',
    isTaskListLoading: false,

    changeStatusSuccess: false,
    statusErrorMsg: '',
    statusSuccessMsg: '',
    moduleCompletePopupState: false,

    uploadPresentationPopupState: false,

    presentationLink: [],
    presentationUploadError: '',
    presentationUploadSuccess: '',

    uploadPresentationLinkSuccess: false,
    presentationLinkUploadError: '',
    presentationLinkUploadSuccess: '',
    getPresentationLinkSuccess: false,
    uploadPresentationLoading: false,

    deletePresentationSuccess: false,
    deletePresentationLoading: false,
    deletePresentationSuccessMsg: '',
    deletePresentationErrorMsg: '',
    uploadPresentationAfterDelete: false,
    getDetailsAfterPresentationUpload: false,
    confirmationForPopup: false,
    dialogueForPopup: false
};
export default function userModuleReducer(state = initialState, action) {
    switch (action.type) {
        case MODULE_LIST_REQUEST:
            return Object.assign({}, state, {
                moduleListSuccess: false,
                isModuleListLoading: true,
            });
        case MODULE_LIST_SUCCESS:
            return Object.assign({}, state, {
                moduleListSuccess: true,
                moduleErrorMsg: '',
                moduleList: action.response.data.list,
                moduleSuccessMsg: action.response.data.message,
                isModuleListLoading: false,
            });
        case MODULE_LIST_FAILURE:
            return Object.assign({}, state, {
                moduleListSuccess: false,
                moduleList: '',
                moduleErrorMsg: action.response.data.message,
                isModuleListLoading: false,
            });
        case CLEAR_MODULE_LIST:
            return Object.assign({}, state, {
                moduleData: {},
            });
        case TASK_LIST_REQUEST:
            return Object.assign({}, state, {
                taskListSuccess: false,
                isTaskListLoading: true,
                getDetailsAfterPresentationUpload: false,
            });
        case TASK_LIST_SUCCESS:
            return Object.assign({}, state, {
                taskListSuccess: true,
                taskList:
                    action.response.data !== undefined
                        ? action.response.data.List
                        : [],
                moduleData:
                    action.response.data !== undefined
                        ? action.response.data.module
                        : {},
                taskSuccessMsg: action.response.data.message,
                isTaskListLoading: false,
            });
        case TASK_LIST_FAILURE:
            return Object.assign({}, state, {
                taskListSuccess: false,
                taskList: [],
                moduleData: {},
                taskErrorMsg: action.response.data.message,
                isTaskListLoading: false,
            });

        case CHANGE_TASK_STATUS_REQUEST:
            return Object.assign({}, state, {
                changeStatusSuccess: false,
            });
        case CHANGE_TASK_STATUS_SUCCESS:
            return Object.assign({}, state, {
                changeStatusSuccess: true,
                statusSuccessMsg: action.response.data.message,
                statusErrorMsg: '',
            });
        case CHANGE_TASK_STATUS_FAILURE:
            return Object.assign({}, state, {
                changeStatusSuccess: false,
                statusErrorMsg: action.response.data.message,
                statusSuccessMsg: '',
            });
        case CLEAR_TASK_STATUS_DATA:
            return Object.assign({}, state, {
                changeStatusSuccess: false,
                statusErrorMsg: '',
                statusSuccessMsg: '',
            });
        case HANDLE_MODULE_COMPLETE_DIALOG_STATE:
            return Object.assign({}, state, {
                moduleCompletePopupState: action.status,
                taskId: action.id,
            });
        case HANDLE_UPLOAD_PRESENTATION_DIALOG_CHANGE:
            return Object.assign({}, state, {
                uploadPresentationPopupState: action.status,
                dialogueForPopup: true
            });
        case UPLOAD_PRESENTATION_REQUEST:
            return Object.assign({}, state, {
                presentationLink: '',
                presentationUploadError: '',
                presentationUploadSuccess: '',
                uploadPresentationAfterDelete: false,
                uploadPresentationLoading: true,
            });
        case UPLOAD_PRESENTATION_SUCCESS:
            return Object.assign({}, state, {
                presentationLink: action.response.data.uploadLink,
                presentationUploadError: '',
                presentationUploadSuccess: action.response.data.message,
                uploadPresentationLoading: false,
            });
        case UPLOAD_PRESENTATION_FAILURE:
            return Object.assign({}, state, {
                presentationLink: '',
                presentationUploadError: action.response.data.message,
                presentationUploadSuccess: '',
                uploadPresentationLoading: false,
            });
        case UPLOAD_PRESENTATION_LINK_REQUEST:
            return Object.assign({}, state, {
                uploadPresentationLinkSuccess: false,
                uploadPresentationLoading: true,
                presentationLinkUploadError: '',
                presentationLinkUploadSuccess: '',
                uploadPresentationAfterDelete: false,
            });
        case UPLOAD_PRESENTATION_LINK_SUCCESS:
            return Object.assign({}, state, {
                confirmationForPopup: true,
                uploadPresentationLinkSuccess: true,
                uploadPresentationLoading: false,
                presentationLinkUploadError: '',
                presentationLinkUploadSuccess: action.response.data.message,
                getDetailsAfterPresentationUpload: true,
            });
        case UPLOAD_PRESENTATION_LINK_FAILURE:
            return Object.assign({}, state, {
                uploadPresentationLinkSuccess: false,
                uploadPresentationLoading: false,
                presentationLinkUploadError: action.response.data.message,
                presentationLinkUploadSuccess: '',
            });
        case GET_PRESENTATION_LINK_REQUEST:
            return Object.assign({}, state, {
                getPresentationLinkSuccess: false,
                presentationLink: '',
            });
        case GET_PRESENTATION_LINK_SUCCESS:
            return Object.assign({}, state, {
                getPresentationLinkSuccess: true,
                presentationLink: action.response.link,
            });
        case GET_PRESENTATION_LINK_FAILURE:
            return Object.assign({}, state, {
                getPresentationLinkSuccess: false,
                presentationLink: '',
            });
        case DELETE_PRESENTATION_REQUEST:
            return Object.assign({}, state, {
                deletePresentationSuccess: false,
                deletePresentationSuccessMsg: '',
                deletePresentationErrorMsg: '',
                deletePresentationLoading: true,
                deleteSuccessMessageRequest: false
            });
        case DELETE_PRESENTATION_SUCCESS:
            return Object.assign({}, state, {
                deletePresentationSuccess: true,
                deletePresentationSuccessMsg: action.response.data.message,
                deletePresentationErrorMsg: '',
                deleteSuccessMessageRequest: true,
                uploadPresentationAfterDelete: true,
                deletePresentationLoading: false,
            });
        case DELETE_PRESENTATION_FAILURE:
            return Object.assign({}, state, {
                deleteSuccessMessageRequest: false,
                deletePresentationSuccess: false,
                deletePresentationSuccessMsg: '',
                deletePresentationErrorMsg: action.response.data.message,
                deletePresentationLoading: false,
            });
        case CLEAR_PRESENTATION_UPLOAD_DATA:
            return Object.assign({}, state, {
                uploadPresentationLinkSuccess: false,
                presentationLinkUploadError: '',
                presentationLinkUploadSuccess: '',
                getDetailsAfterPresentationUpload: false,
            });
        case CLEAR_NOTIFICATION_DATA:{
            return Object.assign({}, state, {
                presentationUploadSuccess: ''
            });
        }
        default:
            return state;
    }
}
