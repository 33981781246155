import {
    ADD_INVITE_EMPLOYEE,
    REMOVE_INVITE_EMPLOYEE,
    UPDTAE_INVITE_EMPLOYEE_FORM,
    INVITE_EMPLOYEE_REQUEST,
    INVITE_EMPLOYEE_SUCCESS,
    INVITE_EMPLOYEE_FAILURE,
    CLEAR_EMPLOYEE_DATA,
    GET_INTERN_COUNT_L1_REQUEST,
    GET_INTERN_COUNT_L1_SUCCESS,
    GET_INTERN_COUNT_L1_FAILURE,
    GET_INTERN_COUNT_L2_REQUEST,
    GET_INTERN_COUNT_L2_SUCCESS,
    GET_INTERN_COUNT_L2_FAILURE,
    GET_INTERN_COUNT_L3_REQUEST,
    GET_INTERN_COUNT_L3_SUCCESS,
    GET_INTERN_COUNT_L3_FAILURE,
    GET_INTERN_LIST_REQUEST,
    GET_INTERN_LIST_SUCCESS,
    GET_INTERN_LIST_FAILURE,
    DELETE_INTERN_REQUEST,
    DELETE_INTERN_SUCCESS,
    DELETE_INTERN_FAILURE,
    GET_USER_PROFILE_REQUEST,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
    DEACTIVATE_ACCOUNT_REQUEST,
    DEACTIVATE_ACCOUNT_SUCCESS,
    CHANGE_LEVEL_REQUEST,
    CHANGE_LEVEL_SUCCESS,
    CHANGE_LEVEL_FAILURE,
    EXPORT_CSV_REQUEST,
    EXPORT_CSV_SUCCESS,
    EXPORT_CSV_FAILURE,
    GET_LINK_REQUEST,
    GET_LINK_SUCCESS,
    GET_LINK_FAILURE,
    DEACTIVATE_ACCOUNT_FAILURE,
    STORE_SELECTED_EMPLOYEE,
    STORE_SELECTED_MODULE,
    UPDATE_SELECTED_MODULE,
    POST_ASSIGN_MODULE_REQUEST,
    POST_ASSIGN_MODULE_SUCCESS,
    POST_ASSIGN_MODULE_FAILURE,
    CLEAR_SELECTED_MODULE_LIST,
    REMOVE_SELECTED_MODULE_DATA,
    STORE_ALL_MODULES,
    CLEAR_SELECTED_EMAIL_LIST,
    CLEAR_ASSIGN_MODULE,
    CLEAR_DEACTIVATE_ACCOUNT_DATA,
    REMOVE_SELECTED_EMPLOYEE,
    STORE_ALL_INTERNS,
    CLEAR_CHANGE_LEVEL_DATA,
    SET_INTERNS_LEVEL,
    INTERN_CHECKED,
    INTERN_NOT_CHECKED,
} from '../../constants/admin/EmployeeConstants';

const initialState = {
    inviteEmployeeList: [
        {
            email: '',
            level: '',
        },
        {
            email: '',
            level: '',
        },
    ],
    employeePageLoading: false,
    employeeSuccessMessage: '',
    employeeErrorMessage: '',
    employeeAddedStatusList: {},
    isListLoading: false,
    isPageLoading: false,
    internListError: '',
    internListSuccess: '',
    internsList: [],
    internCount: 0,
    userProfileData: {},
    userProfileError: '',
    userProfileSuccess: '',
    isProfileLoading: false,
    profileSuccess: false,
    deactivateErrorMsg: '',
    deactivateSuccessMsg: '',

    deleteInternMessage: '',
    deleteInternPageLoading: false,
    deleteInternError: '',

    changeLevelSuccess: '',
    changeLevelFailure: '',
    getReportsAfterLevelChange: false,

    getInternCountL1PageLoading: false,
    getInternCountL1Message: '',
    getInternCountL1Error: '',
    internCountL1: 0,

    getInternCountL2PageLoading: false,
    getInternCountL2Message: '',
    getInternCountL2Error: '',
    internCountL2: 0,

    getInternCountL3PageLoading: false,
    getInternCountL3Message: '',
    getInternCountL3Error: '',
    internCountL3: 0,

    exportSuccess: false,
    csvList: '',
    getLinkSuccess: false,
    documentLink: '',

    employeeList: [],
    selectedModules: [],

    module: [],
    postAssignPageLoading: false,
    postAssignSuccessMessage: '',
    postAssignError: '',

    assignModuleSuccessList: [],
    assignModuleErrorList: [],
    internsLevel: '',
    internSelected: false
};

export default function employeeReducer(state = initialState, action) {
    switch (action.type) {
        case INTERN_CHECKED:
            return Object.assign({}, state, {
                internSelected: true
            })
           
            case INTERN_NOT_CHECKED:
                return Object.assign({}, state, {
                    internSelected: false
                })    
        case ADD_INVITE_EMPLOYEE:
            return Object.assign({}, state, {
                inviteEmployeeList: state.inviteEmployeeList.concat(
                    action.employee
                ),
            });

        case STORE_SELECTED_EMPLOYEE:
            return Object.assign({}, state, {
                employeeList: state.employeeList.concat(action.employee),
            });
        case REMOVE_SELECTED_EMPLOYEE:
            if (action.index > -1) {
                state.employeeList.splice(action.index, 1);
                return Object.assign({}, state, {
                    employeeList: state.employeeList,
                });
            }
            break;
        case STORE_ALL_INTERNS:
            return Object.assign({}, state, {
                employeeList: action.interns,
            });
        case STORE_SELECTED_MODULE:
            return Object.assign({}, state, {
                selectedModules: state.selectedModules.concat(action.module),
            });
        case REMOVE_SELECTED_MODULE_DATA:
            if (action.index > -1) {
                state.selectedModules.splice(action.index, 1);
                return Object.assign({}, state, {
                    selectedModules: state.selectedModules,
                });
            }
            break;
        case STORE_ALL_MODULES:
            return Object.assign({}, state, {
                selectedModules: action.modules,
            });
        case UPDATE_SELECTED_MODULE:
            return Object.assign({}, state, {
                module: action.module,
            });
        case REMOVE_INVITE_EMPLOYEE:
            return Object.assign({}, state, {
                inviteEmployeeList: action.employee,
            });
        case UPDTAE_INVITE_EMPLOYEE_FORM:
            return Object.assign({}, state, {
                inviteEmployeeList: action.employee,
            });
        case CLEAR_SELECTED_MODULE_LIST:
            return Object.assign({}, state, {
                selectedModules: [],
                module: [],
            });
        case CLEAR_SELECTED_EMAIL_LIST:
            return Object.assign({}, state, {
                employeeList: [],
            });
        case POST_ASSIGN_MODULE_REQUEST:
            return Object.assign({}, state, {
                postAssignPageLoading: true,
                postAssignSuccessMessage: '',
                postAssignError: false,
            });
        case POST_ASSIGN_MODULE_SUCCESS:
            return Object.assign({}, state, {
                postAssignPageLoading: false,
                postAssignSuccessMessage: action.response.data.message,
                postAssignError: false,
                assignModuleSuccessList: action.response.data.successful,
                assignModuleErrorList: action.response.data.unsuccessful,
            });
        case POST_ASSIGN_MODULE_FAILURE:
            return Object.assign({}, state, {
                postAssignPageLoading: false,
                postAssignErrorMessage: action.response.data.message,
                postAssignError: true,
            });
        case INVITE_EMPLOYEE_REQUEST:
            return Object.assign({}, state, {
                employeePageLoading: true,
                employeeSuccessMessage: '',
                employeeErrorMessage: '',
                employeeAddedStatusList: {},
            });
        case INVITE_EMPLOYEE_SUCCESS:
            return Object.assign({}, state, {
                employeePageLoading: false,
                employeeSuccessMessage: '',
                employeeAddedStatusList: {
                    successful_sents: action.response.data.successful_sents,
                    unsuccessful_sents: action.response.data.unsuccessful_sents,
                },
            });
        case INVITE_EMPLOYEE_FAILURE:
            return Object.assign({}, state, {
                employeePageLoading: false,
                employeeErrorMessage: action.response.data.message,
                employeeAddedStatusList: {},
            });

        case CLEAR_EMPLOYEE_DATA:
            return Object.assign({}, state, {
                employeeAddedStatusList: {},
                employeePageLoading: false,
                employeeSuccessMessage: '',
                employeeErrorMessage: '',
                inviteEmployeeList: [
                    { email: '', level: '' },
                    { email: '', level: '' },
                ],
            });

        case GET_INTERN_LIST_REQUEST:
            return Object.assign({}, state, {
                isListLoading: true,
                internListError: '',
                internListSuccess: '',
                deleteInternMessage: '',
                deleteInternError: '',
                getReportsAfterLevelChange: false,
            });
        case GET_INTERN_LIST_SUCCESS:
            const list = action.response.data.list;
            return Object.assign({}, state, {
                isListLoading: false,
                internCount: action.response.data.count,
                internsList: list,
                internListError: '',
                internListSuccess: action.response.data.message,
            });
        case GET_INTERN_LIST_FAILURE:
            return Object.assign({}, state, {
                isListLoading: false,
                internListError: action.response.data.message,
                internsList: [],
            });

        case GET_INTERN_COUNT_L1_REQUEST:
            return Object.assign({}, state, {
                getInternCountL1PageLoading: true,
                getInternCountL1Message: '',
                getInternCountL1Error: '',
                internCountL1: '',
            });
        case GET_INTERN_COUNT_L1_SUCCESS:
            return Object.assign({}, state, {
                getInternCountL1PageLoading: false,
                getInternCountL1Message: action.response.data.message,
                getInternCountL1Error:'',
                internCountL1: action.response.data.count,
            });
        case GET_INTERN_COUNT_L1_FAILURE:
            return Object.assign({}, state, {
                getInternCountL1PageLoading: false,
                getInternCountL1Message: action.response.data.message,
                getInternCountL1Error:true,
                internCountL1: '',
            });
            
        case GET_INTERN_COUNT_L2_REQUEST:
            return Object.assign({}, state, {
                getInternCountL2PageLoading: true,
                getInternCountL2Message: '',
                getInternCountL2Error: '',
                internCountL2: '',
            });
        case GET_INTERN_COUNT_L2_SUCCESS:
            return Object.assign({}, state, {
                getInternCountL2PageLoading: false,
                getInternCountL2Message: action.response.data.message,
                getInternCountL2Error: '',
                internCountL2: action.response.data.count,
            });
        case GET_INTERN_COUNT_L2_FAILURE:
            return Object.assign({}, state, {
                getInternCountL2PageLoading: false,
                getInternCountL2Message: action.response.data.message,
                getInternCountL2Error: true,
                internCountL2: '',
            });

        case GET_INTERN_COUNT_L3_REQUEST:
            return Object.assign({}, state, {
                getInternCountL3PageLoading: true,
                getInternCountL3Message: '',
                getInternCountL3Error: '',
                internCountL3: '',
            });
        case GET_INTERN_COUNT_L3_SUCCESS:
            return Object.assign({}, state, {
                getInternCountL3PageLoading: false,
                getInternCountL3Message: action.response.data.message,
                getInternCountL3Error: '',
                internCountL3: action.response.data.count,
            });
        case GET_INTERN_COUNT_L3_FAILURE:
            return Object.assign({}, state, {
                getInternCountL3PageLoading: false,
                getInternCountL3Message: action.response.data.message,
                getInternCountL3Error: true,
                internCountL3: '',
            });

        case DELETE_INTERN_REQUEST:
            return Object.assign({}, state, {
                deleteInternMessage: '',
                deleteInternPageLoading: true,
                deleteInternError: '',
            });
        case DELETE_INTERN_SUCCESS:
            return Object.assign({}, state, {
                deleteInternMessage: action.response.data.message,
                deleteInternPageLoading: false,
                deleteInternError: action.response.data.error,
            });

        case DELETE_INTERN_FAILURE:
            return Object.assign({}, state, {
                deleteInternMessage: action.response.data.message,
                deleteInternPageLoading: false,
                deleteInternError: true,
            });

        case GET_USER_PROFILE_REQUEST:
            return Object.assign({}, state, {
                isProfileLoading: true,
                userProfileSuccess: '',
                userProfileError: '',
                profileSuccess: false,
            });
        case GET_USER_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                isProfileLoading: false,
                userProfileData: action.response.data,
                userProfileError: '',
                profileSuccess: true,
                userProfileSuccess: action.response.data.message,
            });
        case GET_USER_PROFILE_FAILURE:
            return Object.assign({}, state, {
                isProfileLoading: false,
                userProfileError: action.response.data.message,
                userProfileData: [],
                profileSuccess: false,
            });

        case DEACTIVATE_ACCOUNT_REQUEST:
            return Object.assign({}, state, {
                deactivateSuccessMsg: '',
                deactivateErrorMsg: '',
            });
        case DEACTIVATE_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                deactivateErrorMsg: '',
                deactivateSuccessMsg: action.response.data.message,
            });
        case DEACTIVATE_ACCOUNT_FAILURE:
            return Object.assign({}, state, {
                deactivateErrorMsg: action.response.data.message,
                deactivateSuccessMsg: '',
            });
        case CLEAR_DEACTIVATE_ACCOUNT_DATA:
            return Object.assign({}, state, {
                deactivateErrorMsg: '',
                deactivateSuccessMsg: '',
            });
        case CHANGE_LEVEL_REQUEST:
            return Object.assign({}, state, {
                changeLevelSuccess: '',
                changeLevelFailure: '',
            });
        case CHANGE_LEVEL_SUCCESS:
            return Object.assign({}, state, {
                changeLevelSuccess: action.response.data.message,
                changeLevelFailure: '',
                getReportsAfterLevelChange: true,
            });
        case CHANGE_LEVEL_FAILURE:
            return Object.assign({}, state, {
                changeLevelSuccess: '',
                changeLevelFailure: action.response.data.message,
            });
        case CLEAR_CHANGE_LEVEL_DATA:
            return Object.assign({}, state, {
                changeLevelSuccess: '',
                changeLevelFailure: '',
                getReportsAfterLevelChange: false,
            });
        case EXPORT_CSV_REQUEST:
            return Object.assign({}, state, {
                exportSuccess: false,
            });
        case EXPORT_CSV_SUCCESS:
            return Object.assign({}, state, {
                csvList: action.response.data.csvData,
                exportSuccess: true,
            });
        case EXPORT_CSV_FAILURE:
            return Object.assign({}, state, {
                exportSuccess: false,
            });
        case GET_LINK_REQUEST:
            return Object.assign({}, state, {
                getLinkSuccess: false,
                documentLink: '',
            });
        case GET_LINK_SUCCESS:
            return Object.assign({}, state, {
                getLinkSuccess: true,
                documentLink: action.response.link,
            });
        case GET_LINK_FAILURE:
            return Object.assign({}, state, {
                getLinkSuccess: false,
                documentLink: '',
            });
        case CLEAR_ASSIGN_MODULE:
            return Object.assign({}, state, {
                postAssignPageLoading: false,
                postAssignSuccessMessage: '',
                postAssignError: '',
            });
        case SET_INTERNS_LEVEL:
            return Object.assign({}, state, {
                internsLevel: action.level,
            });

        default:
            return state;
    }
}
