import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Dashboardlist from './dashboard-list';
import ModuleProgress from './progressModule';
import ModuleDeadline from './deadlineModule';
import RecentlyJoined from './recentlyJoinedIntern';
import NotJoinedIntern from './notJoinedIntern';
import './dist/dashboard-main.css';

class Dashboard extends Component {
    render() {
        return (
            <div className="dashbaord-main-page">
                <Dashboardlist></Dashboardlist>
                <div className="row">
                    <ModuleProgress></ModuleProgress>
                    <ModuleDeadline></ModuleDeadline>
                </div>
                <div className="row">
                    <RecentlyJoined></ RecentlyJoined>
                    <NotJoinedIntern></ NotJoinedIntern>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { state };
};
export default withRouter(connect(mapStateToProps)(Dashboard));
