import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import classNames from 'classnames';
import {
    getDepartmentList,
    handleEditDepartment,
    setDepartmentInfo,
    handleEditDeptDialogChange,
    clearDepartmentErrorData,
    clearDepartmentData,
} from '../../../actions/admin/departmentActions';
import createNotification from '../../../components/app/notification/createNotification';

class EditDepartment extends Component {
    constructor(props) {
        super(props);
    }
    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleEditDeptDialogChange(false));
    }
    handleChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
        }
        const newState = Object.assign(this.props.departmentData, {
            [name]: value,
        });
        this.props.dispatch(setDepartmentInfo(newState));
    }
    handleSubmit(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.dispatch(
                handleEditDepartment(this.props.departmentData)
            );
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                }
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.updateDepartmentErrorMsg.length !== 0) {
            createNotification('error', nextProps.updateDepartmentErrorMsg);
            nextProps.dispatch(clearDepartmentErrorData());
        }
        if (nextProps.getListAfterUpdate === true) {
            createNotification('success', nextProps.updateDepartmentSuccessMsg);
            document.querySelector('#body-root').style = 'overflow:auto;';
            this.props.dispatch(handleEditDeptDialogChange(false));
            nextProps.dispatch(getDepartmentList());
            nextProps.dispatch(clearDepartmentData());
        }
    }

    render() {
        const { editDepartmentPopupState, departmentData } = this.props;
        return (
            <React.Fragment>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': editDepartmentPopupState,
                        fadeOut: !editDepartmentPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown: editDepartmentPopupState,
                                fadeOutUp: !editDepartmentPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">
                                    Edit Department
                                </h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form
                                onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true}
                                id="edit-education-dialog"
                            >
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        value={
                                                            departmentData.name
                                                        }
                                                        outerClass="input-ctrl"
                                                        placeholder="Department Name"
                                                        required={true}
                                                        onChange={this.handleChange.bind(
                                                            this
                                                        )}
                                                        minLength={3}
                                                        maxLength={50}
                                                        autoComplete={'off'}
                                                        label="Department Name"
                                                        className="form-control"
                                                        outerClassName="signup-input-box"
                                                        errorMessage="Please enter department name(Min 3 char.)."
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 ">
                                                <div className="form-group">
                                                    <Input
                                                        type="text"
                                                        name="short_name"
                                                        id="short_name"
                                                        value={
                                                            departmentData.short_name
                                                        }
                                                        outerClass="input-ctrl"
                                                        placeholder="Department Short Name"
                                                        required={true}
                                                        onChange={this.handleChange.bind(
                                                            this
                                                        )}
                                                        minLength={2}
                                                        maxLength={50}
                                                        autoComplete={'off'}
                                                        label="Department Short Name"
                                                        className="form-control"
                                                        outerClassName="signup-input-box"
                                                        errorMessage="Please enter department short name(Min 2 char.)."
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {editDepartmentPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: editDepartmentPopupState,
                            fadeOut: !editDepartmentPopupState,
                        })}
                    ></div>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {
        editDepartmentPopupState,
        departmentData,
        updateDepartmentErrorMsg,
        updateDepartmentSuccessMsg,
        getListAfterUpdate,
    } = state.departmentReducer;
    return {
        editDepartmentPopupState,
        departmentData,
        updateDepartmentErrorMsg,
        updateDepartmentSuccessMsg,
        getListAfterUpdate,
    };
}

export default withRouter(connect(mapStateToProps)(EditDepartment));
