import { combineReducers } from 'redux';
import appReducer from './appReducer';
import apiReducer from './apiReducer';
import employeeReducer from './admin/employeeReducer';
import authReducer from './auth/authReducer';
import moduleReducer from './admin/internModuleReducer';
import userReducer from './employee/userReducer';
import userModuleReducer from './employee/userModuleReducer';
import member from './admin/memberReducer';
import reviewerReducer from './admin/reviewerReducer';
import feedbackReducer from './employee/feedbackReducer';
import reportsReducer from './admin/reportsReducer';
import departmentReducer from './admin/departmentReducer';
import adminDashboardReducer from './admin/adminDashboardReducer';

export default combineReducers({
    appReducer,
    apiReducer,
    employeeReducer,
    authReducer,
    moduleReducer,
    reviewerReducer,
    userReducer,
    userModuleReducer,
    member,
    feedbackReducer,
    reportsReducer,
    departmentReducer,
    adminDashboardReducer
});