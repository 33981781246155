import {
    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAILURE,
    UPDATE_PROFILE_SUCCESS_STATUS,
    ADD_USER_EDUCATION,
    GET_PROFILE_LINK_REQUEST,
    GET_PROFILE_LINK_SUCCESS,
    GET_PROFILE_LINK_FAILURE,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    UPDATE_PROFILE_FIELDS,
    UPDATE_EDUCATION_FIELDS,
    UPDATE_EMERGENCY_FIELDS,
    UPDATE_CERTIFICATE_FIELDS,
    UPDATE_ADDRESS_FIELDS,
    HANDLE_EDIT_EDUCATION_DIALOG_STATE,
    HANDLE_CHNAGE_PASSWORD_DIALOG_STATE,
    GET_PROFILE_STATUS_REQUEST,
    GET_PROFILE_STATUS_SUCCESS,
    GET_PROFILE_STATUS_FAILURE,
    CHANGE_PROFILE_IMAGE_REQUEST,
    CHANGE_PROFILE_IMAGE_SUCCESS,
    CHANGE_PROFILE_IMAGE_FAILURE,
    HANDLE_EDIT_PERSONAL_DIALOG_STATE,
    HANDLE_EDIT_OTHER_DIALOG_STATE,
    HANDLE_EDIT_EMERGENCY_DIALOG_STATE,
    HANDLE_EDIT_ADDRESS_DIALOG_STATE,
    HANDLE_EDIT_ACCOUNT_DIALOG_STATE,
    UPDATE_ACCOUNT_FIELDS,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    UPDATE_PASSWORD_DATA,
    UPLOAD_USER_FILE_FAILURE,
    UPLOAD_USER_FILE_REQUEST,
    UPLOAD_USER_FILE_SUCCESS,
    HANDLE_EDIT_PROFILE_PICTURE_DIALOG_STATE,
    UPLOAD_USER_CERTIFICATE_REQUEST,
    UPLOAD_USER_CERTIFICATE_SUCCESS,
    UPLOAD_USER_CERTIFICATE_FAILURE,
    SET_PERSONAL_INFO,
    SET_ADDRESS_INFO,
    SET_OTHER_INFO,
    SET_EMERGENCY_INFO,
    SET_ACCOUNT_INFO,
    SET_EDUCATION_INFO,
    REMOVE_USER_EDUCATION_DETAIL,
    DELETE_PROFILE_PICTURE_REQUEST,
    DELETE_PROFILE_PICTURE_SUCCESS,
    DELETE_PROFILE_PICTURE_FAILURE,
    DELETE_USER_FILE_REQUEST,
    DELETE_USER_FILE_SUCCESS,
    DELETE_USER_FILE_FAILURE,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAILURE,
    GET_USER_PROFILE_PICTURE_REQUEST,
    GET_USER_PROFILE_PICTURE_SUCCESS,
    GET_USER_PROFILE_PICTURE_FAILURE,
    USER_PROFILE_PIC_REQUEST,
    ADD_NEW_USER_CERTIFICATE,
    USER_PROFILE_PIC_SUCCESS,
    USER_PROFILE_PIC_FAILURE,
    CLEAR_PASSWORD_CHANGE_DATA,
} from '../../constants/employee/userConstants';

import { GET_API, DELETE_API, PUT_API } from '../../middleware/symbols';
import { getDocumentApi, accountApi } from '../ApiActions';

const AUTH_API = accountApi();
const DOCUMENT_API = getDocumentApi();

export function getEmployeeProfile() {
    
    return {
        [GET_API]: {
            endpoint: AUTH_API + `/v1/user/profile`,
            authenticated: true,
            types: [
                GET_PROFILE_REQUEST,
                GET_PROFILE_SUCCESS,
                GET_PROFILE_FAILURE,
            ],
        },
    };
}
export function updateProfileSuccessStatus() {
    return{
        types: [
            UPDATE_PROFILE_SUCCESS_STATUS,
        ],
    }
}
export function getProfileStatus() {
    return {
        [GET_API]: {
            endpoint: AUTH_API + `/v1/user/profilestatus`,
            authenticated: true,
            types: [
                GET_PROFILE_STATUS_REQUEST,
                GET_PROFILE_STATUS_SUCCESS,
                GET_PROFILE_STATUS_FAILURE,
            ],
        },
    };
}

export function getProfileDocumentLinks(link) {
    const token = localStorage.getItem('token');
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return (dispatch) => {
        dispatch(getLinkRequest());
        fetch(DOCUMENT_API + `/getfile/${link}`, config)
            .then(function (response) {
                return response.json();
            })
            .then(
                function (res) {
                    dispatch(getLinkSuccess(res));
                    window.open(res.link, '_blank');
                },
                function (error) {
                    dispatch(getLinkFailed(error));
                }
            );
    };
}

export function getLinkRequest() {
    return {
        type: GET_PROFILE_LINK_REQUEST,
    };
}

export function getLinkSuccess(response) {
    return {
        type: GET_PROFILE_LINK_SUCCESS,
        response,
    };
}
export function getLinkFailed(response) {
    return {
        type: GET_PROFILE_LINK_FAILURE,
        response,
    };
}

export function updateAccountFields(newState) {
    return {
        type: UPDATE_ACCOUNT_FIELDS,
        newState,
    };
}

export function updateProfileFields(newState) {
    return {
        type: UPDATE_PROFILE_FIELDS,
        newState,
    };
}
export function updateAddressFields(newState) {
    return {
        type: UPDATE_ADDRESS_FIELDS,
        newState,
    };
}
export function updateEducationFields(newState) {
    return {
        type: UPDATE_EDUCATION_FIELDS,
        newState,
    };
}
export function updateEmergencyFields(newState) {
    return {
        type: UPDATE_EMERGENCY_FIELDS,
        newState,
    };
}
export function updateCertificateFields(newState) {
    return {
        type: UPDATE_CERTIFICATE_FIELDS,
        newState,
    };
}
export function updateProfileDetails(body) {
    return {
        [PUT_API]: {
            endpoint: AUTH_API + `/v1/user/profile`,
            authenticated: true,
            types: [
                UPDATE_PROFILE_REQUEST,
                UPDATE_PROFILE_SUCCESS,
                UPDATE_PROFILE_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}
export function deleteProfilePicture(file, details) {
    let status = '';
    const config = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    return (dispatch) => {
        dispatch(deletePictureRequest());
        fetch(DOCUMENT_API + '/delete/' + file, config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            deletePictureSuccess({
                                data: { error: false, message: res.message },
                                status: 200,
                            })
                        );
                        dispatch(updateProfileDetails(details));
                    } else {
                        dispatch(
                            deletePictureFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        deletePictureFailure({
                            data: {
                                message: 'Error while updating file',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
export function deletePictureRequest() {
    return {
        type: DELETE_PROFILE_PICTURE_REQUEST,
    };
}
export function deletePictureSuccess(response) {
    return {
        type: DELETE_PROFILE_PICTURE_SUCCESS,
        response,
    };
}
export function deletePictureFailure(response) {
    return {
        type: DELETE_PROFILE_PICTURE_FAILURE,
        response,
    };
}

export function handleEditEducationDialogChange(status) {
    return {
        type: HANDLE_EDIT_EDUCATION_DIALOG_STATE,
        status,
    };
}
export function handleEditPersonalDialogChange(status) {
    return {
        type: HANDLE_EDIT_PERSONAL_DIALOG_STATE,
        status,
    };
}
export function handleEditOtherDialogChange(status) {
    return {
        type: HANDLE_EDIT_OTHER_DIALOG_STATE,
        status,
    };
}
export function handleEditEmergencyDialogChange(status) {
    return {
        type: HANDLE_EDIT_EMERGENCY_DIALOG_STATE,
        status,
    };
}
export function handleEditAddressDialogChange(status) {
    return {
        type: HANDLE_EDIT_ADDRESS_DIALOG_STATE,
        status,
    };
}
export function handleEditAccountDialogChange(status) {
    return {
        type: HANDLE_EDIT_ACCOUNT_DIALOG_STATE,
        status,
    };
}
export function handleChangePasswordDialogChange(status) {
    return {
        type: HANDLE_CHNAGE_PASSWORD_DIALOG_STATE,
        status,
    };
}
export function handleEditProfilePictureDialogChange(status) {
    return {
        type: HANDLE_EDIT_PROFILE_PICTURE_DIALOG_STATE,
        status,
    };
}
export function changeProfileImage(file) {
    let formData = new FormData();
    formData.set('files', file);
    formData.set('type', 'profile');
    let status = '';
    const config = {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        body: formData,
    };
    return (dispatch) => {
        dispatch(changeProfileRequest());
        fetch(DOCUMENT_API + '/user/upload', config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            changeProfileSuccess({
                                data: {
                                    error: false,
                                    message: res.message,
                                    previewLinks: res.preview_links,
                                    uploadLink: res.uploadlink,
                                },
                                status: 200,
                            })
                        );
                    } else {
                        dispatch(
                            changeProfileFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        changeProfileFailure({
                            data: {
                                message: 'Error while updating profile image',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
function changeProfileRequest() {
    return {
        type: CHANGE_PROFILE_IMAGE_REQUEST,
    };
}

function changeProfileSuccess(response) {
    return {
        type: CHANGE_PROFILE_IMAGE_SUCCESS,
        response,
    };
}
function changeProfileFailure(response) {
    return {
        type: CHANGE_PROFILE_IMAGE_FAILURE,
        response,
    };
}
export function uploaduserFile(files, name, index) {
    let formData = new FormData();

    for (var file of files) {
        formData.append('files', file);
    }
    if (name == 'marksheet_link') {
        formData.set('type', 'marksheet');
    } else {
        formData.set('type', 'degree');
    }
    let status = '';
    const config = {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        body: formData,
    };
    return (dispatch) => {
        dispatch(uploaduserFileRequest());
        fetch(DOCUMENT_API + '/user/upload', config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            uploaduserFileSuccess({
                                data: {
                                    usererror: false,
                                    usermessage: res.message,
                                    userpreviewLinks: res.preview_links,
                                    useruploadLink: res.uploadlink,
                                    username: name,
                                    userindex: index,
                                },
                                status: 200,
                            })
                        );
                    } else {
                        dispatch(
                            uploaduserFileFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        uploaduserFileFailure({
                            data: {
                                message: 'Error while updating file',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
function uploaduserFileRequest() {
    return {
        type: UPLOAD_USER_FILE_REQUEST,
    };
}

function uploaduserFileSuccess(response) {
    return {
        type: UPLOAD_USER_FILE_SUCCESS,
        response,
    };
}
function uploaduserFileFailure(response) {
    return {
        type: UPLOAD_USER_FILE_FAILURE,
        response,
    };
}

export function updatePasswordData(newState) {
    return {
        type: UPDATE_PASSWORD_DATA,
        newState,
    };
}
export function uploaduserCertificate(file, name, index, old_cerificate) {
    let formData = new FormData();
    formData.set('files', file);
    formData.set('type', 'certificate');
    let status = '';
    const config = {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        body: formData,
    };
    return (dispatch) => {
        dispatch(uploaduserCertificateRequest());
        fetch(DOCUMENT_API + '/user/upload', config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            uploaduserCertificateSuccess({
                                data: {
                                    error: false,
                                    message: res.message,
                                    previewLinks: res.preview_links,
                                    uploadLink: res.uploadlink,
                                    name: name,
                                    index: index,
                                },
                                status: 200,
                            })
                        );
                        if (old_cerificate !== '') {
                            dispatch(deleteDocument(old_cerificate));
                        }
                    } else {
                        dispatch(
                            uploaduserCertificateFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        uploaduserCertificateFailure({
                            data: {
                                message: 'Error while updating file',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
function uploaduserCertificateRequest() {
    return {
        type: UPLOAD_USER_CERTIFICATE_REQUEST,
    };
}

function uploaduserCertificateSuccess(response) {
    return {
        type: UPLOAD_USER_CERTIFICATE_SUCCESS,
        response,
    };
}
function uploaduserCertificateFailure(response) {
    return {
        type: UPLOAD_USER_CERTIFICATE_FAILURE,
        response,
    };
}
export function changePassword(body) {
    return {
        [PUT_API]: {
            endpoint: AUTH_API + `/v1/changepassword`,
            authenticated: true,
            types: [
                CHANGE_PASSWORD_REQUEST,
                CHANGE_PASSWORD_SUCCESS,
                CHANGE_PASSWORD_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}
export function clearPasswordChangeData() {
    return { type: CLEAR_PASSWORD_CHANGE_DATA };
}
export function deleteUserFile(file, details) {
    let status = '';
    const config = {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    return (dispatch) => {
        dispatch(deleteUserFileRequest());
        fetch(DOCUMENT_API + '/delete/' + file, config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            deleteUserFileSuccess({
                                data: { error: false, message: res.message },
                                status: 200,
                            })
                        );
                        dispatch(updateProfileDetails(details));
                    } else {
                        dispatch(
                            deleteUserFileFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        deleteUserFileFailure({
                            data: {
                                message: 'Error while updating file',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
export function deleteUserFileRequest() {
    return {
        type: DELETE_USER_FILE_REQUEST,
    };
}
export function deleteUserFileSuccess(response) {
    return {
        type: DELETE_USER_FILE_SUCCESS,
        response,
    };
}
export function deleteUserFileFailure(response) {
    return {
        type: DELETE_USER_FILE_FAILURE,
        response,
    };
}
export function addEducation(education, eduIndex) {
    return { type: ADD_USER_EDUCATION, education, eduIndex };
}
export function removeEducationDetail(education) {
    return { type: REMOVE_USER_EDUCATION_DETAIL, education };
}
export function setPersonalInfo(personalInfo) {
    return { type: SET_PERSONAL_INFO, personalInfo };
}
export function setAddressInfo(addressInfo, addressIndex) {
    return { type: SET_ADDRESS_INFO, addressInfo, addressIndex };
}
export function setEducationInfo(educationInfo, eduIndex) {
    return { type: SET_EDUCATION_INFO, educationInfo, eduIndex };
}
export function setAccountInfo(accountInfo) {
    return { type: SET_ACCOUNT_INFO, accountInfo };
}
export function setOtherInfo(otherInfo) {
    return { type: SET_OTHER_INFO, otherInfo };
}
export function setEmergencyInfo(emergencyInfo) {
    return { type: SET_EMERGENCY_INFO, emergencyInfo };
}
export function deleteDocument(file, name, index, list) {
    return {
        [DELETE_API]: {
            endpoint: DOCUMENT_API + '/delete/' + file,
            authenticated: true,
            types: [
                DELETE_DOCUMENT_REQUEST,
                DELETE_DOCUMENT_SUCCESS,
                DELETE_DOCUMENT_FAILURE,
            ],
            name,
            index,
            list,
        },
    };
}
export function getUserProfilePictureLink(file) {
    return {
        [GET_API]: {
            endpoint: DOCUMENT_API + `/getfile/${file}`,
            authenticated: true,
            types: [
                GET_USER_PROFILE_PICTURE_REQUEST,
                GET_USER_PROFILE_PICTURE_SUCCESS,
                GET_USER_PROFILE_PICTURE_FAILURE,
            ],
        },
    };
}
export function getUserProfilePic() {
    return {
        [GET_API]: {
            endpoint: AUTH_API + `/v1/user/profilepicture`,
            authenticated: true,
            types: [
                USER_PROFILE_PIC_REQUEST,
                USER_PROFILE_PIC_SUCCESS,
                USER_PROFILE_PIC_FAILURE,
            ],
        },
    };
}
export function addNewUserCertificate(newCertificate) {
    return {
        type: ADD_NEW_USER_CERTIFICATE,
        newCertificate,
    };
}
