import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import createNotification from '../../../components/app/notification/createNotification';
import {
    getModuleList,
    changePageNumber,
} from '../../../actions/admin/internModuleActions';
import {
    assignFeedbackForm,
    clearAssignFeedbackData,
    getFeedbackFormDetails,
} from '../../../actions/employee/feedbackActions';
import {
    storeSelectedModuleData,
    removeSelectedModuleData,
    clearSelectedModuleData,
    storeAllModules,
} from '../../../actions/admin/employeeActions';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import userRoles from '../../../components/roles/roles'

class AssignFeedbackForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (this.props.roleId === userRoles.Reviewer) {
            this.props.history.push('/admin/feedback/list');
        }
        const data = {
            formid: this.props.match.params.id,
        };
        this.props.dispatch(
            getFeedbackFormDetails(data, this.props.match.params.id)
        );
        const moduleData = {
            page: 1,
        };
        this.props.dispatch(getModuleList(moduleData));
        this.props.dispatch(changePageNumber(1));
        this.props.dispatch(clearSelectedModuleData());
    }
    handleAssignFeedbackForm(e) {
        e.preventDefault();
        let moduleList = [];
        this.props.selectedModules.map((m, i) => moduleList.push(m.id));
        let body = {
            form: this.props.feedbackFormDetails.slug,
            module: moduleList,
        };
        this.props.dispatch(assignFeedbackForm(body));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.assignFeedbackSuccessList.length !== 0 ||
            nextProps.assignFeedbackErrorList.length !== 0
        ) {
            nextProps.history.push('/admin/feedback/assign-feedback/status');
            nextProps.dispatch(clearSelectedModuleData());
        }
        if (nextProps.assignFeedbackErrorMsg.length !== 0) {
            createNotification('error', nextProps.assignFeedbackErrorMsg);
            nextProps.dispatch(clearAssignFeedbackData());
        }
    }
    checkAllCheckbox(e) {
        var checkboxes = document.getElementsByName('reviewers');
        let list = [];
        if (e.target.checked) {
            for (var checkbox of checkboxes) {
                if (checkbox.checked === false) {
                    checkbox.checked = true;
                }
            }
            this.props.moduleList.map((m, i) => list.push(m));
            this.props.dispatch(storeAllModules(list));
        }
    }

    checkBoxSelectionEvent(e, module) {
        if (e.target.checked) {
            this.props.dispatch(storeSelectedModuleData(module));
        } else {
            const deleteIndex = this.props.selectedModules.indexOf(module);
            this.props.dispatch(removeSelectedModuleData(deleteIndex));
        }
    }
    handlePageChange(pageNumber) {
        this.props.dispatch(changePageNumber(pageNumber));
        const data = {
            page: pageNumber,
        };
        this.props.dispatch(getModuleList(data));
        this.forceUpdate();
    }

    render() {
        const { feedbackFormDetails } = this.props;
        return (
            <main className="main-content-wrapper">
                <section className="dashboard-content-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-6">
                                <div className="dashboard-content-header-left">
                                    <h2 className="content-header-title">
                                        Assign Feedback Form
                                    </h2>
                                    <p className="content-header-description">
                                        Assign the feedback form for a
                                        particular module
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6">
                                <div className="dashboard-content-header-right"></div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="dashboard-body-wrapper">
                    <div className="container">
                        <div className="main-content-body">
                            <section className="content-section-wrapper">
                                <div className="view-feedback-form-wrapper feedback-form-wrapper">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6">
                                            <div className="dashboard-content-header-left">
                                                <div className="heading">
                                                    Selected Feedback Form
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="custom-list-item-wrapper">
                                        <li className="custom-list-item">
                                            <div className="custom-list-item-content">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="custom-list-sub-item">
                                                            <div className="custom-list-sub-item-content">
                                                                <span className="custom-list-item-label">
                                                                    Name
                                                                </span>
                                                                <p className="custom-list-item-value">
                                                                    <b>
                                                                        {
                                                                            feedbackFormDetails.title
                                                                        }
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="custom-list-sub-item">
                                                            <div className="custom-list-sub-item-content">
                                                                <span className="custom-list-item-label">
                                                                    Description
                                                                </span>
                                                                <p className="custom-list-item-value">
                                                                    {
                                                                        feedbackFormDetails.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className="custom-list-sub-item">
                                                            <div className="custom-list-sub-item-content">
                                                                <span className="custom-list-item-label">
                                                                    Created On
                                                                </span>
                                                                <p className="custom-list-item-value">
                                                                    {feedbackFormDetails.created_at !==
                                                                    0
                                                                        ? moment(
                                                                              new Date(
                                                                                  feedbackFormDetails.created_at *
                                                                                      1000
                                                                              )
                                                                          ).format(
                                                                              'L'
                                                                          )
                                                                        : 'N/A'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-2">
                                                        <div className="custom-list-sub-item">
                                                            <div className="custom-list-sub-item-content">
                                                                <span className="custom-list-item-label">
                                                                    Created By
                                                                </span>
                                                                <p className="custom-list-item-value">
                                                                    {feedbackFormDetails.created_by
                                                                        ? feedbackFormDetails.created_by
                                                                        : 'N/A'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                            <section className="content-section-wrapper">
                                <div className="table-wrapper">
                                    <div className="heading">
                                        Select Module(s)
                                    </div>
                                    <div>
                                        <div className="table-container ">
                                            <table
                                                className="listing-table "
                                                style={{ width: '100%' }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <input
                                                                type="checkbox"
                                                                onClick={(e) =>
                                                                    this.checkAllCheckbox(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </th>
                                                        <th className="sno-module">
                                                            Sr. No
                                                        </th>
                                                        <th className="module-name">
                                                            Name
                                                        </th>
                                                        <th className="module-email">
                                                            Descrption
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!this.props
                                                        .getModulePageLoading &&
                                                        this.props.moduleList
                                                            .length > 0 &&
                                                        this.props.moduleList.map(
                                                            (module, i) => (
                                                                <tr>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                this.checkBoxSelectionEvent(
                                                                                    e,
                                                                                    module
                                                                                )
                                                                            }
                                                                            name="reviewers"
                                                                        />
                                                                    </td>
                                                                    <td className="content sno-module">
                                                                        {i + 1}
                                                                    </td>
                                                                    <td className="content module-name">
                                                                        {' '}
                                                                        {
                                                                            module.name
                                                                        }
                                                                    </td>
                                                                    <td className="content module-description">
                                                                        {
                                                                            module.description
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div
                                className="row"
                                style={{ margin: '0px 0px 10px 0px' }}
                            >
                                <div className="col-12 col-sm-12 col-md-12">
                                    <div className="react-xd-pagination-container">
                                        <div className="pagination-outer">
                                            <div className="pagination-group">
                                                <Pagination
                                                    firstPageText={'First'}
                                                    lastPageText="Last"
                                                    prevPageText={
                                                        <i className="fa fa-angle-left" />
                                                    }
                                                    nextPageText={
                                                        <i className="fa fa-angle-right" />
                                                    }
                                                    activePage={
                                                        this.props
                                                            .internTablePageNumber
                                                    }
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={
                                                        this.props.moduleCount
                                                    }
                                                    pageRangeDisplayed={8}
                                                    onChange={this.handlePageChange.bind(
                                                        this
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="xd-btn-group"
                            style={{ marginLeft: '7px' }}
                        >
                            <Button
                                onClick={() =>
                                    this.props.history.push(
                                        '/admin/feedback/list'
                                    )
                                }
                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                onClick={(e) =>
                                    this.handleAssignFeedbackForm(e)
                                }
                            >
                                Assign
                            </Button>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        feedbackFormDetails,
        feedbackFormFieldsList,
        selectedFeedbackForm,
        assignFeedbackSuccess,
        assignFeedbackErrorMsg,
        assignFeedbackSuccessMsg,
        assignFeedbackSuccessList,
        assignFeedbackErrorList,
    } = state.feedbackReducer;
    const {
        moduleList,
        getModulePageLoading,
        moduleCount,
        internTablePageNumber,
    } = state.moduleReducer;
    const {roleId} = state.authReducer;
    const { selectedModules } = state.employeeReducer;
    return {
        roleId,
        feedbackFormDetails,
        feedbackFormFieldsList,
        moduleList,
        getModulePageLoading,
        selectedFeedbackForm,
        moduleCount,
        internTablePageNumber,
        selectedModules,
        assignFeedbackSuccess,
        assignFeedbackErrorMsg,
        assignFeedbackSuccessMsg,
        assignFeedbackSuccessList,
        assignFeedbackErrorList,
    };
}

export default withRouter(connect(mapStateToProps)(AssignFeedbackForm));
