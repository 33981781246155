import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFeedbackFormDetails} from '../../../actions/employee/feedbackActions';
import { Checkbox } from 'xd-react-checkbox';
import {RadioGroup} from 'xd-react-radio';
import NoDataFound from '../../../components/app/no-data-found/NoDataFound';
import moment from "moment";
import {BlockLoader} from 'xd-react-loader';

class FeedbackFormDetails extends Component{
    constructor(props){
        super(props)
        this.state= { }
        this.IsJsonString=this.IsJsonString.bind(this)
    }
    
    componentDidMount(){
        const data={
            formid:this.props.match.params.id
        }
        this.props.dispatch(getFeedbackFormDetails(data, this.props.match.params.id))
    }

    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

render(){
    const {feedbackDetailErrorMsg, feedbackFormDetails, feedbackDetailSuccessMsg, feedbackDetailLoading}=this.props
    
    return(
    <main className="main-content-wrapper">
        <section className="dashboard-content-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                            <h2 className="content-header-title">Feedback Form Details</h2>
                            <p className="content-header-description">Details of feedack form.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <div className="dashboard-body-wrapper">
            <div className="container">                   
                <div className="main-content-body">                        
                    <section className="content-section-wrapper">
                    {feedbackDetailLoading?(
                    <div className="page-section-loader">
                        <BlockLoader
                            loadingText="Loading Form Details .."
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                    </div>
                    ):feedbackDetailErrorMsg?(
                       <NoDataFound title={feedbackDetailErrorMsg} />
                    ):feedbackDetailSuccessMsg?(
                        <div className="feedback-form-wrapper"  >
                        {feedbackFormDetails.length!==0 ?
                        (<><div className="details-container">
                        <div className="user-profile-container">                                        
                            <div className="profile-section_card">
                            <h2 className="profile-section_card_title">Details</h2>
                                <div className="profile-section_grid">
                                    <div className="profile-section_card_item">
                                    <article className="profile-section_card_details">
                                            <div className="profile-card-meta_data">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Name</p>
                                                            <p className="fields-value">{feedbackFormDetails.title!== ''? feedbackFormDetails.title: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Description</p>
                                                            <p className="fields-value">{feedbackFormDetails.description!== ''? feedbackFormDetails.description: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Created On</p>
                                                            <p className="fields-value">{feedbackFormDetails.created_at!==0?(moment(new Date(feedbackFormDetails.created_at * 1000)).format('LL')):'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Created By</p>
                                                            <p className="fields-value">{feedbackFormDetails.created_by?feedbackFormDetails.created_by:'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    </div>
                                            </div>
                                        </article>                                           
                                        </div>
                                    </div>
                                </div>
                            </div>                                               
                        </div> 
                        <div className="details-container">
                        <div className="user-profile-container">                                        
                            <div className="profile-section_card">
                            <h2 className="profile-section_card_title"> Form Preview</h2>
                            
                                                <div className="row">
                                                    <div className="col-12 col-sm-9">
                                                        <div className="profile-fields_data">
                                                        <div className="feedack-form-card card-box-shadow">
                                                        <fieldset className="feedack-form-card-content">
                                                        <legend>{feedbackFormDetails.title}</legend>
                                                        {this.IsJsonString(feedbackFormDetails.outline) ? JSON.parse(feedbackFormDetails.outline).map((formField, index) => (
                                                    <>
                                                    {formField.type=="text" &&
                                                    <div className="form-field-group-item">
                                                    <div class="form-group">
                                                    <label>{formField.label}</label>
                                                    {formField.isRequired && <span class="required">*</span>}
                                                        <div class="input-wrapper">
                                                            <input 
                                                                id={formField.id}
                                                                className="form-control" 
                                                                type={formField.type}
                                                                name={formField.name}
                                                                autocomplete="off" 
                                                                minlength="3" 
                                                                maxlength="50" 
                                                                required={formField.isRequired} 
                                                                placeholder={formField.placeholder} 
                                                                value="" />
                                                          </div>
                                                    </div>
                                                    
                                                </div>}
                                                {formField.type=="textarea" &&
                                                    <div className="form-field-group-item">
                                                    <div class="form-group">
                                                    <label>{formField.label}</label>
                                                    {formField.isRequired && <span class="required">*</span>}
                                                        <div class="input-wrapper">
                                                            <textarea 
                                                                id={formField.id}
                                                                className="form-control" 
                                                                type={formField.type}
                                                                name={formField.name}
                                                                autocomplete="off" 
                                                                minlength="3" 
                                                                maxlength="50" 
                                                                required={formField.isRequired} 
                                                                placeholder={formField.placeholder} 
                                                                value="" />
                                                          </div>
                                                    </div>
                                                </div>}
                                                {formField.type=="select" &&
                                                <div className="form-field-group-item">
                                                <div class="form-group">
                                                <label>{formField.label}</label>
                                                {formField.isRequired && <span class="required">*</span>}
                                                    <div class="input-wrapper">
                                                    <div className="select-wrapper">
                                                        <select 
                                                            id={formField.id}
                                                            className="form-control" 
                                                            type={formField.type}
                                                            name={formField.name}
                                                            autocomplete="off" 
                                                            minlength="3" 
                                                            maxlength="50" 
                                                            required={formField.isRequired} 
                                                            placeholder={formField.placeholder} 
                                                            value="" >
                                                                {formField.options.map((option, i)=>(
                                                                    <option value={option.value}>{option.label}</option>
                                                                ))}
                                                        </select>
                                                        </div>
                                                      </div>
                                                </div>
                                                
                                            </div>}
                                            {formField.type=="radio" &&
                                                <div className="form-field-group-item">
                                                <div class="form-group">
                                                <label>{formField.label}</label>
                                                {formField.isRequired && <span class="required">*</span>}
                                                    <div class="input-wrapper">
                                                        <RadioGroup
                                                            id={formField.id}
                                                            position="vertical"
                                                            className="form-control" 
                                                            type={formField.type}
                                                            name={formField.name}
                                                            autocomplete="off" 
                                                            minlength="3" 
                                                            maxlength="50" 
                                                            required={formField.isRequired} 
                                                            placeholder={formField.placeholder} 
                                                            value="" 
                                                            options={formField.options.map((option, i)=>(
                                                                { label: `${option.label}`, value: `${option.value}` }
                                                            ))}
                                                            
                                                        />
                                                      </div>
                                                </div>
                                            </div>}
                                            {formField.type=="checkbox" &&
                                                <div className="form-field-group-item">
                                                <div class="form-group">
                                                <label>{formField.label}</label>
                                                {formField.isRequired && <span class="required">*</span>}
                                                {formField.options.map((option, i)=>(
                                                    <div class="input-wrapper">
                                                    <div className="form-group" id={formField.id}>
                                            <Checkbox  
                                                type={formField.type}
                                                className="form-control"
                                                name={formField.name}
                                                labelValue={option.label}
                                                value={option.value}
                                                themeColor="#f99316" />
                                            </div>
                                                  </div>
                                                ))}
                                                    
                                                </div>
                                            </div>}
                                                </>
                                                )):(<NoDataFound title="No form preview available"/>)}</fieldset></div>
                                                        </div>
                                                    </div>
                                                </div>
                                </div>
                            </div>                                               
                        </div>
                    </>  
            )
            :(
                <NoDataFound title="No data available"/>
            )}
                        </div>):(
                            <NoDataFound/>
                        )}
                    </section>
                </div>
            </div>
        </div>
    </main>
        )
    }
}

function mapStateToProps(state) {
    const {feedbackFormFieldsList,feedbackDetailErrorMsg, feedbackFormDetails, feedbackDetailSuccessMsg, feedbackDetailLoading }=state.feedbackReducer;
    return { feedbackFormFieldsList, feedbackDetailErrorMsg, feedbackFormDetails, feedbackDetailSuccessMsg, feedbackDetailLoading}
}

export default withRouter(connect(mapStateToProps)(FeedbackFormDetails))
