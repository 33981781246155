import React, { Component } from 'react';
import '../../../components/intern/forms/dist/form.css';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import { RadioGroup } from 'xd-react-radio';
import { Checkbox } from 'xd-react-checkbox';
import {
    InlineLoader,
} from 'xd-react-loader';
import { connect } from 'react-redux';
import {
    updatePersonalInfo,
    updateAddressInfo,
    uploadProfileImage,
    deleteFile,
} from '../../../actions/auth/authActions';
import StateData from '../../../static/data/state.json';
import { Avatar } from 'xd-react-avatar';
const yourhandle = require('countrycitystatejson');

class PersonalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maritalStatus: '',
            addressIsSame: false,
            gendererror: '',
            maritalerror: '',
            cities: [],
            districts: [],
            districts_perm: [],
            profileFile: '',
            selectedState: '',
            selectedPermState: '',
            profileError: '',
            newdate: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.updateProfileLink = this.updateProfileLink.bind(this);
    }
    componentDidMount() {
        let year = new Date(Date.now()).getFullYear();
        let month = new Date(Date.now()).getUTCMonth();
        let day = new Date(Date.now()).getDate();
        let date = new Date(year - 18, month, day);
        this.setState({
            newdate:
                date.getFullYear() +
                '-' +
                (date.getMonth() < 10
                    ? '0' + date.getMonth().toString()
                    : date.getMonth()) +
                '-' +
                (date.getDate() < 10
                    ? '0' + date.getDate().toString()
                    : date.getDate()),
        });
    }

    handleMaritalStatusChange(e) {
        this.setState({
            maritalStatus: e.target.value,
        });
        const newState = Object.assign(this.props.personalInfo, {
            [e.target.name]: e.target.value,
        });
        this.props.dispatch(updatePersonalInfo(newState));
    }
    handleAddressCheck(e) {
        this.setState({
            addressIsSame: !this.state.addressIsSame,
        });
    }
    validateForm() {
        if (
            this.state.email.length == 0 ||
            this.state.fatherName.length == 0 ||
            this.state.motherName.length == 0 ||
            this.state.dob.length == 0 ||
            this.state.doj.length == 0 ||
            this.state.perAddress.length == 0 ||
            this.state.tempAddress.length == 0 ||
            this.state.bloodg.length == 0
        ) {
            this.setState({
                errormsg: 'Required fields cannot be empty',
            });
            return false;
        } else {
            this.setState({ errormsg: '' });
        }
        if (!this.state.email.includes('@')) {
            this.setState({
                emailError: 'Invalid email',
            });
            return false;
        }

        return true;
    }

    handleOnSubmit(e) {
        e.preventDefault();
        if (this.state.addressIsSame) {
            this.props.addressInfo[1].address =
                this.props.addressInfo[0].address;
            this.props.addressInfo[1].country =
                this.props.addressInfo[0].country;
            this.props.addressInfo[1].city = this.props.addressInfo[0].city;
            this.props.addressInfo[1].state = this.props.addressInfo[0].state;
            this.props.addressInfo[1].district =
                this.props.addressInfo[0].district;
            this.props.addressInfo[1].pincode =
                this.props.addressInfo[0].pincode;
        }
        if (
            e.target.checkValidity() &&
            this.props.personalInfo.gender.length != 0 &&
            this.props.personalInfo.marital_status.length != 0 &&
            this.props.uploadedImageURL.length != 0
        ) {
            const personalInformation = {
                fathers_name: this.props.personalInfo.fathers_name,
                mothers_name: this.props.personalInfo.mothers_name,
                gender: this.props.personalInfo.gender,
                dateofbirth: this.props.personalInfo.dateofbirth,
                blood_group: this.props.personalInfo.blood_group,
                marital_status: this.props.personalInfo.marital_status,
                spouse_name: this.props.personalInfo.spouse_name,
                spouse_contact: this.props.personalInfo.spouse_contact,
                profile_img_link: this.props.profileUploadLink[0],
            };
            this.props.dispatch(updatePersonalInfo(personalInformation));
            this.props.history.push(
                '/member-registration/education-information'
            );
        } else {
            if (this.props.personalInfo.gender.length == 0) {
                this.setState({
                    gendererror: 'Please select gender',
                });
            } else {
                this.setState({ gendererror: '' });
            }
            if (this.props.personalInfo.marital_status.length == 0) {
                this.setState({
                    maritalerror: 'Please select your marital status',
                });
            } else {
                this.setState({ maritalerror: '' });
            }
            if (this.props.uploadedImageURL.length == 0) {
                this.setState({
                    profileError: 'Please upload your profile picture',
                });
            } else {
                this.setState({ profileError: '' });
            }

            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    handleChange(e) {
        const newState = Object.assign(this.props.personalInfo, {
            [e.target.name]: e.target.value,
        });
        this.props.dispatch(updatePersonalInfo(newState));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
    }

    handleAddressChange(index, e) {
        let name = e.target.name;
        let value = e.target.value;
        let addressData = this.props.addressInfo;
        if (name == 'state' && index == 0) {
            this.setState({ selectedState: value });
        }
        if (name == 'state' && index == 1) {
            this.setState({ selectedPermState: value });
        }

        if (name == 'state' && index == 0) {
            StateData.country.states.map((state, index) => {
                if (state.label == value) {
                    this.setState({
                        districts: state.districts,
                    });
                }
            });
        }
        if (name == 'state' && index == 1) {
            StateData.country.states.map((state, index) => {
                if (state.label == value) {
                    this.setState({
                        districts_perm: state.districts,
                    });
                }
            });
        }

        if (name == 'pincode') {
            addressData[index][name] = parseFloat(e.target.value);
        } else {
            addressData[index][name] = value;
        }

        this.props.dispatch(updateAddressInfo(addressData));
        if (e.target.checkValidity()) {
            if (e.target.id == 'address-textarea') {
                e.target.addEventListener('keypress', function (e) {
                    var k = e ? e.which : window.event.keyCode;
                    if (k == 32) {
                        e.target.parentElement.classList.add('has-error');
                        e.target.parentElement.parentElement.classList.add(
                            'has-error'
                        );
                        return false;
                    } else {
                        e.target.parentElement.classList.remove('has-error');
                        e.target.parentElement.parentElement.classList.remove(
                            'has-error'
                        );
                    }
                });
            } else {
                e.target.parentElement.classList.remove('has-error');
                e.target.parentElement.parentElement.classList.remove(
                    'has-error'
                );
            }
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
    }
    validateAddressChange(e, i) {
        var k = e ? e.which : window.event.keyCode;

        if (k == 32) {
            return false;
        }
    }
    handleProfileImageUpload(e) {
        let input = document.getElementById('upload-profile-photo');
        if (input.files[0]) {
            let file = input.files[0];
            if (this.props.profileUploadLink.length != 0) {
                this.props.dispatch(
                    deleteFile(this.props.profileUploadLink, file)
                );
            } else {
                this.props.dispatch(uploadProfileImage(file));
            }
        }
    }

    updateProfileLink() {
        const newState = {
            fathers_name: this.props.personalInfo.fathers_name,
            mothers_name: this.props.personalInfo.mothers_name,
            gender: this.props.personalInfo.gender,
            dateofbirth: this.props.personalInfo.dateofbirth,
            blood_group: this.props.personalInfo.blood_group,
            marital_status: this.props.personalInfo.marital_status,
            spouse_name: this.props.personalInfo.spouse_name,
            spouse_contact: this.props.personalInfo.spouse_contact,
            profile_img_link: this.props.profileUploadLink,
        };
        this.props.dispatch(updatePersonalInfo(newState));
    }

    render() {
        const {
            uploadedImageURL,
            loginErrorMessage,
            loginSuccessMessage,
            isPageLoading,
        } = this.props;
        const { maritalStatus, addressIsSame } = this.state;

        return (
            <main className="main-content-wrapper auth-content-wrapper full-height">
                <div className="wrapper-container signing-container personal-information">
    <div className="container user-jounrney-form-container">
        <div className="user-journey-form user-journey-form-internal-steps">
            <div className="dashbaord-user-form">
                <div className="section_header">
                    <label>Step 2/4</label>
                    <h2 className="big-heading">Personal Information</h2>
                </div>
                <div className="personal-information" id="personal-information">
                <form
                                                className="employee-signup-form"
                                                noValidate={true}
                                                id="employee-signup-form"
                                                onSubmit={this.handleOnSubmit.bind(
                                                    this
                                                )}
                                            >
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="text"
                                                            name="fathers_name"
                                                            id="fathers_name"
                                                            placeholder="Father's Name"
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                            }
                                                            autoComplete={'off'}
                                                            label="Father's Name"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter your father's name(min. 3 characters)."
                                                            onChange={this.handleChange.bind(
                                                                this
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="text"
                                                            name="mothers_name"	
                                                            id="mothers_name"
                                                            placeholder="Mother's Name"
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                            }
                                                            autoComplete={'off'}
                                                            label="Mother's Name"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter your mother's name(min. 3 characters)."
                                                            onChange={this.handleChange.bind(
                                                                this
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="date"
                                                            name="dateofbirth"
                                                            id="date_of_birth"
                                                            placeholder="DD/MM/YYYYY"
                                                            min="1980-01-01"
                                                            max={new Date().toISOString().split('T')[0]} 
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            autoComplete={'off'}
                                                            label="Date of Birth"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter vaild Date of Birth."
                                                            onChange={this.handleChange.bind(
                                                                this
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Blood group{' '}
                                                                <span className="req">
                                                                    *
                                                                </span>{' '}
                                                            </label>
                                                            <div className="select-wrapper">
                                                                <select
                                                                    id="state"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.handleChange(
                                                                            e
                                                                        )
                                                                    }
                                                                    name="blood_group"
                                                                    className="form-control"
                                                                    required={
                                                                        true
                                                                    }
                                                                >
                                                                    <option
                                                                        disabled={
                                                                            true
                                                                        }
                                                                        selected
                                                                        value=""
                                                                        defaultValue
                                                                    >
                                                                        Select
                                                                        Blood
                                                                        group
                                                                    </option>
                                                                    <option value="A+">
                                                                        A+
                                                                    </option>
                                                                    <option value="A-">
                                                                        A-
                                                                    </option>
                                                                    <option value="B+">
                                                                        B+
                                                                    </option>
                                                                    <option value="B-">
                                                                        B-
                                                                    </option>
                                                                    <option value="O+">
                                                                        O+
                                                                    </option>
                                                                    <option value="O-">
                                                                        O-
                                                                    </option>
                                                                    <option value="AB+">
                                                                        AB+
                                                                    </option>
                                                                    <option value="AB-">
                                                                        AB-
                                                                    </option>
                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    Please
                                                                    select Blood
                                                                    Group.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <RadioGroup
                                                                label="Gender"
                                                                className="form-control radio"
                                                                id="gender"
                                                                required={true}
                                                                name="gender"
                                                                errorMessage="Select gender"
                                                                onChange={this.handleChange.bind(
                                                                    this
                                                                )}
                                                                options={[
                                                                    {
                                                                        label: 'Male',
                                                                        value: 'male',
                                                                    },
                                                                    {
                                                                        label: 'Female',
                                                                        value: 'female',
                                                                    },
                                                                ]}
                                                            />
                                                            <p className="selecterror">
                                                                {
                                                                    this.state
                                                                        .gendererror
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-group">
                                                            <RadioGroup
                                                                label="Marital status "
                                                                className="form-control radio"
                                                                id="marital_status"
                                                                required="true"
                                                                name="marital_status"
                                                                errorMessage="Select Marital status"
                                                                onChange={this.handleMaritalStatusChange.bind(
                                                                    this
                                                                )}
                                                                options={[
                                                                    {
                                                                        label: 'Single',
                                                                        value: 'single',
                                                                    },
                                                                    {
                                                                        label: 'Married',
                                                                        value: 'married',
                                                                    },
                                                                ]}
                                                            />
                                                            <p className="selecterror">
                                                                {
                                                                    this.state
                                                                        .maritalerror
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {maritalStatus ===
                                                        'married' && (
                                                        <>
                                                            <div className="col-12 col-sm-6">
                                                                <Input
                                                                    type="text"
                                                                    name="spouse_name"
                                                                    id="spouse_name"
                                                                    placeholder="Spouse's Name"
                                                                    required={
                                                                        true
                                                                    }
                                                                    minLength={
                                                                        3
                                                                    }
                                                                    maxLength={
                                                                        50
                                                                    }
                                                                    pattern={
                                                                        '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                    }
                                                                    autoComplete={
                                                                        'off'
                                                                    }
                                                                    label="Spouse's Name"
                                                                    className="form-control"
                                                                    outerClassName="signup-input-box"
                                                                    errorMessage="Please enter your spouse's name."
                                                                    onChange={this.handleChange.bind(
                                                                        this
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <Input
                                                                    type="text"
                                                                    name="spouse_contact"
                                                                    id="spouse_contact"
                                                                    placeholder="Spouse's Phone Number"
                                                                    required={
                                                                        true
                                                                    }
                                                                    minLength={
                                                                        3
                                                                    }
                                                                    maxLength={
                                                                        50
                                                                    }
                                                                    pattern={
                                                                        '[7-9]{1}[0-9]{9}'
                                                                    }
                                                                    autoComplete={
                                                                        'off'
                                                                    }
                                                                    label="Spouse's Phone"
                                                                    className="form-control"
                                                                    outerClassName="signup-input-box"
                                                                    errorMessage="Please enter your spouse's Phone."
                                                                    onChange={this.handleChange.bind(
                                                                        this
                                                                    )}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="col-12">
                                                        <hr className="form-section-divider"></hr>
                                                        <h3 className="form-section-header">
                                                            Profile Picture
                                                            <span
                                                                style={{
                                                                    color: 'darkred',
                                                                    fontWeight:
                                                                        'lighter',
                                                                }}
                                                                className="req"
                                                            >
                                                                *
                                                            </span>
                                                        </h3>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <div className="image-previewer">
                                                                <label
                                                                    htmlFor="upload-profile-photo"
                                                                    className="img-upload-box"
                                                                >
                                                                    <input
                                                                        type="file"
                                                                        id="upload-profile-photo"
                                                                        onChange={this.handleProfileImageUpload.bind(
                                                                            this
                                                                        )}
                                                                    />
                                                                    <i className="fa fa-user-plus"></i>{' '}
                                                                    <span>
                                                                        Upload
                                                                        Profile
                                                                    </span>
                                                                </label>
                                                                <div className="avatar-box">
                                                                    <Avatar
                                                                        name="Profile Photo"
                                                                        src={
                                                                            uploadedImageURL !==
                                                                            ''
                                                                                ? uploadedImageURL
                                                                                : require('../../../static/images/profile-placeholder.png')
                                                                        }
                                                                        size={
                                                                            120
                                                                        }
                                                                        radius={
                                                                            5
                                                                        }
                                                                    />
                                                                </div>
                                                                {this.props
                                                                    .isProfileLoading ? (
                                                                    <div>
                                                                        <InlineLoader
                                                                            loadingText="loading"
                                                                            loading={
                                                                                true
                                                                            }
                                                                            loaderStyle={{
                                                                                size: '20px',
                                                                                color: '0562e8',
                                                                                textSize:
                                                                                    '18px',
                                                                                textColor:
                                                                                    '#485e7d',
                                                                                speed: '1s',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {this.props
                                                                    .uploadProfileErrorMsg ? (
                                                                    <div>
                                                                        {
                                                                            this
                                                                                .props
                                                                                .uploadProfileErrorMsg
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                            <p
                                                                style={{
                                                                    color: '#bf1f67',
                                                                }}
                                                            >
                                                                <i
                                                                    class="fa fa-info-circle"
                                                                    aria-hidden="true"
                                                                ></i>{' '}
                                                                Please upload a
                                                                professional
                                                                picture as it
                                                                may be used for
                                                                future purpose.
                                                            </p>
                                                            <p className="selecterror">
                                                                {
                                                                    this.state
                                                                        .profileError
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <hr className="form-section-divider"></hr>
                                                        <h3 className="form-section-header">
                                                            Current Address
                                                        </h3>
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-group">
                                                            <label>
                                                                Address{' '}
                                                                <span className="req">
                                                                    *
                                                                </span>{' '}
                                                            </label>
                                                            <div className="input-wrapper">
                                                                <textarea
                                                                    placeholder="Enter  your address"
                                                                    name="address"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.handleAddressChange(
                                                                            0,
                                                                            e
                                                                        )
                                                                    }
                                                                    cols="20"
                                                                    rows="3"
                                                                    id="address-textarea"
                                                                    minLength={
                                                                        3
                                                                    }
                                                                    maxLength={
                                                                        200
                                                                    }
                                                                    pattern={
                                                                        '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                    }
                                                                    className="form-control"
                                                                    required={
                                                                        true
                                                                    }
                                                                ></textarea>
                                                            </div>
                                                            <span className="with-error">
                                                                Please enter
                                                                your address
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>
                                                                Country{' '}
                                                                <span className="req">
                                                                    *
                                                                </span>{' '}
                                                            </label>
                                                            <div className="select-wrapper">
                                                                <select
                                                                    id="country"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.handleAddressChange(
                                                                            0,
                                                                            e
                                                                        )
                                                                    }
                                                                    name="country"
                                                                    className="form-control"
                                                                    required={
                                                                        true
                                                                    }
                                                                >
                                                                    <option
                                                                        disabled={
                                                                            true
                                                                        }
                                                                        selected
                                                                        value=""
                                                                        defaultValue
                                                                    >
                                                                        Select
                                                                        Country
                                                                    </option>
                                                                    <option>
                                                                        India
                                                                    </option>
                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    Please
                                                                    select
                                                                    Country.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>
                                                                State{' '}
                                                                <span className="req">
                                                                    *
                                                                </span>{' '}
                                                            </label>
                                                            <div className="select-wrapper">
                                                                <select
                                                                    id="state"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.handleAddressChange(
                                                                            0,
                                                                            e
                                                                        )
                                                                    }
                                                                    name="state"
                                                                    className="form-control"
                                                                    required={
                                                                        true
                                                                    }
                                                                >
                                                                    <option
                                                                        disabled={
                                                                            true
                                                                        }
                                                                        value=""
                                                                        selected
                                                                        defaultValue
                                                                    >
                                                                        Select
                                                                        State
                                                                    </option>
                                                                    {StateData.country.states.map(
                                                                        (
                                                                            state,
                                                                            index
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    state.value
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {
                                                                                    state.label
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    Please
                                                                    select
                                                                    State.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>
                                                                District{' '}
                                                                <span className="req">
                                                                    *
                                                                </span>{' '}
                                                            </label>
                                                            <div className="select-wrapper">
                                                                <select
                                                                    id="district"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.handleAddressChange(
                                                                            0,
                                                                            e
                                                                        )
                                                                    }
                                                                    name="district"
                                                                    className="form-control"
                                                                    required={
                                                                        true
                                                                    }
                                                                >
                                                                    <option
                                                                        disabled={
                                                                            true
                                                                        }
                                                                        selected
                                                                        value=""
                                                                        defaultValue
                                                                    >
                                                                        Select
                                                                        district
                                                                    </option>
                                                                    {this.state.districts.map(
                                                                        (
                                                                            district,
                                                                            index
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    district
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {
                                                                                    district
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    Please
                                                                    select
                                                                    district
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label>
                                                                City{' '}
                                                                <span className="req">
                                                                    *
                                                                </span>{' '}
                                                            </label>
                                                            <div className="select-wrapper">
                                                                <select
                                                                    id="city"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.handleAddressChange(
                                                                            0,
                                                                            e
                                                                        )
                                                                    }
                                                                    name="city"
                                                                    className="form-control"
                                                                    required={
                                                                        true
                                                                    }
                                                                >
                                                                    <option
                                                                        disabled={
                                                                            true
                                                                        }
                                                                        selected
                                                                        value=""
                                                                        defaultValue
                                                                    >
                                                                        Select
                                                                        City
                                                                    </option>
                                                                    {yourhandle
                                                                        .getCities(
                                                                            'IN',
                                                                            this
                                                                                .state
                                                                                .selectedState
                                                                        )
                                                                        .map(
                                                                            (
                                                                                city,
                                                                                index
                                                                            ) => (
                                                                                <option
                                                                                    value={
                                                                                        city
                                                                                    }
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        city
                                                                                    }
                                                                                </option>
                                                                            )
                                                                        )}
                                                                </select>
                                                                <div className="help-block with-errors">
                                                                    Please
                                                                    select City.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="text"
                                                            name="pincode"
                                                            id="pin_code"
                                                            placeholder="Pin Code"
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={'[0-9]{6}'}
                                                            autoComplete={'off'}
                                                            label="Pin Code"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter a valid pincode"
                                                            onChange={(e) =>
                                                                this.handleAddressChange(
                                                                    0,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-group">
                                                            <Checkbox
                                                                name="Checked"
                                                                checked={
                                                                    addressIsSame
                                                                }
                                                                onChange={this.handleAddressCheck.bind(
                                                                    this
                                                                )}
                                                                labelValue="Permanent address same as current address."
                                                                id="checked-input"
                                                                themeColor="#f99316"
                                                            />
                                                        </div>
                                                    </div>
                                                    {!addressIsSame && (
                                                        <>
                                                            <div className="col-12">
                                                                <hr className="form-section-divider"></hr>
                                                                <h3 className="form-section-header">
                                                                    Permanent
                                                                    Address
                                                                </h3>
                                                            </div>
                                                            <div className="col-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label>
                                                                        Address{' '}
                                                                        <span className="req">
                                                                            *
                                                                        </span>{' '}
                                                                    </label>
                                                                    <div className="input-wrapper">
                                                                        <textarea
                                                                            placeholder="Enter  your address"
                                                                            name="address"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.handleAddressChange(
                                                                                    1,
                                                                                    e
                                                                                )
                                                                            }
                                                                            cols="20"
                                                                            rows="3"
                                                                            id="address-textarea"
                                                                            minLength={
                                                                                3
                                                                            }
                                                                            maxLength={
                                                                                200
                                                                            }
                                                                            pattern={
                                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                            }
                                                                            className="form-control"
                                                                            required={
                                                                                true
                                                                            }
                                                                        ></textarea>
                                                                    </div>
                                                                    <span className="with-error">
                                                                        Please
                                                                        enter
                                                                        your
                                                                        address
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>
                                                                        Country{' '}
                                                                        <span className="req">
                                                                            *
                                                                        </span>{' '}
                                                                    </label>
                                                                    <div className="select-wrapper">
                                                                        <select
                                                                            id="state"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.handleAddressChange(
                                                                                    1,
                                                                                    e
                                                                                )
                                                                            }
                                                                            name="country"
                                                                            className="form-control"
                                                                            required={
                                                                                true
                                                                            }
                                                                        >
                                                                            <option
                                                                                disabled={
                                                                                    true
                                                                                }
                                                                                selected
                                                                                value=""
                                                                                defaultValue
                                                                            >
                                                                                Select
                                                                                Country
                                                                            </option>
                                                                            <option>
                                                                                India
                                                                            </option>
                                                                        </select>
                                                                        <div className="help-block with-errors">
                                                                            Please
                                                                            select
                                                                            Country.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>
                                                                        State{' '}
                                                                        <span className="req">
                                                                            *
                                                                        </span>{' '}
                                                                    </label>
                                                                    <div className="select-wrapper">
                                                                        <select
                                                                            id="state"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.handleAddressChange(
                                                                                    1,
                                                                                    e
                                                                                )
                                                                            }
                                                                            name="state"
                                                                            className="form-control"
                                                                            required={
                                                                                true
                                                                            }
                                                                        >
                                                                            <option
                                                                                disabled={
                                                                                    true
                                                                                }
                                                                                selected
                                                                                value=""
                                                                                defaultValue
                                                                            >
                                                                                Select
                                                                                State
                                                                            </option>
                                                                            {StateData.country.states.map(
                                                                                (
                                                                                    state,
                                                                                    index
                                                                                ) => (
                                                                                    <option
                                                                                        value={
                                                                                            state.value
                                                                                        }
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            state.label
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                        <div className="help-block with-errors">
                                                                            Please
                                                                            select
                                                                            State.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>
                                                                        District{' '}
                                                                        <span className="req">
                                                                            *
                                                                        </span>{' '}
                                                                    </label>
                                                                    <div className="select-wrapper">
                                                                        <select
                                                                            id="district"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.handleAddressChange(
                                                                                    1,
                                                                                    e
                                                                                )
                                                                            }
                                                                            name="district"
                                                                            className="form-control"
                                                                            required={
                                                                                true
                                                                            }
                                                                        >
                                                                            <option
                                                                                disabled={
                                                                                    true
                                                                                }
                                                                                selected
                                                                                value=""
                                                                                defaultValue
                                                                            >
                                                                                Select
                                                                                district
                                                                            </option>
                                                                            {this.state.districts_perm.map(
                                                                                (
                                                                                    district,
                                                                                    index
                                                                                ) => (
                                                                                    <option
                                                                                        value={
                                                                                            district
                                                                                        }
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            district
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            )}
                                                                        </select>
                                                                        <div className="help-block with-errors">
                                                                            Please
                                                                            select
                                                                            district
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <div className="form-group">
                                                                    <label>
                                                                        City{' '}
                                                                        <span className="req">
                                                                            *
                                                                        </span>{' '}
                                                                    </label>
                                                                    <div className="select-wrapper">
                                                                        <select
                                                                            id="state"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.handleAddressChange(
                                                                                    1,
                                                                                    e
                                                                                )
                                                                            }
                                                                            name="city"
                                                                            className="form-control"
                                                                            required={
                                                                                true
                                                                            }
                                                                        >
                                                                            <option
                                                                                disabled={
                                                                                    true
                                                                                }
                                                                                selected
                                                                                value=""
                                                                                defaultValue
                                                                            >
                                                                                Select
                                                                                City
                                                                            </option>
                                                                            {yourhandle
                                                                                .getCities(
                                                                                    'IN',
                                                                                    this
                                                                                        .state
                                                                                        .selectedPermState
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        city,
                                                                                        index
                                                                                    ) => (
                                                                                        <option
                                                                                            value={
                                                                                                city
                                                                                            }
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                city
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                )}
                                                                        </select>
                                                                        <div className="help-block with-errors">
                                                                            Please
                                                                            select
                                                                            City.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6">
                                                                <Input
                                                                    type="text"
                                                                    name="pincode"
                                                                    id="pin_code"
                                                                    placeholder="Pin Code"
                                                                    required={
                                                                        true
                                                                    }
                                                                    minLength={
                                                                        3
                                                                    }
                                                                    maxLength={
                                                                        50
                                                                    }
                                                                    pattern={
                                                                        '[0-9]{6}'
                                                                    }
                                                                    autoComplete={
                                                                        'off'
                                                                    }
                                                                    label="Pin Code"
                                                                    className="form-control"
                                                                    outerClassName="signup-input-box"
                                                                    errorMessage="Please enter a valid pincode"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        this.handleAddressChange(
                                                                            1,
                                                                            e
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-group form-btn-group">
                                                            {!isPageLoading ? (
                                                                <Button
                                                                    type="submit"
                                                                    id="send-invite"
                                                                    formNoValidate={
                                                                        true
                                                                    }
                                                                    className="btn box-shadow"
                                                                >
                                                                    Save &
                                                                    Continue
                                                                </Button>
                                                            ) : (
                                                                <Button className="btn btn-icon icon-left">
                                                                    <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                                    <span className="btn-text">
                                                                        Please
                                                                        wait ..
                                                                    </span>
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        {!isPageLoading &&
                                                            loginErrorMessage && (
                                                                <div className="center-error form-error">
                                                                    <p className="error-msg">
                                                                        {
                                                                            loginErrorMessage
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )}
                                                        {!isPageLoading &&
                                                            loginSuccessMessage && (
                                                                <div className="center-error form-error">
                                                                    <p className="success-msg">
                                                                        {
                                                                            loginSuccessMessage
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </form>
                </div>
            </div>
            <div className="xenonstack-jobs-copyright">
                <p>Xenonstack |</p>
                <i className="fa fa-copyright"></i>
                <p>2021 All Rights Reserved</p>
            </div>
        </div>
    </div>
</div>

            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        uploadedImageURL,
        isPageLoading,
        memberSignupData,
        personalInfo,
        previewLinks,
        uploadLink,
        isProfileLoading,
        profileUploadLink,
        uploadProfileSuccess,
        addressInfo,
        emergencyInfo,
        educationInfo,
        otherDocumentsList,
        deleteFileSuccess,
        deleteFileSuccessMsg,
        deleteFileError,
    } = state.authReducer;
    return {
        uploadedImageURL,
        isPageLoading,
        memberSignupData,
        personalInfo,
        previewLinks,
        uploadLink,
        profileUploadLink,
        uploadProfileSuccess,
        isProfileLoading,
        addressInfo,
        emergencyInfo,
        educationInfo,
        otherDocumentsList,
        deleteFileSuccess,
        deleteFileSuccessMsg,
        deleteFileError,
    };
}

export default connect(mapStateToProps)(PersonalInfo);
