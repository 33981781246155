import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/header.css';
import ReactTooltip from 'react-tooltip';
import { Avatar } from 'xd-react-avatar'
import { handleDrawerToggleChange } from '../../../actions/index';
import { logout } from '../../../actions/auth/authActions'

import { Dialogbox } from 'xd-react-dialogbox'
import userRoles from '../../roles/roles';

class Header extends Component {

	handleDrawerToggle(e) {
		this.props.dispatch(handleDrawerToggleChange(!this.props.toggleStatus));
	}
	componentDidMount() {
		document.addEventListener('click', function (event) {
			const openProject = document.querySelector('.project-dropdown-menu.show');
			const openpop = document.querySelector('.profile-dropdown-menu.show');
			if (openProject !== null) {
				if (
					event.target.closest('.info-navbar-menu-item.header-project')
				) {
					// do nothing
				} else {
					openProject.classList.remove('show');
				}
			}
			if (openpop !== null) {
				if (
					event.target.closest('.profile-dropdown-menu') ||
					event.target.closest('.user-image-outer')
				) {
					// do nothing
				} else {
					openpop.classList.remove('show');
				}
			}
		});
	}

	handleProfilePopup(e) {
		document.querySelector(".profile-dropdown-menu").classList.toggle("show");
	}
	handleproject(e) {
		document.querySelector(".project-dropdown-menu").classList.toggle("show");
	}
	openLogoutDialog() {
		document.querySelector('#logoutDialog').classList.add('opened');
		document.querySelector('body').classList.add('has-dialog');
	}
	// submit response of dialog
	submitLogoutDialog() {
		this.props.dispatch(logout())
		document.querySelector('.dialog').classList.remove('opened');
		document.querySelector('body').classList.remove('has-dialog');
	}
	// closes the dialog
	closeDialogbox() {
		document.querySelector('.dialog').classList.remove('opened');
		document.querySelector('body').classList.remove('has-dialog');
	}

	render() {
		return (
			<>
				<header className={this.props.toggleStatus ? 'header-section header-fixed header-fixed-admin' : 'header-section header-fixed closed'} id="header">
					<div className='container'>
					<nav id="navigation-bar" className='navigation-bar'>
						<div className="brand-logo-wrapper">
							<figure className="brand-logo-md">
								<img src={require('../../../static/images/logo-with-white-font.svg')} className="brand-logo" alt="XenonStack OnBording Portal" />
								<img src={require('../../../static/images/normal-logo.svg')} className="brand-logo-mobile"/>
							</figure>
							<figure className="brand-logo-xs">
								<img src={require('./dist/xenonstack-logo-xs.png')} className="brand-logo" alt="XenonStack OnBording Portal" />
							</figure>
							<div className="drawer-toggle" id="drawer-toggle" onClick={this.handleDrawerToggle.bind(this)} >
								<i className={this.props.toggleStatus ? " sm-none fa fa-angle-left" : " sm-none fa fa-angle-right"}></i>
								<i className="fa fa-bars sm-block"></i>
							</div>
						</div>

						<div className="navigation-menu-bar">
							<div className="info-navigation-menu nav-left">
								<div className="brand-name-text">
									<h3>People On Boarding Portal</h3>
								</div>
							</div>
							<div className="info-navigation-menu nav-right">
								<ul className="info-navbar-menu">
									<li>
										<div className="info-navbar-menu-item user-image-outer"
											data-tip data-for='profile'
											onClick={this.handleProfilePopup.bind(this)}  >
											<Avatar name={this.props.userName!=='' ?this.props.userName : 'User'} color={"#F99316"} size={30} marginRight={6} />
											<span className="user-auth-name">
												{this.props.userName!=='' ?this.props.userName : 'User'}
                                        </span>
											<i className="fa fa-angle-down arrow-down"></i>
											<ReactTooltip id='profile' type="dark" effect='solid' place="bottom">
												<span>My Profile</span>
											</ReactTooltip>
										</div>
										<div className="profile-dropdown-menu">
											<div className="profile-dropdown-item">
												<div className="dropdown-header-box">
													<div className="text-content">
														<span><strong>Role : </strong>  {(this.props.roleId? (this.props.roleId.charAt(0).toUpperCase() + this.props.roleId.slice(1)):this.props.roleId) }</span>
													</div>
												</div>
												{this.props.roleId != userRoles.Reviewer && 
												<div className="dropdown-content-box">
													<ul>
														<li> <NavLink to="/admin/invite-member/list"> Manage Admin</NavLink></li>
													</ul>
												</div>}
												{this.props.roleId != userRoles.Reviewer && 
												<div className="dropdown-content-box">
													<ul>
														<li> <NavLink to="/admin/department/list"> Manage Departments</NavLink></li>
													</ul>
												</div>}
												
												<div className="dropdown-footer-box">
													<button type="button" className="logout-link"
														onClick={this.openLogoutDialog.bind(this)}
													><i className="fa fa-sign-out"></i>
                                                Logout</button>
												</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					<Dialogbox
						title="Logout"
						successButton="Logout"
						cancelButton="Cancel"
						submitDialog={this.submitLogoutDialog.bind(this)}
						closeDialog={this.closeDialogbox}
						dialogBoxID="logoutDialog" >
						<p style={{textAlign:'center', marginRight:'32px'}}>Are you sure you want to Logout?</p>
					</Dialogbox>
					</div>
				</header>
			</>
		);
	}
}

function mapStateToProps(state) {
	const { toggleStatus } = state.appReducer;
	const {roleId} = state.authReducer;
	const userName = localStorage.getItem('name')
	return { toggleStatus,userName,roleId};
}

export default withRouter(connect(mapStateToProps)(Header));
