import {
    GET_MEMBER_LIST_REQUEST,
    GET_MEMBER_LIST_SUCCESS,
    GET_MEMBER_LIST_FAILURE,
    DELETE_MEMBER_REQUEST,
    DELETE_MEMBER_SUCCESS,
    DELETE_MEMBER_FAILURE,
    REMOVE_MEMBER_INVITE,
    ADD_MEMBER_INVITE,
    INVITE_MEMBER_REQUEST,
    INVITE_MEMBER_SUCCESS,
    INVITE_MEMBER_FAILURE,
    DEFAULT_MEMBER_STATE, CHANGE_PER_PAGE,
    CLEAR_MEMBER_DATA,
    HANDLE_UPDATE_MEMBER_LIST,
    CLEAR_ALL

} from '../../constants/admin/memberConstant';
import { GET_API , POST_API , DELETE_API } from "../../middleware/symbols";
import {accountApi } from "../ApiActions";

const AUTH_API = accountApi();

export function defaultMemberState() {
    return { type: DEFAULT_MEMBER_STATE }
}
export function getMembersList() {
    return {
        [GET_API]: {
            endpoint: AUTH_API+'v1/adminlist',
            authenticated: true,
            types: [GET_MEMBER_LIST_REQUEST, GET_MEMBER_LIST_SUCCESS, GET_MEMBER_LIST_FAILURE],
        }
    }
}


export function deleteMember(userid) {
    return {
        [DELETE_API]: {
            endpoint: AUTH_API+ '/v1/admin/' + userid,
            authenticated: true,
            types: [DELETE_MEMBER_REQUEST, DELETE_MEMBER_SUCCESS, DELETE_MEMBER_FAILURE],
        }
    }
}

export function removeMember(members) {
    return { type: REMOVE_MEMBER_INVITE, members }
}

export function addMember(obj) {
    return { type: ADD_MEMBER_INVITE, obj }
}

export function inviteMembers(body) {
    return {
        [POST_API]: {
            endpoint: AUTH_API+ '/v1/admininvite',
            authenticated: true,
            types: [INVITE_MEMBER_REQUEST, INVITE_MEMBER_SUCCESS, INVITE_MEMBER_FAILURE],
            body
        }
    }
}

export function changePerPage(value) {
    return { type: CHANGE_PER_PAGE, value }
}

export function clearAll() {
    return {
        type: [CLEAR_ALL],
    }
}

export function clearMemberData() {
    return {
        type: CLEAR_MEMBER_DATA,
    }
}

export function updateMemberList(membersList){
    return {type: HANDLE_UPDATE_MEMBER_LIST, membersList}
}
