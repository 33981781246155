import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/style.css';

class NoDataFound extends Component{

render(){
    const { title, description} = this.props  
    return(                       
        <div className="no-data-found__block">
            <div className="no-data-found__body">
                <img src={require('./dist/no-data-found-error.png')} 
                alt={title !== undefined ? title : 'No Data Found.'}  className="error-img-small" />
                <div className="no-data-found__content">
                    <h3 className="no-data-found__title">{title !== undefined ? title : 'No Data Found'}!</h3>
                    <p className="no-data-found__text">{description!== undefined ? description : 'No data found for this component, Please check for the connectivity.'}</p>
                </div>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return state
}

export default withRouter(connect(mapStateToProps)(NoDataFound))
