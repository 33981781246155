import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import {
    updateLoginForm,
    handleUserLogin,
    defaultLoginState,
} from '../../actions/auth/authActions';
import './dist/style.css';
import './dist/new-login.css';

class Login extends Component {
    handleChange(e) {
        if (e.target.checkValidity()) {
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.parentElement.classList.add('has-error');
        }

        const newState = Object.assign(this.props.loginFormData, {
            [e.target.name]: e.target.value,
        });
        this.props.dispatch(updateLoginForm(newState));
    }

    handleOnSubmit(e) {
        //if invitation form is valid then submit the data, else show the validation message on the invalid field.
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.dispatch(handleUserLogin(this.props.loginFormData));
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    componentDidMount() {
        this.props.dispatch(defaultLoginState());
        if (this.props.isAuthenticated) {
            if (this.props.roleId == 'user') {
                this.props.history.push('/employee/modules');
            } else {
                this.props.history.push('/admin/dashboard');
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isAuthenticated) {
            if (nextProps.redirectLink != '') {
                window.location.replace(nextProps.redirectLink);
            } else {
                if (nextProps.roleId == 'user') {
                    this.props.history.push('/employee/modules');
                } else {
                    this.props.history.push('/admin/dashboard');
                }
            }
        }
    }

    render() {
        const {
            loginErrorMessage,
            isPageLoading,
        } = this.props;
        return (
            <main className="main-content-wrapper full-height">
                <div className="wrapper-container signing-container">
                    <div className="container user-jounrney-form-container">
                        <div className="user-journey-form">
                            <div className="xenonstack-jobs-logo">
                                <a href="https://xenonstack-jobs-dev.neuralcompany.team/">
                                    {' '}
                                    <img
                                        src={require('../../static/images/onboarding-logo.svg')}
                                    />
                                </a>
                            </div>
                            <div className="dashbaord-user-form">
                                <div className="section_header">
                                    <h2 className="big-heading">Sign In</h2>
                                </div>
                                <div className="signup-page" id="signup-page">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10 mar-xs-30">
                                            <div
                                                className="login-div existing-user"
                                                id="existing-user"
                                            >
                                                <form
                                                    className="admin-login-form"
                                                    noValidate={true}
                                                    id="admin-login-form"
                                                    onSubmit={this.handleOnSubmit.bind(
                                                        this
                                                    )}
                                                >
                                                    <Input
                                                        required
                                                        type="email"
                                                        onChange={this.handleChange.bind(
                                                            this
                                                        )}
                                                        pattern={
                                                            '([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}'
                                                        }
                                                        id="email"
                                                        name="email"
                                                        autoComplete={'off'}
                                                        label="Email Address"
                                                        placeholder="Email Address"
                                                        className="form-control"
                                                        outerClassName="login-input-box"
                                                        errorMessage="Please enter a valid email address."
                                                    />

                                                    <Input
                                                        required
                                                        type="password"
                                                        visibility="true"
                                                        passwordShowData='<i  class="fa fa-eye-slash eye-icon" aria-hidden="true"/>'
                                                        passwordHideData='<i  class="fa fa-eye eye-icon" aria-hidden="true"/>'
                                                        name="password"
                                                        autofill={'false'}
                                                        label="Password"
                                                        pattern={
                                                            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.{8,}$'
                                                        }
                                                        maxLength={30}
                                                        minLength={3}
                                                        className="form-control"
                                                        outerClassName="login-input-box"
                                                        placeholder="Password"
                                                        id="password"
                                                        errorMessage="Please enter a valid password"
                                                        onChange={this.handleChange.bind(
                                                            this
                                                        )}
                                                    />
                                                    <div className="form-group form-btn-group">
                                                        {!isPageLoading ? (
                                                            <Button
                                                                type="submit"
                                                                id="send-invite"
                                                                formNoValidate={
                                                                    true
                                                                }
                                                                className="btn box-shadow"
                                                            >
                                                                Login
                                                            </Button>
                                                        ) : (
                                                            <Button className="btn btn-icon icon-left">
                                                                <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                                <span className="btn-text">
                                                                    Please wait
                                                                    ..
                                                                </span>
                                                            </Button>
                                                        )}
                                                    </div>
                                                    <div className="form-link-group">
                                                        <NavLink
                                                            to="/forgot-password"
                                                            className="auth-page-link"
                                                        >
                                                            Forgot Password ?
                                                        </NavLink>
                                                    </div>
                                                    {!isPageLoading &&
                                                        loginErrorMessage && (
                                                            <div className="center-error form-error">
                                                                <p className="error-msg">
                                                                    {
                                                                        loginErrorMessage
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="xenonstack-jobs-copyright">
                                <p>Xenonstack |</p>
                                <i className="fa fa-copyright"></i>
                                <p>2021 All Rights Reserved</p>
                            </div>
                        </div>
                        <div class="sweet-loading"></div>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        loginFormData,
        loginErrorMessage,
        loginSuccessMessage,
        isPageLoading,
        isAuthenticated,
        redirectLink,
        roleId,
    } = state.authReducer;
    return {
        loginFormData,
        loginErrorMessage,
        loginSuccessMessage,
        isPageLoading,
        isAuthenticated,
        redirectLink,
        roleId,
    };
}

export default withRouter(connect(mapStateToProps)(Login));
