import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import {
    inviteMembers,
    addMember,
    removeMember,
    updateMemberList,
    clearMemberData,
} from '../../../../actions/admin/memberAction';
import '../dist/style.css';
import CSVReader from 'react-csv-reader';

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}
class InviteMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            csvError: false,
            csvErrorMsg:
                'Invalid CSV file, Please Upload a file as per the Sample CSV File.',
        };
    }
    componentDidMount() {
        this.props.dispatch(clearMemberData());
    }

    handleRemoveMember(index) {
        const list = this.props.members;
        list.splice(index, 1);
        this.props.dispatch(removeMember(list));
        this.forceUpdate();
    }

    handleAddMember(e) {
        this.props.dispatch(addMember({ email: '', role: '' }));
    }

    skipInvite() {
        this.props.history.push('/register/url-creation');
    }

    handleChange(e, index) {
        this.props.dispatch(clearMemberData());
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }

        let name = e.target.name;
        let value = e.target.value;
        let members = this.props.members;
        members[index][name] = value;

        this.checkDuplicateInObject('email', members);
        this.props.dispatch(updateMemberList(members));
        this.forceUpdate();
    }

    handleInviteMembers(e) {
        this.props.dispatch(updateMemberList(this.props.members));
        //if invitation form is valid then submit the data, else show the validation message on the invalid field.
        e.preventDefault();
        if (
            e.target.checkValidity() &&
            !this.checkDuplicateInObject('email', this.props.members)
        ) {
            let invite = [],
                body = {};
            this.props.members.map((m, i) =>
                invite.push({ email: m.email, role: m.role })
            );
            body = { invite };
            this.props.dispatch(inviteMembers(body));
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    goToMemberList() {
        this.props.dispatch(clearMemberData());
        this.props.history.push('/admin/invite-member/list');
    }

    handleBackToInvite() {
        this.props.dispatch(clearMemberData());
        const members = [
            { email: '', role: '' },
            { email: '', role: '' },
        ];

        this.props.dispatch(updateMemberList(members));
        this.forceUpdate();
    }

    checkDuplicateInObject(propertyName, inputArray) {
        var duplicate = false,
            testObject = {};
        inputArray.map(function (item) {
            var itemPropertyName = item[propertyName];
            if (item.email !== '') {
                if (itemPropertyName in testObject) {
                    testObject[itemPropertyName].duplicate = true;
                    item.duplicate = true;
                    duplicate = true;
                } else {
                    testObject[itemPropertyName] = item;
                    item.duplicate = false;
                    duplicate = false;
                }
            }
        });
        return duplicate;
    }

    handleCSVFileUpload(member) {
        const invite = [];
        let pattern =
            /^(([-\w\d]+)(\.[-\w\d]+)*@((?!gmail.)(?!yahoo.)(?!ymail.)(?!hotmail.)[-\w\d]+)(\.[-\w\d]+)*(\.([a-zA-Z]{2,}|[\d]{1,})){1,2})$/;

        for (let i = 0; i < member.length - 1; i++) {
            const checkmail = pattern.test(
                String(member[i]['0']).toLowerCase()
            );
            if (checkmail === false) {
                this.setState({
                    csvError: true,
                    csvErrorMsg:
                        'CSV file Contain invalid Business Email addresses. Please verify and try again.',
                });
                break;
            } else {
                invite.push({ email: member[i]['0'], role: member[i]['1'] });
                this.setState({
                    csvError: false,
                    csvErrorMsg: '',
                });
            }
        }

        if (invite.length === member.length - 1) {
            let body = {};
            body = { invite };
            this.props.dispatch(inviteMembers(body));
            this.setState({
                csvError: false,
                csvErrorMsg: '',
            });
        } else {
            debugger;
            this.setState({
                csvError: true,
                csvErrorMsg:
                    'Invalid CSV file, Please Upload a file as per the Sample CSV File.',
            });
        }
    }

    handleCSVUploadError(e) {
        this.setState({
            csvError: true,
            csvErrorMsg:
                'Invalid CSV file, Please Upload a file as per the Sample CSV File.',
        });
    }
    render() {
        return (
            <div>
                <main className="main-content-wrapper">
                    <section className="dashboard-content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-left">
                                        <h2 className="content-header-title">
                                            Invite Member
                                        </h2>
                                        <p className="content-header-description">
                                            Add new members to dashboard.
                                        </p>
                                    </div>
                                </div>
                                {isEmpty(this.props.memberAddedStatusList) ===
                                    true && (
                                    <div className="col-12 col-sm-6 col-md-6">
                                        <div className="dashboard-content-header-right dashboard-header-action-group">
                                            <div>
                                                <Button
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            '/admin/invite-member/list'
                                                        )
                                                    }
                                                    className="btn btn-icon icon-left"
                                                    href="/admin/employee/create-module"
                                                >
                                                    <span className="btn-text">
                                                        View Member
                                                    </span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                    <div class="dashboard-body-wrapper">
                        <div class="container">
                            <div class="main-content-body">
                                <section className="content-section-wrapper">
                                    <div className="row invite-employee-form-outer content-section-card">
                                        {isEmpty(
                                            this.props.memberAddedStatusList
                                        ) === true ? (
                                            <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                                                <div class="add-kube-group desktop-only">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <label>
                                                                Email Address
                                                            </label>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <label>Role</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <form
                                                    className="invite-member-form"
                                                    noValidate={true}
                                                    name="invite-member-form"
                                                    id="invite-member-form"
                                                    onSubmit={this.handleInviteMembers.bind(
                                                        this
                                                    )}
                                                >
                                                    <div
                                                        className="invite-member-list"
                                                        id="invite-member-list"
                                                    >
                                                        {this.props.members.map(
                                                            (member, index) => (
                                                                <div
                                                                    className="add-kube-group"
                                                                    key={index}
                                                                >
                                                                    <div className="row">
                                                                        <div className="col-sm-6">
                                                                            <div className="form-group">
                                                                                <input
                                                                                    type="email"
                                                                                    placeholder="name@example.com"
                                                                                    name={
                                                                                        'email'
                                                                                    }
                                                                                    value={
                                                                                        member.email
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.handleChange(
                                                                                            e,
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                    className="form-control"
                                                                                    required={
                                                                                        true
                                                                                    }
                                                                                    id={
                                                                                        'email-' +
                                                                                        index
                                                                                    }
                                                                                    maxLength={
                                                                                        50
                                                                                    }
                                                                                    minLength={
                                                                                        3
                                                                                    }
                                                                                    autoComplete={
                                                                                        'off'
                                                                                    }
                                                                                    pattern="^([-\w\d]+)(\.[-\w\d]+)*@([-\w\d]+)(\.[-\w\d]{2,})$"
                                                                                />
                                                                                {this
                                                                                    .props
                                                                                    .memberAddedStatusList[
                                                                                    index
                                                                                ] ===
                                                                                false ? (
                                                                                    <span className="member-status-check exist">
                                                                                        Already
                                                                                        Exist!
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {this
                                                                                    .props
                                                                                    .memberAddedStatusList[
                                                                                    index
                                                                                ] ===
                                                                                true ? (
                                                                                    <span className="member-status-check added">
                                                                                        Added!
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {this
                                                                                    .props
                                                                                    .members[
                                                                                    index
                                                                                ]
                                                                                    .duplicate ===
                                                                                true ? (
                                                                                    <span className="member-status-check exist">
                                                                                        Duplicate
                                                                                        email
                                                                                        address.
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                <div className="help-block with-errors">
                                                                                    Please
                                                                                    enter
                                                                                    valid
                                                                                    email
                                                                                    address.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <div className="form-group">
                                                                                <div className="select-wrapper">
                                                                                    <select
                                                                                        id={
                                                                                            'projectCategory' +
                                                                                            index
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.handleChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        name="role"
                                                                                        value={
                                                                                            member.role
                                                                                        }
                                                                                        className="form-control"
                                                                                        required={
                                                                                            true
                                                                                        }
                                                                                    >
                                                                                        <option
                                                                                            defaultValue
                                                                                            disabled={
                                                                                                true
                                                                                            }
                                                                                            value=""
                                                                                        >
                                                                                            Select
                                                                                            Role
                                                                                        </option>
                                                                                        <option value="Admin">
                                                                                            Admin
                                                                                        </option>
                                                                                        <option value="Reviewer">
                                                                                            Reviewer
                                                                                        </option>
                                                                                    </select>
                                                                                    <div className="help-block with-errors">
                                                                                        Please
                                                                                        select
                                                                                        role.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span
                                                                        className="cross-icon"
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            this.handleRemoveMember(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="fa fa-trash-o"></i>
                                                                    </span>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div
                                                        className="add-kube-group add-more-group"
                                                        id="add-more-group"
                                                    >
                                                        <div className="row">
                                                            <div className="col-sm-12 text-right">
                                                                <span
                                                                    className="add-more-link"
                                                                    onClick={this.handleAddMember.bind(
                                                                        this
                                                                    )}
                                                                >
                                                                    <i className="fa fa-plus"></i>
                                                                    Add More
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr class="m-2-rem light-grey-hr"></hr>
                                                    <div
                                                        style={{
                                                            paddingRight:
                                                                '52px',
                                                        }}
                                                    >
                                                        <div className="row">
                                                            <div className="col-12 col-sm-5 col-md-6">
                                                                <div className="">
                                                                    <Button
                                                                        type="submit"
                                                                        id="send-invite"
                                                                        formNoValidate={
                                                                            true
                                                                        }
                                                                        className="btn"
                                                                    >
                                                                        Send
                                                                        Invitation
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-7 col-md-6">
                                                                <div className="invite-via-csv-outer">
                                                                    <a
                                                                        href="https://s3-us-west-2.amazonaws.com/members-samples/members-sample.csv"
                                                                        rel="noopener noreferrer"
                                                                        className=" add-more-link download-csv"
                                                                        target="_blank"
                                                                    >
                                                                        Download
                                                                        Sample
                                                                    </a>
                                                                    <div
                                                                        className="csv-btn-group"
                                                                        id="csv-btn-group"
                                                                    >
                                                                        <CSVReader
                                                                            cssClass="file-upload-btn btn box-shadow"
                                                                            label="Invite Via CSV"
                                                                            onFileLoaded={this.handleCSVFileUpload.bind(
                                                                                this
                                                                            )}
                                                                            onError={this.handleCSVUploadError.bind(
                                                                                this
                                                                            )}
                                                                            inputId="csv-fileUplaod"
                                                                            cssInputClass="file-input-ctrl"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.csvError && (
                                                        <div className="form-error">
                                                            <p className="error-msg">
                                                                {
                                                                    this.state
                                                                        .csvErrorMsg
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                                    {this.props
                                                        .errorMessage && (
                                                        <div className="form-error">
                                                            <p className="error-msg">
                                                                {
                                                                    this.props
                                                                        .errorMessage
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                                </form>
                                            </div>
                                        ) : (
                                            <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                                                <div
                                                    className="invite-team-centered invite-team-response"
                                                    id="invite-team-response"
                                                >
                                                    <section
                                                        className="dashboard-content-box"
                                                        id="dashboard-content-box"
                                                    >
                                                        <div
                                                            className="invite-member-message-box"
                                                            id="invite-member-message-box"
                                                        >
                                                            {this.props
                                                                .memberAddedStatusList
                                                                .add !==
                                                                undefined &&
                                                            this.props
                                                                .memberAddedStatusList
                                                                .add.length >
                                                                0 ? (
                                                                <div className="invite-team-header">
                                                                    <h3 className="success">
                                                                        Invitation
                                                                        Sent
                                                                        Successfully!
                                                                    </h3>
                                                                    <p className="success">
                                                                        You’ve
                                                                        successfully
                                                                        invited{' '}
                                                                        {
                                                                            this
                                                                                .props
                                                                                .memberAddedStatusList
                                                                                .add
                                                                                .length
                                                                        }{' '}
                                                                        Member(s).{' '}
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {this.props
                                                                .memberAddedStatusList
                                                                .add !==
                                                                undefined &&
                                                                this.props
                                                                    .memberAddedStatusList
                                                                    .add
                                                                    .length >
                                                                    0 && (
                                                                    <table
                                                                        className="invite-member-message-table sucess-table"
                                                                        id="sucess-table"
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>
                                                                                    Email{' '}
                                                                                </th>
                                                                                <th>
                                                                                    Designation{' '}
                                                                                </th>
                                                                                <th>
                                                                                    Message{' '}
                                                                                </th>
                                                                            </tr>
                                                                            {this.props.memberAddedStatusList.add.map(
                                                                                (
                                                                                    member,
                                                                                    index
                                                                                ) => (
                                                                                    <tr
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        <td data-label="Email">
                                                                                            {
                                                                                                member.email
                                                                                            }
                                                                                        </td>
                                                                                        <td data-label="Designation">
                                                                                            {
                                                                                                member.role
                                                                                            }{' '}
                                                                                        </td>
                                                                                        <td data-label="Message">
                                                                                            {
                                                                                                member.message
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                )}
                                                            {this.props
                                                                .memberAddedStatusList
                                                                .not_add !==
                                                                undefined &&
                                                            this.props
                                                                .memberAddedStatusList
                                                                .not_add
                                                                .length > 0 ? (
                                                                <div className="invite-team-header">
                                                                    <h3 className="error">
                                                                        Invitation
                                                                        not Sent
                                                                        !
                                                                    </h3>
                                                                    <p className="error">
                                                                        {
                                                                            this
                                                                                .props
                                                                                .memberAddedStatusList
                                                                                .not_add
                                                                                .length
                                                                        }
                                                                        &nbsp;
                                                                        invitation(s)
                                                                        didn't
                                                                        send.
                                                                        Please
                                                                        Review
                                                                        the
                                                                        Error
                                                                        Message.
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {this.props
                                                                .memberAddedStatusList
                                                                .not_add !==
                                                                undefined &&
                                                                this.props
                                                                    .memberAddedStatusList
                                                                    .not_add
                                                                    .length >
                                                                    0 && (
                                                                    <table
                                                                        className="invite-member-message-table error-table"
                                                                        id="error-table"
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>
                                                                                    Email{' '}
                                                                                </th>
                                                                                <th>
                                                                                    Designation{' '}
                                                                                </th>
                                                                                <th>
                                                                                    Message{' '}
                                                                                </th>
                                                                            </tr>
                                                                            {this.props.memberAddedStatusList.not_add.map(
                                                                                (
                                                                                    member,
                                                                                    index
                                                                                ) => (
                                                                                    <tr
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        <td data-label="Email">
                                                                                            {
                                                                                                member.email
                                                                                            }
                                                                                        </td>
                                                                                        <td data-label="Designation">
                                                                                            {
                                                                                                member.role
                                                                                            }{' '}
                                                                                        </td>
                                                                                        <td data-label="Message">
                                                                                            {
                                                                                                member.message
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                )}
                                                        </div>
                                                        <div className="xd-btn-group">
                                                            <Button
                                                                onClick={this.handleBackToInvite.bind(
                                                                    this
                                                                )}
                                                            >
                                                                Invite More
                                                                Member
                                                            </Button>
                                                            <Button
                                                                onClick={this.goToMemberList.bind(
                                                                    this
                                                                )}
                                                            >
                                                                View All Members
                                                            </Button>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        isPageLoading,
        members,
        invited,
        errorMessage,
        memberAddedStatusList,
        workspace,
    } = state.member;
    const { toggleStatus } = state.appReducer;
    return {
        isPageLoading,
        members,
        invited,
        errorMessage,
        memberAddedStatusList,
        toggleStatus,
        workspace,
    };
}

export default withRouter(connect(mapStateToProps)(InviteMember));
