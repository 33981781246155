import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    BlockLoader,
} from 'xd-react-loader';
import {
    getEmployeeProfile,
    changeProfileImage,
    handleEditProfilePictureDialogChange,
    setPersonalInfo,
} from '../../../actions/employee/userActions';
import { Avatar } from 'xd-react-avatar';
import { isObjectEmpty } from '../../../utils';
import 'react-circular-progressbar/dist/styles.css';
import EditProfilePicturePopup from './edit-profile/EditProfilePicturePopup';

class UserProfileSidebar extends Component {
    componentDidMount() {
        this.props.dispatch(getEmployeeProfile());
    }
    handleEditProfilePictureDialog(personalInfo) {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleEditProfilePictureDialogChange(true));
        if (personalInfo !== '') {
            let personal = Object.assign(
                {},
                this.props.profileInfo.profile.personal
            );
            this.props.dispatch(setPersonalInfo(personal));
        }
    }
    changeProfilePicture(e) {
        e.preventDefault();
        let input = e.target;
        const details = {
            account_info: {
                name: this.props.profileInfo.profile.account.name,
                contact: this.props.profileInfo.profile.account.contact_no,
            },
            personal_info: this.props.profileInfo.profile.personal,
            user_address: this.props.profileInfo.profile.address,
            education: this.props.profileInfo.profile.education,
            emergency_contact: this.props.profileInfo.profile.emergency,
            others: this.props.profileInfo.profile.others,
        };
        if (input.files[0]) {
            let file = input.files[0];
            this.props.dispatch(changeProfileImage(file, details));
        }
    }

    render() {
        const { profileInfo } = this.props;
        return (
            <div className="profile-sidebar-content-wrapper">
                {this.props.isUserProfileLoading ? (
                    <div className="xs-loader-wrapper">
                        <BlockLoader
                            loadingText="loading"
                            loading={true}
                            loaderStyle={{
                                size: '20px',
                                color: '0562e8',
                                textSize: '18px',
                                textColor: '#485e7d',
                                speed: '1s',
                            }}
                        />
                    </div>
                ) : (
                    <div className="profile-sidebar-content">
                        {!isObjectEmpty(profileInfo) && (
                            <div class="profile-img-wapper">
                                <div className="profile-img">
                                    <div className="profile-img-content">
                                        {profileInfo.link === '' ? (
                                            <Avatar
                                                size={120}
                                                name={
                                                    profileInfo.profile.account
                                                        .name !== ''
                                                        ? profileInfo.profile
                                                              .account.name
                                                        : 'XS'
                                                }
                                            />
                                        ) : (
                                            <Avatar
                                                color={'#F99316'}
                                                size={120}
                                                name={
                                                    profileInfo.profile.account
                                                        .name !== ''
                                                        ? profileInfo.profile
                                                              .account.name
                                                        : 'XS'
                                                }
                                                src={
                                                    profileInfo.link !== ''
                                                        ? profileInfo.link
                                                        : ''
                                                }
                                            />
                                        )}

                                        <div
                                            onClick={(e) =>
                                                this.handleEditProfilePictureDialog(
                                                    profileInfo.profile.personal
                                                )
                                            }
                                            class="edit-profile-img"
                                        >
                                            <i className="icon-pencil"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-img-meta">
                                    <h3>{profileInfo.profile.account.name}</h3>
                                    <p>
                                        {profileInfo.profile.account.level ===
                                            'L1' && 'Level 1 Intern'}
                                        {profileInfo.profile.account.level ===
                                            'L2' && 'Level 2 Intern'}
                                        {profileInfo.profile.account.level ===
                                            'L3' && 'Level 3 Intern'}
                                        {profileInfo.profile.account.level ===
                                            'employee' && 'Employee'}
                                    </p>
                                </div>
                            </div>
                        )}
                        <hr className="section-divider" />
                        {!isObjectEmpty(profileInfo) && (
                            <div className="profile-comple-status">
                                <article className="profile-status-content">
                                    <div className="profile-overall-status">
                                        <h3>Profile Status</h3>
                                        {profileInfo.percentage.total < 100 ? (
                                            <p>
                                                Your profile looks{' '}
                                                <strong>Incomplete</strong>!
                                            </p>
                                        ) : (
                                            <p>
                                                Your profile is{' '}
                                                <strong>Complete</strong>!
                                            </p>
                                        )}
                                    </div>
                                    <ul className="status-progress-list">
                                        <li>
                                            <div className="profile-progress-item">
                                                <h4>Overall Status</h4>
                                                <strong>
                                                    {
                                                        profileInfo.percentage
                                                            .total
                                                    }
                                                    %
                                                </strong>
                                                <p
                                                    className={`${
                                                        profileInfo.percentage
                                                            .total == 100
                                                            ? 'progress-color-green'
                                                            : profileInfo
                                                                  .percentage
                                                                  .total < 50
                                                            ? 'progress-color-red'
                                                            : 'progress-color-yellow'
                                                    } profile-progress-bar`}
                                                    style={{
                                                        width:
                                                            profileInfo
                                                                .percentage
                                                                .total + '%',
                                                    }}
                                                ></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="profile-progress-item">
                                                <h4>Personal Information</h4>
                                                <strong>
                                                    {
                                                        profileInfo.percentage
                                                            .personal_info
                                                    }
                                                    %
                                                </strong>
                                                <p
                                                    className={`${
                                                        profileInfo.percentage
                                                            .personal_info ==
                                                        100
                                                            ? 'progress-color-green'
                                                            : profileInfo
                                                                  .percentage
                                                                  .personal_info <
                                                              50
                                                            ? 'progress-color-red'
                                                            : 'progress-color-yellow'
                                                    } profile-progress-bar`}
                                                    style={{
                                                        width:
                                                            profileInfo
                                                                .percentage
                                                                .personal_info +
                                                            '%',
                                                    }}
                                                ></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="profile-progress-item ">
                                                <h4>Educational Information</h4>
                                                <strong>
                                                    {
                                                        profileInfo.percentage
                                                            .educational_info
                                                    }
                                                    %
                                                </strong>
                                                <p
                                                    className={`${
                                                        profileInfo.percentage
                                                            .educational_info ==
                                                        100
                                                            ? 'progress-color-green'
                                                            : profileInfo
                                                                  .percentage
                                                                  .educational_info <
                                                              50
                                                            ? 'progress-color-red'
                                                            : 'progress-color-yellow'
                                                    } profile-progress-bar`}
                                                    style={{
                                                        width:
                                                            profileInfo
                                                                .percentage
                                                                .educational_info +
                                                            '%',
                                                    }}
                                                ></p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="profile-progress-item">
                                                <h4>Other Information</h4>
                                                <strong>
                                                    {
                                                        profileInfo.percentage
                                                            .others
                                                    }
                                                    %
                                                </strong>
                                                <p
                                                    className={`${
                                                        profileInfo.percentage
                                                            .others == 100
                                                            ? 'progress-color-green'
                                                            : profileInfo
                                                                  .percentage
                                                                  .others < 50
                                                            ? 'progress-color-red'
                                                            : 'progress-color-yellow'
                                                    } profile-progress-bar`}
                                                    style={{
                                                        width:
                                                            profileInfo
                                                                .percentage
                                                                .others + '%',
                                                    }}
                                                ></p>
                                            </div>
                                        </li>
                                    </ul>
                                </article>
                                <EditProfilePicturePopup />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
    } = state.userReducer;
    return {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
    };
}

export default withRouter(connect(mapStateToProps)(UserProfileSidebar));
