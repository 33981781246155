import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import '../assign-module/dist/style.css';
import './dist/style.css';
import createNotification from "../../../components/app/notification/createNotification";
import { clearSelectedModuleData} from '../../../actions/admin/employeeActions';
import {getReviewerList, assignReviewer, storeSelectedReviewerData,removeSelectedReviewerData, storeAllReviewers, clearAssignReviewerData} from '../../../actions/admin/reviewerActions';
class ListReviewer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      showFeedbackModal: false,
    };
    this.checkAllCheckbox=this.checkAllCheckbox.bind(this)
  }
  componentWillMount() {
      this.props.dispatch(getReviewerList())
    this.props.dispatch(clearSelectedModuleData())
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.reviewerAssignedSuccessList.length!==0 || nextProps.reviewerAssignedErrorList.length!==0){
      nextProps.history.push('/admin/employee/assign-reviewer/status')
    }
    if(nextProps.assignReviewerErrorMsg.length!==0){
      createNotification('error', nextProps.assignReviewerErrorMsg);
      nextProps.dispatch(clearAssignReviewerData());
    }
  }
  
  handleAssignReviewer(e){
    e.preventDefault();
    let email = [], list;
      if (this.props.employeeList.length === 0) {
        list = this.props.internsList
      } else {
        list = this.props.employeeList
      }
      list.map((m, i) => email.push(m.email))
      let body = {
        interns: email,
        reviewers:this.props.selectedReviewers ,
      }
    this.props.dispatch(assignReviewer(body))
  }

  checkAllCheckbox(e) {
    var checkboxes = document.getElementsByName('reviewers');
    let list=[]
   if(e.target.checked){
    for (var checkbox of checkboxes) {
			if (checkbox.checked === false) {
				checkbox.checked = true;
			}
    }
    this.props.reviewerList.map((m, i) => list.push(m.email))
    this.props.dispatch(storeAllReviewers(list))
   }
  }

  checkBoxSelectionEvent(e,data) {
    if(e.target.checked){
      this.props.dispatch(storeSelectedReviewerData(data))
    }
    else{
      const deleteIndex=(this.props.selectedReviewers).indexOf(data)
      this.props.dispatch(removeSelectedReviewerData(deleteIndex))
    }
		
	}
  render() {
    window.onbeforeunload = function () {
      return 'Are you sure you want to leave?';
    }
    let list=[];
    if(this.props.employeeList.length === 0) {
      list = this.props.internsList
    } else {
      list = this.props.employeeList
    }

    window.onbeforeunload = function () {
      this.alert('do you want to reload')
    };
    return (
      <React.Fragment>
        <main className="main-content-wrapper">
          <section className="dashboard-content-header">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6">
                  <div className="dashboard-content-header-left">
                    <h2 className="content-header-title">Assign Reviewers</h2>
                     <p className="content-header-description">Assign the reviewers to interns.</p> 
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6">
                  <div className="dashboard-content-header-right dashboard-header-action-group">

                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="dashboard-body-wrapper">
            <div className="container">
              <div className="main-content-body">
                <section className="content-section-wrapper">
                  <div className="table-wrapper">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                          <div className="heading">Selected Intern(s)</div>
                        </div>
                      </div>
                      
                    </div>
                    <div>
                      <div className="table-container" >
                        <table className="listing-table" style={{width:"100%"}} >
                          <thead>
                            <tr>
                              <th className="sno-sel-intern">Sr. No</th>
                              <th className="sel-intern-name">Name</th>
                              <th className="sel-intern-email">Email</th>
                              <th className="sel-intern-actions">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list.length > 0 && 
                            list.map((l,i)=>
                            <tr>
                              <td className="content sno-sel-intern">{i+1}.</td>
                              <td className="content sel-intern-name"> {l.name}</td>
                              <td className="content sel-intern-email">{l.email}</td>
                              <td className="content sel-intern-actions">
                                <button type="button" disabled={true}  className="xd-table-btn delete" ><i class="fa fa-trash" aria-hidden="true"></i></button>
                              </td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                    </div>
                  </div>
                </section>
                <section className="content-section-wrapper">
                  <div className="table-wrapper">
                    <div className="heading">Select Reviewer(s)</div>
                    <div>
                      <div className="table-container " >
                        <table className="listing-table " style={{width:"100%"}}>
                          <thead>
                            <tr>
                              <th ><input type="checkbox" onClick={(e) => this.checkAllCheckbox(e)} /></th>
                              <th className="sno-reviewer">Sr. No</th>
                              <th className="reviewer-name">Name</th>
                              <th className="reviewer-email">Email</th>
                              <th className="reviewer-actions">Role</th>

                            </tr>
                          </thead>
                          <tbody>
                            {(!this.props.reviewerListLoading && this.props.reviewerList.length > 0) &&
                             this.props.reviewerList.map((reviewer,i)=>
                            <tr>
                              <td ><input type="checkbox" 
                              onClick={(e) => this.checkBoxSelectionEvent(e,reviewer.email)} 
                              name="reviewers"/></td>
                              <td className="content sno-reviewer">{i+1}</td>
                              <td className="content reviewer-name"> {reviewer.name}</td>
                              <td className="content reviewer-email">{reviewer.email}</td>
                              <td className="content reviewer-actions">{reviewer.role.charAt(0).toUpperCase() + reviewer.role.slice(1)}</td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

                <div className="xd-btn-group" style={{marginLeft:"7px"}}>
                  <Button onClick={() => this.props.history.push("/admin/employee/l1")} >Back</Button>
                  <Button type="submit" onClick={(e) => this.handleAssignReviewer(e)}>Assign</Button>
                </div>
              </div>
          </div>
          
        </main>
      </React.Fragment>
    )
  }
}


function mapStateToProps(state) {
  const { internsList, employeeList} = state.employeeReducer;
  const {reviewerListLoading, reviewerListErrorMsg, reviewerListSuccessMsg, reviewerList, selectedReviewers, assignReviewerSuccess, assignReviewerSuccessMsg,assignReviewerErrorMsg, reviewerAssignedSuccessList , reviewerAssignedErrorList}= state.reviewerReducer;
  return { internsList,employeeList, reviewerListLoading, reviewerListErrorMsg, reviewerListSuccessMsg, reviewerList, selectedReviewers, assignReviewerSuccess, assignReviewerSuccessMsg,assignReviewerErrorMsg, reviewerAssignedSuccessList , reviewerAssignedErrorList}
}

export default withRouter(connect(mapStateToProps)(ListReviewer))