export const GET_REVIEWER_LIST_REQUEST="GET_REVIEWER_LIST_REQUEST";
export const GET_REVIEWER_LIST_SUCCESS="GET_REVIEWER_LIST_SUCCESS";
export const GET_REVIEWER_LIST_FAILURE="GET_REVIEWER_LIST_FAILURE";

export const ASSIGN_REVIEWER_REQUEST="ASSIGN_REVIEWER_REQUEST";
export const ASSIGN_REVIEWER_SUCCESS="ASSIGN_REVIEWER_SUCCESS";
export const ASSIGN_REVIEWER_FAILURE="ASSIGN_REVIEWER_FAILURE";

export const STORE_SELECTED_REVIEWER_DATA="STORE_SELECTED_REVIEWER_DATA";
export const CLEAR_ASSIGN_REVIEWER_DATA="CLEAR_ASSIGN_REVIEWER_DATA";

export const ASSIGNED_REVIEWER_LIST_REQUEST="ASSIGNED_REVIEWER_LIST_REQUEST";
export const ASSIGNED_REVIEWER_LIST_SUCCESS="ASSIGNED_REVIEWER_LIST_SUCCESS";
export const ASSIGNED_REVIEWER_LIST_FAILURE="ASSIGNED_REVIEWER_LIST_FAILURE";

export const DELETE_ASSIGNED_REVIEWER_REQUEST="DELETE_ASSIGNED_REVIEWER_REQUEST";
export const DELETE_ASSIGNED_REVIEWER_SUCCESS="DELETE_ASSIGNED_REVIEWER_SUCCESS";
export const DELETE_ASSIGNED_REVIEWER_FAILURE="DELETE_ASSIGNED_REVIEWER_FAILURE";

export const CLEAR_DELETE_REVIEWER_DATA="CLEAR_DELETE_REVIEWER_DATA";
export const REMOVE_SELECTED_REVIEWER_DATA="REMOVE_SELECTED_REVIEWER_DATA";
export const STORE_ALL_REVIEWERS_AS_SELECTED="STORE_ALL_REVIEWERS_AS_SELECTED";

export const ADD_MEMBER_TO_MEETING_LIST="ADD_MEMBER_TO_MEETING_LIST";
export const REMOVE_MEMBER_FROM_MEETING_LIST="REMOVE_MEMBER_FROM_MEETING_LIST";
export const UPDATE_REVIEW_MEETING_DETAILS="UPDATE_REVIEW_MEETING_DETAILS";

export const SEND_REVIEW_MEETING_INVITE_REQUEST="SEND_REVIEW_MEETING_INVITE_REQUEST";
export const SEND_REVIEW_MEETING_INVITE_SUCCESS="SEND_REVIEW_MEETING_INVITE_SUCCESS";
export const SEND_REVIEW_MEETING_INVITE_FAILURE="SEND_REVIEW_MEETING_INVITE_FAILURE";
export const CLEAR_REVIEW_MEETING_DATA="CLEAR_REVIEW_MEETING_DATA";
export const CLEAR_REVIEW_INVITE_ERROR_DATA="CLEAR_REVIEW_INVITE_ERROR_DATA";

export const CHECK_CREDENTIALS_REQUEST="CHECK_CREDENTIALS_REQUEST";
export const CHECK_CREDENTIALS_SUCCESS="CHECK_CREDENTIALS_SUCCESS";
export const CHECK_CREDENTIALS_FAILURE="CHECK_CREDENTIALS_FAILURE";

export const GOOGLE_LOGIN_REQUEST="GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS="GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAILURE="GOOGLE_LOGIN_FAILURE";

export const CLEAR_CHECK_CREDENTIALS_DATA="CLEAR_CHECK_CREDENTIALS_DATA";
