import {
    ADD_NEW_FROM_FIELDS,
    REMOVE_FORM_FIELDS,
    ADD_NEW_DROPDOWN_OPTION,
    REMOVE_DROPDOWN_OPTION,
    UPDATE_FORM_DETAIL_DATA,
    GET_FEEDBACK_FORM_LIST_REQUEST,
    GET_FEEDBACK_FORM_LIST_SUCCESS,
    GET_FEEDBACK_FORM_LIST_FAILURE,
    SET_SELECTED_FEEDBACK_FORM,
    DELETE_FEEDBACK_FORM_REQUEST,
    DELETE_FEEDBACK_FORM_SUCCESS,
    DELETE_FEEDBACK_FORM_FAILURE,
    CLEAR_FEEDBACK_FORM_DATA,
    GET_FEEDBACK_FORM_DETAIL_REQUEST,
    GET_FEEDBACK_FORM_DETAIL_SUCCESS,
    GET_FEEDBACK_FORM_DETAIL_FAILURE,
    UPDATE_FEEDBACK_FORM_DETAILS,
    UPDATE_FEEDBACK_FORM_REQUEST,
    UPDATE_FEEDBACK_FORM_SUCCESS,
    UPDATE_FEEDBACK_FORM_FAILURE,
    ASSIGN_FEEDBACK_FORM_REQUEST,
    ASSIGN_FEEDBACK_FORM_SUCCESS,
    ASSIGN_FEEDBACK_FORM_FAILURE,
    CLEAR_ASSIGN_FEEDBACK_FORM_DATA,
    EDIT_FORM_DIALOG_STATE,
    SET_FORM_FIELD_DATA,
    UPDATE_FORM_FIELDS,
    CLEAR_REASSIGN_FORM_DATA,
    REASSIGN_FEEDBACK_FORM_REQUEST,
    REASSIGN_FEEDBACK_FORM_SUCCESS,
    REASSIGN_FEEDBACK_FORM_FAILURE,
    CREATE_FEEDBACK_FORM_REQUEST,
    CREATE_FEEDBACK_FORM_SUCCESS,
    CREATE_FEEDBACK_FORM_FAILURE,
    CLEAR_CREATE_FEEDBACK_FORM_DATA,
    UPDATE_REMOVE_FIELDS,
    CLEAR_UPDATE_FORM_DATA,
    ADD_NEW_UPDATE_FIELD,
    UPDATE_FORM_DIALOG_CHANGE,
    EDIT_UPDATE_FORM_FIELD,
    ADD_FORM_FEEDBACK_DATA,
    GET_MODULE_FEEDBACK_FORM_REQUEST,
    GET_MODULE_FEEDBACK_FORM_SUCCESS,
    GET_MODULE_FEEDBACK_FORM_FAILURE,
    SUBMIT_FEEDBACK_FORM_REQUEST,
    SUBMIT_FEEDBACK_FORM_SUCCESS,
    SUBMIT_FEEDBACK_FORM_FAILURE,
    CLEAR_SUBMIT_FEEDBACK_DATA,
    GET_INTERN_FEEDBACK_LIST_REQUEST,
    GET_INTERN_FEEDBACK_LIST_SUCCESS,
    GET_INTERN_FEEDBACK_LIST_FAILURE,
    GET_INTERN_FEEDBACK_REQUEST,
    GET_INTERN_FEEDBACK_SUCCESS,
    GET_INTERN_FEEDBACK_FAILURE,
} from '../../constants/employee/feedbackConstants';
import {
    GET_API,
    DELETE_API,
    POST_API,
    PUT_API,
} from '../../middleware/symbols';
import { getDocumentApi, accountApi, moduleApi } from '../ApiActions';

const AUTH_API = accountApi();
const DOCUMENT_API = getDocumentApi();
const MODULE_API = moduleApi();

export function addNewFormFields(fieldsProperty) {
    return { type: ADD_NEW_FROM_FIELDS, fieldsProperty };
}

export function handleRemoveFormFields(fieldsProperty) {
    return { type: REMOVE_FORM_FIELDS, fieldsProperty };
}
export function addNewOption(data, index) {
    return { type: ADD_NEW_DROPDOWN_OPTION, data, index };
}
export function removeFromOptions(data) {
    return { type: REMOVE_DROPDOWN_OPTION, data };
}
export function updateFormDetailData(data) {
    return { type: UPDATE_FORM_DETAIL_DATA, data };
}
export function getFeedbackFormList() {
    return {
        [GET_API]: {
            endpoint: MODULE_API + `/v1/form/list`,
            authenticated: true,
            types: [
                GET_FEEDBACK_FORM_LIST_REQUEST,
                GET_FEEDBACK_FORM_LIST_SUCCESS,
                GET_FEEDBACK_FORM_LIST_FAILURE,
            ],
        },
    };
}
export function setSelectedFeedbackFormData(data) {
    return { type: SET_SELECTED_FEEDBACK_FORM, data };
}
export function deleteFeedbackForm(body, id) {
    return {
        [DELETE_API]: {
            endpoint: MODULE_API + `/v1/form/delete/` + id,
            authenticated: true,
            types: [
                DELETE_FEEDBACK_FORM_REQUEST,
                DELETE_FEEDBACK_FORM_SUCCESS,
                DELETE_FEEDBACK_FORM_FAILURE,
            ],
            body: body,
        },
    };
}
export function clearFeedbackData() {
    return { type: CLEAR_FEEDBACK_FORM_DATA };
}
export function getFeedbackFormDetails(body, id) {
    return {
        [GET_API]: {
            endpoint: MODULE_API + `/v1/form/details/` + id,
            authenticated: true,
            types: [
                GET_FEEDBACK_FORM_DETAIL_REQUEST,
                GET_FEEDBACK_FORM_DETAIL_SUCCESS,
                GET_FEEDBACK_FORM_DETAIL_FAILURE,
            ],
            body: body,
        },
    };
}
export function getModuleFeedbackForm(body) {
    return {
        [GET_API]: {
            endpoint: MODULE_API + `/v1/internsform/details/${body.formid}`,
            authenticated: true,
            types: [
                GET_MODULE_FEEDBACK_FORM_REQUEST,
                GET_MODULE_FEEDBACK_FORM_SUCCESS,
                GET_MODULE_FEEDBACK_FORM_FAILURE,
            ],
            body: body,
        },
    };
}
export function updateFeedbackFormDetails(data) {
    return { type: UPDATE_FEEDBACK_FORM_DETAILS, data };
}
export function updateFeedbackForm(data, body) {
    return {
        [PUT_API]: {
            endpoint: MODULE_API + '/v1/form/update/' + data.formid,
            authenticated: true,
            types: [
                UPDATE_FEEDBACK_FORM_REQUEST,
                UPDATE_FEEDBACK_FORM_SUCCESS,
                UPDATE_FEEDBACK_FORM_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}
export function assignFeedbackForm(body) {
    return {
        [POST_API]: {
            endpoint: MODULE_API + '/v1/form/assign',
            authenticated: true,
            types: [
                ASSIGN_FEEDBACK_FORM_REQUEST,
                ASSIGN_FEEDBACK_FORM_SUCCESS,
                ASSIGN_FEEDBACK_FORM_FAILURE,
            ],
            body: body,
        },
    };
}
export function clearAssignFeedbackData() {
    return {
        type: CLEAR_ASSIGN_FEEDBACK_FORM_DATA,
    };
}
export function handleEdiFormDialogChange(status) {
    return {
        type: EDIT_FORM_DIALOG_STATE,
        status,
    };
}
export function setFormFieldData(data, index) {
    return { type: SET_FORM_FIELD_DATA, data, index };
}
export function updateFormFields(data) {
    return { type: UPDATE_FORM_FIELDS, data };
}
export function editupdateFormFields(data) {
    return { type: EDIT_UPDATE_FORM_FIELD, data };
}
export function reassignFeedbackForm(body) {
    return {
        [PUT_API]: {
            endpoint: MODULE_API + '/v1/form/assign',
            authenticated: true,
            types: [
                REASSIGN_FEEDBACK_FORM_REQUEST,
                REASSIGN_FEEDBACK_FORM_SUCCESS,
                REASSIGN_FEEDBACK_FORM_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}
export function clearReassignFeedbackData() {
    return { type: CLEAR_REASSIGN_FORM_DATA };
}
export function createNewFeedbackForm(body) {
    return {
        [POST_API]: {
            endpoint: MODULE_API + '/v1/form/create',
            authenticated: true,
            types: [
                CREATE_FEEDBACK_FORM_REQUEST,
                CREATE_FEEDBACK_FORM_SUCCESS,
                CREATE_FEEDBACK_FORM_FAILURE,
            ],
            body: body,
        },
    };
}
export function clearCreateFeedbackData() {
    return { type: CLEAR_CREATE_FEEDBACK_FORM_DATA };
}
export function handleUpdateRemoveFields(data) {
    return { type: UPDATE_REMOVE_FIELDS, data };
}
export function clearUpdateFeedbackData() {
    return { type: CLEAR_UPDATE_FORM_DATA };
}
export function addNewUpdateFields(data) {
    return { type: ADD_NEW_UPDATE_FIELD, data };
}
export function handleUpdateFormDialogChange(status) {
    return { type: UPDATE_FORM_DIALOG_CHANGE, status };
}
export function addFormFeedback(data) {
    return { type: ADD_FORM_FEEDBACK_DATA, data };
}
export function submitFeedbackForm(body) {
    return {
        [POST_API]: {
            endpoint: MODULE_API + '/v1/feedback/submit',
            authenticated: true,
            types: [
                SUBMIT_FEEDBACK_FORM_REQUEST,
                SUBMIT_FEEDBACK_FORM_SUCCESS,
                SUBMIT_FEEDBACK_FORM_FAILURE,
            ],
            body: body,
        },
    };
}
export function clearSubmitFeedbackData() {
    return { type: CLEAR_SUBMIT_FEEDBACK_DATA };
}
export function getInternFeedbackList(email, reviewerEmail) {
    const role = localStorage.getItem('role');
    return {
        [GET_API]: {
            endpoint:
                MODULE_API +
                `/v1/feedback/list?intern=${email}${
                    role === 'reviewer' ? '&&reviewer=' + reviewerEmail : ''
                }`,
            authenticated: true,
            types: [
                GET_INTERN_FEEDBACK_LIST_REQUEST,
                GET_INTERN_FEEDBACK_LIST_SUCCESS,
                GET_INTERN_FEEDBACK_LIST_FAILURE,
            ],
        },
    };
}
export function getInternFeedbackDetails(body) {
    return {
        [GET_API]: {
            endpoint: MODULE_API + `/v1/feedback/detail/${body.id}`,
            authenticated: true,
            types: [
                GET_INTERN_FEEDBACK_REQUEST,
                GET_INTERN_FEEDBACK_SUCCESS,
                GET_INTERN_FEEDBACK_FAILURE,
            ],
            body: body,
        },
    };
}
