import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import EmployeeSubHeader from './EmployeeSubHeader';
import ModuleItem from '../../../components/employee/module/ModuleItem';
import { getModuleListing } from '../../../actions/employee/userModuleActions';

import {
    getEmployeeProfile,
    updateProfileSuccessStatus,
} from '../../../actions/employee/userActions';
import { BlockLoader } from 'xd-react-loader';
import { Button } from 'antd';
class ModulesListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalProfileCompleted: false,
        };
    }
    componentDidMount() {
        this.props.dispatch(getModuleListing());
        this.employeeProfileDetails();
    }
    async employeeProfileDetails() {
        await this.props.dispatch(getEmployeeProfile());
    }
    closeProfileMessage() {
        this.props.history.push('/employee/profile/account-information');
    }
    togglePopup = () => {
        this.props.dispatch(updateProfileSuccessStatus());
    };
    render() {
        const {
            isModuleListLoading,
            moduleList,
            moduleListSuccess,
            moduleErrorMsg,
            getProfileSuccess,
            profileInfo,
        } = this.props;

        return (
            <>
                {this.props.profileUpdatePercentage < 70 && (
                    <div
                        className={classNames('xd-modal animated ', {
                            'show fadeIn': !this.state.totalProfileCompleted,
                            fadeOut: this.state.totalProfileCompleted,
                        })}
                    >
                        <div
                            className={classNames(
                                'xd-modal-dialog animated',
                                {
                                    fadeInDown:
                                        !this.state.totalProfileCompleted,
                                    fadeOutUp: this.state.totalProfileCompleted,
                                }
                            )}
                        >
                            <div className="xd-modal-content-status">
                                <div className="xd-modal-header-status">
                                    <h2 className="xd-modal-title">
                                        Current Profile Status
                                    </h2>
                                </div>

                                <div className="xd-modal-body-status">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="profile-status">
                                                    Your profile looks{' '}
                                                    <strong>
                                                        {' '}
                                                        Incomplete{' '}
                                                    </strong>
                                                    ! Please update the required
                                                    information to continue
                                                </p>
                                                <p className='required-status'>
                                                    Min. required Overall
                                                    Percentage is 70% .
                                                </p>
                                               
                                                <p className='user-status'>
                                                    Current Overall Percentage
                                                    is{' '}
                                                    {
                                                        this.props
                                                            .profileUpdatePercentage
                                                    }
                                                    %.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xd-modal-footer-status">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.closeProfileMessage.bind(
                                                this
                                            )}
                                        >
                                            Update Profile
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <main className="main-content-wrapper employee-dashboard_wrapper">
                    <EmployeeSubHeader />
                    <div className="dashboard-body-wrapper gry-fill-background">
                        <section className="dashboard-body-content-section">
                            <div className="container">
                                <div className="module-list_wrapper">
                                    {isModuleListLoading ? (
                                        <div className="moudle-list-loader">
                                            <BlockLoader
                                                loadingText="Loading Training Modules .."
                                                loading={true}
                                                loaderStyle={{
                                                    size: '30px',
                                                    color: '0562e8',
                                                    textSize: '16px',
                                                    textColor: '#757575',
                                                    speed: '0.5s',
                                                }}
                                            />
                                        </div>
                                    ) : !moduleListSuccess ? (
                                        <div className="no-data-found__block">
                                            <div className="no-data-found__body">
                                                <img
                                                    src={require('../../../static/images/no-training-found.svg')}
                                                    alt="No Training Module Found!"
                                                />
                                                <div className="no-data-found__content">
                                                    <h3 className="no-data-found__title">
                                                        {moduleErrorMsg}!
                                                    </h3>
                                                    <p className="no-data-found__text">
                                                        Internship Training
                                                        Modules list, including
                                                        the Tasks, Links and all
                                                        the required
                                                        information.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="module-list_grid">
                                            {moduleList.length > 0 ? (
                                                moduleList.map(
                                                    (module, index) => (
                                                        
                                                        <div
                                                            className="module-list_item"
                                                            key={index}
                                                        >
                                                            <ModuleItem
                                                                ModulesData={
                                                                    module
                                                                }
                                                                isFirstModule={
                                                                    index === 0
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <div className="no-data-found__block">
                                                    <div className="no-data-found__body">
                                                        <img
                                                            src={require('../../../static/images/no-training-found.svg')}
                                                            alt="No Training Module Found!"
                                                        />
                                                        <div className="no-data-found__content">
                                                            <h3 className="no-data-found__title">
                                                                No Training
                                                                Module Found!
                                                            </h3>
                                                            <p className="no-data-found__text">
                                                                Internship
                                                                Training Modules
                                                                list, including
                                                                the Tasks, Links
                                                                and all the
                                                                required
                                                                imformation.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </>
        );
    }
}

function mapStateToProps(state) {
    const {
        moduleList,
        moduleListSuccess,
        moduleSuccessMsg,
        moduleErrorMsg,
        isModuleListLoading,
    } = state.userModuleReducer;
    const {
        profileUpdatePercentage,
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
    } = state.userReducer;
    return {
        moduleList,
        moduleListSuccess,
        moduleSuccessMsg,
        moduleErrorMsg,
        isModuleListLoading,
        profileUpdatePercentage,
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
    };
}

export default withRouter(connect(mapStateToProps)(ModulesListing));
