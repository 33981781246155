import React, { Component, useReducer } from 'react';
import { withRouter, NavLink, } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from "moment";
import {BlockLoader} from 'xd-react-loader';
import NoDataFound from '../../../../components/app/no-data-found/NoDataFound';
import { getInternFeedbackList} from '../../../../actions/employee/feedbackActions';

const url_string = window.location.href;
const url = new URL(url_string);

class FeedbackListing extends Component{
    constructor(props){
        super(props)
        this.state= {
        }
      }

    componentDidMount(){
        
        if(this.props.roleId==='reviewer'){
            this.props.dispatch(getInternFeedbackList(this.props.email, this.props.loginEmail))
        }else{this.props.dispatch(getInternFeedbackList(this.props.email))}
    }

    render(){
    const {internFeedbackErrorMsg, internFeedbackList, internFeedbackSuccessMsg, internFeedbackListLoading} = this.props
    return(
        <section className="content-section-wrapper">
            {internFeedbackListLoading?(
                    <div className="page-section-loader">
                        <BlockLoader
                            loadingText="Loading Reviewer Details .."
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                    </div>
                    ):internFeedbackErrorMsg?(
                       <NoDataFound title={internFeedbackErrorMsg} />
                    ):internFeedbackSuccessMsg?(    
            <div className="user-profile-container"> 
                {internFeedbackList.length!==0 ? 
                (<ul className="custom-list-item-wrapper">
                {internFeedbackList.map((feedback, index) =>(
                    <li className="custom-list-item">
                        <div className="custom-list-item-content">
                            <NavLink to={'/admin/employee/l1/'+this.props.email+'/feedback/'+feedback.form_id} className="full-item-content-link"></NavLink>
                            <div className="row">
                                <div className="col-4">
                                    <div className="custom-list-sub-item">
                                        <div className="custom-list-sub-item-content">
                                        <span className="custom-list-item-label">Module Name</span>
                                            <p class="list-content-title">
                                               <strong>{feedback.module_name}</strong></p>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="custom-list-sub-item">
                                        <div className="custom-list-sub-item-content">
                                            <span className="custom-list-item-label">Submitted By</span>
                                            <p className="custom-list-item-value">{feedback.submitted_by? feedback.submitted_by:("N/A")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="custom-list-sub-item">
                                        <div className="custom-list-sub-item-content">
                                            <span className="custom-list-item-label">Score</span>
                                            <p className="custom-list-item-value">{feedback.scores? feedback.scores:("N/A")}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="custom-list-sub-item">
                                        <div className="custom-list-sub-item-content">
                                            <span className="custom-list-item-label">Submitted On</span>
                                            <p className="custom-list-item-value">{feedback.timestamp?(moment(new Date(feedback.timestamp * 1000)).format('ddd, LL')):('N/A')}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </li>
                ))}
            </ul>)
            :(
                <NoDataFound description={'No submitted feedbacks were found.'}/>
            )}
            </div>
            ):(
                <NoDataFound />
             )}
        </section>
        )
    }
}

function mapStateToProps(state) {
    const {internFeedbackListSuccess, internFeedbackErrorMsg, internFeedbackList, internFeedbackSuccessMsg, internFeedbackListLoading}=state.feedbackReducer;
    const loginEmail = localStorage.getItem('email')
    const {roleId} = state.authReducer;
    return {roleId, loginEmail, internFeedbackListSuccess, internFeedbackErrorMsg, internFeedbackList, internFeedbackSuccessMsg, internFeedbackListLoading}
}

export default withRouter(connect(mapStateToProps)(FeedbackListing))
