import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { BlockLoader } from 'xd-react-loader';
import {
    handleEditPersonalDialogChange,
    handleEditAddressDialogChange,
    setPersonalInfo,
    setAddressInfo,
} from '../../../../actions/employee/userActions';
import ProfileSubHeader from '../ProfileSubHeader';
import UserProfileSidebar from '../UserProfileSidebar';
import ProfileNavigation from '../ProfileNavigation';
import EditPersonalPopup from '../edit-profile/EditPersonalPopup';
import EditAddressPopup from '../edit-profile/EditAddressPopup';

class PersonalInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editPersonalInfo: {},
            editAddressInfo: {},
            addressIndex: '',
        };
    }


    calculate_age(dob) {
        var diff_ms = Date.now() - new Date(dob).getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    handleEditPersonalDialog(personalInfo) {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleEditPersonalDialogChange(true));
        if (personalInfo !== '') {
            let personal = Object.assign(
                {},
                this.props.profileInfo.profile.personal
            );
            this.props.dispatch(setPersonalInfo(personal));
        } else {
            this.setState({
                editPersonalInfo: {},
            });
        }
    }
    handleEditAddressDialog(addressInfo, addressIndex) {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleEditAddressDialogChange(true));
        if (addressInfo !== '') {
            let address = _.cloneDeep(this.props.profileInfo.profile.address);
            this.props.dispatch(setAddressInfo(address, addressIndex));
        } else {
            this.setState({
                editAddressInfo: {},
                addressIndex: '',
            });
        }
    }

    render() {
        const { profileInfo } = this.props;
        return (
            <main className="main-content-wrapper employee-profile-wrapper">
                <ProfileSubHeader />
                <div className="dashboard-body-wrapper gry-fill-background">
                    <div className="container">
                        <section className="employee-profile-content-wrapper">
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <div className="employee-profile-side-template">
                                        <UserProfileSidebar />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-9">
                                    <div className="employee-profile-content">
                                        <ProfileNavigation />
                                        {this.props.isUserProfileLoading ? (
                                            <div className="xs-loader-wrapper">
                                                <BlockLoader
                                                    loadingText="loading"
                                                    loading={true}
                                                    loaderStyle={{
                                                        size: '20px',
                                                        color: '0562e8',
                                                        textSize: '18px',
                                                        textColor: '#485e7d',
                                                        speed: '1s',
                                                    }}
                                                />
                                            </div>
                                        ) : this.props.profileErrorMsg ? (
                                            <h3>
                                                {this.props.profileErrorMsg}
                                            </h3>
                                        ) : this.props.getProfileSuccess ? (
                                            <div className="user-profile-container">
                                                <div className="profile-section_card">
                                                    <h2 className="profile-section_card_title">
                                                        Personal Information
                                                    </h2>
                                                    <div className="profile-section_grid">
                                                        <div className="profile-section_card_item">
                                                            <article className="profile-section_card_details">
                                                                <div className="profile-action">
                                                                    <strong
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            this.handleEditPersonalDialog(
                                                                                profileInfo
                                                                                    .profile
                                                                                    .personal
                                                                            )
                                                                        }
                                                                        className="link-button link-button_icon"
                                                                    >
                                                                        <i className="icon-pencil"></i>{' '}
                                                                        Edit
                                                                    </strong>
                                                                </div>
                                                                <div className="profile-card-meta_data">
                                                                    <div className="row">
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Father's
                                                                                    Name
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .personal
                                                                                        .fathers_name !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .personal
                                                                                              .fathers_name
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Mother's
                                                                                    Name
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .personal
                                                                                        .mothers_name !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .personal
                                                                                              .mothers_name
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Gender
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .personal
                                                                                        .gender !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .personal
                                                                                              .gender ==
                                                                                          'female'
                                                                                            ? 'F'
                                                                                            : 'M'
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Blood
                                                                                    Group
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .personal
                                                                                        .blood_group !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .personal
                                                                                              .blood_group
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Marital
                                                                                    Status
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .personal
                                                                                        .marital_Status !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .personal
                                                                                              .marital_Status
                                                                                        : 'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-sm-6">
                                                                            <div className="profile-fields_data">
                                                                                <p className="fields-name">
                                                                                    Date
                                                                                    Of
                                                                                    Birth/Age
                                                                                </p>
                                                                                <p className="fields-value">
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .personal
                                                                                        .dateofbirth !==
                                                                                    ''
                                                                                        ? profileInfo
                                                                                              .profile
                                                                                              .personal
                                                                                              .dateofbirth
                                                                                        : 'N/A'}
                                                                                    {profileInfo
                                                                                        .profile
                                                                                        .personal
                                                                                        .dateofbirth !==
                                                                                    ''
                                                                                        ? `/${this.calculate_age(
                                                                                              profileInfo
                                                                                                  .profile
                                                                                                  .personal
                                                                                                  .dateofbirth
                                                                                          )}yrs`
                                                                                        : ''}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        {profileInfo
                                                                            .profile
                                                                            .personal
                                                                            .marital_Status ===
                                                                            'married' && (
                                                                            <>
                                                                                <div className="col-12 col-sm-6">
                                                                                    <div className="profile-fields_data">
                                                                                        <p className="fields-name">
                                                                                            Spouse's
                                                                                            Name
                                                                                        </p>
                                                                                        <p className="fields-value">
                                                                                            {profileInfo
                                                                                                .profile
                                                                                                .personal
                                                                                                .spouse_name !==
                                                                                            ''
                                                                                                ? profileInfo
                                                                                                      .profile
                                                                                                      .personal
                                                                                                      .spouse_name
                                                                                                : 'N/A'}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-6">
                                                                                    <div className="profile-fields_data">
                                                                                        <p className="fields-name">
                                                                                            Spouse's
                                                                                            Contact
                                                                                        </p>
                                                                                        <p className="fields-value">
                                                                                            {profileInfo
                                                                                                .profile
                                                                                                .personal
                                                                                                .spouse_contact !==
                                                                                            ''
                                                                                                ? profileInfo
                                                                                                      .profile
                                                                                                      .personal
                                                                                                      .spouse_contact
                                                                                                : 'N/A'}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>{' '}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="profile-section_card">
                                                    <h2 className="profile-section_card_title">
                                                        {' '}
                                                        Address
                                                    </h2>
                                                    <div className="profile-section_grid">
                                                        {profileInfo.profile
                                                            .address.length ==
                                                            0 && (
                                                            <>
                                                                <h2
                                                                    style={{
                                                                        fontSize:
                                                                            '15px',
                                                                    }}
                                                                    className="profile-section_card_title"
                                                                >
                                                                    Current
                                                                    Address
                                                                </h2>
                                                                <strong
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.handleEditAddressDialog(
                                                                            '',
                                                                            0
                                                                        )
                                                                    }
                                                                    className="link-button link-button_icon"
                                                                >
                                                                    <i className="icon-pencil"></i>{' '}
                                                                    Edit
                                                                </strong>
                                                            </>
                                                        )}

                                                        {profileInfo.profile.address.map(
                                                            (
                                                                address,
                                                                addressIndex
                                                            ) => (
                                                                <>
                                                                    <div
                                                                        className="profile-section_card_item"
                                                                        key={
                                                                            addressIndex
                                                                        }
                                                                    >
                                                                        <article className="profile-section_card_details">
                                                                            <h3>
                                                                                {
                                                                                    address.address_type
                                                                                }
                                                                            </h3>
                                                                            <div className="profile-action">
                                                                                <strong
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.handleEditAddressDialog(
                                                                                            address,
                                                                                            addressIndex
                                                                                        )
                                                                                    }
                                                                                    className="link-button link-button_icon"
                                                                                >
                                                                                    <i className="icon-pencil"></i>{' '}
                                                                                    Edit
                                                                                </strong>
                                                                            </div>
                                                                            <div className="profile-card-meta_data">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Location
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {address.address !==
                                                                                                ''
                                                                                                    ? address.address
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Country
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {address.country !==
                                                                                                ''
                                                                                                    ? address.country
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                State
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {address.state !==
                                                                                                ''
                                                                                                    ? address.state
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                City
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {address.city !==
                                                                                                ''
                                                                                                    ? address.city
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                District
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {address.district !==
                                                                                                ''
                                                                                                    ? address.district
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Pin
                                                                                                Code
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {address.pincode !==
                                                                                                ''
                                                                                                    ? address.pincode
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <EditPersonalPopup />
                        <EditAddressPopup />
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        getProfileSuccess,
        updateSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        addressInfo,
    } = state.userReducer;
    return {
        getProfileSuccess,
        updateSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        addressInfo,
    };
}

export default withRouter(connect(mapStateToProps)(PersonalInfo));
