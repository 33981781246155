import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {BlockLoader} from 'xd-react-loader';
import NoDataFound from '../../../../components/app/no-data-found/NoDataFound';
import {getDocumentLinks} from '../../../../actions/admin/employeeActions';
class OthersDetails extends Component{
    constructor(props){
        super(props)
        this.state= { }
    }

handleGetDocumentLink(link){
    this.props.dispatch(getDocumentLinks(link))
}
render(){
    const { userProfileData} = this.props  
    return(                       
        <section className="content-section-wrapper">
            <div className="row">               
                <div className="col-12 col-sm-12 col-md-10 col-lg-10">                                
                    <div className="user-details">
                        {this.props.isProfileLoading?(
                        <div className="page-section-loader">
                            <BlockLoader
                                loadingText="Loading Other Details .."
                                loading={true}
                                loaderStyle={{
                                    size: "24px",
                                    color: "0562e8",
                                    textSize: "14px",
                                    textColor: "#757575",
                                    speed: "0.5s"
                                }}
                            />
                        </div>
                        ):this.props.userProfileError?(
                            <NoDataFound title={this.props.userProfileError} />
                        ):this.props.profileSuccess?(<div className="details-container">
                        <div className="user-profile-container"> 
                                    <div className="profile-section_card">
                                        <h2 className="profile-section_card_title">Emergency Contact</h2>
                                        <div className="profile-section_grid">   
                                        {userProfileData.emergency_contacts.length!=0 &&                    
                                        <div className="profile-section_card_item">
                                        <article className="profile-section_card_details">
                                            {userProfileData.emergency_contacts.map((emergency,emIndex)=>
                                                <div className="profile-card-meta_data">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6">
                                                            <div className="profile-fields_data">
                                                                <p className="fields-name">Contact Name</p>
                                                                <p className="fields-value">{emergency.contact_name!== ''? emergency.contact_name: 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="profile-fields_data">
                                                                <p className="fields-name">Contact Number</p>
                                                                <p className="fields-value">{emergency.contact_number!== ''? emergency.contact_number: 'N/A'}</p>
                                                            </div>
                                                        </div>                                                                
                                                    </div>
                                                </div>
                                             )}
                                            </article>
                                        </div>}
                                    </div>                                    
                                </div>

                                <div className="profile-section_card">
                                        <h2 className="profile-section_card_title">Other Certificates</h2>
                                        <div className="profile-section_grid">                                             
                                        {userProfileData.others_certificates.length!==0?(                  
                                        <div className="profile-section_card_item">
                                        <article className="profile-section_card_details">
                                            {userProfileData.others_certificates.map((certifcate, cerIndex)=>
                                                <div className="profile-card-meta_data">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6">
                                                            <div className="profile-fields_data">
                                                                <p className="fields-name">Certificate Name</p>
                                                                <p className="fields-value">{certifcate.name !== '' ? certifcate.name : 'N/A'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="profile-fields_data">
                                                                <p className="fields-name">Certificate Link</p>
                                                                <p className="fields-value fields-value_link">
                                                                    {certifcate.certificate_link !== '' ?
                                                                    (<>
                                                                    Certificate Link
                                                                    <a href="javascript:void(0)"  onClick={()=>this.handleGetDocumentLink(certifcate.certificate_link)} className="doc-download"> <i className="icon-cloud-download"></i>Download 
                                                                    </a>
                                                                    </>)
                                                                    : (<span className="no-data-text-small">N/A</span>)
                                                                    }
                                                                </p>                                                                
                                                            </div>
                                                        </div>                                                                
                                                    </div>
                                                </div>
                                                
                                             )}
                                             
                                             
                                            </article>
                                        </div>):(
                                            <NoDataFound title="No Other Information Available" description="User has not added any certificates yet"/>
                                        )}
                                    </div>                                    
                                </div>                                      
                            <div className="profile-section_card">
                               
                                </div>    
                        </div>                                               
                        </div>   
                     ):(
                        <NoDataFound />
                     )}
                    </div>
                </div>
            </div>
        </section>
        )
    }
}

function mapStateToProps(state) {
    const {isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink} = state.employeeReducer;
    return { isProfileLoading, userProfileData, userProfileError,profileSuccess, userProfileSuccess, documentLink}
}

export default withRouter(connect(mapStateToProps)(OthersDetails))
