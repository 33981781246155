import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import '../../../container/employee/profile/dist/modal.css';
import { Button } from 'xd-react-button';
import createNotification from '../../../components/app/notification/createNotification';
import {
    handleUploadPresentationDialogChange,
    uploadPresentation,
    clearPresentationUploadData,
    clearNotificationData
} from '../../../actions/employee/userModuleActions';

class UploadPresentationPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleUploadPresentationDialogChange(false));
    }

    handleSubmit(e) {
        let input = document.getElementById('presentation-upload-input');
        if (input.checkValidity()) {
            input.parentElement.classList.remove('has-error');
            input.parentElement.parentElement.classList.remove('has-error');
            if (input.files) {
                let files = input.files;
                this.props.dispatch(
                    uploadPresentation(files, this.props.moduleId)
                );
            }
        } else {
            input.parentElement.classList.add('has-error');
            input.parentElement.parentElement.classList.add('has-error');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.presentationLinkUploadError) {
            createNotification('error', nextProps.presentationLinkUploadError);
            nextProps.dispatch(clearPresentationUploadData());
        }
        if (nextProps.presentationLinkUploadSuccess) {
            document.querySelector('#body-root').style = 'overflow:auto;';
            this.props.dispatch(handleUploadPresentationDialogChange(false));
            
            nextProps.dispatch(clearPresentationUploadData());
        }
        if(nextProps.presentationUploadSuccess === "operation successful"){
            createNotification(
                'success',
                'file uploaded successfully'
            );
            nextProps.dispatch(clearNotificationData());
        }
    }

    render() {
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': this.props.uploadPresentationPopupState,
                        fadeOut: !this.props.uploadPresentationPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown:
                                    this.props.uploadPresentationPopupState,
                                fadeOutUp:
                                    !this.props.uploadPresentationPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">
                                    Upload Presentation
                                </h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div id="edit-education-dialog">
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <div>
                                                    <label>
                                                        Upload Presentation{' '}
                                                        <span className="req">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div class="input-wrapper">
                                                        <input
                                                            type="file"
                                                            name="presentation_link"
                                                            required
                                                            multiple
                                                            id="presentation-upload-input"
                                                            placeholder="Upload Presentation"
                                                            label="Upload Presentation "
                                                            className="form-control"
                                                            errorMessage="Please upload your presentation"
                                                        />
                                                        <div className="help-block with-errors">
                                                            Please upload
                                                            presentation
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                            onClick={this.handleSubmit.bind(
                                                this
                                            )}
                                        >
                                            {this.props
                                                .uploadPresentationLoading ? (
                                                <span>
                                                    <i className="fa fa-spin fa-spinner btn-icon"></i>{' '}
                                                    Uploading..
                                                </span>
                                            ) : (
                                                <span>Upload</span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.uploadPresentationPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: this.props.uploadPresentationPopupState,
                            fadeOut: !this.props.uploadPresentationPopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        moduleData,
        uploadPresentationPopupState,
        presentationLink,
        presentationUploadError,
        presentationUploadSuccess,
        presentationLinkUploadError,
        presentationLinkUploadSuccess,
        deletePresentationSuccessMsg,
        deletePresentationErrorMsg,
        uploadPresentationAfterDelete,
        uploadPresentationLoading,
    } = state.userModuleReducer;
    return {
        moduleData,
        uploadPresentationPopupState,
        presentationLink,
        presentationUploadError,
        presentationUploadSuccess,
        presentationLinkUploadError,
        presentationLinkUploadSuccess,
        deletePresentationSuccessMsg,
        deletePresentationErrorMsg,
        uploadPresentationAfterDelete,
        uploadPresentationLoading,
    };
}
export default withRouter(connect(mapStateToProps)(UploadPresentationPopup));
