import React, {Component} from 'react';
import {withRouter,Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import "./dist/style.css"
import ModulesListing from './ModulesListing';
import TaskListing from './TaskListing';

class ModulesIndex extends Component {
    render() {
        return (   
            <Switch>
                <Route exact path="/employee/modules" component={ModulesListing}/>
                <Route exact path="/employee/modules/:ModuleID" component={TaskListing} />
            </Switch>    
        )
    }
}

function mapStateToProps(state) {
    return state
}

export default withRouter(connect(mapStateToProps)(ModulesIndex))