import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import '../assign-module/dist/style.css'
import { clearAssignFeedbackData} from '../../../actions/employee/feedbackActions';
class FeedbackStatus extends Component {
    constructor(props){
        super(props)
    }
    componentDidMount(){
        if(this.props.assignFeedbackSuccessList.length==0 && this.props.assignFeedbackErrorList.length==0){
            this.props.history.push('/admin/feedback/list')
        }
    }

    handleBackToListing() {
        this.props.dispatch(clearAssignFeedbackData());
        this.props.history.push('/admin/feedback/list')
      }

    render(){
        const {assignFeedbackSuccessList, assignFeedbackErrorList}= this.props
        return(
            <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                <div className="invite-employee-form-outer content-section-card invite-team-response" id="invite-team-response">
                    <section className="dashboard-content-box" id="dashboard-content-box">
                        <div className="invite-member-message-box" id="invite-member-message-box">
                            {assignFeedbackSuccessList !== undefined &&
                            assignFeedbackSuccessList.length > 0 ? (
                            <div className="invite-team-header" >
                                <h3 className="invite-success">Feedback form successfully assigned!</h3>
                                <p className="invite-success">You've successfully assigned feedback form to {assignFeedbackSuccessList.length} Module(s).
                                </p>
                            </div>
                                ) : ("")}
                                {assignFeedbackSuccessList !== undefined &&
                                assignFeedbackSuccessList.length > 0 && (
                                <table className="invite-member-message-table sucess-table assign-module-status-table" id="sucess-table">
                                    <tbody>
                                        <tr>
                                            <th>Module </th>
                                            <th>Message</th>
                                        </tr>
                                        {assignFeedbackSuccessList.map(
                                            (form, index) => (
                                            <tr>
                                                <td data-label="Message">{form.module}</td>
                                                <td data-label="Message">{form.message}</td>
                                            </tr>
                                            )
                                            )}
                                    </tbody>
                                </table>
                                )}
                                <hr className="m-2-rem light-grey-hr"></hr>
                                {assignFeedbackErrorList !== undefined &&
                                assignFeedbackErrorList.length > 0 ? (
                                <div className="invite-team-header">
                                    <h3 className="invite-error">Feedback form not assigned!</h3>
                                    <p className="invite-error">Feedback form not assigned to {assignFeedbackErrorList.length} module(s). Please review the error message.
                                    </p>
                                </div>
                                ) : ("")}
                                {assignFeedbackErrorList !== undefined &&
                                assignFeedbackErrorList.length > 0 && (
                                <table className="invite-member-message-table error-table assign-module-status-table" id="error-table">
                                    <tbody>
                                        <tr>
                                            <th>Module </th>
                                            <th>Message</th>
                                        </tr>
                                        {assignFeedbackErrorList.map(
                                            (form, index) => (
                                                <tr>
                                                    <td data-label="Message">{form.module}</td>
                                                    <td data-label="Message">{form.message}</td>
                                                </tr>
                                            )
                                            )}
                                    </tbody>
                                </table>
                                )}
                        </div>
                        <div className="invite-success-action">
                            <div className="row">
                                <div className="col-12 col-sm-5 col-md-6">
                                    <div className="form-btn-group">
                                        <Button className="btn box-shadow send-invite-btn"
                                        onClick={this.handleBackToListing.bind(this)}>Assign more Feedback Form</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {assignFeedbackSuccessList, assignFeedbackErrorList }= state.feedbackReducer;
    return { assignFeedbackSuccessList, assignFeedbackErrorList}
  }
  
  export default withRouter(connect(mapStateToProps)(FeedbackStatus))