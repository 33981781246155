import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import {
    resetPassword,
    updateResetPasswordForm,
} from '../../actions/auth/authActions';
import './dist/style.css';

const url_string = window.location.href;
const url = new URL(url_string);

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    handleChange(e) {
        const token = url.searchParams.get('token'),
            password = document.getElementsByName('password')[0],
            confirmPassword = document.getElementsByName('confirmPassword')[0];
        let resetData = {
            token: token,
            password: password.value,
            confirmPassword: confirmPassword.value,
        };

        if (e.target.checkValidity()) {
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.parentElement.classList.add('has-error');
        }

        if (confirmPassword.value !== '') {
            if (password.value !== confirmPassword.value) {
                e.target.parentElement.classList.add('has-error');
            } else {
                e.target.parentElement.classList.remove('has-error');
            }
        }

        this.props.dispatch(updateResetPasswordForm(resetData));
    }

    handleOnSubmit(e) {
        //if invitation form is valid then submit the data, else show the validation message on the invalid field.
        e.preventDefault();
        if (
            e.target.checkValidity() &&
            this.props.resetPasswordData.password ===
                this.props.resetPasswordData.confirmPassword
        ) {
            const data = {
                password: this.props.resetPasswordData.password,
                token: this.props.resetPasswordData.token,
            };
            this.props.dispatch(resetPassword(data));
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
        this.formRef.reset();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isAuthenticated) {
            if (nextProps.redirectLink != '') {
                window.location.replace(nextProps.redirectLink);
            } else {
                this.props.history.push('/admin/dashboard');
            }
        }
    }
    componentDidMount() {
        if (url.searchParams.get('token') == null) {
            this.props.history.push('/');
        }
    }

    render() {
        const {
            loginErrorMessage,
            loginSuccessMessage,
            isPageLoading,
        } = this.props;
        return (
            <main className="main-content-wrapper full-height">
                <div className="dashboard-body-wrapper auth-fill-background full-height ">
                    <section className="full-width-content-section">
                        <div className="admin-form-outer">
                            <div className="admin-form-header">
                                <div className="admin-form-header-content">
                                    <figure>
                                        <img
                                            src={require('./dist/xenonstack-logo-xs.png')}
                                            alt="XenonStack Onboarding Portal Logo"
                                        />
                                    </figure>
                                    <h2>Reset Password</h2>
                                    <p>Enter the new password</p>
                                </div>
                            </div>
                            <form
                                className="admin-login-form"
                                ref={(ref) => (this.formRef = ref)}
                                noValidate={true}
                                id="admin-login-form"
                                onSubmit={this.handleOnSubmit.bind(this)}
                            >
                                <Input
                                    required
                                    type="password"
                                    visibility="true"
                                    name="password"
                                    autofill={'false'}
                                    label="Password"
                                    pattern={
                                        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
                                    }
                                    errorMessage="Please enter your password (Min 8 char., atleast one upper case letter, one number and one special character )."
                                    maxLength={30}
                                    minLength={3}
                                    className="form-control input-large"
                                    outerClassName="login-input-box"
                                    placeholder="Password"
                                    id="password"
                                    onChange={this.handleChange.bind(this)}
                                />
                                <Input
                                    required
                                    type="password"
                                    visibility="true"
                                    passwordShowData='<i  class="fa fa-eye-slash eye-icon" aria-hidden="true"/>'
                                    passwordHideData='<i  class="fa fa-eye eye-icon" aria-hidden="true"/>'
                                    name="confirmPassword"
                                    autofill={'false'}
                                    label="Confirm Password"
                                    maxLength={30}
                                    minLength={3}
                                    className="form-control input-large"
                                    outerClassName="login-input-box"
                                    placeholder="Comfirm Password"
                                    id="confirmPassword"
                                    errorMessage="Password didn't match."
                                    onChange={this.handleChange.bind(this)}
                                />
                                <div className="form-group form-btn-group">
                                    {!isPageLoading ? (
                                        <Button
                                            type="submit"
                                            id="send-invite"
                                            formNoValidate={true}
                                            className="btn box-shadow"
                                        >
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button className="btn btn-icon icon-left">
                                            <i className="fa fa-spin fa-spinner btn-icon"></i>
                                            <span className="btn-text">
                                                Please wait ..
                                            </span>
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    {this.props.forgotPasswordSuccessMsg ? (
                                        <p style={{ color: 'green' }}>
                                            {
                                                this.props
                                                    .forgotPasswordSuccessMsg
                                            }
                                            . Please login to your account.
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {this.props.forgotPasswordErrorMsg ? (
                                        <p style={{ color: 'red' }}>
                                            {this.props.forgotPasswordErrorMsg}
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="form-link-group">
                                    <NavLink
                                        to="/login"
                                        className="auth-page-link"
                                    >
                                        Back to Login
                                    </NavLink>
                                </div>

                                {!isPageLoading && loginErrorMessage && (
                                    <div className="center-error form-error">
                                        <p className="error-msg">
                                            {loginErrorMessage}
                                        </p>
                                    </div>
                                )}
                                {!isPageLoading && loginSuccessMessage && (
                                    <div className="center-error form-error">
                                        <p className="success-msg">
                                            {loginSuccessMessage}
                                        </p>
                                    </div>
                                )}
                            </form>
                        </div>
                    </section>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        isAuthenticated,
        isPageLoading,
        resetPasswordData,
        forgotInviteErrorMsg,
        forgotInviteSuccessMsg,
        forgotPasswordErrorMsg,
        forgotPasswordSuccessMsg,
    } = state.authReducer;
    return {
        isAuthenticated,
        isPageLoading,
        resetPasswordData,
        forgotInviteErrorMsg,
        forgotInviteSuccessMsg,
        forgotPasswordErrorMsg,
        forgotPasswordSuccessMsg,
    };
}

export default withRouter(connect(mapStateToProps)(ResetPassword));
