import React, { Component } from 'react';
import '../../../components/intern/forms/dist/form.css';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import { connect } from 'react-redux';
import {
    addNewCertificate,
    removeCertificate,
    updateEmergencyInfo,
    updateOtherInfo,
    internRegistration,
    uploadCertificate,
    deleteCertificate,
} from '../../../actions/auth/authActions';
import createNotification from '../../../components/app/notification/createNotification';

class EmergencyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactError: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.internRegistrationSuccess) {
            this.props.history.push('/employee/UserProfile');
        }
    }
    componentWillReceiveProps(nextProps) {
        const self = this;
        if (nextProps.internRegistrationSuccess) {
            setTimeout(function () {
                self.props.history.push('/employee/UserProfile');
            }, 2000);      
        }
    }

    handleOnSubmit(e) {
        e.preventDefault();

        if (
            e.target.checkValidity() &&
            this.props.emergencyInfo[0].contact_number !=
                this.props.emergencyInfo[1].contact_number
        ) {
            const internInfo = {
                personal_info: this.props.personalInfo,
                user_address: this.props.addressInfo,
                education: this.props.educationInfo,
                emergency_contact: this.props.emergencyInfo,
                others: this.props.otherDocumentsList,
            };
            createNotification('success', 'Account Created Successfully');
            this.props.dispatch(internRegistration(internInfo));
        } else {
            createNotification('error', 'Account cannot be created');
            if (
                this.props.emergencyInfo[0].contact_number ==
                    this.props.emergencyInfo[1].contact_number &&
                this.props.emergencyInfo[0].contact_number.length == 10 &&
                this.props.emergencyInfo[1].contact_number.length == 10
            ) {
                this.setState({
                    contactError: 'Both Contact numbers cannot be same',
                });
            } else {
                this.setState({ contactError: '' });
            }
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    handleChange(index, e) {
        let name = e.target.name;
        let value = e.target.value;
        let emergencyData = this.props.emergencyInfo;
        emergencyData[index][name] = value;
        this.props.dispatch(updateEmergencyInfo(emergencyData));

        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
    }

    handleOtherInfoChange(e, index) {
        let name = e.target.name;
        let value = e.target.value;
        let otherData = this.props.otherDocumentsList;
        otherData[index][name] = value;

        this.props.dispatch(updateOtherInfo(otherData));

        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
    }

    handleAddCertificate(e) {
        this.props.dispatch(
            addNewCertificate({ name: '', certificate_link: '' })
        );
    }
    handleRemoveCertificate(index) {
        const list = this.props.otherDocumentsList;
        list.splice(index, 1);
        this.props.dispatch(removeCertificate(list));
        this.forceUpdate();
    }

    handleDocumentUpload(e, index) {
        let input = e.target;
        let name = e.target.name;
        if (input.files[0]) {
            let file = input.files[0];
            if (
                this.props.otherDocumentsList[index].certificate_link.length !=
                0
            ) {
                this.props.dispatch(
                    deleteCertificate(
                        file,
                        name,
                        index,
                        this.props.otherDocumentsList[index].certificate_link
                    )
                );
            } else {
                this.props.dispatch(uploadCertificate(file, name, index));
            }
        }
    }
    render() {
        const { otherDocumentsList, isPageLoading } = this.props;
        return (
            <main className="main-content-wrapper auth-content-wrapper full-height">
                <div className="wrapper-container signing-container personal-information">
    <div className="container user-jounrney-form-container">
        <div className="user-journey-form user-journey-form-internal-steps">
            <div className="dashbaord-user-form">
                <div className="section_header">
                    <label>Step 4/4</label>
                    <h2 className="big-heading">Other Information</h2>
                </div>
                <div className="personal-information" id="personal-information">
                <form
                                                className="employee-signup-form"
                                                noValidate={true}
                                                id="employee-signup-form"
                                                onSubmit={this.handleOnSubmit.bind(
                                                    this
                                                )}
                                            >
                                                <div className="row">
                                                    <div className="col-12">
                                                        <hr className="form-section-divider"></hr>
                                                        <h3 className="form-section-header">
                                                            Emergency Contacts
                                                        </h3>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="text"
                                                            name="contact_name"
                                                            id="contact_name"
                                                            placeholder="Contact Name"
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                            }
                                                            autoComplete={'off'}
                                                            label="Contact Name"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter your Contact Name."
                                                            onChange={(e) =>
                                                                this.handleChange(
                                                                    0,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="text"
                                                            name="contact_number"
                                                            id="contact_number"
                                                            placeholder="Contact Number"
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '[7-9]{1}[0-9]{9}'
                                                            }
                                                            autoComplete={'off'}
                                                            label="Contact Number"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter your Contact Number."
                                                            onChange={(e) =>
                                                                this.handleChange(
                                                                    0,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="text"
                                                            name="contact_name"
                                                            id="contact_name"
                                                            placeholder="Contact Name"
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                            }
                                                            autoComplete={'off'}
                                                            label="Contact Name"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter your Contact Name."
                                                            onChange={(e) =>
                                                                this.handleChange(
                                                                    1,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <Input
                                                            type="text"
                                                            name="contact_number"
                                                            id="contact_number"
                                                            placeholder="Contact Number"
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '[7-9]{1}[0-9]{9}'
                                                            }
                                                            autoComplete={'off'}
                                                            label="Contact Number"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter your Contact Number."
                                                            onChange={(e) =>
                                                                this.handleChange(
                                                                    1,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <div
                                                            style={{
                                                                color: 'red',
                                                                padding: '0',
                                                                margin: '0',
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .contactError
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <hr className="form-section-divider"></hr>
                                                        <h3 className="form-section-header">
                                                            Other Documents
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <label>
                                                            Certificate Name
                                                        </label>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <label>
                                                            Upload Certificate
                                                        </label>
                                                    </div>
                                                </div>
                                                {otherDocumentsList.map(
                                                    (docs, index) => (
                                                        <div className="document-group">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6">
                                                                    <Input
                                                                        type="text"
                                                                        name="name"
                                                                        id="name"
                                                                        placeholder="Document Name"
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        pattern={
                                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                        }
                                                                        autoComplete={
                                                                            'off'
                                                                        }
                                                                        className="form-control"
                                                                        outerClassName="signup-input-box"
                                                                        errorMessage="Please enter  you Certificate name"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleOtherInfoChange(
                                                                                e,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <Input
                                                                        type="file"
                                                                        name="certificate_link"
                                                                        id="certificate_link"
                                                                        placeholder="Certificate "
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        pattern={
                                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                        }
                                                                        autoComplete={
                                                                            'off'
                                                                        }
                                                                        className="form-control"
                                                                        outerClassName="signup-input-box"
                                                                        errorMessage="Please upload your Certificates"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleDocumentUpload(
                                                                                e,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            {index > 0 && (
                                                                <span
                                                                    className="cross-icon"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.handleRemoveCertificate(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </span>
                                                            )}
                                                        </div>
                                                    )
                                                )}

                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <div
                                                                className="add-more-link"
                                                                onClick={this.handleAddCertificate.bind(
                                                                    this
                                                                )}
                                                            >
                                                                <i className="fa fa-plus"></i>
                                                                <span className="add-more-text">
                                                                    Add More
                                                                    Certificate
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-group form-btn-group">
                                                            {!isPageLoading ? (
                                                                <Button
                                                                    type="submit"
                                                                    id="send-invite"
                                                                    formNoValidate={
                                                                        true
                                                                    }
                                                                    className="btn box-shadow"
                                                                >
                                                                    Submit
                                                                </Button>
                                                            ) : (
                                                                <Button className="btn btn-icon icon-left">
                                                                    <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                                    <span className="btn-text">
                                                                        Please
                                                                        wait ..
                                                                    </span>
                                                                </Button>
                                                            )}
                                                        </div>
                                                        {this.props
                                                            .internRegistrationSuccess ? (
                                                            <div
                                                                style={{
                                                                    color: 'green',
                                                                }}
                                                            >
                                                                {
                                                                    this.props
                                                                        .internRegistrationMessage
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                {
                                                                    this.props
                                                                        .internRegistrationMessage
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                </div>
            </div>
            <div className="xenonstack-jobs-copyright">
                <p>Xenonstack |</p>
                <i className="fa fa-copyright"></i>
                <p>2021 All Rights Reserved</p>
            </div>
        </div>
    </div>
</div>

            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        otherDocumentsList,
        internRegistrationSuccess,
        isPageLoading,
        internRegistrationMessage,
        internInfo,
        emergencyInfo,
        personalInfo,
        educationInfo,
        addressInfo,
        uploadFileSuccess,
    } = state.authReducer;
    return {
        otherDocumentsList,
        internRegistrationSuccess,
        isPageLoading,
        internRegistrationMessage,
        internInfo,
        emergencyInfo,
        personalInfo,
        educationInfo,
        addressInfo,
        uploadFileSuccess,
    };
}
export default connect(mapStateToProps)(EmergencyInfo);
