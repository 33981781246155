import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import { BlockLoader} from 'xd-react-loader';
import ProfileSubHeader from "./ProfileSubHeader";
import { getEmployeeProfile, updateEmergencyFields, updateProfileDetails, updateCertificateFields } from "../../../actions/employee/userActions";

class EditOtherInfo extends Component{
    constructor(props){
        super(props)
        this.handleChange=this.handleEmergencyInfoChange.bind(this)
        this.handleChange=this.handleCertificateInfoChange.bind(this)
    }

    componentDidMount(){
        this.props.dispatch(getEmployeeProfile())
    }
    handleEmergencyInfoChange(e,i) {
        let value = e.target.value;
        const name = e.target.name;
        let emergencyData = this.props.profileInfo.emergency_contacts
        
        emergencyData[i][name] = value;
        
        this.props.dispatch(updateEmergencyFields(emergencyData));
        if (e.target.checkValidity()) {
          e.target.parentElement.classList.remove('has-error')
        }
        else {
          e.target.parentElement.classList.add('has-error')
        }
       
      }
      handleCertificateInfoChange(e,i) {
        let value = e.target.value;
        const name = e.target.name;
        let certificateData = this.props.profileInfo.others_certificates
        
        certificateData[i][name] = value;
        
        this.props.dispatch(updateCertificateFields(certificateData));
        if (e.target.checkValidity()) {
          e.target.parentElement.classList.remove('has-error')
        }
        else {
          e.target.parentElement.classList.add('has-error')
        }
       
      }

    
      handleSubmit(e){
        e.preventDefault();
        const details={
            "personal_info":this.props.profileInfo.personal_info,
            "user_address":this.props.profileInfo.address_info,
            "education":this.props.profileInfo.education_details,
            "emergency_contact":this.props.profileInfo.emergency_contacts,
            "others":this.props.profileInfo.others_certificates
        }
        if (e.target.checkValidity()) {
            this.props.dispatch(updateProfileDetails(details))
        }else{
            let allInvalidInput = document.querySelectorAll(
                ".form-group .form-control:invalid"
              );
              if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add("has-error");
                    invalidInput.parentElement.parentElement.classList.add(
                    "has-error"
                  );
                }
              }
        }
      }
      handleCancel(){
        this.props.history.push('/employee/OtherInfo')
    }
    

    render(){
        const userProfile=this.props.profileInfo
        return(
            <main className="main-content-wrapper employee-profile-wrapper">
                <ProfileSubHeader/>
                <div className="container emp-profile">
                <div className="profile-head">
                <div className="row">
                    
                                    <ul className=" profile-nav-tabs" id="myTab" role="tablist">
                                        <li className="profile-nav-item">
                                             <NavLink to='/employee/UserProfile' className="profile-nav-link  " id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">Personal Information</NavLink>
                                         </li>
                                         <li className="profile-nav-item">
                                         <li><NavLink to='/employee/EducationInfo'  className="profile-nav-link " id="home-tab" data-toggle="tab"  role="tab" aria-controls="home" aria-selected="true">Educational Information</NavLink></li>
                                         </li>
                                         <li className="profile-nav-item">
                                         <li><NavLink to='/employee/AccountInfo'  className="profile-nav-link" id="home-tab" data-toggle="tab"  role="tab" aria-controls="home" aria-selected="true">Account Information</NavLink></li>
                                         </li>
                                         <li className="profile-nav-item">
                                         <li><NavLink to='/employee/OtherInfo'  className="profile-nav-link active" id="home-tab" data-toggle="tab"  role="tab" aria-controls="home" aria-selected="true">Other</NavLink></li>
                                         </li>
                                    </ul>
                            </div>
                                </div>
            <form method="post">
                <div className="row">
                    <div className="col-md-4">
                        <div class="profile-img">
                            <img src={userProfile  ? userProfile.link : 
                                'https://www.jamf.com/jamf-nation/img/default-avatars/generic-user-purple.png'} style={{width: '200px', height: '200px', borderRadius:'100px'}} alt=""/>
                            <div class="file btn btn-lg btn-primary">
                                Change Photo
                                <input type="file" name="file"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 style={{paddingTop:"40px"}}>Other Information</h3>
                    </div>
                    <div className="col-md-6">
                        
                    </div>
                   
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="profile-work">
                            
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="tab-content profile-tab" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            
                {this.props.isUserProfileLoading?(
                                             <div>
                                                 <BlockLoader 
                                                 loadingText="loading" 
                                                 loading={true}
                                                 loaderStyle={{size:"20px",
                                                 color:"0562e8",
                                                 textSize:"18px",
                                                 textColor:"#485e7d",
                                                 speed:"1s"
                                                }}
                                                />
                                            </div>
                                        ):this.props.profileErrorMsg?(
                                            <h3>{this.props.profileErrorMsg}</h3>
                                        ):this.props.getProfileSuccess?(<React.Fragment>
                                            
                                            {userProfile.emergency_contacts.map((emergency,i)=>
                                            <React.Fragment>
                                                <h3>Emergency contact {i+1}</h3>
                                                <div class="row">
                                            <div class="col-md-6">
                                                <label>Contact Name</label>
                                            </div>
                                            <div class="col-md-6">
                                                <input
                                                type="text"
                                                className="form-control edit-field"
                                                id="contact_name"
                                                value={emergency.contact_name}
                                                label="Contact Name"
                                                name="contact_name"
                                                onChange={e=>this.handleEmergencyInfoChange(e, i)}
                                                minLength={3}
                                                maxLength={50}
                                                pattern={"([a-zA-Z]\.+\\s)*[a-zA-Z]+$"}
                                                autoComplete={"off"}
                                                errorMessage="Please enter your father's name(Min 3 char.)."/>
                                            </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-md-6">
                                                <label>Contact Number</label>
                                            </div>
                                            <div class="col-md-6">
                                            <input
                                            className="form-control edit-field"
                                            type="text"
                                            name="contact_number"
                                            value={emergency.contact_number}
                                            label="Contact Number"
                                            onChange={e=>this.handleEmergencyInfoChange(e, i)}/>
                                            </div>
                                            </div>
                                            </React.Fragment>)}

                                            
                                            
                                            <div style={{display:"inline", margin:"20px"}} className="submit-btn">
                                                <Button
                                                className="update-btn"
                                                type="submit"
                                                onClick={this.handleSubmit.bind(this)}
                                                >Submit</Button>
                                            </div>
                                            <div style={{display:"inline"}} className="cancel-btn">
                                                <Button
                                                className="cancel-btn"
                                                onClick={this.handleCancel.bind(this)}
                                                >Cancel</Button>
                                            </div>
                                        </React.Fragment>
                                        ):("")}
                 </div>                            
                        </div>
                    </div>
                </div>
            </form>           
        </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    const {getProfileSuccess,isUserProfileLoading, profileErrorMsg, profileInfo, userPersonalInfo} = state.userReducer;
    return {getProfileSuccess,isUserProfileLoading, profileErrorMsg, profileInfo, userPersonalInfo}
}

export default withRouter(connect(mapStateToProps)(EditOtherInfo))