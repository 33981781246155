import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'xd-react-button';
import createNotification from "../../../components/app/notification/createNotification";
import {getModuleTaskList} from '../../../actions/admin/internModuleActions';
import {getFeedbackFormList, reassignFeedbackForm, clearReassignFeedbackData} from '../../../actions/employee/feedbackActions';
class AssignModuleFeedback extends Component{
    constructor(props){
        super(props)
        this.state= { selectedForm:''}
    }

    componentDidMount(){
        this.props.dispatch(getModuleTaskList(this.props.match.params.id));
        this.props.dispatch(getFeedbackFormList())
        
    }
    handleOnChange(e,id){
            this.setState({
                selectedForm:id
            })
    }
    handleReassignFeedbackForm(e){
        e.preventDefault();
        if(this.state.selectedForm.length!==0){
            let body = {
                form: this.state.selectedForm,
                module:this.props.moduleDetails.id ,
              }
            this.props.dispatch(reassignFeedbackForm(body))
        }else{
            createNotification('error', 'Select atleast one feedback form.')
        }
        }
        
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.reassignFeedbackSuccessMsg.length!==0){
            createNotification('success', nextProps.reassignFeedbackSuccessMsg);
            nextProps.history.push('/admin/employee/list-module/1')
            nextProps.dispatch(clearReassignFeedbackData())
          }
        if(nextProps.reassignFeedbackErrorMsg.length!==0){
            createNotification('error', nextProps.reassignFeedbackErrorMsg);
            nextProps.dispatch(clearReassignFeedbackData());
        }
    }

render(){
    const { moduleDetails}= this.props
    return(
    <main className="main-content-wrapper">
        <section className="dashboard-content-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                            <h2 className="content-header-title">Reassign Feedback Form</h2>
                            <p className="content-header-description">Reassign the feedback form to module.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-right">                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="dashboard-body-wrapper">
            <div className="container">                   
                <div className="main-content-body">                        
                    <section className="content-section-wrapper">
                    <div className="view-feedback-form-wrapper feedback-form-wrapper">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                          <div className="heading">Selected Module</div>
                        </div>
                      </div>
                    </div>
                    <ul className="custom-list-item-wrapper">
                            <li className="custom-list-item">
                                <div className="custom-list-item-content">
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                <span className="custom-list-item-label">Name</span>
                                                    <p class="list-content-title"><b>
                                                        {moduleDetails.name}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                <span className="custom-list-item-label">Description</span>
                                                    <p className="custom-list-item-value">
                                                        {moduleDetails.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                    <span className="custom-list-item-label">Level</span>
                                                    <p className="custom-list-item-value">{moduleDetails.level}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                    <span className="custom-list-item-label">Duration</span>
                                                    <p className="custom-list-item-value">{moduleDetails.duration}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="custom-list-sub-item">
                                                <div className="custom-list-sub-item-content">
                                                    <span className="custom-list-item-label">Added By</span>
                                                    <p className="custom-list-item-value">{moduleDetails.added_by}</p>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </li>
            </ul>
                    </div>
                    </section>
                    <section className="content-section-wrapper">
                  <div className="table-wrapper">
                    <div className="heading">Select Feedback Form</div>
                    <div>
                      <div className="table-container " >
                        <table className="listing-table " style={{width:"100%"}}>
                          <thead>
                            <tr>
                              <th></th>
                              <th className="sno-module">Sr. No</th>
                              <th className="module-name">Name</th>
                              <th className="module-email">Description</th>

                            </tr>
                          </thead>
                          <tbody>
                            {(!this.props.isFeedbackListLoading && this.props.feedbackFormList.length > 0) &&
                             this.props.feedbackFormList.map((form,i)=>
                            <tr>
                              <td ><input type="radio" 
                              onChange={(e) => this.handleOnChange(e,form.slug)} 
                              name="reviewers" /></td>
                              <td className="content sno-module">{i+1}</td>
                              <td className="content module-name"> {form.title}</td>
                              <td className="content module-description">{form.description}</td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
                </div>
                <div className="xd-btn-group" style={{marginLeft:"7px"}}>
                  <Button onClick={() => this.props.history.push("/admin/employee/list-module/1")} >Back</Button>
                  <Button type="submit" onClick={(e) => this.handleReassignFeedbackForm(e)}>Reassign</Button>
                </div>
            </div>
        </div>
    </main>
        )
    }
}

function mapStateToProps(state) {
    const {feedbackFormList, selectedFeedbackForm, isFeedbackListLoading, reassignFeedbackErrorMsg, reassignFeedbackSuccessMsg}=state.feedbackReducer;
    const { moduleList,moduleDetails, getModulePageLoading} = state.moduleReducer;
    return { moduleList, moduleDetails, getModulePageLoading, selectedFeedbackForm, feedbackFormList, isFeedbackListLoading, reassignFeedbackErrorMsg, reassignFeedbackSuccessMsg }
}

export default withRouter(connect(mapStateToProps)(AssignModuleFeedback))
