import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import EmployeeSubHeader from './EmployeeSubHeader';
import TaskItem from '../../../components/employee/module/TaskItem';
import {
    getTaskListing,
    clearTaskStatusData,
    handleUploadPresentationDialogChange,
    getPresentationLink,
    uploadPresentationLink,
    deleteUploadedPresentation,
} from '../../../actions/employee/userModuleActions';
import createNotification from '../../../components/app/notification/createNotification';
import { BlockLoader } from 'xd-react-loader';
import { Button } from 'xd-react-button';
import './dist/breadcrumb.css';
import UploadPresentationPopup from './UploadPresentationPopup';

class TaskListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskdetail: '',
        };
    }
    componentDidMount() {
        let id = this.props.match.params.ModuleID;
        this.props.dispatch(getTaskListing(id));
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.statusErrorMsg.length != 0) {
            createNotification('error', nextProps.statusErrorMsg);
            nextProps.dispatch(clearTaskStatusData());
        }
        if (nextProps.getDetailsAfterPresentationUpload) {
            let id = this.props.match.params.ModuleID;
            this.props.dispatch(getTaskListing(id));
        }
        if (nextProps.uploadPresentationAfterDelete) {
            const data = {
                module_id: this.props.moduleData.id,
                link: '',
            };
            this.props.dispatch(uploadPresentationLink(data));
        }
        
    }
    setTaskDetail(e, task) {
        this.setState({
            taskdetail: task,
        });
    }
    handleUploadPresentation() {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleUploadPresentationDialogChange(true));
    }
    handleRemovePresentation() {
        const links = this.props.moduleData.presentation.split(',');
        for (let i = 0; i < links.length; i++) {
            const deleteData = {
                filename: links[i],
            };
            this.props.dispatch(deleteUploadedPresentation(deleteData));
        }
    }
    handleGetPresentationLink(presentation) {
        this.props.dispatch(getPresentationLink(presentation));
    }

    render() {
        const { moduleData } = this.props;
        const tasks = this.props.taskList;
        let modulePpt =
            moduleData.presentation !== undefined
                ? moduleData.presentation.split(',')
                : [''];
        return (
            <main className="main-content-wrapper employee-dashboard_wrapper">
                <EmployeeSubHeader />
                <div className="dashboard-body-wrapper gry-fill-background ">
                    <section className="full-width-content-section task-listing-section">
                        <div className="container">
                            <div className="module-details_header">
                                <div className="page-navigation-breadcrumbs">
                                    <ul class="breadcrumb">
                                        <li class="breadcrumb-item">
                                            <NavLink
                                                to="/employee/modules"
                                                class="breadcrumb-anchor"
                                            >
                                                Modules
                                            </NavLink>
                                            <div class="seperator">/</div>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <span>{moduleData.name}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="module-details_content module-list_item_content ">
                                    <h2>
                                        <b>{moduleData.name}</b>
                                    </h2>
                                    <p>{moduleData.description}</p>
                                    <ul className="meta-data_list">
                                        <li>
                                            <div className="meta-data_item">
                                                Duration : {moduleData.duration}{' '}
                                                Day<small>(s)</small>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="meta-data_item">
                                                Level : {moduleData.level}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="meta-data_item">
                                                Remaining Time :{' '}
                                                {moduleData.remaining_time} Day
                                                <small>(s)</small>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="module-list_wrapper">
                                <div className="module-list-header">
                                    <h3>Task List</h3>
                                </div>
                                {this.props.isTaskListLoading ? (
                                    <div style={{ paddingLeft: '30px' }}>
                                        <BlockLoader
                                            loadingText="loading"
                                            loading={true}
                                            loaderStyle={{
                                                size: '20px',
                                                color: '0562e8',
                                                textSize: '18px',
                                                textColor: '#485e7d',
                                                speed: '1s',
                                            }}
                                        />
                                    </div>
                                ) : this.props.taskErrorMsg ? (
                                    <p>{this.props.taskErrorMsg}</p>
                                ) : (
                                    <div className="module-list_content">
                                        <div className="row">
                                            <div className="col-12 col-sm-7">
                                                <div className="module-listing_card">
                                                    <div className="module-list_grid">
                                                        {tasks.map(
                                                            (task, index) => (
                                                                <div
                                                                    className="module-list_item"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.setTaskDetail(
                                                                            e,
                                                                            task
                                                                        )
                                                                    }
                                                                    key={index}
                                                                >
                                                                    <TaskItem
                                                                        TaskList={
                                                                            task
                                                                        }
                                                                        ModuleId={
                                                                            this
                                                                                .props
                                                                                .match
                                                                                .params
                                                                                .ModuleID
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className="module-list-header"
                                                    style={{
                                                        marginTop: '30px',
                                                    }}
                                                >
                                                    <h3>Upload Presentation</h3>
                                                </div>
                                                <div className="presentation-container">
                                                    {moduleData.presentation !==
                                                        '' && (
                                                        <div className="profile-action">
                                                            <strong
                                                                onClick={(e) =>
                                                                    this.handleRemovePresentation()
                                                                }
                                                                className={`${
                                                                    moduleData.status !==
                                                                    'Done'
                                                                        ? 'link-button-remove-overlay'
                                                                        : 'link-button-remove'
                                                                } `}
                                                            >
                                                                {this.props
                                                                    .deletePresentationLoading ? (
                                                                    <BlockLoader
                                                                        loading={
                                                                            true
                                                                        }
                                                                        loaderStyle={{
                                                                            size: '14px',
                                                                            color: '0562e8',
                                                                            speed: '1s',
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <i
                                                                        class="fa fa-times-circle"
                                                                        style={{
                                                                            fontSize:
                                                                                '24px',
                                                                        }}
                                                                    ></i>
                                                                )}
                                                            </strong>
                                                        </div>
                                                    )}
                                                    <div
                                                        className={
                                                            moduleData.status ===
                                                            'Done'
                                                                ? ''
                                                                : ''
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                moduleData.status !==
                                                                'Done'
                                                                    ? ''
                                                                    : 'presentation_wrapper'
                                                            }
                                                        >
                                                            <div className="module-list_item">
                                                            {moduleData.status === 'Done' && (
                                                                <div className="profile-fields_data education-document-list">
                                                                    <p className="fields-name">
                                                                        Presentation
                                                                    </p>
                                                                    {modulePpt.length >=
                                                                    1 && this.props.moduleData.presentation !== '' ?  (
                                                                        <>
                                                                            {modulePpt.map(
                                                                                (
                                                                                    pptList,
                                                                                    i
                                                                                ) => (
                                                                                    <p className="fields-value fields-value_link">
                                                                                        Presentation
                                                                                        <a
                                                                                            href="javascript:void(0)"
                                                                                            onClick={() =>
                                                                                                this.handleGetPresentationLink(
                                                                                                    pptList
                                                                                                )
                                                                                            }
                                                                                            className="doc-download"
                                                                                        >
                                                                                            <i className="icon-cloud-download"></i>
                                                                                            View/download
                                                                                        </a>
                                                                                    </p>
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <span className="no-data-text-small">
                                                                            N/A
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {moduleData.status !==
                                                        'Done' && (
                                                        <div className="presentation-overlay">
                                                            <i
                                                                class="fa fa-lock"
                                                                aria-hidden="true"
                                                                style={{
                                                                    padding:
                                                                        '10px',
                                                                    fontSize:
                                                                        '18px',
                                                                }}
                                                            ></i>
                                                            Upload presentation
                                                            after completing the
                                                            module.
                                                        </div>
                                                    )}
                                                </div>
                                                {moduleData.status ===
                                                    'Done' && (
                                                    <div
                                                        style={{
                                                            marginTop: '20px',
                                                            padding: '0px',
                                                        }}
                                                    >
                                                        <div className="module-list_item">
                                                            <Button
                                                                type="button"
                                                                className="btn btn-compact btn-outline"
                                                                onClick={(e) =>
                                                                    this.handleUploadPresentation(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                {' '}
                                                                <i
                                                                    class="fa fa-upload"
                                                                    aria-hidden="true"
                                                                ></i>{' '}
                                                                Upload
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {this.state.taskdetail !== '' ? (
                                                <div className="col-12 col-sm-5">
                                                    <div className="module-list_grid">
                                                        <div className="module-list-details_modal module-list_details">
                                                            <div className="module-list-details_modal_content">
                                                                <h3>
                                                                    Task -{' '}
                                                                    {
                                                                        this
                                                                            .state
                                                                            .taskdetail
                                                                            .name
                                                                    }
                                                                </h3>
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state
                                                                            .taskdetail
                                                                            .description
                                                                    }
                                                                </p>

                                                                <div className="module-list-refrence_link_list">
                                                                    <h4>
                                                                        Reference
                                                                        Links
                                                                    </h4>
                                                                    <ul>
                                                                        {this.state.taskdetail.resources.map(
                                                                            (
                                                                                resource,
                                                                                index
                                                                            ) => (
                                                                                <li>
                                                                                    <div className="refrence-link_item">
                                                                                        <a
                                                                                            href={
                                                                                                resource
                                                                                            }
                                                                                            target="_blank"
                                                                                        >
                                                                                            {
                                                                                                resource
                                                                                            }
                                                                                        </a>
                                                                                        <span className="refrence-link-copy">
                                                                                            <i
                                                                                                className="fa fa-copy"
                                                                                                onClick={() => {
                                                                                                    navigator.clipboard.writeText(
                                                                                                        resource
                                                                                                    );
                                                                                                }}
                                                                                            ></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                    <h4>
                                                                        Download
                                                                        Attachment
                                                                    </h4>
                                                                    <ul>
                                                                        {this
                                                                            .state
                                                                            .taskdetail
                                                                            .object_link
                                                                            ?.length >
                                                                        0
                                                                            ? this.state.taskdetail.object_link?.map(
                                                                                  (
                                                                                      resource,
                                                                                      index
                                                                                  ) => (
                                                                                      <li>
                                                                                          <div className="refrence-link_item">
                                                                                              <a
                                                                                                  href={
                                                                                                      resource
                                                                                                  }
                                                                                                  target="_blank"
                                                                                                  className="refrence-link_item_pdf"
                                                                                              >
                                                                                                  Attachment{' '}
                                                                                                  {index +
                                                                                                      1}
                                                                                                  <div>
                                                                                                      <a
                                                                                                          href={
                                                                                                              resource.links
                                                                                                          }
                                                                                                          target="_blank"
                                                                                                          className="doc-download"
                                                                                                      >
                                                                                                          <i className="icon-cloud-download"></i>
                                                                                                          View/download
                                                                                                      </a>
                                                                                                  </div>
                                                                                              </a>
                                                                                          </div>
                                                                                      </li>
                                                                                  )
                                                                              )
                                                                            : 'N/A'}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                    <UploadPresentationPopup moduleId={moduleData.id} />
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        taskListSuccess,
        taskList,
        moduleData,
        taskSuccessMsg,
        taskErrorMsg,
        isTaskListLoading,
        changeStatusSuccess,
        statusErrorMsg,
        getDetailsAfterPresentationUpload,
        presentationLinkUploadSuccess,
        uploadPresentationLoading,
        presentationLinkUploadError,
        uploadPresentationAfterDelete,
        deletePresentationLoading,
    } = state.userModuleReducer;
    return {
        taskListSuccess,
        taskList,
        moduleData,
        taskSuccessMsg,
        taskErrorMsg,
        isTaskListLoading,
        changeStatusSuccess,
        statusErrorMsg,
        getDetailsAfterPresentationUpload,
        presentationLinkUploadSuccess,
        uploadPresentationLoading,
        presentationLinkUploadError,
        uploadPresentationAfterDelete,
        deletePresentationLoading,
    };
}

export default withRouter(connect(mapStateToProps)(TaskListing));
