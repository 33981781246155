import React, { Component } from 'react';
import {  withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/style.css';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import createNotification from "../../../components/app/notification/createNotification";
import { Avatar } from 'xd-react-avatar';
import {getAssignedReviewerListing, addMemberToMeetingList, removeMemberFromMeeting, updateReviewMeetingData, sendReviewMeetingInvite, clearReviewInviteData, clearReviewInviteErrorData} from '../../../actions/admin/reviewerActions';
import moment from "moment";
import NoDataFound from '../../../components/app/no-data-found/NoDataFound';
import { BlockLoader} from 'xd-react-loader';
import userRoles from '../../../components/roles/roles'

class ReviewInvite extends Component{
    constructor(props){
        super(props)
        this.state= { otherGuest:'',
    descriptionError:''}
    }

    componentDidMount(){
        if(this.props.roleId===userRoles.Reviewer){
            this.props.history.push('/admin/employee/l1')
        }
        let email=this.props.match.params.email
        this.props.dispatch(addMemberToMeetingList(email))
        this.props.dispatch(getAssignedReviewerListing(email))
    }

    static getDerivedStateFromProps(nextProps, prevState) {
       if(nextProps.reviewInviteSuccessMsg.length!==0){
        createNotification('success', nextProps.reviewInviteSuccessMsg);
            nextProps.history.push('/admin/employee/l1')
            nextProps.dispatch(clearReviewInviteData())
          }
        if(nextProps.reviewInviteErrorMsg.length!==0){
            createNotification('error', nextProps.reviewInviteErrorMsg);
            nextProps.dispatch(clearReviewInviteErrorData());
        }
    }
    
    addReviewerToMeeting(e,reviewer){
        let duplicateEmail= false
        for(let memberList of this.props.meetingMemberList){
            if(memberList === reviewer){
                duplicateEmail=true
                break
            }
        }if(duplicateEmail===true){createNotification('error', "Member already added.");}
        else{this.props.dispatch(addMemberToMeetingList(reviewer))}
    }
    handleRemoveMember(member, index){
        const list = this.props.meetingMemberList;
        list.splice(index, 1);
        this.props.dispatch(removeMemberFromMeeting(list))
    }
    handleOnChange(e){
        const newState = Object.assign(this.props.reviewMeetingData, {
            [e.target.name]: e.target.value
            });
            this.props.dispatch(updateReviewMeetingData(newState))
          if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove("has-error");
            e.target.parentElement.parentElement.classList.remove("has-error");
          } else {
            e.target.parentElement.classList.add("has-error");
            e.target.parentElement.parentElement.classList.add("has-error");
          }
          
    }
    handleSendMeetingInvite(e){
        e.preventDefault();
        var email=document.getElementById("other-guest");
        
        if (e.target.checkValidity() && email.checkValidity()){
            const sdate= this.props.reviewMeetingData.date + " "+ this.props.reviewMeetingData.start
            var startDate = (Date.parse(sdate))/1000;
            const edate= this.props.reviewMeetingData.date + " "+ this.props.reviewMeetingData.end
            var endDate = (Date.parse(edate))/1000;
            const details={
                "title":this.props.reviewMeetingData.title,
                "description":this.props.reviewMeetingData.description,
                "start": startDate,
                "end": endDate,
                "email": this.props.meetingMemberList
            }
            this.props.dispatch(sendReviewMeetingInvite(details))
        }else{
            if(this.props.reviewMeetingData.description==0){
                this.setState({descriptionError:"Please enter description(min 3 char.)"})
            }else{this.setState({descriptionError:''})}
            let allInvalidInput = document.querySelectorAll(
                ".form-group .form-control:invalid"
              );
              if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of  allInvalidInput) {
                    invalidInput.parentElement.classList.add("has-error");
                    invalidInput.parentElement.parentElement.classList.add("has-error");
                }
              }
        }
    }
    checkScheduleLink(){
        var link='https://calendar.google.com/calendar/u/0/r?cid='
        for(let i=0; i< this.props.meetingMemberList.length;i++){
            if(i===this.props.meetingMemberList.length-1){
                link= link + this.props.meetingMemberList[i]
            }else{
                link= link + this.props.meetingMemberList[i]+'&cid='
            }
        }
        window.open(link);
    }
    handleAddOtherGuest(e){
        const guest= e.target.value
        this.setState({
            otherGuest:guest
        })
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove("has-error");
            e.target.parentElement.parentElement.classList.remove("has-error");
          } else {
            e.target.parentElement.classList.add("has-error");
            e.target.parentElement.parentElement.classList.add("has-error");
          }
    }
    addOtherGuest(){
        let duplicateEmail= false
        const input= document.getElementById('other-guest')
        for(let meetingMember of this.props.meetingMemberList){
            if(meetingMember === this.state.otherGuest){
                duplicateEmail=true
                break
            }
        }if(input.checkValidity()) {
        if(duplicateEmail===true){createNotification('error', "Member already added.");}
        else{
            this.props.dispatch(addMemberToMeetingList(this.state.otherGuest))}
            this.setState({
                otherGuest : ''
            });}else{
                input.parentElement.classList.add("has-error");
            input.parentElement.parentElement.classList.add("has-error");
            }
    }

render(){
    const {  assignedReviewerList}= this.props
    const currentDate= moment(new Date().getTime() + 5 * 60 * 1000).format("YYYY-MM-DD")
    return(
    <main className="main-content-wrapper">
        <section className="dashboard-content-header">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                            <h2 className="content-header-title">Review Invite</h2>
                            <p className="content-header-description">Send the meeting invite for the review.</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-right">                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="dashboard-body-wrapper">
            <div className="container">
            <form onSubmit={this.handleSendMeetingInvite.bind(this)} noValidate={true} id='edit-education-dialog'>                   
                <div className="main-content-body">   
                <div className="row">               
            <div className="col-12 col-sm-6">                      
                    <section className="content-section-wrapper">
                    <div className="view-feedback-form-wrapper feedback-form-wrapper">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6">
                        <div className="dashboard-content-header-left">
                          <div className="heading">Add Meeting Details</div>
                        </div>
                      </div>
                    </div>
                    
                    <Input type="text" 
                    name="title"
                    className="form-control"
                    errorMessage="Enter meeting title(min 3 char.)"
                    placeholder="Title" 
                    label="Title"
                    onChange={this.handleOnChange.bind(this)}
                    required
                    minLength={3}
                    maxLength={50}
                    /> 
                    <div className="form-group">
                        <label>Add Description <span className="req">*</span> </label>
                        <div className="input-wrapper">
                        <textarea  
                        name="description"
                        className="form-control"
                        required={true}
                        placeholder="Description"
                        onChange={this.handleOnChange.bind(this)}
                        minLength={3}
                        maxLength={200}
                        value={this.props.reviewMeetingData.description}
                        pattern={"([a-zA-Z]\.+\\s)*[a-zA-Z]+$"}
                        > </textarea>
                        <p class="with-error">Please enter description(min 3 char.)</p>
                        </div>
                        </div>
                        <div className="form-group">
                            <Input type="date"
                            name="date"
                            className="form-control"
                            min={currentDate}
                            errorMessage="Enter date of meeting"
                            placeholder="Date"
                            label="Select Date"
                            onChange={this.handleOnChange.bind(this)}
                            required
                            pattern={"([a-zA-Z0-9-_])+$"}
                            />
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <Input type="time"
                                    name="start"
                                    className="form-control"
                                    errorMessage="Enter meeting start time"
                                    placeholder="Start Time"
                                    label="Start Time"
                                    onChange={this.handleOnChange.bind(this)}
                                    required
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="form-group">
                                    <Input type="time"
                                    name="end"
                                     className="form-control"
                                     errorMessage="Enter meeting end time."
                                     placeholder="End Time"
                                     label="End Time"
                                     onChange={this.handleOnChange.bind(this)}
                                     required
                                     />
                                </div>
                            </div>
                        </div>
                        <div className="xd-btn-group" >
                  <Button onClick={() => this.props.history.push("/admin/employee/l1")} >Back</Button>
                    <Button type="submit" >Create {this.props.reviewMeetingLoading && <i className="fa fa-spin fa-spinner btn-icon"></i>}</Button>
                </div>
                    </div>
                    </section>
                    </div>
                    <div className="col-12 col-sm-6">  
                    <section className="content-section-wrapper">
                  <div className="table-wrapper">
                  
                  <div className="row">
                      <div className="col-12 col-sm-6">
                        <div className="dashboard-content-header-left">
                        <div className="heading">Add Members/Reviewers</div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="dashboard-content-header-left">
                      <div className="btn-calender-link" onClick={()=>this.checkScheduleLink()}><i class="fa fa-calendar" aria-hidden="true" style={{color:"black"}}></i> Check Schedule</div>
                      </div></div>
                    </div>
                  
                    {this.props.meetingMemberList.length!==0?
                    <div className="row">
                      <div className="col-12 col-sm-12">
                        <div className="dashboard-content-header-left">
                        <div className="form-group"> 
                        <div className="member-list-container">
                            {this.props.meetingMemberList.map((member,i)=>(
                                <>
                                {i===0?(<><p className="meeting-list-member"><b>Intern: </b>{member}</p></>):(<p className="meeting-list-member">{member} <i onClick={()=>this.handleRemoveMember(member, i)} class="fa fa-close"></i>
                                </p>)}
                            </>))}
                        </div>
                        </div>
                      </div>
                    </div>
                    </div>:("")}
                    

                    <h2 className="profile-section_card_title" style={{fontSize:"14px"}}>Reviewers List</h2>
                    <div>
                            {(this.props.assignedReviewerListLoading ? (
                    <div className="page-section-loader">
                        <BlockLoader
                            loadingText="Loading Reviewer Details .."
                            loading={true}
                            loaderStyle={{
                                size: "24px",
                                color: "0562e8",
                                textSize: "14px",
                                textColor: "#757575",
                                speed: "0.5s"
                            }}
                        />
                    </div>
                    ):this.props.assignedReviewerListError?(
                       <NoDataFound title={this.props.assignedReviewerListError} />
                    ):this.props.assignedReviewerListSuccess?((
                          assignedReviewerList.length > 0) &&
                             assignedReviewerList.map((reviewer,i)=>
                             <div className="custom-list-item-content">
                                 <div className="row">  
                                     <div className="col-10">
                                         <div className="custom-list-sub-item">
                                             <div className="custom-list-sub-item-content assigned-reviewer-meta-info">
                                                 <div className="assigned-reviewer-avatar">
                                                 <Avatar name={reviewer.name} size={36} radius={36}/>
                                                 </div>
                                                 <div className="assigned-reviewer-info">
                                                     <h3 className="assigned-reviewer-name">{reviewer.name}</h3>
                                                     <p className="assigned-reviewer-email">{reviewer.email}</p>
                                                 </div>                                       
                                             </div>
                                         </div>
                                     </div>
                                     <div className="col-2">
                                         <div className="custom-list-sub-item">
                                             <div className="custom-list-sub-item-content">
                                                 <div className="assigned-reviewr-action">
                                                     <button type="button" className="custom-icon-button close-icon-btn" onClick={(e)=>this.addReviewerToMeeting(e, reviewer.email)}>
                                                     <i className="fa fa-plus"></i>
                                                     </button>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                             )):(<NoDataFound />)
                            )}
                    </div>
                    <div className="row" style={{marginTop:"20px"}}>
                      <div className="col-12 col-sm-6 col-md-10">
                        <div className="dashboard-content-header-left">
                        <div className="form-group">
                            <input type="email" 
                            name="add-other-guest"
                            id="other-guest"
                            className="form-control"
                            value= {this.state.otherGuest}
                            placeholder="Add other guests"
                            onChange={this.handleAddOtherGuest.bind(this)}
                            pattern={"([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}"}
                            />
                            <p class="with-error">Please enter valid email</p>
                            
                        </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-2">
                        <button type="button" className="custom-icon-button close-icon-btn"
                        onClick={(e)=>this.addOtherGuest(e)}
                        >
                            <i className="fa fa-plus"></i>
                            </button>
                    </div>
                </div>
                  </div>
                </section>
                </div>
                </div>
              </div></form>
            </div>
        </div>
    </main>
        )
    }
}

function mapStateToProps(state) {
    const { userProfileData}= state.employeeReducer;
    const {roleId} = state.authReducer;
    const {assignedReviewerList, assignedReviewerListLoading, assignedReviewerListSuccess, assignedReviewerListError, meetingMemberList, reviewMeetingData, reviewInviteSent, reviewMeetingLoading, reviewInviteSuccessMsg, reviewInviteErrorMsg, checkCredentialsErrorMsg, checkCredentialsSuccessMsg}=state.reviewerReducer;
    return {roleId, userProfileData,assignedReviewerList, assignedReviewerListLoading, assignedReviewerListSuccess, assignedReviewerListError, meetingMemberList, reviewMeetingData, reviewMeetingLoading, reviewInviteSent, reviewInviteSuccessMsg, reviewInviteErrorMsg, checkCredentialsErrorMsg, checkCredentialsSuccessMsg}
}

export default withRouter(connect(mapStateToProps)(ReviewInvite))
