import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

class ProfileNavigation extends Component {
    render(){
        return(
        <div className="profile-navition-navbar">                
            <ul className="profile-nav-tabs">
                <li className="profile-nav-item">
                    <NavLink to='/employee/profile/account-information' 
                    className="profile-nav-link">Account Details</NavLink>
                </li>
                <li className="profile-nav-item">
                    <NavLink to='/employee/profile/personal-information' 
                    className="profile-nav-link">Personal Details</NavLink>
                </li>
                <li className="profile-nav-item">
                    <li><NavLink to='/employee/profile/education-information' 
                     className="profile-nav-link">Education Details</NavLink></li>
                </li>
                <li className="profile-nav-item">
                    <li><NavLink to='/employee/profile/other-information' 
                     className="profile-nav-link">Other Details</NavLink></li>
                </li>
            </ul>
        </div>
        )
    }
}

function mapStateToProps(state) {
   return state
}

export default withRouter(connect(mapStateToProps)(ProfileNavigation))
