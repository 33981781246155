import React, {Component} from 'react';
import {withRouter,Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from '../../components/employee/header/Header';
import ModulesIndex from '../employee/modules';
import UserProfileIndex from '../employee/profile';
import DashboardIndex from '../employee/dashboard';

class EmployeeMainIndex extends Component {
    componentDidMount(){
        const role=localStorage.getItem('role')
        if(role!=='user'){
            this.props.history.push('/admin/dashboard')
        }
    }
    render() {
        return (   
            <div className="dashboard-wrappper user-side-dashboard"> 
            <div className="dashboard-employee-body-content"> 
                    <Header />
                    <Switch>
                        <Route exact path="/employee" component={ModulesIndex}/>
                        <Route path="/employee/modules" component={ModulesIndex} />
                        <Route path="/employee/profile" component={UserProfileIndex} />
                        <Route path="/employee/dashboard" component={DashboardIndex} />
                    </Switch>
                </div>
            </div>             
        )
    }
}

function mapStateToProps(state) {
    const {toggleStatus} = state.appReducer;
    return {toggleStatus}
}

export default withRouter(connect(mapStateToProps)(EmployeeMainIndex))