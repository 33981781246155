import {
    MODULE_LIST_REQUEST,
    MODULE_LIST_SUCCESS,
    MODULE_LIST_FAILURE,
    TASK_LIST_REQUEST,
    TASK_LIST_SUCCESS,
    TASK_LIST_FAILURE,
    CHANGE_TASK_STATUS_FAILURE,
    CHANGE_TASK_STATUS_REQUEST,
    CHANGE_TASK_STATUS_SUCCESS,
    HANDLE_MODULE_COMPLETE_DIALOG_STATE,
    CLEAR_TASK_STATUS_DATA,
    HANDLE_UPLOAD_PRESENTATION_DIALOG_CHANGE,
    UPLOAD_PRESENTATION_REQUEST,
    UPLOAD_PRESENTATION_SUCCESS,
    UPLOAD_PRESENTATION_FAILURE,
    UPLOAD_PRESENTATION_LINK_REQUEST,
    UPLOAD_PRESENTATION_LINK_SUCCESS,
    UPLOAD_PRESENTATION_LINK_FAILURE,
    GET_PRESENTATION_LINK_REQUEST,
    GET_PRESENTATION_LINK_SUCCESS,
    GET_PRESENTATION_LINK_FAILURE,
    DELETE_PRESENTATION_REQUEST,
    DELETE_PRESENTATION_SUCCESS,
    DELETE_PRESENTATION_FAILURE,
    CLEAR_PRESENTATION_UPLOAD_DATA,
    CLEAR_MODULE_LIST,
    CLEAR_NOTIFICATION_DATA
} from '../../constants/employee/userModuleConstants';

import {
    GET_API,
    DELETE_API,
    PUT_API,
} from '../../middleware/symbols';
import { moduleApi, getDocumentApi } from '../ApiActions';

const MODULE_API = moduleApi();
const DOCUMENT_API = getDocumentApi();

export function getModuleListing() {
    return {
        [GET_API]: {
            endpoint: MODULE_API + `/v1/user/listmodules`,
            authenticated: true,
            types: [
                MODULE_LIST_REQUEST,
                MODULE_LIST_SUCCESS,
                MODULE_LIST_FAILURE,
            ],
        },
    };
}
export function getTaskListing(id) {
    return {
        [GET_API]: {
            endpoint: MODULE_API + `/v1/user/listtasks/` + id,
            authenticated: true,
            types: [TASK_LIST_REQUEST, TASK_LIST_SUCCESS, TASK_LIST_FAILURE],
        },
    };
}
export function updateModuleListing() {
    return {
            authenticated: true,
            type:CLEAR_MODULE_LIST ,
    };
}
export function changeTaskStatus(body, moduleId) {
    let status = '';
    const config = {
        method: 'PUT',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    };
    return (dispatch) => {
        dispatch(changeTaskStatusRequest());
        fetch(
            MODULE_API + `/v1/user/task/${body.taskid}/status/${body.status}`,
            config
        )
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            changeTaskStatusSuccess({
                                data: { error: false, message: res.message },
                                status: 200,
                            })
                        );
                        dispatch(getTaskListing(moduleId));
                    } else {
                        dispatch(
                            changeTaskStatusFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                        dispatch(getTaskListing(moduleId));
                    }
                },
                function () {
                    dispatch(
                        changeTaskStatusFailure({
                            data: {
                                message: 'Error while updating file',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
export function changeTaskStatusRequest() {
    return {
        type: CHANGE_TASK_STATUS_REQUEST,
    };
}
export function changeTaskStatusSuccess(response) {
    return {
        type: CHANGE_TASK_STATUS_SUCCESS,
        response,
    };
}
export function changeTaskStatusFailure(response) {
    return {
        type: CHANGE_TASK_STATUS_FAILURE,
        response,
    };
}
export function clearTaskStatusData() {
    return {
        type: CLEAR_TASK_STATUS_DATA,
    };
}

export function handleModuleCompleteDialogChange(status, id) {
    return {
        type: HANDLE_MODULE_COMPLETE_DIALOG_STATE,
        status,
        id,
    };
}
export function handleUploadPresentationDialogChange(status) {
    return { type: HANDLE_UPLOAD_PRESENTATION_DIALOG_CHANGE, status };
}
export function uploadPresentation(files, moduleId) {
    let formData = new FormData();
    for (var file of files) {
        formData.append('files', file);
    }
    formData.set('type', 'presentation');
    let status = '';
    const config = {
        method: 'POST',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        body: formData,
    };
    return (dispatch) => {
        dispatch(uploadPresentationRequest());
        fetch(DOCUMENT_API + '/user/upload', config)
            .then(function (res) {
                status = res.status;
                return res.json();
            })
            .then(
                function (res) {
                    if (status == 200 && !res.error) {
                        dispatch(
                            uploadPresentationSuccess({
                                data: {
                                    error: false,
                                    message: res.message,
                                    previewLinks: res.preview_links,
                                    uploadLink: res.uploadlink,
                                },
                                status: 200,
                            })
                        );
                        const presentationdata = {
                            module_id: moduleId,
                            link: res.uploadlink.join(),
                        };
                        dispatch(uploadPresentationLink(presentationdata));
                    } else {
                        dispatch(
                            uploadPresentationFailure({
                                data: { error: true, message: res.message },
                                status: status,
                            })
                        );
                    }
                },
                function () {
                    dispatch(
                        uploadPresentationFailure({
                            data: {
                                message: 'Error while uploading presentation',
                                error: true,
                            },
                            status: 500,
                        })
                    );
                }
            );
    };
}
export function uploadPresentationRequest() {
    return { type: UPLOAD_PRESENTATION_REQUEST };
}
export function uploadPresentationSuccess(response) {
    return { type: UPLOAD_PRESENTATION_SUCCESS, response };
}
export function uploadPresentationFailure(response) {
    return { type: UPLOAD_PRESENTATION_FAILURE, response };
}

export function uploadPresentationLink(body) {
    return {
        [PUT_API]: {
            endpoint: MODULE_API + `/v1/presentation`,
            authenticated: true,
            types: [
                UPLOAD_PRESENTATION_LINK_REQUEST,
                UPLOAD_PRESENTATION_LINK_SUCCESS,
                UPLOAD_PRESENTATION_LINK_FAILURE,
            ],
            body: JSON.stringify(body),
        },
    };
}
export function getPresentationLink(link) {
    const token = localStorage.getItem('token');
    let config = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    return (dispatch) => {
        dispatch(getPresentationLinkRequest());
        fetch(DOCUMENT_API + `/getfile/${link}`, config)
            .then(function (response) {
                return response.json();
            })
            .then(
                function (res) {
                    dispatch(getPresentationLinkSuccess(res));
                    window.open(res.link, '_blank');
                },
                function (error) {
                    dispatch(getPresentationLinkFailed(error));
                }
            );
    };
}
export function getPresentationLinkRequest() {
    return {
        type: GET_PRESENTATION_LINK_REQUEST,
    };
}

export function getPresentationLinkSuccess(response) {
    return {
        type: GET_PRESENTATION_LINK_SUCCESS,
        response,
    };
}
export function getPresentationLinkFailed(response) {
    return {
        type: GET_PRESENTATION_LINK_FAILURE,
        response,
    };
}
export function deleteUploadedPresentation(body) {
    return {
        [DELETE_API]: {
            endpoint: DOCUMENT_API + '/delete/' + body.filename,
            authenticated: true,
            types: [
                DELETE_PRESENTATION_REQUEST,
                DELETE_PRESENTATION_SUCCESS,
                DELETE_PRESENTATION_FAILURE,
            ],
            body,
        },
    };
}
export function clearPresentationUploadData() {
    return { type: CLEAR_PRESENTATION_UPLOAD_DATA };
}

export function clearNotificationData() {
    return { type: CLEAR_NOTIFICATION_DATA };
}
