import React, { Component } from 'react';
import '../../../components/intern/forms/dist/form.css';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import { connect } from 'react-redux';
import {
    addEducation,
    removeEducation,
    updateEducationInfo,
    deleteMarksheet,
    uploadFile,
    deleteDegree,
} from '../../../actions/auth/authActions';
import QualificationData from '../../../static/data/qualification.json';
class EducationInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errormsg: '',
            emailError: '',
            uploadError: '',
        };
        this.handleDocumentUpload = this.handleDocumentUpload.bind(this);
        this.handleDeleteMarksheet = this.handleDeleteMarksheet.bind(this);
    }

    handleAddEducation(e) {
        this.props.dispatch(
            addEducation({
                name: '',
                institution: '',
                location: '',
                year: '',
                percentage: 0,
                marksheet_link: '',
                degree_link: '',
            })
        );
    }

    handleDeleteMarksheet(marksheet) {
        this.props.dispatch(deleteMarksheet(marksheet));
    }

    handleRemoveEducation(index) {
        const list = this.props.educationInfo;
        list.splice(index, 1);
        this.props.dispatch(removeEducation(list));
        this.forceUpdate();
    }

    handleOnSubmit(e) {
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.history.push('/member-registration/other-information');
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    handleChange(e, index) {
        let name = e.target.name;
        let value = e.target.value;
        let educationData = this.props.educationInfo;
        if (name == 'percentage') {
            educationData[index][name] = parseFloat(e.target.value);
        } else {
            educationData[index][name] = value;
        }
        // update member data props
        this.props.dispatch(updateEducationInfo(educationData));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }
    }

    handleDocumentUpload(e, index) {
        let input = e.target;
        let name = e.target.name;
        if (input.files) {
            let files = input.files;
            if (
                name == 'degree_link' &&
                this.props.educationInfo[index].degree_link.length != 0
            ) {
                this.props.dispatch(
                    deleteDegree(
                        files,
                        name,
                        index,
                        this.props.educationInfo[index].degree_link
                    )
                );
            } else {
                this.props.dispatch(uploadFile(files, name, index));
            }
        }
    }

    updateUploadLink(e, index) {
        if (this.props.uploadFileSuccess) {
            let name = e.target.name;
            let value = this.props.uploadLink;
            let educationData = this.props.educationInfo;
            educationData[index][name] = value;
        }
    }

    render() {
        const {
            educationInfo,
            isPageLoading,
        } = this.props;

        return (
            <main className="main-content-wrapper auth-content-wrapper full-height">
                <div className="wrapper-container signing-container personal-information">
    <div className="container user-jounrney-form-container">
        <div className="user-journey-form user-journey-form-internal-steps">
            <div className="dashbaord-user-form">
                <div className="section_header">
                    <label>Step 3/4</label>
                    <h2 className="big-heading">Educational Information</h2>
                </div>
                <div className="personal-information" id="personal-information">
                <form
                                                className="employee-signup-form"
                                                noValidate={true}
                                                id="employee-signup-form"
                                                onSubmit={this.handleOnSubmit.bind(
                                                    this
                                                )}
                                            >
                                                {educationInfo.map(
                                                    (education, index) => (
                                                        <div className="education-group">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Qualification{' '}
                                                                            <span className="req">
                                                                                *
                                                                            </span>{' '}
                                                                        </label>
                                                                        <div className="select-wrapper">
                                                                            <select
                                                                                id="qualification"
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    this.handleChange(
                                                                                        e,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                name="name"
                                                                                className="form-control"
                                                                                required={
                                                                                    true
                                                                                }
                                                                            >
                                                                                <option
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    selected
                                                                                    value=""
                                                                                    defaultValue
                                                                                >
                                                                                    Select
                                                                                    Qualification
                                                                                </option>
                                                                                {QualificationData.map(
                                                                                    (
                                                                                        qualification,
                                                                                        index
                                                                                    ) => (
                                                                                        <option
                                                                                            value={
                                                                                                qualification
                                                                                            }
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                qualification
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                )}
                                                                            </select>
                                                                            <div className="help-block with-errors">
                                                                                Please
                                                                                select
                                                                                Qualification.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <Input
                                                                            id="years"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.handleChange(
                                                                                    e,
                                                                                    index
                                                                                )
                                                                            }
                                                                            name="year"
                                                                            className="form-control"
                                                                            errorMessage="Enter your Passing Year"
                                                                            placeholder="Passing Year"
                                                                            label="Year of Passing"
                                                                            type="number"
                                                                            max="9999"
                                                                            min="1000"
                                                                            required
                                                                        />

                                                                        <div className="help-block with-errors">
                                                                            Please
                                                                            select
                                                                            Year
                                                                            of
                                                                            Passing.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <Input
                                                                        type="text"
                                                                        name="institution"
                                                                        id="institution"
                                                                        placeholder="School/College/University Name"
                                                                        required={
                                                                            true
                                                                        }
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        pattern={
                                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                        }
                                                                        autoComplete={
                                                                            'off'
                                                                        }
                                                                        label="School/College/University Name"
                                                                        className="form-control"
                                                                        outerClassName="signup-input-box"
                                                                        errorMessage="Please enter your School/College/University name."
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleChange(
                                                                                e,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <Input
                                                                        type="text"
                                                                        name="location"
                                                                        id="location"
                                                                        placeholder="School/College/University location"
                                                                        required={
                                                                            true
                                                                        }
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        pattern={
                                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                                        }
                                                                        autoComplete={
                                                                            'off'
                                                                        }
                                                                        label="School/College/University Location"
                                                                        className="form-control"
                                                                        outerClassName="signup-input-box"
                                                                        errorMessage="Please enter your School/College/University location."
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleChange(
                                                                                e,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <Input
                                                                        type="text"
                                                                        name="percentage"
                                                                        id="percentage"
                                                                        placeholder="Percentage/CGPA "
                                                                        required={
                                                                            true
                                                                        }
                                                                        pattern={
                                                                            '[0-9]+(.[0-9]{0,2})?%?'
                                                                        }
                                                                        autoComplete={
                                                                            'off'
                                                                        }
                                                                        label="Percentage/CGPA "
                                                                        className="form-control"
                                                                        outerClassName="signup-input-box"
                                                                        errorMessage="Please enter your Percentage/CGPA"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleChange(
                                                                                e,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="col-12">
                                                                    <hr className="form-section-divider"></hr>
                                                                    <h3 className="form-section-header">
                                                                        Add
                                                                        Certificate
                                                                    </h3>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Upload
                                                                            Marksheet{' '}
                                                                            <span className="req">
                                                                                *
                                                                            </span>{' '}
                                                                        </label>
                                                                        <div className="file-wrapper">
                                                                            <input
                                                                                type="file"
                                                                                multiple
                                                                                required
                                                                                name="marksheet_link"
                                                                                id="marksheet_link"
                                                                                autoComplete={
                                                                                    'off'
                                                                                }
                                                                                className="form-control"
                                                                                outerClassName="signup-input-box"
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    this.handleDocumentUpload(
                                                                                        e,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            />
                                                                            <div className="help-block with-errors">
                                                                                Please
                                                                                upload
                                                                                your
                                                                                Marksheet(s).
                                                                            </div>
                                                                            <p
                                                                                style={{
                                                                                    color: '#bf1f67',
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    class="fa fa-info-circle"
                                                                                    aria-hidden="true"
                                                                                ></i>{' '}
                                                                                You
                                                                                can
                                                                                select
                                                                                multiple
                                                                                marksheet
                                                                                by
                                                                                pressing
                                                                                Ctrl
                                                                                or
                                                                                Shift
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-6">
                                                                    <Input
                                                                        type="file"
                                                                        name="degree_link"
                                                                        id="degree_link"
                                                                        placeholder="Upload Degree"
                                                                        autoComplete={
                                                                            'off'
                                                                        }
                                                                        label="Upload Degree "
                                                                        className="form-control"
                                                                        outerClassName="signup-input-box"
                                                                        errorMessage="Please upload your Degree"
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            this.handleDocumentUpload(
                                                                                e,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            {index > 0 && (
                                                                <span
                                                                    className="cross-icon"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.handleRemoveEducation(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </span>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <div
                                                                className="add-more-link"
                                                                onClick={this.handleAddEducation.bind(
                                                                    this
                                                                )}
                                                            >
                                                                <i className="fa fa-plus"></i>
                                                                <span className="add-more-text">
                                                                    Add More
                                                                    Qualification
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-group form-btn-group">
                                                            {!isPageLoading ? (
                                                                <Button
                                                                    type="submit"
                                                                    id="send-invite"
                                                                    formNoValidate={
                                                                        true
                                                                    }
                                                                    className="btn box-shadow"
                                                                >
                                                                    Save &
                                                                    Continue
                                                                </Button>
                                                            ) : (
                                                                <Button className="btn btn-icon icon-left">
                                                                    <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                                    <span className="btn-text">
                                                                        Please
                                                                        wait ..
                                                                    </span>
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                </div>
            </div>
            <div className="xenonstack-jobs-copyright">
                <p>Xenonstack |</p>
                <i className="fa fa-copyright"></i>
                <p>2021 All Rights Reserved</p>
            </div>
        </div>
    </div>
</div>

            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        educationInfo,
        memberRegistrationSuccess,
        isPageLoading,
        memberRegistrationMessage,
        memberSignupData,
        fileLink,
    } = state.authReducer;
    return {
        educationInfo,
        memberRegistrationSuccess,
        isPageLoading,
        memberRegistrationMessage,
        memberSignupData,
        fileLink,
    };
}

export default connect(mapStateToProps)(EducationInfo);
