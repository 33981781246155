import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import {
    updateMemberRegistrationData,
    inviteMemberRegistration,
} from '../../../actions/auth/authActions';
import ReactTelInput from 'react-telephone-input';
import 'react-phone-input-2/lib/style.css';
import '../dist/style.css';

let phone = null;
const url_string = window.location.href;
const url = new URL(url_string);

class CreateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumberLength: 15,
        };
    }  
    componentDidMount() {
        document.getElementsByName('name')[0].focus();
        phone = document.querySelector('input[type="tel"]');
        phone.setAttribute('tabIndex', 2);
        if (url.searchParams.get('token') === null) {
            this.props.history.push('/');
        }
        if (this.props.memberRegistrationSuccess) {
            this.props.history.push(
                '/member-registration/personal-information'
            );
        }
    }

    componentWillReceiveProps(nextProps) {
        const self = this;
        if (nextProps.memberRegistrationSuccess) {
            setTimeout(function () {
                self.props.history.push(
                    '/member-registration/personal-information'
                );
            }, 2000);
        }
    }

    handleInputChange(telNumber, selectedCountry, e) {
        document
            .querySelector('input[type="tel"]')
            .setAttribute('maxlength', selectedCountry.format.length);
        //validation for phone number

        if (telNumber.length !== selectedCountry.format.length) {
            phone.parentElement.parentElement.parentElement.classList.add(
                'has-error'
            );
        } else {
            let parentClassList = phone.parentElement.parentElement.classList;
            let nestedChildClassList = phone.parentElement.parentElement.parentElement.classList;
            parentClassList.contains("has-error") && parentClassList.remove('has-error');
            nestedChildClassList.contains("has-error") &&  nestedChildClassList.remove('has-error');
         
            let memberData = {
                token: this.props.memberSignupData.token,
                name: this.props.memberSignupData.name,
                contact: phone.value,
                password: this.props.memberSignupData.password,
                confirmPassword: this.props.memberSignupData.confirmPassword,
            };
            this.props.dispatch(updateMemberRegistrationData(memberData));
        }
    }
    handleInputBlur(telNumber, selectedCountry) {
        if (telNumber.length === selectedCountry.format.length) {
            phone.setCustomValidity('');
        }
        document.querySelector('label.phn').classList.remove('focused');
    }

    setCaretAtEnd(elem) {
        //this function relocate your pointer to the first character of the input field when we focus into the field.
        const elemLen = elem.value.length;
        if (document.selection) {
            elem.focus();
            const oSel = document.selection.createRange();
            oSel.moveStart('character', -elemLen);
            oSel.moveStart('character', elemLen);
            oSel.moveEnd('character', 0);
            oSel.select();
        } else if (elem.selectionStart || elem.selectionStart === 0) {
            elem.selectionStart = elemLen;
            elem.selectionEnd = elemLen;
            elem.focus();
        }
    }

    handleFocus(e) {
        this.setCaretAtEnd(phone);
        document.querySelector('label.phn').classList.add('focused');
    }

    handleChange(e) {
        const newState = Object.assign(this.props.memberSignupData, {
            [e.target.name]: e.target.value,
            token: url.searchParams.get('token'),
        });
        this.props.dispatch(updateMemberRegistrationData(newState));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
            e.target.parentElement.parentElement.classList.add('has-error');
        }

        // validate confirm password
        if (
            this.props.memberSignupData.password !==
            this.props.memberSignupData.confirmPassword
        ) {
            if (this.props.memberSignupData.confirmPassword !== '') {
                e.target.parentElement.parentElement.classList.add('has-error');
            } else {
                e.target.parentElement.parentElement.classList.remove(
                    'has-error'
                );
            }
        }
    }

    handleOnSubmit(e) {
        if(window.innerHeight < 948){
            setTimeout(() => {
                const scrollOnErrorMessage = document.getElementById('error-msg')
                const scrollOnSuccessMessage = document.getElementById('auth-redirection') 

                if (this.props.memberRegistrationMessage === "Your invite is expired") {
                    scrollOnErrorMessage.scrollIntoView({ behavior: 'smooth' })
                }
                else {
                   // Do Nothing
                }
            }, 100);
        }
       
        //if invitation form is valid then submit the data, else show the validation message on the invalid field.
        e.preventDefault();
         let parentClassList = phone.parentElement.parentElement.parentElement.classList;
        if (e.target.checkValidity() && !parentClassList.contains("has-error") && this.props.memberSignupData.password ===
        this.props.memberSignupData.confirmPassword) {
            this.props.dispatch(
                inviteMemberRegistration(this.props.memberSignupData)
            );
        }

            // phone validation
            if (phone.value.length < 5) {

                phone.setCustomValidity('');                
                phone.parentElement.parentElement.classList.add('has-error');
            } else {
                phone.parentElement.parentElement.classList.remove('has-error');

            }

            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            
        }
    }

    render() {
        const {
            memberRegistrationMessage,
            memberRegistrationSuccess,
            isPageLoading,
        } = this.props;
        const { password } = this.props.memberSignupData;
        return (
            <main className="main-content-wrapper auth-content-wrapper full-height">
                <div className="wrapper-container signing-container personal-information">
    <div className="container user-jounrney-form-container">
        <div className="user-journey-form user-journey-form-internal-steps">
            {/* <div class="xenonstack-jobs-logo">
                <a href="https://careers.neuralcompany.work/"> <img src="/static/media/logoxe.69c52c00.svg" /></a>
            </div> */}
            <div className="dashbaord-user-form">
                <div className="section_header">
                    <label>Step 1/4</label>
                    <h2 className="big-heading">Create Your Account</h2>
                    <p>Fill the required information to create your account.</p>
                </div>
                <div className="personal-information" id="personal-information">
                <form
                                                className="employee-signup-form"
                                                noValidate={true}
                                                id="employee-signup-form"
                                                onSubmit={this.handleOnSubmit.bind(
                                                    this
                                                )}
                                            >
                                                <div className="row">
                                                    <div className="col-12 col-sm-12">
                                                        <Input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            outerClass="input-ctrl"
                                                            placeholder="Full Name e.g: Steven Smith"
                                                            required={true}
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                            }
                                                            autoComplete={'off'}
                                                            label="Full Name"
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            errorMessage="Please enter your full name(min. 3 characters)."
                                                            onChange={this.handleChange.bind(
                                                                this
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-group telephone-outer">
                                                            <label className="phn">
                                                                Phone
                                                            </label>
                                                            <span class="required">
                                                                *
                                                            </span>
                                                            <div className="input-wrapper">
                                                                <ReactTelInput
                                                                    id="react-phone-control"
                                                                    defaultCountry="in"
                                                                    country={
                                                                        'in'
                                                                    }
                                                                    required={
                                                                        true
                                                                    }
                                                                    flagsImagePath={require('../../../static/images/flags.png')}
                                                                    preferredCountries={[
                                                                        'in',
                                                                        'us',
                                                                        'gb',
                                                                    ]}
                                                                    name="phone"
                                                                    tabIndex={2}
                                                                    onChange={this.handleInputChange.bind(
                                                                        this
                                                                    )}
                                                                    onFocus={this.handleFocus.bind(
                                                                        this
                                                                    )}
                                                                    onBlur={this.handleInputBlur.bind(
                                                                        this
                                                                    )}
                                                                />
                                                                <div className="with-error">
                                                                    Please enter
                                                                    a valid
                                                                    phone
                                                                    number.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-group">
                                                            <label>
                                                                Password{' '}
                                                                <span className="req">
                                                                    {' '}
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                required
                                                                type="password"
                                                                name="password"
                                                                autofill={
                                                                    'false'
                                                                }
                                                                maxLength={30}
                                                                minLength={8}
                                                                className="form-control"
                                                                outerClassName="signup-input-box"
                                                                placeholder="Password"
                                                                id="password"
                                                                pattern={
                                                                    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
                                                                }
                                                                onChange={this.handleChange.bind(
                                                                    this
                                                                )}
                                                            />
                                                            <div
                                                                className="help-block with-errors"
                                                                style={{
                                                                    color: 'red',
                                                                }}
                                                            >
                                                                Enter your
                                                                password (
                                                                <span
                                                                    style={{
                                                                        color: `${
                                                                            password.length >=
                                                                            8
                                                                                ? 'green'
                                                                                : '#f03030'
                                                                        }`,
                                                                    }}
                                                                >
                                                                    Min 8 char.
                                                                </span>
                                                                ,{' '}
                                                                <span
                                                                    style={{
                                                                        color: `${
                                                                            password.match(
                                                                                '(?=.*?[A-Z])'
                                                                            )
                                                                                ? '#279627'
                                                                                : '#f03030'
                                                                        }`,
                                                                    }}
                                                                >
                                                                    at least one
                                                                    uppercase
                                                                    letter
                                                                </span>
                                                                ,{' '}
                                                                <span
                                                                    style={{
                                                                        color: `${
                                                                            password.match(
                                                                                '(?=.*?[a-z])'
                                                                            )
                                                                                ? '#279627'
                                                                                : '#f03030'
                                                                        }`,
                                                                    }}
                                                                >
                                                                    one
                                                                    lowercase
                                                                    letter
                                                                </span>
                                                                ,{' '}
                                                                <span
                                                                    style={{
                                                                        color: `${
                                                                            password.match(
                                                                                '(?=.*?[0-9])'
                                                                            )
                                                                                ? '#279627'
                                                                                : '#f03030'
                                                                        }`,
                                                                    }}
                                                                >
                                                                    one number
                                                                    and{' '}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        color: `${
                                                                            password.match(
                                                                                '(?=.*?[#?!@$%^&*-])'
                                                                            )
                                                                                ? '#279627'
                                                                                : '#f03030'
                                                                        }`,
                                                                    }}
                                                                >
                                                                    one special
                                                                    character
                                                                    required
                                                                </span>
                                                                ).
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        <Input
                                                            required
                                                            type="password"
                                                            visibility="true"
                                                            passwordShowData='<i  class="fa fa-eye-slash eye-icon" aria-hidden="true"/>'
                                                            passwordHideData='<i  class="fa fa-eye eye-icon" aria-hidden="true"/>'
                                                            name="confirmPassword"
                                                            autofill={'false'}
                                                            label="Confirm Password"
                                                            maxLength={30}
                                                            minLength={3}
                                                            className="form-control"
                                                            outerClassName="signup-input-box"
                                                            placeholder="Confirm Password"
                                                            id="confirm-password"
                                                            errorMessage="Password didn't match."
                                                            onChange={this.handleChange.bind(
                                                                this
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-group form-btn-group">
                                                            {!isPageLoading ? (
                                                                <Button
                                                                    type="submit"
                                                                    id="send-invite"
                                                                    formNoValidate={
                                                                        true
                                                                    }
                                                                    className="btn box-shadow"
                                                                >
                                                                    Create
                                                                    Account
                                                                </Button>
                                                            ) : (
                                                                <Button className="btn btn-icon icon-left">
                                                                    <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                                    <span className="btn-text">
                                                                        Please
                                                                        wait ..
                                                                    </span>
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12">
                                                        <div className="form-link-group">
                                                            <NavLink
                                                                to="/login"
                                                                className="auth-page-link"
                                                                id="auth-redirection"
                                                            >
                                                                Already have an
                                                                account ?
                                                            </NavLink>
                                                        </div>
                                                        {!isPageLoading &&
                                                            !memberRegistrationSuccess && (
                                                                <div className="center-error form-error" id="error-msg">
                                                                    <p className="error-msg">
                                                                        {
                                                                            memberRegistrationMessage
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )}
                                                        {!isPageLoading &&
                                                            memberRegistrationSuccess && (
                                                                <div className="center-error form-error">
                                                                    <p className="success-msg">
                                                                        {
                                                                            memberRegistrationMessage
                                                                        }
                                                                    </p>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </form>
                </div>
            </div>
            <div className="xenonstack-jobs-copyright">
                <p>Xenonstack |</p>
                <i className="fa fa-copyright"></i>
                <p>2021 All Rights Reserved</p>
            </div>
        </div>
    </div>
</div>

            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        memberRegistrationSuccess,
        isPageLoading,
        memberRegistrationMessage,
        memberSignupData,
    } = state.authReducer;
    return {
        memberRegistrationSuccess,
        isPageLoading,
        memberRegistrationMessage,
        memberSignupData,
    };
}

export default withRouter(connect(mapStateToProps)(CreateAccount));
