import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Checkbox } from 'xd-react-checkbox';
import '../dist/modal.css';
import { Button } from 'xd-react-button';
import { Input } from 'xd-react-input';
import {
    handleEditAddressDialogChange,
    updateAddressFields,
    updateProfileDetails,
} from '../../../../actions/employee/userActions';
import StateData from '../../../../static/data/state.json';

const yourhandle = require('countrycitystatejson');
class EditAddressPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            districts: [],
            addressIsSame: false,
            selectedState:
                this.props.useraddressInfo[this.props.addressIndex].state,
        };
    }


    setStateDistricts(districtsList) {
        this.setState({
            districts: districtsList,
        });
    }

    setSelectedState(state) {
        this.setState({
            selectedState: state,
        });
    }

    handleAddressCheck(e) {
        this.setState({
            addressIsSame: !this.state.addressIsSame,
        });
    }

    handleDialogOnClose() {
        document.querySelector('#body-root').style = 'overflow:auto;';
        this.props.dispatch(handleEditAddressDialogChange(false));
    }

    handleOnChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        const index = this.props.addressIndex;
        if (name == 'state') {
            this.setState({ selectedState: value });
        }
        if (name == 'state') {
            StateData.country.states.map((state, index) => {
                if (state.label == value) {
                    this.setState({
                        districts: state.districts,
                    });
                }
            });
        }

        let addressData = this.props.useraddressInfo;
        if (name == 'pincode') {
            if (e.target.value) {
                addressData[index][name] = parseFloat(e.target.value);
            } else {
                addressData[index][name] = e.target.value;
            }
        } else {
            addressData[index][name] = value;
        }

        this.props.dispatch(updateAddressFields(addressData));
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.addressIsSame) {
            this.props.useraddressInfo[1].address =
                this.props.useraddressInfo[0].address;
            this.props.useraddressInfo[1].country =
                this.props.useraddressInfo[0].country;
            this.props.useraddressInfo[1].city =
                this.props.useraddressInfo[0].city;
            this.props.useraddressInfo[1].state =
                this.props.useraddressInfo[0].state;
            this.props.useraddressInfo[1].district =
                this.props.useraddressInfo[0].district;
            this.props.useraddressInfo[1].pincode =
                this.props.useraddressInfo[0].pincode;
        }
        const details = {
            account_info: {
                name: this.props.profileInfo.profile.account.name,
                contact_no: this.props.profileInfo.profile.account.contact_no,
            },
            personal_info: this.props.profileInfo.profile.personal,
            user_address: this.props.useraddressInfo,
            education: this.props.profileInfo.profile.education,
            emergency_contact: this.props.profileInfo.profile.emergency,
            others: this.props.profileInfo.profile.others,
        };
        if (e.target.checkValidity()) {
            this.props.dispatch(updateProfileDetails(details));
            document.querySelector('#body-root').style = 'overflow:auto;';
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
    }

    render() {
        const { editAddressPopupState } = this.props;
        const useraddressInfo =
            this.props.useraddressInfo[this.props.addressIndex];
        return (
            <>
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': editAddressPopupState,
                        fadeOut: !editAddressPopupState,
                    })}
                    id="add_member_dialog"
                >
                    <div
                        className={classNames(
                            'edit-education-dialog  xd-modal-dialog  animated',
                            {
                                fadeInDown: editAddressPopupState,
                                fadeOutUp: !editAddressPopupState,
                            }
                        )}
                    >
                        <div className="xd-modal-content">
                            <div className="xd-modal-header">
                                <h2 className="xd-modal-title">
                                    {useraddressInfo.address_type}
                                </h2>
                                <button
                                    type="button"
                                    className="xd-modal-close"
                                    onClick={this.handleDialogOnClose.bind(
                                        this
                                    )}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form
                                onSubmit={this.handleSubmit.bind(this)}
                                noValidate={true}
                                id="edit-education-dialog"
                            >
                                <div className="xd-modal-body">
                                    <div className="add-new-dag-form">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Address{' '}
                                                        <span className="req">
                                                            *
                                                        </span>{' '}
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        name="address"
                                                        id='addressBar'
                                                        className="form-control"
                                                        errorMessage="Enter your Address"
                                                        placeholder="Address"
                                                        label="Address"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={
                                                            useraddressInfo.address
                                                        }
                                                        minLength={3}
                                                        maxLength={200}
                                                        pattern={
                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                        }
                                                    >
                                                        {' '}
                                                    </textarea>
                                                    <span className="with-error">
                                                        Please enter your
                                                        address(min 3 char)
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        Country{' '}
                                                        <span className="req">
                                                            *
                                                        </span>{' '}
                                                    </label>
                                                    <div className="select-wrapper">
                                                        <select
                                                            type="text"
                                                            name="country"
                                                            className="form-control"
                                                            errorMessage="Enter Country"
                                                            placeholder="Country"
                                                            label="Country"
                                                            minLength={3}
                                                            maxLength={50}
                                                            pattern={
                                                                '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                            }
                                                            onChange={this.handleOnChange.bind(
                                                                this
                                                            )}
                                                            required
                                                            value={
                                                                useraddressInfo.country
                                                            }
                                                            selected={
                                                                useraddressInfo.country
                                                            }
                                                        >
                                                            <option
                                                                disabled={true}
                                                                value=""
                                                                defaultValue
                                                            >
                                                                Select Country
                                                            </option>
                                                            <option>
                                                                India
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        Please select Country.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        State{' '}
                                                        <span className="req">
                                                            *
                                                        </span>{' '}
                                                    </label>
                                                    <div className="select-wrapper">
                                                        <select
                                                            name="state"
                                                            className="form-control"
                                                            errorMessage="Enter state."
                                                            placeholder="State"
                                                            label="State"
                                                            onChange={this.handleOnChange.bind(
                                                                this
                                                            )}
                                                            required
                                                            value={
                                                                useraddressInfo.state
                                                            }
                                                            id="current-state"
                                                        >
                                                            <option
                                                                disabled={true}
                                                                value=""
                                                                defaultValue
                                                            >
                                                                Select State
                                                            </option>
                                                            {StateData.country.states.map(
                                                                (
                                                                    state,
                                                                    index
                                                                ) => (
                                                                    <option
                                                                        value={
                                                                            state.value
                                                                        }
                                                                        selected={
                                                                            useraddressInfo.state
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            state.label
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        Please select State.
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        City
                                                        <span className="req">
                                                            *
                                                        </span>{' '}
                                                    </label>
                                                    <div className="select-wrapper">
                                                        <select
                                                            name="city"
                                                            className="form-control"
                                                            errorMessage="Enter City"
                                                            placeholder="City"
                                                            label="City"
                                                            onChange={this.handleOnChange.bind(
                                                                this
                                                            )}
                                                            required
                                                            value={
                                                                useraddressInfo.city
                                                            }
                                                        >
                                                            <option
                                                                disabled={true}
                                                                value=""
                                                                defaultValue
                                                            >
                                                                Select City
                                                            </option>

                                                            {this.state
                                                                .selectedState ==
                                                                '' && (
                                                                <>
                                                                    {this.props
                                                                        .profileInfo
                                                                        .profile && (
                                                                        <>
                                                                            {this
                                                                                .props
                                                                                .profileInfo
                                                                                .profile
                                                                                .address && (
                                                                                <>
                                                                                    {this
                                                                                        .props
                                                                                        .profileInfo
                                                                                        .profile
                                                                                        .address[
                                                                                        this
                                                                                            .props
                                                                                            .addressIndex
                                                                                    ] && (
                                                                                        <>
                                                                                            {this
                                                                                                .props
                                                                                                .profileInfo
                                                                                                .profile
                                                                                                .address[
                                                                                                this
                                                                                                    .props
                                                                                                    .addressIndex
                                                                                            ]
                                                                                                .state &&
                                                                                                this.setSelectedState(
                                                                                                    this
                                                                                                        .props
                                                                                                        .profileInfo
                                                                                                        .profile
                                                                                                        .address[
                                                                                                        this
                                                                                                            .props
                                                                                                            .addressIndex
                                                                                                    ]
                                                                                                        .state
                                                                                                )}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {yourhandle
                                                                .getCities(
                                                                    'IN',
                                                                    this.state
                                                                        .selectedState
                                                                )
                                                                .map(
                                                                    (
                                                                        city,
                                                                        index
                                                                    ) => (
                                                                        <option
                                                                            value={
                                                                                city
                                                                            }
                                                                            selected={
                                                                                useraddressInfo.city
                                                                            }
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {
                                                                                city
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </select>
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        Please select city
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="form-group">
                                                    <label>
                                                        District{' '}
                                                        <span className="req">
                                                            *
                                                        </span>{' '}
                                                    </label>
                                                    <div className="select-wrapper">
                                                        <select
                                                            name="district"
                                                            className="form-control"
                                                            errorMessage="Enter district."
                                                            placeholder="District"
                                                            label="District"
                                                            onChange={this.handleOnChange.bind(
                                                                this
                                                            )}
                                                            required
                                                            value={
                                                                useraddressInfo.district
                                                            }
                                                        >
                                                            <option
                                                                disabled={true}
                                                                value=""
                                                                defaultValue
                                                            >
                                                                Select district
                                                            </option>
                                                            {this.state
                                                                .districts
                                                                .length ==
                                                                0 && (
                                                                <>
                                                                    {this.props
                                                                        .profileInfo
                                                                        .profile && (
                                                                        <>
                                                                            {this
                                                                                .props
                                                                                .profileInfo
                                                                                .profile
                                                                                .address[
                                                                                this
                                                                                    .props
                                                                                    .addressIndex
                                                                            ] && (
                                                                                <>
                                                                                    {StateData.country.states.map(
                                                                                        (
                                                                                            state,
                                                                                            index
                                                                                        ) => {
                                                                                            if (
                                                                                                state.label ==
                                                                                                this
                                                                                                    .props
                                                                                                    .profileInfo
                                                                                                    .profile
                                                                                                    .address[
                                                                                                    this
                                                                                                        .props
                                                                                                        .addressIndex
                                                                                                ]
                                                                                                    .state
                                                                                            ) {
                                                                                                this.setStateDistricts(
                                                                                                    state.districts
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {this.state
                                                                .districts
                                                                .length !==
                                                                0 && (
                                                                <>
                                                                    {this.state.districts.map(
                                                                        (
                                                                            district,
                                                                            index
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    district
                                                                                }
                                                                                selected={
                                                                                    useraddressInfo.district
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {
                                                                                    district
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        Please select district
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div>
                                                    <Input
                                                        type="text"
                                                        name="pincode"
                                                        className="form-control"
                                                        errorMessage="Enter pincode."
                                                        placeholder="Pincode"
                                                        label="Pincode"
                                                        onChange={this.handleOnChange.bind(
                                                            this
                                                        )}
                                                        required
                                                        value={
                                                            useraddressInfo.pincode
                                                        }
                                                        minLength={3}
                                                        maxLength={50}
                                                        pattern={'[0-9]{6}'}
                                                    />
                                                </div>
                                            </div>
                                            {this.props.profileInfo.profile && (
                                                <>
                                                    {this.props.profileInfo
                                                        .profile.address
                                                        .length == 0 && (
                                                        <div className="col-12 col-sm-12">
                                                            <div className="form-group">
                                                                <Checkbox
                                                                    name="Checked"
                                                                    checked={
                                                                        this
                                                                            .state
                                                                            .addressIsSame
                                                                    }
                                                                    onChange={this.handleAddressCheck.bind(
                                                                        this
                                                                    )}
                                                                    labelValue="Permanent address same as current address."
                                                                    id="checked-input"
                                                                    themeColor="#f99316"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {this.props.updateErrorMsg && (
                                            <p style={{ color: 'red' }}>
                                                {this.props.updateErrorMsg}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="xd-modal-footer">
                                    <div className="xd-btn-group form-group">
                                        <Button
                                            className="btn btn-outline btn-compact box-shadow"
                                            onClick={this.handleDialogOnClose.bind(
                                                this
                                            )}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="btn btn-compact btn-with-loader box-shadow"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {editAddressPopupState && (
                    <div
                        className={classNames('xd-modal-overlay animated ', {
                            fadeIn: editAddressPopupState,
                            fadeOut: !editAddressPopupState,
                        })}
                    ></div>
                )}
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        editAddressPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        useraddressInfo,
        addressIndex,
    } = state.userReducer;
    return {
        editAddressPopupState,
        profileInfo,
        updateSuccess,
        updateErrorMsg,
        updateSuccessMsg,
        useraddressInfo,
        addressIndex,
    };
}
export default withRouter(connect(mapStateToProps)(EditAddressPopup));
