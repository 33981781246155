import classNames from 'classnames';
import React from 'react';

const Popup = (props) => {
    return (
        <div className="popup-box ">
            <div
                className={classNames(
                    'box moduleBox',
                    props.classname
                        ? 'remove-intern-modal-alignment'
                        : ''
                )}
            >
                {props.isCloseButtonVisible && (
                    <button
                        type="button"
                        class="xd-modal-close"
                        onClick={props.handleClose}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                )}
                {props.content}
            </div>
        </div>
    );
};

Popup.defaultProps = { isCloseButtonVisible: true };

export default Popup;