import React, {Component} from 'react';
import {withRouter,Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import SignupHeader from '../../../components/auth/Header'
import CreateAccount from "./CreateAccount";
import PersonalInfo from './PersonalInfo';
import EducationInfo from './EducationInfo';
import EmergencyInfo from './EmergencyInfo';
import "../dist/style.css"

class SignupIndex extends Component {
    render() {
        return (   
            <div className="dashboard-wrappper"> 
            <div className="dashboard-full-body-content">   
                    <SignupHeader />
                    <Switch>
                        <Route exact path="/member-registration" component={CreateAccount}/>
                        <Route exact path="/member-registration/personal-information" component={PersonalInfo}/>
                        <Route exact path="/member-registration/education-information" component={EducationInfo}/>
                        <Route exact path="/member-registration/other-information" component={EmergencyInfo}/>
                    </Switch>
                </div>
            </div>             
        )
    }
}

function mapStateToProps(state) {
    const {toggleStatus} = state.appReducer;
    return {toggleStatus}
}

export default withRouter(connect(mapStateToProps)(SignupIndex))