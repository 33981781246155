import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import {
    resetPasswordInvite,
    updateForgotPasswordForm,
} from '../../actions/auth/authActions';
import './dist/style.css';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    handleChange(e) {
        if (e.target.checkValidity()) {
            e.target.parentElement.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.parentElement.classList.add('has-error');
        }

        const newState = Object.assign(this.props.forgotPasswordData, {
            [e.target.name]: e.target.value,
        });
        this.props.dispatch(updateForgotPasswordForm(newState));
    }

    handleOnSubmit(e) {
        //if invitation form is valid then submit the data, else show the validation message on the invalid field.
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.dispatch(
                resetPasswordInvite(this.props.forgotPasswordData)
            );
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.parentElement.classList.add('has-error');
                }
            }
        }
        this.formRef.reset();
    }
    componentDidMount() {
        if (this.props.isAuthenticated) {
            this.props.history.push('/admin/dashboard');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isAuthenticated) {
            if (nextProps.redirectLink != '') {
                window.location.replace(nextProps.redirectLink);
            } else {
                this.props.history.push('/admin/dashboard');
            }
        }
    }

    render() {
        const {
            loginErrorMessage,
            loginSuccessMessage,
            isPageLoading,
        } = this.props;
        return (
            <main className="main-content-wrapper full-height">
                <div className="wrapper-container signing-container">
                    <div className="container user-jounrney-form-container">
                        <div className="user-journey-form">
                            <div className="xenonstack-jobs-logo">
                                <a href="https://xenonstack-jobs-dev.neuralcompany.team/">
                                    {' '}
                                    <img
                                        src={require('../../static/images/onboarding-logo.svg')}
                                    />
                                </a>
                            </div>
                            <div className="dashbaord-user-form">
                                <div className="section_header">
                                    <h2 className="big-heading">Please Enter Your Email For Verification</h2>
                                </div>
                                <div className="signup-page" id="signup-page">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-10 mar-xs-30">
                                            <div
                                                className="login-div existing-user"
                                                id="existing-user"
                                            >
                                                  <form
                                className="admin-login-form"
                                ref={(ref) => (this.formRef = ref)}
                                noValidate={true}
                                id="admin-login-form"
                                onSubmit={this.handleOnSubmit.bind(this)}
                            >
                                <Input
                                    required
                                    type="email"
                                    onChange={this.handleChange.bind(this)}
                                    pattern={
                                        '([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}'
                                    }
                                    id="email"
                                    name="email"
                                    autoComplete={'off'}
                                    label="Email Address"
                                    placeholder="Email Address"
                                    className="form-control"
                                    outerClassName="login-input-box"
                                    errorMessage="Please enter a valid email address."
                                />
                                <div className="form-group form-btn-group">
                                    {!isPageLoading ? (
                                        <Button
                                            type="submit"
                                            id="send-invite"
                                            formNoValidate={true}
                                            className="btn box-shadow"
                                        >
                                            Submit
                                        </Button>
                                    ) : (
                                        <Button className="btn btn-icon icon-left">
                                            <i className="fa fa-spin fa-spinner btn-icon"></i>
                                            <span className="btn-text">
                                                Please wait ..
                                            </span>
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    {this.props.forgotInviteSuccessMsg ? (
                                        <p style={{ color: 'green' }}>
                                            {this.props.forgotInviteSuccessMsg}.
                                            Please check your email.
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    {this.props.forgotInviteErrorMsg ? (
                                        <p style={{ color: 'red' }}>
                                            {this.props.forgotInviteErrorMsg}
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="form-link-group">
                                    <NavLink
                                        to="/login"
                                        className="auth-page-link"
                                    >
                                        Back to Login
                                    </NavLink>
                                </div>

                                {!isPageLoading && loginErrorMessage && (
                                    <div className="center-error form-error">
                                        <p className="error-msg">
                                            {loginErrorMessage}
                                        </p>
                                    </div>
                                )}
                                {!isPageLoading && loginSuccessMessage && (
                                    <div className="center-error form-error">
                                        <p className="success-msg">
                                            {loginSuccessMessage}
                                        </p>
                                    </div>
                                )}
                            </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="xenonstack-jobs-copyright">
                                <p>Xenonstack |</p>
                                <i className="fa fa-copyright"></i>
                                <p>2021 All Rights Reserved</p>
                            </div>
                        </div>
                        <div class="sweet-loading"></div>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        isAuthenticated,
        isPageLoading,
        forgotPasswordData,
        forgotInviteErrorMsg,
        forgotInviteSuccessMsg,
    } = state.authReducer;
    return {
        isAuthenticated,
        isPageLoading,
        forgotPasswordData,
        forgotInviteErrorMsg,
        forgotInviteSuccessMsg,
    };
}

export default withRouter(connect(mapStateToProps)(ForgotPassword));
