export const GET_MEMBER_LIST_REQUEST = 'GET_MEMBER_LIST_REQUEST';
export const GET_MEMBER_LIST_SUCCESS = 'GET_MEMBER_LIST_SUCCESS';
export const GET_MEMBER_LIST_FAILURE = 'GET_MEMBER_LIST_FAILURE';

export const CHANGE_DELETED_MEMBER = 'CHANGE_DELETED_MEMBER';
export const DELETE_MEMBER_REQUEST = 'DELETE_MEMBER_REQUEST';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE';

export const REMOVE_MEMBER_INVITE = 'REMOVE_MEMBER_INVITE';

export const ADD_MEMBER_INVITE = 'ADD_MEMBER_INVITE';
export const INVITE_MEMBER_REQUEST = 'INVITE_MEMBER_REQUEST';
export const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS';
export const INVITE_MEMBER_FAILURE = 'INVITE_MEMBER_FAILURE';


export const DEFAULT_MEMBER_STATE = 'DEFAULT_MEMBER_STATE';
export const CHANGE_CSV_FILE = 'CHANGE_CSV_FILE';

export const HANDLE_UPDATE_MEMBER_LIST = 'HANDLE_UPDATE_MEMBER_LIST';


export const CHANGE_PER_PAGE = 'CHANGE_PER_PAGE';

export const CLEAR_MEMBER_DATA = 'CLEAR_MEMBER_DATA';

export const CLEAR_ALL = 'CLEAR_ALL';
