import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { BlockLoader } from 'xd-react-loader';
import {
    getProfileDocumentLinks,
    handleEditEducationDialogChange,
    addEducation,
    removeEducationDetail,
    setEducationInfo,
} from '../../../../actions/employee/userActions';
import ProfileSubHeader from '../ProfileSubHeader';
import UserProfileSidebar from '../UserProfileSidebar';
import ProfileNavigation from '../ProfileNavigation';
import EditEducationPopup from '../edit-profile/EditEducationPopup';

class EducationInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editEducationInfo: {},
            eduIndex: '',
        };
    }

    handleGetDocumentLink(link) {
        this.props.dispatch(getProfileDocumentLinks(link));
    }
    handleEditEducationDialog(educationInfo, eduIndex) {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleEditEducationDialogChange(true));
        if (educationInfo !== '') {
            let education = _.cloneDeep(
                this.props.profileInfo.profile.education
            );
            this.props.dispatch(setEducationInfo(education, eduIndex));
        } else {
            this.setState({
                editEducationInfo: {},
                eduIndex: eduIndex,
            });
        }
    }
    handleAddEducation(educationInfo, eduIndex) {
        this.props.dispatch(
            addEducation(
                {
                    name: '',
                    institution: '',
                    location: '',
                    year: '',
                    percentage: 0,
                    marksheet_link: [],
                    degree_link: '',
                },
                eduIndex
            )
        );
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleEditEducationDialogChange(true));
    }
    handleRemoveEducation(index) {
        const list = this.props.profileInfo.profile.education;
        list.splice(index, 1);
        this.props.dispatch(removeEducationDetail(list));
        this.forceUpdate();
    }

    render() {
        const { profileInfo } = this.props;
        return (
            <main className="main-content-wrapper employee-profile-wrapper">
                <ProfileSubHeader />
                <div className="dashboard-body-wrapper gry-fill-background">
                    <div className="container">
                        <section className="employee-profile-content-wrapper">
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <div className="employee-profile-side-template">
                                        <UserProfileSidebar />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-9">
                                    <div className="employee-profile-content">
                                        <ProfileNavigation />
                                        {this.props.isUserProfileLoading ? (
                                            <div className="xs-loader-wrapper">
                                                <BlockLoader
                                                    loadingText="loading"
                                                    loading={true}
                                                    loaderStyle={{
                                                        size: '20px',
                                                        color: '0562e8',
                                                        textSize: '18px',
                                                        textColor: '#485e7d',
                                                        speed: '1s',
                                                    }}
                                                />
                                            </div>
                                        ) : this.props.profileErrorMsg ? (
                                            <h3>
                                                {this.props.profileErrorMsg}
                                            </h3>
                                        ) : this.props.getProfileSuccess ? (
                                            <div className="user-profile-container">
                                                <div className="profile-section_card education-section_card">
                                                    <h2 className="profile-section_card_title">
                                                        Education Information
                                                    </h2>
                                                    <div className="profile-section_grid">
                                                        {profileInfo.profile.education.map(
                                                            (
                                                                education,
                                                                eduIndex
                                                            ) => (
                                                                <React.Fragment>
                                                                    <div
                                                                        className="profile-section_card_item"
                                                                        key={
                                                                            eduIndex
                                                                        }
                                                                    >
                                                                        <article className="profile-section_card_details">
                                                                            <h3>
                                                                                <strong>
                                                                                    {
                                                                                        education.name
                                                                                    }
                                                                                </strong>
                                                                                <span>
                                                                                    (Year
                                                                                    -{' '}
                                                                                    {
                                                                                        education.year
                                                                                    }

                                                                                    )
                                                                                </span>
                                                                            </h3>
                                                                            <div className="profile-action">
                                                                                <strong
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.handleEditEducationDialog(
                                                                                            education,
                                                                                            eduIndex
                                                                                        )
                                                                                    }
                                                                                    className="link-button link-button_icon"
                                                                                >
                                                                                    <i className="icon-pencil"></i>{' '}
                                                                                    Edit
                                                                                </strong>
                                                                            </div>
                                                                            <div className="profile-card-meta_data">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                School/College/University
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {education.institution !==
                                                                                                ''
                                                                                                    ? education.institution
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Location
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {education.location !==
                                                                                                ''
                                                                                                    ? education.location
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Percentage/CGPA
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {education.percentage !==
                                                                                                ''
                                                                                                    ? education.percentage
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Passing
                                                                                                Year
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {education.year !==
                                                                                                ''
                                                                                                    ? education.year
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data education-document-list">
                                                                                            <p className="fields-name">
                                                                                                Marksheet
                                                                                            </p>
                                                                                            {education
                                                                                                .marksheet_link
                                                                                                .length !==
                                                                                                0 &&
                                                                                            education.marksheet_link ? (
                                                                                                education.marksheet_link.map(
                                                                                                    (
                                                                                                        marksheet,
                                                                                                        marIndex
                                                                                                    ) => (
                                                                                                        <p
                                                                                                            className="fields-value fields-value_link"
                                                                                                            key={
                                                                                                                marIndex
                                                                                                            }
                                                                                                        >
                                                                                                            Marksheet
                                                                                                            -{' '}
                                                                                                            {marIndex +
                                                                                                                1}
                                                                                                            <a
                                                                                                                href="javascript:void(0)"
                                                                                                                onClick={() =>
                                                                                                                    this.handleGetDocumentLink(
                                                                                                                        marksheet
                                                                                                                    )
                                                                                                                }
                                                                                                                className="doc-download"
                                                                                                            >
                                                                                                                <i className="icon-cloud-download"></i>
                                                                                                                Download
                                                                                                            </a>
                                                                                                        </p>
                                                                                                    )
                                                                                                )
                                                                                            ) : (
                                                                                                <span className="no-data-text-small">
                                                                                                    N/A
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Degree
                                                                                            </p>
                                                                                            <p className="fields-value fields-value_link">
                                                                                                {education.degree_link !==
                                                                                                '' ? (
                                                                                                    <>
                                                                                                        Degree
                                                                                                        <a
                                                                                                            href="javascript:void(0)"
                                                                                                            onClick={() =>
                                                                                                                this.handleGetDocumentLink(
                                                                                                                    education.degree_link
                                                                                                                )
                                                                                                            }
                                                                                                            className="doc-download"
                                                                                                        >
                                                                                                            {' '}
                                                                                                            <i className="icon-cloud-download"></i>
                                                                                                            Download
                                                                                                        </a>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <span className="no-data-text-small">
                                                                                                        N/A
                                                                                                    </span>
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </article>
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="profile-section_card education-section_card">
                                                    <div className="profile-section_card_item">
                                                        <strong
                                                            className="link-button"
                                                            onClick={(e) =>
                                                                this.handleAddEducation(
                                                                    [],
                                                                    this.props
                                                                        .profileInfo
                                                                        .profile
                                                                        .education
                                                                        .length
                                                                )
                                                            }
                                                        >
                                                            + Add Education
                                                        </strong>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <EditEducationPopup />
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        getProfileLinkSuccess,
        profileDocumentLink,
        updateSuccess,
        usereducationInfo,
    } = state.userReducer;
    return {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        getProfileLinkSuccess,
        profileDocumentLink,
        updateSuccess,
        usereducationInfo,
    };
}

export default withRouter(connect(mapStateToProps)(EducationInfo));
