import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment';
import Popup from './Popup';
import './style.css';
import classNames from 'classnames';

class ModuleItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isCurrDateGreaterUnlockDate: false,
        };
    }
    togglePopup = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    comparedDate(dateUnlock) {
        //Today's Date
        const currentDate = new Date();
        let today_date = `${currentDate.getDate()}`;
        let today_month = `${currentDate.getMonth() + 1}`;
        let today_year = `${currentDate.getFullYear()}`;

        //Unlock date
        let dateUnlockArray = dateUnlock.split(' ');
        let unlockDateYear = dateUnlockArray[3];
        let unlockDateDate = dateUnlockArray[2].split(',')[0];
        let unlockDateMonthString = dateUnlockArray[1];
        var months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        let unlockDateMonth = months.indexOf(unlockDateMonthString) + 1;

        if (today_year >= unlockDateYear) {
            if (today_month >= unlockDateMonth) {
                if (today_date >= unlockDateDate) {
                    this.state.isCurrDateGreaterUnlockDate = true;
                    return;
                }
            }
        }
        this.state.isCurrDateGreaterUnlockDate = false;
    }

    render() {
        const {
            name,
            description,
            level,
            duration,
            score,
            start_date,
            status,
            id,
            unlock_date,
            remaining_time,
        } = this.props.ModulesData;
        let isPreviousModuleCompleted = this.props.isPreviousModuleCompleted;
        let isFirstModule = this.props.isFirstModule;

        let dateUnlock = moment(new Date(unlock_date * 1000)).format(
            'dddd, LL'
        );

        this.comparedDate(dateUnlock);

        return (
            <article
                className="module-list_item_wrapper"
                onClick={this.togglePopup}
            >
                <div
                    className={classNames('xd-modal animated ', {
                        'show fadeIn': this.state.isOpen && !isFirstModule,
                        fadeOut: !this.state.isOpen && isFirstModule,
                    })}
                >
                    <div
                        className={classNames('xd-modal-dialog box animated', {
                            fadeInDown: this.state.isOpen && !isFirstModule,
                            fadeOutUp: !this.state.isOpen && isFirstModule,
                        })}
                    >
                        {this.state.isOpen && !isFirstModule && (
                            <div className="module-popup">
                                <Popup
                                    content={
                                        status === 'Done' ?
                                        <p className='module-complete-message'>
                                        This module is completed.
                                    </p>
                                    :
                                         status === 'Locked' ||
                                        !this.state
                                            .isCurrDateGreaterUnlockDate ? (
                                            <p className="module-lock-message">
                                                This module will unlock on{' '}
                                                {dateUnlock}.
                                            </p>
                                        ) : (
                                            <p>
                                                Please complete your previous
                                                module.
                                            </p>
                                        )
                                    }
                                    handleClose={this.togglePopup}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={
                        status === 'InComplete' && status === 'Done' ?
                        'module-list_item_content'
                        :status === 'Locked' 
                            ? 'module-list_item_content_disabled'
                            : 'module-list_item_content'
                    }
                >
                    <div className="module-content">
                        {status != 'Locked' ? (
                            <NavLink
                                to={`/employee/modules/${id}`}
                                className="module-title"
                            >
                                {' '}
                                <h3> {name}</h3>{' '}
                            </NavLink>
                        ) : (
                            <div>
                                {' '}
                                <h3> {name}</h3>{' '}
                            </div>
                        )}

                        <p>{description}</p>
                        <ul className="meta-data_list">
                            <li>
                                <div className="meta-data_item">
                                    Duration : {duration} Day<small>(s)</small>
                                </div>
                            </li>
                            <li>
                                <div className="meta-data_item">
                                    Level : {level}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="module-progres_content">
                        <div className="progres-circle">
                            <CircularProgressbar
                                value={score}
                                className="module-progress"
                                strokeWidth={5}
                                text={score + '%'}
                                styles={buildStyles({
                                    pathColor:
                                        score == 100
                                            ? '#006400'
                                            : score < 50
                                            ? '#FF0000'
                                            : '#FFFF00',
                                    trailColor: '#d6d6d6',
                                    backgroundColor: '#ffffff',
                                    textColor: '#bf1f67',
                                    textSize: '20px',
                                })}
                            />
                            <p>Progress</p>
                        </div>
                    </div>
                </div>
                <div className="module-list_item_footer">
                    <ul className="module_list_footer-content">
                        <li>
                            {status !== 'Locked' && (
                                <div className="module-start_date">
                                    <img
                                        src={require('../../../static/images/onboarding-start.svg')}
                                    />
                                    <strong>Start From :</strong>
                                    <span>
                                        {' '}
                                        {moment(
                                            new Date(start_date * 1000)
                                        ).format('dddd, LL')}
                                    </span>
                                </div>
                            )}
                            {status == 'Locked' && (
                                <div className="module-start_date">
                                    <img
                                        src={require('../../../static/images/lock-icon.svg')}
                                    />
                                    <strong>Unlock Date :</strong>
                                    <span>
                                        {' '}
                                        {moment(
                                            new Date(unlock_date * 1000)
                                        ).format('dddd, LL')}
                                    </span>
                                </div>
                            )}
                        </li>
                        <li>
                            {status !== 'Locked' && remaining_time !== 0 && (
                                <div className="module-start_date">
                                    <img
                                        src={require('../../../static/images/onboarding-timer.svg')}
                                    />
                                    <strong>Remaining Time :</strong>
                                    <span>
                                        {' '}
                                        {remaining_time} Day<small>(s)</small>
                                    </span>
                                </div>
                            )}
                        </li>
                        <li>
                            <div className="module_action">
                                {status === 'Done' && (
                                    <button
                                        type="button"
                                        disabled={true}
                                        className="btn btn-compact box-shadow module-completed"
                                    >
                                        Completed
                                    </button>
                                )}
                                {status === 'Locked' && (
                                    <button
                                        type="button"
                                        disabled={true}
                                        className="btn btn-compact box-shadow in-progress locked-buttom"
                                    >
                                        <i
                                            class="fa fa-lock"
                                            aria-hidden="true"
                                        ></i>{' '}
                                        Locked{' '}
                                    </button>
                                )}
                                {status === 'InComplete' && (
                                    <button
                                        type="button"
                                        disabled={true}
                                        className="btn btn-compact box-shadow box-color-fade-out"
                                    >
                                        In Progress
                                    </button>
                                )}
                            </div>
                        </li>
                    </ul>
                </div>
            </article>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

export default withRouter(connect(mapStateToProps)(ModuleItem));
