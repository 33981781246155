import {logout} from "../actions/auth/authActions";

let Symbol = require('es6-symbol');


function postWithoutTOKEN(endpoint, body) {
    let config = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: body
    };

    return fetch(endpoint, config)
        .then(response =>
            response.json().then(data => (
                {data, status: response.status}))
        ).catch(err => ({data:{error: true, message: "Internal Server Error"}}));
}


export const POST_WITHOUT_TOKEN = Symbol('Post Without Token');

export default store => next => action => {
    const postWithoutToken = action[POST_WITHOUT_TOKEN];
    // So the middleware doesn't get applied to every single action
    if (typeof postWithoutToken === 'undefined') {
        return next(action);
    }
    let {endpoint, types, body} = postWithoutToken;
    const [requestType, successType, errorType] = types;

    // Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes

    return (next({type: requestType}),postWithoutTOKEN(endpoint, body).then(
            response => {
              if((response.status === "logout") || (response.status === 401)) {
                  store.dispatch(logout())
              }
              else if (response.status === 200) {
                    return next({
                        response,
                        type: successType
                    })
                } else {
                    if (response.status === 401) {
                        localStorage.clear();
                        window.location.href = '/onboarding/login';
                    }
                    return next({
                        response,
                        type: errorType
                    })
                }
            }
        ))
}
