export const ADD_INVITE_EMPLOYEE = 'ADD_INVITE_EMPLOYEE';
export const REMOVE_INVITE_EMPLOYEE = 'REMOVE_INVITE_EMPLOYEE';
export const UPDTAE_INVITE_EMPLOYEE_FORM = 'UPDTAE_INVITE_EMPLOYEE_FORM';

export const INVITE_EMPLOYEE_REQUEST = 'INVITE_EMPLOYEE_REQUEST';
export const INVITE_EMPLOYEE_SUCCESS = 'INVITE_EMPLOYEE_SUCCESS';
export const INVITE_EMPLOYEE_FAILURE = 'INVITE_EMPLOYEE_FAILURE';

export const CLEAR_EMPLOYEE_DATA = 'CLEAR_EMPLOYEE_DATA';

export const INTERN_CHECKED = "INTERN_CHECKED"
export const INTERN_NOT_CHECKED = "INTERN_NOT_CHECKED"

export const DELETE_ASSIGNED_MODULE_REQUEST = 'DELETE_ASSIGNED_MODULE_REQUEST';
export const DELETE_ASSIGNED_MODULE_SUCCESS = 'DELETE_ASSIGNED_MODULE_SUCCESS';
export const DELETE_ASSIGNED_MODULE_FAILURE = 'DELETE_ASSIGNED_MODULE_FAILURE';

export const GET_INTERN_LIST_REQUEST = 'GET_INTERN_LIST_REQUEST';
export const GET_INTERN_LIST_SUCCESS = 'GET_INTERN_LIST_SUCCESS';
export const GET_INTERN_LIST_FAILURE = 'GET_INTERN_LIST_FAILURE';

export const GET_INTERN_COUNT_L1_REQUEST = 'GET_INTERN_COUNT_L1_REQUEST';
export const GET_INTERN_COUNT_L1_SUCCESS = 'GET_INTERN_COUNT_L1_SUCCESS';
export const GET_INTERN_COUNT_L1_FAILURE = 'GET_INTERN_COUNT_L1_FAILURE';

export const GET_INTERN_COUNT_L2_REQUEST = 'GET_INTERN_COUNT_L2_REQUEST';
export const GET_INTERN_COUNT_L2_SUCCESS = 'GET_INTERN_COUNT_L2_SUCCESS';
export const GET_INTERN_COUNT_L2_FAILURE = 'GET_INTERN_COUNT_L2_FAILURE';

export const GET_INTERN_COUNT_L3_REQUEST = 'GET_INTERN_COUNT_L3_REQUEST';
export const GET_INTERN_COUNT_L3_SUCCESS = 'GET_INTERN_COUNT_L3_SUCCESS';
export const GET_INTERN_COUNT_L3_FAILURE = 'GET_INTERN_COUNT_L3_FAILURE';

export const DELETE_INTERN_REQUEST = 'DELETE_INTERN_REQUEST';
export const DELETE_INTERN_SUCCESS = 'DELETE_INTERN_SUCCESS';
export const DELETE_INTERN_FAILURE = 'DELETE_INTERN_FAILURE';

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const DEACTIVATE_ACCOUNT_REQUEST = 'DEACTIVATE_ACCOUNT_REQUEST';
export const DEACTIVATE_ACCOUNT_SUCCESS = 'DEACTIVATE_ACCOUNT_SUCCESS';
export const DEACTIVATE_ACCOUNT_FAILURE = 'DEACTIVATE_ACCOUNT_FAILURE';
export const CLEAR_DEACTIVATE_ACCOUNT_DATA = 'CLEAR_DEACTIVATE_ACCOUNT_DATA';

export const CHANGE_LEVEL_REQUEST = 'CHANGE_LEVEL_REQUEST';
export const CHANGE_LEVEL_SUCCESS = 'CHANGE_LEVEL_SUCCESS';
export const CHANGE_LEVEL_FAILURE = 'CHANGE_LEVEL_FAILURE';
export const CLEAR_CHANGE_LEVEL_DATA = 'CLEAR_CHANGE_LEVEL_DATA';

export const EXPORT_CSV_REQUEST = 'EXPORT_CSV_REQUEST';
export const EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS';
export const EXPORT_CSV_FAILURE = 'EXPORT_CSV_FAILURE';

export const GET_LINK_REQUEST = 'GET_LINK_REQUEST';
export const GET_LINK_SUCCESS = 'GET_LINKL_SUCCESS';
export const GET_LINK_FAILURE = 'GET_LINK_FAILURE';

export const STORE_SELECTED_EMPLOYEE = 'STORE_SELECTED_EMPLOYEE';

export const STORE_SELECTED_MODULE = 'STORE_SELECTED_MODULE';
export const UPDATE_SELECTED_MODULE = 'UPDATE_SELECTED_MODULE';

export const POST_ASSIGN_MODULE_REQUEST = 'POST_ASSIGN_MODULE_REQUEST';
export const POST_ASSIGN_MODULE_SUCCESS = 'POST_ASSIGN_MODULE_SUCCESS';
export const POST_ASSIGN_MODULE_FAILURE = 'POST_ASSIGN_MODULE_FAILURE';

export const UPDATE_ASSIGN_MODULE_REQUEST = 'UPDATE_ASSIGN_MODULE_REQUEST';
export const UPDATE_ASSIGN_MODULE_SUCCESS = 'UPDATE_ASSIGN_MODULE_SUCCESS';
export const UPDATE_ASSIGN_MODULE_FAILURE = 'UPDATE_ASSIGN_MODULE_FAILURE';

export const CLEAR_SELECTED_EMAIL_LIST = 'CLEAR_SELECTED_EMAIL_LIST';
export const CLEAR_SELECTED_MODULE_LIST = 'CLEAR_SELECTED_MODULE_LIST';

export const CLEAR_ASSIGN_MODULE = 'CLEAR_ASSIGN_MODULE';

export const REMOVE_SELECTED_EMPLOYEE = 'REMOVE_SELECTED_EMPLOYEE';
export const STORE_ALL_INTERNS = 'STORE_ALL_INTERNS';
export const REMOVE_SELECTED_MODULE_DATA = 'REMOVE_SELECTED_MODULE_DATA';
export const STORE_ALL_MODULES = 'STORE_ALL_MODULES';

export const SET_INTERNS_LEVEL = 'SET_INTERNS_LEVEL';
