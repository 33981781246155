import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'xd-react-input';
import { Button } from 'xd-react-button';
import _ from 'lodash';
import UpdateFormPopup from './UpdateFormPopup';
import {} from '../../../actions/admin/employeeActions';
import './dist/style.css';
import FeedbackFormFields from './FeedbackFormFields';
import {
    getFeedbackFormDetails,
    updateFeedbackFormDetails,
    updateFeedbackForm,
    handleUpdateFormDialogChange,
    setFormFieldData,
    handleUpdateRemoveFields,
    clearUpdateFeedbackData,
    addNewUpdateFields,
} from '../../../actions/employee/feedbackActions';
import userRoles from '../../../components/roles/roles'
import createNotification from '../../../components/app/notification/createNotification';
import NoDataFound from '../../../components/app/no-data-found/NoDataFound';

class UpdateFeedbackForm extends Component {
    constructor(props) {
        super(props);
        this.handleEditFields = this.handleEditFields.bind(this);
        this.handleRemoveFields = this.handleRemoveFields.bind(this);
    }
    componentDidMount() {
        if (this.props.roleId === userRoles.Reviewer) {
            this.props.history.push('/admin/feedback/list');
        }
        const data = {
            formid: this.props.match.params.id,
        };
        this.props.dispatch(
            getFeedbackFormDetails(data, this.props.match.params.id)
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.updateFeedbackSuccessMsg.length !== 0) {
            createNotification('success', nextProps.updateFeedbackSuccessMsg);
            nextProps.history.push('/admin/feedback/list');
            nextProps.dispatch(clearUpdateFeedbackData());
        }
        if (nextProps.updateFeedbackErrorMsg.length !== 0) {
            createNotification('error', nextProps.updateFeedbackErrorMsg);
            nextProps.dispatch(clearUpdateFeedbackData());
        }
    }
    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    handleAddNewTextInput() {
        let textInoutField = {
            id: 'short-answer-text',
            name: 'short-answer-text',
            type: 'text',
            isRequired: false,
            placeholder: 'Short Answer text',
            label: 'Question?',
        };

        this.props.dispatch(addNewUpdateFields(textInoutField));
    }
    handleAddDropdownInput() {
        let dropdownInputField = {
            id: 'select-option',
            name: 'select-option',
            type: 'select',
            isRequired: false,
            placeholder: 'select option',
            label: 'Select',
            options: [
                { label: 'option1', value: 0 },
                { label: 'option2', value: 1 },
                { label: 'option3', value: 2 },
            ],
        };
        this.props.dispatch(addNewUpdateFields(dropdownInputField));
    }
    handleAddRadioInput() {
        let radioInputField = {
            id: 'radio-input',
            name: 'radio-input',
            type: 'radio',
            isRequired: false,
            placeholder: 'select option',
            label: 'Radio Input',
            options: [
                { label: 'option1', value: 0 },
                { label: 'option2', value: 1 },
                { label: 'option3', value: 2 },
            ],
        };
        this.props.dispatch(addNewUpdateFields(radioInputField));
    }
    handleAddTextarea() {
        let textareaField = {
            id: 'long-answer-text',
            name: 'long-answer-text',
            type: 'textarea',
            isRequired: false,
            placeholder: 'Long Answer text',
            label: 'Question?',
        };

        this.props.dispatch(addNewUpdateFields(textareaField));
    }
    handleAddCheckbox() {
        let textareaField = {
            id: 'checkbox-input',
            name: 'check-box-input',
            type: 'checkbox',
            isRequired: false,
            placeholder: 'Select option',
            label: 'Select any option.',
            options: [
                { label: 'option1', value: 'option1' },
                { label: 'option2', value: 'option2' },
                { label: 'option3', value: 'option3' },
            ],
        };

        this.props.dispatch(addNewUpdateFields(textareaField));
    }
    handleEditFields(formFields, index) {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleUpdateFormDialogChange(true));
        if (formFields !== '') {
            let data = _.cloneDeep(
                JSON.parse(this.props.feedbackFormDetails.outline)
            );
            this.props.dispatch(setFormFieldData(data, index));
        }
    }
    handleRemoveFields(index) {
        const fieldsList = JSON.parse(this.props.feedbackFormDetails.outline);
        fieldsList.splice(index, 1);
        this.props.dispatch(handleUpdateRemoveFields(fieldsList));
    }
    handleChange(e) {
        let value = e.target.value;
        const name = e.target.name;
        if (e.target.checkValidity()) {
            e.target.parentElement.classList.remove('has-error');
        } else {
            e.target.parentElement.classList.add('has-error');
        }
        const newState = Object.assign(this.props.feedbackFormDetails, {
            [name]: value,
        });
        this.props.dispatch(updateFeedbackFormDetails(newState));
    }
    updateFeedbackSubmit(e) {
        e.preventDefault();
        var name = document.getElementById('form_title');
        var description = document.getElementById('form_description');
        if (name.checkValidity() && description.checkValidity()) {
            let body = {
                title: this.props.feedbackFormDetails.title,
                description: this.props.feedbackFormDetails.description,
                outline: this.props.feedbackFormDetails.outline,
            };
            let data = {
                formid: this.props.match.params.id,
            };
            this.props.dispatch(updateFeedbackForm(data, body));
        } else {
            let allInvalidInput = document.querySelectorAll(
                '.form-group .form-control:invalid'
            );
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let invalidInput of allInvalidInput) {
                    invalidInput.parentElement.classList.add('has-error');
                    invalidInput.parentElement.parentElement.classList.add(
                        'has-error'
                    );
                }
            }
        }
    }

    render() {
        return (
            <>
                <main className="main-content-wrapper">
                    <section className="dashboard-content-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-left">
                                        <h2 className="content-header-title">
                                            Update Feedback Form
                                        </h2>
                                        <p className="content-header-description">
                                            Update the details of feedback form.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6">
                                    <div className="dashboard-content-header-right dashboard-header-action-group"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="dashboard-body-wrapper">
                        <div className="container">
                            <div className="main-content-body">
                                <section className="content-section-wrapper">
                                    <div className="feedback-form-wrapper">
                                        <div className="row">
                                            <div className="col-12 col-sm-9">
                                                <form
                                                    noValidate={true}
                                                    id="invute-employee-form"
                                                    onSubmit={this.updateFeedbackSubmit.bind(
                                                        this
                                                    )}
                                                >
                                                    <div className="feedback-form-main-content">
                                                        <div className="feedack-form-card card-box-shadow feedback-form-title">
                                                            <fieldset className="feedack-form-card-content">
                                                                <legend>
                                                                    Form Title &
                                                                    Description
                                                                </legend>
                                                                <div class="form-group">
                                                                    <Input
                                                                        type="text"
                                                                        name="title"
                                                                        id="form_title"
                                                                        value={
                                                                            this
                                                                                .props
                                                                                .feedbackFormDetails
                                                                                .title
                                                                        }
                                                                        outerClass="input-ctrl"
                                                                        placeholder="Title"
                                                                        required={
                                                                            true
                                                                        }
                                                                        onChange={this.handleChange.bind(
                                                                            this
                                                                        )}
                                                                        minLength={
                                                                            3
                                                                        }
                                                                        maxLength={
                                                                            50
                                                                        }
                                                                        autoComplete={
                                                                            'off'
                                                                        }
                                                                        label="Title"
                                                                        className="form-control"
                                                                        outerClassName="signup-input-box"
                                                                        errorMessage="Please enter the title(Min 3 char.)."
                                                                    />
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="control-label mb-5">
                                                                        Description
                                                                        <span
                                                                            class="req"
                                                                            style={{
                                                                                fontWeight:
                                                                                    'lighter',
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <textarea
                                                                        cols="20"
                                                                        rows="3"
                                                                        type="text"
                                                                        id="form_description"
                                                                        onChange={this.handleChange.bind(
                                                                            this
                                                                        )}
                                                                        value={
                                                                            this
                                                                                .props
                                                                                .feedbackFormDetails
                                                                                .description
                                                                        }
                                                                        class="form-control  textarea-sm"
                                                                        name="description"
                                                                        minlength="3"
                                                                        maxLength={
                                                                            200
                                                                        }
                                                                        autocomplete="off"
                                                                        required=""
                                                                        placeholder="Form Description"
                                                                    ></textarea>
                                                                    <p class="with-error">
                                                                        Please
                                                                        enter
                                                                        description
                                                                        (Min 3
                                                                        char.).
                                                                    </p>
                                                                </div>
                                                            </fieldset>
                                                            <div className="divider"></div>
                                                            <div className="feedack-form-card card-box-shadow">
                                                                <fieldset className="feedack-form-card-content">
                                                                    <legend>
                                                                        Form
                                                                        Fields
                                                                    </legend>
                                                                    {this.IsJsonString(
                                                                        this
                                                                            .props
                                                                            .feedbackFormDetails
                                                                            .outline
                                                                    ) ? (
                                                                        JSON.parse(
                                                                            this
                                                                                .props
                                                                                .feedbackFormDetails
                                                                                .outline
                                                                        ).map(
                                                                            (
                                                                                formField,
                                                                                index
                                                                            ) => (
                                                                                <>
                                                                                    <FeedbackFormFields
                                                                                        formField={
                                                                                            formField
                                                                                        }
                                                                                        index={
                                                                                            index
                                                                                        }
                                                                                        removeHandler={
                                                                                            this
                                                                                                .handleRemoveFields
                                                                                        }
                                                                                        editHandler={
                                                                                            this
                                                                                                .handleEditFields
                                                                                        }
                                                                                    />
                                                                                </>
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        <NoDataFound />
                                                                    )}
                                                                </fieldset>
                                                            </div>
                                                            <div
                                                                className="xd-btn-group"
                                                                style={{
                                                                    marginTop:
                                                                        '20px',
                                                                }}
                                                            >
                                                                <Button
                                                                    onClick={() =>
                                                                        this.props.history.push(
                                                                            '/admin/feedback/list'
                                                                        )
                                                                    }
                                                                    className="btn "
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button type="submit">
                                                                    Update
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                {this.IsJsonString(
                                                    this.props
                                                        .feedbackFormDetails
                                                        .outline
                                                ) && (
                                                    <div className="feedback-form-toolbox-wrapper">
                                                        <div className="feedback-form-toolbox">
                                                            <h3 className="feedback-form-toolbox-title">
                                                                Form Toolbox
                                                            </h3>
                                                            <ul className="feedback-form-toolbox-list">
                                                                <li>
                                                                    <div
                                                                        className="form-toolbox-item"
                                                                        onClick={() =>
                                                                            this.handleAddNewTextInput()
                                                                        }
                                                                    >
                                                                        <i className="fa fa-font"></i>
                                                                        <span>
                                                                            Text
                                                                            Input{' '}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div
                                                                        className="form-toolbox-item"
                                                                        onClick={() =>
                                                                            this.handleAddDropdownInput()
                                                                        }
                                                                    >
                                                                        <i class="fa fa-caret-square-o-down"></i>
                                                                        <span>
                                                                            Dropdown{' '}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div
                                                                        className="form-toolbox-item"
                                                                        onClick={() =>
                                                                            this.handleAddRadioInput()
                                                                        }
                                                                    >
                                                                        <i
                                                                            class="fa fa-dot-circle-o"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <span>
                                                                            Radio
                                                                            Input{' '}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div
                                                                        className="form-toolbox-item"
                                                                        onClick={() =>
                                                                            this.handleAddTextarea()
                                                                        }
                                                                    >
                                                                        <i className="fa fa-font"></i>
                                                                        <span>
                                                                            Textarea{' '}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div
                                                                        className="form-toolbox-item"
                                                                        onClick={() =>
                                                                            this.handleAddCheckbox()
                                                                        }
                                                                    >
                                                                        <i
                                                                            class="fa fa-check-square"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                        <span>
                                                                            Checkbox{' '}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <UpdateFormPopup />
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

function mapStateToProps(state) {
    const {
        feedbackFormFieldsList,
        feedbackFormDetails,
        updateFeedbackSuccess,
        updateFeedbackErrorMsg,
        updateFeedbackSuccessMsg,
    } = state.feedbackReducer;
    const {roleId} = state.authReducer;
    return {
        roleId,
        feedbackFormFieldsList,
        feedbackFormDetails,
        updateFeedbackSuccess,
        updateFeedbackErrorMsg,
        updateFeedbackSuccessMsg,
    };
}

export default withRouter(connect(mapStateToProps)(UpdateFeedbackForm));
