import React, {Component} from 'react';
import classNames from 'classnames';
import { Button } from 'xd-react-button';
import "./modal.css";
import "./animation.css";

let defaultState = true;

export default class Modal extends Component{  
    render() {
        const {showModal, className, closeModal, size, hasHeader, showCross, hasFooter} = this.props;    
        const width = typeof size === 'string' && size ;   
        return (
            <div className={classNames(className ? "xd-modal animated " + className : "xd-modal animated ", {'show fadeIn': showModal, 'fadeOut' : !showModal})} 
                id={this.props.id} >
                <div className={classNames("xd-modal-overlay animated ", {'fadeIn': showModal, 'fadeOut' : !showModal})}
                    onClick={this.props.outsideClose !== false && closeModal }></div>
                <div className={classNames("xd-modal-dialog animated " , {'fadeInDown': showModal, 'fadeInUp' : !showModal})}
                    style={{width:width}}>
                    <div className="xd-modal-content">
                        { hasHeader !== false &&
                            <div className="xd-modal-header">       
                                <h2 className="xd-modal-title">{this.props.title}</h2>                     
                                { showCross !== false &&
                                    <button type="button" className="xd-modal-close" onClick={closeModal}>
                                        <span aria-hidden="true">&times;</span>
                                    </button> 
                                }
                            </div>
                        }
                        <div className="xd-modal-body">
                            {this.props.children}
                        </div>
                        { hasFooter !== false &&
                            <div class="xd-modal-footer">
                                <div className="xd-btn-group">
                                    <Button className="btn btn-outline btn-compact" onClick={closeModal}> {this.props.cancelBtn} </Button>
                                    <Button className="btn btn-compact">{this.props.submitBtn}</Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}