import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import classNames from 'classnames';
import '../../../container/employee/profile/dist/modal.css';
import { Button } from 'xd-react-button';
import { handleModuleCompleteDialogChange, changeTaskStatus} from "../../../actions/employee/userModuleActions";


class ModuleCompletePopup extends Component { 
    constructor(props){
        super(props)
        this.state={
        }}
    
    handleDialogOnClose(){
        document.querySelector('#body-root').style="overflow:auto;"
        this.props.dispatch(handleModuleCompleteDialogChange(false))
    }
    handleSubmit(){
        document.querySelector('#body-root').style="overflow:auto;"
        this.props.dispatch(handleModuleCompleteDialogChange(false))
        const statusDetails= {
            'taskid':this.props.taskId,
            'status':'Done'
        }
        this.props.dispatch(changeTaskStatus(statusDetails, this.props.moduleId ))
    }

    render() {
        return (        
            <>          
            <div className={classNames("xd-modal animated ", {'show fadeIn': this.props.moduleCompletePopupState, 'fadeOut' : !this.props.moduleCompletePopupState})} id="add_member_dialog">
                
                <div className={classNames('edit-education-dialog  xd-modal-dialog  animated', {'fadeInDown': this.props.moduleCompletePopupState, 'fadeOutUp' : !this.props.moduleCompletePopupState})} >
                    <div className="xd-modal-content">
                        <div className="xd-modal-header">       
                            <h2 className="xd-modal-title">Complete Module</h2>
                            <button type="button" className="xd-modal-close" onClick={this.handleDialogOnClose.bind(this)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div  id='edit-education-dialog'>
                        <div className="xd-modal-body">
                            <div className="add-new-dag-form">    
                                    <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <p>If you change status of this task to complete, the module will be completed and you'll not be able to change its status again. Do you want to continue?</p>
                                    </div>
                                    </div>
                            </div>
                        </div>
                        <div className="xd-modal-footer">                            
                           <div className="xd-btn-group form-group">
                                <Button className="btn btn-outline btn-compact box-shadow" onClick={this.handleDialogOnClose.bind(this)} >Cancel</Button>
                                <Button type="submit" className="btn btn-compact btn-with-loader box-shadow" onClick={this.handleSubmit.bind(this)} >OK</Button>
                           </div>                           
                        </div>
                    </div>
                    </div>
                </div>               
            </div>
            {this.props.moduleCompletePopupState &&
             <div className={classNames("xd-modal-overlay animated ", {'fadeIn': this.props.moduleCompletePopupState, 'fadeOut' : !this.props.moduleCompletePopupState})}></div>
            }
             </>
        )
    }    
}
function mapStateToProps(state) {  
    const {moduleCompletePopupState, taskList, taskId} = state.userModuleReducer;
    return {moduleCompletePopupState, taskList, taskId}
}
export default withRouter(connect(mapStateToProps)(ModuleCompletePopup))
