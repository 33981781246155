import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {BlockLoader} from 'xd-react-loader';
import NoDataFound from '../../../../components/app/no-data-found/NoDataFound';
import { Avatar } from 'xd-react-avatar';

class AccountDetails extends Component{
render(){
    const {userProfileData, isProfileLoading, userProfileError, profileSuccess} = this.props  
    return(                       
        <section className="content-section-wrapper">
            <div className="row">               
                <div className="col-12 col-sm-12 col-md-10 col-lg-10">                                
                    <div className="user-details">
                        {isProfileLoading?(
                        <div className="page-section-loader">
                            <BlockLoader
                                loadingText="Loading Profile Details .."
                                loading={true}
                                loaderStyle={{
                                    size: "24px",
                                    color: "0562e8",
                                    textSize: "14px",
                                    textColor: "#757575",
                                    speed: "0.5s"
                                }}
                            />
                        </div>
                        ):userProfileError?(
                           <NoDataFound title={userProfileError} />
                        ):profileSuccess?(<div className="details-container">
                        <div className="user-profile-container">                                        
                            <div className="profile-section_card">
                                <div className="profile-section_grid">
                                    <div className="profile-section_card_item">
                                    <article className="profile-section_card_details">
                                            <div className="profile-card-meta_data">
                                                <div className="row">
                                                    <div className="col-12">
                                                    <div className="avatar-box">
                                                        {userProfileData.personal_info.profile_img_link === '' ?
                                                        (<Avatar size={120}  radius={100}
                                                            name={userProfileData.accounts.name !== '' ? userProfileData.accounts.name : 'XS'}/>
                                                            ):(<Avatar 
                                                                color={"#F99316"} size={120} radius={100}
                                                                name={userProfileData.accounts.name !== '' ? userProfileData.accounts.name : 'XS'}
                                                                src={userProfileData.personal_info.profile_img_link !== '' ?  userProfileData.personal_info.profile_img_link : ''} />
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Name</p>
                                                            <p className="fields-value">{userProfileData.accounts.name!== ''? userProfileData.accounts.name: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Email</p>
                                                            <p className="fields-value">{userProfileData.accounts.email!== ''? userProfileData.accounts.email: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Phone</p>
                                                            <p className="fields-value">{userProfileData.accounts.contact_no!== ''? userProfileData.accounts.contact_no: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Level </p>
                                                            <p className="fields-value">{userProfileData.accounts.level!== ''? userProfileData.accounts.level: 'N/A'}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="profile-fields_data">
                                                            <p className="fields-name">Policy Accepted </p>
                                                            <p className="fields-value">{userProfileData.accounts.accepted_policy ? (<strong>Yes</strong>): (<strong>NO</strong>)}</p>
                                                        </div>
                                                    </div>       
                                                </div>
                                            </div>
                                        </article>                                           
                                        </div>
                                    </div>
                                </div> 
                                {/* p&c info */}
                                <div className="profile-section_card">
                                    <h2 className="profile-section_card_title"> Team Information</h2>
                                    <div className="profile-section_grid"> 
                                    <div className="profile-section_card_item">
                                    <article className="profile-section_card_details">
                                        <div className="profile-card-meta_data">
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">Team</p>
                                                        <p className="fields-value">{userProfileData.pandcinfo.team!== ''? userProfileData.pandcinfo.team: 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">Coach</p>
                                                        <p className="fields-value">{userProfileData.pandcinfo.coach!== ''? userProfileData.pandcinfo.coach: 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">Head Coach</p>
                                                        <p className="fields-value">{userProfileData.pandcinfo.headcoach!== ''? userProfileData.pandcinfo.headcoach: 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">People Manager</p>
                                                        <p className="fields-value">{userProfileData.pandcinfo.people_manager!== ''? userProfileData.pandcinfo.people_manager: 'N/A'}</p>
                                                    </div>
                                                </div> 
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">Project Manager</p>
                                                        <p className="fields-value">{userProfileData.pandcinfo.project_manager!== ''? userProfileData.pandcinfo.project_manager: 'N/A'}</p>
                                                    </div>
                                                </div>   
                                                <div className="col-12 col-sm-6">
                                                    <div className="profile-fields_data">
                                                        <p className="fields-name">Buddy</p>
                                                        <p className="fields-value">{userProfileData.pandcinfo.buddy!==''?userProfileData.pandcinfo.buddy:'N/A'}</p>
                                                    </div>
                                                </div>                                                 
                                            </div>
                                        </div>
                                    </article>                                           
                                    </div>
                                </div>
                            </div>    
                        </div>                                               
                        </div>   
                     ):(
                        <NoDataFound />
                     )}
                    </div>
                </div>
            </div>
        </section>
        )
    }
}

function mapStateToProps(state) {
    const {isProfileLoading, userProfileData, userProfileError,profileSuccess} = state.employeeReducer;
    return { isProfileLoading, userProfileData, userProfileError,profileSuccess}
}

export default withRouter(connect(mapStateToProps)(AccountDetails))
