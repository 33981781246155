import {GET_REVIEWER_LIST_REQUEST, GET_REVIEWER_LIST_SUCCESS,GET_REVIEWER_LIST_FAILURE, 
    ASSIGN_REVIEWER_REQUEST, ASSIGN_REVIEWER_SUCCESS, ASSIGN_REVIEWER_FAILURE, 
    STORE_SELECTED_REVIEWER_DATA, CLEAR_ASSIGN_REVIEWER_DATA,
    ASSIGNED_REVIEWER_LIST_REQUEST, ASSIGNED_REVIEWER_LIST_SUCCESS,ASSIGNED_REVIEWER_LIST_FAILURE,
    DELETE_ASSIGNED_REVIEWER_REQUEST, DELETE_ASSIGNED_REVIEWER_SUCCESS, DELETE_ASSIGNED_REVIEWER_FAILURE,
    CLEAR_DELETE_REVIEWER_DATA, REMOVE_SELECTED_REVIEWER_DATA, STORE_ALL_REVIEWERS_AS_SELECTED, ADD_MEMBER_TO_MEETING_LIST,
    REMOVE_MEMBER_FROM_MEETING_LIST, UPDATE_REVIEW_MEETING_DETAILS, CLEAR_REVIEW_INVITE_ERROR_DATA,
    SEND_REVIEW_MEETING_INVITE_REQUEST, SEND_REVIEW_MEETING_INVITE_SUCCESS, SEND_REVIEW_MEETING_INVITE_FAILURE, CLEAR_REVIEW_MEETING_DATA,
    CHECK_CREDENTIALS_REQUEST, CHECK_CREDENTIALS_SUCCESS, CHECK_CREDENTIALS_FAILURE,
    GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_FAILURE, CLEAR_CHECK_CREDENTIALS_DATA} from '../../constants/admin/ReviewerConstants';

import { GET_API, DELETE_API, POST_API } from "../../middleware/symbols";

import {getDocumentApi, accountApi, moduleApi } from "../ApiActions";

const AUTH_API = accountApi();
const DOCUMENT_API = getDocumentApi();
const MODULE_API = moduleApi();

export function getReviewerList(){
    return{
        [GET_API]:{
            endpoint: AUTH_API+`/v1/adminlist?role=${"reviewer"}`,
            authenticated: true,
            types:[GET_REVIEWER_LIST_REQUEST, GET_REVIEWER_LIST_SUCCESS,GET_REVIEWER_LIST_FAILURE],
        }
    }
}
export function assignReviewer(body){
    return{
        [POST_API]:{
            endpoint: AUTH_API+`/v1/assignreviewer`,
            authenticated: true,
            types:[ASSIGN_REVIEWER_REQUEST, ASSIGN_REVIEWER_SUCCESS, ASSIGN_REVIEWER_FAILURE],
            body
        }
    }
}
export function storeSelectedReviewerData(data){
    return {type: STORE_SELECTED_REVIEWER_DATA, data}
}
export function storeAllReviewers(list){
    return {type: STORE_ALL_REVIEWERS_AS_SELECTED, list}
}
export function removeSelectedReviewerData(data){
    return {type: REMOVE_SELECTED_REVIEWER_DATA, data}
}
export function clearAssignReviewerData(){
    return {type: CLEAR_ASSIGN_REVIEWER_DATA}
}
export function getAssignedReviewerListing(email){
    return {
        [GET_API]:{
            endpoint: AUTH_API+`/v1/listreviewer/${email}`,
            authenticated: true,
            types:[ASSIGNED_REVIEWER_LIST_REQUEST, ASSIGNED_REVIEWER_LIST_SUCCESS,ASSIGNED_REVIEWER_LIST_FAILURE],
        }
    }
}
export function deleteAssignedReviewer (reviewerEmail, userEmail){
    return{
        [DELETE_API]:{
            endpoint: AUTH_API+`/v1/deletereviewer/${reviewerEmail}/user/${userEmail}`,
            authenticated: true,
            types:[DELETE_ASSIGNED_REVIEWER_REQUEST, DELETE_ASSIGNED_REVIEWER_SUCCESS, DELETE_ASSIGNED_REVIEWER_FAILURE],
        }
    }
}
export function clearDeleteReviewerData(){
    return{
        type: CLEAR_DELETE_REVIEWER_DATA
    }
}
export function addMemberToMeetingList(member){
    return{ type: ADD_MEMBER_TO_MEETING_LIST, member}
}
export function removeMemberFromMeeting(list){
    return{type: REMOVE_MEMBER_FROM_MEETING_LIST, list}
}
export function updateReviewMeetingData(data){
    return{ type: UPDATE_REVIEW_MEETING_DETAILS, data}
}
export function sendReviewMeetingInvite(body){
    return{
        [POST_API]:{
            endpoint: AUTH_API+`/v1/google/invite`,
            authenticated: true,
            types:[SEND_REVIEW_MEETING_INVITE_REQUEST, SEND_REVIEW_MEETING_INVITE_SUCCESS, SEND_REVIEW_MEETING_INVITE_FAILURE],
            body
        }
    }
}
export function clearReviewInviteData(){
    return{ type: CLEAR_REVIEW_MEETING_DATA}
}
export function clearReviewInviteErrorData(){
    return {type: CLEAR_REVIEW_INVITE_ERROR_DATA}
}
export function checkUserCredentials(){
    return{
        [GET_API]:{
            endpoint: AUTH_API+`/v1/google/check`,
            authenticated: true,
            types:[CHECK_CREDENTIALS_REQUEST, CHECK_CREDENTIALS_SUCCESS, CHECK_CREDENTIALS_FAILURE],
        }
    }
}
export function getGoogleLogin(){
    return{
        [GET_API]:{
            endpoint: AUTH_API+`/v1/google/login`,
            authenticated: false,
            types:[GOOGLE_LOGIN_REQUEST, GOOGLE_LOGIN_SUCCESS, GOOGLE_LOGIN_FAILURE],
        }
    }
}
export function clearCheckData(){
    return{type: CLEAR_CHECK_CREDENTIALS_DATA}
}