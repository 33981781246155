import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { BlockLoader } from 'xd-react-loader';
import {
    getProfileDocumentLinks,
    handleEditOtherDialogChange,
    handleEditEmergencyDialogChange,
    setOtherInfo,
    setEmergencyInfo,
} from '../../../../actions/employee/userActions';
import ProfileSubHeader from '../ProfileSubHeader';
import UserProfileSidebar from '../UserProfileSidebar';
import ProfileNavigation from '../ProfileNavigation';
import EditOtherPopup from '../edit-profile/EditOtherPopup';
import EditEmergencyPopup from '../edit-profile/EditEmergencyPopup';

class OtherInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOtherInfo: [],
            editEmergencyInfo: [],
        };
    }


    handleGetDocumentLink(link) {
        this.props.dispatch(getProfileDocumentLinks(link));
    }
    handleEditOtherDialog(certificates) {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleEditOtherDialogChange(true));
        if (certificates !== '') {
            let others = _.cloneDeep(this.props.profileInfo.profile.others);
            this.props.dispatch(setOtherInfo(others));
        }
    }

    handleEditEmergencyDialog(emergencyInfo) {
        document.querySelector('#body-root').style = 'overflow:hidden;';
        this.props.dispatch(handleEditEmergencyDialogChange(true));
        if (emergencyInfo !== '') {
            let emergency = _.cloneDeep(
                this.props.profileInfo.profile.emergency
            );
            this.props.dispatch(setEmergencyInfo(emergency));
        }
    }

    render() {
        const { profileInfo } = this.props;
        return (
            <main className="main-content-wrapper employee-profile-wrapper">
                <ProfileSubHeader />
                <div className="dashboard-body-wrapper gry-fill-background">
                    <div className="container">
                        <section className="employee-profile-content-wrapper">
                            <div className="row">
                                <div className="col-12 col-sm-3">
                                    <div className="employee-profile-side-template">
                                        <UserProfileSidebar />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-9">
                                    <div className="employee-profile-content">
                                        <ProfileNavigation />
                                        {this.props.isUserProfileLoading ? (
                                            <div className="xs-loader-wrapper">
                                                <BlockLoader
                                                    loadingText="loading"
                                                    loading={true}
                                                    loaderStyle={{
                                                        size: '20px',
                                                        color: '0562e8',
                                                        textSize: '18px',
                                                        textColor: '#485e7d',
                                                        speed: '1s',
                                                    }}
                                                />
                                            </div>
                                        ) : this.props.profileErrorMsg ? (
                                            <h3>
                                                {this.props.profileErrorMsg}
                                            </h3>
                                        ) : this.props.getProfileSuccess ? (
                                            <div className="user-profile-container">
                                                <div className="profile-section_card">
                                                    <h2 className="profile-section_card_title">
                                                        Emergency Contact
                                                    </h2>
                                                    <div className="profile-section_grid">
                                                        {profileInfo.profile
                                                            .emergency.length ==
                                                            0 && (
                                                            <strong
                                                                onClick={(e) =>
                                                                    this.handleEditEmergencyDialog(
                                                                        ''
                                                                    )
                                                                }
                                                                className="link-button link-button_icon"
                                                            >
                                                                <i className="icon-pencil"></i>{' '}
                                                                Edit
                                                            </strong>
                                                        )}
                                                        {profileInfo.profile
                                                            .emergency.length !=
                                                            0 && (
                                                            <div className="profile-section_card_item">
                                                                <article className="profile-section_card_details">
                                                                    <div className="profile-action">
                                                                        <strong
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                this.handleEditEmergencyDialog(
                                                                                    profileInfo
                                                                                        .profile
                                                                                        .emergency
                                                                                )
                                                                            }
                                                                            className="link-button link-button_icon"
                                                                        >
                                                                            <i className="icon-pencil"></i>{' '}
                                                                            Edit
                                                                        </strong>
                                                                    </div>
                                                                    {profileInfo.profile.emergency.map(
                                                                        (
                                                                            emergency,
                                                                            emIndex
                                                                        ) => (
                                                                            <div className="profile-card-meta_data">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Contact
                                                                                                Name
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {emergency.contact_name !==
                                                                                                ''
                                                                                                    ? emergency.contact_name
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Contact
                                                                                                Number
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {emergency.contact_number !==
                                                                                                ''
                                                                                                    ? emergency.contact_number
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </article>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="profile-section_card">
                                                    <h2 className="profile-section_card_title">
                                                        Other Certificates
                                                    </h2>
                                                    <div className="profile-section_grid">
                                                        {profileInfo.profile
                                                            .others.length ==
                                                            0 && (
                                                            <strong
                                                                onClick={(e) =>
                                                                    this.handleEditOtherDialog(
                                                                        ''
                                                                    )
                                                                }
                                                                className="link-button link-button_icon"
                                                            >
                                                                <i className="icon-pencil"></i>{' '}
                                                                Edit
                                                            </strong>
                                                        )}
                                                        {profileInfo.profile
                                                            .others.length !=
                                                            0 && (
                                                            <div className="profile-section_card_item">
                                                                <article className="profile-section_card_details">
                                                                    <div className="profile-action">
                                                                        <strong
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                this.handleEditOtherDialog(
                                                                                    profileInfo
                                                                                        .profile
                                                                                        .others
                                                                                )
                                                                            }
                                                                            className="link-button link-button_icon"
                                                                        >
                                                                            <i className="icon-pencil"></i>{' '}
                                                                            Edit
                                                                        </strong>
                                                                    </div>
                                                                    {profileInfo.profile.others.map(
                                                                        (
                                                                            certifcate,
                                                                            cerIndex
                                                                        ) => (
                                                                            <div className="profile-card-meta_data">
                                                                                <div className="row">
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Certificate
                                                                                                Name
                                                                                            </p>
                                                                                            <p className="fields-value">
                                                                                                {certifcate.name !==
                                                                                                ''
                                                                                                    ? certifcate.name
                                                                                                    : 'N/A'}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-6">
                                                                                        <div className="profile-fields_data">
                                                                                            <p className="fields-name">
                                                                                                Certificate
                                                                                                Link
                                                                                            </p>
                                                                                            <p className="fields-value fields-value_link">
                                                                                                {certifcate.certificate_link !==
                                                                                                '' ? (
                                                                                                    <>
                                                                                                        Certificate
                                                                                                        Link
                                                                                                        <a
                                                                                                            href="javascript:void(0)"
                                                                                                            onClick={() =>
                                                                                                                this.handleGetDocumentLink(
                                                                                                                    certifcate.certificate_link
                                                                                                                )
                                                                                                            }
                                                                                                            className="doc-download"
                                                                                                        >
                                                                                                            {' '}
                                                                                                            <i className="icon-cloud-download"></i>
                                                                                                            Download
                                                                                                        </a>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <span className="no-data-text-small">
                                                                                                        N/A
                                                                                                    </span>
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </article>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <EditOtherPopup />
                        <EditEmergencyPopup />
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
    const {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        getProfileLinkSuccess,
        profileDocumentLink,
        updateSuccess,
        userotherInfo,
        useremergencyInfo,
    } = state.userReducer;
    return {
        getProfileSuccess,
        isUserProfileLoading,
        profileErrorMsg,
        profileInfo,
        getProfileLinkSuccess,
        profileDocumentLink,
        updateSuccess,
        userotherInfo,
        useremergencyInfo,
    };
}

export default withRouter(connect(mapStateToProps)(OtherInfo));
