export const MODULE_LIST_REQUEST="MODULE_LIST_REQUEST";
export const MODULE_LIST_SUCCESS="MODULE_LIST_SUCCESS";
export const MODULE_LIST_FAILURE="MODULE_LIST_FAILURE";

export const TASK_LIST_REQUEST="TASK_LIST_REQUEST";
export const TASK_LIST_SUCCESS="TASK_LIST_SUCCESS";
export const TASK_LIST_FAILURE="TASK_LIST_FAILURE";

export const CHANGE_TASK_STATUS_REQUEST="CHANGE_TASK_STATUS_REQUEST";
export const CHANGE_TASK_STATUS_SUCCESS="CHANGE_TASK_STATUS_SUCCESS";
export const CHANGE_TASK_STATUS_FAILURE="CHANGE_TASK_STATUS_FAILURE";

export const CLEAR_MODULE_LIST = "CLEAR_MODULE_LIST"

export const CLEAR_TASK_STATUS_DATA="CLEAR_TASK_STATUS_DATA"

export const HANDLE_MODULE_COMPLETE_DIALOG_STATE="HANDLE_MODULE_COMPLETE_DIALOG_STATE";

export const HANDLE_UPLOAD_PRESENTATION_DIALOG_CHANGE="HANDLE_UPLOAD_PRESENTATION_DIALOG_CHANGE";

export const UPLOAD_PRESENTATION_REQUEST="UPLOAD_PRESENTATION_REQUEST";
export const UPLOAD_PRESENTATION_SUCCESS="UPLOAD_PRESENTATION_SUCCESS";
export const UPLOAD_PRESENTATION_FAILURE="UPLOAD_PRESENTATION_FAILURE";

export const UPLOAD_PRESENTATION_LINK_REQUEST="UPLOAD_PRESENTATION_LINK_REQUEST";
export const UPLOAD_PRESENTATION_LINK_SUCCESS="UPLOAD_PRESENTATION_LINK_SUCCESS";
export const UPLOAD_PRESENTATION_LINK_FAILURE="UPLOAD_PRESENTATION_LINK_FAILURE";

export const GET_PRESENTATION_LINK_REQUEST="GET_PRESENTATION_LINK_REQUEST";
export const GET_PRESENTATION_LINK_SUCCESS="GET_PRESENTATION_LINK_SUCCESS";
export const GET_PRESENTATION_LINK_FAILURE="GET_PRESENTATION_LINK_FAILURE";

export const DELETE_PRESENTATION_REQUEST="DELETE_PRESENTATION_REQUEST";
export const DELETE_PRESENTATION_SUCCESS="DELETE_PRESENTATION_SUCCESS";
export const DELETE_PRESENTATION_FAILURE="DELETE_PRESENTATION_FAILURE";

export const CLEAR_NOTIFICATION_DATA="CLEAR_NOTIFICATION_DATA";

export const CLEAR_PRESENTATION_UPLOAD_DATA="CLEAR_PRESENTATION_UPLOAD_DATA";