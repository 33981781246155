import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import PrivateRoute from './PrivateRoute';
import { store } from '../index';
import SignupIndex from '../container/auth/signup';
import EmployeeIndex from '../container/admin/invite-employee';
import Login from '../container/auth/Login';
import ForgotPassword from '../container/auth/ForgotPassword';
import ResetPassword from '../container/auth/ResetPassword';
import { isTokenValid } from '../actions/ApiActions';
import moment from 'moment';
import EmployeeMainIndex from '../container/employee';
import { NotificationContainer } from 'react-notifications';
import MemberSignup from '../container/auth/membersignup/MemberSignup';
import MainIndex from '../container/index';
/**
 * This function checks wheather the user is logged in or logged out.
 * isAuthenticated === true - logged in
 * isAuthenticated === false - logged out
 *
 * we can change it, but its name needs to be the same and
 *  it needs to return a true or false resemblance values
 */
export function checkAuth() {
    const { isAuthenticated } = store.getState().authReducer;
    return isAuthenticated;
}

class RouteComponent extends Component {

    componentDidMount() {
        //   call refreshToken api
        setInterval(() => {
            let tokenExpireDate = moment(localStorage.getItem('expire')).format(
                'YYYY-MM-DD HH:mm:ss'
            );

            let refreshThresholdDate = moment(
                new Date().getTime() + 5 * 60 * 1000
            ).format('YYYY-MM-DD HH:mm:ss');

            if (
                this.props.isAuthenticated &&
                refreshThresholdDate >= tokenExpireDate
            ) {
                this.props.dispatch(isTokenValid());
            }
        }, 5000);
    }

    render() {
        return (
            <div className="route-wrapper">
                <Switch>
                    <Route exact path="/" component={MainIndex} />
                    <Route exact path="/login" component={Login} />

                    {/*<ProtectedRoute exact path="/UserProfile" component={UserProfile} />*/}
                    <Route
                        exact
                        path="/admin-registration"
                        component={MemberSignup}
                    />
                    <ProtectedRoute
                        exact
                        path="/forgot-password"
                        component={ForgotPassword}
                    />
                    <ProtectedRoute
                        exact
                        path="/reset-password"
                        component={ResetPassword}
                    />

                    {/*<Route path="/intern" component={InternDashboard}/> */}
                    <ProtectedRoute
                        path="/member-registration"
                        component={SignupIndex}
                    />

                    <Switch>
                        <PrivateRoute path="/admin" component={EmployeeIndex} />
                        <PrivateRoute
                            path="/admin/employee"
                            component={EmployeeIndex}
                        />
                        <PrivateRoute
                            path="/admin/feedback"
                            component={EmployeeIndex}
                        />
                        <PrivateRoute
                            path="/employee"
                            component={EmployeeMainIndex}
                        />
                    </Switch>
                    {/* <Redirect from='*' to={Login} /> */}
                </Switch>
                <NotificationContainer />
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { isAuthenticated } = state.authReducer;
    return { isAuthenticated };
}

export default withRouter(connect(mapStateToProps)(RouteComponent));
